<template>
  <div class="relative mt-24  overflow-y-auto bg-gray-50 lg:ml-4 dark:bg-gray-900">
    <div class="px-4 mx-auto max-w-screen-2xl  mb-2">
      <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">

 
        <div
          class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4"
        >
          <div class="flex items-center flex-1 space-x-4">
            <div class="  flex items-center divide-x divide-gray-300 dark:divide-gray-700">
              <div class="pr-3 hidden">
                <a
                  class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  @click="onCancel"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
              <div class="hidden flex pl-0 space-x-2 sm:px-2">
                <a
                  href="#"
                  class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="#"
                  class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="#"
                  class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                    <path
                      fill-rule="evenodd"
                      d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="#"
                  class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                    />
                  </svg>
                </a>
              </div>
              <div class="hidden pl-3 text-sm font-medium text-gray-500">
                -
              </div>
            </div>


            <h5 class="hidden">
              <span class="text-gray-500"> bel_total </span>
              <span class="ml-1 dark:text-white font-bold"> total </span>
            </h5>
            <h5 class="hidden">
              <span class="text-gray-500">Total sales:</span>
              <span class="dark:text-white">$88.4k</span>
            </h5>


            <button
              v-if="(template.showGenerate) && (record._id != undefined)"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onGenerateSheet"
            >
              {{ common_labels['BUTTON_GENERATE_SHEET'][lg] }}
            </button>
          </div>
          <div
            class="flex  flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3"
          >
            <button
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onExplore"
            >
              <i class="fa-solid fa-folder-tree mr-2" />
              {{ common_labels['BUTTON_EXPLORE'][lg] }}
            </button>

            <button
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onSaveSheet"
            >
              <i class="fa-solid fa-share-from-square mr-2" />
              {{ common_labels['BUTTON_SHARE_RESULT'][lg] }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <Result
      :config="config"
      :record="record"
      :template="template"
      @change-view="onChangeView"
      @save="onSave"
      @cancel="onCancel"
    />
  </div>
</template>
<script>
    import Result from '@/components/ResultComponent.vue'
    import service from "@/services/FullService.vue"
    import common_labels from "@/assets/lg/common.json"
    import description_result_cheese from '@/templates/result-cheese.json'
    import description_result_cheese_raw from '@/templates/result-cheese raw.json'
    import { mapGetters } from "vuex";
    export default {
        name: 'ResultView',
        components: {
            Result
        }, 
        data() {
            return {
                isLoading: false,
                config: {},
                record: {},
                template: description_result_cheese_raw,

                common_labels: common_labels,
                lg: "FR",
                title: "",
                button: ""
            }
        },

        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            '$route.params.id': async function () {
 
                if (this.$route.path.indexOf("finished-product-batches") != -1) {
                    this.template = description_result_cheese
                    this.record = await service.getItem3(this.$route.params.id, this.user?.token)
 
                }
                else {
                    this.template = description_result_cheese_raw
                    this.record = await service.getItem2(this.$route.params.id, this.user?.token)

                }
 
            },
            config2: function (val) {
                if (val) {
                    this.config = this.config2
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }
            }
        },
        async mounted() {
            if (this.user == undefined) {
                //this.$router.push("/login");
                window.location = "/login"
                return;
            }


            this.isLoading = true;
            console.log("ResultView 1", "result-shipment")
            this.config = await service.getConfiguration("result-shipment", {}, this.user?.token)
           
            if (this.$route.path.indexOf("finished-product-batches") != -1) {
                this.template = description_result_cheese
                this.record = await service.getItem3(this.$route.params.id, this.user?.token)
            }
            else {
                this.template = description_result_cheese_raw
                this.record = await service.getItem2(this.$route.params.id, this.user?.token)
            }
        },
        methods: {
            onExplore() {
                let url = "/admin/network/" + this.$route.params.id;
                window.location = url;
            },
            onCancel() {
                if (this.$route.path.indexOf("finished-product-batches") != -1) {
                    let url = "/admin/views/finished-product-batches";
                    window.location = url;
                    return;
                }

                let url = "/admin/views/raw-product-batches";
                window.location = url;
            },
            async onSave(item) {
                if (this.$route.params.rid == 'new') {

                    let collection = this.$route.params.id
                    if (this.template.endpoint != undefined)
                        collection = this.template.endpoint
                    let response = await service.addRecord(collection, this.user?.token, item)
                    if (response != undefined)
                        this.$router.push("/admin/views/" + this.$route.params.id + "/" + response._id)
                }
                else {
                    //let response = 
                    await service.updateRecord(this.$route.params.id, this.$route.params.rid, this.user?.token, item)
                }
            },
            async onChangeView(item) {

      
                this.config = await service.getConfiguration(item.collection, {}, this.user?.token)


                let url = "/admin/views/" + item.collection + "/" + item.record

                window.location = url
            }
        }
    }
</script>
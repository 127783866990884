<template>
  <div class="relative  mt-2 mb-2 w-full flex justify-start">
    <ol class="flex max-w-3xl bg-gray-100 items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500   border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4">
      
      <li class="flex items-center text-gray-600 dark:text-blue-500 w-full">
        <span
          class="flex items-center justify-center w-5 h-5 mr-2 text-xs border  rounded-full shrink-0 dark:border-blue-500"
                
                
          :class="{ 'border-blue-600 bg-blue-600 text-white' : value=='received', 'border-gray-500   text-gray-500 ': value!='received'}"
        >
          1
        </span>
        Collecté 
        <svg
          class="w-3 h-3 ml-2 sm:ml-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 12 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m7 9 4-4-4-4M1 9l4-4-4-4"
          />
        </svg>
      </li>
      
      <li class="flex items-center text-gray-600 dark:text-blue-500 w-full">
        <span
          class="flex items-center justify-center w-5 h-5 mr-2 text-xs border  rounded-full shrink-0 dark:border-blue-500"
                
                
          :class="{ 'border-blue-600 bg-blue-600 text-white' : value=='received', 'border-gray-500   text-gray-500 ': value!='received'}"
        >
          1
        </span>
        Réceptionné 
        <svg
          class="w-3 h-3 ml-2 sm:ml-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 12 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m7 9 4-4-4-4M1 9l4-4-4-4"
          />
        </svg>
      </li>
      <li
        class="flex items-center w-full  "
        :class="{ 'text-blue-600' : value=='received', 'text-gray-600': value!='received'}"
      >
        <span
          class="flex items-center justify-center w-5 h-5 mr-2 text-xs   border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                
          :class="{ 'border-blue-600 bg-blue-600 text-white' : value=='validated', 'border-gray-500   text-gray-500 ': value!='validated'}"
        >
          2
        </span>
        Inspecté  
        <svg
          class="w-3 h-3 ml-2 sm:ml-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 12 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m7 9 4-4-4-4M1 9l4-4-4-4"
          />
        </svg>
      </li>
      <li
        class="flex items-center w-full"
        :class="{ 'text-blue-600' : value=='accepted', 'text-gray-600': value!='accepted'}"
      >   
        <span
          class="flex items-center justify-center w-5 h-5 mr-2 text-xs border  rounded-full shrink-0 dark:border-gray-400"
                
          :class="{ 'border-blue-600 bg-blue-600 text-white' : value=='accepted', 'border-gray-500   text-gray-500': value!='accepted'}"
        >
          3
        </span>
        Controlé
      </li>
    </ol>
  </div>
</template>
<script>
  


    export default {
        name: 'ContentStep',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: String,
            config: Object
        },
        data() {
            return {
                output: ""
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        },
        mounted() {
          
            this.output = this.value;
        },
        methods: {
       
            getData() {
                
                return ""
            }
        }
    }
</script>
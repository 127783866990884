<template>
    <div class="relative   w-full  w-96  flex justify-center items-center p-2 ">
        <apexchart class=" h-auto  w-full flex items-center  justify-center   pl-3" type="treemap"
            :options="chartOptions" :series="series" />
    </div>
</template>

<script>
    import VueApexCharts from "vue3-apexcharts";
    export default {
        name: 'TreMap',
        components: {
            apexchart: VueApexCharts,
        },
        props: {

            title: String,
            records: Object,
            config: Object
        },
        data: function () {
            return {



                chartOptions: {
                    legend: {
                        show: true
                    },
                    chart: {

                        type: 'treemap',
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },


                    },
                    title: {
                        text: this.title
                    }
                },
                series: [
                    {
                        data: []
                    }
                ]
            }
        },
        watch: {
            'records': async function (val) {
                console.log("TreeMapChart WATCH records")
                //    console.log("ColumnBarChart WATCH records")
                //    console.log("ColumnBarChart WATCH records")
                //    console.log("ColumnBarChart WATCH records")
                //    console.log("ColumnBarChart WATCH records")
                //   console.log(val)




                let r = this.transformData(val);
                if (r == undefined) r = { data: [], name: "" }
                this.localrecords = r


                this.series[0].data = [
                    {
                        x: 'New Delhi',
                        y: 218
                    },
                    {
                        x: 'Kolkata',
                        y: 149
                    },
                    {
                        x: 'Mumbai',
                        y: 184
                    },
                    {
                        x: 'Ahmedabad',
                        y: 55
                    },
                    {
                        x: 'Bangaluru',
                        y: 84
                    },
                    {
                        x: 'Pune',
                        y: 31
                    },
                    {
                        x: 'Chennai',
                        y: 70
                    },
                    {
                        x: 'Jaipur',
                        y: 30
                    },
                    {
                        x: 'Surat',
                        y: 44
                    },
                    {
                        x: 'Hyderabad',
                        y: 68
                    },
                    {
                        x: 'Lucknow',
                        y: 28
                    },
                    {
                        x: 'Indore',
                        y: 19
                    },
                    {
                        x: 'Kanpur',
                        y: 29
                    }
                ]

                /*  this.series[0].data = r.series[0].data;
                  this.chartOptions =
                  {
                      xaxis: {
                          categories: r.categories
                      }
                      //    labels : r.categories
                  }*/
                //     this.series[0].name = r.series[0].name;
            }


        },
        async mounted() {

            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.id })


        },

        methods: {

            mapLabels: function (name) {

                return name;
            },
            transformData(data) {

                // console.log("RowBar - RowBar RowBar RowBar RowBar RowBar", data.records)
                //  console.log(JSON.stringify(data))
                let series = []
                let labels = {}


                {
                    for (let i = 0; i < data.records.length; i++) {
                        if (data.records[i]) {
                            series.push(

                                {
                                    x: data.records[i]['label'] ? this.mapLabels(data.records[i]['label']) : 'autres',
                                    y: data.records[i]['value']
                                }


                            )
                            labels[data.records[i]['label']] = this.mapLabels(data.records[i]['value'])
                        }

                    }
                }


                //     console.log("series", data.records.length)
                //     console.log(series)
                //     console.log(labels)
                //     console.log(Object.keys(labels))
                return { categories: Object.keys(labels), series: [{ name: "test", data: series }] }
            }
        }
    }
</script>
<template>
  <div
    class="bg-red-100 cursor-grab mt-2 mb-1 w-full  items-start overflow-x-none overflow-y-none flex flex-row justify-center "
  >
    <div class="flex flex-col items-start w-full    ">
      <div
        v-if="properties?.option?.title"
        class="w-full text-sm text-left text-gray-700 font-bold"
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option?.description"
        class=" w-full  text-xs text-left text-gray-700 font-normal"
      >
        {{ properties?.description }}
      </div>
      <div class=" min-h-full   w-full   h-20 bg-yellow-200  overflow-y-hidden pb-2 pt-1 flex flex-row">
        <div
          v-for="section in properties.components"
          :key="section.id"
          class="w-full px-1 bg-blue-100"
        >
          <div
            v-if="section.isVisible == undefined || section.isVisible"
            :id="`editor-section-${record?.id}`"
            class="relative group   cursor-pointer mb-4 pb-2   ring-transparent "
            :class="{'ring-blue-600 ring-2' : (section?.id == selected?.id), 'ring-blue-600 ring-1': (hovered?.id == section?.id)}"
            @click="onItemClick($event, 'body', section)"
            @mouseover="onMouseOver(section)"
            @mouseleave="onLeave(section)"
          >
            <component
              :is="mapComponent(section.type,section.action)"
              :id="section.id"
              class="w-full"
              :properties="section"
              :selected="selected"
              @item-click="onSubItemClick"
              @section-hover="onHover"
            />
        

            <div
              class="absolute -bottom-4 w-full opacity-0  group-hover: -bottom-2   text-blue text-xs p-1 flex items-center flex justify-center  "
              :class="{'opacity-100' : (section?.id == selected?.id), 'opacity-100': (hovered?.id == section?.id)}"
            >
              >
              <div class="  bg-blue-700 w-6  h-6 rounded-full flex justify-center items-center">
                <i class="  text-white fa-solid   shrink-0   fa-plus  fa-xs bg-blue-700" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import componentImage from '@/layout/Editor/Components/componentImage.vue'
    import componentAvatar from '@/layout/Editor/Components/componentAvatar.vue'
    import componentTitle from '@/layout/Editor/Components/componentTitle.vue'
    import componentScanCode from '@/layout/Editor/Components/componentScanCode.vue'
    import componentScanDoc from '@/layout/Editor/Components/componentScanDoc.vue'
    import componentNumber from '@/layout/Editor/Components/componentNumber.vue'
    import componentDate from '@/layout/Editor/Components/componentDate.vue'
    import componentRadio from '@/layout/Editor/Components/componentRadio.vue'
    import componentHTML from '@/layout/Editor/Components/componentHtml.vue'
    import componentEdit from '@/layout/Editor/Components/componentEdit.vue'
    import componentSeparator from '@/layout/Editor/Components/componentSeparator.vue'
    import componentSignature from '@/layout/Editor/Components/componentSignature.vue'
    import componentTakePhoto from '@/layout/Editor/Components/componentTakePhoto.vue'
    import componentButton from '@/layout/Editor/Components/componentButton.vue'
    import componentList from '@/layout/Editor/Components/componentList.vue'



    export default {
        name: 'SignatureTableFormComponent', components: {
            componentTitle,
            componentEdit,
            componentSeparator,
            componentAvatar,
            componentDate,
            componentImage,
            componentRadio,
            componentSignature,
            componentHTML,
            componentScanCode,
            componentScanDoc,
            componentNumber,
            componentButton,
            componentTakePhoto,
            componentList
        },
        props: {
            properties: Object,
            isSelected: Boolean,
            selected: Object,
            index: Number,
            record: Object,

        },
        data() {
            return {
                showheader: false,
                hovered: null
            }
        },
        computed:
        {
            getStyle: function () {
                if (this.properties.height == undefined) return "height: 250px"

                if (this.properties.height == 'full') return "height: 650px"

                return "height: " + this.properties.height + "px"

            }

        }, watch: {

            "record": function () {


                console.log("EditorBody", "watch", "record")

                //  let sections = this.record?.properties?.pages[0].components

                //   if (sections == undefined) {
                //    this.sections = []
                //  }
                //  else {
                //     this.sections = sections
                //  }

            },

            "properties": function () {
                console.log("EditorBody", "watch", "sections")
            },

            "selected": function () {
                console.log("EditorBody", "watch", "selected")
            }
        },


        methods:
        {
            onLeave: function () {
                event.stopPropagation()
                this.hovered = null
            },
            onMouseOver: function (item) {
                event.stopPropagation()
                console.log("onMouseOver", item.ud)
                this.hovered = item
                this.$emit("sectionHover", { hover: item, zone: 'body' })

            },

            onItemClick: function (event, zone, section) {

                event.stopPropagation()
                section.zone = zone
                this.$emit("itemClick", { section: section, zone: 'body' })

                return false
            },

            mapComponent: function (type, action) {
                if (type == 'title') return componentTitle;
                if (type == 'scan-code') return componentScanCode;
                if (type == 'scan-qrcode') return componentScanCode;

                if (type == 'scan-barcode') return componentScanCode;

                if (type == 'list') return componentList;
                if (type == 'image') return componentImage;

                if (type == 'scan-doc') return componentScanDoc;
                if (type == 'number') return componentNumber;
                if (type == 'radio') return componentRadio;
                if (type == 'button') {
                    if (action == "take-photo") return componentTakePhoto;

                    return componentButton;

                }
                if (type == 'avatar') return componentAvatar;
                if (type == 'date') return componentDate;
                if (type == 'html') return componentHTML;
                if (type == 'hand-signature') return componentSignature;
                if (type == 'separator') return componentSeparator;
                if (type == 'validate') return componentButton;
                if (type == 'edit') return componentEdit;

            },

        }
    }
</script>
<template>
  <div class="  w-full flex flex-wrap py-1 relative  ">
    <label
      v-for="section in source?.sections"
      :key="section.title" 
      class="mb-4 w-full text-left block   dark:text-white px-2.5"
    >
               
               
      <div class="text-base font-bold text-gray-100 bg-gray-800 px-2.5 ">{{ section.title }}</div>      



      <div  
        v-for="control in section?.controls"
        :key="control.id" 
        class="w-full flex flex-row items-center bg-white text-sm    font-standard   py-1  pl-2   "
      >


        <div
          v-if="control.status=='OK'"
          class="bg-lime-500 rounded-full w-8 h-3  "
        />
        <div
          v-if="control.status=='KO'"
          class="bg-red-500 rounded-full w-8 h-3  "
        />
        <div
          v-if="control.status=='WA'"
          class="bg-orange-400 rounded-full w-8 h-3  "
        />



        <div class=" ml-2 text-gray-900 font-standard bg-red w-full">{{ control.title }}</div>
                
        <div class=" text-gray-500 font-standard text-right w-96 pr-3">{{ control.comment }}</div>

      </div>




    </label>
  </div>
  <div
    v-if="comment != ''"
    class="hidden flex items-center w-full max-w-lg p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
    role="alert"
  >
    <i class="fa-solid fa-triangle-exclamation" />
    <div class="pl-4 text-sm font-normal">
      {{ comment }}
    </div>
  </div>
</template>

<script>
    import Handlebars from "handlebars";
    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue";
    import common_labels from "@/assets/lg/common.json"
    export default {
        name: 'ContentCompliant',
        props: {
            id: String,
            label: String,

            required: Boolean,
            value: String,
            file: String,
            comment: String,
            source: String

        },
        async mounted() {

            this.output = this.value;
        },

        methods: {
            async onClickTranslate() {


                let response = await service.translate("EN", this.output, this.user?.token)

                this.output = response.text[0].text

            }, getValue(col, record) {

                const template = Handlebars.compile(col.data);
                let value = (template(record));
                let displayValue = value;


                if (col?.map != undefined) {
                    if (col?.map[this.lg] != undefined) {
                        if (col?.map[this.lg][value] != undefined) {
                            displayValue = col?.map[this.lg][value]
                        }
                    }
                }

                if (col.type == "text") return displayValue;
                if (col.type == "date") return displayValue;
                if (col.type == "date-time") return displayValue;
                if (col.type == "time") return displayValue;

                if (col.type == "badge") {

                    let color = "gray"
                    if (col?.colors != undefined)
                        if (col?.colors[value] != undefined) {
                            color = col?.colors[value]
                        }
                    let html = '<span class="bg-' + color + '-100 text-' + color + '-800 border border-' + color + '-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 ">' + displayValue + '</span>';
                    return html;
                }

                return value
            },

            getData() {
                return this.output;
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        components: {},
        data() {
            return {
                output: "",
                common_labels: common_labels,

                lg: "FR"

            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        }
    }
</script>
<template>
  <div class="bg-green-200">
    <TopNav
      :record="record"
      @save="onSavePage"
      @exit="onExit"
    />
    <SideNav
      :properties="record"
      :sections="sections"
      :footer="footer"
      :selected="selectedSection"
      @select="onSelectSection"
      @select-sub-section="onSelectSubSection"
      @add-section="onAddSection"
      @add-sub-section="onAddSubSection"
      @delete-section="onDeleteSection"
      @move-section="onMoveSection"
      @move-sub-section="onMoveSubSection"
      @update-section="onUpdateSection"
    />
    <RightSideNav
      :selected-section="selectedSection"
      :record="record"
      @delete-section="onDeleteSection"
      @update-section="onUpdateSection"
    />
    <EditorBody
      mode="smartphone"
      :record="record"
      :selected="selectedSection"
      :sections="sections"
      @item-click="onSectionClick"
    />
  </div>
</template>

<script>
  import TopNav from '@/layout/Editor/TopNavEditor.vue'
  import SideNav from '@/layout/Editor/SideNavEditor.vue'
  import RightSideNav from '@/layout/Editor/SideRightNavEditor.vue'
  import EditorBody from '@/layout/Editor/EditorBody.vue'

  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  import { unflatten, flatten } from 'flat'

  export default {
    name: 'EditorLayout',
    components: {
      SideNav, TopNav, RightSideNav, EditorBody
    },
    data() {
      return ({
        record: {},
        footer: {
          left: [],
          right: [],
          center: []
        },
        data: { sections: [] },
        sections: [],

        selectedSection: null
      })
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    watch: {
      '$route.params.id': async function () {
        await this.loadForm()
      }
    },
    async mounted() {
      await this.loadForm()
    },
    methods:
    {


      loadForm: async function () {
        let id = this.$route.params.id;

        if (this.user == undefined) {
          //this.$router.push("/login");
          window.location = "/login?redirect=/admin/editor/forms/" + id;
          return;
        }
  
        let r = await service.getDataId("workflow-forms", id, this.user.token, {})
        this.record = r;

        this.sections = this.record?.properties?.pages[0]?.components
      },
      onMoveSection: function (event) {
        console.log("onMoveSection ----------------------------------------")
        console.log(JSON.stringify(event))

        if (event.zone == "body")
          this.record?.properties?.pages[0]?.components?.splice(event.newIndex, 0, this.record?.properties?.pages[0]?.components?.splice(event.oldIndex, 1)[0]);

      },
      onMoveSubSection: function (event) {
        console.log("onMoveSubSection ----------------------------------------")
        console.log(JSON.stringify(event))

        if (event.zone == "body") {
          let section = this.record?.properties?.pages[0]?.components?.find(function (v) { return v.id == event.parent })
          console.log(section)
          if (section != undefined)

            section.components?.splice(event.newIndex, 0, section?.components?.splice(event.oldIndex, 1)[0]);

        }

      },

      onDeleteSection: function (event) {

        //   console.log("onDeleteSection")
        if (event == undefined) { console.log("1"); return; }
        if (this.record == undefined) { console.log("2"); return; }
        if (this.record?.properties?.pages == undefined) { console.log("3"); return; }



        console.log(JSON.stringify(event))
        {
          let index = this.record?.properties?.pages[0]?.components?.findIndex(function (v) { return v.id == event.id })

          //    console.log("components", index)

          if (index != -1) {
            //    console.log("Delete a component - Before", this.record?.properties?.pages[0]?.components.length)
            this.selectedSection = null
            const arrayOfLetters = this.record?.properties?.pages[0]?.components

            const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

            const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
            const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
            this.record.properties.pages[0].components = copyWithoutThirdElement
            this.record.updated_at = new Date()
            //      console.log("Delete a component - After", this.record?.properties?.pages[0]?.components.length)
            this.sections = this.record?.properties?.pages[0]?.components
            return;
          }

        }

        {
          let index = this.record?.properties?.pages[0].submit.areas?.left?.findIndex(function (v) { return v.id == event.id })
          if (index != -1) {
            this.selectedSection = null
            const arrayOfLetters = this.record?.properties?.pages[0].submit.areas?.left

            const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

            const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
            const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
            this.record.properties.pages[0].submit.areas.left = copyWithoutThirdElement
          }
          //     console.log("this.record?.properties?.pages[0].submit.areas?.left", index)
        }

        {
          let index = this.record?.properties?.pages[0].submit.areas?.center?.findIndex(function (v) { return v.id == event.id })
          if (index != -1) {
            this.selectedSection = null
            const arrayOfLetters = this.record?.properties?.pages[0].submit.areas?.center

            const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

            const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
            const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
            this.record.properties.pages[0].submit.areas.center = copyWithoutThirdElement
          }
          //     console.log("this.record?.properties?.pages[0].submit.areas?.center", index)
        }

        {
          let index = this.record?.properties?.pages[0].submit.areas?.right?.findIndex(function (v) { return v.id == event.id })
          if (index != -1) {
            this.selectedSection = null
            const arrayOfLetters = this.record?.properties?.pages[0].submit.areas?.right

            const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

            const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
            const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
            this.record.properties.pages[0].submit.areas.right = copyWithoutThirdElement
            //     console.log("this.record?.properties?.pages[0].submit.areas?.right", index)

          }
        }

        //    console.log(JSON.stringify(this.record?.footer))
        return true
      },
      onUpdateSection: function (event) {
        console.log("onUpdateSection -------------------------EditorLayout")
        console.log(JSON.stringify(event))

        {
          let index = this.record?.properties?.pages[0]?.components?.findIndex(function (v) { return v.id == event.id })
          //  console.log("Body ", index)
          if (index != -1) {

            if (event.att == "_events") {
              this.record.properties.pages[0].components[index]._events = event.value

              return;
            }
            if (event.att == "_controls") {
              this.record.properties.pages[0].components[index]._controls = event.value

              return;
            }
            let f = flatten(this.record.properties.pages[0].components[index])


            f[event.att] = event.value
            let u = unflatten(f)
            //      console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv")
            //      console.log(event.att)
            //      console.log(JSON.stringify(event.value))
            //      console.log(JSON.stringify(u))
            //      console.log("composant ", index)
            // console.log({...this.record.properties.pages[0].components[index], ...att})

            this.record.properties.pages[0].components[index] = u
            return;
          }
        }
        {
          let listComponents = this.record?.properties?.pages[0]?.components?.filter(function (v) { return v.type == 'list' })

          //  console.log("Body ", index)
          if (listComponents.length > 0) {


            for (let y = 0; y < listComponents.length; y++) {


              let index = listComponents[y].components?.findIndex(function (v) { return v.id == event.id })
              //  console.log("Body ", index)
              if (index != -1) {

                let f = flatten(listComponents[y].components[index])
                f[event.att] = event.value
                let u = unflatten(f)
                // console.log(event.value)
                // console.log(JSON.stringify(u))
                // console.log({...this.record.properties.pages[0].components[index], ...att})

                listComponents[y].components[index] = u
                return;
              }
            }
          }
        }
        {
          let index = this.record?.properties?.pages[0].submit.areas.left.findIndex(function (v) { return v.id == event.id })
          //    console.log("left ", index)
          if (index != -1) {
            this.record.properties.pages[0].submit.areas.left[index][event.att] = event.value
          }
        }

        {
          let index = this.record?.properties?.pages[0].submit.areas.center.findIndex(function (v) { return v.id == event.id })
          //    console.log("center ", index)
          if (index != -1) {
            this.record.properties.pages[0].submit.areas.center[index][event.att] = event.value
          }
        }


        {
          let index = this.record?.properties?.pages[0].submit.areas.right.findIndex(function (v) { return v.id == event.id })
          //      console.log("Right ", index)
          if (index != -1) {
            this.record.properties.pages[0].submit.areas.right[index][event.att] = event.value
          }
        }



      },
      onAddSection: function (event) {

        //     console.log("onAddSection")
        //    console.log(JSON.stringify(event, null, '\t'))


        if (event.zone == "body") {
          //       console.log("Zone  BODY")
          if (event.after == undefined) {
            delete event.section.images

            this.record?.properties?.pages[0]?.components.push(event.section)

          }
          else {
            let index = this.record?.properties?.pages[0]?.components?.findIndex(function (v) {


              //            console.log(v.id, event.after, v.id == event.after)
              return v.id == event.after
            })
            //           console.log("Body Find index of after = ", index, this.record?.properties?.pages[0]?.components.length)
            if (index != -1) {

              this.record?.properties?.pages[0]?.components?.splice(index + 1, 0, event.section);
            }
            else {
              console.log("ERROR ERROR ERROR ERROR ",)
              console.log("*", JSON.stringify(this.record?.properties?.pages[0]?.components), "/")
            }


          }
        }
        if (event.zone == "footer") {



          if (event.area == "left") {
            //           console.log("onAddSection", event.section, event.area)
            if (event.after == undefined) {
              delete event.section.images
              this.record?.properties?.pages[0].submit.areas.left.push(event.section)
            }
            else {
              let index = this.record?.properties?.pages[0].submit.areas.left.findIndex(function (v) { return v.id == event.after })
              //           console.log("left Find index of after = ", index)
              this.record?.properties?.pages[0].submit.areas.left.splice(index + 1, 0, event.section);
            }
          }
          if (event.area == "center") {

            //          console.log("onAddSection", event.section, event.area)


            if (event.after == undefined) {
              delete event.section.images
              this.record?.properties?.pages[0].submit.areas.center.push(event.section)
            }
            else {
              let index = this.record?.properties?.pages[0].submit.areas.center.findIndex(function (v) { return v.id == event.after })
              //            console.log("center Find index of after = ", index)
              this.record?.properties?.pages[0].submit.areas.center.splice(index + 1, 0, event.section);
            }
          }
          if (event.area == "right") {
            //          console.log("onAddSection", event.section, event.area)

            if (event.after == undefined) {
              delete event.section.images
              this.record?.properties?.pages[0].submit.areas.right.push(event.section)
            }
            else {
              let index = this.record?.properties?.pages[0].submit.areas.right.findIndex(function (v) { return v.id == event.after })
              //             console.log("Right Find index of after = ", index)
              this.record?.properties?.pages[0].submit.areas.right.splice(index + 1, 0, event.section);
            }
          }

        }
        this.selectedSection = event.section
        // 

      },



      onAddSubSection: function (event) {

        //       console.log("onAddSubSection")
        ////       console.log(JSON.stringify(event, null, '\t'))


        if (event.zone == "body") {

          let parent = this.record?.properties?.pages[0]?.components?.find(function (v) { return v.id == event.parent })

          if (event.after == undefined) {
            delete event.section.images
            parent?.components?.push(event.section)

          }
          else {
            let index = this.record?.properties?.pages[0]?.components?.findIndex(function (v) { return v.id == event.after })
            //          console.log("Find index of after = ", index)
            if (index != 1) {

              this.record?.properties?.pages[0]?.components?.splice(index + 1, 0, event.section);
            }
            else {
              console.log("ERROR ERROR ERROR ERROR ",)
            }


          }
        }

      },


      onSelectSection: function (section) {
        //     console.log("onSelectSection --------------------------------")

        //     console.log(JSON.stringify(this.record?.footer))
        //     console.log(JSON.stringify(section))

        {


          let source = this.record?.properties?.pages[0].components
          let index = source?.findIndex(function (v) { return v.id == section.id })

          //let index = this.record?.sections?.findIndex(function (v) { return v.id == section.id })
          //      console.log("body", index)
          if (index != -1)
            this.selectedSection = source[index]
        }
        {
          let index = this.record?.properties?.pages[0].submit.areas.left.findIndex(function (v) { return v.id == section.id })
          //       console.log("left", index)
          if (index != -1)
            this.selectedSection = this.record?.properties?.pages[0].submit.areas.left[index]
        }

        {
          let index = this.record?.properties?.pages[0].submit.areas.center.findIndex(function (v) { return v.id == section.id })
          //       console.log("center", index, section.id)
          //       console.log(JSON.stringify(this.record?.footer))
          if (index != -1)
            this.selectedSection = this.record?.properties?.pages[0]?.submit.areas.center[index]
        }

        {
          let index = this.record?.properties?.pages[0]?.submit.areas.right.findIndex(function (v) { return v.id == section.id })
          //        console.log("right", index)
          if (index != -1)
            this.selectedSection = this.record?.properties?.pages[0]?.submit.areas.right[index]
        }


      },

      onSelectSubSection: function (section) {
        //       console.log("onSelectSubSection --------------------------------")

        //     console.log(JSON.stringify(this.record?.footer))
        //     console.log(JSON.stringify(section))
        //     console.log(JSON.stringify(this.record?.properties?.pages[0].components))
        {


          let source = this.record?.properties?.pages[0].components
          let item = source?.find(function (v) { return v.id == section.parent })
          //       console.log("find", item?.id)
          let index = item?.components?.findIndex(function (v) { return v.id == section.id })
          //        console.log("body sub", index)
          if (index != -1)
            this.selectedSection = item.components[index]

          //       console.log(this.selectedSection.id)
        }
      },


      onExit: function () {
        this.$router.go(-1)
      },
      onSavePage: async function () {
 
        let id = this.$route.params.id;
        let response = await service.updateRecord("workflow-forms", id, this.user.token, this.record)
         console.log(response)
      },
      onSectionClick: function (section) {

        console.log(JSON.stringify(section))
        if (section.zone == "body")
          this.selectedSection = section


        if (section.zone == "footer")
          this.selectedSection = section
      }
    }
  }
</script>
<template>
  <div class="w-full mt-12 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start  ">
    <div id="myFormTabContent">
      <div
        id="tab-properties-content"
        class="  p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="tab-properties-tab"
      >
        <div class="mb-6 flex flex-col items-start justify-start w-full">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">

            Titre de la colonne


          </label>

          <div
            v-if="currentItem.name != undefined"
            class="flex flex-col items-center   justify-start"
          >
            <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
              <div
                class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
              >
                <div class="text-sm  text-gray-50">
                  FR
                </div>
              </div>
              <input
                v-model="currentItem.name.FR"
                class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              >
            </div>
            <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
              <div
                class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
              >
                <div class="text-sm  text-gray-50">
                  EN
                </div>
              </div>
              <input
                v-model="currentItem.name.EN"
                class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              >
            </div>
            <div class="flex justify-start flex-row w-full mt-2 ml-6 items-center">
              <input
                id="checked-checkbox"
                v-model="currentItem.name.displayTitle"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              >
              <label
                for="checked-checkbox"
                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Afficher le
                titre</label>
            </div>
          </div>
        </div>

        <div class="mb-1 flex flex-col items-start justify-start w-6/12">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Type {{ currentItem.type }}</label>
          <select
            id="default"
            v-model="currentItem.type"
            class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option
              v-for="type in types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.value[lg] ? type.value[lg] : type.value }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 
 
    export default {
        name: 'DeveloperCellButton',
        components: {
           
        },
        props: {
            item: Object
        },

        data() {
            return ({
                lg: "FR",
                currentItem: {},
             
              
                types: [
                    {
                        id: "text",
                        value: {
                            "EN": "Text",
                            "FR": "Texte"
                        }
                    },
                    {
                        id: "date",
                        value: {
                            "EN": "Date",
                            "FR": "Date"
                        }
                    },
                    {
                        id: "date-time",
                        value: {
                            "EN": "Date & Hour",
                            "FR": "Date & Heure"
                        }
                    },
                    {
                        id: "badge",
                        value: {
                            "EN": "Badge",
                            "FR": "Badge"
                        }
                    },
                    {
                        id: "image-title-subtitle",
                        value: {
                            "EN": "Text Field",
                            "FR": "Image & Text"
                        }
                    },
                    {
                        id: "button",
                        value: {
                            "EN": "Button",
                            "FR": "Bouton"
                        }
                    } 
                ],

            })
        },
        computed: {
           
        },
        watch: {
            item: function (val) {
                let item = val;
 



                this.currentItem = item;

            }
        },
        mounted() {

            let item = this.item;
 

            this.currentItem = item;
            
        },

        methods: {
            
        }

    }
</script>
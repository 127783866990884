<template>
  <div class="list-group mt-2  w-full flex items-start flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm  font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Nombre
          d'élements (Nombre de lignes : {{ max }})</label>

        <div class="w-full flex flex-col   mb-2">
          <div class="relative w-full flex flex-row w-full px-12 ">
            <div class="start-0 absolute  flex   -bottom-8  left-2">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900"
              >0</label>
            </div>
            <div class="start-1/4 absolute flex justify-center -bottom-8">
              <label
                for="steps-range"
                class="block text-2xs font-medium text-gray-900"
              >5</label>
            </div>
            <div class="start-2/4 absolute flex justify-center  -bottom-8">
              <label
                for="steps-range"
                class="block  text-2xs font-medium text-gray-900 "
              >10</label>
            </div>
            <div class="start-3/4 absolute flex justify-center  -bottom-8 ">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900 -left-4 "
              >15</label>
            </div>
            <div class="end-0 absolute  flex justify-end  -bottom-8 ">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >20</label>
            </div>
          </div>
          <input
            id="photo-max-range"
            v-model="max"
            type="range"
            min="0"
            max="20"
            step="1"
            class="ml-1 mr-1 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            @change="onChangeMax"
          >
        </div>
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm  font-medium text-gray-900 dark:text-white"
        >Options</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description</label>
          </div>
        </div>
      </div>
      <div
        class="hidden flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center"
      >
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponentDetails',
        props: {
            properties: Object,
            index: Number,
        },
        data() {
            return ({
                title: "",
                max: 5,
                description: "",
                attribute: "",
                template: "title-tpl-1",
                isTitleDisplayed: false,
                isDescriptionDisplayed: false
            }
            )
        },
        watch:
        {
            properties: function () {
                this.title = this.properties.title;
                this.description = this.properties?.description;
                this.attribute = this.properties?.attribute;
                if (this.properties?.template != undefined)
                    this.template = this.properties?.template
                this.max = 5;
                if (this.properties?.max != undefined)
                    this.max = this.properties?.max
                this.isTitleDisplayed = this.properties?.option?.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.description = this.properties?.description;

            this.description = this.properties?.description;
            this.attribute = this.properties?.attribute;

            if (this.properties?.template != undefined)
                this.template = this.properties?.template


            this.max = 5;
            if (this.properties?.max != undefined)
                this.max = this.properties?.max

            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;

        },
        methods:
        {
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.title', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.description', value: this.isTitleDisplayed })
            },
            onChangeTemplate: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'template', value: this.template })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },

            onChangeMax: function ( ) {
                console.log(this.max)
               
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'max', value: parseInt(this.max) })
            },
            onInputAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'attribute', value: this.attribute })
            },


            onInputDescription: function () {

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            }
        }

    }
</script>
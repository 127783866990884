<template>
  <div class="w-full h-96 overflow-y-auto">
    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <button
        type="button"
        class="flex items-center justify-center w-full p-5 font-medium text-gray-500            gap-3"
        @click="reloadDate"
      >
        Relaod
      </button>

      <div class=" w-full grid gap-4 mb-4 sm:grid-cols-1">
        <div
          v-for="(filter,index) in filters"
          :key="filter.id"
          class=" group max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  bg-orange-100"
        >
          <nav class=" mb-1 dark:bg-gray-700">
            <div class="max-w-screen-xl px-2 py-1 mx-auto">
              <div class="flex items-center ">
                <ul
                  class="w-full flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm "
                >
                  <li class="w-full   flex flex-row items-center">
                    <svg
                      class="w-[16px] h-[16px] text-gray-300 dark:text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    ><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                      <path
                        d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z"
                      />
                    </svg>

                    <svg
                      class="w-[16px] h-[16px] text-gray-600 dark:text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    ><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                      <path
                        d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z"
                      />
                    </svg>
                    <div
                      href="#"
                      class="ml-2 text-left text-gray-900 dark:text-white hover:underline"
                      aria-current="page"
                    >
                      {{ filter.id }}
                    </div>
                  </li>


                  <li class="    flex items-center">
                    <label
                      class="hidden items-center  cursor-pointer  hidden   group-hover:inline-flex "
                    >
                      <input
                        type="checkbox"
                        value=""
                        class="sr-only peer"
                      >
                      <div
                        class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                      />
                      <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300" />

                    </label>

                    <div
                      href="#"
                      class="text-gray-600 dark:text-white hover:underline"
                    >
                      <svg
                        class="w-[18px] h-[18px] text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          -- {{ filter.items.length }} -- {{ index }} --
          <div
            :id="`accordion-color-${index}`"
            data-accordion="collapse"
            data-active-classes2="bg-white dark:bg-gray-800 text-blue-600 dark:text-white"
          >
            <div
              :id="`accordion-color-body-1-${index}`"
              class="hidden "
              :aria-labelledby="`accordion-color-heading-1-${index}`"
            >
              <ul
                class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 justify-start text-left"
                aria-labelledby="dropdownSearchButton"
              >
                <li
                  v-for="item in filter.items"
                  :key="item.id"
                >
                  <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <input
                      id="checkbox-item-11"
                      type="checkbox"
                      value=""
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    >
                    <label
                      for="checkbox-item-11"
                      class="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300 "
                    >

                      {{ item.name }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            <h2 :id="`accordion-color-heading-1-${index}`">
              <button
                type="button"
                class="flex items-center justify-center w-full p-5 font-medium text-gray-500            gap-3"
                :data-accordion-target2="`#accordion-color-body-1-${index}`"
                aria-expanded="true" 
                :aria-controls="`accordion-color-body-1-${index}`"
                @click="manageAccordeon(index)"
              >
                <svg
                  data-accordion-icon
                  class="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import { Accordion } from 'flowbite';
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'FiltersEditDiagramModal',
        props: {
            input: Object

        },
        data() {
            return ({
                filters: [],
                items: [],
                accordeons: []
            })
        },
        watch: {


        },

        mounted() {


            this.loadDistinctValues()



        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {



            manageAccordeon(index) {
          


                if (this.accordeons[index] != undefined) {
            
                 //   this.accordeons[index].open('accordion-color-heading-1-0');
                    return
                }
            
                const accordionElement = document.getElementById('accordion-color-' + index);
                if (accordionElement == undefined) return;
                const accordionItems = [
                    {
                        id: 'accordion-color-heading-1-0',
                        triggerEl: document.querySelector('#accordion-color-heading-1-0'),
                        targetEl: document.querySelector('#accordion-color-body-1-0'),
                        active: true
                    },
                    /*    {
                            id: 'accordion-color-heading-1',
                            triggerEl: document.querySelector('#accordion-color-heading-1'),
                            targetEl: document.querySelector('#accordion-color-body-1'),
                            active: false
                        } */
                ];

                // options with default values
                const options = {
                    alwaysOpen: true,
                    activeClasses: 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white',
                    inactiveClasses: 'text-gray-500 dark:text-gray-400',
                    onOpen: (item) => {
                        console.log('accordion item has been shown');
                        console.log(item);
                    },
                    onClose: (item) => {
                        console.log('accordion item has been hidden');
                        console.log(item);
                    },
                    onToggle: (item) => {
                        console.log('accordion item has been toggled');
                        console.log(item);
                    },
                };

                // instance options object
                const instanceOptions = {
                    id: 'accordion-color-0',
                    override: true
                };
                // const accordion = 
                this.accordeons[index] = new Accordion(accordionElement, accordionItems, options, instanceOptions);
                //   accordion.open('accordion-color-heading-1-0');
            },
            async reloadDate() {
         
                await this.loadDistinctValues()
            },
            async loadDistinctValues() {



                let filters = []

                if (this.input?.items?.length > 0)

                    filters = this.input?.items[0].source.filters;


                for (let u = 0; u < filters.length; u++) {
                    let id = "66499ba5b8a2cab975d67f5e"
                    filters[u].items = []
                    let lfilter = {}
                    let lbody = { "parameters": { "__alias_0": "$" + filters[u].id } }
                    try {
                        let response = await service.generateReport("data-sources/" + id + '/charts', this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, lfilter), lbody)

                        if (response != null)
                            filters[u].items = response.records
                        // for (let u = 0; u < response.length; u++) {
                        //     sections.push(response[u])
                        // }

                    }
                    catch (e) {
                        console.log(e)
                    }
                }
                this.filters = filters
                console.log("-----------------------------------------------")
                console.log(filters)
                /*[

                    {
                        id: "1",
                        name: "1111",
                        op: "222"

                    },
                    {
                        id: "2",
                        name: "1111",
                        op: "222"

                    }

                ]*/



            }

        },
        components: {

        }
    }
</script>
<template>
  <div class="bg-gray-100 w-full p-6">
    <div class="mb-6 flex flex-col items-start justify-start">
      Options pour champ de Saisie

      <div class="mb-6 flex flex-col items-start justify-start w-full">
        <label
          for="password"
          class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >
          Libellé </label>

        <div
          v-if="currentItem.label != undefined"
          class="flex flex-col items-center   justify-start"
        >
          <div class="flex flex-row justify-start items-center mb-1 w-96">
            <span class="w-9 mr-2 text-sm">FR</span>
            <input
              v-model="currentItem.label.FR"
              type="text"
              class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            >
          </div>
          <div class="flex flex-row justify-start items-center w-96">
            <span class="w-9 mr-2 text-sm">EN</span>
            <input
              v-model="currentItem.label.EN"
              type="text"
              class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            >
          </div> 
        </div>  
      </div> 
    </div>
  </div>
</template>

<script>

    export default {
        name: 'DeveloperItemFileList',
        props: {
            item: Object
        },
        data() {
            return ({
                currentItem: {}
            })
        },
        watch: {
            item: function (val) {
                let item = val;

                this.currentItem = item;

            }
        },



        mounted() {

            let item = this.item;

            this.currentItem = item;
           

        }

    }
</script>
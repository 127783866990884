<template>
  <div class="w-full bg-red-300">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres (display)
    </h3>
    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Format de
          l'affichage</label>
        <select
          id="format"
          v-model="format"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @change="onChangeFormat"
        >
          <option
            v-for="f in formats"
            :key="f"
          >
            {{ f }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="format=='image-title-subtitle'"
      class=" w-full grid gap-4 mb-4 sm:grid-cols-2"
    >
      <div class="flex flex-col w-full pr-6">
        <label
          for="template"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Modèle
          d'affichage</label>
        <select
          id="template"
          v-model="template"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @change="onChangeTemplate"
        >
          <option
            v-for="t in templates"
            :key="t"
          >
            {{ t }}
          </option>
        </select>
      </div>
    </div>

    <div
      v-if="format=='image-title-subtitle'"
      class="grid gap-4 mb-4 sm:grid-cols-2"
    >
      <div>
        <label
          for="DisplayImageURL"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >URL de
          l'image
          en Français</label>
        <input
          id="DisplayImageURL"
          v-model="image_url"
          type="text"
          name="DisplayImageURL"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder=""
          @input="onChangeImageURL"
        >
      </div>
      <div class=" ">
        <label
          for="DisplayImageClass"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Classe de l'image</label>
        <input
          id="DisplayImageClass"
          v-model="image_class"
          type="text"
          name="DisplayImageClass"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder=""
          @input="onChangeImageClass"
        >
      </div>
    </div>



    <div
      v-if="format=='image-title-subtitle'"
      class="grid gap-4 mb-4 sm:grid-cols-2"
    >
      <div>
        <label
          for="DisplayTitle"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre
          s</label>
        <input
          id="DisplayImageURL"
          v-model="data"
          type="text"
          name="DisplayImageURL"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder=""
          @input="onChangeData"
        >
      </div>
      <div class=" ">
        <label
          for="DisplaySubTitle"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Sous-Titre
        </label>
        <input
          id="DisplayImageClass"
          v-model="subdata"
          type="text"
          name="DisplayImageClass"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder=""
          @input="onChangeSubData"
        >
      </div>
    </div>


    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Categorie
          output = {{ output }} category={{ category }}</label>
        <select
          id="category"
          v-model="output"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @change="onChange"
        >
          <option
            v-for="value in values"
            :key="value"
          >
            {{ value.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'DisplayField',
        components: {

        },
        props: {
            values: Array,
            input: String
        },
        data() {
            return ({

                output: "",
                format: "",
                formats: [ "separator","text", "image", "date", "date-time", "image-title-subtitle"],

                templates: ["image-title-subtitle-1", "image-title-subtitle-2"],
                template: "",
                data: "",
                subdata: "",
                image_url: "",
                image_class: "",
                text_class: ""
            })
        },
        watch: {

            category: function () {


                this.data = this.input?.data;
                this.image_url = this.input?.image_url;
                this.format = this.input?.format;
                this.subdata = this.input?.subdata;
                this.image_url = this.input?.image_url;
                this.image_class = this.input?.image_class;
                this.text_class = this.input?.text_class;
                this.template = this.input?.template;

            }
        },

        mounted() {
         //   console.log("EditField Mounted")

            this.data = this.input?.data;
            this.image_url = this.input?.image_url;
            this.format = this.input?.format;
            this.subdata = this.input?.subdata;
            this.image_url = this.input?.image_url;
            this.image_class = this.input?.image_class;
            this.text_class = this.input?.text_class;
            this.template = this.input?.template;

        },
        methods: {

            onChangeFormat: function () {

                this.$emit("changeValue", { format: this.format, template: this.template, subdata: this.subdata, data: this.data, image_url: this.image_url, image_class: this.image_class, text_class: this.text_class })

            },
            onChangeTemplate: function () {

                this.$emit("changeValue", { format: this.format, template: this.template, subdata: this.subdata, data: this.data, image_url: this.image_url, image_class: this.image_class, text_class: this.text_class })

            },
            onChangeImageUrl: function () {

                this.$emit("changeValue", { format: this.format, template: this.template, subdata: this.subdata, data: this.data, image_url: this.image_url, image_class: this.image_class, text_class: this.text_class })

            },


            onChangeImageClass: function () {

                this.$emit("changeValue", { format: this.format, template: this.template, subdata: this.subdata, data: this.data, image_url: this.image_url, image_class: this.image_class , text_class: this.text_class})

            },


            onChangeData: function () {

                this.$emit("changeValue", { format: this.format, template: this.template, subdata: this.subdata, data: this.data, image_url: this.image_url, image_class: this.image_class, text_class: this.text_class })

            },


            onChangeSubData: function () {

                this.$emit("changeValue", { format: this.format, template: this.template, subdata: this.subdata, data: this.data, image_url: this.image_url, image_class: this.image_class, text_class: this.text_class })

            }

        }
    }
</script>
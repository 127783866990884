<template>
  <div class="p-4 sm:ml-64   ">
    <div class="w-full flex flex-row items-center">
      <div class="w-full flex justify-start text-2xl font-bold flex-col">
        <span class="w-full   text-left justify-start"> {{ displayTitle }}</span>
        <small class="w-full  justify-start text-left"><span> {{ displaySubTitle }}</span></small>
      </div>
      <div class="w-full flex justify-end">
        <button
          class="text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="saveForm"
        >
          Sauvegarder
        </button>
      </div>
    </div>

    <div class="p-6 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
      <div class="grid grid-cols-2 gap-4 mb-4 flex items-start">
        <div
          v-if="record != undefined"
          class="flex flex-col items-start justify-center rounded bg-gray-50  dark:bg-gray-800 overflow-y-auto"
        >
          <div
            :class="{'bg-blue-100': currentItem.id == record.id}"
            class=" text-sm font-bold px-4 cursor-pointer text-gray-900 bg-gray-50 border border-gray-200 rounded-lg shadow"
            @click="onClick(null)"
          >
            Liste
          </div>

          <div class="flex flex-col items-start   ">
            <nested-draggable
              parent="YES"
              :lines="lines"
              :selected-item-id="currentItem.id"
              @end="onNestedMove"
              @click-item="onClick"
            />
          </div>
        </div>

        <div class="flex flex-col items-start justify-start px-6 rounded bg-gray-50 dark:bg-gray-800">
          <h1 class="w-full font-bold mt-2 text-xl">
            Propriétés de l'élement
          </h1>

          <DeveloperList
            v-if="index==0"
            class="w-full bg-yellow-100"
            :item="currentItem"
            @add-tab="onAddTab"
            @update="onUpdateForm"
          />
          <DeveloperCell
            v-if="index==1"
            class="w-full bg-green-100"
            :item="currentItem"
            @add-section="onAddTab"
            @delete="onDelete" 
            @update="onUpdateCell"
          />
        </div>
      </div>






      <div class="hidden bg-green-400 w-full">
        <h1>lines</h1>
        {{ JSON.stringify(lines, null,"\t") }}
      </div>

      <hr>
      <span class="hidden w-full">
        <h1>fulllines</h1>
        {{ JSON.stringify(fulllines, null,"\t") }}
      </span>
      <hr>
      <div class="hidden bg-orange-300 w-full">
        <h1>record</h1>
        {{ JSON.stringify(record.cols, null,"\t") }}
      </div>
      <hr>
      <span class="hidden bg-blue-300 w-full">
        <h1>modele</h1>
        {{ JSON.stringify(model, null,"\t") }}
      </span>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import DeveloperList from "@/components/developer/DeveloperList.vue";
  import DeveloperCell from "@/components/developer/DeveloperCell.vue";
  import { v4 as uuidv4 } from 'uuid';
  import nestedDraggable from "./nested";
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  // eslint-disable-next-line

  //const ID = "64ce6bfe265268079b605508";//"64c9ff81ae9f4714d49d6056";

  export default {
    name: 'DeveloperListView',

    components: {
      // eslint-disable-next-line
      draggable, nestedDraggable, DeveloperList, DeveloperCell
    },
    props: {
      msg: String
    },
    data() {
      return ({
        id: "",

        select_type: "predefined",
        lines: [],
        model: [],
        fulllines: [],
        predefined_records: [],
        record: {},
        lg: "FR",
        currentItem: {},
        index: 0
      })
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      displayTitle: function () {
        if (this.record && this.record.title) {

          return this.record.title[this.lg]
        }
        return ""
      },
      displaySubTitle: function () {
        if (this.record && this.record.id) {
          return this.record.id
        }
        return ""
      }
    },
    watch: {

      'model': function () {

      }

    },
    async mounted() {
      this.id = this.$route.params.id;
      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login?redirect=/admin/developer/forms/" + this.id;
        return;
      }

      this.record = await service.getDataId("ui-lists/fix", this.id, this.user?.token)

      this.fulllines = this.mapData(this.record)

      let response = await service.getData("select-values", this.user?.token, { category: "value-category" })
      if (response != undefined)
        this.predefined_records = response.records;

      this.lines = this.selectData(null, this.fulllines)


      // this.currentItem = this.record;
      //delete this.currentItem.children;



    },



    /*
    
    
     {
                  id: root_node.lines[i].id,
                  type: root_node.lines[i].type,
                  raw: root_node.lines[i].raw,
                  parent: root_node.lines[i].parent,
                  name: (root_node.lines[i].name != "") ? root_node.lines[i].name : (root_node.lines[i].id ? root_node.lines[i].id : "??")
                }
    
     */


    methods: {

      onDelete: function (item) {
      

    
        let indexToRemove = this.lines.findIndex(function(a) { return (a.id == item.id)} )
   
        this.lines.splice(indexToRemove, 1);
        this.setRecordCols(this.lines)
      },


      onAddTab: function () {
        let id =  uuidv4()
        this.lines.push(
          {
            id: id,
            type: "text",
            raw: {
              id: id,
              name: {
                EN: "Nouvelle Colonne",
                FR: "Nouvelle Colonne"
              },
              style: "text-left",
              styleh: "text-left",
              data: "",
              type: "text"

            },
            parent: "",
            name: {
                EN: "Nouvelle Colonne",
                FR: "Nouvelle Colonne"
              }
          }
        )
      },
      onAddCell: function (item) {
     
        let node = this.findNode(item.id, this.lines)
        node.lines.push(
          {
            id: "root_node.lines[i].id",
            type: "column",
            raw: null,
            parent: "",
            name: "nouvelle column"
          }
        )
        this.setRecordCols(this.lines)
        //Selectionner la page
      },

      onUpdateCell: function (/*item*/) {


      },


      onUpdateForm: function (/*item*/) {



      },
      saveForm: async function () {
        let response = await service.updateRecord("ui-lists", this.id, this.user?.token, this.record)
        console.log(response)
      },
      onClick: function (item) {
        if (item == undefined) {

          this.currentItem = this.record;
          // delete this.currentItem.children;

          this.index = 0;
          return;
        }
        let id = item.id


        let children = this.selectData(id, this.fulllines)
        if (children != null) {

          let node = this.findNode(id, this.lines)
          if (node != undefined) {
            if ((node.lines != undefined) && (node.lines.length > 0)) {

              node.lines = null
            }
            else {

              node.lines = children
            }

          }
          //  else {

          //  }

        }


        {
          this.currentItem = item.raw;
          this.index = 1;
        }
      
      },
      findNode: function (node, data) {

        if (node == null) return { lines: data };
        if (Array.isArray(data) == null) return null;

        for (let i = 0; i < data.length; i++) {

          if (data[i].id == node) return data[i];
        }

        for (let j = 0; j < data.length; j++) {

          if (data[j].lines != undefined) {
            let r = this.findNode(node, data[j].lines);

            if (r != null) return r;
          }
        }
        return null;

      },

      findParent: function (node, data) {

        // A REVOIR

        if (node == null) return { lines: data };
        if (Array.isArray(data) == null) return null;

        for (let i = 0; i < data.length; i++) {

          if (data[i].id == node) return data[i];
        }

        for (let j = 0; j < data.length; j++) {

          if (data[j].lines != undefined) {
            let r = this.findNode(node, data[j].lines);

            if (r != null) return r;
          }
        }
        return null;

      },

      selectData: function (root, data) {

        let result = []

        //Find the root
        let root_node = this.findNode(root, data)

        if (root_node == undefined) return null;
        if (root_node.lines == undefined) return null;
        for (let i = 0; i < root_node.lines.length; i++) {
          result.push(
            {
              id: root_node.lines[i].id,
              type: root_node.lines[i].type,
              raw: root_node.lines[i].raw,
              parent: root_node.lines[i].parent,
              name: (root_node.lines[i].name != "") ? root_node.lines[i].name : (root_node.lines[i].id ? root_node.lines[i].id : "??")
            }
          )
        }
        return result
      },
      mapData: function (record) {
        let lines = [];
        for (let i1 = 0; i1 < record.cols.length; i1++) {

          let raw = record.cols[i1];

          lines.push(
            {
              id: record.cols[i1].id,
              name: record.cols[i1].name,
              type: record.cols[i1].type,
              raw: raw,
              parent: ""
            }
          )
        }
        return lines;
      },
      SelectItem: function (item) {
        this.currentItem = item;

        this.index = 100;
      },
      SelectNode: function (index) {
        this.index = index
        return true;
      },
      SelectType: function (index) {
        this.currentItem.type = index
        return true;
      },


      moveArrayElement: function (arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
          let padding = newIndex - arr.length + 1;
          while (padding--) {
            arr.push(undefined);
          }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
      },

      move: function (array, from, to) {

        if (to === from) return array
        /*;
        var target = array[from];
        var increment = to < from ? -1 : 1;
        for (var k = from; k != to; k += increment) {
          array[k] = array[k + increment];
        }
        array[to] = target;
        return array; */




      },
      findChildList: function (parent) {
        if (parent == "") return this.record.children;
        let s = parent.split(",")

        let response = null
        for (let i1 = 0; i1 < this.record.children.length; i1++) {
          let c1 = this.record.children[i1];

          if (s[0] == c1.id) {

            if (s[1] == undefined) {
              return c1.body;
            }
            else {
              for (let i2 = 0; i2 < c1.body.length; i2++) {
                let c2 = c1.body[i2];

                if (s[1] == c2.id) {
                  if (s[2] == undefined) {
                    return c2.items;
                  }
                  else {
                    for (let i3 = 0; i3 < c2.items.length; i3++) {
                      let c3 = c2.items[i3];

                      if (s[2] == c3.id) {
                        return c3.items
                      }
                      else {
                        for (let i4 = 0; i4 < c3.items.length; i4++) {
                          let c4 = c3.items[i4];
                          if (s[3] == c4.id) {
                            return c4.items
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
        return response;
      },
      findParentList: function (parent) {
        if (parent == "") return this.record.children;
        let s = parent.split(",")

        let response = null
        for (let i1 = 0; i1 < this.record.children.length; i1++) {
          let c1 = this.record.children[i1];

          if (s[0] == c1.id) {

            if (s[1] == undefined) {
              return null;
            }
            else {
              for (let i2 = 0; i2 < c1.body.length; i2++) {
                let c2 = c1.body[i2];

                if (s[1] == c2.id) {
                  if (s[2] == undefined) {
                    return c1.body;
                  }
                  else {
                    for (let i3 = 0; i3 < c2.items.length; i3++) {
                      let c3 = c2.items[i3];

                      if (s[2] == c3.id) {
                        return c2.items;
                      }
                      else {
                        for (let i4 = 0; i4 < c3.items.length; i4++) {
                          let c4 = c3.items[i4];
                          if (s[3] == c4.id) {
                            return c3.items
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
        return response;
      },

      setRecordOld: function (parent, list) {

        if (parent == "") return this.record.children;
        let s = parent.split(",")

        let response = null
        for (let i1 = 0; i1 < this.record.children.length; i1++) {
          let c1 = this.record.children[i1];

          if (s[0] == c1.id) {

            if (s[1] == undefined) {

              this.record.children = list

            }
            else {
              for (let i2 = 0; i2 < c1.body.length; i2++) {
                let c2 = c1.body[i2];

                if (s[1] == c2.id) {
                  if (s[2] == undefined) {
                    c1.body = list;
                  }
                  else {
                    for (let i3 = 0; i3 < c2.items.length; i3++) {
                      let c3 = c2.items[i3];
                      if (s[3] == c3.id) {
                        c2.items = list
                      }
                    }
                  }
                }
              }
            }
          }

        }
        return response;
      },

      setRecordCols: function (lines) {

        let cols = []

        for (let i = 0; i < lines.length; i++) {

          cols.push(lines[i].raw)
        }

        this.record.cols = cols
        console.log(JSON.stringify(cols))
        return cols;
      },


      onNestedMove: function (item) {
        console.log("onNestedMove --------------------------")

        this.setRecordCols(this.lines)
        if (item.draggedContext == undefined) return;

        if (item.relatedContext == undefined) return;
        console.log("item.draggedContext index", item.draggedContext.index)
        console.log("item.draggedContext futureIndex", item.draggedContext.futureIndex)

        console.log("item.draggedContext futureIndex", item.draggedContext.element.name)
        console.log("item.relatedContext", item.relatedContext.index)

        //let list = this.findChildList(item.draggedContext.element.parent);
        //console.log(list)

        //if (list == undefined) {
        //  return;
        // }
        //  let lines = this.moveArrayElement(this.lines, item.draggedContext.index, item.draggedContext.futureIndex)


        //        list = this.move(list, item.draggedContext.index, item.relatedContext.index)






        //this.record.children = list;

      },
      reverseMapData: function () {

        let newChildren = [];


        for (let i1 = 0; i1 < this.lines.length; i1++) {
          let newChildren2 = [];
          let child1 = this.lines[i1];
          let c1 = JSON.parse(JSON.stringify(child1.raw))
          delete c1.body;


          if (child1.lines != undefined) {
            for (let i2 = 0; i2 < child1.lines.length; i2++) {

              let child2 = child1.lines[i2];

              let c2 = JSON.parse(JSON.stringify(child2.raw))
              delete c2.items;

              if (child2.lines != undefined)
                for (let i3 = 0; i3 < child2.lines.length; i3++) {
                  let child3 = child2.lines[i3];

                  if (child3.lines != undefined)
                    for (let i4 = 0; i4 < child3.lines.length; i4++) {
                      // let child4 = child3.lines[i4];



                    }

                }



              newChildren2.push(c2)

            }
          }

          c1.body = newChildren2;
          newChildren.push(c1);
        }


        return newChildren;

      }

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
  .dragArea {
    min-height: 50px;
    outline: 1px dashed;
  }
</style>
<template>
  <div class="mt-6 mb-6 bg-white flex flex-col  items-center justify-center w-full  ">
    <div class="w-12/12  w-full flex flex-row px-6 relative ">
      <div class="w-6/12  w-full flex flex-col px-6 ">
        <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
          <label
            for="qr_ean"
            class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_FORMAT'][lg] }}  
          </label>
        </div>

        <div class="   w-full bg-white flex flex-row items-center">
          <select
            id="default"
            v-model="output"
            class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option
              v-for="choice in choices"
              :key="choice.id"
            >
              {{ choice.value[lg] ? choice.value[lg] : choice.value }}
            </option>
          </select>
        </div>
        <div class="justify-end   mb-2 w-full bg-white flex flex-row items-center">
          <button
            type="button"
            class="  flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            @click="onClickUp"
          >
            <i class="fa-solid fa-plus  mr-2" />
            {{ labels['BUTTON_ADD'][lg] }}
          </button>
          <button
            type="button"
            class="ml-2  flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
            @click="onOpenEditor"
          >
            <i class="fa-solid fa-pen-to-square  mr-2" />
            {{ labels['BUTTON_EDIT'][lg] }}
          </button>
        </div>
        <hr class="h-px my-2 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">

        <div class="mt-2 mb-2 h48 w-full bg-white flex flex-col justify-center items-center">
          <label class="w-full text-left  mt-2  mb-2 text-sm font-bold text-gray-900 dark:text-white">
            {{ labels['LABEL_DESCRIPTION'][lg] }}
          </label>
          <label class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white">
            . . .
          </label>
        </div>

        <hr class="h-px my-2 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">





        <div class="mt-4 mb-2 w-full bg-white flex flex-row items-center justify-center">
          <button
            type="button"
            class="  flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
          >
            <i class="fa-solid fa-print  mr-2" />
            {{ labels['BUTTON_PRINT_TEST'][lg] }}
          </button>
        </div>
      </div>
      <div class="w-6/12 w-full bg-white flex justify-center">
        <div class="h-96 w-96 bg-white  w-full flex flex-col items-center justify-center">
          <span class="w-96 px-6 w-full" />

          <vue-qr
            :logo-scale="logoScale"
            :dot-scale="dotScale"
            class="   h-64 w-64 bg-black  "
            :components="options"
            :logo-src="logo"
            :text="url"
            :callback="test"
            qid="testid"
          />
          <img
            class="h-12"
            :src="logo1"
          >
        </div>
      </div>
    </div>
    <hr class="h-px my-2 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">

    <div class="w-12/12  w-full flex flex-col px-6 ">
      {{ url }}
    </div>
  </div>
  <DisplayQRCodeModal />
</template>
<script>
    import labels from "@/assets/lg/component_label.json"
    import logo from "@/assets/img/qr2.png"
    import logo1 from "@/assets/img/qr1.png"
    import vueQr from 'vue-qr/src/packages/vue-qr.vue'
    import { Modal } from 'flowbite';

    import DisplayQRCodeModal from '@/components/modals/DisplayQRCodeModal.vue'


    export default {
        name: 'ContentLabel',
        components: {
            vueQr, DisplayQRCodeModal
        },
        props: {
            source: Object,
            value: Object,
            config: Object
        },
        compute: {
            url: function () {



                if (this.value == undefined) return ""
                return this.value.name
                // return this.source.qrcode_ean.toString()
            }
        },
        data() {
            return {
                logoScale: 0.3,
                dotScale: 0.6,
                output: {},
                choices: [{ value: { EN: "Fromage Pâte Môle", FR: "Fromage Pâte Môle" } }, { value: { EN: "Fromage Persillé", FR: "Fromage Persillé" } }, { value: { EN: "Expédition", FR: "Expédition" } }],
                options: {
                    data: {
                        scale: 0.5,
                    },
                    timing: {
                        scale: 0.5,
                        protectors: true,
                    },
                    alignment: {
                        scale: 0.5,
                        protectors: true,
                    },
                    cornerAlignment: {
                        scale: 0.5,
                        protectors: true,
                    },
                },
                windowRef: null,
                logo: logo,
                logo1: logo1,
                labels: labels,
                lg: 'FR',
                selectedLabel: {
                    id: "new"
                },
                url: 'https://b4f.biz/01/3565430000916/10/23041VPB/21/40/15/230416',
                size: 300,
            }
        },
        watch: {
            value: function () {
         
                //   this.output = val;
            }
        },
        mounted() {
            this.output.qr_ean = true;
            this.output.qr_batch = true;
            this.output.qr_serial = true;
            this.output.qr_dluo = true;
            this.output.qr_weight == true;

            const modalEl = document.getElementById('display-qr-code-modal');
            this.surveyModal = new Modal(modalEl, {
                placement: 'center'
            });
        },
        methods: {
       
            getData() {
                return "";
                //   return this.labels['BUTTON_EDIT'][this.lg];
            },
            getLabel() {
                return this.value.name;
                //   return this.labels['BUTTON_EDIT'][this.lg];
            },
            onOpenEditor: function () {
                this.surveyModal.show();
                //  this.windowRef = window.open("https://editor-label.vercel.app/edit/" + this.selectedLabel.id, this.selectedLabel.id, "");
                //  if (!this.windowRef) return;
            },
            test(/*dataUrl, id*/) {
               
            },
            onChange: function () {
                this.url = 'https://b4f.biz';
                /*
                                if (this.output.qr_ean == true) {
                                    this.url += '/01/' + this.value.item_gtin
                                }
                
                                if (this.output.qr_batch == true) {
                                    this.url += '/10/23041VPB'
                                }
                
                                if (this.output.qr_serial == true) {
                                    this.url += '/21/40'
                                }
                
                                if (this.output.qr_dluo == true) {
                                    this.url += '/15/230416'
                                }
                
                                if (this.output.qr_weight == true) {
                                    this.url += '/3103/00' + this.value.item_weight
                                }
                                */
                //17) expired date
            }
        }
    }
</script>
<template>
  <!-- Navbar -->
  <nav
    class="
        absolute
        bg-gray-800
        top-0
        left-0
        w-full
        z-10
        bg-transparent
        md:flex-row md:flex-nowrap md:justify-start
        flex
        items-center
      "
  >
    <div
      class="
          w-full
          bg-gray-800
          mx-autp
          items-center
          flex
          justify-between
          md:flex-nowrap
          flex-wrap
          md:px-3
          px-2
        "
    >
      <div
        class="
            text-white text-sm
            font-bold
            leading-relaxed
            inline-block
            mr-4
            py-2
            whitespace-nowrap
             cursor-pointer
          "
        @click="back"
      >
        <i class="fas fa-arrow-left" />
        {{ lg_register['BUTTON_BACK'][lg] }}
      </div>

      <div
        class="
text-white text-sm
font-bold
leading-relaxed
inline-block
mr-4
py-2"
      >
        -  {{ record?.name }} -
      </div>

      <!-- Form -->
      <div
        id="example-navbar-info"
        class="flex lg:flex-grow items-center"
      >
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item">
            <button
              class="
                  px-3
                  py-2
                  flex
                  items-center
                  text-xs
                  uppercase
                  font-bold
                  leading-snug
                  text-white
                  hover:opacity-75
                "
              @click="save"
            >
              {{ lg_register['BUTTON_SAVE'][lg] }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
    import logo from "@/assets/img/logo.png";
    import tenant from "@/assets/img/TREMBLAYE_BEIGE.png";
    import lg_register from "@/assets/lg/flow_designer.json"

    export default {
        components: {

        },
        props: {
            record: {

            }
        },
        data() {
            return {
                lg_register: lg_register,
                lg: "FR",
                navbarOpen: false,
                logo, tenant,
                dlg: null
            };
        },
        methods:
        {
            back: function () {
                this.$emit("back")
            },
            save: function () {
                this.$emit("save")
            },
        },
    };
</script>
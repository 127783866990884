<template>
  <div
    class="  w-full border-l-4 border-l-green-600 flex flex-col  bg-white  rounded-sm border-t border-b border-r border-t-gray-300 border-b-gray-300 border-r-gray-300 "
  >
    <div class="w-full flex flex-row items-center text-left bg-gray-100  border-b border-b-gray-300 ">
      <div class="w-full flex flex-row px-2 py-4 items-center justify-start">
        <div class="pr-3">
          Match Type
        </div>
        <div class=" ">
          <select
            id="vqb-match-type"
            class="rounded-sm py-1 border border-gray-300 px-2"
          >
            <option value="all">
              All
            </option>
            <option value="any">
              Any
            </option>
          </select>
        </div>
      </div>

      <div
        class="w-6"
        @click="onDelete"
      >
        x
      </div>
    </div>
    <div class="w-full p-3">
      <div class=" ">
        <div class="flex flex-row justify-start items-center">
          <select
            v-model="choice"
            class="rounded-sm py-1 border border-gray-300 px-2"
          >
            <option value="first_name">
              First Name
            </option>
            <option value="last_name">
              Last Name
            </option>
            <option value="plan">
              Plan Type
            </option>
          </select>
          <button
            type="button"
            class="rounded-sm bg-gray-700 text-white mx-2 py-1 px-2"
            @click="onAddRule"
          >
            Add Rule
          </button>
          <button
            type="button"
            class="rounded-sm bg-gray-700 text-white mx-2 py-1 px-2"
            @click="onAddGroup"
          >
            Add Group
          </button>
        </div>
      </div>
    </div>
    <div class="w-full p-3">
      <QueryBuilderGroups
        :input-value="output"
        @delete-row="onDeleteRow"
        @delete-group="onDeleteGroup"
        @update-value="onUpdateValue"
      />
    </div>
  </div>
</template>


<script>
    import QueryBuilderGroups from "@/components/QueryBuilder/QueryBuilderGroups.vue";
    import { v4 } from 'uuid';
    export default {
        name: 'QueryBuilder',
        components: { QueryBuilderGroups },
        props: {
            values: Object
        },

        data() {
            return {
                output: {},
                choice: ""
            }

        },
        watch: {
            value: function () {
                this.output = this.value
            }
        },
        mounted() {
            this.output = this.values
        },
        methods:
        {
            onDeleteRow(event) {
                console.log("QueryBuilder", "onDeleteRow")
                console.log(JSON.stringify(event.id))
            },
            onDeleteGroup(event) {
                console.log("QueryBuilder", "onDeleteGroup")
                console.log(JSON.stringify(event.id))

                const output = this.output.rules.filter(function (item) { return item.id !== event.id; });
                console.log(event.id, output)
                this.output.rules = output

                let newevent = { id: this.output.id, rules: this.output.rules }
                this.$emit("updateValue", newevent)

            },
            onUpdateValue(event) {
                this.$emit("updateValue", event)
            },
            onAddRule() {
                let item = {

                    id: v4(),
                    type: "string",
                    name: "Test",

                    attribute: this.choice,
                    action: "contains",
                    value: ""
                }

                this.output.rules.push(item)

                let newevent = { id: this.output.id, rules: this.output.rules }
                this.$emit("updateValue", newevent)
            }, 
            onAddGroup() {
                let item = {

                    id: v4(),
                    type: "group",
                    rules: []
                }

                this.output.rules.push(item)

                let newevent = { id: this.output.id, rules: this.output.rules }
                this.$emit("updateValue", newevent)
            },
        }
    }
</script>
<template>
  <div
    id="display-qr-code-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
  >
    <div class="relative w-full h-full max-w-md md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click="onClose"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          /></svg>  
          <span class="sr-only">Close modal</span>
        </button>
        <!-- Modal header -->
        <div class="px-6 py-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE'][lg] }}
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-6">
          <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE_COMMENT'][lg] }}
          </p>
          <ul class="my-4 space-y-3">
            <li
              v-for="type in types"
              :key="type.id"
              class="cursor-pointer"
            >
              <div
                class="  flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                @click="onProductSelect(type.id)"
              >
                <i
                  class="fa-solid fa-2xl  "
                  :class="type.icon"
                />
                <span class="flex-1 ml-3 whitespace-nowrap">{{ type.name[lg] }}</span>
                <span class="hidden inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">Popular</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import labels from "@/assets/lg/product-types.json"
    export default {
        name: 'DisplayQRCodeModal',
        components: {
           
        },
        data()
        {
            return({
                lg: "FR",
                labels: labels,
                types: [
                {
                        id: "yoghurt",
                        icon: "fa-glass-water",
                        name: {
                            "EN": "Yoghurt",
                            "FR": "Yaourt"
                        }
                    },
                    {
                        id: "cheese",
                        icon: "fa-cheese",
                        name: {
                            "EN": "Cheese",
                            "FR": "Fromage"
                        }
                    },
                    {
                        id: "olive-oil",
                        icon: "fa-bottle-droplet",
                        name: {
                            "EN": "Olive Oil",
                            "FR": "Huile d'Olive"
                        }
                    },
                    {
                        id: "wine",
                        icon: "fa-wine-bottle",
                        name: {
                            "EN": "Wine",
                            "FR": "Vins"
                        }
                    },
                ]
            })
        },
        methods: {
            onProductSelect(item){
                this.$emit("select", item)
            },
            onClose(){
                this.$emit("close")
            }
        },
    }
</script>
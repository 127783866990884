<template>
  <div class="block max-w-sm  p-2  ">
    <div class="w-full flex flex-row items-center justify-start mb-3">
      <div class="w-4 text-gray-300">
        <i class="fa-solid fa-grip-vertical" />
      </div>
      <div class="w-4 text-gray-500 ml-2">
        <i class="fa-solid fa-font" />
      </div>
      <div class="w-full ml-2">
        <label
          for="small"
          class="block  text-sm font-semibold text-gray-900 dark:text-white"
        >
          Trier par
        </label>
      </div>
      <div
        class="w-4 text-gray-500"
        @click="onDelete"
      >
        <i class="w-3 fa-solid fa-trash" />
      </div>
    </div>

    <div class="w-full">
      <label
        for="small"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Trier par
      </label>
      <div class="max-w-sm flex flex-row mx-auto items-center">
        <select
          id="small"
          class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option selected>
            Valeur
          </option>
          <option value="US">
            Catégorie
          </option>
        </select>
        <div class="flex items-center w-12 justify-center text-center mx-auto">
          <i class=" fa-solid fa-arrow-up-z-a" />
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row items-center">
      <label class="inline-flex items-center   cursor-pointer mr-4">
        <input
          type="checkbox"
          value=""
          class="sr-only peer"
        >
        <div
          class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
        />
        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Limit Results</span>
      </label>

      <form class="max-w-xs mx-auto ">
        <div class="relative flex items-center">
          <button
            id="decrement-button"
            type="button"
            data-input-counter-decrement="counter-input"
            class="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
          >
            <svg
              class="w-2.5 h-2.5 text-gray-900 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 2"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h16"
              />
            </svg>
          </button>
          <input
            id="counter-input"
            type="text"
            data-input-counter
            class="flex-shrink-0 text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center text-sm"
            placeholder=""
            value="12"
            required
          >
          <button
            id="increment-button"
            type="button"
            data-input-counter-increment="counter-input"
            class="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
          >
            <svg
              class="w-2.5 h-2.5 text-gray-900 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>


    <div class="w-full px-1 mb-1">
      <div class="flex">
        <div class="flex items-center h-5">
          <input
            id="helper-checkbox"
            aria-describedby="helper-checkbox-text"
            type="checkbox"
            value=""
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          >
        </div>
        <div class="ms-2 text-sm">
          <label
            for="helper-checkbox"
            class="font-medium text-gray-900 dark:text-gray-300"
          >Show "All
            others"</label>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


    // import service from "@/services/FullService.vue"

    import { mapGetters } from "vuex";
    export default {
        name: 'PropertiesXAxisString',
        components: {

        },
        props: {
            input: Object
        },
        computed: {

            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        data() {
            return {
            }
        },
        methods: {

            onDelete: function () {

                this.$emit('delete', this.input)

            }

        }
    }

</script>
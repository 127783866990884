<template>
  <div
    class="h-8 flex items-center bg-gray-900 dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="w-8 h-6 bg-purple-500 text-white">
      <i class="fa-solid fa-chevron-left" />
    </div>
    <div class="flex  px-3 items-center w-full h-6 text-xs font-bold text-left text-white truncate">
      {{ title }}
    </div>
    <div class="w-8 h-6 mr-3 text-white">
      <i class="fa-solid fa-user" />
    </div>
  </div>
</template>


<script>
    export default {
        name: 'HeaderTableFormComponent',
        props: {
            title: String,
            isSelected: Boolean
        },
        computed:
        {

        },
        methods:
        {
           
        },
    }
</script>
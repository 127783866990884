<template>
  <div
    class="  relative cursor-grab bg-gray-700 border list-group mt-2 w-full flex items-center bg-gray-700 border   shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
    :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected, 'h-12': (element.isTitleDisplayed == false && element.isDescriptionDisplayed == false),'h-14': (element.isTitleDisplayed == true && element.isDescriptionDisplayed == false),'h-14': (element.isTitleDisplayed == false && element.isDescriptionDisplayed == true),'h-18': (element.isTitleDisplayed == true && element.isDescriptionDisplayed == true) }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
    <div class="hidden flex flex-col items-center w-6 ">
      <i class="fa-solid fa-grip-vertical" />
    </div>
    <div class="flex flex-col items-center w-full  pl-2 bg-gray-700 ">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full h-6 text-xs text-left text-white"
      >
        {{ element.title }}
      </div>
      <div
        v-if="element.isDescriptionDisplayed"
        class="w-full h-6 text-xs font-normal text-left text-white"
      >
        {{ element.description }}
      </div>
      <div class="w-full     font-bold text-lg  text-white">
        {{ displayDateTime }} ******
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'DateTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            displayDateTime: function () {
                if (this.element.format == "date") return "12/1/2023";
                if (this.element.format == "date-time") return "12/1/2023 2:44";
                if (this.element.format == "time") return "2:44";
                return ""
            }
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
<template>
  <div class="relative   w-full   flex justify-center items-center p-1">
    <apexchart
      :id="`apex-${id}`"
      :ref="`graph-chart-${id}`"
      class="     h-96  w-full  flex items-center  justify-center p-1"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>

    /*
 

    */

    import VueApexCharts from "vue3-apexcharts";

    export default {
        name: 'LineChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            id: String,
            title: String,
            source: String,
            config: Object,
            records: Object,
        },
        data: function () {
            return {
                serieTitle: "Température",
                chartOptions: {

                    chart: {
                  
                        type: "line",
                        stacked: false,
                        zoom: {
                            enabled: false
                        },


                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },

                    },
                    dataLabels: {
                        enabled: true
                    },
                    colors: ["#FF1654", "#247BA0"],

                    stroke: {
                        width: [4, 4]
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "20%"
                        }
                    },
                    xaxis: {
                         type: 'datetime',
                         labels: {
                            show: true,
                         },
                        categories: []
                    },
                    yaxis: [
                           ],
                    tooltip: {
                        shared: false,
                        intersect: true,
                        x: {
                            show: false
                        }
                    },
                    legend: {
                        horizontalAlign: "left",
                        offsetX: 40
                    }
                },
                series: [],

            };
        },
        watch: {
            config: function () {
                      // this.config?.source?.title = 
            },
            records: function (val) {
             //   console.log("LINECHART -------")
             //   console.log(JSON.stringify(this.records))

                if (val == undefined) return;
                if (val.length == 0) return;

                //  this.series = [{
                //      data: val
                //  }]

                let t = this.transformData(val);
           //     console.log(JSON.stringify(t))

                let k = Object.keys(t)

                for (let i = 0; i < k.length; i++) {
                    //if(i==0) 
                    this.series[i] = { name: k[i], data: t[k[i]] }
                }


                if (this.config?.graph?.yaxis) {
                    this.chartOptions = {
                        yaxis: this.config.graph.yaxis
                    }
                }



                /* try {
                  
 
                     //let el = this.$refs['graph-chart-' + this.id]
                     //el.refresh()
                     // if (this.$refs['graph-chart-' + this.id])
                     //     this.$refs['graph-chart-' + this.id].refresh();
 
                 }
                 catch (e) {
                    
                 }*/
                //  this.series.push({data:val})

            }
            /*    records: {
                
                    data(values) {
                  
                        this.series = values;
                    },
                    deep: true
                }*/





        },
        async mounted() {

            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })

        },
        methods:
        {
            /*
"total":4,"records":[{"x":"2024-05-17T08:39:28.494Z","y":300},{"x":"2024-05-17T09:15:42.439Z","y":620},{"x":"2024-05-17T09:51:36.006Z","y":125},{"x":"2024-05-17T09:54:31.228Z","y":12}]}
            */
            transformData(records) {

                let data = {}

                for (let i = 0; i < records.records.length; i++) {

                    if (data[records.records[i].color] == undefined) {
                        data[records.records[i].color] = []
                    }
                    if (records?.records[i]?.y)
                           if (typeof records.records[i].y == "string")   records.records[i].y =  records.records[i].y.replaceAll(" ","")
                    if (records.records[i].y == "Ininterpretable") records.records[i].y ="0"

                    let y = parseFloat(records.records[i].y)
                    let date_x = records.records[i].x.year + "-0" + (records.records[i].x.month + 1) + "-" + records.records[i].x.date
             
                    data[records.records[i].color].push({
                      //  x: new Date(records.records[i].x),
                        x: new Date(date_x).getTime(),
                        y: y ?  y.toFixed(1) : 100
                    })
                }

                data[records.records[0].color].push({
                      //  x: new Date(records.records[i].x),
                        x: new Date("2024-08-21").getTime(),
                        y: 0
                    })

                


           //     console.log(JSON.stringify(data))
                return data
            }
        }
    }
</script>
<template>
  <div class="mt-6 mb-2 w-full relative w-64 ">
    <label class="  w-6/12 flex items-center text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
      {{ label }}

    </label>


    <div
      :id="`indicators-carousel-${id}`"
      class="relative w-full"
      data-carousel="static"
    >
      <!-- Carousel wrapper -->
      <div class="relative h-96 overflow-hidden rounded-lg bg-white  p-24">
        <!-- Item 1 -->
        <div
          :id="`indicators-carousel-1-${id}`"
          class="hidden duration-700 ease-in-out border-6 border-red-500  "
          data-carousel-item="active"
        >
          <a
            target="_null"
            :href="`https://files.b4food.io/b4food/images/${img1}`"
          >
            <img
              :src="`https://files.b4food.io/b4food/images/${img1}`"
              class="  absolute block w-auto object-none  -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2  "
              alt="..."
            >
          </a>
        </div>
        <!-- Item 2 -->
        <div
          :id="`indicators-carousel-2-${id}`"
          class="hidden duration-700 ease-in-out  border-6 border-blue-500"
          data-carousel-item
        >
          <a
            target="_null"
            :href="`https://files.b4food.io/b4food/images/${img2}`"
          >
            <img
              :src="`https://files.b4food.io/b4food/images/${img2}`"
              class="absolute block object-none    w-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            >
          </a>
        </div>
        <!-- Item 3 -->
        <div
          :id="`indicators-carousel-3-${id}`"
          class="hidden duration-700 ease-in-out"
          data-carousel-item
        >
          <a
            target="_null"
            :href="`https://files.b4food.io/b4food/images/${img3}`"
          >
            <img
              :src="`https://files.b4food.io/b4food/images/${img3}`"
              class="absolute block object-none    w-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            >
          </a>
        </div>
        <!-- Item 4 -->
        <div
          :id="`indicators-carousel-4-${id}`"
          class="hidden duration-700 ease-in-out"
          data-carousel-item
        >
          <a
            target="_null"
            :href="`https://files.b4food.io/b4food/images/${img4}`"
          >
            <img
              :src="`https://files.b4food.io/b4food/images/${img4}`"
              class="absolute block object-none    w-auto-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            >
          </a>
        </div>
        <!-- Item 5 -->
        <div
          :id="`indicators-carousel-5-${id}`"
          class="hidden duration-700 ease-in-out"
          data-carousel-item
        >
          <a
            target="_null"
            :href="`https://files.b4food.io/b4food/images/${img5}`"
          >
            <img
              :src="`https://files.b4food.io/b4food/images/${img5}`"
              class="absolute block object-none    w-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            >
          </a>
        </div>
      </div>
      <!-- Slider indicators -->
      <div class="absolute z-30 flex -translate-x-1/2 space-x-3 rtl:space-x-reverse bottom-5 left-1/2">
        <button
          v-if="img1 != undefined"
          type="button"
          class="w-3 h-3 rounded-full"
          aria-current="true"
          aria-label="Slide 1"
          data-carousel-slide-to="0"
        />
        <button
          v-if="img2 != undefined"
          type="button"
          class="w-3 h-3 rounded-full"
          aria-current="false"
          aria-label="Slide 2"
          data-carousel-slide-to="1"
        />
        <button
          v-if="img3 != undefined"
          type="button"
          class="w-3 h-3 rounded-full"
          aria-current="false"
          aria-label="Slide 3"
          data-carousel-slide-to="2"
        />
        <button
          v-if="img4 != undefined"
          type="button"
          class="w-3 h-3 rounded-full"
          aria-current="false"
          aria-label="Slide 4"
          data-carousel-slide-to="3"
        />
        <button
          v-if="img5 != undefined"
          type="button"
          class="w-3 h-3 rounded-full"
          aria-current="false"
          aria-label="Slide 5"
          data-carousel-slide-to="4"
        />
      </div>
      <!-- Slider controls -->
      <button
        :id="`data-carousel-prev-${id}`"
        type="button"
        class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        :class="{ 'opacity-0 pointer-none-events': (items?.length < 2)}"
        data-carousel-prev
      >
        <span
          class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
        >
          <svg
            class="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span class="sr-only">Previous</span>
        </span>
      </button>
      <button
        :id="`data-carousel-next-${id}`"
        type="button"
        class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        :class="{ 'opacity-0 pointer-none-events': (items?.length < 2)}"
        data-carousel-next
      >
        <span
          class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
        >
          <svg
            class="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span class="sr-only">Next</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>

    import { Carousel } from 'flowbite';


    export default {
        name: 'ContentImageGallery',
        props: {
            source: String,

            img1: String,
            img2: String,
            img3: String,
            img4: String,
            img5: String,
            label: String,
            id: String,
            config: Object
        },
        data() {
            return {
                output: "",
                items: [],
                carousel: null
            }
        },
        watch: {
            id: function () {
                this.buildCarrousel()
            },
            img1: function () {
                this.buildCarrousel()
            },



        },
        mounted() {
            this.buildCarrousel()

        },
        methods: {
            buildCarrousel: function () {

                if (this.carousel != null) return;


                const carouselElement = document.getElementById('carousel-example');

                const items = [];


                if (this.img1 != undefined)

                    items.push({
                        position: 0,
                        el: document.getElementById('indicators-carousel-1-' + this.id,),
                    })

                if (this.img2 != undefined)

                    items.push({
                        position: 1,
                        el: document.getElementById('indicators-carousel-2-' + this.id,),
                    })



                        /*     ,
                             {
                                 position: 1,
                                 el: document.getElementById('indicators-carousel-2-' + this.id,),
                             },
                             {
                                 position: 2,
                                 el: document.getElementById('indicators-carousel-3-' + this.id,),
                             },
                             {
                                 position: 3,
                                 el: document.getElementById('indicators-carousel-4-' + this.id,),
                             }, {
                                 position: 4,
                                 el: document.getElementById('indicators-carousel-5-' + this.id,),
                             },*/
                        ;
              //  console.log("items", items)

                if (items.length < 1) return;
                // options with default values
                const options = {
                    defaultPosition: 1,
                    interval: 3000,

                    indicators: {
                        activeClasses: 'bg-white dark:bg-gray-800',
                        inactiveClasses:
                            'bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800',
                        items: items /*,[
                            {
                                position: 0,
                                id: 'carousel-indicator-1-' + this.id,
                                el: document.getElementById('indicators-carousel-1-' + this.id,),
                            }
                            {
                                position: 1,
                                id: 'carousel-indicator-2-' + this.id,
                                el: document.getElementById('indicators-carousel-2-' + this.id,),
                            },
                            {
                                position: 2,
                                id: 'carousel-indicator-3-' + this.id,
                                el: document.getElementById('indicators-carousel-3-' + this.id,),
                            },
                            {
                                position: 3,
                                id: 'carousel-indicator-4-' + this.id,
                                el: document.getElementById('indicators-carousel-4-' + this.id,),
                            }, {
                                position: 4,
                                id: 'indicators-carousel-' + this.id,
                                el: document.getElementById('indicators-carousel-' + this.id,),
                            },
                        ],*/
                    },

                    // callback functions
                    onNext: () => {
                        console.log('next slider item is shown');
                    },
                    onPrev: () => {
                        console.log('previous slider item is shown');
                    },
                    onChange: () => {
                        console.log('new slider item has been shown');
                    },
                };

                // instance options object
                const instanceOptions = {
                    id: 'indicators-carousel-' + this.id,
                    override: true
                };

                this.carousel = new Carousel(carouselElement, items, options, instanceOptions);



                //  this.carousel.cycle();

                // set event listeners for prev and next buttons
                const $prevButton = document.getElementById('data-carousel-prev-' + this.id);
                const $nextButton = document.getElementById('data-carousel-next-' + this.id);





                $prevButton.addEventListener('click', () => {
                    this.carousel.prev();
                });

                $nextButton.addEventListener('click', () => {
                    this.carousel.next();
                });

                this.items = items;
            }
        }
    }
</script>
<template>
  <div class="mt-2 mb-2 w-full relative">
    <label
      for="default"
      class="text-left block mb-2 text-sm font-semibold text-gray-900 dark:text-white"
    >
      {{ label }}
    </label>

    <Multiselect
      v-model="output"
      class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      :close-on-select="false"
      :searchable="true"
      :create-option="true"
      mode="tags"
      :on-create="handleTagCreate"
      :options="loadAutocomplete"
      @add="addTag"
    />
  </div>
</template>
<script>
    import Multiselect from '@vueform/multiselect'
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'ContentTag',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: String,
            source: String,
            mode: String,
            filters: String,
            config: Object
        },
        async mounted() {

            this.loadAutocomplete()

            this.output = this.value;

        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },

        methods: {
            addTag: async function (option) {
                let body =
                {
                    lg: this.lg,
                    state: "ACTIVE",
                    source_id: this.config.source_id,
                    value:  option.value,
                         
                }

                await service.addRecord("tags", this.user?.token, body)

                // Modifying option label
                //option.label = option.label 
                this.loadAutocomplete();
            },
            loadAutocomplete: async function (/*query*/) {
                //   return await this.fetchLanguages(query)

                let filters = {};
                filters.source_id = this.config.source_id


                let options = await service.getData("tags", this.user?.token, filters)
     


                return options.records;
            },

            handleTagCreate: async (option, select$) => {
                // Do not allow create tags above 67
                //   if (parseInt(option.value) > 67) {
          

                // If returns `false` the tag will not be added
                //      return false
                //  }

                select$.$emit("add", option)

                return option
            },


            getData() {


                return this.output;
            }
        },

        components: {
            Multiselect,
        }, data() {
            return {
                options: [
                    'Batman',
                    'Robin',
                    'Joker',
                ],
                output: null,
                choices: [],
                lg: "FR",
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            },
            collection: async function () {





            },
            config: async function () {

            }
        }
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class=" w-full mt-6 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start  ">
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
      <ul
        id="myTab"
        class="flex flex-wrap -mb-px text-sm font-medium text-center"
        data-tabs-toggle="#myFormTabContent"
        role="tablist"
      >
        <li
          class="mr-2"
          role="form-properties"
        >
          <button
            id="form-properties-tab"
            class="inline-block p-4 border-b-2 rounded-t-lg"
            data-tabs-target="#form-properties-content"
            type="button"
            role="tab"
            aria-controls="form-properties-content"
            aria-selected="false"
          >
            Propriétés
          </button>
        </li>
        <li
          class="mr-2"
          role="form-style"
        >
          <button
            id="form-style-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#form-style-content"
            type="button"
            role="tab"
            aria-controls="form-style-content"
            aria-selected="false"
          >
            Style Graphique
          </button>
        </li>
        <li
          class="mr-2"
          role="form-misc"
        >
          <button
            id="form-misc-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#form-misc-content"
            type="button"
            role="tab"
            aria-controls="form-misc-content"
            aria-selected="false"
          >
            Divers
          </button>
        </li>
      </ul>
    </div>

    <div
      id="myFormTabContent"
      class="w-full"
    >
      <div
        id="form-properties-content"
        class=" w-96  p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="form-properties-tab"
      >
        <div
          v-if="currentItem"
          class="hidden mb-6 w-full"
        >
          <label
            for="type"
            class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
          >
            Name <span class="font-medium">{{ tag }}</span></label>
          <input
            v-model="currentItem.shortName"
            type="text"
            class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Nom court"
            required
          >
        </div>
        <div class="mb-6 flex flex-col items-start justify-start w-full">
          <label
            for="password"
            class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
          >
            Titre du Formulaire </label>

          <div
            v-if="currentItem.title != undefined"
            class="flex flex-col items-center   justify-start"
          >
            <div class="flex flex-row justify-start items-center mb-1 w-96">
              <span class="w-9 mr-2 text-sm">FR</span>
              <input
                v-model="currentItem.title.FR"
                type="text"
                class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              >
            </div>
            <div class="flex flex-row justify-start items-center w-96">
              <span class="w-9 mr-2 text-sm">EN</span>
              <input
                v-model="currentItem.title.EN"
                type="text"
                class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              >
            </div>
          </div>
        </div>
        <div
          v-if="currentItem.options"
          class="mb-6 w-full"
        >
          <label
            for="type"
            class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
          >
            Afficher les boutons suivant <span class="font-medium">{{ tag }}</span></label>
          <div class="flex items-center">
            <input
              id="checked-checkbox"
              v-model="currentItem.options.displaySave"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="checked-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Sauvegarder</label>
          </div>
          <div class="flex items-center">
            <input
              id="checked-checkbox"
              v-model="currentItem.options.displayDelete"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="checked-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Supprimer</label>
          </div>
          <div class="flex items-center">
            <input
              id="checked-checkbox"
              v-model="currentItem.options.displayGenerate"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="checked-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Générer</label>
          </div>

          <div>
            <div
              v-if="currentItem.options"
              class="flex items-center"
            >
              <input
                id="checked-checkbox"
                v-model="currentItem.options.displayBack"
                checked
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              >
              <label
                for="checked-checkbox"
                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Retour </label>
            </div>
            <label
              for="type"
              class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
            >
              URL </label>
            <input
              v-model="currentItem.options.backurl"
              type="text"
              class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Url "
              required
            >
          </div>
        </div>
      </div>
      <div
        id="form-style-content"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="form-style-tab"
      >
        <DeveloperStyle :item="currentItem" />
      </div>
      <div
        id="form-misc-content"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="form-misc-tab"
      >
        <DeveloperMisc :item="currentItem" />
      </div>
    </div>

    <span class="bg-red-300 ">** {{ currentItem }} **</span>


    <div class="w-full flex flex-row items-center mb-3">
      <div class="w-full flex justify-start">
        <button
          class="text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="onClickAddTab"
        >
          Ajouter un onglet
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    import { Tabs } from "flowbite";
    import DeveloperStyle from "@/components/developer/DeveloperStyle.vue";
    import DeveloperMisc from "@/components/developer/DeveloperMisc.vue";


    import  slugify from 'slugify'
    export default {
        name: 'DeveloperForm',
        components: {
            DeveloperStyle, DeveloperMisc
        },
        props: {
            item: Object
        },

        data() {
            return ({
                lg: "FR",
                tabElements: [],
                options: {
                    defaultTabId: 'form-home',
                    activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
                    inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
                   // onShow: () => {
        
                   // }
                },
                currentItem: {}
            })
        },
        computed: {

            tag: function () {
                if (this.currentItem == undefined) return ""
                if (this.currentItem.shortName == undefined) return ""
                return slugify(this.currentItem.shortName, { lower: true })
            }
        },
        watch: {
            item: function (val) {
                let item = val;

                 if (item.options == undefined) item.options = {}
                if (item.options.displaySave == undefined) item.options.displaySave = true

                this.currentItem = item;

            }
        },
        mounted() {

            let item = this.item;
            if (item.options == undefined) item.options = {}
            if (item.options.displaySave == undefined) item.options.displaySave = true


            this.currentItem = item;
            const tabElements = [
                {
                    id: 'form-home',
                    triggerEl: document.querySelector('#form-properties-tab'),
                    targetEl: document.querySelector('#form-properties-content')
                },
                {
                    id: 'form-dashboard',
                    triggerEl: document.querySelector('#form-style-tab'),
                    targetEl: document.querySelector('#form-style-content')
                },
                {
                    id: 'form-settings',
                    triggerEl: document.querySelector('#form-misc-tab'),
                    targetEl: document.querySelector('#form-misc-content')
                }
            ]
     
            this.$nextTick(() => {
                const tabs = new Tabs(tabElements, this.options);
      
                tabs.show('form-home');
            });
        },
        methods: {
            onClickAddTab: function () {
                this.$emit("addTab")
            }

        }

    }
</script>
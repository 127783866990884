<template>
  <div
    class=" mr-1 relative  cursor-grab flex  list-group w-full flex items-center    flex flex-row justify-start items-center "
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      class="flex  w-full  pl-2   "
      :class="{'flex-col items-start' : (properties?.layout != 'row'), 'flex-row items-center' : (properties?.layout == 'row') }"
    >
      <div
        v-if="properties?.option?.title"
        class=" w-full flex  py-0.5 mb-1 items-center justify-start w-full  text-sm text-left text-gray-900 font-bold"
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option?.description"
        class="mt-1  w-full   text-xs text-left text-gray-500 font-normal"
      >
        {{ properties?.description }}
      </div>

      <div
        class=" text-left  mt-2 font-bold text-sm flex items-center text-gray-800"
        :class="getClass"
      >
        <div class="w-48 rounded-full bg-blue-100 text-center text-gray-700 py-1">
          {{ displayDateTime }}
        </div>


        <span class="ml-3 text-gray-700"> Maintenant</span>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            getClass: function () {
                if (this.properties.width == undefined) return "w-full"
                if (this.properties.width == "") return "w-full"
                return this.properties.width
            },
            displayDateTime: function () {
                if (this?.properties?.format == "date") return "12/1/2023";
                if (this?.properties?.format == "date-time") return "12/1/2023 2:44";
                if (this?.properties?.format == "time") return "2:44";
                return "???????"
            }
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties.id })
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
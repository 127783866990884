<template>
  <div class="relative   overflow-y-auto bg-gray-50  lg:ml-4 dark:bg-gray-900">
    <div class="relative  mt-24  ml-12">
      <div class="  dark:border-gray-50 mt-2">
        <h2 class="px-16 text-left mb-2 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          {{ displayTitle() }}
        </h2>
        <h4 class="px-16 text-left mb-2 text-xl tracking-tight font-bold text-gray-600 dark:text-white">
          {{ config && config.subTitle && config.subTitle[lg] }}
        </h4>
      </div>

      <nav
        class="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <a
              v-if="folders.paths && (folders.paths.length > 0)"
              href="#"
              class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
              @click="selectPath('')"
            >
              <svg
                aria-hidden="true"
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              {{ getRootName() }}

            </a>
            <span
              v-if="folders.paths && (folders.paths.length < 1)"
              class="inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-400  "
            >
              <svg
                aria-hidden="true"
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              {{ getRootName() }}

            </span>
          </li>
          <li
            v-for="(path,index) in folders.paths"
            :key="path.id"
          >
            <div
              v-if="index !=1000"
              class="flex items-center"
            >
              <svg
                aria-hidden="true"
                class="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <a
                v-if="folders.paths.length != (index+1)"
                href="#"
                class="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                @click="selectPath(path.id)"
              >
                {{ path.name }}
              </a>
              <span
                v-if=" (folders.paths.length == (index+1))"
                class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400"
              >
                {{ path.name }}
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <Grid
        :is-image="isImage"
        :is-loading="isLoading"
        :is-loading2="isLoading2"
        :is-loading3="isLoading3"
        :limit="limit"
        :offset="offset"
        :config="config"
        :records="records"
        :folders="folders"
        @row-click="onRowClick"
        @add="onAddClick"
        @add-folder="onAddFolderClick"
        @export="onSelectFolderClick"

        @select-folder="onSelectFolderClick"
        @refresh="onRefreshClick"
        @change-folder="onChangeFolder"
        @refresh-page="onRefreshPage"
        @refresh-table="onRefreshTable"
        @delete-item="onDeleteItem"
        @update-name="onUpdateName"
      />
    </div>
  </div>
  <UploadImageModal
    :input="imageName"
    @close="OnSelectProductClose"
    @add-image="OnAddImage"
  />
  <CreateFolderModal
    :input="folderName"
    @close="OnCreateFolderClose"
    @add-image="OnCreateFolderValidate"
  />
  <UploadFileModal
    :input="fileName"
    @close="OnSelectProductClose"
    @add-image="OnAddImage"
  />
  <SelectFolderModal
    @close="OnSelectImageModalClose"
    @select-folder="onSelectFolder"
  />
  <div
    id="drawer-delete-confirmation"
    class="fixed top-0 left-0 right-0 z-40 w-full p-4 transition-transform -translate-y-full bg-white dark:bg-gray-800"
    tabindex="-1"
  >
    <h5
      id="drawer-top-label"
      class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
    >
      <svg
        class="w-4 h-4 mr-2.5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>Confirmation de suppression
    </h5>
    <button
      type="button"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
    >
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
      <span class="sr-only">Close menu</span>
    </button>
    <div class="w-full mb-6 text-sm text-gray-500 dark:text-gray-400 flex justify-center">
      <div class="flex flex-col justify-center items-center">
        <span>Veuillez confirmer que vous souhaitez
          supprimer</span><strong>{{ itemtodelete?.name }}</strong>
      </div>
    </div>
    <div class="flex flex-row w-full justify-center">
      <div
        class="cursor-pointer inline-flex items-center mr-2 px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        @click="onCloseDrawer"
      >
        Annulation
      </div>
      <div
        class="cursor-pointer  py-2  px-4 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        @click="onConfirmDeletion"
      >
        Confirmation de la suppression
      </div>
    </div>
  </div>
  <div
    id="alertMedia"
    style="z-index:999"
    class="fixed left-36 right-36 top-3 flex justify-center opacity-0 hidden"
    role="alert"
  >
    <div
      class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
    >
      <svg
        class="flex-shrink-0 inline w-4 h-4 mr-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Danger alert!</span> Change a few things up and try submitting again.
      </div>
    </div>
  </div>
  <button
    id="alertButton"
    type="button"
    class="hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
  >
    Hide alert
  </button>
</template>
<script>
    const MAX_ITEMS = 100;
    import { Modal } from 'flowbite';
    import Grid from '@/components/GridComponent.vue'
    import service from "@/services/FullService.vue"
    import UploadImageModal from '@/components/modals/UploadImageModal.vue'
    import CreateFolderModal from '@/components/modals/CreateFolderModal.vue'
    import UploadFileModal from '@/components/modals/UploadFileModal.vue'
    import SelectFolderModal from '@/components/modals/SelectFolderModal.vue'
    import { Drawer } from 'flowbite';
    import { Dismiss } from "flowbite";
    import { mapGetters } from "vuex";
    export default {
        name: 'MediaView',
        components: {
            Grid, UploadImageModal, CreateFolderModal, SelectFolderModal, UploadFileModal
        },
     
        data() {
            return {
                folderName: "",
                imageName: "",
                fileName: "",


                initFolder: "",
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                itemtodelete: null,
                path: "",
                pathList: [],
                config: {},
                records: [],
                folders: [],
                offset: 0,
                isImage: true,
                filters: {},
                limit: MAX_ITEMS,
                lg: "FR",
                title: "",
                surveyModal: null,
                selectFolderModal: null,
                createFolderModal: null,
                uploadFileModal: null,
                button: "",
                dismiss: null,
                drawerConfirmation: null,
                optionsAlert: {
                    transition: 'transition-opacity',
                    duration: 1000,
                    timing: 'ease-out',

                    // callback functions
                    //  onHide: (context, targetEl) => {

                    //  }
                },
                options: {
                    placement: 'right',
                    backdrop: true,
                    bodyScrolling: false,
                    edge: false,
                    edgeOffset: '',
                    backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
                    //  onHide: () => {

                    //  },
                    //  onShow: () => {

                    //  },
                    //  onToggle: () => {

                    //  }
                }
            }
        },
       
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
       
        watch:
        {
            /*
                        '$route.fullPath': async function () {
                            console.log("watch********************", "route.fullPath")
                            let filters = {}
                            if (this.$route.fullPath.includes('/admin/media-documents'))
                                this.isImage = false;
                            else this.isImage = true;
            
            
            
                           // 
            
                            //filters['category'] = config.filters;
                            await  this.reloadPage(null, 0, MAX_ITEMS, filters, 1);
                            window.scrollTo(0, 0);
            
                        },*/
            '$route.params.rid': async function (val) {


                //    console.log("watch********************", "route.params.rid")

                let filters = {}
                this.folders = []
                //filters['category'] = config.filters;
                await this.reloadPage(val, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            '$route.params.id': async function (val) {


                // console.log("watch********************", "route.params.id")

                let filters = {}
                this.folders = []
                //filters['category'] = config.filters;
                await this.reloadPage(val, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user.tenant_id': async function () {
                this.isLoading = true;
                let filters = {}
                await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);

            }
        },
        async mounted() {
            // await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {})


            const $targetElAlert = document.getElementById('alertMedia');

            const $triggerElAlert = document.getElementById('alertButton');

            this.dismiss = new Dismiss($targetElAlert, $triggerElAlert, this.optionsAlert);


            const $targetEl = document.getElementById('drawer-delete-confirmation');

            this.drawerConfirmation = new Drawer($targetEl, this.options);





            if (this.$route.fullPath.includes('/admin/media-documents')) this.isImage = false;
            else this.isImage = true;



            const modalEl4 = document.getElementById('select-folder-modal');
            this.selectFolderModal = new Modal(modalEl4, {
                placement: 'center'
            });

            const modalEl = document.getElementById('upload-image-modal');
            this.surveyModal = new Modal(modalEl, {
                placement: 'center'
            });

            const modalEl2 = document.getElementById('create-folder-modal');
            this.createFolderModal = new Modal(modalEl2, {
                placement: 'center'
            });


            const modalEl3 = document.getElementById('upload-file-modal');
            this.uploadFileModal = new Modal(modalEl3, {
                placement: 'center'
            });

        },
        methods: {

            onCloseDrawer: function () {
                this.drawerConfirmation.hide()
            },
            onConfirmDeletion: async function () {

                let f = this.folders.records
                let response = await service.deleteRecord(this.itemtodelete.tag, this.user?.tokenn, this.itemtodelete.id)

                this.drawerConfirmation.hide()

                let o = this
                function myStopFunction() {
                    o.dismiss.hide()
                }

                if (response != null) {
                    const objIndex = f.findIndex(o => o.id === this.itemtodelete.id)

                    if (objIndex > -1) {

                        f.splice(objIndex, 1)
                    }
                }
                else {


                    const $targetElAlert = document.getElementById('alertMedia');

                    $targetElAlert.classList.remove("hidden")
                    $targetElAlert.classList.remove("opacity-0")


                    const $triggerElAlert = document.getElementById('alertButton');

                    this.dismiss = new Dismiss($targetElAlert, $triggerElAlert, this.optionsAlert);


                    setTimeout(myStopFunction, 3000);


                }
            },
            onRowClick: function () {

            },
            getTitle() {
                if (this.$route.fullPath == '/admin/media-documents')
                    return "Mes Documents"
                return "Mes Images";
            },


            getRootName() {
                if (this.$route.fullPath.includes('/admin/media-documents'))
                    return "Mes Documents"
                return "Mes Images";
            },
            onExportClick() {

            },
            displayTitle() {
                if (this.$route.fullPath.includes('/admin/media-documents'))
                    return "Bibliothèque Documents"

                return "Bibliothèque Images"
            },
            getPaths() {
                // let p = this.path.split("/")

            },
            displaySubTitle() {
                return "displaySubTitle"
            },
            async reloadPage(id, offset, limit, filter, level) {
                this.folders = [];
                this.records = [];
                if (level == null) level = 1

                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }

                this.offset = offset;
                this.limit = limit;


                if (level == 1) this.isLoading = true;
                if (level == 2) this.isLoading2 = true;
                if (level == 3) this.isLoading3 = true;


                filter.type = "FOLDER";
                if (id != undefined)
                    filter.parent_id = id
                else
                    delete filter.parent_id


                let tag = "images";

                if (this.$route.fullPath.includes('/admin/media-documents')) tag = "files"


                let folders = await service.getData(tag, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
                //    console.log("Folders = ", folders.records.length)
                filter.type = tag.toUpperCase();
                console.log(filter)
                let records = await service.getData(tag, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
                //    console.log("Files = ", records.records.length)

                if (folders != undefined) this.folders = folders;
                if (records != undefined) this.records = records;
                if (level == 1) this.isLoading = false;
                if (level == 2) this.isLoading2 = false;
                if (level == 3) this.isLoading3 = false;

            },

            onDeleteItem: async function (item) {

                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }
                this.itemtodelete = item
                this.drawerConfirmation.show()
                //  
            },

            onUpdateName: async function (item) {

                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }

                await service.updateRecord(item.tag, item.id, this.user?.token, { name: item.name })
            },

            onSelectFolder: async function (item) {
              
                console.log(item)

                this.selectFolderModal.hide();
            },



            OnAddImage: async function (item) {

                if (this.$route.params.id != undefined)
                    item.parent_id = this.$route.params.id
                else
                    delete item.parent_id


                let tag = "images";

                if (this.$route.fullPath.includes('/admin/media-documents'))
                    tag = "files"


                item.type = tag.toUpperCase();
                await service.addRecord(tag, this.user?.token, item)

                await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {}, 1);
                this.surveyModal.hide();


                this.imageName = item?.name
                this.fileName = item?.name

            },


            OnCreateFolderValidate: async function (item) {

                this.createFolderModal.hide();

                this.folderName = item
                if (this.$route.params.id != undefined)
                    item.parent_id = this.$route.params.id
                else
                    delete item.parent_id
                item.type = "FOLDER";

                let tag = "images";

                if (this.$route.fullPath == '/admin/media-documents')
                    tag = "files"

                await service.addRecord(tag, this.user?.token, item)

                await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {}, 1);


            },


            OnSelectProductClose: function () {
                this.surveyModal.hide();
                this.imageName = "-"
            },

            OnCreateFolderClose: function () {
                this.createFolderModal.hide();
                this.folderName = "-"
            },


            onRefreshClick: async function (item) {
                //    console.log("onRefreshClick------------------------------------------")
                await this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 2);
            },
            onRefreshPage: async function (item) {
                //   console.log("onRefreshPage----------------------------------------------")
                await this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 1);
            },
            onChangeFolder: function (item) {
                //  console.log("onChangeFolder----------------------------------------------")
                // this.$route.params.id = item.id

                this.path = this.path + "/" + item.name

                this.pathList.push({ id: item.id, name: item.name })



                let path = '/admin/media/'
                if (this.$route.fullPath.includes('/admin/media-documents'))
                    path = '/admin/media-documents/'

                // console.log("change to ", path + item.id)
                this.$router.push(path + item.id)


                //   this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 1);


            },
            onRefreshTable: async function (item) {

                await this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 3);
            },
            selectPath: function (id) {
                //   console.log("selectPath")

                let path = '/admin/media/'
                if (this.$route.fullPath.includes('/admin/media-documents'))
                    path = '/admin/media-documents/'
                if (id == '') {
                    this.$router.push(path)
                    return;
                }
                this.$router.push(path + id)
            },
            onAddFolderClick: function () {

                this.folderName = ""
                this.createFolderModal.show();


            },
            onAddClick: function () {

                if (this.$route.fullPath.includes('/admin/media-documents')) {
                    this.fileName = ""
                    this.uploadFileModal.show();
                    return;
                }
                this.imageName = ""

                this.surveyModal.show();
            },
            onSelectFolderClick: function () {
                this.selectFolderModal.show();
            }
        }
    }
</script>
<template>
  <div
    class=" relative h-64 cursor-grab bg-gray-700 border  list-group mt-2 w-full flex items-start bg-gray-700 border   shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
    :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-0 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
      
    <div class="flex flex-col items-start w-full  pl-2 bg-gray-700 ">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full h-6 text-xs text-left text-white"
      >
        {{ element.title }}  
      </div>
      <div
        v-if="element.isDescriptionDisplayed"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ element.description }}  
      </div>
      <div class="absolute bottom-0 left-0 right-0 top-12  font-bold text-lg  text-white  bg-gray-900" />
    </div>
  </div>
</template>


<script>
    export default {
        name: 'SignatureTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            displayDateTime: function () {
                if (this.element.format == "date") return "12/1/2023";
                if (this.element.format == "date-time") return "12/1/2023 2:44";
                if (this.element.format == "time") return "2:44";
                return ""
            }
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
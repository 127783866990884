<template>
  <div class="ml-12 h-full relative mt-24  overflow-y-none bg-gray-50  dark:bg-gray-900">
    <nav
      class="border-b-1 border-b-gray-300 flex p-4"
      aria-label="Breadcrumb"
    >
      <ol class="w-full inline-flex items-center   ">
        <li>
          <div class="flex items-center">
            <h3 class="text-3xl font-bold ml-2">
              Liste des tâches des employés
            </h3>
          </div>
        </li>
      </ol>
      <ol class="w-full text-right justify-end inline-flex items-center  ">
        <li class="cursor-pointer inline-flex items-center">
          <div
            class=" inline-flex items-center text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            @click="onClickBreadCrumb('/')"
          >
            Date :
          </div>
        </li>
        <li class="cursor-pointer inline-flex items-center">
          <div
            class="px-4 inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            @click="onClickBreadCrumb('/')"
          >
            <input
              :id="`date-${id}`"
              v-model="searchRange"
              datepicker
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Date de la journée"
            >
          </div>
        </li>
        <li>
          <div class="flex items-center px-4">
            <button
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickAdd"
            >
              <i class="fa-solid fa-plus  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }}
            </button>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <button
              type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickExport"
            >
              <i class="fa-solid fa-download mr-2 " />
              {{ common_labels['BUTTON_EXPORT'][lg] }}
            </button>
          </div>
        </li>
      </ol>
    </nav>
    <main>
      <div class="flex flex-col mt-2">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden shadow">
              <div class="flex justify-start items-start px-4 mb-6 space-x-4 grid w-full grid-cols-4 bg-gray-50">
                <div class="hidden min-w-kanban">
                  <div class="py-4 text-lg font-semibold text-gray-900 dark:text-gray-300">
                    A Traiter
                  </div>

                  <div
                    id="kanban-list-1"
                    class="mb-4 space-y-4 min-w-kanban"
                  >
                    <div
                      class="bg-purple-100  flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white">
                          Relevé Température - Expédition
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>



                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-purple-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 09a</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_32_3"
                              class="-mr-3"
                            >
                              <div class="flex flex-row items-center">

                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/michael-gough.png"
                                  alt="Michael Gough"
                                >

                                <span class="ml-1">Lucie</span>
                              </div>
                            </a>
                            <div
                              id="user_32_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-purple-800 bg-white rounded-lg dark:bg-purple-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Reste 2 heures
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      class="bg-green-100 flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white">
                          Nettoyage du Maturateur
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      <div class="hidden flex justify-center items-center pb-4">
                        <img
                          class="bg-contain rounded-lg"
                          src="@/assets/kanban/task-3.jpg"
                          alt="attachment"
                        >
                      </div>

                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-green-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR NETT 08</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_23_1"
                              class="-mr-3"
                            >

                              <div class="flex flex-row items-center">

                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/bonnie-green.png"
                                  alt="Bonnie Green"
                                >
                                <span class="ml-1">Magalie</span>


                              </div>
                            </a>
                            <div
                              id="user_23_1"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Bonnie Green
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-green-800 bg-white rounded-lg dark:bg-purple-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Reste 2 jours
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      class="bg-green-100 flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white">
                          Nettoyage de la salle Emballage - Zone Rouge
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>



                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-green-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR NETT 07</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_65_2"
                              class="-mr-3"
                            >

                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/roberta-casas.png"
                                  alt="Roberta Casas"
                                >
                                <span class="ml-1">Pascale</span>
                              </div>

                            </a>
                            <div
                              id="user_65_2"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Roberta Casas
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-green-800 bg-white rounded-lg dark:bg-purple-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Reste 3 heures
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    data-modal-toggle="new-card-modal"
                    class="flex justify-center items-center py-2 w-full font-semibold text-gray-500 rounded-lg border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ajouter une nouvelle carte
                  </button>
                </div>

                <div
                  v-for="col in tasks"
                  :key="col.id"
                  class="min-w-kanban "
                >
                  <div class="py-4 text-base font-semibold text-gray-900 dark:text-gray-300">
                    {{ col.name }}
                  </div>

                  <div
                    id="kanban-list-2"
                    class="mb-4 space-y-4 min-w-kanban"
                  >
                    <div
                      v-for="line in col.tasks"
                      :key="line.id"
                      class="bg-purple-100 flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white">
                          {{ line.name }}
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>


                      <div class="hidden flex justify-center items-center pb-4">
                        <img
                          class="bg-contain rounded-lg"
                          src="@/assets/kanban/task-1.jpg"
                          alt="attachment"
                        >
                      </div>


                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-purple-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 09a</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_76_3"
                              class="-mr-3"
                            >

                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  :src="line.assignedTo?.avatar"
                                  alt="Michael Gough"
                                >
                                <span class="ml-1 text-xs text-left">{{ line.assignedTo?.fullname }}</span>
                              </div>
                            </a>
                            <div
                              id="user_76_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-purple-800 bg-white rounded-lg dark:bg-purple-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Planifiée à 16h30
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    data-modal-toggle="new-card-modal"
                    class="flex justify-center items-center py-2 w-full font-semibold text-gray-500 rounded-lg border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ajouter une nouvelle carte
                  </button>
                </div>

                <div class="hidden  min-w-kanban">
                  <div class="py-4 text-base font-semibold text-gray-900 dark:text-gray-300">
                    En Cours
                  </div>

                  <div
                    id="kanban-list-3"
                    class="mb-4 space-y-4 min-w-kanban"
                  >
                    <div
                      class="bg-purple-100 text-left flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-base font-semibold text-gray-900 dark:text-white">
                          Relevé Température - Congélateurs et Frigos
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>


                      <div class="hidden flex justify-center items-center pb-4">
                        <img
                          class="bg-contain rounded-lg"
                          src="@/assets/kanban/task-2.jpg"
                          alt="attachment"
                        >
                      </div>


                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-purple-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 25</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_87_2"
                              class="-mr-3"
                            >

                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/roberta-casas.png"
                                  alt="Roberta Casas"
                                >
                                <span class="ml-1">Pascale</span>
                              </div>

                            </a>
                            <div
                              id="user_87_2"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Roberta Casas
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-purple-800 bg-white rounded-lg dark:bg-green-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Débutée à 13h16
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      class="bg-blue-100 flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-ledt pb-4">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white  ">
                          Relevé Température - Chambre Froide
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>



                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-blue-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 09a</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_43_1"
                              class="-mr-3"
                            >

                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/bonnie-green.png"
                                  alt="Bonnie Green"
                                >
                                <span class="ml-1">Magalie</span>
                              </div>

                            </a>
                            <div
                              id="user_43_1"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Bonnie Green
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-blue-800 bg-white rounded-lg dark:bg-green-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Débutée à 13h34
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      class="bg-orange-100 flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-base font-semibold text-gray-900 dark:text-white">
                          Contrôles poids papier pour emballages
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>



                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-orange-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 09a</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_34_3"
                              class="-mr-3"
                            >

                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/michael-gough.png"
                                  alt="Michael Gough"
                                >

                                <span class="ml-1">Lucie</span>
                              </div>
                            </a>
                            <div
                              id="user_34_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-orange-800 bg-white rounded-lg dark:bg-green-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Débutée à 14h30
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    data-modal-toggle="new-card-modal"
                    class="flex justify-center items-center py-2 w-full font-semibold text-gray-500 rounded-lg border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ajouter une nouvelle tâche
                  </button>
                </div>

                <div class="hidden min-w-kanban">
                  <div class="py-4 text-base font-semibold text-gray-900 dark:text-gray-300">
                    Réalisées
                  </div>

                  <div
                    id="kanban-list-3"
                    class="mb-4 space-y-4 min-w-kanban"
                  >
                    <div
                      class="text-left bg-orange-100 flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-base font-semibold text-gray-900 dark:text-white">
                          Contrôle de la mesure du poids des balances (I700)
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>


                      <div class="hidden flex justify-center items-center pb-4">
                        <img
                          class="bg-contain rounded-lg"
                          src="@/assets/kanban/task-2.jpg"
                          alt="attachment"
                        >
                      </div>


                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="flex flex-col bg-orange-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 23</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_87_1"
                              class="-mr-3"
                            >
                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/bonnie-green.png"
                                  alt="Bonnie Green"
                                >
                                <span class="ml-1">Magalie</span>
                              </div>

                            </a>
                            <div
                              id="user_87_1"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Bonnie Green
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>

                            <a
                              href="#"
                              data-tooltip-target="user_87_2"
                              class="hidden -mr-3"
                            >
                              <img
                                class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                src="@/assets/users/roberta-casas.png"
                                alt="Roberta Casas"
                              >
                            </a>
                            <div
                              id="user_87_2"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Roberta Casas
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>

                            <a
                              href="#"
                              data-tooltip-target="user_87_3"
                              class="hidden -mr-3"
                            >
                              <img
                                class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                src="@/assets/users/michael-gough.png"
                                alt="Michael Gough"
                              >
                            </a>
                            <div
                              id="user_87_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-orange-800 bg-white rounded-lg dark:bg-green-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Terminée à 8h42
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      class="bg-blue-100  flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="text-left flex justify-between items-center pb-4">
                        <div class="text-base font-semibold text-gray-900 dark:text-white">
                          Contôle Températures et Propretés Camion
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>



                      <div class="flex flex-col">
                        <div class="text-left pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="flex flex-col bg-blue-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR CONT 11C</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">A chaque
                                Expédition</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_43_1"
                              class="-mr-3 hidden"
                            >
                              <img
                                class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                src="@/assets/users/bonnie-green.png"
                                alt="Bonnie Green"
                              >
                            </a>
                            <div
                              id="user_43_1"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Bonnie Green
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>

                            <a
                              href="#"
                              data-tooltip-target="user_43_2"
                              class="-mr-3 "
                            >
                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/roberta-casas.png"
                                  alt="Roberta Casas"
                                >
                                <span class="ml-1">Pascale</span>
                              </div>

                            </a>
                            <div
                              id="user_43_2"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Roberta Casas
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>

                            <a
                              href="#"
                              data-tooltip-target="user_43_3"
                              class="-mr-3 hidden"
                            >
                              <img
                                class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                src="@/assets/users/michael-gough.png"
                                alt="Michael Gough"
                              >
                            </a>
                            <div
                              id="user_43_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-blue-800 bg-white rounded-lg dark:bg-green-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Terminée à 9h34
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      class="bg-green-100  flex flex-col p-5 max-w-md bg-white rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center pb-4">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white">
                          Nettoyage des Vestiaires Vert
                        </div>

                        <button
                          type="button"
                          data-modal-toggle="kanban-card-modal"
                          class="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>



                      <div class="flex flex-col">
                        <div class="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">
                          <div class="text-left flex flex-col bg-green-50 p-2">
                            <div>
                              Procédure : <span class="font-bold">ENR NETT 08</span>
                            </div>
                            <div>
                              Fréquence : <span class="font-bold">Tous les jours</span>
                            </div>
                          </div>
                        </div>

                        <div class="flex justify-between">
                          <div class="flex justify-start items-center">
                            <a
                              href="#"
                              data-tooltip-target="user_34_1"
                              class="  -mr-3"
                            >
                              <img
                                class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                src="@/assets/users/bonnie-green.png"
                                alt="Bonnie Green"
                              >
                            </a>
                            <div
                              id="user_34_1"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Bonnie Green
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>

                            <a
                              href="#"
                              data-tooltip-target="user_34_2"
                              class="hidden -mr-3"
                            >
                              <img
                                class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                src="@/assets/users/roberta-casas.png"
                                alt="Roberta Casas"
                              >
                            </a>
                            <div
                              id="user_34_2"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Roberta Casas
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>

                            <a
                              href="#"
                              data-tooltip-target="user_34_3"
                              class=" -mr-3"
                            >

                              <div class="flex flex-row w-full">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/michael-gough.png"
                                  alt="Michael Gough"
                                >

                                <span class="ml-1">Magalie & Lucie</span>
                              </div>

                            </a>
                            <div
                              id="user_34_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium text-green-800 bg-white rounded-lg dark:bg-green-200"
                          >
                            <svg
                              class="mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Terminée à 10h12
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <button
                    type="button"
                    data-modal-toggle="new-card-modal"
                    class="flex justify-center items-center py-2 w-full font-semibold text-gray-500 rounded-lg border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ajouter une nouvelle tâche
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {{ tasks }}
    </main>
  </div>
</template>
<script>
  import labels from "@/assets/lg/common.json"
  import Datepicker from 'flowbite-datepicker/Datepicker';

  import service from "@/services/FullService.vue"
  // import service from "@/services/FullService.vue"
  /* const heatmapData = [
       {
           lat: 43.6744,
           lng: 7.1949
       },
       {
           lat: 48.8232,
           lng: 2.278
       }];
*/
  import { mapGetters } from "vuex";

  export default {
    name: "KanbanViewDynamic",
    components: {},

    props:
    {
      title: String,
      subTitle: String
    },
    data() {

      return {
        id: 100,
        lg: "FR",
        api: null,
        common_labels: labels,
        heat2: {

        },
        response: [],
        labels: [],
        tasks: [],
        tasks2: [

          {
            id: 1,
            name: "Contacté",
            tasks: [

              {
                id: "6",
                url: "https://file-storage-ten.vercel.app/b4food/images/6535282939a38ce77b2ff309",
                name: "Numod"
              }



            ]
          },
          {
            id: 2,
            name: "Qualifié",
            tasks: [

              {
                id: "3",
                url: "https://file-storage-ten.vercel.app/b4food/images/6533f000ab24db4c51c181ee",
                name: "Gazo Fils"
              },
              {
                id: "4",
                url: "https://file-storage-ten.vercel.app/b4food/images/653400f5b1f4342903409c01",
                name: "Gazo Fils"
              }

            ]
          },
          {
            id: 3,
            name: "Devis Envoyé",
            tasks: [
              {
                id: "2",
                url: "",
                name: "Argotier"
              }
            ]
          }
          ,
          {
            id: 4,
            name: "Négotiation",
            tasks: []
          },
          {
            id: 5,
            name: "Terminé",
            tasks: [
              {
                id: "1",
                url: "https://file-storage-ten.vercel.app/b4food/images/64e763a538c794a433395951",
                name: "Ferme de la Tremblaye"
              }
            ]
          }
        ]
      }
    },

    computed:
    {
      ...mapGetters({ user: "StateUser" }),



    },


    watch: {
      'user.tenant_id': async function () {
        let response = await this.getPoints()
        this.heatmapData = response

      }
    },
    mounted: async function () {


      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login"
        return;




      }

      let filter = {}
      let records = await service.getData("tasks", this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, filter))


      this.buildColumns(records?.records);

      const datepickerEl = document.getElementById('date-' + this.id);
      new Datepicker(datepickerEl, {
        format: "dd/mm/yyyy",
        autohide: true,
        title: "Date expiration du document"
      });

      this.$nextTick(function () {

      });
    },



    methods:
    {

      buildColumns: function (records) {




        if (records == undefined) {

          return []
        }

        let result = {
          'NEW': [],
          'PLANNED': [],
          'IN_PROGRESS': [],
          'COMPLETED': []
        }

        for (let i = 0; i < records.length; i++) {
          let record = records[i]

          if (result[record.state] == undefined) result[record.state] = []


          if (record != undefined)
            result[record.state].push(
              {
                id: record._id,
                url: "https://file-storage-ten.vercel.app/b4food/images/64e763a538c794a433395951",
                name: record.name,
                avatar: record.assignedTo.avatar.url,
                assignedTo:
                {
                  "fullname": record.assignedTo.fullname,
                  "avatar": record.assignedTo.avatar,
                }

              }

            )

        }



        this.tasks.push({
          id: 1,
          name: "En attente ",
          tasks: result['NEW']
        })


        this.tasks.push({
          id: 1,
          name: "Planifiée ",
          tasks: result['PLANNED']
        })

        this.tasks.push({
          id: 2,
          name: "En cours",
          tasks: result['IN_PROGRESS']
        })

        this.tasks.push({
          id: 2,
          name: "Finis",
          tasks: result['COMPLETED']
        })

      }

    },



  };
</script>
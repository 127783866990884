<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div
        v-if="displayRequired"
        class="  mb-6 w-full text-left"
      >
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Contraintes xxx</label>

        <div class="  block text-black">
          {{ element }}
        </div>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-checked-checkbox"
              v-model="isRequired"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRequired"
            >
            <label
              for="inline-checked-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Obligatoire</label>
          </div>
        </div>
      </div>

      <div
        v-if="displayMessage"
        class="  mb-6 w-full text-left"
      >
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Pas de
          contraintes possible sur cet élément</label>

        <div class="  block text-black">
          {{ element }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ControlsTableFormComponentDetails',
        props: {
            element: Object,
            index: Number,
            definition: Object
        },
        data() {

            return {
                isRequired: true
            }
        },
        computed: {
            displayRequired: function () {
                if (this.element?.type == 'avatar') return true
                if (this.element?.type == 'radio') return true
                if (this.element?.type == 'edit') return true
                if (this.element?.type == 'button') return true
                if (this.element?.type == 'date') return true

                if (this.element?.type == 'scan-code') return true


                if (this.element?.type == 'number') return true
                return false;
            },
            displayMessage: function () {
                if (this.element?.type == 'avatar') return false
                if (this.element?.type == 'radio') return false
                if (this.element?.type == 'edit') return false
                if (this.element?.type == 'date') return false
                if (this.element?.type == 'scan-code') return false
                if (this.element?.type == 'button') return false
                if (this.element?.type == 'number') return false
                return true;
            }
        },
        watch:
        {
            element: function () {
                this.isRequired = this.element._controls?.isRequired;
                if (this.isRequired == undefined) this.isRequired = true
            }
        },

        mounted() {
            this.isRequired = this.element?._controls?.isRequired
            if (this.isRequired == undefined) this.isRequired = true

        },
        methods:
        {
            onChangeIsRequired: function () {
                this.$emit("update", { att: 'isRequired', value: this.isRequired })
            }
        }
    }
</script>
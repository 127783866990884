import axios from "axios";
const languageCode = (navigator?.language || navigator?.userLanguage)?.substr(0, 2)?.toUpperCase();
const state = {

  langage: languageCode ? languageCode : 'EN',
  user: null,
  fake: null
}
const getters = {
  StateUser: (state) => state.user,
  StateRememberMe: (state) => state.rememberMe,
  StateLangage: (state) => state.langage,
  StateFake: (state) => state.fake,
}


const actions = {
  async LogIn({ commit }, User) {
    // console.log("store", "LogIn")
    // console.log("LogIn");

    //const json = JSON.stringify(User);
  //  let a = await axios.post("/api/b4food/auth/login", User, {
  //    headers: {
  //      "Content-Type": "application/json",
  //    },
  //  });


  //  if (a.code == 0) {
      await commit("setUser",User);
  //  }

 //   return a.data
  },


  async ChangeTenant({ commit }, id) {
    //  console.log("store", "ChangeTenant")
    let token = null;
    if (this.getters && this.getters.StateUser != undefined) {
      token = this.getters.StateUser.token;
    }
    let a = await axios.get("/api/b4food/users/me/tenant/" + id, {
      headers: {

        "x-access-token": token
      },
    });
    if (a.data != undefined) {
      //  console.log("ChangeTenant", "It is ok")

      if (this.getters && this.getters.StateUser != undefined) {
        this.getters.StateUser.tenant_id = id
        this.getters.StateUser.tenant = a.data
        await commit("setUser", this.getters.StateUser);
      }
      return a.data
    }
    else
      console.log("ChangeTenant", "Something Wrong")
    return null
  },



  async ChangeEntity({ commit }, id) {
    //  console.log("store", "ChangeTenant")
    let token = null;
    if (this.getters && this.getters.StateUser != undefined) {
      token = this.getters.StateUser.token;
    }
    let a = await axios.get("/api/b4food/users/me/entity/" + id, {
      headers: {

        "x-access-token": token
      },
    });
    if (a.data != undefined) {
    

      if (this.getters && this.getters.StateUser != undefined) {
        this.getters.StateUser.entity_id = id
        await commit("setUser", this.getters.StateUser);
      }
      return a.data
    }
    else
      console.log("ChangeEntity", "Something Wrong")
    return null
  },



  async ChangeLanguage({ commit }, lg) {
    // console.log("store", "ChangeLanguage")
    let token = null;
    if (this.getters && this.getters.StateUser != undefined) {
      token = this.getters.StateUser.token;
    }
    let a = await axios.get("/api/b4food/users/me/language/" + lg, {
      headers: {

        "x-access-token": token
      },
    });
    if (a.data != undefined) {
      // console.log("ChangeLanguage", "It is ok")

      if (this.getters && this.getters.StateUser != undefined) {
        this.getters.StateUser.language = lg
        await commit("setUser", this.getters.StateUser);
      }
      return a.data
    }
    // else
    //  console.log("ChangeLanguage", "Something Wrong")
    return null
  },


  async Langage({ commit }, langage) {
    await commit("setLangage", langage);
  },



  async RememberMe({ commit }, remember) {
    await commit("setRememberMe", remember);
  },

  async LogOut({ commit }) {
    let user = null;
    commit("LogOut", user);
  },
}


const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  setRememberMe(state, remember) {
    state.rememberMe = remember;
  },


  setLangage(state, langage) {
    state.langage = langage;
  },

  LogOut(state) {
    state.user = null;


  },
  setFake(state, username) {
    state.fake = username;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
};
<template>
  <div
    id="vue-flow-view"
    class="w-full  h-screen overflow-hidden"
  >
    <WorkflowEditorNavbar
      :record="record"
      @save="onSave"
      @back="onBack"
    />
    <div
      class="dndflow w-full mx-auto"
      style="height: 100%;"
      @drop="onDrop"
    >
      <Sidebar class="h-96" />
      <VueFlow
        v-model="elements2"
        style="overflow-y:auto;min-height:530px; height:100%; width:100%"
        class="basicflow"
        :default-zoom="1"
        :min-zoom="0.4"
        :max-zoom="2"
        :fit-view-on-init="true"
        :connection-mode="ConnectionMode.Loose"
        @dragover="onDragOver"
      >
        <Background
          pattern-color="#a00"
          gap="8"
        />

        <div
          class="controls  "
          style="display:block"
        >
          <strong class="text-2xl">{{ record.name }} {{ opts.label }}</strong>
        </div>

        <div class="hidden updatenode__controls">
          <label>label:</label>
          <input
            v-model="opts.label"
            @input="updateNode"
          >

          <label class="updatenode__bglabel">background:</label>
          <input
            v-model="opts.bg"
            type="color"
            @input="updateNode"
          >

          <div class="updatenode__checkboxwrapper">
            <label>hidden:</label>
            <input
              v-model="opts.hidden"
              type="checkbox"
              @change="updateNode"
            >
          </div>
        </div>


        <template #node-custom="props">
          <ColorSelectorNodeStandard
            :data="props.data"
            :full="props"
            @change="onChange"
          />
        </template>

        <template #node-custom-start="props">
          <ColorSelectorNodeStart
            :data="props.data"
            :full="props"
            @change="onChange"
          />
        </template>

        <template #node-custom-end="props">
          <ColorSelectorNodeEnd
            :data="props.data"
            :full="props"
            @change="onChange"
          />
        </template>

        <template #node-custom-import="props">
          <ColorSelectorNodeImport
            :data="props.data"
            @change="onChange"
          />
        </template>


        <template #node-custom-sensor="props">
          <ColorSelectorNodeSensor
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-document="props">
          <ColorSelectorNodeDocument
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-scan-code="props">
          <ColorSelectorNodeScanCode
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-stock="props">
          <ColorSelectorNodeStock
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-stock-new="props">
          <ColorSelectorNodeStock
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-api="props">
          <ColorSelectorNodeApi
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-push="props">
          <ColorSelectorNodePush
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-push-document-mail="props">
            <ColorSelectorNodePushDocumentMail
              :data="props.data"
              @change="onChange"
            />
        </template>

        <template #node-custom-tasks="props">
          <ColorSelectorNodeTasks
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-code="props">
          <ColorSelectorNodeCode
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-save="props">
          <ColorSelectorNodeSave
            :data="props.data"
            @change="onChange"
          />
        </template>


        <template #node-custom-photo="props">
          <ColorSelectorNodePhoto
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-collection="props">
          <ColorSelectorNodeCollection
            :data="props.data"
            @change="onChange"
          />
        </template>



        <template #node-custom-print="props">
          <ColorSelectorNodePrint
            :data="props.data"
            @change="onChange"
          />
        </template>


        <template #node-custom-send-mail="props">
          <ColorSelectorNodeSendMail
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-export="props">
          <ColorSelectorNodeExport
            :data="props.data"
            @change="onChange"
          />
        </template>

        <template #node-custom-qrcode="props">
          <ColorSelectorNodeQRCode
            :data="props.data"
            @change="onChange"
          />
        </template>


        <template #node-custom-batch="props">
          <ColorSelectorNodeBatch
            :data="props.data"
            @change="onChange"
          />
        </template>




        <MiniMap />
        <Controls />

        <div
          class="controls hidden"
          style="display:none"
        >
          <button
            style="background-color: #113285; color: white"
            @click="resetTransform"
          >
            reset
            transform
          </button>
          <button
            style="background-color: #6f3381; color: white"
            @click="updatePos"
          >
            update positions
          </button>
          <button
            :style="{ backgroundColor: dark ? '#FFFFFB' : '#1C1C1C', color: dark ? '#1C1C1C' : '#FFFFFB' }"
            @click="toggleClass"
          >
            toggle {{ dark ? 'light' : 'dark' }}
          </button>
          <button @click="logToObject">
            log toObject
          </button>
          <button
            style="background-color: #6f3381; color: white"
            @click="onSave"
          >
            save
          </button>
          <button
            style="background-color: #113285; color: white"
            @click="onRestore"
          >
            load
          </button>
        </div>
      </VueFlow>

      <PropertiesBar
        :properties="node_p"
        @remove-node="onRemoveNode"
        @remove-edge="onRemoveEdge"
        @update-properties="onUpdateNode"
        @show-form-edit="onShowFormDetails"
        @create-action="onCreateAction"
      />
    </div>
    <div
      id="modalEl"
      tabindex="-1"
      aria-hidden="true"
      class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0"
    >
      <div class="relative max-h-full w-full max-w-2xl">
        <!-- Modal content -->
        <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
              Terms of Service
            </h3>
            <button
              type="button"
              class="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                class="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="space-y-6 p-6">
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              With less than a month to go before the European Union
              enacts new consumer privacy laws for its citizens, companies
              around the world are updating their terms of service
              agreements to comply.
            </p>
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The European Union’s General Data Protection Regulation
              (G.D.P.R.) goes into effect on May 25 and is meant to ensure
              a common set of data rights in the European Union. It
              requires organizations to notify users as soon as possible
              of high-risk data breaches that could personally affect
              them.
            </p>
          </div>
          <!-- Modal footer -->
          <div
            class="flex items-center space-x-2 rtl:space-x-reverse rounded-b border-t border-gray-200 p-6 dark:border-gray-600"
          >
            <button
              type="button"
              class="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              I accept
            </button>
            <button
              type="button"
              class="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
    /* eslint-disable */
    import axios from 'axios';

    import { useStore } from "vuex";
    import service from "@/services/FullService.vue";

    import { v4 as uuidv4 } from 'uuid';
    import { computed, h, watch, onMounted, ref, reactive, nextTick } from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import lg_register from "@/assets/lg/flow_designer.json"



    import { Modal } from 'flowbite';


    import ColorSelectorNodeStandard from '../components/flow/nodes/CustomNode2.vue'
    import ColorSelectorNodeStart from '../components/flow/nodes/CustomNodeStart.vue'
    import ColorSelectorNodeEnd from '../components/flow/nodes/CustomNodeEnd.vue'
    import ColorSelectorNodeImport from '../components/flow/nodes/CustomNodeImport.vue'
    import ColorSelectorNodeExport from '../components/flow/nodes/CustomNodeExport.vue'
    import ColorSelectorNodeQRCode from '../components/flow/nodes/CustomNodeQRCode.vue'
    import ColorSelectorNodeBatch from '../components/flow/nodes/CustomNodeBatch.vue'
    import ColorSelectorNodeSendMail from '../components/flow/nodes/CustomNodeSendMail.vue'
    import ColorSelectorNodePrint from '../components/flow/nodes/CustomNodePrint.vue'
    import ColorSelectorNodeSensor from '../components/flow/nodes/CustomNodeSensor.vue'
    import ColorSelectorNodeScanCode from '../components/flow/nodes/CustomNodeScanCode.vue'
    import ColorSelectorNodeDocument from '../components/flow/nodes/CustomNodeDocument.vue'
    import ColorSelectorNodePhoto from '../components/flow/nodes/CustomNodePhoto.vue'
    import ColorSelectorNodeSave from '../components/flow/nodes/CustomNodeSave.vue'
    import ColorSelectorNodeCode from '../components/flow/nodes/CustomNodeCode.vue'
    import ColorSelectorNodeCollection from '../components/flow/nodes/CustomNodeCollection.vue'

    import ColorSelectorNodeStock from '../components/flow/nodes/CustomNodeStock.vue'
    import ColorSelectorNodeTasks from '../components/flow/nodes/CustomNodeTasks.vue'
    import ColorSelectorNodePush from '../components/flow/nodes/CustomNodePush.vue'
    import ColorSelectorNodeApi from '../components/flow/nodes/CustomNodeApi.vue'
    import ColorSelectorNodePushDocumentMail from '../components/flow/nodes/CustomNodePushDocumentMail.vue'


    import { presets } from '../components/flow/presets.js'
    import { ConnectionMode, VueFlow, useVueFlow, MarkerType } from '@vue-flow/core'
    import { MiniMap } from '@vue-flow/minimap'
    import { Background } from '@vue-flow/background'

    import { Controls } from '@vue-flow/controls'


    import Sidebar from '../components/flow/SideBar.vue'

    import PropertiesBar from '../components/flow/PropertiesBar.vue'

    import { mapState, mapGetters, mapMutations, mapActions } from '../components/flow/mapStates.js'
    import WorkflowEditorNavbar from "@/components/flow/NavigatorView.vue";


    const { workflowforedit } = mapGetters()

    const picture = ref(null);
    let record = ref({
        name: "Loading in progress"
    })



    let change = false;
    let flow_reference = {}
    const route = useRoute()
    const lg = "FR";

    let initialElements = ref([])
    const elements = ref([])
    const flowKey = 'example-flow'

    const { onNodeDoubleClick, onNodeClick, onNodeDragStart, onEdgeClick, onEdgeDoubleClick, setTransform, onConnect, toObject, onPaneReady, onPaneClick, getNode, nodes, edges, addEdges, addNodes, findNode, setNodes, setEdges, viewport,
        project, removeNodes, removeEdges, vueFlowRef } = useVueFlow()

    const gradient = ref(false)
    const bgColor = ref(presets.ayame)
    const bgName = ref('AYAME')

    const router = useRouter();
    const store = useStore();
    const connectionLineStyle = { stroke: '#fff', width: "10px" }
    let selectProductTypeModal = null;

    const cuser = computed(() => store.getters["StateUser"]);
    let user = cuser.value


    // minimap stroke color functions
    const nodeStroke = (n) => {
        if (n.type === 'input') return '#ff0072'
        if (n.type === 'custom') return presets.sumi
        if (n.type === 'output') return '#ff0072'
        if (n.type === 'import') return '#220072'
        if (n.type === 'save') return '#220072'
        if (n.type === 'export') return '#220072'
        if (n.type === 'send-mail') return '#220072'
        if (n.type === 'qrcode') return '#22ff22'
        return '#eee'
    }


    // const props = defineProps(['initialElements'])

    const nodeColor = (n) => {
        if (n.type === 'custom') return bgColor.value

        if (n.type == "input") return '#F00';
        return '#fff'
    }

    // output labels
    const outputColorLabel = () => h('div', {}, bgColor.value)
    const outputNameLabel = () => h('div', {}, bgName.value)

    const onChange = (color) => {


        //   gradient.value = false
        //   bgColor.value = color.value
        //   bgName.value = color.name

        //  outputColorNode.value.hidden = false
    }

    const onGradient = () => {
        gradient.value = true
        bgColor.value = null
        bgName.value = 'gradient'
        outputColorNode.value.hidden = true
    }




    onMounted(async () => {

        const route = useRoute()

        //("OnMonted == A1", route.params)

        // if (this.$route.query.id)

        {

            let r = await service.getDataId("workflows", route.params.id, user.token, {})
            //let r = await GetEditWorkflow(route.query.id)


            if (r) {
                flow_reference = JSON.parse(JSON.stringify(r));
                record.value = r;
                // const [x = 0, y = 0] = r.workflow.position
                // setNodes(r.workflow.nodes)
                // setEdges(r.workflow.edges)
                // setTransform({ x, y, zoom: r.workflow.zoom || 0 })
                let arr = ref([])
                if (r.workflow) {
                    for (let i = 0; i < r.workflow?.nodes?.length; i++) {
                        let item = r.workflow.nodes[i]
                        
                        let n = {
                            id: item.id,
                            type: item.type,
                            active: true,
                            label: item.label,
                            data: reactive({
                                color: item?.data?.color,
                                label: item?.data?.label,
                                description: item?.data?.description,
                                parameters: item?.data?.parameters,
                                emails: item?.data?.emails,
                                xlsmodel: item?.data?.xlsmodel,
                                shortlabel: item?.data?.shortlabel,

                                items: item?.data?.items,

                                action_id: item?.data?.action_id,
                                source: item?.data?.source,
                                destination: item?.data?.destination,
                                trigger: item?.data?.trigger,
                                quantity: item?.data?.quantity,
                                unit: item?.data?.unit,
                                title: item?.data?.title,
                                scope: item?.data?.scope,
                                body: item?.data?.body,
                                from_id: item?.data?.from_id,
                                to_id: item?.data?.to_id,
                                product_id: item?.data?.product_id,
                                identifier: item?.data?.identifier,
                                mail_tpl_id: item?.data?.mail_tpl_id,
                                document_tpl_id: item?.data?.document_tpl_id,
                                manufacture_date: item?.data?.manufacture_date,
                                batch_number: item?.data?.batch_number,
                                end_of_life: item?.data?.end_of_life,
                                supplier_id: item?.data?.supplier_id,

                                products: item?.data?.products,
                                information: item?.data?.information,

                                action: item?.data?.action,
                                profile: item?.data?.profile,
                                init_context: item?.data?.init_context,

                                mode: item?.data?.mode,
                                filter: item?.data?.filter,
                                projection: item?.data?.projection,
                                remote_identifier: item?.data?.remote_identifier,

                                batchformula: item?.data?.batchformula
                            }),
                            position: { x: item?.position?.x, y: item?.position?.y }
                        }
                        arr.value.push(n)
                    }

                    for (let i = 0; i < r?.workflow?.edges?.length; i++) {
                        let item = r.workflow.edges[i]

                        let n = {
                            "id": item.id,
                            "source": item.source,
                            "target": item.target,
                            data: item.data,
                            "active": false,
                            "markerEnd": "",
                            "label": "",
                            "selected": false,
                            style: { cursor: 'pointer' } 
                        }
                        arr.value.push(n)
                    }
                }
                elements2.value = arr.value;
            }
        }
        node_p.value = null;



    })


    /*
        let node_p = ref({
            label: ""
        });*/

    let node_p = ref();
    node_p.value = null;
    let id = 200;



    const getId = () => uuidv4(); // `dndnode_${id++}`

    const onDragOver = (event) => {
        event.preventDefault()
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'move'
        }
    }
    let a = this



    const onCreateAction = async (node) => {

 
        // console.log("Elements 2 =================================")
        //  console.log(JSON.stringify(elements2))
        record.value.workflow = toObject()
        //  console.log("record =================================")
        //  console.log(JSON.stringify(record.value.workflow.pages))
        record.value.id = route.params.id

        let res = await service.UpdateWorkflow(record.value, user.token)
        if (res != undefined) {
            node.workflow_id = record.value._id
            node.image = record.value.image_id
            node.category = record.value.category
            node.title = record.value.name
            node.subtitle = record.value.description
            let response = await service.addRecord("workflow-forms", user?.token, node)
            //    console.log(response)
            if (response._id != null) {
                router.push("/admin/new-editor/" + response._id);
            }
        }

    }



    const onSaveForm = (prf) => {
    

        profile = prf;

        const node = getNode.value(node_p.value.id)
 
        if (node == undefined) { alert("errreur #44550"); return; }
console.log(JSON.stringify(prf))

        node.data.profile = prf;


    }

    const onShowFormDetails = (a) => {

        if (a.data.profile == undefined) {

            //    profile.value = {
            //        title: "", lines: [{
            //            id: "98",
            //            title: "Valider",
            //            type: "validate",
            //            isDisplayValidate: true,
            //            isDisplayNext: false,
            //            isDisplayPrevious: false
            //        }]
            //   }
        }
        else {
            profile.value = a.data.profile
        }
        selectProductTypeModal.show()
    }
    onEdgeClick(({ edge }) => {

        // selectProductTypeModal.show();

        if (edge.label == undefined) edge.label = ""
        if (edge.data == undefined) edge.data = {}

        node_p.value = edge
        // node_p = ref(JSON.parse(JSON.stringify(node)));
        // node_p.label = "%%%%%%%%%%%%%%%"

    })


    onNodeDragStart(({ node }) => {

        node_p.value = node
    })

    onNodeClick(({ node }) => {

        node_p.value = node
    })

    // onNodeDoubleClick(({ node }) => {   })
    // onEdgeDoubleClick(({ edge }) => {  })
    //addEdges([params])

    const resetTransform = () => setTransform({ x: 0, y: 0, zoom: 1 })

    onConnect((params) => {
        params.active = true;

        //params.markerEnd = MarkerType.Arrow;
        params.selected = true
        // params.style = {   cursor: 'pointer' }

        addEdges([params])
    })

    onPaneReady(({ fitView }) => {

        fitView()
    })
    onPaneClick(() => {
        node_p.value = null
    })



    const dark = ref(false)

    const onSave2 = () => {

        localStorage.setItem(flowKey, JSON.stringify(toObject()))
    }

    const onRestore = () => {
        const flow = JSON.parse(localStorage.getItem(flowKey))

        if (flow) {
            const [x = 0, y = 0] = flow.position
            setNodes(flow.nodes)
            setEdges(flow.edges)
            setTransform({ x, y, zoom: flow.zoom || 0 })
        }
    }

    const logToObject = () => { }

    const toggleClass = () => {
        dark.value = !dark.value
        elements.value.forEach((el) => (el.class = dark.value ? 'dark' : 'light'))
    }

    const updatePos = () => {

        /*      elements.value.forEach((el) => {
   
              if (isNode(el)) {
                  el.position = {
                      x: Math.random() * 400,
                      y: Math.random() * 400,
                  }
              }
          })*/


    }


    const onDrop = (event, b) => {



        const type2 = event.dataTransfer?.getData('application/vueflow')

        if (type2 == undefined) return;
        if (type2 == "") return;



        if (node_p && node_p.value) {

            const nodeSelected = findNode(node_p.value.id)
            if (nodeSelected) nodeSelected.selected = false;

        }

        node_p.value = null

        const type = event.dataTransfer?.getData('application/vueflow')
        let ltype = "custom";
        let label = `${type} node`
        if (type == 'default') { label = lg_register['TASK_MIDDLE'][lg]; ltype = "custom" }
        if (type == 'output') { label = lg_register['TASK_END'][lg]; ltype = "custom-end" }
        if (type == 'input') { label = lg_register['TASK_START'][lg]; ltype = "custom-start" }
        if (type == 'import') { label = lg_register['TASK_IMPORT'][lg]; ltype = "custom-import" }
        if (type == 'export') { label = lg_register['TASK_EXPORT_XLS'][lg]; ltype = "custom-export" }
        if (type == 'send-mail') { label = lg_register['TASK_SEND_MAIL'][lg]; ltype = "custom-send-mail" }
        if (type == 'sensor') { label = lg_register['TASK_SENSOR'][lg]; ltype = "custom-sensor" }

        if (type == 'document') { label = lg_register['TASK_DOCUMENT'][lg]; ltype = "custom-document" }
        if (type == 'scan-code') { label = lg_register['TASK_SCAN_CODE'][lg]; ltype = "custom-scan-code" }

        if (type == 'stock') { label = lg_register['TASK_STOCK'][lg]; ltype = "custom-stock" }
        if (type == 'stock-new') { label = lg_register['TASK_STOCK'][lg]; ltype = "custom-stock-new" }


        if (type == 'tasks') { label = lg_register['TASK_TASKS'][lg]; ltype = "custom-tasks" }

        if (type == 'push') { label = lg_register['TASK_PUSH'][lg]; ltype = "custom-push" }

        if (type == 'push-document-mail') { label = lg_register['TASK_PUSH_DOCUMENT_MAIL'][lg]; ltype = "custom-push-document-mail" }



        if (type == 'api') { label = lg_register['TASK_PUSH'][lg]; ltype = "custom-api" }

        if (type == 'collection') { label = lg_register['TASK_COLLECTION'][lg]; ltype = "custom-collection" }

        if (type == 'photo') { label = lg_register['TASK_PHOTO'][lg]; ltype = "custom-photo" }
        if (type == 'print') { label = lg_register['TASK_PRINT'][lg]; ltype = "custom-print" }
        if (type == 'code') { label = lg_register['TASK_CODE'][lg]; ltype = "custom-code" }
        if (type == 'save') { label = lg_register['TASK_SAVE'][lg]; ltype = "custom-save"; }
        if (type == 'qrcode') { label = lg_register['TASK_QR_CODE'][lg]; ltype = "custom-qrcode" }


        if (type == 'batch') { label = lg_register['TASK_BATCH'][lg]; ltype = "custom-batch" }



        let id = getId();


        const { left, top } = vueFlowRef.value.getBoundingClientRect()



        const position = project({
            x: event.clientX - left - (172) / 2,
            y: event.clientY - top - (67) / 2,
        })

        const newNode = [{
            id: id.toString(),
            active: true,
            selected: true,
            type: ltype,
            position: position,
            label: label,
            data: reactive({
                color: "#FF00FF",
                label: label,
                batchformula: "",
                xlsmodel: "",

                quantity: "",
                unit: "",

                mail_tpl_id: "",
                document_tpl_id: "",

                items: "",
                shortlabel: "",
                action_id: null,
                emails: "",
                parameters: [],
                profile: {
                    title: "Nouveau Formulaire",
                    lines: [{
                        id: "98",
                        title: "Valider",
                        type: "validate",
                        isDisplayValidate: true,
                        isDisplayNext: false,
                        isDisplayPrevious: false

                    }]
                }
            })
        }]


        //elements2.value.forEach((el) => (el.selected = false))
        addNodes(newNode)

        // align node position after drop, so it's centered to the mouse
        nextTick(() => {
            const node = findNode(newNode[0].id)

            const stop = watch(
                () => node.dimensions,
                (dimensions) => {

                    if (dimensions.width > 0 && dimensions.height > 0) {

                        node.position = { x: node.position.x - node.dimensions.width / 2, y: node.position.y - node.dimensions.height / 2 }
                        stop()
                    }
                },
                { deep: true, flush: 'post' },
            )
        })
        node_p.value = newNode[0]
    }
    const compareEdge = (a, b) => {

        if (a.id != b.id) {

            return false
        };
        if (a.source != b.source) {

            return false
        };
        if (a.target != b.target) {

            return false
        };


        return true;
    }

    const compareNode = (a, b) => {

        if (a.id != b.id) { return false };

        if (a.type != b.type) { return false; }
        if (a.label != b.label) { return false; }
        if (a.position.x != b.position.x) { return false; }
        if (a.position.y != b.position.y) { return false; }
        if (a.data.label != b.data.label) { return false; }
        if (a.data.description != b.data.description) { return false; }
        if (a.data.source != b.data.source) { return false; }
        if (a.data.identifier != b.data.identifier) { return false; }
        if (a.data.destination != b.data.destination) { return false; }
        if (a.data.trigger != b.data.trigger) { return false; }

        if (a.data.emails != b.data.emails) { return false; }
        if (a.data.xlsmodel != b.data.xlsmodel) { return false; }


        if (a.data.quantity != b.data.quantity) { return false; }
        if (a.data.unit != b.data.unit) { return false; }


        if (a.data.mail_tpl_id != b.data.mail_tpl_id) { return false; }
        if (a.data.document_tpl_id != b.data.document_tpl_id) { return false; }

        if (a.data.items != b.data.items) { return false; }

        if (a.data.shortlabel != b.data.shortlabel) { return false; }
        if (a.data.action_id != b.data.action_id) { return false; }
        if (a.data.batchformula != b.data.batchformula) { return false; }

        if (a.data.mode != b.data.mode) { return false; }
        if (a.data.projection != b.data.projection) { return false; }

        if (a.data.remote_identifier != b.data.remote_identifier) { return false; }

        if (a.data.filter != b.data.filter) { return false; }



        if (a.data.parameters.length != b.data.parameters.length) { return false; }

        for (let i = 0; i < a.data.parameters.length; i++) {
            if (a.data.parameters[i].id != b.data.parameters[i].id) return false;
            if (a.data.parameters[i].name != b.data.parameters[i].name) return false;
            if (a.data.parameters[i].type != b.data.parameters[i].type) return false;
            if (a.data.parameters[i].required != b.data.parameters[i].required) return false;
            if (a.data.parameters[i].default != b.data.parameters[i].default) return false;
        }

        if (a.data?.profile?.text != b.data?.profile?.text) { return false; }
        for (let i = 0; i < a.data?.profiles?.lines?.length; i++) {

        }

        return true;
    }

    const compareVersionEdges = (a, b) => {
        if (a == undefined) return false;
        if (b == undefined) return false;
        for (let i = 0; i < a.length; i++) {
            let fb = b.find(edge => { return edge.id == a[i].id });

            if (fb == undefined) {

                return false;
            }
            else {

                if (!compareEdge(a[i], fb)) return false
            }
        }
        return true;
    }

    const compareVersionNodes = (a, b) => {
        if (a == undefined) return false;
        if (b == undefined) return false;


        for (let i = 0; i < a.length; i++) {
            let fb = b.find(node => { return node.id == a[i].id });

            if (fb == undefined) {

                return false;
            }
            else {

                if (!compareNode(a[i], fb)) return false
            }
        }
        return true;

    }

    const compareVersion = (a, b) => {
        if (a == undefined) return false;
        if (b == undefined) return false;
        /*
        let keysA = Object.keys(a);
        let keysB = Object.keys(b);

        for (let ia = 0; ia < keysA.length; ia++) {
          
            if (typeof a[keysA[ia]] != 'object')
                if (a[keysA[ia]] != b[keysA[ia]]) return false;
        }

        for (let ib = 0; ib < keysB.length; ib++) {
            if (typeof a[keysB[ib]] != 'object')
                if (a[keysB[ib]] != b[keysB[ib]]) return false;
        }*/

        // let aJSON = JSON.stringify(a)
        // let bJSON = JSON.stringify(b) 
        let t = toObject()


        let an = compareVersionNodes(flow_reference?.workflow?.nodes, t.nodes)
        let nn = compareVersionNodes(t.nodes, flow_reference?.workflow?.nodes)

        let ae = compareVersionEdges(flow_reference?.workflow?.edges, t.edges)
        let ne = compareVersionEdges(t.edges, flow_reference?.workflow?.edges)

        return an && nn && ne && ae
    }


    const onBack = (event) => {


        let t = toObject();

        if (!compareVersion(flow_reference.workflow, toObject())) {
            if (confirm("Voulez-vous sauvegarder vos changements?")) {
                record.value.workflow = toObject()
                service.UpdateWorkflow(record.value, user.token)
                // router.push({ path: '/admin/views/workflows' })
                window.location = '/admin/views/workflows'
            } else {
                //   router.push({ path: '/admin/views/workflows' })
                window.location = '/admin/views/workflows'
            }
        }
        else {
 

            router.push({ path: '/admin/views/workflows' })
            //  route.push({path:"/"})
        }


    }

    const onSave = async (event) => {
        // localStorage.setItem(flowKey, JSON.stringify(toObject()))

        record.value.workflow = toObject()

        console.log(record.value.workflow)
        //localStorage.setItem(flowKey, JSON.stringify(toObject()))


        record.value.id = route.params.id


        let r = await service.UpdateWorkflow(record.value, user.token)

    }

    const onRemoveEdge = (edge) => {

        node_p.value = null
        removeEdges([edge]);

    }


    const onRemoveNode = (node) => {

        node_p.value = null; //ref({ properties: {}})
        removeNodes([node], true)

    }


    const opts = reactive({
        bg: '#eeeeee',
        label: '',

    })

    const updateNode = () => {


        const node = getNode.value('dndnode_0')
        node.data.label = opts.label.trim() !== '' ? (new Date()).toISOString() : defaultLabel
        node.label = node.data.label

        node.style = { backgroundColor: opts.bg }


    }
    const updateNode2 = (id, txt) => {

        const node = getNode.value(id)
        if (txt.label) {

            node.label = txt.label  // opts.label.trim() !== '' ? txt.label : "defaultLabel"
            if (node.data == undefined) node.data = {}
            node.data.label = txt.label  //opts.label.trim() !== '' ? txt.label : "defaultLabel"
        }


        node.style = { backgroundColor: opts.bg }

    }


    const onUpdateNode = (tField) => {

        let field = JSON.parse(tField)


        const node = getNode.value(field.data.id)
        if (node == undefined) {

            return;
        }
        if (field.type == "name") {
            if (node.data == undefined) node.data = {}
            if (field.data.data)
                node.data.label = field.data.data.label
            else
                node.data.label = field.data.label
            node.label = node.data.label
        }

        if (field.type == "shortname") {
            node.data.shortlabel = field.data.data.shortlabel


        }
        if (field.type == "profile") {
            node.data.profile = field.data.data.profile

        }
        if (field.type == "items") {
            node.data.profile = field.data.data.items

        }
        if (field.type == "description") {
            node.data.description = field.data.data.description
        }

        if (field.type == "batchformula") {
            node.data.batchformula = field.data.data.batchformula
        }

        if (field.type == "init_context") {
            node.data.init_context = field.data.data.init_context
        }

        if (field.type == "mode") {
            node.data.mode = field.data.data.mode
        }

        if (field.type == "filter") {
            node.data.filter = field.data.data.filter
        }

        if (field.type == "projection") {
            node.data.projection = field.data.data.projection
        }

        
        if (field.type == "remote_identifier") {
            node.data.remote_identifier = field.data.data.remote_identifier
        }



        if (field.type == "condition") {
            node.data.condition = field.data.condition
        }

        if (field.type == "xlsmodel") {
            node.data.xlsmodel = field.data.data.xlsmodel
        }

        if (field.type == "document_tpl_id") {
            node.data.document_tpl_id = field.data.data.document_tpl_id
        }

        if (field.type == "mail_tpl_id") {
            node.data.mail_tpl_id = field.data.data.mail_tpl_id
        }

        if (field.type == "quantity") {
            node.data.quantity = field.data.data.quantity
        }

        if (field.type == "unit") {
            node.data.unit = field.data.data.unit
        } 
        
        if (field.type == "items") {
            node.data.items = field.data.data.items
        }

        if (field.type == "shortlabel") {
            node.data.shortlabel = field.data.data.shortlabel
        }

        if (field.type == "shortname") {
            node.data.shortlabel = field.data.data.shortlabel

        }
        if (field.type == "trigger") {
            node.data.trigger = field.data.data.trigger
        }

        if (field.type == "quantity") {
            node.data.quantity = field.data.data.quantity

        }

        if (field.type == "product_id") {
            node.data.product_id = field.data.data.product_id

        }
        if (field.type == "to_id") {
            node.data.to_id = field.data.data.to_id

        }
        if (field.type == "to_from") {
            node.data.to_from = field.data.data.to_from

        }

        if (field.type == "source") {
            node.data.source = field.data.data.source

        }


        if (field.type == "destination") {
            node.data.destination = field.data.data.destination

        }
        if (field.type == "identifier") {
            node.data.identifier = field.data.data.identifier

        }

        if (field.type == "emails") {
            node.data.emails = field.data.data.emails

        }


    }


    let elements2 = ref([]);

</script>


<style>
    .vue-flow {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: lightgray;

    }

    .vue-flow__container {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    .vue-flow__transformationpane {
        pointer-events: none;
        transform-origin: 0 0;
        z-index: 2;
    }

    .vue-flow__pane {
        z-index: 1;
    }

    .vue-flow__viewport {
        z-index: 4;
    }

    .vue-flow__selectionpane {
        z-index: 5;
    }

    .vue-flow__selection {
        position: absolute;
        top: 0;
        left: 0;
    }

    .vue-flow__edges {
        overflow: visible;
        pointer-events: none;
    }

    .vue-flow__edge {
        pointer-events: visibleStroke;
    }

    .vue-flow__edge.inactive {
        pointer-events: none;
    }

    @-webkit-keyframes dashdraw {
        from {
            stroke-dashoffset: 10;
        }
    }

    @keyframes dashdraw {
        from {
            stroke-dashoffset: 10;
        }
    }

    .vue-flow__edge-path {
        fill: none;
    }

    .vue-flow__edge-textwrapper {
        pointer-events: all;
    }

    .vue-flow__edge-text {
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .vue-flow .vue-flow__connectionline {
        z-index: 1001;
    }

    .vue-flow__connection {
        pointer-events: none;
    }

    .vue-flow__connection .animated {
        stroke-dasharray: 5;
        -webkit-animation: dashdraw 0.5s linear infinite;
        animation: dashdraw 0.5s linear infinite;
    }

    .vue-flow__connection-path {
        fill: none;
    }

    .vue-flow__nodes {
        pointer-events: none;
        transform-origin: 0 0;
    }

    .vue-flow__node {
        position: absolute;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        pointer-events: all;
        transform-origin: 0 0;
        box-sizing: border-box;
    }

    .vue-flow__nodesselection {
        z-index: 3;
        transform-origin: left top;
        pointer-events: none;
    }

    .vue-flow__nodesselection-rect {
        position: absolute;
        pointer-events: all;
        cursor: -webkit-grab;
        cursor: grab;
    }

    .vue-flow__handle {
        position: absolute;
        pointer-events: none;
    }

    .vue-flow__handle.connectable {
        pointer-events: all;
    }

    .vue-flow__handle-bottom {
        top: auto;
        left: 50%;
        bottom: -4px;
        transform: translate(-50%, 0);
    }

    .vue-flow__handle-top {
        left: 50%;
        top: -4px;
        transform: translate(-50%, 0);
    }

    .vue-flow__handle-left {
        top: 50%;
        left: -4px;
        transform: translate(0, -50%);
    }

    .vue-flow__handle-right {
        right: -4px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .vue-flow__edgeupdater {
        cursor: move;
        pointer-events: all;
    }

    /* additional components */
    .vue-flow__controls {
        position: absolute;
        z-index: 5;
        bottom: 10px;
        left: 10px;
    }

    .vue-flow__controls-button {
        width: 24px;
        height: 24px;
        border: none;
    }

    .vue-flow__controls-button svg {
        width: 100%;
    }

    .vue-flow__minimap {
        position: absolute;
        z-index: 5;
        bottom: 10px;
        right: 10px;
    }


    :root {
        --vf-node-bg: #fff;
        --vf-node-text: #222;
        --vf-connection-path: #b1b1b7;
        --vf-handle: #555;
    }

    .vue-flow__selection {
        background: rgba(0, 89, 220, 0.08);
        border: 1px dotted rgba(0, 89, 220, 0.8);
    }

    .vue-flow__edge.selected .vue-flow__edge-path {
        stroke: #555;
    }

    .vue-flow__edge.animated path {
        stroke-dasharray: 5;
        -webkit-animation: dashdraw 0.5s linear infinite;
        animation: dashdraw 0.5s linear infinite;
    }

    .vue-flow__edge.updating .vue-flow__edge-path {
        stroke: #777;
    }

    .vue-flow__edge-path {
        stroke: #b1b1b7;
        stroke-width: 1;
    }

    .vue-flow__edge-text {
        font-size: 10px;
    }

    .vue-flow__edge-textbg {
        fill: #fff;
    }

    .vue-flow__connection-path {
        stroke: var(--vf-connection-path);
        stroke-width: 1;
    }

    .vue-flow__node {
        cursor: -webkit-grab;
        cursor: grab;
    }

    .vue-flow__node-default,
    .vue-flow__node-input,
    .vue-flow__node-output {
        padding: 10px;
        border-radius: 3px;
        width: 150px;
        font-size: 12px;
        color: var(--vf-node-text);
        text-align: center;
        border-width: 1px;
        border-style: solid;

        background: var(--vf-node-bg);
        border-color: var(--vf-node-color);
    }

    .vue-flow__node-default.selected,
    .vue-flow__node-default.selected:hover,
    .vue-flow__node-input.selected,
    .vue-flow__node-input.selected:hover,
    .vue-flow__node-output.selected,
    .vue-flow__node-output.selected:hover {
        box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
    }

    .vue-flow__node-default .vue-flow__handle,
    .vue-flow__node-input .vue-flow__handle,
    .vue-flow__node-output .vue-flow__handle {
        background: var(--vf-handle);
    }

    .vue-flow__node-default.selectable:hover,
    .vue-flow__node-input.selectable:hover,
    .vue-flow__node-output.selectable:hover {
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
    }

    .vue-flow__node-input {
        --vf-node-color: #0041d0;
        --vf-handle: var(--vf-node-color);
        --vf-box-shadow: var(--vf-node-color);

        background: var(--vf-node-bg);
        border-color: var(--vf-node-color);
    }

    .vue-flow__node-default {
        --vf-node-color: #1a192b;
        --vf-handle: var(--vf-node-color);
        --vf-box-shadow: var(--vf-node-color);

        background: var(--vf-node-bg);
        border-color: var(--vf-node-color);
    }

    .vue-flow__node-output {
        --vf-node-color: #999;
        --vf-handle: var(--vf-node-color);
        --vf-box-shadow: var(--vf-node-color);
    }

    .vue-flow__nodesselection-rect {
        background: rgba(0, 89, 220, 0.08);
        border: 1px dotted #333;
    }

    .vue-flow__handle {
        width: 6px;
        height: 6px;
        background: var(--vf-handle);
        border: 1px solid #fff;
        border-radius: 100%;
    }

    .vue-flow__handle.connectable {
        cursor: crosshair;
    }

    .vue-flow__minimap {
        background-color: #fff;
    }

    .vue-flow__controls {
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
    }

    .vue-flow__controls-button {
        background: #fefefe;
        border-bottom: 1px solid #eee;
        box-sizing: content-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        padding: 5px;
    }

    .vue-flow__controls-button svg {
        max-width: 12px;
        max-height: 12px;
    }

    .vue-flow__controls-button:hover {
        background: #f4f4f4;
    }
</style>


<style>
    .vue-flow__edge-path {

        stroke-width: 4;
    }


    .vue-flow__edge.selected .vue-flow__edge-path {

        stroke-width: 5;
    }


    .vue-flow__minimap {
        transform: scale(85%);
        transform-origin: top right;
    }

    .basicflow .vue-flow__node.dark {
        background: #1C1C1C;
        color: #fffffb
    }

    .basicflow .controls {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 4;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0px
    }

    .basicflow .vue-flow__node-custom {
        border: 3px solid #777;
        padding: 10px;
        border-radius: 10px;
        background: red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom {
        border: 1px solid #333;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-start {
        border: 1px solid #333;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node.selected {
        border: 3px solid #333 !important;
        background: rgb(251, 251, 251) !important;

    }

    .basicflow .vue-flow__node-custom-end {
        border: 1px solid #333;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-batch {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }



    .basicflow .vue-flow__node-custom-sensor {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }


    .basicflow .vue-flow__node-custom-save {
        border: 1px solid #333;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-photo {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }


    .basicflow .vue-flow__node-custom-collection {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: green;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-code {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-stock {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }


    .basicflow .vue-flow__node-custom-stock-new {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: greenyellow;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-push {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-push-document-mail {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-api {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }



    .basicflow .vue-flow__node-custom-tasks {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-scan-code {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-document {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-print {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-import {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-export {
        border: 1px solid #999;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        font-weight: "bold";
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow .vue-flow__node-custom-send-mail {
        border: 1px solid #333;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }




    .basicflow .vue-flow__node-custom-qrcode {
        border: 1px solid #333;
        padding: 10px;
        border-radius: 3px;
        color: black;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        max-width: 250px
    }

    .basicflow button {
        padding: 5px;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3);
        box-shadow: 0 5px 10px #0000004d;
        cursor: pointer
    }

    .basicflow button:hover {
        opacity: .9;
        transform: scale(105%);
        transition: .25s all ease
    }

    .animated-bg-gradient {
        background: linear-gradient(122deg, #f4edf6, #81c7d4, #fedfe1, #fffffb);
        background-size: 800% 800%;
        -webkit-animation: gradient 4s ease infinite;
        -moz-animation: gradient 4s ease infinite;
        animation: gradient 4s ease infinite
    }

    .vue-flow__handle.connectable {
        border-width: 2px;
        border-style: solid;
        border-color: white
    }

    @-webkit-keyframes gradient {
        0% {
            background-position: 0% 22%
        }

        50% {
            background-position: 100% 79%
        }

        to {
            background-position: 0% 22%
        }
    }

    @-moz-keyframes gradient {
        0% {
            background-position: 0% 22%
        }

        50% {
            background-position: 100% 79%
        }

        to {
            background-position: 0% 22%
        }
    }

    @keyframes gradient {
        0% {
            background-position: 0% 22%
        }

        50% {
            background-position: 100% 79%
        }

        to {
            background-position: 0% 22%
        }
    }

    .basicflow .controls button {
        padding: 5px;
        border-radius: 5px;
        font-weight: 500;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3);
        box-shadow: 0 5px 10px #0000004d;
        cursor: pointer
    }

    .basicflow .controls button:hover {
        opacity: .8;
        transform: scale(105%);
        transition: .25s all ease
    }


    .dndflow .vue-flow-wrapper {
        flex-grow: 1;
        height: 100%
    }


    .updatenode__controls {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 4;
        font-size: 11px;
        background-color: #d3d3d3;
        border-radius: 10px;
        padding: 8px
    }

    .updatenode__controls label {
        display: blocK
    }

    .updatenode__controls input {
        padding: 2px;
        border-radius: 5px
    }

    .updatenode__bglabel {
        margin-top: 8px
    }

    .updatenode__checkboxwrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px
    }
</style>
<template>
  <div class="w-full">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres
    </h3>



    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Category</label>
        <select
          id="category"
          v-model="category"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        >
          <option
            v-for="item in selectTags"
            :key="item._id"
            :value="item._id"
          >
            {{ item.name[lg] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ContainerField',
        components: {

        },
        props: {

            format: Object
        },
        data() {
            return ({

                unit: "",
                editUnit: "",
                editFormat: "",
                units: [],
                distance: [
                    'm',
                    'cm',
                    'mm'
                ],
                volume: [
                    'l',
                    'cl',
                    'ml',
                    "m3"
                ],
                mass: [
                    'kg',
                    'g',
                    'mg',
                    'µg'
                ],
                energy: [
                    'kcal',
                    'cal',
                    'kj',
                    'j'
                ]
            })
        },
        watch: {

            config: function (val) { console.log(val); }
        },

       // mounted() {
       //     console.log("EditField Mounted")
       // },
        methods: {
            onChangeEditFormat: function (format) {

                if (format == "mass") { this.units = this.mass; this.editUnit = "g"; return; }
                if (format == "volume") { this.units = this.volume; this.editUnit = "l"; return; }
                if (format == "distance") { this.units = this.distance; this.editUnit = "cm"; return; }
                if (format == "energy") { this.units = this.energy; this.editUnit = "kcal"; return; }

                this.units = [];
                this.editUnit = "";

            },
            getEditUnit: function (format, unit) {
                if (format == 'percent') return unit;
                if (format == 'mass') return unit;
                if (format == 'volume') return unit;
                if (format == 'distance') return unit;
                if (format == 'energy') return unit;


                return "";
            },


        }
    }
</script>
<template>
  <div
    class="  w-full border-l-4 border-l-blue-600 flex flex-row bg-gray-100  rounded-sm border-t border-b border-r border-t-gray-300 border-b-gray-300 border-r-gray-300 "
  >
    <div class="w-96 flex items-center px-2 text-left truncate   ">
      <p class="truncate">
        {{ output.attribute }}
      </p>
    </div>
    <div
      v-if="output.type=='string'"
      class="flex flex-row w-full   "
    >
      <div class="w-full py-2 mr-2">
        <select
          v-model="output.action"
          class="form-control  rounded-sm py-1 border border-gray-300 px-2"
          @change="onChangeAction"
        >
          <option value="equals">
            equals
          </option>
          <option value="does-not-equal">
            does not equal
          </option>
          <option value="contains">
            contains
          </option>
          <option value="does-not-contain">
            does not contain
          </option>
          <option value="is-empty">
            is empty
          </option>
          <option value="is-not-empty">
            is not empty
          </option>
          <option value="begins-with">
            begins with
          </option>
          <option value="ends-with">
            ends with
          </option>
        </select>
      </div>
      <div class="w-full py-2">
        <input
          v-model="output.value"
          type="text"
          class="w-36 form-control    rounded-sm py-1 px-2 border border-gray-300"
        >
      </div>
    </div>
    <div
      v-if="output.type=='list'"
      class="flex flex-row w-full d"
    >
      <div class="flex items-center  justify-start w-full py-2 mr-2">
        <input
          id="bordered-radio-1"
          type="radio"
          value=""
          name="bordered-radio"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
        >
        <label
          for="bordered-radio-1"
          class="w-full py-2 text-sm font-medium text-gray-900 dark:text-gray-300 text-left ms-2"
        >Default radio</label>
      </div>
      <div class="flex items-center justify-start w-full py-2 mr-2">
        <input
          id="bordered-radio-2"
          checked
          type="radio"
          value=""
          name="bordered-radio"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
        >
        <label
          for="bordered-radio-2"
          class="w-full py-2   text-sm font-medium text-gray-900 dark:text-gray-300 text-left ms-2"
        >Checked state</label>
      </div>
    </div>

    <div
      class="w-24 flex items-center text-left justify-center cursor-pointer bg-gray-500"
      @click="onDeleteRow"
    >
      x
    </div>
  </div>
</template>


<script>



    export default {
        name: 'QueryBuilderRow',
        components: {},
        props: {
            index: Number,
            inputValue: Object
        },
        data: function () {
            return {
                output: {}
            }

        },
        watch:
        {
            inputValue: function () {
                this.output = this.inputValue
            }
        },
        async mounted() {
            this.output = this.inputValue

        },
        methods:
        {
            onChangeAction() {
                this.$emit("updateRow", this.output)
            },
            onDeleteRow()
            {
                this.output.index = this.index
                console.log("QueryBuilderRow","onDeleteRow")
                this.$emit("deleteRow", this.output)
            }
        }

    }
</script>
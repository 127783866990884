<template>
  <div class="w-full mt-12 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start bg-purlple-100  ">
    <div
      v-if="currentItem != undefined"
      class="w-full mb-6 flex flex-col items-start justify-start w-full"
    >
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Texte a afficher </label>
      <div class="flex flex-row justify-start items-center w-full">
        <textarea
          v-model="data"
          type="text"
          class="h-36 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'DeveloperCellText',
        components: {

        },
        props: {
            item: Object
        },

        data() {
            return ({
                lg: "FR",
                data: "",
                currentItem: {},

            })
        },
        computed: {

        },
        watch: {
            item: function (val) {
                let item = val;
                this.data = item.data


                this.currentItem = item;

            }
        },
        mounted() {
            let item = this.item;
            this.data = item.data

            this.currentItem = item;
        },

        methods: {

        }

    }
</script>
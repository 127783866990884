<template>
  <div class="relative w-full min-h-24  flex justify-center items-center p-2 flex-col ">
    <div>
      <div class="text-5xl" :class="class_counter">
        {{ counter }}
      </div>
      <div class="text-gray-300 text-xl" :class="class_unit">
        {{ config?.unit }}
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'CounterChart', 
    components: {},
    props: {
      parent: String,
   
      refresh: String,
      records: Object,
      config: Object
    },
    data: function () {
      return {

        counter: "-"
      };
    },
    computed: 
    {
      class_counter: function()
      {
        if (this.config?.class_counter) return this.config.class_counter;
        return {}
      },
      class_unit: function()
      {
        if (this.config?.class_unit) return this.config.class_unit;
        return {}
      }
    },
    watch: {
      'refresh': function () {
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },

      'records': async function (val) {
        //   console.log("Counter records vvvvvvvvvv")
        //   console.log(JSON.stringify(val))
        //   console.log("Counter records AAAAAAAA")



        if (val != undefined) {
          if (val.records != undefined) {
            if (val.records.length > 0) {
              this.counter = val.records[0].total
            }
          }
        }
        //let r = this.transformData(val);
        // if (r == undefined) r = { data: [], name: "" }
        // this.localrecords = r
        // this.series.data = r.series[0].data;

        //     this.series[0].name = r.series[0].name;
      }
    },


    async mounted() {

      //   this.chartOptions.subtitle.text = this.config?.title + '**mounted*title*';
      //    this.chartOptions.title.text = this.value + '**mounted*value*';
      //console.log("FigureSparklineChart mounted")
      //this.localrecords = sparklineData
      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })


    },
    methods: {

    }
  }
</script>
<template>
  <div class="p-4 sm:ml-64   ">
    <div class="w-full flex flex-row items-center">
      <div class="w-full flex justify-start text-2xl font-bold flex-col">
        <span class="w-full   text-left justify-start"> {{ displayTitle }}</span>
        <small class="w-full  justify-start text-left"><span> {{ displaySubTitle }}</span></small>
      </div>
      <div class="w-full flex justify-end">
        <button
          class="text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="saveForm"
        >
          Sauvegarder
        </button>
      </div>
    </div>

    <div class="p-6 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
      <div class="grid grid-cols-2 gap-4 mb-4 flex items-start">
        <div
          v-if="record != undefined"
          class="flex flex-col items-start justify-center rounded bg-gray-50  dark:bg-gray-800 overflow-y-auto"
        >
          <div
            :class="{'bg-blue-100': currentItem.id == record.id}"
            class=" text-sm font-bold px-4 cursor-pointer text-gray-900 bg-gray-50 border border-gray-200 rounded-lg shadow"
            @click="onClick(null)"
          >
            Formulaire
          </div>

          <div class="flex flex-col items-start   ">
            <nested-draggable
              parent="YES"
              :lines="lines"
              :selected-item-id="currentItem.id"
              @move="onNestedMove"
              @click-item="onClick"
            />
          </div>
        </div>
        <div class="hidden flex items-start justify-center rounded bg-gray-50  dark:bg-gray-800 overflow-y-auto">
          <div class="py-4 overflow-y-auto">
            <div @click="SelectNode(0)">
              Formulaire
            </div>
            <ul class=" font-medium pl-16">
              <li
                v-for="tab in record.children"
                :key="tab.id"
              >
                <button
                  type="button"
                  class="flex items-center w-full px-2 py-1 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  :aria-controls="`dropdown-tab-${tab.id}`"
                  :data-collapse-toggle="`dropdown-tab-${tab.id}`"
                  @click="SelectNode(1)"
                >
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 21"
                  >
                    <path
                      d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z"
                    />
                  </svg>
                  <span class="flex-1 ml-3 text-left whitespace-nowrap">
                    {{ tab.title[lg] }}
                  </span>
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <ul
                  :id="`dropdown-tab-${tab.id}`"
                  class="hidden py-2 space-y-2"
                >
                  <li
                    v-for="section in tab.body"
                    :key="section.id"
                  >
                    <button
                      type="button"
                      class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      :aria-controls="`dropdown-section-${tab.id}-${section.id}`"
                      :data-collapse-toggle="`dropdown-section-${tab.id}-${section.id}`"
                      @click="SelectNode(1)"
                    >
                      <span class="flex-1 ml-6 text-left whitespace-nowrap">
                        {{ section.title[lg] ? section.title[lg] : "<empty>" }}
                      </span>
                      <svg
                        class="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>
                    <ul
                      :id="`dropdown-section-${tab.id}-${section.id}`"
                      class="hidden py-1 space-y-2"
                    >
                      <li
                        v-for="line in section.items"
                        :key="line.id"
                      >
                        <button
                          type="button"
                          class="flex items-center w-full px-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                          :aria-controls="`dropdown-line-${tab.id}-${section.id}-${line.id}`"
                          :data-collapse-toggle="`dropdown-line-${tab.id}-${section.id}-${line.id}`"
                          @click="SelectNode(1)"
                        >
                          <span class="flex-1 ml-9 text-left whitespace-nowrap">
                            {{ line.id ? line.id : "<empty>" }}
                          </span>
                          <svg
                            class="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        <ul
                          :id="`dropdown-line-${tab.id}-${section.id}-${line.id}`"
                          class="hidden py-2 space-y-2"
                        >
                          <li
                            v-for="item in line.items"
                            :key="item.id"
                          >
                            <button
                              class="ml-3 flex items-center w-full px-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                              @click="SelectItem(item)"
                            >
                              {{ item.label ? item.label[lg] : item.id }}
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex flex-col items-start justify-start px-6 rounded bg-gray-50 dark:bg-gray-800">
          <h1 class="w-full font-bold mt-2 text-xl">
            Propriétés de l'élement
          </h1>

          <DeveloperForm
            v-if="index==0"
            class="w-full"
            :item="currentItem"
            @add-tab="onAddTab"
            @update="onUpdateForm"
          />
          <DeveloperTab
            v-if="index==1"
            class="w-full"
            :item="currentItem"
            @add-section="onAddSection"
            @update="onUpdateTab"
          />
          <DeveloperSection
            v-if="index==2"
            class="w-full"
            :item="currentItem"
            @add-column="onAddColumn"
            @update="onUpdateSection"
          />
          <DeveloperColumn
            v-if="index==3"
            class="w-full"
            :item="currentItem"
            @update="onUpdateColumn"
            @add-item="onAddItem"
          />
          <DeveloperItem
            v-if="index==100"
            class="w-full"
            :item="currentItem"
            @update="onUpdateItem"
            @add-item="onAddBrother"
            @duplicate-item="onDuplicateBrother"
          />
        </div>
      </div>
      <span class=" bg-green-400 ">

        {{ JSON.stringify(lines, null,"\t") }}
      </span>

      <hr>
      <span class="hidden ">
        {{ JSON.stringify(fulllines, null,"\t") }}
      </span>
      <hr>
      <span class="bg-orange-300">
        {{ JSON.stringify(record, null,"\t") }}
      </span>
      <hr>
      <span class="bg-blue-300">
        {{ JSON.stringify(model, null,"\t") }}
      </span>
    </div>
  </div>
</template>

<script>
 
  import draggable from 'vuedraggable'
  import DeveloperForm from "@/components/developer/DeveloperForm.vue";

  import DeveloperItem from "@/components/developer/DeveloperItem.vue";
  import DeveloperTab from "@/components/developer/DeveloperTab.vue";
  import DeveloperSection from "@/components/developer/DeveloperSection.vue";
  import DeveloperColumn from "@/components/developer/DeveloperColumn.vue";

  import nestedDraggable from "./nested";
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  // eslint-disable-next-line

  //const ID = "64ce6bfe265268079b605508";//"64c9ff81ae9f4714d49d6056";

  export default {
    name: 'DeveloperFormView',
    components: {
      // eslint-disable-next-line
      draggable, nestedDraggable, DeveloperForm, DeveloperItem, DeveloperTab, DeveloperSection, DeveloperColumn
    },
    props: {
   
    },
    data() {
      return ({
        id: "",

        select_type: "predefined",
        lines: [],
        model: [],
        fulllines: [],
        predefined_records: [],
        record: {},
        lg: "FR",
        currentItem: {},
        index: 0
      })
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      displayTitle: function () {
        if (this.record && this.record.title) {
          return this.record.title[this.lg]
        }
        return ""
      },
      displaySubTitle: function () {
        if (this.record && this.record.id) {
          return this.record.id
        }
        return ""
      }
    },
   
    watch: {

      'model': function () {
       
      }

    },
    async mounted() {
      this.id = this.$route.params.id;
      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login?redirect=/admin/developer/forms/" + this.id;
        return;
      }
 
      this.record = await service.getDataId("ui-forms/fix", this.id, this.user?.token);
 
      this.fulllines = this.mapData(this.record)

      let response = await service.getData("select-values", this.user?.token, { category: "value-category" })
      if (response != undefined)
        this.predefined_records = response.records;

      this.lines = this.selectData(null, this.fulllines)


      this.currentItem = this.record;
      //delete this.currentItem.children;



    },
    methods: {
      onAddTab: function () {
   
        this.lines.push(
          {
            id: "root_node.lines[i].id",
            type: "tab",
            raw: null,
            parent: "",
            name: "nouveau onglet"
          }
        )
      },
      onAddSection: function (item) {
     
        let node = this.findNode(item.id, this.lines)
        node.lines.push(
          {
            id: "root_node.lines[i].id",
            type: "column",
            raw: null,
            parent: "",
            name: "nouvelle column"
          }
        )

        //Selectionner la page
      },
      onAddColumn: function (item) {
     
    
        let node = this.findNode(item.id, this.lines)
        node.lines.push(
          {
            id: "root_node.lines[i].id",
            type: "section",
            raw: null,
            parent: "",
            name: "nouvelle section"
          }
        )

        //Selectionner la page
      },


      onAddItem: function (item) {
 

        
        let list = this.findChildList("home,section 1," + item.id);


        if (list == undefined) {
    
          return;
        }
        let newItemRecord = {
          "id": "4500",
          "label": {
            "EN": "New",
            "FR": "New"
          },
          "data": "new-data",
          "type": "text"
        }

        list.push(newItemRecord)

        this.setRecord(item.id, list)






        let node2 = this.findNode(item.id, this.fulllines)
        node2.lines.push(
          {
            id: "4500",
            type: "item",
            raw: list[1],
            parent: "home,section 1," + item.id,
            name: "nouvelle item"
          }
        )


        let node = this.findNode(item.id, this.lines)
        node.lines.push(
          {
            id: "4500",
            type: "item",
            raw: list[1],
            parent: "home,section 1," + item.id,
            name: "nouvelle item"
          }
        )

        //Selectionner la page
      },



      onAddBrother: function (/*item*/) {

 


/*
        let list = this.findParentList("home,section 1,row2," + item.id);
        if (list == undefined) {
     
          return;
        }
     

        let newItemRecord = {
          "id": "7500",
          "label": {
            "EN": "New",
            "FR": "New"
          },
          "data": "new-data",
          "type": "text"
        }

        list.push(newItemRecord)

        //    this.setRecord(item.id, list)

        let node2 = this.findNode(item.id, this.fulllines)
        node2.lines.push(
          {
            id: "4500",
            type: "item",
            raw: list[1],
            parent: "home,section 1," + item.id,
            name: "nouvelle item"
          }
        )


        let node = this.findNode(item.id, this.lines)
        node.lines.push(
          {
            id: "4500",
            type: "item",
            raw: list[1],
            parent: "home,section 1," + item.id,
            name: "nouvelle item"
          }
        )

        //Selectionner la page


        */
      },



      onUpdateColumn: function (/*item*/) {
    

      },
      onUpdateSection: function (/*item*/) {
      

      },
      onUpdateItem: function (/*item*/) {
      

      },
      onUpdateTab: function (/*item*/) {
      

      },
      onUpdateForm: function (/*item*/) {
     

      },
      saveForm: async function () {

       // let response = await service.updateRecord("ui-forms", this.id, this.user?.token, this.record)
   
      },
      onClick: function (item) {
        if (item == undefined) {

          this.currentItem = this.record;
          delete this.currentItem.children;

          this.index = 0;
          return;
        }
        let id = item.id
  
        let children = this.selectData(id, this.fulllines)
        if (children != null) {
        
          let node = this.findNode(id, this.lines)
          if (node != undefined) {
            if ((node.lines != undefined) && (node.lines.length > 0)) {
            
              node.lines = null
            }
            else {
         
              node.lines = children
            }

          }
       //   else {
         
       //   }

        }


        if (item.type == 'tab') {
          this.currentItem = item.raw;
          this.index = 1;
        }
        if (item.type == 'section') {

          this.currentItem = item.raw;
          this.index = 2;
        }
        if (item.type == 'col') {
          this.currentItem = item.raw;
          this.index = 3;
        }
        if (item.type == 'item') {

         
          this.currentItem = item.raw;
          this.index = 100;
        }
      },
      findNode: function (node, data) {
     
        if (node == null) return { lines: data };
        if (Array.isArray(data) == null) return null;

        for (let i = 0; i < data.length; i++) {
       
          if (data[i].id == node) return data[i];
        }

        for (let j = 0; j < data.length; j++) {
        
          if (data[j].lines != undefined) {
            let r = this.findNode(node, data[j].lines);
         
            if (r != null) return r;
          }
        }
        return null;

      },

      findParent: function (node, data) {

        // A REVOIR
   
        if (node == null) return { lines: data };
        if (Array.isArray(data) == null) return null;

        for (let i = 0; i < data.length; i++) {
       
          if (data[i].id == node) return data[i];
        }

        for (let j = 0; j < data.length; j++) {
       
          if (data[j].lines != undefined) {
            let r = this.findNode(node, data[j].lines);
          
            if (r != null) return r;
          }
        }
        return null;

      },




      selectData: function (root, data) {
      
        let result = []

        //Find the root
        let root_node = this.findNode(root, data)
 
        if (root_node == undefined) return null;
        if (root_node.lines == undefined) return null;
        for (let i = 0; i < root_node.lines.length; i++) {
          result.push(
            {
              id: root_node.lines[i].id,
              type: root_node.lines[i].type,
              raw: root_node.lines[i].raw,
              parent: root_node.lines[i].parent,
              name: (root_node.lines[i].name != "") ? root_node.lines[i].name : (root_node.lines[i].id ? root_node.lines[i].id : "??")
            }
          )
        }
        return result
      },
      mapData: function (record) {
        let lines = [];
        for (let i1 = 0; i1 < record.children.length; i1++) {
          let line1 = [];
          if (record.children[i1].body != undefined) {
            for (let i2 = 0; i2 < record.children[i1].body.length; i2++) {
              let line2 = [];
              if (record.children[i1].body[i2].items != undefined) {
                for (let i3 = 0; i3 < record.children[i1].body[i2].items.length; i3++) {
                  let line3 = [];
                  if (record.children[i1].body[i2].items[i3].items != undefined) {
                    for (let i4 = 0; i4 < record.children[i1].body[i2].items[i3].items.length; i4++) {
                      line3.push(
                        {
                          id: record.children[i1].body[i2].items[i3].items[i4].id,
                          type: "item",
                          parent: record.children[i1].id + "," + record.children[i1].body[i2].id + "," + record.children[i1].body[i2].items[i3].id,

                          raw: record.children[i1].body[i2].items[i3].items[i4],
                          name: record.children[i1].body[i2].items[i3].items[i4].label ? record.children[i1].body[i2].items[i3].items[i4].label : { FR: record.children[i1].body[i2].items[i3].items[i4].type + " #" + i1 + "-" + i2 + "-" + i3, EN: "Item #" + i1 + "-" + i2 + "-" + i3 }
                        })
                    }


                  }

                  let raw = record.children[i1].body[i2].items[i3];
                  //delete raw.items;
                  line2.push(
                    {
                      id: record.children[i1].body[i2].items[i3].id ? record.children[i1].body[i2].items[i3].id : i1 + "-" + i2 + "-" + i3,
                      name: record.children[i1].body[i2].items[i3].title ? record.children[i1].body[i2].items[i3].title : { FR: "Colonne #" + i1 + "-" + i2 + "-" + i3, EN: "Column #" + i1 + "-" + i2 + "-" + i3 },// record.children[i1].body[i2].title, //items[i3].id 
                      type: "col",
                      raw: raw,
                      parent: record.children[i1].id + "," + record.children[i1].body[i2].id,
                      lines: line3
                    })

                }
              }
              let raw = record.children[i1].body[i2];
              //delete raw.items;
              line1.push(
                {
                  id: record.children[i1].body[i2].id ? record.children[i1].body[i2].id : i1 + "-" + i2,
                  name: record.children[i1].body[i2].title ? record.children[i1].body[i2].title : { FR: "FR i3=" + i2, "EN": "EN i3=" + i2 },
                  type: "section",
                  raw: raw,
                  parent: record.children[i1].id,
                  lines: line2
                })
            }
          }
          let raw = record.children[i1];
          //delete raw.body;
          lines.push(
            {
              id: record.children[i1].id,
              name: record.children[i1].title,
              type: "tab",
              raw: raw,
              parent: "",
              lines: line1
            }
          )
        }
        return lines;
      },
      SelectItem: function (item) {

        this.currentItem = item;
   
        this.index = 100;

      },
      SelectNode: function (index) {
        this.index = index
        return true;
      },
      SelectType: function (index) {
        this.currentItem.type = index
        return true;
      },
      move: function (array, from, to) {
        if (to === from) return array;
        var target = array[from];
        var increment = to < from ? -1 : 1;
        for (var k = from; k != to; k += increment) {
          array[k] = array[k + increment];
        }
        array[to] = target;
        return array;
      },
      findChildList: function (parent) {

        if (parent == "") return this.record.children;
        let s = parent.split(",")
     
        let response = null
        for (let i1 = 0; i1 < this.record.children.length; i1++) {
          let c1 = this.record.children[i1];
    
          if (s[0] == c1.id) {
       
            if (s[1] == undefined) {
              return c1.body;
            }
            else {
              for (let i2 = 0; i2 < c1.body.length; i2++) {
                let c2 = c1.body[i2];
           
                if (s[1] == c2.id) {
                  if (s[2] == undefined) {
                    return c2.items;
                  }
                  else {
                    for (let i3 = 0; i3 < c2.items.length; i3++) {
                      let c3 = c2.items[i3];
                  
                      if (s[2] == c3.id) {
                        return c3.items
                      }
                      else {
                        for (let i4 = 0; i4 < c3.items.length; i4++) {
                          let c4 = c3.items[i4];
                          if (s[3] == c4.id) {
                            return c4.items
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
        return response;
      },



      findParentList: function (parent) {
        if (parent == "") return this.record.children;
        let s = parent.split(",")
   
        let response = null
        for (let i1 = 0; i1 < this.record.children.length; i1++) {
          let c1 = this.record.children[i1];
     
          if (s[0] == c1.id) {
        
            if (s[1] == undefined) {
              return null;
            }
            else {
              for (let i2 = 0; i2 < c1.body.length; i2++) {
                let c2 = c1.body[i2];
              
                if (s[1] == c2.id) {
                  if (s[2] == undefined) {
                    return c1.body;
                  }
                  else {
                    for (let i3 = 0; i3 < c2.items.length; i3++) {
                      let c3 = c2.items[i3];
                 
                      if (s[2] == c3.id) {
                        return c2.items;
                      }
                      else {
                        for (let i4 = 0; i4 < c3.items.length; i4++) {
                          let c4 = c3.items[i4];
                          if (s[3] == c4.id) {
                            return c3.items
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
        return response;
      },



      setRecord: function (parent, list) {
    

        if (parent == "") return this.record.children;
        let s = parent.split(",")

        let response = null
        for (let i1 = 0; i1 < this.record.children.length; i1++) {
          let c1 = this.record.children[i1];
 
          if (s[0] == c1.id) {
      
            if (s[1] == undefined) {

              this.record.children = list

            }
            else {
              for (let i2 = 0; i2 < c1.body.length; i2++) {
                let c2 = c1.body[i2];
       
                if (s[1] == c2.id) {
                  if (s[2] == undefined) {
                    c1.body = list;
                  }
                  else {
                    for (let i3 = 0; i3 < c2.items.length; i3++) {
                      let c3 = c2.items[i3];
                      if (s[3] == c3.id) {
                        c2.items = list
                      }
                    }
                  }
                }
              }
            }
          }

        }
        return response;
      },


      onNestedMove: function (item) {
   
        if (item.draggedContext == undefined) return;
  
        if (item.relatedContext == undefined) return;

      
        let list = this.findChildList(item.draggedContext.element.parent);


        if (list == undefined) {
    
          return;
        }

      


        list = this.move(list, item.draggedContext.index, item.relatedContext.index)

        this.setRecord(item.draggedContext.element.parent, list)
 

        //this.record.children = list;

      },
      reverseMapData: function () {

        let newChildren = [];


        for (let i1 = 0; i1 < this.lines.length; i1++) {
          let newChildren2 = [];
          let child1 = this.lines[i1];
          let c1 = JSON.parse(JSON.stringify(child1.raw))
          delete c1.body;

  
          if (child1.lines != undefined) {
            for (let i2 = 0; i2 < child1.lines.length; i2++) {
        
              let child2 = child1.lines[i2];

              let c2 = JSON.parse(JSON.stringify(child2.raw))
              delete c2.items;

              if (child2.lines != undefined)
                for (let i3 = 0; i3 < child2.lines.length; i3++) {
                  let child3 = child2.lines[i3];

                  if (child3.lines != undefined)
                    for (let i4 = 0; i4 < child3.lines.length; i4++) {
                      // let child4 = child3.lines[i4];
         


                    }

                }



              newChildren2.push(c2)

            }
          }

          c1.body = newChildren2;
          newChildren.push(c1);
        }


        return newChildren;

      }

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
  .dragArea {
    min-height: 50px;
    outline: 1px dashed;
  }
</style>
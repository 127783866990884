<template>
  <div
    class="  w-full border-l-4 border-l-green-600 flex flex-col  bg-white  rounded-sm border-t border-b border-r border-t-gray-300 border-b-gray-300 border-r-gray-300 "
  >
    <div class="w-full flex flex-row items-center text-left bg-gray-100  border-b border-b-gray-300 ">
      <div class="w-full flex flex-row px-2 py-4 items-center justify-start">
        <div class="pr-3">
          Match Type
        </div>
        <div class=" ">
          <select
            id="vqb-match-type"
            class="rounded-sm py-1 border border-gray-300 px-2"
          >
            <option value="all">
              All
            </option>
            <option value="any">
              Any
            </option>
          </select>
        </div>
      </div>

      <div
        class="w-6 cursor-pointer bg-gray-500"
        @click="onDeleteGroup"
      >
        x
      </div>
    </div>
    <div class="w-full p-3">
      <div class=" ">
        <div class="flex flex-row justify-start items-center">
          <select
            v-model="choice"
            class="rounded-sm py-1 border border-gray-300 px-2"
          >
            <option value="first_name">
              First Name
            </option>
            <option value="last_name">
              Last Name
            </option>
            <option value="plan">
              Plan Type
            </option>
          </select>
          <button
            type="button"
            class="rounded-sm bg-gray-700 text-white mx-2 py-1 px-2"
            @click="onAddRule"
          >
            Add Rule
          </button>
          <button
            type="button"
            class="rounded-sm bg-gray-700 text-white mx-2 py-1 px-2"
            @click="onAddGroup"
          >
            Add Group
          </button>
        </div>
      </div>
    </div>
    <div class="w-full p-3">
      <QueryBuilderRows
        :input-value="output"
        @delete-row="onDeleteRow"
        @update-value="onUpdateValues"
      />
    </div>
  </div>
</template>


<script>
  import { v4 }  from 'uuid' ;
    import QueryBuilderRows from "@/components/QueryBuilder/QueryBuilderRows.vue";

    export default {
        name: 'QueryBuilderGroup',
        components: { QueryBuilderRows },
        props: {
            inputValue: Object
        },
        data: function () {
            return {
                output: {},
                choice: ""
            }

        },
        watch:
        {
            inputValue: function () {
                this.output = this.inputValue
            }
        },
        async mounted() {
            this.output = this.inputValue

        },
        methods:
        {
            onAddRule() {



                let item = {

                    id: v4(),
                    type: "string",
                    name: "Test",

                    attribute: this.choice,
                    action: "contains",
                    value: ""
                }

                this.output.rules.push(item)

                let newevent = { id: this.output.id, rules: this.output.rules }
                this.$emit("updateValue", newevent)
            },

            onAddGroup() {
                let item = {

                    id: v4(),
                    type: "group",
                    rules: []
                }

                this.output.rules.push(item)

                let newevent = { id: this.output.id, rules: this.output.rules }
                this.$emit("updateValue", newevent)
            },
            onChangeAction() {
                this.$emit("updateRow", this.output)
            },
            onDeleteRow(event) {
                console.log("QueryBuilderGroup", "onDeleteRow")
                this.$emit("deleteRow", event)
            },
            onDeleteGroup() {
                console.log("QuryBuilderGroup", "onDeleteGroup")
                this.$emit("deleteGroup", this.output)
            },
            onUpdateValues(event) {
                console.log("QueryBuilderGroup", "onUpdateValues")
                this.$emit("updateValue", event)
            }
        }

    }
</script>
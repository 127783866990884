<script>

    import { defineComponent } from 'vue'
    import lg_register from "@/assets/lg/flow_designer.json"

    export default defineComponent({
        data() {
            return {
                lg_register: lg_register,
                lg: "FR"
            }
        },
        methods:
        {
            onDragStart: function (event, nodeType) {
                if (event.dataTransfer) {
                    event.dataTransfer.setData('application/vueflow', nodeType)
                    event.dataTransfer.effectAllowed = 'move'
                }
            }
        }
    })

</script>

<template>
  <aside class="dndflow_aside h-screen overflow-hidden ">
    <div class="description">
      {{ lg_register['TASK_SIDEBAR_INFO'][lg] }}
    </div>
    <div
      class="nodes  overflow-auto"
      style="height:calc(100% - 40px)"
    >
      <div
        class="h-16  flex items-center vue-flow__node-input"
        :draggable="true"
        @dragstart="onDragStart($event, 'input')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-play mr-6"
              style="font-size:20px"
            />
          </div>
          <div
            class="w-10/12 flex    items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_START'][lg] }}</strong>
          </div>
        </div>
      </div>
      <div
        class="h-16  flex items-center vue-flow__node-default"
        :draggable="true"
        @dragstart="onDragStart($event, 'default')"
      >
        <div class="flex flex-row">
          <div class="w-2/12">
            <i
              class="fa-regular fa-keyboard  mr-6"
              style="font-size:28px"
            />
          </div>
          <div
            class="w-10/12  flex  items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_MIDDLE'][lg] }}</strong>
          </div>
        </div>
      </div>
      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'output')"
      >
        <div class="flex flex-wrap">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-star-of-life  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_END'][lg] }}</strong>
          </div>
        </div>
      </div>
      <div
        class="h-16  flex items-center vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'import')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-file-import  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12  flex justify-center items-center">
            <strong class="ml-2">
              {{ lg_register['TASK_IMPORT'][lg] }}</strong>
          </div>
        </div>
      </div>
      <div
        class="h-16  flex items-center vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'export')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-file-export  mr-6"
              style="font-size:28px"
            />
          </div>
          <div
            class="w-10/12 flex  justify-center items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_EXPORT_XLS'][lg] }}</strong>
          </div>
        </div>
      </div>

      <div
        class="h-16  flex items-center vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'send-mail')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-envelope  mr-6"
              style="font-size:28px"
            />
          </div>
          <div
            class="w-10/12 flex  justify-center items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_SEND_MAIL'][lg] }}</strong>
          </div>
        </div>
      </div>

      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'batch')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa  fa-list-ol  mr-6"
              style="font-size:28px"
            />
          </div>
          <div
            class="w-10/12 flex  justify-center items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_BATCH'][lg] }}</strong>
          </div>
        </div>
      </div>



      <div
        class="h-16 flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'print')"
      >
        <div class="flex flex-wrap">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-print  mr-6"
              style="font-size:28px"
            />
          </div>
          <div
            class="w-10/12 flex justify-center items-center"
            style="text-align: center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_PRINT'][lg] }}</strong>
          </div>
        </div>
      </div>

      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'sensor')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-temperature-three-quarters  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_SENSOR'][lg] }}</strong>
          </div>
        </div>
      </div>


      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'photo')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-camera  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_PHOTO'][lg] }}</strong>
          </div>
        </div>
      </div>
 
      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'document')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-file-invoice  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_DOCUMENT'][lg] }}</strong>
          </div>
        </div>
      </div>
      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'collection')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-database  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_COLLECTION'][lg] }}</strong>
          </div>
        </div>
      </div>


      <div
        class="hidden h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'scan-code')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-barcode  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_SCAN_CODE'][lg] }}</strong>
          </div>
        </div>
      </div>



      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'code')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-code  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_CODE'][lg] }}</strong>
          </div>
        </div>
      </div>




      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'controls')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-arrows-turn-to-dots   mr-6"
              style="font-size:28px"
            />
          </div>
          <div class=" w-10/12 flex justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_CONTROLS'][lg] }}</strong>
          </div>
        </div>
      </div>

      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'api')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid  fa-solid fa-code-compare   mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_API'][lg] }}</strong>
          </div>
        </div>
      </div>

      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'save')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-database  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_SAVE'][lg] }}</strong>
          </div>
        </div>
      </div>

      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'stock')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-boxes-stacked  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_STOCK'][lg] }}</strong>
          </div>
        </div>
      </div>


      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'stock-new')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-boxes-stacked  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_STOCK'][lg] }} - NEW</strong>
          </div>
        </div>
      </div>



      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'push')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-message  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_PUSH'][lg] }}</strong>
          </div>
        </div>
      </div>


      <div
      class="h-16  flex items-center  vue-flow__node-output"
      :draggable="true"
      @dragstart="onDragStart($event, 'push-document-mail')"
    >
      <div class="flex flex-row">
        <div class="w-2/12 flex  items-center">
          <i
            class="fa-solid fa-message  mr-6"
            style="font-size:28px"
          />
        </div>
        <div class="w-10/12 flex  justify-center items-center">
          <strong
            class="ml-2"
          >{{ lg_register['TASK_PUSH_DOCUMENT_MAIL'][lg] }}</strong>
        </div>
      </div>
    </div>




      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'tasks')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa-solid fa-stack-exchange  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_TASKS'][lg] }}</strong>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<style>
    .dndflow aside {

        color: #fff;
        font-weight: 700;
        border-right: 1px solid #eee;
        top: 60px;
        left: 0px;

        font-size: 12px;
        /*  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3);
        box-shadow: 0 5px 10px #0000004d;*/
        min-width: 120px !important;
        width: 260px;
        padding-top: 60px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-left: 10px;
    }


    .dndflow aside .nodes>* {
        margin-bottom: 0px;
        cursor: grab;
        font-weight: 500;
        /*  -webkit-box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, .25);
        box-shadow: 5px 5px 10px 2px #00000040*/
    }

    .dndflow aside .description {
        margin-bottom: 10px;
        color: cornflowerblue
    }

    .dndflow .vue-flow-wrapper {
        flex-grow: 1;
        height: 100%
    }

    .dndflow aside .nodes {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;
    }

    .dndflow {
        flex-direction: column;
        display: flex;
        height: 100%
    }

    @media screen and (min-width: 640px) {
        .dndflow {
            flex-direction: row
        }

        .dndflow aside {
            min-width: 120px
        }
    }

    @media screen and (max-width: 639px) {
        .dndflow aside .nodes {
            display: flex;
            flex-direction: row;
            gap: 5px
        }


        @media screen and (min-width: 640px) {
            .dndflow {
                flex-direction: row
            }


        }

        @media screen and (max-width: 639px) {
            .dndflow aside .nodes {
                display: flex;
                flex-direction: row;
                gap: 5px
            }
        }
    }
</style>
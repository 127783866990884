<template>
  <div class="w-full">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres {{ collection }} (tag)
    </h3>

    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="collection"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Collection</label>
        <select
          id="collection"
          v-model="collection"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @change="onChangeCollection"
        >
          <option
            v-for="item in values"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name[lg] }}
          </option>
        </select>
      </div>
      <div class="grid gap-4 mb-4 sm:grid-cols-2">
        <div>
          <label
            for="Filter"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Filtre</label>
          <input
            id="Filter"
            v-model="filter"
            type="text"
            name="Filter"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder=""
            @change="onChangeCollection"
            @input="onChangeCollection"
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TagField',
        components: {

        },
        props: {
            values: Array,
            input: String
        },
        data() {
            return ({

                collection: "",
                filter: "",
                lg: "FR"

            })
        },
        watch: {

            input: function () {
                this.collection = this.input?.collection
                this.filter = this.input?.filter

            }
        },

        mounted() {
         //   console.log("EditField Mounted")
            this.collection = this.input?.collection
            this.filter = this.input?.filter
        },
        methods: {
            onChangeFilter: function () {
                this.$emit("changeValue", { collection: this.collection, filter: this.filter })

            },
            onChangeCollection: function () {
                this.$emit("changeValue", { collection: this.collection, filter: this.filter })

            }
        }
    }
</script>
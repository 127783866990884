<template>
  <!-- drawer component -->
  <div
    id="drawer-dashboard-indicators"
    class="fixed top-0 right-0 z-50 h-screen py-4 overflow-y-hidden transition-transform translate-x-full bg-white w-2/3 dark:bg-gray-800 justify-start text-left"
    tabindex="-1"
    aria-labelledby="drawer-right-label"
  >
   
    <h5
      id="drawer-right-label"
      class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400 px-4"
    >
      <svg
        class="w-4 h-4 me-2.5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>Ajouter des indicateurs
    </h5>
    <button
      type="button"
      data-drawer-hide2="drawer-right-examples"
      aria-controls="drawer-right-examples"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
      @click="onClose"
    >
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
      <span class="sr-only">Close menu</span>
    </button>
    <p class="hidden px-4 mb-6 text-sm text-gray-500 dark:text-gray-400">
      Supercharge your hiring by taking advantage
      of our <a
        href="#"
        class="text-blue-600 underline font-medium dark:text-blue-500 hover:no-underline"
      >limited-time
        sale {{ selectedCount }}</a>
      for Flowbite Docs + Job Board. Unlimited access to over 190K top-ranked candidates and the #1 design job
      board.
    </p>
    <div class=" px-4 mb-6 text-sm text-gray-500 dark:text-gray-400 flex justify-start flex-col">
      <div>
        Nombre d'indicateurs sélectionnés
        {{ selectedCount }} / 5
      </div>
      <div
        class="flex justify-end  bg-gray-50 mr-3 cursor-pointer"
        @click="onAddIndicators"
      >
        <button
          type="button"
          class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          @click="onSaveSheet"
        >
          Ajouter
        </button>
      </div>
    </div>
    <div class="hidden grid grid-cols-2 gap-4">
      <a
        href="#"
        class="px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >Learn
        more</a>
      <a
        href="#"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >Get
        access <svg
          class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg></a>
    </div>


    <div class=" border-b border-gray-200 dark:border-gray-700">
      <ul
        id="dashboard-drawer-tab"
        class="flex flex-wrap -mb-px text-sm font-medium text-center"
        data-tabs-toggle2="#dashboard-drawer-tab-content"
        role="tablist"
      >
        <li
          v-for="group in catalog?.groups"
          :key="group.id"
          class="me-2"
          role="presentation"
        >
          <button
            :id="`dashboard-drawer-tab-${group.id}`"
            class="inline-block p-4 border-b-2 rounded-t-lg"
            :data-tabs-target2="`#dashboard-drawer-${group.id}`"
            type="button"
            role="tab"
            :aria-controls="`dashboard-drawer-tab-${group.id}`"
            aria-selected="false"
            @click="selectTab(group)"
          >
          **********  {{ group.name[lg] }}  +++++++++++++
          </button>
        </li>
      </ul>
    </div>
    <div
      id="dashboard-drawer-tab-content"
      class="bg-gray-100 min-h-96  flex  min-h-full  items-stretch overflow-y-auto"
    >
      <div
        v-for="group in catalog?.groups"
        :id="`dashboard-drawer-${group.id}`"
        :key="group.id"
        class="hidden p-4    dark:bg-gray-800"
        role="tabpanel"
        :aria-labelledby="`#dashboard-drawer-tab-${group.id}`"
      >
        <div
          id="accordion-open"
          data-accordion="open"
        >
          <div
            v-for="section in group?.sections"
            :key="section.id"
          >
            <h2 :id="`dashboard-drawer-accordion-open-heading-${section.id}`">
              <button
                type="button"
                class="flex items-center w-full p-5 font-medium text-gray-500 gap-3"
                :data-accordion-target2="`#dashboard-drawer-accordion-open-body-${section.id}`"
                aria-expanded="true"
                :aria-controls2="`dashboard-drawer-accordion-open-body-${section.id}`"
                @click="toggleSection(section.id)"
              >
                <svg
                  data-accordion-icon
                  class="w-3 h-3 rotate-180 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
                <span class="flex items-center justify-start  font-semibold text-lg">
                  {{ section.name[lg] }}</span>
              </button>
            </h2>
            <div
              :id="`dashboard-drawer-accordion-open-body-${section.id}`"
              class="hidden"
              :aria-labelledby2="`dashboard-drawer-accordion-open-heading-${section.id}`"
            >
              <div class="p-4 ">
                <div class="grid grid-cols-2 md:grid-cols-4 gap-5 ">
                  <div
                    v-for="indicator in section?.indicators"
                    :key="indicator._id"
                    href="#"
                    class="relative cursor-pointer block max-w-sm p-0 bg-white border border-gray-200 rounded-lg shadow hover:border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                    @click="selectIndicator(indicator)"
                  >
                    <h5
                      class="h-10 px-2 flex bg-gray-100 rounded-lg flex items-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white"
                    >
                      <div class="w-full">
                        {{ indicator.name[lg] }}
                      </div>
                      <div class="w-8  ">
                        <i
                          v-if="selected[indicator._id] != undefined"
                          class="fa-solid fa-check fa-xl"
                        />
                      </div>
                    </h5>
                    <div class="h-32  flex items-center justify-center pb-1">
                      <img
                        class="h-auto grayscale"
                        :src="`https://files.b4food.io/b4food/images/${indicator.image_id}`"
                      >
                    </div>
                    <p
                      class="h-16 px-2 font-normal text-xs text-gray-400 dark:text-gray-400 border-t border-t-gray-200"
                    >
                      {{ indicator.description[lg] }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>





<script>
  import { Tabs } from 'flowbite';
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  import { Collapse } from 'flowbite';
  export default {
    name: 'DashboardDrawer',
    components: {

    },
    props: {
      input: Number
    },
    data: function () {
      return {
        catalog: {},
        lg: "FR",
        tabs: {},
        sections: {},
        selected: {}
      }

    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      selectedCount: function () {
        return Object.keys(this.selected).length


      }
    },

    watch: {

      input: async function () {
        this.selected = {}
        await this.getIndicatorCatalog()
      }
    },
    async mounted() {

      await this.getIndicatorCatalog()


    },

    methods: {

      selectTab: function (tab) {
        console.log("selectTab")


        if (this.tabs['dashboard-drawer-tab'] == null) {

          const tabsElement = document.getElementById('dashboard-drawer-tab');
          // create an array of objects with the id, trigger element (eg. button), and the content element


          let tabElements = []
          for (let i = 0; i < this.catalog?.groups.length; i++) {

            console.log('#dashboard-drawer-tab-' + this.catalog?.groups[i].id)
            console.log('#dashboard-drawer-' + this.catalog?.groups[i].id)
            let item = {
              id: this.catalog?.groups[i].id,
              triggerEl: document.querySelector('#dashboard-drawer-tab-' + this.catalog?.groups[i].id),
              targetEl: document.querySelector('#dashboard-drawer-' + this.catalog?.groups[i].id),
            }

            tabElements.push(item)
          }
          console.log(JSON.stringify(tabElements))

          // options with default values
          const options = {
            defaultTabId: tab.id,
            activeClasses:
              'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
            inactiveClasses:
              'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
            onShow: () => {
              console.log('tab is shown');
            },
          };

          // instance options with default valuesuser?.isAdmin
          const instanceOptions = {
            id: 'dashboard-drawer-tab',
            override: true
          };

          new Tabs(tabsElement, tabElements, options, instanceOptions);
        }
      },


      onAddIndicators() {

        let items = []
        let keys = Object.keys(this.selected)
        for (let i = 0; i < keys.length; i++) {
          items.push(this.selected[keys[i]])
        }

        this.$emit("add", items)
      },
      onClose() {
        this.$emit("close")
      },
      selectIndicator(item) {
        if (this.selected[item._id] == undefined) {
          this.selected[item._id] = item
        }
        else {
          delete this.selected[item._id];

        }
      },
      toggleSection(id) {
        if (this.sections[id] == undefined) {
          const options = {
            triggerType: 'none',

            //      onCollapse: () => {
            //          console.log('onSelectSectionelement has been collapsed');
            //      },
            //      onExpand: () => {
            //          console.log('onSelectSection element has been expanded');
            //      },
            //      onToggle: () => {
            //          console.log('onSelectSection  dropdown has been toggled');
            //      },
          }

          const $targetElBody = document.getElementById("dashboard-drawer-accordion-open-body-" + id);
          const $triggerElBody = document.getElementById("dashboard-drawer-accordion-open-heading-" + id);


          //  console.log($triggerElBody)
          //  console.log($targetElBody)


          const instanceOptions = {
            id: "dashboard-drawer-accordion-open-body-" + id,
            override: true
          };

          this.sections[id] = new Collapse($targetElBody, $triggerElBody, options, instanceOptions);



        }
        // else {
        //     this.sections[id].collapse()
        //}
      },
      async getIndicatorCatalog() {
        let filter = {}

        let response = await service.getData("data-sources/6639dd140f35603d5cb7b1ca/records", this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, filter))
        //     console.log("Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard")
        //     console.log("Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard")
        //     console.log("Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard")
        //     console.log("Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard")
        //     console.log("Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard")
        //     console.log(response)
        if (response?.records.length > 0) {
          this.catalog = response?.records[0]
        }


      }
    }
  }
</script>
<template>
  <div
    class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-bold"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-bold"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-bold"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-bold"
        >Default</label>
        <input
          :id="`att-input-default-${properties?.id}`"
          v-model="defaultValue"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeDefault"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Format
          de la saisie </label>
        <div class="w-full flex flex-col">
          <div
            class="inline-flex rounded-md shadow-sm w-full"
            role="group"
          >
            <button
              type="button"
              :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (format =='text') , 'text-gray-700 bg-gray-200 font-medium' : (format !='text') , }"
              class="w-4/12 px-4 py-2 text-xs   border border-gray-300 rounded-s-lg hover:bg-gray-50 hover:text-gray-900            "
              @click="onClickFormat('text')"
            >
              Texte
            </button>
            <button
              type="button"
              :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (format =='integer') , 'text-gray-700 bg-gray-200 font-medium' : (format !='integer') , }"
              class="w-4/12 px-4 py-2 text-xs font-medium   border-t border-b border-gray-300 hover:bg-gray-50 hover:text-gray-900         "
              @click="onClickFormat('integer')"
            >
              Entier
            </button>
            <button
              type="button"
              :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (format =='double') , 'text-gray-700 bg-gray-200 font-medium' : (format !='double') , }"
              class="w-4/12 px-4 py-2 text-xs font-medium   border border-gray-300 rounded-e-lg hover:bg-gray-50 hover:text-gray-900      "
              @click="onClickFormat('double')"
            >
              Virgule
            </button>
          </div>
        </div>
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="template-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Width</label>

        <select
          :id="`att-input-${properties?.id}`"
          v-model="width"
          class="w-full  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @change="onChangeWidth"
        >
          <option value="">
            None
          </option>
          <option value="w-full">
            w-full
          </option>
          <option value="w-11/12">
            w-11/12
          </option>
          <option value="w-10/12">
            w-10/12
          </option>
          <option value="w-9/12">
            w-9/12
          </option>
          <option value="w-8/12">
            w-8/12
          </option>
          <option value="w-7/12">
            w-7/12
          </option>
          <option value="w-6/12">
            w-6/12
          </option>
          <option value="w-5/12">
            w-5/12
          </option>
          <option value="w-4/12">
            w-4/12
          </option>
          <option value="w-3/12">
            w-3/12
          </option>
          <option value="w-2/12">
            w-2/12
          </option>
          <option value="w-1/12">
            w-1/12
          </option>
        </select>
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="template-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Template</label>

        <select
          :id="`att-input-${properties?.id}`"
          v-model="template"
          class="w-full  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @change="onChangeTemplate"
        >
          <option value="edit-tpl-1">
            edit-tpl-1
          </option>
          <option value="edit-tpl-2">
            edit-tpl-2
          </option>
          <option value="edit-tpl-3">
            edit-tpl-3
          </option>
        </select>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="number-lines-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Hauteur en nombre de
          lignes</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="lines"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeNumberLines"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-3-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description </label>
          </div>
          <div class="flex items-center me-4 mt-2">
            <input
              id="inline-2-checkbox"
              v-model="isRowDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRowDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage sur une
              ligne</label>
          </div>
          <div class="flex items-center me-4 hidden">
            <input
              id="inline-4-checkbox"
              v-model="isDisplayScan"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDisplayScan"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage Bouton
              Scan</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditTableFormComponentDetails',
        props: {
            properties: Object
        },
        data() {

            return {
                title: "",
                description: "",
                format: "",
                template: "edit-tpl-1",
                attribute: "",
                lines: 1,
                width: "",
                defaultValue: "",
                isDisplayScan: false,
                isRowDisplayed: false,
                layout: "1-0",
                isTitleDisplayed: false,
                isDescriptionDisplayed: false,

            }
        },
        watch:
        {
            properties: function () {
                this.title = this.properties?.title;
                this.template = this.properties?.template

                this.width = this.properties?.width
                

                this.defaultValue = this.properties?.default
                this.format = this.properties?.format;

                this.isTitleDisplayed = this.properties?.option?.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;

                this.isRowDisplayed = this.properties?.layout == 'row';
                this.attribute = this.properties?.attribute;
                this.isDisplayScan = this.properties?.isDisplayScan;


                this.description = this.properties?.description;

                this.lines = 1


                if (this.properties?.lines != undefined) this.lines = parseInt(this.properties?.lines)

                this.layout = this.properties?.layout
                if (this.layout == undefined) this.layout = "1-0"
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.template = this.properties?.template
            this.format = this.properties?.format
            this.defaultValue = this.properties?.default
            this.width = this.properties?.width
            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;

            this.attribute = this.properties?.attribute;
            this.isDisplayScan = this.properties?.isDisplayScan;

            this.description = this.properties?.description;
            this.isRowDisplayed = this.properties?.layout == 'row';
            this.lines = this.properties?.lines
            if (this.lines == undefined) this.lines = 1
            this.layout = this.properties?.layout
            if (this.layout == undefined) this.layout = "1-0"

        },
        methods:
        {

            onClickFormat: function (format) {
                this.format = format;
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'format', value: this.format })
            },

            onChangeNumberLines: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'lines', value: parseInt(this.lines) })
            },

            onChangeTemplate: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'template', value: this.template })
            },
            onChangeWidth: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'width', value: this.width })
            },
            onChangeDefault: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'default', value: this.defaultValue })
            },


            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'attribute', value: this.attribute })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },
            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'isDescriptionDisplayed', value: this.isDescriptionDisplayed })
            },
            onChangeIsDisplayScan: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'isDisplayScan', value: this.isDisplayScan })
            },
            onChangeIsRowDisplayed: function () {


                if (this.isRowDisplayed)
                    this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'layout', value: 'row' })
                else
                    this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'layout', value: '1-0' })
            },
            onChangeLayout: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'layout', value: this.layout })
            },
            onInputFormat: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            }
        }
    }
</script>
<template>
  <div
    class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6  px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>
        

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Source de donnée
        </label>

        <div class="w-full flex flex-col   items-center">
          <div class="flex flex-row items-center  justify-start text-left  w-full  ">
            <button
              :id="`dropdown-data-source-button-${properties?.id}`"
              :data-dropdown-toggle2="`dropdown-data-source-${properties?.id}`"
              class="w-full inline-flex items-center px-4 py-1.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              @click="onOpenDataSource"
            >
              <span class="w-full text-left truncate">{{ collection }}</span>

              <svg
                class="w-2.5 h-2.5 ms-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div
              :id="`dropdown-data-source-${properties?.id}`"
              class="w-80 mx-3  z-10 hidden rounded-lg shadow w-60 dark:bg-gray-700 border border-bg-gray-400  bg-gray-100 "
            >
              <div class="p-3 ">
                <label
                  for="input-group-search"
                  class="sr-only"
                >Search</label>
                <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    id="input-group-search"
                    type="text"
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 pr-2.5  py-1.5 pl-6 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    style="padding-left:40px"
                    placeholder="Rechercher une source"
                  >
                </div>
              </div>
              <ul
                class="h-48   px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                :aria-labelledby2="`dropdown-data-source-button-${properties?.id}`"
              >
                <li
                  v-for="item in sources"
                  :id="item.id"
                  :key="item.id"
                >
                  <div
                    class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                    @click="selectSource(item)"
                  >
                    <label
                      class="  w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300   cursor-pointer"
                    >
                      {{ item.name }}
                    </label>
                  </div>
                </li>
              </ul>
              <a
                href="#"
                class="flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline"
              >
                <svg
                  class="w-4 h-4 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path
                    d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z"
                  />
                </svg>
                Ajouter une source
              </a>
            </div>
          </div>
          <div class="mt-1 w-full text-left flex flex-col items-center justify-start  ">
            <label
              for="default-input"
              class="block w-full text-xs font-medium text-gray-900 dark:text-white pl-1"
            >Filtre</label>
            <input
              id="default-filter-input"
              v-model="filter"
              type="text"
              class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @input="onChangeSourceFilter"
            >
          </div>
        </div>
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-value-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Valeur par défault</label>

        <div class="w-full flex flex-row   items-center">
          <div class="flex flex-row items-center   text-left  w-full ">
            <button
              :id="`dropdownDefaultButton-${properties?.id}`"
              :data-dropdown-toggle="`dropdownDefault-${properties?.id}`"
              class="w-full inline-flex items-center px-4 py-1.5 text-sm font-medium text-center text-white  rounded-lg hover:bg-blue-800 focus:ring-0 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              :class="{'bg-blue-700': (collection =='actors'), ' bg-gray-300 pointer-events-none' : (collection !='actors')}"
            >
              <span class="w-full text-left truncate">{{ defaultval?.name }}</span>

              <svg
                class="w-2.5 h-2.5 ms-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div
              :id="`dropdownDefault-${properties?.id}`"
              class="w-80 mx-3  z-10 hidden   rounded-lg shadow w-60 dark:bg-gray-700 bg-gray-100"
            >
              <div class="p-3">
                <label
                  for="input-group-search"
                  class="sr-only"
                >Default</label>
                <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    id="input-group-search"
                    type="text"
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 pr-2.5  py-1.5 pl-6 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    style="padding-left:40px"
                    placeholder="Rechercher une source"
                  >
                </div>
              </div>
              <ul
                class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <li
                  v-for="item in defaults"
                  :id="item.id"
                  :key="item.id"
                >
                  <div
                    class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                    @click="selectDefault(item)"
                  >
                    <label
                      class="  w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300   cursor-pointer"
                    >
                      {{ item.name }}
                    </label>
                  </div>
                </li>
              </ul>
              <a
                href="#"
                class="flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline"
              >
                <svg
                  class="w-4 h-4 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path
                    d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z"
                  />
                </svg>
                Ajouter une source
              </a>
            </div>
          </div>
        </div>
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Sélection Minimum</label>

        <div class="w-full flex flex-col  relative mb-2">
          <div class="w-full flex flex-row w-full px-3 ">
            <div class="start-0 absolute  flex   -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900"
              >1</label>
            </div>
            <div class="start-1/4 absolute flex justify-center -bottom-6">
              <label
                for="steps-range"
                class="block text-2xs font-medium text-gray-900"
              >2</label>
            </div>
            <div class="start-2/4 absolute flex justify-center  -bottom-6">
              <label
                for="steps-range"
                class="block  text-2xs font-medium text-gray-900 "
              >3</label>
            </div>
            <div class="start-3/4 absolute flex justify-center  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >4</label>
            </div>
            <div class="end-0 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >5</label>
            </div>
          </div>
          <input
            id="avatar-min-range"
            v-model="selectionmin"
            type="range"
            min="1"
            max="5"
            step="1"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            @change="onInputMinSelection"
          >
        </div>
      </div>
 

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Sélection Maximum</label>


        <div class="w-full flex flex-col relative mb-2">
          <div class="w-full flex flex-row w-full px-3 ">
            <div class="start-0 absolute  flex   -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900"
              >1</label>
            </div>
            <div class="start-1/4 absolute flex justify-center -bottom-6">
              <label
                for="steps-range"
                class="block text-2xs font-medium text-gray-900"
              >2</label>
            </div>
            <div class="start-2/4 absolute flex justify-center  -bottom-6">
              <label
                for="steps-range"
                class="block  text-2xs font-medium text-gray-900 "
              >3</label>
            </div>
            <div class="start-3/4 absolute flex justify-center  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >4</label>
            </div>
            <div class="end-0 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >5</label>
            </div>
          </div>


          <input
            id="avatar-max-range"
            v-model="selectionmax"
            type="range"
            min="1"
            max="5"
            step="1"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            @change="onInputMaxSelection"
          >
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <input
          id="inline-2-checkbox"
          v-model="isString"
          type="checkbox"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          @change="onChangeIsString"
        >
        <label
          for="inline-2-checkbox"
          class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >Forcer
          en Chaîne</label>
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="template-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Template</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="template"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeTemplate"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Options</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

    import { Dropdown } from 'flowbite';


    export default {
        name: 'AvatarTableFormComponentDetails',
        props: {
            properties: Object,
            form: Object
        },
        data() {

            return {
                title: "",
                maxlimit: "",
                defaultval: { name: "Aucune", id: "none" },
                dropdownDataSource: null,
                collection: "",
                filter: "",
                description: "",
                dropdown: null,
                dropdownDefault: null,
                isRequired: false,
                isString: false,
                isTitleDisplayed: true,
                isDescriptionDisplayed: false,
                selectionmin: "1",
                selectionmax: "1",

                template: "avatar-tpl-1",
                default: "",
                position: "",


                attribute: "",
                defaults: [
                    { id: "none", name: "Aucune" },
                    { id: "user", name: "Utilisateur Courant" }
                ],
                sources: [
                    { id: "categories", name: "Catégories" },
                    { id: "sub-categories", name: "Sous Categories" },
                    { id: "customers", name: "Clients" },
                    { id: "customer-addresses", name: "Addresses Clients" },
                    { id: "outbound-orders", name: "Commandes Clients" },
                    { id: "batches", name: "Lots" },
                    { id: "batchesEx", name: "Lots 2" },
                    { id: "equipments", name: "Machines" },

                    { id: "locations", name: "Emplacements" },
                    { id: "brands", name: "Marques" },
                    { id: "suppliers", name: "Fournisseurs" },
                    { id: "actors", name: "Membres" },
                    { id: "products", name: "Produits Commercialisés" },
                    { id: "raw-products", name: "Produits Fabriqués" },
                    { id: "products-suppliers", name: "Produits Fournisseurs" },
                    { id: "supplier-product-batches", name: "Lots Fournisseurs" },


                ]
            }
        },
        watch:
        {
            properties: function () {
                this.isMultiple = this.properties?.isMultiple;

                this.isTitleDisplayed = this.properties?.option.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;
                this.isString = this.properties?.isString;
                if (this.isString == undefined) this.isString = false;

                this.attribute = this.properties?.attribute;

                this.position = this.properties?.position;

                this.title = this.properties?.title;

                this.filter = this.properties?.filter;
                this.template = this.properties?.template;
                this.description = this.properties?.description;
                this.collection = this.properties?.collection;

                let def = this.findDefault(this.properties?.default)

                if (def != undefined) {
                    this.defaultval = def;
                }

                this.selectionmin = this.properties?.selection.min;
                this.selectionmax = this.properties?.selection.max;
            }
        },
        mounted() {

            this.title = this.properties?.title;
            this.description = this.properties?.description;

            this.isTitleDisplayed = this.properties?.option.title;
            this.isDescriptionDisplayed = this.properties?.option.description;
            this.filter = this.properties?.filter;
            this.attribute = this.properties?.attribute;


            this.attribute = this.properties?.attribute;

    
            this.position = this.properties?.position;

            this.template = this.properties?.template;

            this.isString = this.properties?.isString;
            if (this.isString == undefined) this.isString = false;

            this.collection = this.properties?.collection;
            this.selectionmin = this.properties?.selection.min;
            this.selectionmax = this.properties?.selection.max;

            let def = this.findDefault(this.properties?.default)

            if (def != undefined) {
                this.defaultval = def;
            }



            {

                const $targetEl = document.getElementById('dropdownDefault-' + this.properties?.id);

                //  
                const $triggerEl = document.getElementById('dropdownDefaultButton-' + this.properties?.id);

                // options with default values
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    ignoreClickOutsideClass: false,
                    //   onHide: () => {

                    //   },
                    //   onShow: () => {

                    //   },
                    //   onToggle: () => {

                    //   },
                };

                // instance options object
                const instanceOptions = {
                    id: 'dropdownSearchButton-' + this.properties?.id,
                    override: true
                };
                const dropdownDefault = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
                dropdownDefault.hide()
            }
        },
        methods:
        {
            findDefault: function (val) {


                let def = { name: "Aucune", id: "none" }

                if (val == undefined) return def
                if (val == "{{global.user._id}}") def = { id: "user", name: "Utilisateur Courant" };
                if (val == "{{global.user.id}}") def = { id: "user", name: "Utilisateur Courant" };
                //    let def = this.defaults.find(function (a) { return a.value == val })
                return def
            },

            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'attribute', value: this.attribute })
            }, 

            onChangeTemplate: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'template', value: this.template })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'option.title', value: this.isTitleDisplayed })
            },
            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'option.description', value: this.isDescriptionDisplayed })
            },
            onChangeIsString: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'isString', value: this.isString })
            },
            onChangeSourceFilter: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'filter', value: this.filter })
            },


            selectDefault: function (item) {

                this.defaultval = item;

                const $targetEl = document.getElementById('dropdownDefault-' + this.properties?.id);

                //  
                const $triggerEl = document.getElementById('dropdownDefaultButton-' + this.properties?.id);

                // options with default values
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    dependency: 'no',
                    dependencyId: "",
                    ignoreClickOutsideClass: false,
                    //        onHide: () => {

                    //        },
                    //        onShow: () => {

                    //        },
                    //        onToggle: () => {

                    //        },
                };

                // instance options object
                const instanceOptions = {
                    id: 'dropdownDefaultButton-' + this.properties?.id,
                    override: true
                };
                const dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
                dropdown.hide()
                this.$emit("updateField", { att: 'default', value: this.defaultval })
            },
            onOpenDataSource: function () {
                if (this.dropdownDataSource == undefined) {

                    const $targetEl = document.getElementById('dropdown-data-source-' + this.properties?.id);
                    console.log("$targetEl")
                    console.log($targetEl)
                    // set  
                    const $triggerEl = document.getElementById('dropdown-data-source-button-' + this.properties?.id);
                    console.log("$triggerEl")
                    console.log($triggerEl)
                    // options with default values
                    const options = {
                        placement: 'bottom',
                        triggerType: 'click',
                        offsetSkidding: 0,
                        offsetDistance: 2,
                        delay: 300,
                        dependency: 'no',
                        dependencyId: "",
                        ignoreClickOutsideClass: false,

                    };
                    // instance options object
                    const instanceOptions = {
                        id: 'dropdown-data-source-' + this.properties?.id,
                        override: true
                    };
                    const dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
                    this.dropdownDataSource = dropdown
                    dropdown.show()
                }
            },

            selectSource: function (item) {

                this.source = item;


                this.dropdownDataSource.hide()



                this.collection = item.id
                this.$emit("updateField", { id: this.properties?.id, att: 'collection', value: this.collection })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'description', value: this.description })
            },


            onInputMaxSelection: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'selection.max', value: parseInt(this.selectionmax) })
            },

            onInputMinSelection: function () {
                this.$emit("updateField", { id: this.properties?.id, att: 'selection.min', value: parseInt(this.selectionmin) })
            }

        }
    }
</script>
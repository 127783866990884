<template>
  <div
    id="select-recipe-step-modal"
    tabindex="-1"
    aria-hidden="true"
    data-modal-backdrop="static"
    class="  fixed top-0 left-0 right-0 z-50 hidden w-full px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
  >
    <div class=" relative w-full h-full max-w-2xl md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click="onClose"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <!-- Modal header -->
        <div class="px-6 py-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
            {{ labels['TITLE_PRODUCE_STEP'][lg] }}
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-6 w-full  max-h-fit relative">
          <div
            class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
          >
            <ul class="flex flex-wrap -mb-px">
              <li class="me-2">
                <a
                  href="#"
                  class="inline-block p-4 border-b-2  rounded-t-lg active  "
                  :class="{ 'text-blue-600 border-blue-600' : (selected == 0), 'hover:text-gray-600 hover:border-gray-300 border-transparent': (selected == 1)}"
                  aria-current="page"
                  @click="onChangeTab(0)"
                >
                  Description
                </a>
              </li>
              <li class="me-2">
                <a
                  href="#"
                  class="inline-block p-4 border-b-2  rounded-t-lg active  "
                  :class="{ 'text-blue-600 border-blue-600' : (selected ==1), 'hover:text-gray-600 hover:border-gray-300 border-transparent': (selected == 0)}"
                  aria-current="page"
                  @click="onChangeTab(1)"
                >Contrôles</a>
              </li>
            </ul>
          </div>
          <div class="w-full relative    flex flex-col  ">
            <div
              class="p-6 w-full     top-0  "
              :class="{'opacity-100 invisible ' : (selected == 1), 'opacity-100 visible' : (selected == 0)}"
            >
              <div class="w-full flex flex-row items-center">
                <div class=" w-full  text-left justify-left flex flex-col">
                  <label
                    for="name"
                    class="w-full  block mb-2 text-sm font-bold text-gray-900 dark:text-white"
                  >
                    name </label>
                  <input
                    id="select-recipe-step-modal-name"
                    v-model="name"
                    v-focus
                    type="text"
                    name="name"
                    class="w-full   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder=""
                  >
                </div>
              </div>
              <div class="w-full flex flex-row items-center mt-3">
                <div class="m w-full  text-left justify-left flex flex-col">
                  <label
                    for="name"
                    class="w-full  block mb-2 text-sm font-semibold text-gray-900 dark:text-white"
                  >
                    Description </label>

                  <textarea
                    id="select-recipe-step-modal-message"
                    v-model="description"
                    v-focus
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                  />
                </div>
              </div>


              <div class="w-full flex flex-row items-center mt-3">
                <div class=" w-full  text-left justify-left flex flex-col">
                  <label
                    for="name"
                    class="w-full  block mb-2 text-sm font-bold text-gray-900 dark:text-white"
                  >
                    Durée de la Tâche </label>
                  <input
                    id="select-recipe-step-duration"
                    v-model="duration"
                    v-focus
                    type="text"
                    name="duration"
                    class="w-full   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder=""
                  >
                </div>
              </div>

              <div class="w-full flex flex-row items-center mt-3">
                <div class=" w-full  text-left justify-left flex flex-col">
                  <label
                    for="name"
                    class="w-full  block mb-2 text-sm font-bold text-gray-900 dark:text-white"
                  >
                    Temps de Travail
                  </label>
                  <input
                    id="select-recipe-step-workload"
                    v-model="workload"
                    v-focus
                    type="text"
                    name="workload"
                    class="w-full   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder=""
                  >
                </div>
              </div>
            </div>


            <div
              class="p-6 w-full   h-full  absolute flex flex-col justify-start items-start  top-0  "
              :class="{'opacity-100 invisible ' : (selected == 0), 'opacity-100 visible ' : (selected == 1)}"
            >
              <div class="w-full font-bold text-left mb-1">
                Contrôles à effectuer :
              </div> 

              <treeselect
                v-model="controls"
                class="w-full"
                :multiple="true"
                :options="options"
                :disable-branch-nodes="true"
                placeholder=""
                search-nested
              />
            </div>
          </div>



          <hr class="mb-3">

          <div class="flex items-center justify-end">
            <button
              type="button"
              class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click=" onClose"
            >
              Annuler
            </button>
            <button
              type="button"
              class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
              @click="onValidate"
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import service from "@/services/FullService.vue"
    import labels from "@/assets/lg/product-types.json"
    import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";


    import { mapGetters } from "vuex";
    export default {
        name: 'SelectIngredientModal3',
        props: {
            item: Object,
        },
        data() {
            return ({
                lg: "FR",
                selected: 0,
                labels: labels,
                id: null,
                name: "",
                description: "",
                duration: "",
                workload: "",
                controls: [],
                options: []

            })
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        watch:
        {
            "item": function () {
                this.id = this.item?.id;
                this.name = this.item?.name;
                this.description = this.item?.description;
                this.duration = this.item?.duration;
                this.workload = this.item?.workload;
                this.controls = this.item?.controls;
                this.loadData()
            }
        },
        async mounted() {
            if (this.user == undefined) {
                //this.$router.push("/login");
                window.location = "/login"

                await this.loadData()
                return;
            }

        },
        methods: {


            transformData(records) {
                let response = []
                for (let i = 0; i < records.length; i++) {
                    let record = records[i]
                    let categoryIndex = response.findIndex(function (a) {return (a.id == record.category?.code) })
                    if (categoryIndex == -1) {
                        categoryIndex = response.push({ label: record.category?.name, id: record?.category?.code, children: [] })
                        categoryIndex--
                    }
                    response[categoryIndex].children.push({ label: record.name, id: record._id, })
                }
                return response;
            },

            async loadData() {
                let url = "data-sources/66bf46a4e83c6762c44e0467/records"
                let records = await service.getData(url, this.user?.token, { limit: 1000, offset: 0 })
                this.options = this.transformData(records.records)
            },

            onChangeTab(index) {
                this.selected = index
            },
            onClose() {

                this.$emit("close")
            },
            onValidate() {

                this.$emit("validate", { id: this.id, name: this.name, description: this.description, duration: this.duration, workload: this.workload, controls: this.controls })
            }
        },
        components: {

        }
    }
</script>

<style>
    .vue3-treeselect__input-container {

        border-width: 0px;
    }

    .vue3-treeselect__input-container:hover {

        border-width: 0px;
    }

    .vue3-treeselect__input-container:focus {

        border-width: 0px;
    }

    .vue3-treeselect__multi-value-label {
        font-size: 14px;
    }
</style>
<template>
  <div
    class="relative mt-2  h-20 cursor-grab list-group bg-gray-700 border   mt-2 w-full flex items-center bg-gray-700  border      shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
    :class="{'border-gray-200 hover:border-solid hover:border-gray-100' : isSelected, 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
    @click="selectItem"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
    <div class="hidden flex flex-col items-center w-6 ">
      <i class="fa-solid fa-grip-vertical" />
    </div>

    <div class="flex flex-col items-center w-full text-white pl-2">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full h-6 text-xs text-left text-white"
      >
        {{ element.title }}
      </div>
      <div class="w-full h-12  ">
        <div class="w-full flex flex-row text-gray-800 text-xs ">
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(1) }}
          </div>
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(2) }}
          </div>
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(3) }}
          </div>
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(4) }}
          </div>
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(5) }}
          </div>
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(6) }}
          </div>
          <div class="h-8 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(7) }}
          </div>
          <div class="hidden h-10 w-10  bg-orange-100 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(1) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'NumberTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        methods:
        {
            addItem: function()
            {
                this.$emit("add", {type: 'avatar', id: this.element.id} )
            },
            displayNumber: function (index) {
                let r = parseFloat(this.element?.minDisplayValue) + index * parseFloat(this.element?.displayStepValue)
                if (this.element?.format == "percent") return r + "%"
                if (this.element?.format == "temperature") return r + "°"
                return r
            }, 
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {

                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
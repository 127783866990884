<template>
  <div
    class="block max-w-sm mx-auto mt-2 p-2 bg-white border border-gray-200 rounded-lg   hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
  >
    <div class="w-full flex flex-row items-center justify-start mb-3">
      <div class="w-4 text-gray-300">
        <i class="fa-solid fa-grip-vertical" />
      </div>
      <div class="w-4 text-gray-500 ml-2">
        <i class="fa-solid fa-font" />
      </div>

      <div class="w-full ml-2">
        <label
          for="small"
          class="block  text-sm font-semibold text-gray-900 dark:text-white"
        >
          Trier par
        </label>
      </div>

      <div class="w-4 text-gray-500">
        <i class="w-3 fa-solid fa-trash" />
      </div>
    </div>
    <div class="w-full flex flex-row items-center">
      <label class="inline-flex items-center   cursor-pointer mr-4">
        <input
          type="checkbox"
          value=""
          class="sr-only peer"
        >
        <div
          class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
        />
        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Binning</span>
      </label>
    </div>
    <div class="w-full">
      <label
        for="small"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Trier par
      </label>
      <div class="max-w-sm flex flex-row mx-auto items-center">
        <select
          id="small"
          class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option selected>
            Year
          </option>
          <option value="2">
            Month
          </option>
          <option value="3">
            Week of the Year
          </option>
          <option value="4">
            Date of the Month
          </option>
          <option value="4">
            Date of the week
          </option>
          <option value="4">
            Hour
          </option>
          <option value="4">
            Minute
          </option> 
          <option value="4">
            Second
          </option>
        </select>
        <div class="flex items-center w-12 justify-center text-center mx-auto">
          <i class=" fa-solid fa-arrow-up-z-a" />
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row items-center">
      <label class="inline-flex items-center   cursor-pointer mr-4">
        <input
          type="checkbox"
          value=""
          class="sr-only peer"
        >
        <div
          class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
        />
        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Compare Periods</span>
      </label>
    </div>
  </div>
</template>
<template>
  <div class="flex flex-row bg-red-100 z-10 border shadow max-h-full min-h-48 ">
    <div class="w-72    bg-white  text-left ">
      <div class="p-3">
        <label
          for="input-group-search"
          class="sr-only"
        >Search</label>
        <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              class="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            id="input-group-search"
            type="text"
            class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 pr-2.5  py-1.5 pl-6 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            style="padding-left:40px"
            placeholder="Rechercher une source"
          >
        </div>
      </div>
      <ul
        class="max-h-72 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownSearchButton"
      >
        <li
          v-for="item in components"
          :id="item.id"
          :key="item.id"
        >
          <div
            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
            @click="onClickComponent(item)"
            @hover="onHoverItem(item)"
          >
            <label
              class="  w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300   cursor-pointer"
            >
              {{ item.name }}
            </label>
          </div>
        </li>
      </ul>
      <a
        href="#"
        class="flex items-center p-3 text-sm font-medium text-red-600 border-t border-t-gray-200   bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline"
      >
        <svg
          class="w-4 h-4 me-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z"
          />
        </svg>
        Ajouter un composant
      </a>
    </div>


    <div class="w-96 max-h-96  min-h-48  bg-gray-100 overflow-y-auto px-6 py-2">
      <div
        v-for="image in images"
        :key="image"
        class="w-full flex items-center p-1 cursor-pointer "
        @click="onSelectItem(image)"
      >
        <div
          class="border border-gray-300 bg-white shadow w-full h-6 flex items-center justify-center p-6 hover:bg-gray-200 hover:font-bold"
        >
          Modéle de base
        </div>
      </div>
    </div>
  </div>
</template>


<script>

    import { v4 as uuidv4 } from 'uuid';
    export default {
        name: 'PopupAddSection',
        props: {
            properties: Object,
            parent: Object,
            index: Number,
            zone: String
        },
        data() {

            return {
                images: [],
                selected: null,
                components: [
                    { id: "separator", name: "Séparateur", type: "separator", title: "Titre", description: "Description", attribute: null, template: "separator-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, height: "30px", line_height: "2px", images: ["65ff1322f9d615b16384c6ef"] },
                    { id: "title", name: "Titre", type: "title", title: "Titre", description: "Description", attribute: null, template: "title-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, images: ["65ff1322f9d615b16384c6ef"] },
                    { id: "edit", name: "Champs de Saisie", type: "edit", title: "Titre", description: "Description", attribute: "attx", template: "edit-tpl-2", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, lines: 1, format: "text", images: ["65ff1316f9d615b16384c6ee"] },
                    { id: "date", name: "Champs Date", type: "date", title: "Titre", description: "Description", attribute: "date", template: "date-tpl-2", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] },   format: "date-time", images: ["65ff1316f9d615b16384c6ee"] },
                    { id: "list", name: "Liste de composants", type: "list", title: "Titre", description: "Description", attribute: "list", template: "list-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] },   components: [], images: ["65ff1316f9d615b16384c6ee"] },
                    { id: "row", name: "Ligne de composants", type: "row", title: "Titre", description: "Description", attribute: "row", template: "list-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] },   components: [], images: ["65ff1316f9d615b16384c6ee"] },
                    { id: "image", name: "Image", type: "image", title: "Titre", description: "Description", attribute: "attx", template: "image-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, lines: 1, format: "text", images: ["65ff1316f9d615b16384c6ee"] },
               
                    { id: "grid", name: "Grille d'élements", type: "grid", title: "Titre", description: "Description", attribute: "list", template: "list-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] },   columns: 3, rows: 3, images: ["65ff1316f9d615b16384c6ee"] },
                 
                    { id: "hand-signature", name: "Champs Signature", type: "hand-signature", title: "Titre", description: "Description", attribute: "attx", template: "signature-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, images: ["65ff1309f9d615b16384c6ed", "65ff1309f9d615b16384c6ed", "65ff1309f9d615b16384c6ed", "65ff1309f9d615b16384c6ed", "65ff1309f9d615b16384c6ed", "65ff1309f9d615b16384c6ed"] },
                    { id: "photos", name: "Prise de Photos", type: "button", title: "Titre", description: "Description", attribute: "attx", template: "photos-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, action: "take-photo", max: 1, images: ["65ff12fbf9d615b16384c6ec"] },
                    { id: "number", name: "Choix de Numéros", type: "number", title: "Titre", description: "Description", attribute: "attx", template: "number-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, format: "number", "values": { "min": 0, "max": 10, "step": 1 }, images: ["65ff1316f9d615b16384c6ee"] },
                    { id: "avatar", name: "Choix d'Avatars", type: "avatar", title: "Titre", description: "Description", attribute: "attx", template: "avatar-tpl-2", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, default: "", selection: { min: 1, max: 1 }, collection: "", filter: "", images: ["65ff1309f9d615b16384c6ed"] },
                    { id: "radio", name: "Choix Multiple", type: "radio", title: "Titre", description: "Description", attribute: "attx", template: "radio-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, default: "", values: [{ title: "Non inspecté", value: "NA", default: "off" }, { title: "Conforme", value: "C", default: "off" }, { title: "Non Conforme", value: "NC", default: "off" }], images: ["65ff1322f9d615b16384c6ef"] },
                    { id: "html", name: "HTML", type: "html", title: "Titre", description: "Description", attribute: null, template: "html-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, href: "", body: "<hello", height: "250px", images: ["65ff1322f9d615b16384c6ef"] },
                    { id: "scan-code", name: "QR Code", type: "scan-qrcode", title: "Titre", description: "Description", attribute: "attx", template: "code-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, height: "300px", images: ["65ff12fbf9d615b16384c6ec", "65ff1309f9d615b16384c6ed"] },
                    { id: "scan-code", name: "Codes Barre", type: "scan-barcode", title: "Titre", description: "Description", attribute: "attx", template: "code-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, height: "300px", images: ["65ff12fbf9d615b16384c6ec", "65ff1309f9d615b16384c6ed"] },

                    { id: "scan-doc", name: "Scan de Documents", type: "scan-doc", title: "Titre", description: "Description", attribute: "attx", template: "doc-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, images: ["65ff1322f9d615b16384c6ef"] },
                    { id: "print", name: "Zone Impression", type: "print", title: "Titre", description: "Description", attribute: "attx", template: "print-tpl-1", option: { title: true, description: false }, _controls: { isRequired: false }, _events: { onInit: [], onChange: [] }, images: ["65ff1316f9d615b16384c6ee", "65ff1309f9d615b16384c6ed"] }


                ]
            }
        },
        watch:
        {
            properties: function () {




            }
        },
        mounted() {


        },
        methods:
        {
            onClickComponent: function (component) {
            
                this.images = [...component.images]
                this.selected = JSON.parse(JSON.stringify(component))
            },

            onHoverItem: function (component) {
                this.images = [...component.images]
            },
            onSelectItem: function (variation) {

                let section = this.selected
                section.id = uuidv4();
                this.$emit("select", { id: this.properties?.id, parent: this.parent?.id, section: section, variation: variation, zone: this.zone })
            }
        }
    }
</script>
<template>
  <div
    class="h-24  relative   cursor-grab flex  list-group mt-2 w-full flex items-center bg-gray-700 border     dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-start items-center "
    :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>


    <div class="flex flex-col items-start w-full justify-center w-full pl-2 bg-gray-700 ">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full h-6 text-xs text-left text-white"
      >
        {{ element.title }}   
      </div>
      <div
        v-if="element.isDescriptionDisplayed"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ element.description }}  
      </div>
      <div class="flex  font-bold text-lg  text-white w-full justify-center text-center ">
        <button
          type="button"
          class="min-w-lg h-10 flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-green-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
        >
          {{ element.label }}  
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.element.id })
            },
            deleteMe: function () {
             
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
<template>
  <div class="w-full bg-red-300">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres
    </h3>
 
    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Categorie output = {{ output }} category={{ category }}</label>
        <select
          id="category"
          v-model="output"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @change="onChange"
        >
          <option
            v-for="value in values"
            :key="value.id"
          >
            {{ value.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'SelectField',
        components: {

        },
        props: {
            values: Array,
            input: String
        },
        data() {
            return ({

                output: "" 
            })
        },
        watch: {

            category: function ( ) { this.output =  this.input?.source; }
        },

        mounted() {
       //     console.log("EditField Mounted")
            this.output =  this.input?.source;
        },
        methods: {

            onChange: function ( ) {
 
                this.$emit("changeValue", this.output)

            }
          


        }
    }
</script>
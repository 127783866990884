<template>
  <div
    class="mb-121 relative h-18 cursor-grab flex  list-group w-full flex items-center    flex flex-row justify-start items-center "
  >
    <div class="flex flex-col items-start w-full  pl-2   ">
      <div
        class=" w-full flex font-bold  py-0.5  items-center justify-start w-full text-sm  text-left text-gray-700  "
      >
        {{ properties?.title }} {{ propertie?.type }}
      </div>
      <div
        v-if="properties?.option.description"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ properties?.description }}
      </div>

      <div class="w-full mt-2  font-bold  text-xs flex  text-gray-800 ">
        <input
          type="text"
          disabled
          :class="{ 'bg-gray-200': properties?.option?.mode == 'read-only', 'bg-gray-50': properties?.option?.mode =='read-write' }"
          class="w-64  border border-gray-300 text-gray-900 text-sm rounded-lg   px-2.5 py-1.5  "
        >

        <button
          type="button"
          disabled
          class="ml-2 min-w-lg h-8 flex items-center justify-center px-4 py-1.5 text-sm font-medium text-white rounded-lg bg-gray-500 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-primary-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-primary-800"
        >
          <i class=" fa-solid fa-qrcode" />
        </button>
      </div>
    </div>
  </div>
</template>




<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
          
        },
        methods:
        {
            addItem: function()
            {
                this.$emit("add", {type: 'avatar', id: this.properties.id} )
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
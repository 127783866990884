<template>
  <div class="ml-12 h-full relative mt-24  overflow-y-none bg-gray-50  dark:bg-gray-900">
    <nav
      class="border-b-1 border-b-gray-300 flex p-4"
      aria-label="Breadcrumb"
    >
      <ol class="w-full inline-flex items-center   ">
        <li>
          <div class="flex items-center flex-row">
            <div class="text-2xl font-bold flex items-center">
              Plan de Nettoyage
            </div>
            <div
              class="text-xl font-bold ml-3 flex items-center"
              @click="OnSelect"
            >
              Chambre Froide Tampon
            </div>
          </div>
        </li>
      </ol>
      <ol class="w-full text-right justify-end inline-flex items-center  ">
        <li>
          <div class="flex items-center">
            <button
              type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickExport"
            >
              <i class="fa-solid fa-download mr-2 " />
              {{ common_labels['BUTTON_EXPORT'][lg] }}
            </button>
          </div>
        </li>
      </ol>
    </nav>
    <nav
      class="border-b-1 border-b-gray-300 flex p-4"
      aria-label="Breadcrumb"
    >
      <ol class="w-full inline-flex items-center   ">
        <li>
          <div class="flex items-center">
            <h3 class="text-xl font-bold ml-2">
              Chambre Froide Tampon
            </h3>
          </div>
        </li>
      </ol>
      <ol class="w-full text-right justify-end inline-flex items-center  ">
        <li
          class="w-8 h-8 border text-center flex items-center justify-center hover:bg-gray-100 cursor-pointer"
          @click="OnClickPrevious"
        >
          <i class="fa-solid fa-chevron-left" />
        </li>
        <li
          class="w-8 h-8 border text-center flex items-center justify-center hover:bg-gray-100 cursor-pointer"
          @click="OnClickNext"
        >
          <i class="fa-solid fa-chevron-right" />
        </li>
      </ol>
    </nav>
    <main>
      <div class="flex flex-col mt-2">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden shadow">
              <div class="flex justify-start items-start px-4 mb-6 space-x-4">
                <div class="min-w-kanban   ">
                  <div class="py-4 text-base text-lg font-semibold text-gray-900 dark:text-gray-300">
                    Tâches
                  </div>



                  <div
                    id="kanban-list-1"
                    class="mb-4 space-y-4 min-w-kanban"
                  >
                    <div
                      v-for="task in tasks"
                      :key="task.id"
                      class="h-12 w-72 bg-purple-100  flex    items-center justify-center   max-w-md   rounded-lg shadow transform cursor-move dark:bg-gray-800"
                    >
                      <div class="flex justify-between items-center bg-purple-100">
                        <div class="text-left text-base font-semibold text-gray-900 dark:text-white">
                          {{ task.name }}
                        </div>
                      </div>
                    </div>
                  </div>


                  <button
                    type="button"
                    data-modal-toggle="new-card-modal"
                    class="hidden flex justify-center items-center py-2 w-full font-semibold text-gray-500 rounded-lg border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ajouter une nouvelle carte
                  </button>
                </div>


                <div
                  v-for="week in display_weeks"
                  :key="week.id"
                  class="min-w-kanban"
                >
                  <div class="py-4 text-base text-lg font-semibold text-gray-900 dark:text-gray-300 w-full">
                    {{ week.name }}
                  </div>

                  <div
                    id="kanban-list-3"
                    class="mb-4 space-y-4 min-w-kanban w-full"
                  >
                    <div
                      v-for="task in tasks"
                      :key="task.id"
                      class="h-12   flex flex-col  items-center justify-center max-w-md  min-w-md  rounded-lg shadow transform cursor-move dark:bg-gray-800"
                      :class="{ 'bg-green-100': (task.results.S20.value == 'OK'), 'bg-red-100': (task.results.S20.value == 'KO')}"
                    >
                      <div
                        class="flex flex-col  items-center justify-between w-full"
                        :class="{ 'bg-green-100': (task.results.S20.value == 'OK'), 'bg-red-100': (task.results.S20.value == 'KO')}"
                      >
                        <div class="flex justify-between items-center w-full">
                          <div class="flex justify-start items-center ">
                            <a
                              href="#"
                              data-tooltip-target="user_76_3"
                              class="ml-3 -mr-3"
                            >

                              <div class="flex flex-row items-center">
                                <img
                                  class="w-7 h-7 rounded-full border-2 border-white dark:border-gray-800"
                                  src="@/assets/users/michael-gough.png"
                                  alt="Michael Gough"
                                >
                                <span class="ml-1 hidden">Christophe</span>
                              </div>
                            </a>
                            <div
                              id="user_76_3"
                              role="tooltip"
                              class="inline-block absolute invisible z-50 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
                            >
                              Michael Gough
                              <div
                                class="tooltip-arrow"
                                data-popper-arrow
                              />
                            </div>
                          </div>

                          <div
                            class="flex justify-center items-center px-3 text-sm font-medium   rounded-lg dark:bg-purple-200 mr-1"
                            :class="{ 'text-green-800 bg-green-50': (task.results.S20.value == 'OK'), 'text-red-800 bg-red-50': (task.results.S20.value == 'KO')}"
                          >
                            <i class="fa-solid fa-check mr-1 w-4 h-4" />

                            <svg
                              class="mr-1 w-4 h-4 hidden"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            {{ task.results.S20.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    data-modal-toggle="new-card-modal"
                    class="hidden flex justify-center items-center py-2 w-full font-semibold text-gray-500 rounded-lg border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ajouter une nouvelle tâche
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <SelectProductType
      @close="OnSelectProductTypeClose"
      @select="OnSelectProductTypeSelected"
    />
  </div>
</template>

<script>
  import labels from "@/assets/lg/common.json"
  import SelectProductType from '@/components/modals/SelectProductType.vue'
  //import Datepicker from 'flowbite-datepicker/Datepicker';


  // import service from "@/services/FullService.vue"
  /* const heatmapData = [
       {
           lat: 43.6744,
           lng: 7.1949
       },
       {
           lat: 48.8232,
           lng: 2.278
       }];
*/
  import { mapGetters } from "vuex";
  import { Modal } from 'flowbite';
  export default {
    name: "DashboardKanbanView",
    components: {
      SelectProductType,
    },
    props:
    {
      title: String,
      subTitle: String
    },
    data() {

      return {
        id: 100,
        selectProductTypeModal: null,
        lg: "FR",
        api: null,
        common_labels: labels,
        heat2: {

        },
        display_weeks: [],

        current_week: 40,


        response: [],
        labels: [],
        weeks: [
          {
            id: 17,
            name: "Semaine #40",
            start: "lundi 2 octobre",
            end: "dimanche 8 octobre"
          },
          {
            id: 18,
            name: "Semaine #41",
            start: "lundi 9 octobre	",
            end: "dimanche 15 octobre"
          },
          {
            id: 19,
            name: "Semaine #42",
            start: "lundi 16 octobre",
            end: "dimanche 22 octobre"
          },

          {
            id: 20,
            name: "Semaine #43",
            start: "lundi 23 octobre",
            end: "dimanche 29 octobre"
          },

          {
            id: 21,
            name: "Semaine #44",
            start: "lundi 30 octobre",
            end: "dimanche 5 novembre"
          },
          {
            id: 22,
            name: "Semaine #45",
            start: "lundi 6 novembre",
            end: "dimanche 12 novembre"
          }, {
            id: 23,
            name: "Semaine #46",
            start: "lundi 13 novembre",
            end: "dimanche 19 novembre"
          },

        ],

        tasks: [
          {
            id: "1",
            name: "Plafond et murs toute hauteur",
            results: {
              "S20": {
                value: "OK",
                name: "Satisfaisant",
                by: {
                  name: "Christophe",
                  avatar: "@/assets/users/michael-gough.png"
                }
              }
            }
          },
          {
            id: "2",
            name: "Evaporateurs",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }
          },
          {
            id: "3",
            name: "Etagères",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }
          }
          ,
          {
            id: "4",
            name: "Poignée de porte",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }
          },
          {
            id: "5",
            name: "Portes",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }

          },
          {
            id: "6",
            name: "Sols",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }

          },
          {
            id: "7",
            name: "Murs",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }
          },
          {
            id: "8",
            name: "Palettes Plastiques",
            results: {
              "S20": {
                value: "KO",
                name: "Non Satisfaisant"
              }
            }
          }
        ]
      }
    },

    computed:
    {
      ...mapGetters({ user: "StateUser" }),



    },
    watch: {
      'user.tenant_id': async function () {
        let response = await this.getPoints()
        this.heatmapData = response

      }
    },

    mounted: async function () {


      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login"
        return;
      }
      // const datepickerEl = document.getElementById('date-' + this.id);
      // new Datepicker(datepickerEl, {
      //     format: "dd/mm/yyyy",
      //     autohide: true,
      //     title: "Date expiration du document"
      // });
      const modalEl = document.getElementById('select-product-type-modal');
      this.selectProductTypeModal = new Modal(modalEl, {
        placement: 'center'
      });

      this.display_weeks.push(this.weeks[this.current_week - 40])
      this.display_weeks.push(this.weeks[this.current_week + 1 - 40])
      this.display_weeks.push(this.weeks[this.current_week + 2 - 40])
      this.display_weeks.push(this.weeks[this.current_week + 3 - 40])

      this.$nextTick(function () {

      });
    },



    methods:
    {

      OnClickPrevious: function () {
        this.display_weeks.slice(this.display_weeks.length - 1, 1)
        this.display_weeks.push(this.weeks[this.current_week + 3 - 40])
      },
      OnClickNext: function () {
        this.display_weeks.splice(0, 1)
        this.display_weeks.push(this.weeks[this.current_week + 4 - 40])
      },
      OnSelect: function () {
        this.selectProductTypeModal.show();
      },
      OnSelectProductTypeSelected: function (/*selection*/) {

      },
      OnSelectProductTypeClose: function () {
        this.selectProductTypeModal.hide();
      },
    }




  };
</script>
<style>
  .min-w-kanban {
    min-width: 200px;
  }
</style>
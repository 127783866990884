<template>
  <div class="flex flex-col w-full  pt-10 px-10 items-center   w-full ">
    <h2
      class="justify-start flex flex-row text-sm font-medium leading-tight text-gray-900 dark:text-white text-clip overflow-hidden items-center  w-full  "
    >
      <p class="flex-grow justify-start left overflow-hidden text-ellipsis">
        <span v-show="edit==false"> {{ title }} </span>

        <input
          v-show="edit==true"
          :id="'title-user-'+record.id"
          v-model="newTitle"
          type="text"
          class="form-control"
          @keydown.enter="updateTitle"
          @keydown.esc="cancelEditing"
        >
      </p>

      <button
        :id="`image-${record.id}-media-button`"
        class="flex items-center justify-center h-6 w-6 rounded-full inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-300 hover:bg-gray-500 :text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
        type="button"
      >
        <i class="fa-solid fa-ellipsis-vertical  fa-xl" />
      </button>
      <div
        :id="`image-${record.id}-media-dropdown`"
        class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
      >
        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
          <li>
            <div
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              @click="onImageRename(record)"
            >
              Renommer
            </div>
          </li>

          <li>
            <div
              href="#"
              disabled
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-500"
              @click="onImageShow(record)"
            >
              Editer
            </div>
          </li>

          <li>
            <div
              href="#"
              disabled
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-500"
              @click="onImageMove(record)"
            >
              Déplacer
            </div>
          </li>
          <li class="border-t-2 border-color-gray-200">
            <div
              href="#"
              disabled
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-500"
              @click="onDelete(record)"
            >
              Supprimer
            </div>
          </li>
        </ul>
        <div class="py-1 hidden">
          <a
            href="#"
            class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            @click="onImageDelete(record)"
          >Supprimer</a>
        </div>
      </div>
    </h2>
    <div class=" px-6  flex items-center justify-center  h-64">
      <img
        :src="getImageUrl(record)"
        class="mb-5 w-full w-full rounded-lg"
        alt="Image 1"
      >
    </div>
  </div>
</template>



<script>

    import { Dropdown } from "flowbite";


    export default {
        name: 'GridComponentImage',
        props: {
            record: Object
        },
        data() {
            return {
                title: "",
                newTitle: "",
                edit: false,
                options: {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    //onHide: () => {

                    //},
                    //onShow: () => {

                    //},
                    //onToggle: () => {

                    //}
                },
                dropdown: null
            }
        },
        watch: {
            record: function () {


                if (this.record?.id != undefined) {
                    this.title = this.record.name;



                    /*
                                        if ($triggerEl != undefined)
                                            this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);
                                        
                                  */
                }
            }
        },
        mounted() {

            if (this.record?.id != undefined) {
                this.title = this.record.name;


                const $targetEl = document.getElementById("image-" + this.record.id + "-media-dropdown");

                const $triggerEl = document.getElementById("image-" + this.record.id + "-media-button");


                if ($triggerEl != undefined)
                    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);

            }



        },
        methods:
        {

            getImageUrl: function (record) {

                if (record.format == "pdf") {
                    return "https://file-storage-ten.vercel.app/b4food/images/651b0ba49fe2f69966670f7d?icon=true"
                }
                return `${record.url}?icon=true`
            },

            onDelete: function () {
                this.$emit("delete", { tag: 'images', type: "image", name: this.title, id: this.record.id })
            },

            cancelEditing(


            ) { this.edit = false; },

            updateTitle() {
                this.edit = false;
                this.title = this.newTitle;
                this.$emit("updateName", { tag: 'images', type: "image", name: this.title, id: this.record.id })

            },
            onImageMove: function (record) {
             
                console.log(record)
            },

            onImageRename: function (record) {

                this.edit = true;
                this.newTitle = this.title;
                this.dropdown.hide()
                this.$nextTick(function () {
                    document.getElementById('title-user-' + record.id).focus()
                })
            },
        }
    }
</script>
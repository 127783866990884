<template>
  <div class="  w-full flex flex-wrap py-1 relative ">
    <label class="w-9/12 text-left block  text-sm font-bold text-gray-900  dark:text-white">
      {{ label }}  
      <span
        v-if="value"
        class="w-9/12 text-left    text-sm font-medium text-gray-900  dark:text-white"
      ><br>
        {{ common_labels['LABEL_MEASURED_TEMP'][lg] }}
        {{ value }}
      </span>
         

      <div>
        <br>
        <button
          v-if="file"
          type="button"
          class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <svg
            class="w-3 h-3 text-white mr-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 16"
          >
            <path
              d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"
            />
            <path
              d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"
            />
          </svg>
          {{ common_labels['BUTTON_DOWNLOAD_FILE'][lg] }}
        </button>
      </div>


    </label>
   
    <label
      class="w-3/12   flex   justify-end items-center  text-left block text-sm font-bold text-gray-900  dark:text-white"
    >

      <span
        v-if="source=='OK'"
        class="bg-green-100 text-green-800 text-sm font-bold mr-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
      >


        {{ common_labels['LABEL_COMPLIANT'][lg] }}
           
           
      </span>

      <span
        v-if="source=='KO'"
        class="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400"
      >
        {{ common_labels['LABEL_NOT_COMPLIANT'][lg] }}
      </span>
    </label>
  </div>
  <div
    v-if="comment != ''"
    class="hidden flex items-center w-full max-w-lg p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
    role="alert"
  >
    <i class="fa-solid fa-triangle-exclamation" />
    <div class="pl-4 text-sm font-normal">
      {{ comment }}
    </div>
  </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue";
    import common_labels from "@/assets/lg/common.json"
    export default {
        name: 'ContentCompliant',
        props: {
            id: String,
            label: String,

            required: Boolean,
            value: String,
            file: String,
            comment: String,
            source: String

        },
        async mounted() {

            this.output = this.value;
        },

        methods: {
            async onClickTranslate() {


                let response = await service.translate("EN", this.output, this.user?.token)

                this.output = response.text[0].text
               
            },
            getData() {
                return this.output;
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        components: {},
        data() {
            return {
                output: "",
                common_labels: common_labels,

                lg: "FR"

            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        }
    }
</script>
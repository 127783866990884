<template>
  <div
    class="  cursor-pointer list-group mt-2  w-full flex items-start    justify-start     dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div
      v-if="properties != undefined"
      class="w-full"
    >
      <div class="flex flex-col items-start w-full justify-start  pt-6  px-2 w-full">
        <div
          v-if="displayRequired"
          class="  mb-6 w-full text-left"
        >
          <label
            for="default-input"
            class="w-full  block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Contraintes</label>

          <div class="hidden  block text-black">
            {{ properties }}
          </div>

          <div class="flex w-full ">
            <div class="flex items-center w-full  ">
              <input
                id="inline-checked-checkbox"
                v-model="isRequired"
                checked
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @change="onChangeIsRequired"
              >
              <label
                for="inline-checked-checkbox"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Champ Obligatoire</label>
            </div>
          </div>
        </div>

        <div
          v-if="displayMessage"
          class="  mb-6 w-full text-left"
        >
          <label
            for="default-input"
            class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
          >Contraintes</label>

          <div class="hidden  block text-black">
            {{ properties }}
          </div>

          <div class="flex">
            <div class="flex items-center me-4">
              <input
                id="disabled-checkbox"
                disabled
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              >
              <label
                for="disabled-checkbox"
                class="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500"
              >Champ Obligatoire</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ControlsTableFormComponentDetails',
        props: {
            properties: Object,
            index: Number,
            definition: Object
        },
        data() {

            return {
                isRequired: true
            }
        },
        computed: {
            displayRequired: function () {
                if (this.properties?.type == 'avatar') return true
                if (this.properties?.type == 'radio') return true
                if (this.properties?.type == 'edit') return true
                if (this.properties?.type == 'button') return true
                if (this.properties?.type == 'date') return true

                if (this.properties?.type == 'scan-code') return true


                if (this.properties?.type == 'number') return true
                return false;
            },
            displayMessage: function () {
                if (this.properties?.type == 'avatar') return false
                if (this.properties?.type == 'radio') return false
                if (this.properties?.type == 'edit') return false
                if (this.properties?.type == 'date') return false
                if (this.properties?.type == 'scan-code') return false
                if (this.properties?.type == 'button') return false
                if (this.properties?.type == 'number') return false
                return true;
            }
        },
        watch:
        {
            properties: function () {
                this.isRequired = this.properties?._controls?.isRequired;
                if (this.isRequired == undefined) this.isRequired = true
            }
        },

        mounted() {
            this.isRequired = this.properties?._controls?.isRequired
            if (this.isRequired == undefined) this.isRequired = true

        },
        methods:
        {
            onChangeIsRequired: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "controls", att: '_controls', value: {isRequired: this.isRequired} })
            }
        }
    }
</script>
<template>
  <div
    id="select-ingredient-modal-old"
    tabindex="-1"
    aria-hidden="true"
    class="  fixed top-0 left-0 right-0 z-50 hidden w-full px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
  >
    <div class=" relative w-full h-full max-w-md md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click="onClose"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <!-- Modal header -->
        <div class="px-6 py-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE'][lg] }}
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-6">
          <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE_COMMENT'][lg] }}
          </p>

          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              id="default-search"
              v-model="searchText"
              type="search"
              class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Mockups, Logos..."
              required
              @input="onSearchInput"
              @change="onSearchChange"
            >
          </div>

          <ul class="my-4 space-y-3 overflow-y-auto  h-96">
            <li
              v-for="type in filteredTypes"
              :key="type._id"
              class="cursor-pointer"
            >
              <div
                class="  flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                @click="onProductSelect(type)"
              >
                <i
                  class="fa-solid fa-2xl  "
                  :class="type.icon"
                />
                <span class="flex-1 ml-3 whitespace-nowrap">{{ type.name[lg] }}</span>
                <span
                  class="hidden inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
                >Popular</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import labels from "@/assets/lg/product-types.json"
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";


    export default {
        name: 'SelectIngredientModal1',
        data() {
            return ({
                lg: "FR",
                labels: labels,
                searchText: "",
                filteredTypes: [],
                types: [],
                types2: [
                    {
                        id: "milk-cow-raw",
                        icon: "fa-cow",
                        name: {
                            "EN": "Raw Cow's Milk",
                            "FR": "Lait de Vache Cru"
                        },
                        displayName: {
                            "EN": "Raw Cow's Milk",
                            "FR": "Lait de Vache Cru",
                            "DE": "Rohe Kuhmilch",
                            "IT": "Latte Vaccino crudo",
                            "SP": "Leche cruda de Vaca"
                        }
                    },
                    {
                        id: "milk-cow-raw-organic",
                        icon: "fa-cow",
                        name: {
                            "EN": "Organic Raw Cow's Milk",
                            "FR": "Lait de Vache Cru BIO"
                        },
                        displayName: {
                            "EN": "Organic Raw Cow's Milk",
                            "FR": "Lait de Vache Cru BIO",
                            "DE": "Bio Kuhrohmilch",
                            "IT": "Latte vaccino crudo biologico",
                            "SP": "Leche de vaca cruda orgánica"
                        }
                    },
                    {
                        id: "milk-pasteurized",
                        icon: "fa-cow",
                        name: {
                            "EN": "Pasteurized Cow's Milk",
                            "FR": "Lait de Vache Pasteurisé"
                        },
                        displayName: {
                            "EN": "Pasteurized Cow's Milk",
                            "FR": "Lait de Vache Pasteurisé",
                            "DE": "Pasteurisierte Kuhmilch",
                            "IT": "Latte vaccino pastorizzato",
                            "SP": "Leche pasteurizada de vaca"
                        }
                    },

                    {
                        id: "milk-cow-pasteurized-organic",
                        icon: "fa-cow",
                        name: {
                            "EN": "Organic Pasteurized Cow's Milk",
                            "FR": "Lait de Vache Pasteurisé BIO"
                        },
                        displayName: {
                            "EN": "Organic Pasteurized Cow's Milk",
                            "FR": "Lait de Vache Pasteurisé BIO",
                            "DE": "Pasteurisierte Bio-Kuhmilch",
                            "IT": "Latte vaccino pastorizzato biologico",
                            "SP": "Leche de vaca pasteurizada orgánica"
                        }
                    }, {
                        id: "milk-goat-raw",
                        icon: "fa-circle",
                        name: {
                            "EN": "Raw Goat Milk",
                            "FR": "Lait de Chévre Cru"
                        },
                        displayName: {
                            "EN": "Raw Goat Milk",
                            "FR": "Lait de Chévre Cru",
                            "DE": "Rohe Ziegenmilch",
                            "IT": "Latte crudo di capra",
                            "SP": "Leche cruda de cabra"
                        }
                    },
                    {
                        id: "milk-goat-raw-organic",
                        icon: "fa-circle",
                        name: {
                            "EN": "Organic Raw Goat Milk",
                            "FR": "Lait de Chévre Cru BIO"
                        },
                        displayName: {
                            "EN": "Organic Raw Goat Milk",
                            "FR": "Lait de Chévre Cru BIO",
                            "DE": "Bio-Rohziegenmilch",
                            "IT": "Latte crudo di capra biologico",
                            "SP": "Leche de cabra cruda orgánica"
                        }
                    }, {
                        id: "milk-goat-pasteurized",
                        icon: "fa-circle",
                        name: {
                            "EN": "Pasteurized Goat Milk",
                            "FR": "Lait de Chévre Pasteurisé"
                        },
                        displayName: {
                            "EN": "Pasteurized Goat Milk",
                            "FR": "Lait de Chévre Pasteurisé",
                            "DE": "Pasteurisierte Ziegenmilch",
                            "IT": "Latte di capra pastorizzato",
                            "SP": "Lait de Chévre Pasteurisé"
                        }
                    }, {
                        id: "milk-goat-pasteurized-organic",
                        icon: "fa-circle",
                        name: {
                            "EN": "Organic Pasteurized Goat Milk",
                            "FR": "Lait de Chèvre Pasteurisé BIO"
                        },
                        displayName: {
                            "EN": "Organic Pasteurized Goat Milk",
                            "FR": "Lait de Chèvre Pasteurisé BIO",
                            "DE": "Pasteurisierte Bio-Ziegenmilch",
                            "IT": "Leche de cabra pasteurizada orgánica",
                            "SP": "Leche de cabra pasteurizada orgánica"
                        }
                    }, {
                        id: "ferment",
                        icon: "fa-circle",
                        name: {
                            "EN": "Ferment",
                            "FR": "Ferment"
                        },
                        displayName: {
                            "EN": "Ferment",
                            "FR": "Ferment",
                            "DE": "Fermentieren",
                            "IT": "Fermento",
                            "SP": "Ebullición"
                        }
                    }, {
                        id: "rennet",
                        icon: "fa-circle",
                        name: {
                            "EN": "Rennet",
                            "FR": "Présure"
                        },
                        displayName: {
                            "EN": "Rennet",
                            "FR": "Présure",
                            "DE": "Lab",
                            "IT": "Caglio",
                            "SP": "Cuajo"
                        }
                    },
                    {
                        id: "salt",
                        icon: "fa-circle",
                        name: {
                            "EN": "Salt",
                            "FR": "Sel"
                        },
                        displayName: {
                            "EN": "Salt",
                            "FR": "Sel",
                            "DE": "Salz",
                            "IT": "Sale",
                            "SP": "Sal"
                        }
                    }, {
                        id: "salt-organic",
                        icon: "fa-circle",
                        name: {
                            "EN": "Organic Salt",
                            "FR": "Sel BIO"
                        },
                        displayName: {
                            "EN": "Organic Salt",
                            "FR": "Sel BIO",
                            "DE": "Bio-Salz",
                            "IT": "Sale biologico",
                            "SP": "Sal orgánica"
                        }
                    }, {
                        id: "vegetal-coal",
                        icon: "fa-circle",
                        name: {
                            "EN": "Vegetal Coal",
                            "FR": "Charbon Végéral"
                        },
                        displayName: {
                            "EN": "Vegetal Coal",
                            "FR": "Charbon Végéral",
                            "DE": "Pflanzenkohle",
                            "IT": "Carbone vegetale",
                            "SP": "Carbón vegetal"
                        }
                    },
                    {
                        id: "calcium-chloride",
                        icon: "fa-circle",
                        name: {
                            "EN": "Calcium chloride",
                            "FR": "Chlorure de calcium"
                        },
                        displayName: {
                            "EN": "Calcium chloride",
                            "FR": "Chlorure de calcium",
                            "DE": "Calciumchlorid",
                            "IT": "Cloruro di calcio",
                            "SP": "Cloruro de calcio"
                        }
                    },
                    {
                        id: "chestnut-cream-organic",
                        icon: "fa-circle",
                        name: {
                            "EN": "Organic chestnut cream",
                            "FR": "Crème de marron BIO"
                        },
                        displayName: {
                            "EN": "Organic chestnut cream",
                            "FR": "Crème de marron BIO",
                            "DE": "Bio-Kastaniencreme",
                            "IT": "Crema di castagne biologica",
                            "SP": "Organic chestnut cream"
                        }
                    },
                    {
                        id: "chestnut-honey-organic",
                        icon: "fa-circle",
                        name: {
                            "EN": "Chestnut Honey BIO",
                            "FR": "Miel de châtaigniers"
                        },
                        displayName: {
                            "EN": "Chestnut Honey BIO",
                            "FR": "Miel de châtaigniers",
                            "DE": "Kastanienhonig",
                            "IT": "Miele di castagno",
                            "SP": "Miel de castaño"
                        }
                    }
                ]
            })
        },
        async mounted() {
      

            let collection = "data-sources/668cecd29535b23c096b6407/records";
           // let collection = "ingredient-types"

            this.types = (await service.getData(collection, this.user.token, {}, "json")).records
          //  console.log(JSON.stringify(this.types))
            this.filteredTypes = this.types;

        }, computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {

            onSearchInput() {

                let lg = this.lg
                let searchText = this.searchText
                this.filteredTypes = this.types.filter(function (el) {

                    return el.name[lg].toUpperCase().includes(searchText.toUpperCase())
                })
            },

            onSearchChange() {

            },


            onProductSelect(item) {
                this.$emit("select", item)
            },
            onClose() {
                this.$emit("close")
            }
        },
        components: {

        },
    }
</script>
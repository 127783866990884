<template>


  <div class="w-full bg-green-100 group">
    <div v-if="editedFieldId === id" class="flex items-center w-full">
      <input :ref="`field${id}`" v-model="outputedit" type="text" class="  bg-gray-50   py-1 px-2  border-none "
        @keyup.prevent="toggleEdit($event,false)">
      <button class="hidden text-sm ml-2 text-green-400 " @click.prevent="toggleEditConfirm($event,false)">
        <i class="fas fa-check" />
      </button>
      <button class="hidden text-sm ml-2 text-gray-400    " @click.prevent="toggleEditCancel($event,false)">
        <i class="fas fa-cross" />
      </button>
    </div>
    <div v-else class="flex items-center w-full ">
      <span>
        {{ output }}
      </span>
      <button class="text-sm ml-2 text-green-400 opacity-0 group-hover:opacity-100"
        @click.prevent="toggleEdit($event,true)">
        <i class="fas fa-edit" />
      </button>
    </div>

  </div>

</template>


<script>
  export default {
    name: 'ContentInlineEditor',
    props: {
      id: String,
      label: String,
      index: String,
      placeholder: String,
      required: Boolean,
      value: [Object, String],
      config: {},
      format: String,
      dunit: String,
      visible: Boolean,
      record: Object,
      countries: Array,
      isButton: Boolean,
    },
    data: function () {
      return {
        editedFieldId: null,
        outputedit: "",
        output: ""

      };
    },
    mounted() {
      this.output = this.value?.value
    },
    watch: {
      'value': function () {
        this.output = this.value.value
      }
    },
    methods:
    {
      toggleEditCancel: function (ev) {
        console.log(ev)
        this.outputedit = this.output
        this.editedFieldId = null;
      },
      toggleEditConfirm: function (ev) {
        this.output = this.outputedit
        console.log(ev)
        this.editedFieldId = null;


        let value = { value: this.output.toString(), unit: "", sign: "" }
 

 

        this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })



      },


      toggleEdit: function (ev, flag) {
        if (flag) {

          this.outputedit = this.output

          this.editedFieldId = this.id;
          this.$nextTick(() => {
            if (this.$refs["field" + this.id]) {
              this.$refs["field" + this.id].focus();
            }
          });
        } else {


          if (event.key === "Enter") {
            this.editedFieldId = null;
            this.output = this.outputedit

            let value = { value: this.output.toString(), unit: "", sign: "" }
            console.log(value)
         
          }

        }
      },
      getData() {
        return this.output
      }
    }
  }
</script>

<style>
  .editable-field {
    margin: 10px 0;
  }

  .editable-field button {

    padding: 4px 6px;

  }
</style>
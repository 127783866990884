<template>
  <div class="w-full mt-12 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start  ">
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
      <ul
        id="myTab"
        class="flex flex-wrap -mb-px text-sm font-medium text-center"
        data-tabs-toggle="#myFormTabContent"
        role="tablist"
      >
        <li
          class="mr-2"
          role="presentation"
        >
          <button
            id="profile-dev-tab"
            class="inline-block p-4 border-b-2 rounded-t-lg"
            data-tabs-target="#profile-dev"
            type="button"
            role="tab"
            aria-controls="profile-dev"
            aria-selected="false"
          >
            Propriétés
          </button>
        </li>
        <li
          class="mr-2"
          role="presentation"
        >
          <button
            id="dashboard-dev-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#dashboard-dev"
            type="button"
            role="tab"
            aria-controls="dashboard-dev"
            aria-selected="false"
          >
            Style Graphique
          </button>
        </li>
        <li
          class="mr-2"
          role="settings-dev"
        >
          <button
            id="settings-dev-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#settings-dev"
            type="button"
            role="tab"
            aria-controls="settings-dev"
            aria-selected="false"
          >
            Divers
          </button>
        </li>
      </ul>
    </div>
    <div
      id="myFormTabContent"
      class="w-full"
    >
      <div
        id="profile-dev"
        class="  p-4 rounded-lg bg-gray-50 dark:bg-gray-800 w-full"
        role="tabpanel"
        aria-labelledby="profile-dev-tab"
      >
        <div
          v-if="currentItem"
          class="hidden mb-6 w-full"
        >
          <label
            for="type"
            class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
          >
            Name <span class="font-medium">{{ tag }}</span></label>
          <input
            v-model="currentItem.shortName"
            type="text"
            class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Nom court"
            required
          >
        </div>

        <div class="mb-1 flex flex-col items-start justify-start w-6/12">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Type {{ currentItem.type }}</label>
          <select
            id="default"
            v-model="currentItem.type"
            class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option
              v-for="type in types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.value[lg] ? type.value[lg] : type.value }}
            </option>
          </select>
        </div>
        = {{ curentItem.parent }} =
        <DeveloperItemSelect
          v-if="(currentItem.type=='select' ||currentItem.type=='selectCollection')"
          :item="currentItem"
        />
        <DeveloperItemTag
          v-if="currentItem.type=='tag'"
          class="w-full"
          :item="currentItem"
        />
        <DeveloperItemText
          v-if="currentItem.type=='text'"
          :item="currentItem"
        />
        <DeveloperItemSwitch
          v-if="currentItem.type=='switch'"
          :item="currentItem"
        />
        <DeveloperItemImage
          v-if="currentItem.type=='image'"
          :item="currentItem"
        />

        <DeveloperItemImageUpload
          v-if="currentItem.type=='image-upload'"
          :item="currentItem"
        />
        <DeveloperItemNote
          v-if="currentItem.type=='note'"
          :item="currentItem"
        />
        <DeveloperItemFileUploadExtented
          v-if="currentItem.type=='file-upload-extended'"
          :item="currentItem"
        />
        <DeveloperItemFileList
          v-if="currentItem.type=='file-list'"
          :item="currentItem"
        />
        <DeveloperItemTable
          v-if="currentItem.type=='table'"
          :item="currentItem"
        />
        <DeveloperItemList
          v-if="currentItem.type=='list'"
          :item="currentItem"
        />
        <DeveloperItemComposer
          v-if="currentItem.type=='composer'"
          :item="currentItem"
        />

        <DeveloperItemDisplay
          v-if="currentItem.type=='display'"
          :item="currentItem"
        />
        <DeveloperItemBanner
          v-if="currentItem.type=='message'"
          :item="currentItem"
        />

        <DeveloperItemCompliant
          v-if="currentItem.type=='compliant'"
          :item="currentItem"
        />
        <DeveloperItemRadio
          v-if="currentItem.type=='radio'"
          :item="currentItem"
        />
        <DeveloperItemTinyTable
          v-if="currentItem.type=='tiny-table'"
          :item="currentItem"
        />
        <DeveloperItemLabel
          v-if="currentItem.type=='label'"
          :item="currentItem"
        />
        <DeveloperItemQRCode
          v-if="currentItem.type=='qrcode'"
          :item="currentItem"
        />


        <DeveloperItemTitle
          v-if="currentItem.type=='title'"
          :item="currentItem"
        />
        <DeveloperItemTimeline
          v-if="currentItem.type=='timeline'"
          :item="currentItem"
        />
      </div>
      <div
        id="dashboard-dev"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="dashboard-dev-tab"
      >
        <DeveloperStyle
          :item="currentItem"
          @update="onUpdateStyle"
        />
      </div>
      <div
        id="settings-dev"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="settings-dev-tab"
      >
        <DeveloperMisc :item="currentItem" />
      </div>
    </div>






    <div class="w-full flex flex-row items-center mb-3">
      <div class="w-full flex justify-start">
        <button
          class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          @click="onClickDelete"
        >
          Supprimer
        </button>
      </div>
      <div class="w-full flex justify-end">
        <button
          class="text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="onClickAddElement"
        >
          Ajouter
        </button>
        <button
          class="ml-2 text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="onClickDuplicateElement"
        >
          Dupliquer
        </button>
      </div>
    </div>
    <span class="bg-red-300 ">** {{ currentItem }} **</span>
  </div>
</template>

<script>
    import { Tabs } from "flowbite";
    import DeveloperStyle from "@/components/developer/DeveloperStyle.vue";
    import DeveloperMisc from "@/components/developer/DeveloperMisc.vue";

    import DeveloperItemText from "@/components/developer/DeveloperItemText.vue";
    import DeveloperItemNote from "@/components/developer/DeveloperItemNote.vue";
    import DeveloperItemSwitch from "@/components/developer/DeveloperItemSwitch.vue";

    import DeveloperItemFileUploadExtented from "@/components/developer/DeveloperItemFileUploadExtented.vue";
    import DeveloperItemFileList from "@/components/developer/DeveloperItemFileList.vue";
    import DeveloperItemDisplay from "@/components/developer/DeveloperItemDisplay.vue";

    import DeveloperItemTable from "@/components/developer/DeveloperItemTable.vue";

    import DeveloperItemTag from "@/components/developer/DeveloperItemTag.vue";
    import DeveloperItemSelect from "@/components/developer/DeveloperItemSelect.vue";
    import DeveloperItemImageUpload from "@/components/developer/DeveloperItemImageUpload.vue";
    import DeveloperItemImage from "@/components/developer/DeveloperItemImage.vue";
    import DeveloperItemRadio from "@/components/developer/DeveloperItemRadio.vue";

    import DeveloperItemList from "@/components/developer/DeveloperItemList.vue";
    import DeveloperItemComposer from "@/components/developer/DeveloperItemComposer.vue";
    import DeveloperItemBanner from "@/components/developer/DeveloperItemBanner.vue";
    import DeveloperItemCompliant from "@/components/developer/DeveloperItemCompliant.vue";
    import DeveloperItemTinyTable from "@/components/developer/DeveloperItemTinyTable.vue";

    import DeveloperItemQRCode from "@/components/developer/DeveloperItemQRCode.vue";
    import DeveloperItemLabel from "@/components/developer/DeveloperItemLabel.vue";

    import DeveloperItemTitle from "@/components/developer/DeveloperItemTitle.vue";

    import DeveloperItemTimeline from "@/components/developer/DeveloperItemTimeline.vue";



    import  slugify from 'slugify'
    export default {
        name: 'DeveloperView',
        components: {
            DeveloperStyle,
            DeveloperMisc,
            DeveloperItemText,
            DeveloperItemSwitch,
            DeveloperItemRadio,
            DeveloperItemNote,
            DeveloperItemTag,
            DeveloperItemTable,
            DeveloperItemSelect,
            DeveloperItemFileUploadExtented,
            DeveloperItemFileList,
            DeveloperItemImageUpload,
            DeveloperItemImage,
            DeveloperItemList,
            DeveloperItemComposer,
            DeveloperItemDisplay,
            DeveloperItemBanner,
            DeveloperItemTinyTable,
            DeveloperItemLabel,
            DeveloperItemQRCode,
            DeveloperItemTimeline,
            DeveloperItemTitle,
            DeveloperItemCompliant
        },
        props: {
            item: Object
        },
        data() {
            return ({
                lg: "FR",
                currentItem: {},
                tabElements: [],
                options: {
                    defaultTabId: 'home',
                    activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
                    inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
                    //  onShow: () => {
           
                    //  }
                },
                types: [
                    {
                        id: "display",
                        value: {
                            "EN": "Display Label",
                            "FR": "Affichage Libellé"
                        }
                    },
                    {
                        id: "text",
                        value: {
                            "EN": "Text Field",
                            "FR": "Champ de saisie"
                        }
                    },
                    {
                        id: "switch",
                        value: {
                            "EN": "Switch",
                            "FR": "Choix Oui/Non"
                        }
                    },
                    {
                        id: "title",
                        value: {
                            "EN": "Paragraph title",
                            "FR": "Titre de paragraphe"
                        }
                    },
                    {
                        id: "timeline",
                        value: {
                            "EN": "Timeline",
                            "FR": "Timeline"
                        }
                    },

                    {
                        id: "tiny-table",
                        value: {
                            "EN": "Smaill Table",
                            "FR": "Petit tableau"
                        }
                    },

                    {
                        id: "radio",
                        value: {
                            "EN": "radio",
                            "FR": "Une option"
                        }
                    },

                    {
                        id: "display",
                        value: {
                            "EN": "Display Value",
                            "FR": "Affichage Value"
                        }
                    },
                    {
                        id: "message",
                        value: {
                            "EN": "Message",
                            "FR": "Message"
                        }
                    },
                    {
                        id: "list",
                        value: {
                            "EN": "List",
                            "FR": "Liste"
                        }
                    },
                    {
                        id: "composer",
                        value: {
                            "EN": "Editeur Ingrédients",
                            "FR": "Editeur Ingrédients"
                        }
                    },
                    {
                        id: "compliant",
                        value: {
                            "EN": "Compliant Table",
                            "FR": "Tableau Conformités"
                        }
                    },
                    {
                        id: "table",
                        value: {
                            "EN": "Table",
                            "FR": "Tableau"
                        }
                    },
                    {
                        id: "file-upload-extended",
                        value: {
                            "EN": "File Upload",
                            "FR": "File Upload"
                        }
                    },
                    {
                        id: "file-list",
                        value: {
                            "EN": "File List",
                            "FR": "Liste de Fichiers"
                        }
                    },

                    {
                        id: "select",
                        value: {
                            "EN": "Simple Select",
                            "FR": "Selection Simple"
                        }
                    },
                    {
                        id: "tag",
                        value: {
                            "EN": "Tags Fields",
                            "FR": "Champ Tags"
                        }
                    },
                    {
                        id: "note",
                        value: {
                            "EN": "Note Fields",
                            "FR": "Champ de saisie Avancé"
                        }
                    },
                    {
                        id: "image",
                        value: {
                            "EN": "Display Image",
                            "FR": "Afficher Image"
                        }
                    },
                    {
                        id: "image-upload",
                        value: {
                            "EN": "Upload Image",
                            "FR": "Chargement Image"
                        }
                    },

                    {
                        id: "blank",
                        value: {
                            "EN": "Blank",
                            "FR": "Espace Vide"
                        }
                    }
                ],

            })
        },
        computed: {

            tag: function () {
                if (this.currentItem == undefined) return ""
                if (this.currentItem.shortName == undefined) return ""
                return slugify(this.currentItem.shortName, { lower: true })
            }
        },
        watch: {
            item: function (val) {
                let item = val;

                this.currentItem = item;

            }
        },
        mounted() {

            let item = this.item;

            this.currentItem = item;


            this.tabElements = [
                {
                    id: 'home',
                    triggerEl: document.querySelector('#profile-dev-tab'),
                    targetEl: document.querySelector('#profile-dev')
                },
                {
                    id: 'dashboard',
                    triggerEl: document.querySelector('#dashboard-dev-tab'),
                    targetEl: document.querySelector('#dashboard-dev')
                },
                {
                    id: 'settings',
                    triggerEl: document.querySelector('#settings-dev-tab'),
                    targetEl: document.querySelector('#settings-dev')
                }
            ]

            this.$nextTick(() => {
                //  this.timeout = setTimeout(this.loadTabs(), 13000);

                const tabs = new Tabs(this.tabElements, this.options);
        
                // open tab item based on id
                tabs.show('home');


            });
        },
        methods: {
            onClickDelete: function () {
             
            },
            onClickAddElement: function () {
                this.$emit("addItem", this.currentItem);
            },
            onClickDuplicateElement: function () {
                this.$emit("duplicateItem", this.currentItem);
            },
            onUpdateStyle: function (cls) {
              
                this.currentItem.class = cls.class;
          
                this.$emit("update", this.currentItem)
            }
        }

    }
</script>
<script setup>
    /* eslint-disable */

    import { Handle, Position } from '@vue-flow/core'
    import { computed, watch } from 'vue'


    const props = defineProps({
        data: {
            type: Object,
            required: true,
        } 
    })

   // watch(() => props, () => {
      
   // })



    // const onConnect = (params) => {}

    const sourceHandleStyleA = computed(() => ({

        backgroundColor: props.data.color,
        color: props.data.color,
        height: '16px',
        width: '16px',
        top: '-8px'
    }))
    const sourceHandleStyleB = computed(() => ({

        height: '16px',
        width: '16px',
        bottom: '-8px'
    })) 
</script>


<template>

    <div style="width:150px;text-align: center;">
        <div class="text-sm  font-bold">
            <div class="flex flex-row items-center justify-center">
                <div class="w-3/12 align-middle">
                    <i class="fa fa-code" style="font-size:35px"></i>
                </div>
                <div class="w-9/12 align-middle px-2">
                    {{props.data.label}}
                </div>
            </div>
        </div>
        <span v-if="data.data != undefined &&  data.data.parameters!= undefined && data.data.parameters.length >0"
            class="inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded text-xs">{{data.data
            && data.data.parameters && data.data.parameters.length}} <span
                v-if="data.data != undefined &&  data.data.parameters!= undefined && data.data.parameters.length==1">parameter</span><span
                v-if="data.data.parameters!= undefined && data.data.parameters.length > 1">parameters</span></span>

        <span v-if="data.data != undefined &&  data.data.parameters!= undefined && data.data.parameters.length  == 0"
            class="inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline bg-slate-600 text-white rounded text-xs">aucun
            paramètre</span>
        <Handle id="a" type="target" :position="Position.Top" :style="sourceHandleStyleA" />
        <Handle id="b" type="source" :position="Position.Bottom" :style="sourceHandleStyleB" />
    </div>
</template>
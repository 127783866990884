<template>
  <div
    class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Type de
          données</label>

        <div class="w-full flex flex-row">
          <div class="w-36 ml-3 flex items-center mb-4">
            <input
              id="default-radio-1"
              v-model="format"
              type="radio"
              value="number"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeFormat"
            >
            <label
              for="default-radio-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Nombre</label>
          </div>


          <div class="w-36 ml-3 flex items-center mb-4">
            <input
              id="default-radio-2"
              v-model="format"
              checked
              type="radio"
              value="temperature"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeFormat"
            >
            <label
              for="default-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Température</label>
          </div>

          <div class="w-36 ml-3 flex items-center mb-4">
            <input
              id="default-radio-3"
              v-model="format"
              checked
              type="radio"
              value="percent"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeFormat"
            >
            <label
              for="default-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Pourcentage</label>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Plage
          d'affichage</label>

        <div class="grid gap-6 mb-3 md:grid-cols-3 text-left">
          <div>
            <label
              for="min"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Minimum</label>
            <input
              id="minDisplayValue"
              v-model="minDisplayValue"
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="12"
              required
              @input="onChangeMinDisplayValue"
            >
          </div>
          <div>
            <label
              for="max"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Maximum</label>
            <input
              id="last_name"
              v-model="maxDisplayValue"
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="29"
              required
              @input="onChangeMaxDisplayValue"
            >
          </div>
          <div>
            <label
              for="step"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Pas</label>


            <select
              id="step"
              v-model="displayStepValue"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="onChangeDisplayStepValue"
            >
              <option value="0.01">
                0.01
              </option>
              <option value="0.05">
                0.05
              </option>
              <option value="0.1">
                0.1
              </option>
              <option value="0.5">
                0.5
              </option>
              <option value="1">
                1
              </option>


              <option value="10">
                10
              </option>
              <option value="100">
                100
              </option>
            </select>
          </div>
        </div>
      </div>






      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${element.id}`"
          v-model="data"
          type="text"
          class="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeData"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="hidden flex items-center">
            <input
              id="inline-disabled-checkbox"
              disabled
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="inline-disabled-checkbox"
              class="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500"
            >Inline disabled</label>
          </div>
        </div>
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-3 text-lg font-medium text-gray-900 dark:text-white"
        >Contraintes Conformité</label>
        <div class="flex items-center me-4 mb-6">
          <input
            id="inline-checked-checkbox"
            v-model="isRequired"
            checked
            type="checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @change="onChangeIsRequired"
          >
          <label
            for="inline-checked-checkbox"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Obligatoire</label>
        </div>
        <div class="grid gap-6 mb-3 md:grid-cols-2 text-left">
          <div>
            <label
              for="min"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Valeur
              Minimum</label>
            <input
              id="minValue"
              v-model="minValue"
              type="number"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="12"
              required
              @input="onChangeMinValue"
            >
          </div>
          <div>
            <label
              for="max"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Valeur
              Maximum</label>
            <input
              id="maxValue"
              v-model="maxValue"
              type="number"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="29"
              required
              @input="onChangeMaxValue"
            >
          </div>
        </div>
      </div>
      <div class="flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center">
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'NumberTableFormComponentDetails',
        props: {
            element: Object
        },
        data() {
            return {
                title: "",
                format: "",
                data: "",
                isRequired: false,
                isTitleDisplayed: false,
                minValue: "",
                maxValue: "",
                minDisplayValue: "",
                maxDisplayValue: "",
                displayStepValue: "1"
            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title
                this.isRequired = this.element?.isRequired;
                this.isTitleDisplayed = this.element?.isTitleDisplayed;
                this.data = this.element?.data;
                this.format = this.element?.format;
                this.minValue = this.element?.minValue
                this.maxValue = this.element?.maxValue
                this.minDisplayValue = this.element?.minDisplayValue
                this.maxDisplayValue = this.element?.maxDisplayValue
                this.displayStepValue = this.element?.displayStepValue
            }
        },
        mounted() {
            this.title = this.element?.title
            this.isRequired = this.element?.isRequired;
            this.isTitleDisplayed = this.element?.isTitleDisplayed;
            this.data = this.element?.data;
            this.format = this.element?.format;
            this.minValue = this.element?.minValue
            this.maxValue = this.element?.maxValue
            this.minDisplayValue = this.element?.minDisplayValue
            this.maxDisplayValue = this.element?.maxDisplayValue
            this.displayStepValue = this.element?.displayStepValue
        },
        methods:
        {
            addItem: function()
            {
                this.$emit("add", {type: 'avatar', id: this.element.id} )
            },

            onChangeDisplayStepValue: function () {
                this.$emit("update", { att: 'displayStepValue', value: this.displayStepValue })
            },
            onChangeMinDisplayValue: function () {
                this.$emit("update", { att: 'minDisplayValue', value: this.minDisplayValue })
            },

            onChangeMaxDisplayValue: function () {
                this.$emit("update", { att: 'maxDisplayValue', value: this.maxDisplayValue })
            },
            onChangeMinValue: function () {
                this.$emit("update", { att: 'minValue', value: this.minValue })
            },

            onChangeMaxValue: function () {
                this.$emit("update", { att: 'maxValue', value: this.maxValue })
            },
            onChangeFormat: function () {
                this.$emit("update", { att: 'format', value: this.format })
            },
            onChangeData: function () {
                this.$emit("update", { att: 'data', value: this.data })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("update", { att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },

            onChangeIsRequired: function () {
                this.$emit("update", { att: 'isRequired', value: this.isRequired })
            },

            onInputTitle: function () {
                this.$emit("update", { att: 'title', value: this.title })
            }
        }
    }
</script>
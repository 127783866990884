<template>
  <div
    id="select-users-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full px-4 py-12 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-2rem)] md:h-full"
  >
    <div class="relative w-full h-full max-w-xl h-auto pt-6 pb-6 bg-transparent">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click=" onClose"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <!-- Modal header -->
        <div class="px-6 py-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE'][lg] }}
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-6 ">
          <div class="h-96 w-full p-4">
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ labels['TITLE_SELECT_PRODUCT_TYPE_COMMENT'][lg] }}
            </p>

            <ul class="my-4 space-y-3  overflow-auto text-gray-800  ">
              <li class="cursor-pointer py-1 px-3">
                <div class="flex items-center mb-4 px-3">
                  <input
                    id="everyone-checkbox"
                    type="checkbox"
                    :checked="output.everyone"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    @click="onSelect('everyone')"
                  >
                  <label
                    for="everyone-checkbox"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  > Tous le monde
                  </label>
                </div>
              </li>


              <li
                v-for="record in records.records"
                :key="record.id"
                class="cursor-pointer py-1 px-3"
              >
                <div class="flex items-start mb-2  px-3">
                  <input
                    :id="`${record.id}-checkbox`"
                    type="checkbox"
                    :checked="output[record.id]"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    @click="onSelect(record.id)"
                  >
                  <label
                    for="`${record.id}-checkbox`"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  > {{ record.id }} {{ (output[record.id]) }}
                  </label>
                </div>
              </li>
            </ul>
            <hr>
            ** {{ rights }} **<hr>
            ** {{ output }} **
            <hr>
          </div>
          <div class="flex items-center justify-end">
            <button
              type="button"
              class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click=" onClose"
            >
              Annuler
            </button>
            <button
              type="button"
              class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
              @click="onValidate"
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import labels from "@/assets/lg/select-users-modal.json"

    export default {
        name: 'SelectUsersModal',
        components: {

        },
        props: {
            records: Object,
            rights: Object
        },
        data() {
            return ({
                lg: "FR",
                output: {},
                labels: labels,
                isLoading: false,
                selected: []
            })
        },
        watch:
        {
            "records": function () {
                // this.output.everyone = false

            },
            "rights": function () {

                let output = {}

                for (let i = 0; i < this.rights.length; i++) {
                    output[this.rights[i].replace(":read","")] = true
                }

                this.output = output;

            }
        },
        mounted() {

        },
        methods: {
            onSelect(id) {
                if (this.output[id] == undefined) this.output[id] = false;

                this.output[id] = !this.output[id]

            },
            onProductSelect(item) {
                this.$emit("select", item)
            },
            onValidate() {

                this.$emit("validate", this.output)
                // this.output = []
            },
            onClose() {
                this.$emit("close")
            }
        },
    }
</script>
<template>
  <div
    class="   relative mt-2  cursor-grab list-group mt-2 w-full bg-gray-700 border  flex items-start bg-gray-700 border  flex items-start  shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
    :class="{'h-24': (!element.isDescriptionDisplayed), 'h-32': (element.isDescriptionDisplayed), 'border-red-200 hover:border-solid hover:border-gray-100' : isSelected, 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end"
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
    <div class="hidden flex flex-col items-center w-6 ">
      <i class="fa-solid fa-grip-vertical" />
    </div>

    <div class="flex flex-col items-start w-full text-white pl-2">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full   text-xs text-left text-white  "
      >
        {{ element.title }}
      </div>
      <div
        v-if="element.isDescriptionDisplayed"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ element.description }}  
      </div>

      <div class="w-full h-12  ">
        <div class="w-full flex flex-row text-gray-800 text-xs ">
          <div
            v-for="item in items"
            :key="item.id"
            class="h-16 w-16  bg-orange-100 mx-1 rounded-lg flex flex-col items-center justify-center"
          >
            <div class="flex flex-col mt-2">
              <i
                class="fa-solid   fa-2xl mb-4"
                :class="item.icon"
              />
              <span>{{ item.id }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'AvatarTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
                items: [
                    { id: 1, icon: 'fa-user' },
                    { id: 2, icon: 'fa-user' },
                    { id: 3, icon: 'fa-user' },
                    { id: 4, icon: 'fa-user' },
                    { id: 5, icon: 'fa-user' }


                ]
            };
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        }
    }
</script>
<template>
  <div
    class="relative mt-2  h  cursor-grab list-group       mt-2 w-full flex items-center   dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
  >
    <div class="hidden flex flex-col items-center w-6 ">
      <i class="fa-solid fa-grip-vertical" />
    </div>

    <div class="flex flex-col items-center w-full text-gray-700 pl-2 pr-2">
      <div
        v-if="properties?.option?.title"
        class="w-full   text-sm text-left text-gray-700 font-bold"
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option?.title"
        class="w-full   text-sm text-left text-gray-500"
      >
        {{ properties?.description }}
      </div>
      <div class="w-full h-12  ">
        <div class="w-full flex flex-row text-gray-800 text-xs ">
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(1) }}
          </div>
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(2) }}
          </div>
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(3) }}
          </div>
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(4) }}
          </div>
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(5) }}
          </div>
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(6) }}
          </div>
          <div class="h-8 w-10  bg-blue-200 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(7) }}
          </div>
          <div class="hidden h-10 w-10  bg-blue-300 mx-1 rounded-lg flex items-center justify-center">
            {{ displayNumber(1) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'NumberTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties?.id })
            },
            formatNumber(val, step) {
                console.log(typeof step)
                

                if (step == undefined) return val.toFixed(0)
                let s = step.toString().split(".")
                console.log(s.length)
                if (s.length < 2) return val.toFixed(0)
               
                return val.toFixed(s[1].length)
            },
            displayNumber: function (index) {
                let r = parseFloat(this.properties?.values?.min) + index * parseFloat(this.properties?.values?.step)
                if (this.properties?.format == "percent") return this.formatNumber(r, this.properties?.values?.step) + "%"
                if (this.properties?.format == "temperature") return this.formatNumber(r, this.properties?.values?.step) + "°"
                return this.formatNumber(r, this.properties?.values?.step)
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {

                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
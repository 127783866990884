<template>
  <div
    id="select-image-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 p-10 z-50 justify-center items-center w-full md:inset-0  h-full"
  >
    <div class="  p-4   w-full h-screen fixed top-0 right-0 left-0  bottom-0 p-10 z-50 ">
      <!-- Modal content -->

      <div class="relative p-4 bg-white  rounded-lg shadow dark:bg-gray-800 sm:p-5 h-full flex flex-col ">
        <!-- Modal header -->
        <div
          class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            {{ labels['TITLE_SELECT_IMAGE'][lg] }} ({{ currentIn }})
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div
          class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
        >
          <ul class="flex flex-wrap -mb-px">
            <li class="me-2">
              <div
                class=" cursor-pointer inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                :class="{' border-blue-600 text-blue-600': tab == 1, 'border-transparent text-gray-600 ': tab != 1}"
                @click="selectLibrary('private')"
              >
                Ma Bibliothèque
              </div>
            </li>
            <li class="me-2">
              <div
                class=" cursor-pointer inline-block p-4  border-b-2 border-transparent  rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                :class="{' border-blue-600 text-blue-600': tab == 2, 'border-transparent text-gray-600 ': tab != 2}"
                aria-current="page"
                @click="selectLibrary('public')"
              >
                Bibliothèque Publique
              </div>
            </li>
            <li class="me-2">
              <div
                class=" cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                @click="selectLibrary('other')"
              >
                Favoris
              </div>
            </li>
          </ul>
        </div>
        <!-- Modal body -->
        <div class="flex-1  h-screen overflow-y-auto    bg-gray-50 ">
          <div class="   h-full bg-blue-100 overflow-y-auto  ">
            <nav
              class="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              aria-label="Breadcrumb"
            >
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                  <a
                    v-if="folders?.paths && (folders?.paths.length > 1)"
                    href="#"
                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    @click="selectRootPath()"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                      />
                    </svg>
                    Images

                  </a>
                  <span
                    v-if="folders?.paths && (folders?.paths.length < 2)"
                    class="inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-400  "
                  >
                    <svg
                      aria-hidden="true"
                      class="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                      />
                    </svg>
                    Images
                  </span>
                </li>
                <li
                  v-for="(path,index) in folders?.paths"
                  :key="path.id"
                >
                  <div
                    v-if="index !=0"
                    class="flex items-center"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <a
                      v-if="folders?.paths?.length != (index+1)"
                      href="#"
                      class="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                      @click="selectPath(path.id)"
                    >
                      {{ path.name }}
                    </a>
                    <span
                      v-if=" (folders?.paths?.length == (index+1))"
                      class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400"
                    >
                      {{ path.name }}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <Grid
              class="pt-10   h-screen flex items-start"
              :current="current"
              :is-loading="isLoading"
              :is-loading2="isLoading2"
              :is-loading3="isLoading3"
              :limit="limit"
              :offset="offset"
              :records="records"
              :folders="folders"
              @add="onAddClick"
              @export="onExportClick"
              @refresh="onRefreshClick"
              @change-folder="onChangeFolder"
              @refresh-page="onRefreshPage"
              @refresh-table="onRefreshTable"
              @select-image="onSelectImage"
            />
          </div>
        </div>
        <div class="mt-3  w-full right-16 bottom-6 flex items-center justify-end">
          <button
            type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()"
          >
            Annuler
          </button>
          <button
            type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    const MAX_ITEMS = 100;
    import labels from "@/assets/lg/product-types.json"
    import Grid from '@/components/GridComponentModal.vue'
    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue"
    export default {
        name: 'SelectImageModal',
        props: {
            currentIn: String
        },
        data() {
            return ({
                lg: "FR",
                labels: labels,
                current: null,
                tab: 1,
                private: null,
                rootfolder: null,
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                type: "images",
                limit: 1000,
                currentImage: null,
                offset: 0,
                records: [],
                folders: [],
                transparent: false,
                item: {
                    id: "1",
                    label: {
                        'EN': "",
                        'FR': ''
                    }
                },
            })
        },
        watch: {
            currentIn: function () {

                this.current = this.currentIn;
                this.records = [];
                this.folders = [];
                this.reloadPage(this.current, 0, MAX_ITEMS, {});
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {

            async selectLibrary(library) {
                this.offset = 0;
                this.limit = 100;

                if (library == "private") {
                    this.tab = 1;
                    this.current = null;
                    this.rootfolder = null;
                    this.type = "images";
                }

                if (library == "public") {
                    this.tab = 2;
                    this.current = "65ae5774a6d9952c1312127d",
                        this.rootfolder = "65ae5774a6d9952c1312127d";

                    this.type = "images/public";


                }

                let filter = {}

                filter.type = "FOLDER";

                filter.parent_id = this.current;
                this.folders = await service.getData(this.type, this.user?.token, Object.assign({}, { limit: this.limit, offset: this.offset }, filter))
                filter.type = "IMAGES";
                this.records = await service.getData(this.type, this.user?.token, Object.assign({}, { limit: this.limit, offset: this.offset }, filter))

            },
            onAddClick() {

            },
            onExportClick() {

            },
            onSelectImage(image) {
                this.currentImage = image.id
                this.$emit("selectImage", image);
            },
            onRefreshClick() {



            },
            selectPath: function (id) {
                //   let path = '/admin/media/'
                //   if (this.$route.fullPath.includes('/admin/media-documents'))
                //       path = '/admin/media-documents/'

                //   this.$router.push(path + id)



                this.reloadPage(id, 0, MAX_ITEMS, {});
            },
            selectRootPath: function () {

                this.reloadPage(this.rootfolder, 0, MAX_ITEMS, {});
            },


            async reloadPage(id, offset, limit, filter) {

                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }

                this.offset = offset;
                this.limit = limit;

                filter.type = "FOLDER";

                if (id != null) filter.parent_id = id;
                else delete filter.parent_id;

                /*
                                if (id != null) {
                                    this.currentfolder = await service.getDataId("images", id, this.user?.token)
                                  
                                
                                }
                */


                this.folders = await service.getData(this.type, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
                filter.type = "IMAGES";
                this.records = await service.getData(this.type, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))



            },
            onRefreshTable: function (item) {

                this.reloadPage("images", item.offset, item.limit, item.filter);
            },
            onRefreshPage: async function (item) {


                this.reloadPage(this.current, item.offset, item.limit, item.filter);
            },

            onChangeFolder: function (item) {




                this.private = item.id;
                this.current = item.id
                this.reloadPage(this.current, 0, MAX_ITEMS, {});
                this.$emit("changeRoot", item.id)
            },
            onValidate() {

                if ((this.currentImage == undefined) && (this.name == undefined)) {
                    alert("Sélectionner une image / fichier !");
                    return;
                }

                this.$emit("addImage", {})
            },

            onClose() {
                this.$emit("close")
            }
        },
        components: {
            Grid
        },
    }
</script>
<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Texte du
          bouton</label>
        <input
          id="default-input"
          v-model="label"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputLabel"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Action {{ action }}</label>
        <div class="w-full flex flex-row">
          <div class="ml-3 flex items-center mb-4 w-42">
            <input
              id="action-1"
              v-model="action"
              type="radio"
              value="take-photo"
              name="action"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputAction"
            >
            <label
              for="action-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Prendre une photo</label>
          </div>

          <div class="ml-3 flex items-center mb-4 w-42">
            <input
              id="action-2"
              v-model="action"
              type="radio"
              value="scan-doc"
              name="action"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputAction"
            >
            <label
              for="action-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Scanner un
              document</label>
          </div>

          <div class="ml-3 flex items-center mb-4 w-42">
            <input
              id="action-3"
              v-model="action"
              type="radio"
              value="scan-code"
              name="action"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputAction"
            >
            <label
              for="action-3"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Scanner un code</label>
          </div>
        </div>
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Nombre Maximum de photos/documents</label>
       
        <ul class="grid w-full gap-5 md:grid-cols-5 px-3">
          <li>
            <input
              id="number-1"
              v-model="max"
              type="radio"
              name="number-item"
              value="1"
              class="hidden peer"
              required
              @change="onInputMax"
            >
            <label
              for="number-1"
              class="inline-flex items-center justify-center w-full p-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-gray-600 peer-checked:text-gray-100   peer-checked:bg-gray-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg center font-semibold">1</div>
              </div>
            </label>
          </li>
          <li>
            <input
              id="number-2"
              v-model="max"
              type="radio"
              name="number-item"
              value="2"
              class="hidden peer"
              @change="onInputMax"
            >
            <label
              for="number-2"
              class="inline-flex items-center justify-center w-full p-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-gray-600 peer-checked:text-gray-100   peer-checked:bg-gray-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">2</div>
              </div>
            </label>
          </li>
          <li>
            <input
              id="number-3"
              v-model="max"
              type="radio"
              name="number-item"
              value="3"
              class="hidden peer"
              @change="onInputMax"
            >
            <label
              for="number-3"
              class="inline-flex items-center justify-center w-full p-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-gray-600 peer-checked:text-gray-100   peer-checked:bg-gray-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">3</div>
              </div>
            </label>
          </li>
          <li>
            <input
              id="number-4"
              v-model="max"
              type="radio"
              name="number-item"
              value="4"
              class="hidden peer"
              @change="onInputMax"
            >
            <label
              for="number-4"
              class="inline-flex items-center justify-center w-full p-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-gray-600 peer-checked:text-gray-100   peer-checked:bg-gray-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">4</div>
              </div>
            </label>
          </li>
          <li>
            <input
              id="number-5"
              v-model="max"
              type="radio"
              name="number-item"
              value="5"
              class="hidden peer"
              @change="onInputMax"
            >
            <label
              for="number-5"
              class="inline-flex items-center justify-center w-full p-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-gray-600 peer-checked:text-gray-100   peer-checked:bg-gray-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">5</div>
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Attribut</label>
        <input
          id="default-input"
          v-model="data"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeData"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>
        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description</label>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Contraintes</label>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-checked-checkbox"
              v-model="isRequired"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRequired"
            >
            <label
              for="inline-checked-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Obligatoire</label>
          </div>
        </div>
      </div>

      <div class="flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center">
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ButtonTableFormComponentDetails',
        props: {
            element: Object
        },
        data() {

            return {
                title: "",
                description: "",
                label: "",
                action: "take-photo",
                data: "",
                format: "",
                max: "1",
                isRequired: false,
                isTitleDisplayed: false,
                isDescriptionDisplayed: false

            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title;
                this.description = this.element?.description
                this.label = this.element.label
                this.action = this.element?.action
                this.max = this.element?.max
                this.data = this.element.data
                this.isRequired = this.element?.isRequired;
                this.isTitleDisplayed = this.element?.isTitleDisplayed;
                this.isDescriptionDisplayed = this.element?.isDescriptionDisplayed;
            }
        },
        mounted() {
            this.title = this.element?.title
            this.description = this.element?.description
            this.label = this.element.label
            this.action = this.element.action
            this.max = this.element.max
            this.data = this.element.data
            this.isRequired = this.element?.isRequired;
            this.isTitleDisplayed = this.element?.isTitleDisplayed;
            this.isDescriptionDisplayed = this.element?.isDescriptionDisplayed;

        },
        methods:
        {
            onChangeIsTitleDisplayed: function () {
                this.$emit("update", { att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },
            onChangeIsDescriptionDisplayed: function () {
                this.$emit("update", { att: 'isDescriptionDisplayed', value: this.isDescriptionDisplayed })
            },
            onChangeIsRequired: function () {
                this.$emit("update", { att: 'isRequired', value: this.isRequired })
            },
            onChangeData: function () {
                this.$emit("update", { att: 'data', value: this.data })
            },
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.element.id })
            },
            onInputFormat: function () {
                this.$emit("update", { att: 'format', value: this.format })
            },
            onInputMax: function () {
                this.$emit("update", { att: 'max', value: this.max })
            },
            onInputAction: function () {
                this.$emit("update", { att: 'action', value: this.action })
            },
            onInputTitle: function () {
                this.$emit("update", { att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("update", { att: 'description', value: this.description })
            },
            onInputLabel: function () {
                this.$emit("update", { att: 'label', value: this.label })
            }
        }
    }
</script>
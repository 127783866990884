<template>
  <aside
    id="sidebar-double-editor"
    class="top-0 flex z-20 fixed  bg-red-100 left-0 h-full transition-transform -translate-x-full sm:translate-x-0 pt-12"
    aria-label="Sidebar"
  >
    <div
      class="overflow-y-auto z-30 py-5 w-12  h-full bg-red-100 border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
    >
      <a
        href="#"
        class="hidden"
      >
        <img
          src="https://flowbite.com/docs/images/logo.svg"
          class="pl-2 mb-6 h-7"
          alt="Flowbite Logo"
        >
      </a>
      <ul class="   mt-12">
        <li
          v-for="item in config.items"
          :key="item.id"
          class="cursor-pointer"
        >
          <div
            class="cursor-pointer   flex flex-col h-12  w-12 items-center justify-center text-gray-400 hover:text-white   text-gray-400  transition duration-75 dark:text-gray-400 hover:text-gray-100 dark:hover:text-white hover:bg-gray-800  active:bg-green-600 dark:hover:bg-gray-700"
            :class="{'bg-gray-800 text-white' :(item.id==currentitem.id)}"
            @click="onSelectMenu(item)"
            @mouseover="onSelectMenu(item)"
          >
            <i
              :class="item.icon"
              class="mt-3 w-8 h-8  transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white"
            />
          </div>
        </li>
      </ul>
    </div>

    <div
      id="secondary-sidenav"
      class="   relative  w-80 pb-20  bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="h-20 flex items-center justify-center px-2 bg-gray-700  ">
        <span class="font-bold text-xl text-white">

          Title <i class="fa fa-deer" />
        </span>
      </div>



      <div class="overflow-y-scroll   h-full ">
        <div class=" pt-2 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700 h-max ">
          <a
            href="#"
            class="flex font-bold text-sm  items-center p-2 text-base   text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <span class="ml-6">Body</span>
          </a>
        </div>

        <ul class=" px-2">
          <NestedElement
            class="  dragArea list-group w-full"
            :list="list"
            @reload="load"
            @add-sub-section="onAddSubSection"
            @select="OnSelectItem"
            @delete="onDeleteItem"
            @move-section="onMoveSection"
            @move-sub-section="onMoveSubSection"
          />
          <li class=" pl-2">
            <button
              id="dropdownRightEndButtonBody"
              type="button"
              class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
              @click="openDropdownRightEndButtonBody()"
            >
              <div class="  mr-1">
                <i
                  class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                />
              </div>
              <div class="flex-1 ml-1 text-left text-sm">
                <span class=" text-left whitespace-nowrap font-normal  text-blue-600">
                  Ajouter une Section
                </span>
              </div>
            </button>
            <!-- Dropdown menu -->
            <div
              id="dropdownRightEndBody"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700"
            >
              <popupAddSection
                zone="body"
                @select="onSelectComponentBody"
              />
            </div>
          </li>
        </ul>
        <hr>
        <ul class="hidden space-y-0">
          <li
            v-for="item in menu?.body?.children"
            :key="item.id"
          >
            <button
              type="button"
              class="group flex flew-row items-center px-3 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75 dark:text-white dark:hover:bg-gray-700"
              :aria-controls="`dropdown-editor-menu-${item.id}`"
              :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
            >
              <div class="mr-1">
                <i
                  class="fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0  fa-chevron-down   "
                />
              </div>
              <div class="flex-1 ml-1 text-left text-sm">
                <span
                  class=" text-left whitespace-nowrap font-normal "
                >{{ item.name[lg] }} </span>
              </div>
              <div>
                <i
                  class="group-hover:opacity-100 opacity-0 text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                />
                <i
                  class="group-hover:opacity-100 opacity-0  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2 fa-eye  "
                />
              </div>
            </button>
            <ul
              :id="`dropdown-editor-menu-${item.id}`"
              class="hidden  space-y-1 "
            >
              <li
                v-for="subitem in item.children"
                :key="subitem.name"
                class=" pl-11   p-0 m-0 flex flex-col"
              >
                <button
                  type="button"
                  class="group flex flew-row items-center px-2 py-1 w-full   hover:bg-gray-200    font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                  :aria-controls="`dropdown-editor-menu-${item.id}`"
                  :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                >
                  <div class="  mr-1">
                    <i
                      :class="`fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0 ${subitem.icon}`"
                    />
                  </div>
                  <div class="flex-1 ml-1 text-left text-sm">
                    <span
                      class=" text-left whitespace-nowrap font-normal "
                    >
                      {{ subitem.name[lg] }}</span>
                  </div>
                  <div>
                    <i
                      class="group-hover:opacity-100 opacity-0 text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                    />
                    <i
                      class="group-hover:opacity-100 opacity-0 text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2 fa-eye  "
                    />
                  </div>
                </button>
                <button
                  type="button"
                  class="group  relative   cursor-pointer  w-full     h-1"
                >
                  <div
                    class="  absolute top-0 bottom-0 flex    items-center w-full ease-in-out  delay-100 "
                  >
                    <div
                      class="transition ease-in-out   duration-500 group-hover:opacity-100 opacity-0  h-0.5 w-full bg-blue-800  	 w-6 group-hover:w-full "
                    />
                  </div>
                  <div
                    class="group-hover:opacity-100 opacity-0 absolute top-0 bottom-0 left-0 right-0 justify-center flex items-center transition ease-in-out delay-100"
                  >
                    <i class="   text-blue-600 fa-solid   bg-white  fa-circle-radiation   " />
                  </div>
                </button>
              </li>


              <li class=" pl-11 ">
                <button
                  type="button"
                  class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                  :aria-controls="`dropdown-editor-menu-${item.id}`"
                  :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                >
                  <div class="  mr-1">
                    <i
                      class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                    />
                  </div>
                  <div class="flex-1 ml-1 text-left text-sm">
                    <span
                      class=" text-left whitespace-nowrap font-normal  text-blue-600"
                    > Add
                      block</span>
                  </div>
                </button>
              </li>
            </ul>
          </li>
          <li class=" pl-2">
            <button
              type="button"
              class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
            >
              <div class="  mr-1">
                <i
                  class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                />
              </div>
              <div class="flex-1 ml-1 text-left text-sm">
                <span
                  class=" text-left whitespace-nowrap font-normal  text-blue-600"
                > Add
                  Section +++</span>
              </div>
            </button>
          </li>
        </ul>
        <div class=" pt-2 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700 h-max ">
          <a
            href="#"
            class="flex font-bold text-sm  items-center p-2 text-base   text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <span class="ml-6">Footer</span>
          </a>
        </div>
        <ul class=" ">
          <li class=" pl-6 text-sm text-left justify-start pt-6">
            Zone Gauche
          </li>

          <draggable
            class="dragArea flex flex-col w-full"
            :list="footerL"
            group="footer"
            :move="checkMove"
            @change="onFooterChangeFooterLeft"
          >
            <li
              v-for="item in footerL"
              :key="item.id"
            >
              <button
                type="button"
                class="flex flew-row items-center px-3 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75 dark:text-white dark:hover:bg-gray-700"
                :class="{'bg-gray-200':item.id == selected?.id}"
                @click="onSelectSection(item)"
              >
                <div class="mr-1">
                  <i
                    class="fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0  fa-chevron-down   "
                  />
                </div>
                <div class="flex-1 ml-1 text-left text-sm">
                  <span
                    class=" text-left whitespace-nowrap font-normal "
                  >{{ item.name[lg] }}</span>
                </div>
                <div>
                  <i
                    class=" text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                    @click="onDelete($event, item)"
                  />
                  <i
                    class="  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2  "
                    :class="{'fa-eye' : (item.isVisible || (item.isVisible == undefined)), 'fa-eye-slash' : (!item.isVisible)}"
                    @click="showHideItem(item)"
                  />
                </div>
              </button>
              <ul
                :id="`dropdown-editor-menu-${item.id}`"
                class="hidden py-2 space-y-2"
              >
                <li
                  v-for="subitem in item.children"
                  :key="subitem.name"
                  class=" pl-11 "
                >
                  <button
                    type="button"
                    class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                    :aria-controls="`dropdown-editor-menu-${item.id}`"
                    :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                  >
                    <div class="  mr-1">
                      <i
                        :class="`fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0 ${subitem.icon}`"
                      />
                    </div>
                    <div class="flex-1 ml-1 text-left text-sm">
                      <span
                        class=" text-left whitespace-nowrap font-normal "
                      >
                        {{ subitem.name[lg] }}</span>
                    </div>
                    <div>
                      <i
                        class=" text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                        @click="onDelete($event, subitem)"
                      />
                      <i
                        :class="{'fa-eye' : (subitem.isVisible || (subitem.isVisible == undefined)), 'fa-eye-slash' : (!subitem.isVisible)}"
                        class="  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2    "
                        @click="showHideItem(subitem)"
                      />
                    </div>
                  </button>
                </li>


                <li class=" pl-11 ">
                  <button
                    type="button"
                    class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                    :aria-controls="`dropdown-editor-menu-${item.id}`"
                    :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                  >
                    <div class="  mr-1">
                      <i
                        class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                      />
                    </div>
                    <div class="flex-1 ml-1 text-left text-sm">
                      <span
                        class=" text-left whitespace-nowrap font-normal  text-blue-600"
                      > Add
                        block</span>
                    </div>
                  </button>
                </li>
              </ul>
            </li>
          </draggable>
          <li class=" pl-2">
            <button
              id="dropdownRightEndButtonFooterL"
              type="button"
              data-dropdown-placement="right-start"
              :class="{ 'hover:bg-transparent' : isFooterLeftFull, 'hover:bg-gray-200' : !isFooterLeftFull}"
              :disabled="isFooterLeftFull"
              class="flex flew-row items-center px-2 w-full   py-1 font-normal text-gray-900 rounded-lg transition duration-75    "
              @click="openDropdownRightEndButtonFooter('left')"
            >
              <div class="  mr-1">
                <i
                  class="fa-xs   fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                  :class="{ 'text-gray-400' : isFooterLeftFull, 'text-blue-600' : !isFooterLeftFull}"
                />
              </div>
              <div class="flex-1 ml-1 text-left text-sm">
                <span
                  class=" text-left whitespace-nowrap font-normal  "
                  :class="{ 'text-gray-400' : isFooterLeftFull, 'text-blue-600' : !isFooterLeftFull}"
                >
                  Ajouter un bouton
                </span>
              </div>
            </button>

            <!-- Dropdown menu -->
            <div
              id="dropdownRightEndFooterL"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700"
            >
              <popupAddSection
                zone="footer"
                area="left"
                @select="onSelectComponentFooter"
              />
            </div>
          </li>
          <li class=" pl-2 text-sm text-left justify-start  pt-6">
            Zone Centrale
          </li>
          <draggable
            class="dragArea list-group w-full"
            :list="footerC"
            group="footer"
            :move="checkMove"
            @change="onFooterChangeFooterCenter"
          >
            <li
              v-for="item in footerC"
              :key="item.id"
            >
              <button
                type="button"
                class="flex flew-row items-center px-3 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                :class="{'bg-gray-200':item.id == selected?.id}"
                @click="onSelectSection(item)"
              >
                <div class="mr-1">
                  <i
                    class="fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0  fa-chevron-down   "
                  />
                </div>
                <div class="flex-1 ml-1 text-left text-sm">
                  <span
                    class=" text-left whitespace-nowrap font-normal "
                  >{{ item.name[lg] }}</span>
                </div>
                <div>
                  <i
                    class=" text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                    @click="onDelete($event, item)"
                  />
                  <i
                    :class="{'fa-eye' : (item.isVisible || (item.isVisible == undefined)), 'fa-eye-slash' : (!item.isVisible)}"
                    class="  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2   "
                    @click="showHideItem(item)"
                  />
                </div>
              </button>
              <ul
                :id="`dropdown-editor-menu-${item.id}`"
                class="hidden py-2 space-y-2"
              >
                <li
                  v-for="subitem in item.children"
                  :key="subitem.name"
                  class=" pl-11 "
                >
                  <button
                    type="button"
                    class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                    :aria-controls="`dropdown-editor-menu-${item.id}`"
                    :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                  >
                    <div class="  mr-1">
                      <i
                        :class="`fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0 ${subitem.icon}`"
                      />
                    </div>
                    <div class="flex-1 ml-1 text-left text-sm">
                      <span
                        class=" text-left whitespace-nowrap font-normal "
                      >
                        {{ subitem.name[lg] }}</span>
                    </div>
                    <div>
                      <i
                        class=" text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                        :class="{'fa-eye' : (item.isVisible || (item.isVisible == undefined)), 'fa-eye-slash' : (!item.isVisible)}"
                        @click="showHideItem(item)"
                      />
                      <i class="  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2   " />
                    </div>
                  </button>
                </li>
                <li class=" pl-11 ">
                  <button
                    type="button"
                    class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                    :aria-controls="`dropdown-editor-menu-${item.id}`"
                    :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                  >
                    <div class="  mr-1">
                      <i
                        class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                      />
                    </div>
                    <div class="flex-1 ml-1 text-left text-sm">
                      <span class=" text-left whitespace-nowrap font-normal  text-blue-600"> Add
                        block</span>
                    </div>
                  </button>
                </li>
              </ul>
            </li>
          </draggable>
          <li class=" pl-2">
            <button
              id="dropdownRightEndButtonFooterC"
              type="button"
              data-dropdown-placement="right-start"
              :disabled="isFooterCenterFull"
              :class="{ 'hover:bg-transparent' : isFooterCenterFull, 'hover:bg-gray-200' : !isFooterCenterFull}"
              class="flex flew-row items-center px-2 w-full    py-1 font-normal text-gray-900 rounded-lg transition duration-75      "
              @click="openDropdownRightEndButtonFooter('center')"
            >
              <div class="  mr-1">
                <i
                  class="fa-xs  fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                  :class="{ 'text-gray-400' : isFooterCenterFull, 'text-blue-600' : !isFooterCenterFull}"
                />
              </div>
              <div class="flex-1 ml-1 text-left text-sm">
                <span
                  class=" text-left whitespace-nowrap font-normal   "
                  :class="{ 'text-gray-400' : isFooterCenterFull, 'text-blue-600' : !isFooterCenterFull}"
                >
                  Ajouter un bouton
                </span>
              </div>
            </button>

            <!-- Dropdown menu -->
            <div
              id="dropdownRightEndFooterC"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700"
            >
              <popupAddSection
                zone="footer"
                area="center"
                @select="onSelectComponentFooter"
              />
            </div>
          </li>
          <li class=" pl-2 text-sm text-left justify-start  pt-6">
            Zone Droite
          </li>
          <draggable
            class="dragArea list-group w-full"
            :list="footerR"
            group="footer"
            :move="checkMove"
            @change="onFooterChangeFooterRight"
          >
            <li
              v-for="item in footerR"
              :key="item.id"
            >
              <button
                type="button"
                class="flex flew-row items-center px-3 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                :class="{'bg-gray-200':item.id == selected?.id}"
                @click="onSelectSection(item)"
              >
                <div class="mr-1">
                  <i
                    class="fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0  fa-chevron-down   "
                  />
                </div>
                <div class="flex-1 ml-1 text-left text-sm">
                  <span
                    class=" text-left whitespace-nowrap font-normal "
                  >{{ item.name[lg] }}</span>
                </div>
                <div>
                  <i
                    class=" text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
                    @click="onDelete($event, item)"
                  />
                  <i
                    :class="{'fa-eye' : (item.isVisible || (item.isVisible == undefined)), 'fa-eye-slash' : (!item.isVisible)}"
                    class="  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2    "
                    @click="showHideItem(item)"
                  />
                </div>
              </button>
              <ul
                :id="`dropdown-editor-menu-${item.id}`"
                class="hidden py-2 space-y-2"
              >
                <li
                  v-for="subitem in item.children"
                  :key="subitem.name"
                  class=" pl-11 "
                >
                  <button
                    type="button"
                    class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                    :aria-controls="`dropdown-editor-menu-${item.id}`"
                    :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                  >
                    <div class="  mr-1">
                      <i
                        :class="`fa-xs text-gray-600 fa-solid  hover:rotate-90 shrink-0 ${subitem.icon}`"
                      />
                    </div>
                    <div class="flex-1 ml-1 text-left text-sm">
                      <span
                        class=" text-left whitespace-nowrap font-normal "
                      >
                        {{ subitem.name[lg] }}</span>
                    </div>
                    <div>
                      <i class=" text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 " />
                      <i class="  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2 fa-eye  " />
                    </div>
                  </button>
                </li>


                <li class=" pl-11 ">
                  <button
                    type="button"
                    class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
                    :aria-controls="`dropdown-editor-menu-${item.id}`"
                    :data-collapse-toggle="`dropdown-editor-menu-${item.id}`"
                  >
                    <div class="  mr-1">
                      <i
                        class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                      />
                    </div>
                    <div class="flex-1 ml-1 text-left text-sm">
                      <span
                        class=" text-left whitespace-nowrap font-normal  text-blue-600"
                      > Add
                        block</span>
                    </div>
                  </button>
                </li>
              </ul>
            </li>
          </draggable>
          <li class=" pl-2">
            <button
              id="dropdownRightEndButtonFooterR"
              type="button"
              data-dropdown-placement="right-start"
              :disabled="isFooterRightFull"
              :class="{ 'hover:bg-transparent' : isFooterRightFull, 'hover:bg-gray-200' : !isFooterRightFull}"
              class="flex flew-row items-center px-2 w-full   py-1 font-normal text-gray-900 rounded-lg transition duration-75   "
              @click="openDropdownRightEndButtonFooter('right')"
            >
              <div class="  mr-1">
                <i
                  class="fa-xs   fa-solid  hover:rotate-90 shrink-0  fa-plus   "
                  :class="{ 'text-gray-400' : isFooterRightFull, 'text-blue-600' : !isFooterRightFull}"
                />
              </div>
              <div class="flex-1 ml-1 text-left text-sm">
                <span
                  class=" text-left whitespace-nowrap font-normal  "
                  :class="{ 'text-gray-400' : isFooterRightFull, 'text-blue-600' : !isFooterRightFull}"
                >
                  Ajouter un bouton
                </span>
              </div>
            </button>

            <!-- Dropdown menu -->
            <div
              id="dropdownRightEndFooterR"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700"
            >
              <popupAddSection
                zone="footer"
                area="right"
                @select="onSelectComponentFooter"
              />
            </div>
          </li>
        </ul>
        <ul
          class="  pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700 h-max   items-center justify-start text-left"
        >
          <li>
            <a
              href="#"
              class="flex items-center text-sm text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group text-left"
            >
              <span class="ml-3">Documentation</span>
            </a>
          </li>

          <li>
            <a
              href="#"
              class="flex items-center  text-sm text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
            >
              <span class="ml-3">Aide</span>
            </a>
          </li>
        </ul>

        <div class="hidden flex absolute right-2 bottom-2 z-20 justify-end w-full bg-white dark:bg-gray-800">
          <button
            id="show-secondary-sidenav-button"
            aria-controls="secondary-sidenav"
            type="button"
            class="inline-flex p-2 text-gray-500 rounded-full cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
    import { mapGetters } from "vuex";
    import { Dropdown, Collapse /*,  Drawer*/ } from 'flowbite';
    import popupAddSection from '@/layout/Editor/Popups/popupAddSection.vue'
    import { v4 as uuidv4 } from 'uuid';
    import { VueDraggableNext } from 'vue-draggable-next'

    import NestedElement from '@/layout/Editor/NestedElement.vue'
    export default {
        name: 'SideNavEditor',
        props: {
            configNew: Object,
            properties: Object,
            selected: Object,
            sections: Array,
            footer: Object,
        },
        methods: {
            load: function () {
                console.log("SideNavEditor", "load")
                let sections = []
                let source = this.properties?.properties?.pages[0].components
                for (let i = 0; i < source?.length; i++) {


                    let components = []
                    for (let j = 0; j < source[i]?.components?.length; j++) {
                        components.push({
                            id: source[i].components[j].id,
                            type: source[i].components[j].type,
                            name: {
                                "FR": source[i].components[j].type,
                                "EN": source[i].components[j].type
                            },
                            icon: "",
                            components: [],

                        })
                    }

                    sections.push({
                        id: source[i].id,
                        type: source[i].type,
                        name: {
                            "FR": source[i].type,
                            "EN": source[i].type
                        },
                        icon: "",
                        components: components,

                    })




                }

                this.list = sections

                let footerRight = this.properties?.properties?.pages[0].submit.areas.right

                let footerR = []

                for (let i = 0; i < footerRight?.length; i++) {

                    if (footerRight[i].type == undefined) footerRight[i].type = "button"

                    footerR.push({
                        id: footerRight[i].id,
                        name: {
                            "FR": footerRight[i].type,
                            "EN": footerRight[i].type
                        },
                        type: footerRight[i].type + "button",
                        icon: "",
                        children: []
                    })
                }
                this.footerR = footerR



                let footerCenter = this.properties?.properties?.pages[0].submit.areas.center

                let footerC = []

                for (let i = 0; i < footerCenter?.length; i++) {

                    if (footerCenter[i].type == undefined) footerCenter[i].type = "button"

                    footerC.push({
                        id: footerCenter[i].id,
                        name: {
                            "FR": footerCenter[i].type,
                            "EN": footerCenter[i].type
                        },
                        type: footerCenter[i].type,
                        icon: "",
                        children: []
                    })
                }
                this.footerC = footerC


                let footerL = []
                let footerLeft = this.properties?.properties?.pages[0].submit.areas.left

                for (let i = 0; i < footerLeft?.length; i++) {


                    if (footerLeft[i].type == undefined) footerLeft[i].type = "button"

                    footerL.push({
                        id: footerLeft[i].id,
                        name: {
                            "FR": footerLeft[i].type,
                            "EN": footerLeft[i].type
                        },
                        type: footerLeft[i].type,
                        icon: "",
                        children: []
                    })
                }
                this.footerL = footerL

                console.log("left", JSON.stringify(this.footerL))
                console.log("center", JSON.stringify(this.footerC))
                console.log("right", JSON.stringify(this.footerR))

            },
            addItemInList: function (zone, area, item) {



                if (zone == "footer" && area == "left")
                    this.footerL.push({
                        id: item.id,
                        name: {
                            "FR": item.type,
                            "EN": item.type
                        },
                        type: item.type,
                        icon: "",
                        children: []
                    })


                if (zone == "footer" && area == "center")
                    this.footerC.push({
                        id: item.id,
                        name: {
                            "FR": item.type,
                            "EN": item.type
                        },
                        type: item.type,
                        icon: "",
                        children: []
                    })


                if (zone == "footer" && area == "right")
                    this.footerR.push({
                        id: item.id,
                        name: {
                            "FR": item.type,
                            "EN": item.type
                        },
                        type: item.type,
                        icon: "",
                        children: []
                    })


            },
            deleteItemInList: function (item) {
                console.log("Find = ", item.id)
                {
                    let index = this.footerL?.findIndex(function (v) { return v.id == item.id })
                    if (index != -1) {
                        this.selectedSection = null
                        const arrayOfLetters = this.footerL
                        const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)
                        const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                        const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                        this.footerL = copyWithoutThirdElement
                        console.log("delete  this.footerL", index)
                    }

                }
                {
                    let index = this.footerC?.findIndex(function (v) { return v.id == item.id })
                    if (index != -1) {
                        this.selectedSection = null
                        const arrayOfLetters = this.footerC
                        const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)
                        const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                        const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                        this.footerC = copyWithoutThirdElement
                        console.log("delete  this.footerC", index)
                    }

                }
                {
                    let index = this.list?.findIndex(function (v) { return v.id == item.id })
                    if (index != -1) {
                        this.selectedSection = null
                        const arrayOfLetters = this.list
                        const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)
                        const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                        const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                        this.list = copyWithoutThirdElement
                        console.log("delete  this.list", index)
                    }

                }

                {
                    let index = this.footerR?.findIndex(function (v) { return v.id == item.id })
                    if (index != -1) {
                        this.selectedSection = null
                        const arrayOfLetters = this.footerR
                        const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)
                        const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                        const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                        this.footerR = copyWithoutThirdElement
                        console.log("delete  this.footerR", index)
                    }

                }
            },

            deleteItemInListBody: function (item) {
                console.log("Find = ", item.id)


                {
                    let index = this.list?.findIndex(function (v) { return v.id == item.id })
                    if (index != -1) {
                        this.selectedSection = null
                        const arrayOfLetters = this.list
                        const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)
                        const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                        const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                        this.list = copyWithoutThirdElement
                        console.log("delete  this.list", index)
                        return;
                    }


                    for (let y = 0; y < this.list.length; y++) {
                        if (this.list[y].components != undefined) {
                            let index = this.list[y].components?.findIndex(function (v) { return v.id == item.id })
                            if (index != -1) {
                                this.selectedSection = null
                                const arrayOfLetters = this.list[y].components
                                const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)
                                const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                                const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                                this.list[y].components = copyWithoutThirdElement
                                console.log("delete  this.list", index)
                                return;
                            }
                        }
                    }

                }

            },


            onDeleteItem: function (item) {

                console.log("SideNavEditor", "onDeleteItem onDeleteItem onDeleteItem onDeleteItem onDeleteItem")
                console.log(item)


                this.$emit("deleteSection", item)

                this.deleteItemInListBody(item)
            },
            onDelete: function (event, item) {
                event.stopPropagation()

                console.log("onDelete onDelete onDelete onDelete onDelete")
                console.log(item)


                this.$emit("deleteSection", item)


                this.deleteItemInList(item)

                return false
            },

            onMoveSubSection: function (event) {

                console.log("SideNavEditor", "onMoveSubSection onMoveSubSection onMoveSubSection onMoveSubSection")
                this.$emit("moveSubSection", event)
            },

            onMoveSection: function (event) {

                console.log("SideNavEditor", "onMoveSection onMoveSection onMoveSection onMoveSection")
                this.$emit("moveSection", event)

            },
            openDropdownRightEndButtonFooter: function (zone) {
                let button =
                {
                    "id": uuidv4(),
                    "title": "Valider",
                    "text": "Valider",
                    "type": "validate",
                    "isDisplayValidate": true,
                    "isDisplayNext": false,
                    "isDisplayPrevious": false,
                    "alignment": "end",
                    "format": "FIXED-BOTTOM",
                    "suffix": "none",
                    "prefix": "none"
                }

                if (zone == 'left') {

                    this.$emit("addSection", { zone: "footer", section: button, area: 'left' })
                    this.selecteAddSectionButton = null
                    this.footerL.push({
                        id: button.id,
                        name: {
                            "FR": button.type,
                            "EN": button.type
                        },
                        type: button.type,
                        icon: "",
                        children: []
                    })
                    //  this.selecteAddSectionButton =  button;
                    return;
                }

                if (zone == 'center') {

                    this.$emit("addSection", { zone: "footer", section: button, area: 'center' })
                    this.selecteAddSectionButton = null
                    this.footerC.push({
                        id: button.id,
                        name: {
                            "FR": button.type,
                            "EN": button.type
                        },
                        type: button.type,
                        icon: "",
                        children: []
                    })
                    //  this.selecteAddSectionButton =  button;
                    return;
                }
                if (zone == 'right') {

                    this.$emit("addSection", { zone: "footer", section: button, area: 'right' })
                    this.selecteAddSectionButton = null
                    this.footerR.push({
                        id: button.id,
                        name: {
                            "FR": button.type,
                            "EN": button.type
                        },
                        type: button.type,
                        icon: "",
                        children: []
                    })
                    // this.selecteAddSectionButton =  button;
                    return;
                }
            },
            checkMove(event) {
                console.log('checkMove', event.draggedContext)
                console.log('Future index: ' + event.draggedContext.futureIndex)
            },
            log(item) {
                //   event.stopPropagation();
                console.log("log")
                console.log(JSON.stringify(item))
                // console.log(JSON.stringify(this.list))
                if (item.moved != undefined) {

                    if (this.item != null) {
                        this.$emit("moveSection", { zone: "body", id: item.moved.element.id, parent: this.item.id, oldIndex: item.moved.oldIndex, newIndex: item.moved.newIndex })

                    }
                    else {
                        this.$emit("moveSection", { zone: "body", id: item.moved.element.id, oldIndex: item.moved.oldIndex, newIndex: item.moved.newIndex })
                    }


                }

            },

            OnSelectItem(event) {
                this.$emit("select", event)
            },
            onAddSubSection(event) {
                this.$emit("addSubSection", event)
            },
            onFooterChangeFooterLeft(item) {
                console.log("onFooterChangeFooterLeft")
                this.onFooterChange(item, "footer", "left")
            },
            onFooterChangeFooterCenter(item) {
                console.log("onFooterChangeFooterCenter")
                this.onFooterChange(item, "footer", "center")
            },
            onFooterChangeFooterRight(item) {
                console.log("onFooterChangeFooterRight")
                this.onFooterChange(item, "footer", "right")
            },
            onFooterChange(item, zone, area) {
                //  event.stopPropagation();
                console.log("onFooterChange")
                // console.log(JSON.stringify(item))
                // console.log(JSON.stringify(this.list))

                if (item.moved != undefined) {
                    console.log("Move =", item.removed.element.id, " oldIndex ===> ", item.removed.oldIndex, " newIndex ===> ", item.removed.oldIndex)
                    this.$emit("moveSection", { zone: zone, area: area, id: item.moved.element.id, oldIndex: item.moved.oldIndex, newIndex: item.moved.newIndex })
                }


                if (item.removed != undefined) {
                    console.log("Remove =", item.removed.element.id, " index ===> ", item.removed.oldIndex)
                    this.$emit("deleteSection", { zone: zone, area: area, id: item.removed.element.id, oldIndex: item.removed.oldIndex })
                    this.deleteItemInList(item.removed.element)
                }


                if (item.added != undefined) {
                    console.log("///////////////////////////////////////////////////////// ADDED")
                    let section = null;
                    let indexL = this.properties?.properties?.pages[0].submit.areas?.left?.findIndex(function (v) { return v.id == item.added.element.id })
                    console.log("///////////////////////////////////////////////////////// ADDED - LEFT", indexL)

                    if (indexL != -1) {
                        console.log(JSON.stringify(this.properties?.properties?.pages[0].submit.areas?.left))
                        section = JSON.parse(JSON.stringify(this.properties?.properties?.pages[0].submit.areas?.left[indexL]))
                        section.id = uuidv4()
                        // this.$emit("addSection", { zone: zone, section: section, area: area })
                        // this.addItemInList(zone, area, section)
                    }


                    let indexC = this.properties?.properties?.pages[0].submit.areas?.center?.findIndex(function (v) { return v.id == item.added.element.id })
                    console.log("///////////////////////////////////////////////////////// ADDED - CENTER", indexC)

                    if (indexC != -1) {
                        section = JSON.parse(JSON.stringify(this.properties?.properties?.pages[0].submit.areas?.center[indexC]))
                        section.id = uuidv4()
                        // this.$emit("addSection", { zone: zone, section: section, area: area })
                        //  this.addItemInList(zone, area, section)
                    }


                    let indexR = this.properties?.properties?.pages[0].submit.areas?.right?.findIndex(function (v) { return v.id == item.added.element.id })
                    console.log("///////////////////////////////////////////////////////// ADDED - CENTER", indexR)

                    if (indexR != -1) {
                        section = JSON.parse(JSON.stringify(this.properties?.properties?.pages[0].submit.areas?.right[indexR]))
                        section.id = uuidv4()
                        //this.$emit("addSection", { zone: zone, section: section, area: area })
                        //  this.addItemInList(zone, area, section)
                    }

                    if (section != undefined) {
                        console.log("ADD ITEM To ", zone, " -  ", area)
                        this.$emit("addSection", { zone: zone, section: section, area: area })
                        this.addItemInList(zone, area, section)
                    }


                }
            },
            openDropdownRightEndButtonBody: function () {

                console.log("openDropdownRightEndButtonBody")



                if (this.dropdownAddSectionBody == undefined) {
                    const options = {
                        triggerType: 'none',
                        placement: 'right-start',
                        onHide: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been hidden');
                        },
                        onShow: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been shown');
                        },
                        onToggle: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been toggled');
                        },
                    }
                    const $targetElBody = document.getElementById('dropdownRightEndBody');
                    const $triggerElBody = document.getElementById('dropdownRightEndButtonBody');
                    let dropdownBody = new Dropdown($targetElBody, $triggerElBody, options);
                    this.dropdownAddSectionBody = dropdownBody;
                    this.dropdownAddSectionBody.toggle();
                }
                else {

                    this.dropdownAddSectionBody.toggle();
                }
                this.selecteAddSectionButton = null

            },
            openDropdownRightEndButtonBodyItem: function (id) {
                console.log("openDropdownRightEndButtonBodyItem")

                if (this.dropdownAddSection[id] == undefined) {

                    console.log("Create DropDown ", id)
                    const options = {
                        triggerType: 'none',
                        placement: 'right-start',
                        onHide: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been hidden');
                        },
                        onShow: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been shown');
                        },
                        onToggle: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been toggled');
                        },
                    }

                    const $targetElBody = document.getElementById('dropdownRightEndBodyItem' + id);
                    const $triggerElBody = document.getElementById('dropdownRightEndButtonBodyItem' + id);

                    console.log(">>>>>>>>", $targetElBody)
                    console.log(">>>>>>>>", $triggerElBody)
                    let dropdownBody = new Dropdown($targetElBody, $triggerElBody, options);
                    this.dropdownAddSection[id] = dropdownBody
                    this.dropdownAddSection[id].toggle();
                }
                else {
                    console.log("exit DropDown ", id)
                    this.dropdownAddSection[id].toggle();
                }
                this.selecteAddSectionButton = id

            },


            openDropdownRightEndButtonBodySubItem: function (id) {
                console.log("openDropdownRightEndButtonBodySubItem")

                if (this.dropdownAddSubSection[id] == undefined) {

                    console.log("Create DropDown ", id)
                    const options = {
                        triggerType: 'none',
                        placement: 'right-start',
                        onHide: () => {
                            console.log('openDropdownRightEndButtonBodySubItem  dropdown has been hidden');
                        },
                        onShow: () => {
                            console.log('openDropdownRightEndButtonBodySubItem  dropdown has been shown');
                        },
                        onToggle: () => {
                            console.log('openDropdownRightEndButtonBodySubItem  dropdown has been toggled');
                        },
                    }

                    const $targetElBody = document.getElementById('dropdownRightEndBodySubItem' + id);
                    const $triggerElBody = document.getElementById('dropdownRightEndButtonBodySubItem' + id);

                    console.log(">>>>>>>>", $targetElBody)
                    console.log(">>>>>>>>", $triggerElBody)
                    let dropdownBody = new Dropdown($targetElBody, $triggerElBody, options);
                    this.dropdownAddSubSection[id] = dropdownBody
                    this.dropdownAddSubSection[id].toggle();
                }
                else {
                    console.log("exit DropDown ", id)
                    this.dropdownAddSubSection[id].toggle();
                }
                this.selecteAddSubSectionButton = id

            },


            onSelectComponentBodyItem: function (event) {
                console.log("onSelectComponentBodyItem")
                console.log(JSON.stringify(event))
                if (this.dropdownAddSection[event.id].isVisible()) this.dropdownAddSection[event.id].toggle();
                console.log(this.dropdownAddSection[event.id].isVisible())
                this.selecteAddSectionButton = null
                this.$emit("addSection", { zone: "body", section: event.section, after: event.id })

                this.load()




            },



            onSelectComponentBodySubItem: function (event) {
                console.log("onSelectComponentBodySubItem", event?.id, event.parent)
                console.log(event)
                console.log(this.dropdownAddSubSection)

                if (this.dropdownAddSubSection[event.parent].isVisible()) this.dropdownAddSubSection[event.parent].toggle();
                this.selecteAddSubSectionButton = null
                this.$emit("addSubSection", { zone: "body", section: event.section, parent: event.parent })
                this.load()
            },


            onSelectComponentBody: function (event) {
                console.log("onSelectComponentBody")
                if (this.dropdownAddSectionBody.isVisible()) this.dropdownAddSectionBody.toggle();
                console.log(this.dropdownAddSectionBody.isVisible())
                this.$emit("addSection", { zone: "body", section: event.section, parent: null })

                this.load()
                /*
                this.list.push({
                        id: event.section.id,
                        type: event.section.type,
                        name: {
                            "FR": event.section.type,
                            "EN": event.section.type
                        },
                        icon: "",
                        components: event.section?.components,

                    })
                    */

            },
            onSelectComponentFooter: function (event) {
                if (event.area == 'left') {
                    if (this.dropdownAddSectionFooterL.isVisible()) this.dropdownAddSectionFooterL.toggle();
                }
                if (event.area == 'center') {
                    if (this.dropdownAddSectionFooterC.isVisible()) this.dropdownAddSectionFooterC.toggle();
                }
                if (event.area == 'right') {
                    if (this.dropdownAddSectionFooterR.isVisible()) this.dropdownAddSectionFooterR.toggle();
                }
                this.$emit("addSection", { zone: "footer", section: event.section, area: event.area })
            },
            onSelectSection: function (item) {
                console.log("onSelectSection onSelectSection onSelectSection")
                const options = {
                    triggerType: 'none',

                    onCollapse: () => {
                        console.log('onSelectSectionelement has been collapsed');
                    },
                    onExpand: () => {
                        console.log('onSelectSection element has been expanded');
                    },
                    onToggle: () => {
                        console.log('onSelectSection  dropdown has been toggled');
                    },
                }
                if (this.dropdownAddSection[item.id] == undefined) {
                    const $targetElBody = document.getElementById("dropdown-pages" + item.id);
                    const $triggerElBody = document.getElementById("dropdown-pages-button-" + item.id);

                    console.log("dropdown-pages" + item.id)
                    console.log($triggerElBody)
                    console.log($targetElBody)


                    const instanceOptions = {
                        id: '"dropdown-pages"+item.id',
                        override: true
                    };

                    this.dropdownAddSection[item.id] = new Collapse($targetElBody, $triggerElBody, options, instanceOptions);
                    // this.dropdownAddSection[item.id].collapse();
                }



                // 
                this.$emit("select", item)

            },
            onSelectSubSection: function (event) {

                event.stopPropagation()
                this.$emit("selectSubSection", event)

            },
            onSelectMenu: function (item) {
                this.currentitem = item;
                this.subitems = item.children;

                /// this.sideNav.show();
            },
            showHideItem: function (item) {
                console.log("showHideItem")
                console.log(item)
                if (item.isVisible == undefined) {
                    item.isVisible = false
                    this.$emit("updateSection", { id: item.id, zone: item.zone, att: "isVisible", value: item.isVisible })
                    return true;
                }

                item.isVisible = !item.isVisible
                this.$emit("updateSection", { id: item.id, zone: item.zone, att: "isVisible", value: item.isVisible })
                return true;
            },
            onSelectSubMenu: function (item) {



                this.$router.push(item.link)
            },
            openMenu: function () {
                const $targetEl = document.getElementById('dropdown-tenant');
                const $triggerEl = document.getElementById('dropdown-tenant-button');
                const options = {
                    placement: 'bottom',
                    triggerType: 'hover',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300
                }
                let dropdownTenant = new Dropdown($targetEl, $triggerEl, options);
                dropdownTenant.toggle();
            },
            getMediaIdentifier: function () {
                if (this.user == undefined) return;

                return this.user.tenant.media_id;
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            isFooterRightFull: function () {
                if (this.properties == undefined) return false
                return (this.properties?.footer?.right?.length > 0)
            },
            isFooterCenterFull: function () {
                return (this.properties?.footer?.center?.length > 0)
            },
            isFooterLeftFull: function () {
                return (this.properties?.footer?.left?.length > 0)
            }
        },
        watch: {

            'sections': function () {
                console.log("///WATCH Properties.sections----------SideNavEditor")
                this.load()
            },
            'footer': function () {

            },
            properties: function () {
                console.log("///WATCH Properties----------SideNavEditor")
                console.log(JSON.stringify(this.properties))
                //:id="`dropdownRightEndButtonBodyItem${item.id}`"
                this.load()
                //this.menu.body.children = sections

            }
        },
        mounted() {
            console.log("mounted ------------------------------------------------")
            this.load()
        },
        components: { popupAddSection, draggable: VueDraggableNext, NestedElement },
        data() {
            return {
                lg: "FR",
                sideNav: [],

                enabled: true,
                footerR: [],
                footerC: [],
                footerL: [],
                list: [

                ],
                dragging: false,


                selecteAddSectionButton: null,
                dropdownAddSectionFooterL: null,
                dropdownAddSectionFooterC: null,
                dropdownAddSectionFooterR: null,
                dropdownAddSectionBody: null,
                dropdownAddSection: {},
                dropdownAddSubSection: {},
                subitems: [],
                currentitem: {},
                loading2: false,
                mode: 'closed',
                config: {},
                menu: {
                    header: {
                        id: "header",
                        name: {
                            "FR": "Header",
                            "EN": "Header"
                        },
                        icon: "",
                        children: [

                            {
                                id: "header-1",
                                name: {
                                    "FR": "Header 1",
                                    "EN": "Header 2"
                                },
                                icon: "",
                                children: [
                                    {
                                        id: "header 1-1",
                                        name: {
                                            "FR": "Header 1-1",
                                            "EN": "Header 1-1"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "header 1-2",
                                        name: {
                                            "FR": "Header 1-2",
                                            "EN": "Header 1-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "header 1-2",
                                        name: {
                                            "FR": "Header 1-2",
                                            "EN": "Header 1-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    }
                                ]
                            },
                            {
                                id: "header-2",
                                name: {
                                    "FR": "Header 2",
                                    "EN": "Header 2"
                                },
                                icon: "",
                                children: [
                                    {
                                        id: "header 2-1",
                                        name: {
                                            "FR": "Header 2-1",
                                            "EN": "Header 2-1"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "header 2-2",
                                        name: {
                                            "FR": "Header 2-2",
                                            "EN": "Header 2-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "header 2-3",
                                        name: {
                                            "FR": "Header 2-3",
                                            "EN": "Header 2-3"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    }
                                ]
                            }
                        ]
                    }

                    ,
                    body: {
                        id: "body",
                        name: {
                            "FR": "Body",
                            "EN": "Body"
                        },
                        icon: "",
                        children: [

                            {
                                id: "body-1",
                                name: {
                                    "FR": "Body 1",
                                    "EN": "Body 2"
                                },
                                icon: "",
                                children: [
                                    {
                                        id: "body-1-1",
                                        name: {
                                            "FR": "Body 1-1",
                                            "EN": "Body 1-1"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-1-2",
                                        name: {
                                            "FR": "Body 1-2",
                                            "EN": "Body 1-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-1-2",
                                        name: {
                                            "FR": "Body 1-2",
                                            "EN": "Body 1-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    }
                                ]
                            },
                            {
                                id: "body-2",
                                name: {
                                    "FR": "Body 2",
                                    "EN": "Body 2"
                                },
                                icon: "",
                                children: [
                                    {
                                        id: "body-2-1",
                                        name: {
                                            "FR": "Body 2-1",
                                            "EN": "Body 2-1"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-2-2",
                                        name: {
                                            "FR": "Body 2-2",
                                            "EN": "Body 2-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-2-3",
                                        name: {
                                            "FR": "Body 2-3",
                                            "EN": "Body 2-3"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-2-4",
                                        name: {
                                            "FR": "Body 2-4",
                                            "EN": "Body 2-4"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-2-5",
                                        name: {
                                            "FR": "Body 2-5",
                                            "EN": "Body 2-5"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    }
                                ]
                            }
                            ,
                            {
                                id: "body-3",
                                name: {
                                    "FR": "Body 3",
                                    "EN": "Body 3"
                                },
                                icon: "",
                                children: [
                                    {
                                        id: "body-3-1",
                                        name: {
                                            "FR": "Body 3-1",
                                            "EN": "Body 3-1"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-3-2",
                                        name: {
                                            "FR": "Body 3-2",
                                            "EN": "Body 3-2"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-3-3",
                                        name: {
                                            "FR": "Body 3-3",
                                            "EN": "Body 3-3"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-3-4",
                                        name: {
                                            "FR": "Body 3-4",
                                            "EN": "Body 3-4"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    },
                                    {
                                        id: "body-3-5",
                                        name: {
                                            "FR": "Body 3-5",
                                            "EN": "Body 3-5"
                                        },
                                        icon: "fa-user",
                                        action: ""

                                    }
                                ]
                            }
                        ]
                    }



                }
            }
        }
    }

</script>
<style>
    fa-deer:before {
        content: "\f78e";
    }
</style>
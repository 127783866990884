<template>
  <!-- Large Modal -->
  <div
    id="large-modal-pv"
    tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
  >
    <div class="relative w-full h-full max-w-4xl md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-xl text-lg font-semibold font-medium text-gray-900 dark:text-white">
            Controles Effectués - lot {{ batch_number }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="onClickClose"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div
          v-if="controls && controls[phase]"
          class="p-6 space-y-6 overflow-y-auto w-full"
          style="max-height:500px"
        >
          <div
            v-for="control in controls[phase].controls"
            :key="control.id"
            class="w-full"
          >
            <h6 class="text-lg font-semibold mb-2 text-left w-full">
              {{ control.name && control.name[lg] }} 
            </h6>

            <div
              class="justify-start text-left block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
            >
              {{ control.subName && control.subName[lg] }}
            </div>
            <div
              id="detailed-pricing"
              class="w-full overflow-x-auto  "
            >
              <div class="overflow-hidden min-w-max  ">
                <div
                  class="grid grid-cols-6 p-2 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200 gap-x-16 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                >
                  <div class="col-span-3 flex items-center font-seminbold">
                    Nom
                  </div>
                  <div class="col-span-1 text-left">
                    Conforme
                  </div>
                  <div class="col-span-2 text-left">
                    Commentaire
                  </div>
                </div>
                <div
                  v-for="step in control.steps"
                  :key="step.id"
                  class="grid grid-cols-6 px-2 py-3 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700"
                >
                  <div class="col-span-3 text-gray-500 dark:text-gray-400">
                    {{ step.name[lg] }}
                  </div>
                  <div class="col-span-1 text-right   flex justify-center">
                    <div>
                      <svg
                        v-if="step.isConform == true"
                        class="w-5 h-5 text-green-500"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        v-if="step.isConform == false"
                        class="w-5 h-5 text-red-500"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>

                  <div class="col-span-2">
                    {{ step.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            type="button"
            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            @click="onClickClose"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'TimelineComponent',
        props: {
            id: String,
            phase: String,
        },
        data() {
            return {
                lg: "FR",
                batch_number: "24-258-PO",
                controls:
                {
                    "inbound2": {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Réception du lait"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 30/02/2023 par HS "
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Type de lait"
                                        },
                                        "isConform": true,
                                        "value": "Vache Bio"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Fournisseur"
                                        },
                                        "isConform": true,
                                        "value": "Ferme de la Temblaye"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Information sur volume attendu"
                                        },
                                        "isConform": true,
                                        "value": "1200 litres"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Information sur volume reçu"
                                        },
                                        "isConform": true,
                                        "value": "1180 litres"
                                    },
                                    {
                                        "id": "control-0-step-3",
                                        "name": {
                                            "EN": "step 3",
                                            "FR": "Test Témpérature réalisé"
                                        },
                                        "isConform": true,
                                        "value": "15° mesuré"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Test pH réalisé"
                                        },
                                        "isConform": true,
                                        "value": "6,3"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "1er Test Antibiotique Tétracyclines réalisé"
                                        },
                                        "isConform": true,
                                        "value": "Négatif"
                                    }, {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "1er Test Antibiotique Lactames réalisé"
                                        },
                                        "isConform": true,
                                        "value": "Négatif"
                                    }, {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "2er Test Antibiotique Tétracyclines réalisé si premier positif"
                                        },
                                        "isConform": true,
                                        "value": "-"
                                    }, {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "2er Test Antibiotique Tétracyclines réalisé si premier positif"
                                        },
                                        "isConform": true,
                                        "value": "-"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Test Matière Grasse"
                                        },
                                        "isConform": true,
                                        "value": "50 g/Kg"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Indication sur destination"
                                        },
                                        "isConform": true,
                                        "value": "1180 litres vers le tank #4"
                                    }
                                ]
                            },



                        ]
                    },


                    "inbound3": {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Réception de la Viande"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 30/02/2023 par Pascal E"
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Type de Viande"
                                        },
                                        "isConform": true,
                                        "value": "Morceau de Viande de Porc"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Fournisseur"
                                        },
                                        "isConform": true,
                                        "value": "Société A"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Numéro Bon de Livraison"
                                        },
                                        "isConform": true,
                                        "value": "BL-4568-05"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Quantité Reçue"
                                        },
                                        "isConform": true,
                                        "value": "150 kilogrammes"
                                    },
                                    {
                                        "id": "control-0-step-4b",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Numéro de lot"
                                        },
                                        "isConform": true,
                                        "value": "BL-4568-05"
                                    },
                                    {
                                        "id": "control-0-step-4c",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "DLUO"
                                        },
                                        "isConform": true,
                                        "value": "11/03/2024"
                                    },
                                    {
                                        "id": "control-0-step-4c",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Prise de Photographie"
                                        },
                                        "isConform": false,
                                        "value": "Aucune Photographie disponible"
                                    }
                                ]
                            },

                            {
                                id: "control-1",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Contrôles de Conformités"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": ""
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Inspection Globale"
                                        },
                                        "isConform": true,
                                        "value": "RAS"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Inspection Humidité"
                                        },
                                        "isConform": true,
                                        "value": "RAS"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Inspection Fientes d'oiseaux/Crottes"
                                        },
                                        "isConform": true,
                                        "value": "RAS"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Température dans le Camion"
                                        },
                                        "isConform": true,
                                        "value": "3.8°C (doit être entre 2°C et 6°C)"
                                    },
                                    {
                                        "id": "control-0-step-4b",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Température de la viande"
                                        },
                                        "isConform": true,
                                        "value": "4.2°C (doit être entre 2°C et 6°C)"
                                    }
                                ]
                            },



                        ]
                    },




                    "inbound": {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Réception Pommes de Terre"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 30/02/2023 par Pascal E"
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Type"
                                        },
                                        "isConform": true,
                                        "value": "Pomme de Terre Bintje - Calibre X"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Fournisseur"
                                        },
                                        "isConform": true,
                                        "value": "VAUQUELIN"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Numéro Bon de Livraison"
                                        },
                                        "isConform": true,
                                        "value": "BL-4568-05"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Quantité Reçue"
                                        },
                                        "isConform": true,
                                        "value": "5000 kilogrammes"
                                    },
                                    {
                                        "id": "control-0-step-4b",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Numéro de lot"
                                        },
                                        "isConform": true,
                                        "value": "24-258-PO"
                                    },
                                    {
                                        "id": "control-0-step-4c",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "DLUO"
                                        },
                                        "isConform": true,
                                        "value": "01/10/2024"
                                    },
                                    {
                                        "id": "control-0-step-4c",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Prise de Photographie"
                                        },
                                        "isConform": false,
                                        "value": "Aucune Photographie disponible"
                                    }
                                ]
                            },

                            {
                                id: "control-1",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Contrôles de Conformités"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": ""
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Inspection Globale"
                                        },
                                        "isConform": true,
                                        "value": "RAS"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Inspection Humidité"
                                        },
                                        "isConform": true,
                                        "value": "RAS"
                                    },
                                    {
                                        "id": "control-0-step-3",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Inspection Présence de Nuissibles"
                                        },
                                        "isConform": true,
                                        "value": "RAS"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Contrôle du Calibre"
                                        },
                                        "isConform": true,
                                        "value": "Non Réalisé"
                                    }
                                ]
                            },



                        ]
                    },





                    "Pasteurisation": {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Réception du lait"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 30/02/2023 par HS "
                                },
                                steps: []
                            }
                        ]
                    },


                    "refining": {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "control #0"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "control #0.0"
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "control-0-step-1"
                                        },
                                        "isConform": true,
                                        "value": "17,9°"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "control-0-step-2"
                                        },
                                        "isConform": true,
                                        "value": "1182"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "control-0-step-4"
                                        },
                                        "isConform": true,
                                        "value": "4.81"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "control-0-step-4"
                                        },
                                        "isConform": false,
                                        "value": "21259"
                                    }
                                ]
                            },
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Disponibilité des documents"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 03/02/2023 par MD "
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Document sur le lot du sel"
                                        },
                                        "isConform": false,
                                        "value": "-"
                                    },


                                ]
                            },

                        ]
                    },

                    "salting": {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Salage du Fromage"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 03/02/2023 par MD "
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Relevé de Température Bac #1"
                                        },
                                        "isConform": true,
                                        "value": "17,9°"
                                    },
                                    {
                                        "id": "control-0-step-2",
                                        "name": {
                                            "EN": "step 2",
                                            "FR": "Relevé de Densité Bac #1"
                                        },
                                        "isConform": true,
                                        "value": "1182"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Relevé de pH Bac #1"
                                        },
                                        "isConform": true,
                                        "value": "4.81"
                                    },
                                    {
                                        "id": "control-0-step-4",
                                        "name": {
                                            "EN": "step 4",
                                            "FR": "Numéro Lot du Sel"
                                        },
                                        "isConform": false,
                                        "value": "21259"
                                    }
                                ]
                            },
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Disponibilité des documents"
                                },
                                subName: {
                                    "EN": "control #0",
                                    "FR": "réalisé le 03/02/2023 par MD "
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Document sur le lot du sel"
                                        },
                                        "isConform": false,
                                        "value": "-"
                                    },


                                ]
                            },

                        ]
                    },
                    "shipment":
                    {
                        controls: [
                            {
                                id: "control-0",
                                name: {
                                    "EN": "control #0",
                                    "FR": "Document d'expédition"
                                },
                                steps: [
                                    {
                                        "id": "control-0-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Bon de Livraison archivé"
                                        },
                                        "isConform": false,
                                        "value": "Pas disponible."
                                    }
                                ]
                            },

                            {
                                id: "control-1",
                                name: {
                                    "EN": "control #1",
                                    "FR": "Vérification Température avant expédition"
                                },
                                steps: [
                                    {
                                        "id": "control-1-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "T° au sein de la Palette"
                                        },
                                        "isConform": true,
                                        "value": "4° mesuré"
                                    },
                                    {
                                        "id": "control-1-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "T°C au sein du Camion"
                                        },
                                        isConform: true,
                                        "value": "5° mesuré"
                                    },
                                    {
                                        "id": "control-1-step-1",
                                        "name": {
                                            "EN": "step 1",
                                            "FR": "Propreté du Camion"
                                        },
                                        isConform: true,
                                        "value": "Camion propre"
                                    }
                                ]
                            }
                        ]
                    }


                }
            }

        },
        methods: {
            onClickClose: function () {
                this.$emit('hide')
            }

        }
    }

</script>
<template>
  <div class="mt-2 mb-2 w-full relative">
    <label class="font-bold text-left ml-4 px-2.5 text-left block mb-2 text-lg   text-gray-900 dark:text-white">
      {{ labels['LABEL_INGREDIENT_VALUE'][lg] }}
    </label>

    <div class="flex flex-row items-center ">
      <label class=" w-full">
        {{ labels['LABEL_INGREDIENT_VALUE'][lg] }}
      </label>
      <label class="w-96 text-left ">
        {{ labels['LABEL_INGREDIENT_VALUE'][lg] }}
      </label>
    </div>

    <div
      v-for="(line,index) in lines"
      :key="line.id"
      class="mb-3 flex flex-row items-center justify-center"
    >
      <div class="flex flex-row items-center ">
        <label
          class="w-8 flex  items-center justify-center text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          # {{ index }}
        </label>

        <div class="w-96 flex items-center">
          <label
            v-if="index==0"
            class="  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_INGREDIENT_NAME_FR'][lg] }}
          </label>
          <input
            :id="`${id}`"
            v-model="lines[index].fieldFR"
            type="text"
            class="hidden w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :placeholder="placeholder"
            :required="required"
          >

          <select
            :id="`${id}`"
            v-model="lines[index].fieldFR"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected>
              Choose a country
            </option>
            <option value="US">
              United States
            </option>
            <option value="CA">
              Canada
            </option>
            <option value="FR">
              France
            </option>
            <option value="DE">
              Germany
            </option>
          </select>
        </div>

        <div class="w-96 hidden">
          <label
            v-if="index==0"
            class="  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_INGREDIENT_NAME_FR'][lg] }}
          </label>
          <input
            :id="`${id}`"
            v-model="lines[index].fieldFR"
            type="text"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :placeholder="placeholder"
            :required="required"
          >
        </div>

        <div class="w-96 hidden">
          <label
            v-if="index==0"
            class="  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_INGREDIENT_NAME_EN'][lg] }}
          </label>
          <input
            :id="`${id}`"
            v-model="lines[index].fieldEN"
            type="text"
            class="ml-2 w-96  w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :placeholder="placeholder"
            :required="required"
          >
        </div>
        <form class="w-48 ml-4 flex flex-col   items-center text-left justify-start">
          <label
            v-if="index==0"
            :for="`${id}`"
            class="hidden text-left ml-4 px-2.5 text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_INGREDIENT_VALUE'][lg] }}
          </label>
          <div class="flex  ">
            <label
              for="location-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >-</label>
            <div class="relative w-full">
              <input
                id="location-search"
                v-model="lines[index].value"
                type="text"
                class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50  border border-l-gray-50 border-l-0  border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                placeholder=""
                required
              >
            </div>

            <button
              :id="`dropdown-button-2-${index}`"
              :data-dropdown-toggle="`dropdown-unit-${index}`"
              class="w-12 flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300   hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              type="button"
            >
              {{ lines[index].unit }}
              <svg
                aria-hidden="true"
                class="  w-4 h-4 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>


            <div
              :id="`dropdown-unit-${index}`"
              class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                class="py-2 text-sm text-gray-700 dark:text-gray-200"
                :aria-labelledby="`dropdown-button-2-${index}`"
              >
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index,'g')"
                  >
                    <div class="inline-flex items-center">
                      g
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index, 'mg')"
                  >
                    <div class="inline-flex items-center">
                      mg
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index, 'cl')"
                  >
                    <div class="inline-flex items-center">
                      cl
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index, '%')"
                  >
                    <div class="inline-flex items-center">
                      %
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </form>
        <div class="w-auto flex flex-col items-center justify-center">
          <label
            v-if="index==0"
            class="opacity-0  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            -
          </label>
          <div class="w-auto flex flex-row items-center justify-center">
            <div
              href="#"
              class="w-4 cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg px-3   mr-2    focus:outline-none  "
              @click="onClickMinus(line)"
            >
              <i class="fa-solid fa-circle-minus" />
            </div>

            <div
              href="#"
              class="w-4 cursor-pointer text-gray-800 hover:text-gray-900 font-bold text-lg px-3 mr-2 focus:outline-none  "
              @click="onClickPlus"
            >
              <i class="fa-solid fa-circle-plus" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import labels from "@/assets/lg/products.json"
    import { Dropdown } from 'flowbite';

    export default {
        name: 'ContentList',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: String,
            config:Object
        },
        data() {
            return {
                output: [],
                labels: labels,
                lg: 'FR',
                el_dropdown: null,
                dropdowns: [],
                lines: [

                    {
                        id: 1,
                        fieldFR: "",
                        fieldEN: "",
                        value: ""
                    },
                    {
                        id: 2,
                        fieldFR: "",
                        fieldEN: "",
                        value: ""
                    }
                ]
            }
        },
        watch: {
            value: function () {
                //   this.output = val;
            }
        },
        mounted() {
            const options = {
                placement: 'bottom',
                triggerType: 'click',
                offsetSkidding: 0,
                offsetDistance: 10,
                delay: 300,
                onHide: () => {
                     
                },
                onShow: () => {
                   
                },
                onToggle: () => {
                 



                }

            };
            {
                const $targetEl = document.getElementById('dropdown-unit-0');
                const $triggerEl = document.getElementById('dropdown-button-2-0');
                this.dropdowns[0] = new Dropdown($targetEl, $triggerEl, options);

            }
            {
                const $targetEl = document.getElementById('dropdown-unit-1');
                const $triggerEl = document.getElementById('dropdown-button-2-1');
                this.dropdowns[1] = new Dropdown($targetEl, $triggerEl, options);

            }

        },
        methods: {
         
            selectValue: function (index, unit) {
                //this.output = a
          
                if (this.lines[index] == undefined) this.lines[index] = { unit: '' }
                this.lines[index].unit = unit;

                this.dropdowns[index].toggle()
            },
            onClickPlus: function () {

/*
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    onHide: () => {
                    
                    },
                    onShow: () => {
                      
                    },
                    onToggle: () => {
                      

                    }

                };
                */
                this.lines.push(
                    {
                        id: this.lines.length,
                        fieldFR: "",
                        fieldEN: "",
                        value: ""
                    }
                )
                /*
                let index = this.dropdowns.length
             
                {
                    const $targetEl = document.getElementById('dropdown-unit-' + index);
                    const $triggerEl = document.getElementById('dropdown-button-2-' + index);
                    this.dropdowns[index] = new Dropdown($targetEl, $triggerEl, options);

                }*/

              
            },
            onClickMinus: function (item) {
                function removeItemOnce(arr, index) {

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    return arr;
                }
                for (let i = 0; i < this.lines.length; i++) {

                    if (this.lines[i].id == item.id) {
                      
                        //   this.lines = this.output;
                        this.lines = removeItemOnce(this.lines, i);

                        return
                    }
                }
            }
        }
    }
</script>
<template>
  <div class="mt-2 mb-1 w-full relative ">
    <label
      :for="`${id}`"
      class="font-bold text-left block mb-1 text-sm   text-gray-900 dark:text-white"
    >
      {{ label }}  
    </label>
    <div class="flex">
      <div class="relative w-full flex ">
        <input
          :id="`${id}`"
          v-model="displayOutput"
          type="text"
          disabled
          class="    disabled block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-100   border-r-gray-50 border-r-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-r-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
          :placeholder="placeholder"
          :required="required"
          @keyup="onChange"
        >
        <button
          type="button"
          class="ml-6  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          @click="onOpenList"
        >
          <i class="fa-solid fa-plus  mr-2" />

          {{ labels['BUTTON_SELECT'][lg] }}  
        </button>
      </div>
    </div>


    <SelectIngredientModal
      @close="onSelectIngredientClose"
      @select="onSelectIngredientSelect"
    />
  </div>
</template>
<script>
    import common_labels from "@/assets/lg/common.json"
    import SelectIngredientModal from '@/components/modals/SelectIngredientModal4.vue'
    import service from "@/services/FullService.vue"
    import { Modal } from 'flowbite';
    import { mapGetters } from "vuex";


    export default {
        name: 'ContentButtonSelect',
        components: { SelectIngredientModal, },
        props: {
            id: String,
            label: String,
            index: String,
            placeholder: String,
            required: Boolean,
            value: [Object, String],
            config: {},

            isButton: Boolean,

        },
        emits: ["updateField", "valueChanged", "manageField"],
        data() {
            return {
                output: "",
                displayOutput: "",
                lg: "FR",
                labels: common_labels,
                addIngredientModal: null,
                unit: "",
                empty: {},
                sign: "",
                tt: "champ externe",
                items: [],
                scanModal: null,
                common_labels: common_labels,



                options: {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    //  onHide: () => {

                    //  },
                    //  onShow: () => {

                    //  },
                    //  onToggle: () => {

                    //  }
                },
                dropdown: null
            }
        },
        async mounted() {


            this.output = this.value
            //if (this.value != undefined)
            {

                if (this.config.format == "distance") {
                    this.unit = this.config.unit
                    this.items = this.distance;
                }
                if (this.config.format == "volume") {
                    this.unit = this.config.unit
                    this.items = this.volume;
                }
                if (this.config.format == "mass") {
                    this.unit = this.config.unit
                    this.items = this.mass;
                }
                if (this.config.format == "percent") {
                    this.unit = "%"
                    this.items = this.percent;
                }

                if (this.config.format == "mass-volume") {

                    this.unit = this.config.unit
                    this.items = [...this.mass, ...this.volume];
                }
                if (this.config.format == "energy") {
                    this.unit = this.config.unit
                    this.items = this.energy;
                }

                if (typeof this.value == 'object') {

                    if (typeof this.value?.value == 'string') this.output = this.value?.value?.replace(",", ".");
                    this.unit = this.value?.unit
                    this.sign = this.value?.sign
                }

                if (typeof this.value == 'string') {
                    this.output = this.value.replace(",", ".");
                }


            }
            if (this.output == undefined) {
                console.log("Watch value in ContentButtonSelect", this.output)
                if (this.output != null) {
                    let data = await service.getDataId("product-types", this.output, this.user?.token, {})
                    if (data) this.displayOutput = data.name && data.name[this.lg]
                }

            }
            else {
                console.log("contentButtonSelect", "TODO ERROR : Check why empty")
            }




            const modalEl = document.getElementById('select-ingredient-modal-4');
            // console.log(modalEl)
            //if (modalEl != undefined)
            this.addIngredientModal = new Modal(modalEl, {  placement: 'center'  });

            // this.items = this.mass 

        },



        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },

        },


        methods: {
            onSelectIngredientSelect: function (item) {

                this.displayOutput = item.name[this.lg];

                this.output = item._id;


                this.addIngredientModal.hide();
            },
            onSelectIngredientClose: function () {
                this.addIngredientModal.hide();
            },
            onOpenList: function () {
                this.addIngredientModal.show();
                //  this.windowRef = window.open("https://editor-label.vercel.app/edit/" + this.selectedLabel.id, this.selectedLabel.id, "");
                //  if (!this.windowRef) return;
            },
            onChange() {

                let value = this.output;

                if (this.config.format != undefined)
                    value = { value: this.output.toString(), unit: this.unit, sign: this.sign }
                this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })
            },

            selectOption: function (choice) {
                this.unit = choice;
                let value = { value: this.output.toString(), unit: this.unit, sign: this.sign }
                this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })
                this.dropdown.hide()
                return false;
            },
            selectOptionSign: function (choice) {
                this.sign = choice;

                let value = { value: this.output.toString(), unit: this.unit, sign: this.sign }
                this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })


                this.dropdownSign.hide()
                return false;
            },
            getData() {

                return this.output


            }
        },
        watch: {
            value: async function (val) {


                if (val != undefined) {
                    this.output = this.value;


                    console.log("Watch value in ContentButtonSelect", val)
                    if (val == undefined) {
                        let data = await service.getDataId("product-types", val, this.user?.token, {})
                        if (data) this.displayOutput = data.name[this.lg]
                    }
                    else
                    this.displayOutput =  ""

                }

            }
        }
    }
</script>
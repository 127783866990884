<template>
  <div
    id="select-packaging-modal-3"
    tabindex="-1"
    aria-hidden="true"
    class="  fixed top-0 left-0 right-0 z-50 hidden w-full px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
  >
    <div class=" relative w-full h-full max-w-4xl md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click="onClose"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <!-- Modal header -->
        <div class="px-6 py-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
            {{ labels['TITLE_PRODUCE_STEP'][lg] }}+++1234
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-6 w-full">
          <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE_COMMENT'][lg] }}
          </p>

          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              id="default-search"
              v-model="searchText"
              type="search"
              class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Mockups, Logos..."
              required
              @input="onSearchInput"
              @change="onSearchChange"
            >
          </div>

          <div class="flex flex-row">
            <ul class="my-4 space-y-3 overflow-y-auto h-96 w-full">
              <li
                v-for="item in filteredTypes"
                :key="item._id"
                class="cursor-pointer"
              >
                <div
                  class="  flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                  @click="onProductSelect(item)"
                >
                  <i class="fa-solid fa-xl  fa-circle " />
                  <span
                    class="flex-1 text-left ml-3 whitespace-nowrap text-gray-900  text-sm"
                  >{{ item.name[lg]
                  }}</span>
                  <span
                    class="hidden inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
                  >Popular</span>
                </div>
              </li>
            </ul>

            <div class="w-6 h-96 bg-red-100" />

            <ul class="border border-gray-200  my-4 space-y-3 overflow-y-auto h-96 w-full">
              <li
                v-for="item in filteredTypes"
                :key="item._id"
                class="cursor-pointer"
              >
                <div
                  class="  flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                  @click="onProductSelect(item)"
                >
                  <i class="fa-solid fa-xl  fa-circle " />
                  <span
                    class="flex-1 text-left ml-3 whitespace-nowrap text-gray-900 text-sm"
                  >{{ item.name[lg]
                  }}</span>
                  <span
                    class="hidden inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
                  >Popular</span>
                </div>
              </li>
            </ul>
            <div class="w-6 h-96 bg-red-100" />

            <ul class="border border-gray-200 my-4 space-y-3 overflow-y-auto h-96 w-full">
              <li
                v-for="item in filteredTypes"
                :key="item._id"
                class="cursor-pointer"
              >
                <div
                  class="  flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                  @click="onProductSelect(item)"
                >
                  <i class="fa-solid fa-xl  fa-circle " />
                  <span
                    class="flex-1 text-left ml-3 whitespace-nowrap text-gray-900  text-sm"
                  >{{ item.name[lg]
                  }}</span>
                  <span
                    class="hidden inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400"
                  >Popular</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import service from "@/services/FullService.vue"
    import labels from "@/assets/lg/product-types.json"



    import { mapGetters } from "vuex";
    export default {
        name: 'SelectPackagingModal3',
        data() {
            return ({
                lg: "FR",
                labels: labels,
                searchText: "",
                records: [],
                filteredTypes: [],
                allTypes: []
            })
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        async mounted() {
            if (this.user == undefined) {
                //this.$router.push("/login");
                window.location = "/login"
                return;
            }

            let limit = 100;
            let offset = 0;
            let filter = {}
       

            let collection = "data-sources/668cecd29535b23c096b6407/records";
           // let collection = "ingredient-types"


            this.data = await service.getData(collection, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
            this.data = []
            if (this.data == null) {
                this.allTypes = []
            }
            else
                this.allTypes = this.data.records;

            this.filteredTypes = this.allTypes

        },
        methods: {

            onSearchInput() {

                let lg = this.lg
                let searchText = this.searchText
                this.filteredTypes = this.allTypes?.filter(function (el) {

                    return el.name[lg].toUpperCase().includes(searchText.toUpperCase())
                })
            },

            onSearchChange() {

            },


            onProductSelect(item) {
                this.$emit("select", item)
            },
            onClose() {
                this.$emit("close")
            }
        },
        components: {

        },
    }
</script>
<template>
  <div
    class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre
        </label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Type de
          Code</label>
        <div class="w-full flex flex-row">
          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="mode-radio-1"
              v-model="mode"
              type="radio"
              value="both"
              name="mode-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputMode"
            >
            <label
              for="mode-radio-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >les
              deux</label>
          </div>
          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="mode-radio-2"
              v-model="mode"
              type="radio"
              value="qrcode"
              name="mode-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputMode"
            >
            <label
              for="mode-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >QR
              Code</label>
          </div>


          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="mode-radio-2"
              v-model="mode"
              type="radio"
              value="barcode"
              name="mode-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputMode"
            >
            <label
              for="mode-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Code
              Barre</label>
          </div>
        </div>
      </div>
        
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${element.id}`"
          v-model="data"
          type="text"
          class="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeData"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Attribute Photo</label>
        <input
          :id="`att-photo-input-${element.id}`"
          v-model="dataphoto"
          type="text"
          class="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeDataPhoto"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="save-photo-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Photo {{ typeof isPhotoSaved }}</label>
        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="save-photo-2-checkbox"
              v-model="isPhotoSaved"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsPhotoSaved"
            >
            <label
              for="save-photo-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Sauver également la photo</label>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre
              s</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              description</label>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Contraintes</label>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-checked-checkbox"
              v-model="isRequired"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRequired"
            >
            <label
              for="inline-checked-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Obligatoire</label>
          </div>
        </div>
      </div>


      <div class="flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center">
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'CodeTableFormComponentDetails',
        props: {
            element: Object
        },
        data() {

            return {
                title: "",
                format: "",
                mode: "barcopde",
                data: "",
                dataphoto: "",
                isPhotoSaved: "",
                isRequired: false,
                isTitleDisplayed: false

            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title;
                this.format = this.element.format;
                this.isPhotoSaved = this.element.isPhotoSaved;
                this.mode = this.element.mode

                if (this.mode == undefined) this.mode = "barcode"


                this.isRequired = this.element?.isRequired;
                this.isTitleDisplayed = this.element?.isTitleDisplayed;
                this.data = this.element?.data;
                this.dataphoto = this.element?.dataphoto;
            }
        },
        mounted() {
            this.title = this.element?.title
            this.format = this.element.format

            this.isPhotoSaved = this.element.isPhotoSaved;

            this.mode = this.element.mode
            if (this.mode == undefined) this.mode = "barcode"
            this.isRequired = this.element?.isRequired;
            this.isTitleDisplayed = this.element?.isTitleDisplayed;
            this.data = this.element?.data;
            this.dataphoto = this.element?.dataphoto;
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.element.id })
            },
            onChangeData: function () {
                this.$emit("update", { att: 'data', value: this.data })
            },
            onChangeDataPhoto: function () {
                this.$emit("update", { att: 'dataphoto', value: this.dataphoto })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("update", { att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },

            onChangeIsRequired: function () {
                this.$emit("update", { att: 'isRequired', value: this.isRequired })
            },


            onChangeIsPhotoSaved: function () {
                this.$emit("update", { att: 'isPhotoSaved', value: this.isPhotoSaved })
            },

         
            onInputMode: function () {

                this.$emit("update", { att: 'mode', value: this.mode })


            },
            onInputTitle: function () {
                this.$emit("update", { att: 'title', value: this.title })
            }
        }
    }
</script>
<template>
  <div class="w-full h-96">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres
    </h3>



    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Category</label>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'SeriesEditDiagramModal',
        components: {

        },
        props: {

           
        },
        data() {
            return ({

              
            })
        },
        watch: {

          
        },

        mounted() {
      
        },
        methods: {
             

        }
    }
</script>
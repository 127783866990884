<template>
  <div class=" mx-auto  h-full">
    <div
      class="absolute top-0 w-full h-full bg-slate-800 bg-no-repeat bg-full"
      :style="`background-image: url('${registerBg2}');background-repeat: no-repeat;background-attachment: fixed;background-size: cover;`"
    />
    <div class="relative shadow-xl flex content-center items-center justify-center h-full">
      <div
        class="  lg:w-4/12 px-4"
        :class="{
          'opacity-50': loginInProgress,
          block: !loginInProgress,
        }"
      >
        <div
          class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
              mb-6
              shadow-lg
              rounded-lg
              bg-slate-200
              border-0
            "
        >
          <div class="flex-auto justify-end px-4 lg:px-4 py-4 pt-0">
            <div class="w-full bg-transparent text-center flex justify-center">
              <a
                href="https://www.b4food.io"
                class="flex  "
              >
                <img
                  :src="logo"
                  class="h-24  "
                  alt="B4food.io"
                >

              </a>
            </div>
            <div class="text-slate-600 text-center mb-3 font-bold text-2xl">
              <br>


              {{ $t("CHANGE_PASSWORD_TITLE") }}
            </div>

            <div class="text-slate-600 text-center mb-3 font-se text-lg">
              {{ $t("CHANGE_PASSWORD_DESCRIPTION") }}
            </div>
            <div class="text-slate-600 text-center mb-3 font-semibold text-lg flex flex-row items-center justify-center">
              <div class="text-slate-400">
                {{ $t("CHANGE_PASSWORD_LOGIN") }}
              </div>
              <div class="text-slate-600">
                {{ email }}
              </div>
            </div>
            <div class="h-6 py-2">
              <div
                v-if="showError=='ERROR_NOT_SAME'"
                class=" bg-orange-100 border-l-2 border-l-orange-500  text-red-500 font-normal"
              >
                {{ $t("CHANGE_PASSWORD_ERROR_NOT_SAME") }}
              </div>

              <div
                v-if="showError=='ERROR_WRONG_FORMAT'"
                class=" bg-orange-100 border-l-2 border-l-orange-500 text-red-500 font-normal"
              >
                {{ $t("CHANGE_PASSWORD_ERROR_WRONG_FORMAT") }}
              </div>
            </div>
            <br>
            <div
              v-if="showError == 'ERROR_TOKEN_EXPIRED'"
              class="mb-6"
            >
              <div class="bg-red-100  border-l-4 border-l-red-600 w-full py-6 px-3">
                {{ $t("CHANGE_PASSWORD_ERROR_TOKEN_EXPIRED") }}

                <div
                  class="mt-6 text-sm underline cursor-pointer flex items-center justify-center"
                  @click="onRequestPasswordChange"
                >
                  <div>{{ $t("CHANGE_PASSWORD_ERROR_REQUEST") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="showError == 'CHANGE_OK'"
              class="mb-6"
            >
              <div class="bg-green-100  border-l-4 border-l-green-600 w-full py-6 px-3">
                {{ $t("CHANGE_PASSWORD_CHANGE_DONE") }}

                <div
                  class="mt-6 text-sm underline cursor-pointer flex items-center justify-center"
                  @click="onRequestLogin"
                >
                  <div>{{ $t("CHANGE_PASSWORD_GO_LOGIN") }}</div>
                </div>
              </div>
            </div>
            <form
              v-if="showError != 'CHANGE_OK' && showError != 'ERROR_TOKEN_EXPIRED'"
              class=" "
              @submit.prevent="submit"
            >
              <div class="relative w-full mb-3">
                <label
                  class="
                      block
                      text-left
                      uppercase
                      text-slate-600 text-xs
                      font-bold
                      mb-2
                    "
                  htmlFor="grid-password"
                >

                  {{ $t("CHANGE_PASSWORD_LABEL_NEW_PASSWORD") }}


                </label>
                <div class="relative w-full  ">
                  <input
                    ref="password"
                    v-model="form.password"
                    :type="passwordType"
                    class="  
                      border-0
                      px-3
                      py-3
                      text-left
                      placeholder-slat-300
                      text-slate-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    :placeholder="`${$t('CHANGE_PASSWORD_PLACEHOLDER_PASSWORD')}`"
                  >




                  <button
                    type="button"
                    class="focus:outline-0 absolute inset-y-0 end-0 flex items-center pe-3"
                  >
                    <div
                      v-if="form.password.length > 0"
                      @click="onClearPassword($event)"
                    >
                      <svg
                        class="w-4 h-4 mr-2 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18 17.94 6M18 18 6.06 6"
                        />
                      </svg>
                    </div>

                    <div
                      class="focus:outline-0"
                      @click="onViewPassword($event)"
                    >
                      <svg
                        v-if="passwordType=='password'"
                        class="w-6 h-6 text-gray-500  dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-width="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          stroke-width="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>


                      <svg
                        v-if="passwordType=='text'"
                        class="w-6 h-6 text-gray-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div class="mt-3 w-full flex h-2  border border-grey-30 rounded">
                  <div
                    :style="{ width: `${width}%` }"
                    :class="description.color"
                    class="  progress-bar flex h-2"
                  />
                </div>

                <div class="relative select-none">
                  <p class="absolute mt-1 text-sm">
                    {{ description?.label }}
                  </p>
                </div>
              </div>

              <div class="mt-12 relative w-full mb-3">
                <label
                  class="
                      block
                      uppercase
                      text-left
                      text-slate-600 text-xs
                      font-bold
                      mb-2 flex items-center max-w-lg mx-aut
                    "
                  htmlFor=""
                >


                  {{ $t("CHANGE_PASSWORD_LABEL_CONFIRM_PASSWORD") }}  

                </label>



                <div class="relative w-full  ">
                  <input
                    ref="confirmpassword"
                    v-model="form.confirm_password"
                    :type="passwordType"
                    :disabled="loginInProgress"
                    class="   border-0
                    px-3
                    py-3
                    placeholder-slat-300
                    text-slate-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150"
                    :placeholder="`${$t('CHANGE_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD')}`"
                    required
                  >

                  <button
                    type="button"
                    class="focus:outline-0 absolute inset-y-0 end-0 flex items-center pe-3 ring-0"
                  >
                    <div
                      v-if="form.confirm_password.length > 0"
                      @click="onClearConfirmPassword($event)"
                    >
                      <svg
                        class="w-4 h-4 mr-2 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18 17.94 6M18 18 6.06 6"
                        />
                      </svg>
                    </div>



                    <div @click="onViewPassword($event)">
                      <svg
                        v-if="passwordType=='password'"
                        class="w-6 h-6 text-gray-500  dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-width="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          stroke-width="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>


                      <svg
                        v-if="passwordType=='text'"
                        class="w-6 h-6 text-gray-500 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              <div class="text-center mt-6">
                <button
                  class="
                     
                      text-white
                      
                      text-sm
                      font-bold
                      uppercase
                      px-6
                      py-3
                      rounded
                      shadow
                     
                      outline-none
                    
                      mr-1
                      mb-1
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  :class="{' bg-gray-800 active:bg-gray-600   focus:outline-none  hover:shadow-lg' : score > 1, ' bg-gray-400 pointer-events-none' : score < 2}"
                  :disabled="loginInProgress && (score > 1)"
                  type="submit"
                >
                  {{ $t("CHANGE_PASSWORD_BUTTON") }}
                </button>
              </div>
            </form>
            <div class=" text-left flex flex-wrap mt-6 relative ">
              <div class="w-1/2">
                <div
                  class="cursor-pointer flex items-center"
                  @click="onRequestLogin"
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white mr-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M5 12l4-4m-4 4 4 4"
                    />
                  </svg>

                  <small>
                    {{ $t("LOGIN_LABEL_BACK") }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="absolute top-0 right-0 flex flex-row bg-black px-2 py-2">
          <div
            class="px-1 text-white   cursor-pointer"
            :class="{ 'font-bold' : ( $i18next?.language === 'en' ), 'font-normal' : ( $i18next?.language !== 'en' ),}"
            @click="onChangeLanguage('en')"
          >
            en
          </div>
          <div class="px-1 text-white font-normal">
            |
          </div>
          <div
            class="px-1 text-white  cursor-pointer"
            :class="{ 'font-bold' : ( $i18next?.language === 'fr' ), 'font-normal' : ( $i18next?.language !== 'fr' ),}"
            @click="onChangeLanguage('fr')"
          >
            fr
          </div>
          <div class="px-1 text-white font-normal">
            |
          </div>
          <div
            class="px-1 text-white  cursor-pointer"
            :class="{ 'font-bold' : ( $i18next?.language === 'sp' ), 'font-normal' : ( $i18next?.language !== 'sp' ),}"
            @click="onChangeLanguage('sp')"
          >
            sp
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


  import registerBg2 from "@/assets/img/bg_login_1.jpg";

  import zxcvbn from "zxcvbn"
  import service from "@/services/FullService.vue"
  import labels from "@/assets/lg/login.json";

  import logo from "@/assets/logo.png"

  import github from "@/assets/img/github.svg";
  import google from "@/assets/img/google.svg";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "ChangePasswordView",
    data() {
      return {
        registerBg2,
        logo: logo,
        analysis: null,
        labels: labels,
        lg: "FR",
        width: 0,
        color: null,
        score: 0,
        descriptions: [
          {
            color: "bg-red-400",
            label: this.$t("CHANGE_PASSWORD_SCORE_0"),
          },
          { color: "bg-red-400", label: this.$t("CHANGE_PASSWORD_SCORE_1"), labelen: "Still weak, keep on trying!" },
          { color: "bg-orange-400", label: this.$t("CHANGE_PASSWORD_SCORE_2") },
          { color: "bg-green-300", label: this.$t("CHANGE_PASSWORD_SCORE_3") },
          {
            color: "bg-green-500",
            label: this.$t("CHANGE_PASSWORD_SCORE_4"),
          }
        ],
        email : "-",
        passwordType: "password",
        form: {
          confirm_password: "",
          password: "",
        },
        autofocus: false,
        loginInProgress: false,
        showError: null,
        github,
        google,
        options: {}
      }
    },
    computed: {
      ...mapGetters({ Remember: "StateRememberMe" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },

      description: function () {

        if (this.form?.password && (this.form?.password.length > 0) && this.analysis != undefined) {
          return this.descriptions[this.analysis?.score]
        }
        return ({
          color: "bg-transparent",
          label: "",
        })
      },


    },
    watch:
    {

      "form.confirm_password": function () {

        if (this.form.confirm_password != "") {
          if (this.form.confirm_password != this.form.password) {
            this.showError = "ERROR_NOT_SAME"

          }
          else this.showError = null
        } else this.showError = null

      },
      "form.password": function () {
        if (this.form?.password != undefined) {
          this.analysis = zxcvbn(this.form.password)
          this.score = this.analysis.score
          this.width = (this.analysis.score / 4) * 100
        }

        else {
          this.analysis = null
          this.score = 0
          this.width = 0
        }


      }
    },
    async mounted() {
      let token = this.$route.params.id
      let response = await service.getLoginName(token);

      this.email = response.email
      console.log(response)
    },
 
    methods: {
      ...mapActions(["RememberMe", "LogIn"]),

      onChangeLanguage: function (lang) {
        this.$i18next.changeLanguage(lang);
      },

      onClearConfirmPassword: function () {
        this.form.confirm_password = ""
      },

      onClearPassword: function () {
        this.form.password = ""
      },
      onRequestPasswordChange: function () {
        this.$router.push("/reset-password")
      },


      onRequestLogin: function () {
        this.$router.push("/login")
      },

      setFocus: function () {
        // Note, you need to add a ref="search" attribute to your input.
        this.$refs.password.focus();
      },
      setFocusLogin: function () {
        // Note, you need to add a ref="search" attribute to your input.
        this.$refs.login.focus();
      },
      onViewPassword(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.passwordType == "password") {
          this.passwordType = "text"
        }
        else {
          this.passwordType = "password"
        }

      },
      async submit() {

        if (this.form.confirm_password != this.form.password) {
          this.showError = "ERROR_NOT_SAME"
          return
        }

        if (this.analysis.score < 2) {

          this.showError = "ERROR_WRONG_FORMAT"
          return
        }

        let token = this.$route.params.id
        let body = {
          confirm_password: this.form.confirm_password,
          password: this.form.password,
          token: token
        };

        try {
          this.loginInProgress = true;
          let response = await service.ChangePassword(body);
     
          this.loginInProgress = false;
          if (response.code == 10) {
       
            this.showError = "ERROR_TOKEN_EXPIRED"
            return
          }
          if (response.code == 0) {
         
            this.showError = "CHANGE_OK"
            return
          }

       
         
        } catch (error) {
          this.loginInProgress = false;

          this.showError = true;
        }
      },
    },
  };
</script>
<style scoped>
  .error {
    color: white;
    background-color: red;
    text-align: center;
  }
</style>
<template>
  <section class="w-full bg-gray-50 dark:bg-gray-900">
    <div
      v-if="isLoading"
      class="w-full   mx-auto max-w-screen-2xl  "
    >
      <h1 class="text-2xl mt-6">
        {{ msg_waiting[lg] }}
      </h1>
    </div>

    <div
      v-if="!isLoading"
      class="w-full   mx-auto max-w-screen-2xl  "
    >
      <div
        v-if="isLoading2"
        class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800  "
      >
        <h1 class="text-2xl mt-6 text-black">
          {{ msg_waiting[lg] }}
        </h1>
      </div>
      <div
        v-if="!isLoading2"
        class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800  "
      >
        <div
          v-if="config != undefined && (config.options == undefined || config.options.topBar != false)"
          class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4"
        >
          <div class="flex items-center w-full space-x-3">
            <h5 class="font-semibold dark:text-white">
              Element(s)
            </h5>
            <div class="font-medium text-gray-500 dark:text-gray-400">
              {{ records && records.total }} resultats
            </div>
            <div data-tooltip-target="results-tooltip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">More info</span>
            </div>
            <div
              id="results-tooltip"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Showing 1-10 of 6,560 results
              <div
                class="tooltip-arrow"
                data-popper-arrow
              />
            </div>
          </div>
          <div class="hidden flex items-center flex-1 space-x-4">
            <h5>
              <span class="text-gray-500">{{ config && config.label_total && config.label_total[lg] }}
              </span>
              <span class="ml-1 dark:text-white font-bold">{{ records && records.total }}</span>
            </h5>
            <h5 class="hidden">
              <span class="text-gray-500">Total sales:</span>
              <span class="dark:text-white">$88.d4k</span>
            </h5>
          </div>

          <div
            class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3"
          >
            <button
              type="button"
              class="hidden flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickUp"
            >
              <i class="fa-solid fa-arrow-up  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }}
            </button>
            <button
              v-if="selected.length > 0"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-red-700 hover:bg-danger-800 focus:ring-4 focus:ring-danger-300 dark:bg-primary-600 dark:hover:bg-danger-700 focus:outline-none dark:focus:ring-danger-800"
              @click="onClickDelete"
            >
              <i class="fa-solid fa-plus  mr-2" />
              {{ common_labels['BUTTON_DELETE'][lg] }}
            </button>
            <button
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickAddFolder"
            >
              <i class="fa-solid fa-plus  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }} Répertoire
            </button>
            <button
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickAdd"
            >
              <i class="fa-solid fa-plus  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }}
            </button>
            <button
              type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickRefresh"
            >
              <i class=" mr-2 fa-solid fa-arrows-rotate" />

              {{ common_labels['BUTTON_REFRESH'][lg] }}
            </button>
            <button
              type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickExport"
            >
              <i class="fa-solid fa-download mr-2 " />
              {{ common_labels['BUTTON_EXPORT'][lg] }}
            </button>
          </div>
        </div>
 
        <div
          v-if="config != undefined && (config.options == undefined || config.options.secondBar != false)"
          class="hidden flex flex-col-reverse items-start justify-between p-4 border-b md:flex-row md:items-center md:space-x-4 dark:border-gray-700"
        >
          <div class="mt-3 md:mt-0">
            <button
              id="actionsDropdownButton"
              data-dropdown-toggle="actionsDropdown"
              class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              type="button"
            >
              <svg
                class="-ml-1 mr-1.5 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                />
              </svg>
              Actions
            </button>
            <div
              id="actionsDropdown"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
            >
              <ul
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="actionsDropdownButton"
              >
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Mass Edit
                  </a>
                </li>
              </ul>
              <div class="py-1">
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Delete all
                </a>
              </div>
            </div>
          </div>
          <div
            v-if="config != undefined"
            class="grid w-full grid-cols-1 lg:grid-cols-4 lg:w-2/3 md:gap-4"
          >
            <div
              v-for="filterBox in config.secondBar"
              :key="filterBox.id"
              class="w-full"
            >
              <label
                for="item_category"
                class="sr-only"
              >{{ filterBox.name[lg] }}</label>
              <select
                :id="`item_category_${filterBox.id}`"
                class="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                @change="changeSelect($event, filterBox.data)"
              >
                <option
                  v-for="filterOption in filterBox.values"
                  :key="filterOption.id"
                  :value="filterOption.value"
                >
                  <b>{{ filterOption.name[lg] ? filterOption.name[lg] :
                    filterOption.name }}</b>
                </option>
              </select>
            </div>
          </div>
        </div>


        <h2
          v-if="folders.length > 0"
          class="text-left mb-2 ml-8 text-2xl font-bold text-gray-900 dark:text-white"
        >
          Dossiers
        </h2>
        <div class="overflow-x-auto  bg-gray-50">
          <aside
            aria-label="Related articles"
            class="py-2 bg-white lg:py-4s dark:bg-gray-900"
          >
            <div class="px-4 mx-auto max-w-screen-2xl">
              <div
                v-if="records != undefined && !isLoading3"
                class="grid gap-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4"
              >
                <article
                  v-for="record in folders.records"
                  :key="record.id"
                  class="w-full flex justify-enter item-center flex-row p-4 mx-auto max-w-sm bg-gray-100 rounded-lg shadow-md border border-gray-200 dark:border-gray-800 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 cursor-pointer"
                >
                  <GridComponentFolder
                    :folder="record"
                    @update-folder-name="onUpdateName"
                    @change-folder="onChangeFolder"
                    @delete-folder="onDeleteFolder"
                  />
                </article>
              </div>
            </div>
          </aside>
        </div>
        <h2
          v-if="records && records.records && records.records.length > 0"
          class="mt-6  text-left mb-2 ml-8 text-2xl font-bold text-gray-900 dark:text-white"
        >
          {{ getTitle() }}
        </h2>
        <div class="overflow-x-auto  bg-gray-50">
          <aside
            aria-label="Related articles"
            class="py-2 bg-white lg:py-4 dark:bg-gray-900"
          >
            <div class="px-4 mx-auto max-w-screen-2xl">
              <div
                v-if="records != undefined && !isLoading3"
                class="grid gap-8 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4"
              >
                <article
                  v-for="record in records.records"
                  :key="record.id"
                  class="flex justify-enter flex-col p-4 mx-auto max-w-sm   rounded-lg shadow-md border border-gray-200 dark:border-gray-800 dark:bg-gray-800 dark:border-gray-700 hover:border-2 cursor-pointer  w-full"
                >
                  <h2
                    class="justify-start flex flex-row text-sm font-medium leading-tight text-gray-900 dark:text-white text-clip overflow-hidden items-center"
                  >
                    <div class="hidden bg-blue-600 flex flex-row w-full px-6 py-2">
                      <p class="flex-grow justify-start left overflow-hidden text-ellipsis">
                        <span>  {{ record.name }} </span>
                      </p>
                    </div>
                  </h2>                        
                  <GridComponentImage
                    :record="record"
                    @update-name="onUpdateName"
                    @delete="onDelete"
                  />
                </article>
              </div>
            </div>
          </aside>
        </div>
        <nav
          v-if="records != undefined && !isLoading3"
          class="  flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
          aria-label="Table navigation"
        >
          <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
            {{ common_labels['LABEL_SHOWING'][lg] }}
            <span class="font-semibold text-gray-900 dark:text-white">
              {{ first_item }}-{{ last_item }}
            </span>
            {{ common_labels['LABEL_OF'][lg] }}
            <span class="font-semibold text-gray-900 dark:text-white">
              {{ records && records.total }}
            </span>
          </span>

          <ul
            v-if="displayPagination"
            class="inline-flex items-stretch -space-x-px"
          >
            <li>
              <div
                class=" flex items-center justify-center h-full py-1.5 px-3 ml-0 bg-white rounded-l-lg border border-gray-300  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{'cursor-pointer text-gray-500 hover:text-gray-700 hover:bg-gray-100 ': previous_valid, 'cursor-event-none text-gray-200 hover:text-gray-200': !previous_valid}"
                @click="onButtonPrevious"
              >
                <span class="sr-only">Previous</span>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </li>
            <li v-if="max_page > 2">
              <a
                href="#"
                class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >1</a>
            </li>
            <li v-if="max_page > 3">
              <a
                href="#"
                class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >2</a>
            </li>
            <li v-if="max_page > 4">
              <a
                href="#"
                aria-current="page"
                class="z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              >3</a>
            </li>
            <li v-if="max_page > 5">
              <a
                href="#"
                class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >...</a>
            </li>
            <li v-if="max_page > 6">
              <a
                href="#"
                class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                {{ max_page }}
              </a>
            </li>
            <li>
              <div
                :class="{'cursor-pointer text-gray-500 hover:text-gray-700 hover:bg-gray-100 ': next_valid, 'cursor-event-none text-gray-200 hover:text-gray-200': !next_valid}"
                class="  flex items-center justify-center h-full py-1.5 px-3 leading-tight   bg-white rounded-r-lg border border-gray-300   dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                @click="onButtonNext"
              >
                <span class="sr-only">Next</span>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>


<script>
    const MAX_ITEMS = 150;
    import labels from "@/assets/lg/common.json"
    import blank from "@/assets/img/blank.png"


    import GridComponentImage from "@/components/GridComponentImage"

    import GridComponentFolder from "@/components/GridComponentFolder"

    export default {
        name: 'GridComponent',
        components: {
            GridComponentImage, GridComponentFolder
        },
        props: {
            config: Object,
            records: Object,
            folders: Object,
            offset: Number,
            limit: Number,
        
            isImage: Boolean,
            isLoading: Boolean,
            isLoading2: Boolean,
            isLoading3: Boolean
        },
        data() {
            return {
                lg: "FR",
                page: 1,
                msg_waiting: {
                    "EN": "Please wait a moment",
                    "FR": "Merci de patienter"
                },
                category: null,
                select_values: {},

                selected: [],
                filters: {},
                blank: blank,
                loading2: false,
                current_page: 1,
                common_labels: labels
            }
        },
        computed: {



            first_item: function () {
                return this.offset + 1;
            },
            last_item: function () {

                if (this.records == undefined) return 0;

                let n = this.offset + this.limit

                if (n > this.records.total) return this.records.total
                return this.offset + this.limit;
            },
            max_page: function () {
                if (this.records == undefined) return MAX_ITEMS;
                if ((this.records.total % MAX_ITEMS) == 0) return (this.records.total / MAX_ITEMS)
                return parseInt(this.records.total / MAX_ITEMS) + 1;
            },
            displayPagination: function () {
                return (this.max_page > 1)

            },
            previous_valid: function () {
                if (this.page == 0) return false;
                return true;
            },
            next_valid: function () {
                if (this.page == this.max_page) return false;
                return true;
            },
            tripledots_valid: function () {
                if (this.max_page < 3) return false;
                return true;
            },
        },
        watch: {

            "$route.query.code": function () {

                this.$emit("refreshPage", { collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })

            },

           // config: function () {

             
            //},
            //records: function () {
            
           // }
        },
        async mounted() {



            // init menu
            //image-64ec4ece8dd37ec92075eebd-dropdown-media-button
            //image-64ec4ece8dd37ec92075eebd-media-dropdown



            this.$emit("refreshPage", { collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })
        },

        methods: {

            onUpdateName: function (item) {
          
                this.$emit("updateName", item)
            },

            onDelete: function (item) {
                this.$emit("deleteItem", item)
            },
            onDeleteFolder: function (item) {
               
                this.$emit("deleteItem", item)
            },

            showMenu: function () {
                // this.dropdown.show()
            },
            getImageUrl: function (record) {
                if (!this.isImage)
                    return "/img/logo_pdf.png"

                return `${record.url}?icon=true`
            },
            getTitle: function () {

                if (this.isImage) return "Mes Images";

                return "Mes Documents";
            },
            onImageShow: function (/*record*/) {
              
            },
            // onImageRename: function (record) {
     
                //this.dropdown.hide()
            //},


            onImageDelete: function (/*record*/) {
             
            },
            onChangeFolder: function (folder) {

                this.$emit("changeFolder", { collection: this.config.id, id: folder.id, name: folder.name, offset: 0, limit: MAX_ITEMS, filter: this.filter })

            },
            badgeColor(record, col) {

                let v = this.displayValue(record, col, false, false)
                v = v.toString();
                if (v == '') v = 'default'


                if (col['colors'] == undefined) return 'bg-gray-100 text-gray-800 border-gray-400'


                if (col['colors'][v.toString()] == 'red') return 'font-bold bg-red-100 text-red-800 border-red-400'
                if (col['colors'][v.toString()] == 'orange') return 'font-bold bg-orange-100 text-orange-800 border-orange-400'
                if (col['colors'][v.toString()] == 'green') return 'font-bold bg-green-100 text-green-800 border-green-400'
                return 'font-bold bg-gray-100 text-gray-800 border-gray-400'

            },

            getImageTitleData(record, col) {

                //let arr = this.manageVariable("actors", record);
         

                let v1 = col.data;

                if (record[v1] == undefined) return [];
                if (Array.isArray(record[v1])) {


                    let components = [];
                    for (let i = 0; i < record[v1].length; i++) {

                        let url = this.manageVariable(col.combined.avatar.image, record[v1][i]);
                        let title = this.manageVariable(col.combined.title, record[v1][i]);
                        let subtitle = this.manageVariable(col.combined.subtitle, record[v1][i])
                        components.push(
                            {
                                title: title,
                                subtitle: subtitle,
                                url: url
                            })
                    }
                    return components
                }
                let default_image = col.combined.avatar.image_default;
                if (default_image == undefined) default_image = this.blank;
                let url = this.manageVariable(col.combined.avatar.image, record, default_image);



                if (url == '-') {
               
                    if (col.combined.avatar.image_default != undefined)
                        url = col.combined.avatar.image_default;

                }
                // url = col.combined.avatar.image_default
                let title = this.manageVariable(col.combined.title, record);
                let subtitle = this.manageVariable(col.combined.subtitle, record)

                return [{
                    title: title,
                    subtitle: subtitle,
                    url: url
                }]

            },
            changeSelect(event, value) {
                //this.filters = {}
                this.filters[value] = event.target.value
                this.$emit("refreshTable", { value: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })

            },
            selectItemType(value) {
                this.category = value;

                this.filters = {}
                this.filters['category'] = value;
                this.$emit("refresh", { value: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, level: 2 })

            },
            onEditButton(col, record) {


                if (col.buttonURI) {
                    let url = col.buttonURI.replace(":id", record._id);
                    this.$router.push(url)
                    return;
                }

                //this.$router.push("/admin/flows/" + record.id)
            },
            onClickCheck(event, item) {
                event.stopPropagation();
                this.selected.push(item)
            },
            onButtonNext() {
                this.current_page = this.current_page + 1;
                if (this.current_page > this.max_page)
                    this.current_page = this.max_page;

                this.$emit("refresh", { collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })
            },
            onButtonPrevious() {
                this.current_page = this.current_page - 1;
                if (this.current_page < 0)
                    this.current_page = 0;

                this.$emit("refresh", { collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })

            },


            onClickBreadCrumb() {
            
            },

            onClickDelete() {
                this.$emit("delete", { collection: this.config.id, selected: [] })
            },
            onClickRefresh() {

                this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters })

            },
            onClickExport() {

                this.$emit("export", { collection: this.config.id })
            },
            onClickAdd() {

                this.$emit("add", { collection: this.config.id })
            },
            onClickAddFolder() {

                this.$emit("addFolder", { collection: this.config.id })
            },
            onClickUp() {
                this.$emit("up", { collection: this.config.id })
            },
            onRowClick(record) {

                this.$emit("rowClick", { collection: this.config.id, record: record._id, body: record })
            },
        }
    }
</script>
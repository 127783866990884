<template>
  <div class="  list-group mt-2  w-full flex items-start          flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5  py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Name</label>
        <input
          id="default-description-input"
          v-model="name"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDisplayName"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Image</label>
        <input
          id="default-description-input"
          v-model="image"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDisplayImage"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-bold"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="template-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Template</label>

        <select
          :id="`att-input-${properties?.id}`"
          v-model="template"
          class="w-full  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @change="onChangeTemplate"
        >
          <option value="edit-tpl-1">
            image-tpl-1
          </option>
          <option value="edit-tpl-2">
            image-tpl-2
          </option>
        </select>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="template-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Width</label>

        <select
          :id="`att-input-${properties?.id}`"
          v-model="width"
          class="w-full  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @change="onChangeWidth"
        >
          <option value="">
            None
          </option>
          <option value="w-full">
            w-full
          </option>
          <option value="w-11/12">
            w-11/12
          </option>
          <option value="w-10/12">
            w-10/12
          </option>
          <option value="w-9/12">
            w-9/12
          </option>
          <option value="w-8/12">
            w-8/12
          </option>
          <option value="w-7/12">
            w-7/12
          </option>
          <option value="w-6/12">
            w-6/12
          </option>
          <option value="w-5/12">
            w-5/12
          </option>
          <option value="w-4/12">
            w-4/12
          </option>
          <option value="w-3/12">
            w-3/12
          </option>
          <option value="w-2/12">
            w-2/12
          </option>
          <option value="w-1/12">
            w-1/12
          </option>
        </select>
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Options</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

    export default {
        name: 'HtmlTabletFormComponentDetails',
        components: {

        },
        props: {
            properties: Object
        },
        data() {

            return {
                title: "",
                format: "",
                data: "",

                height: "",
                attribute: "",
                name: "",

                image: "",
                template: "",
                width: "",
          
                description: "",

                selectedLangage: "fr",

                isTitleDisplayed: false,
                isDescriptionDisplayed: false



            }
        },
        watch:
        {

            properties: function () {
                this.title = this.properties?.title;
                this.attribute = this.properties?.attribute
                this.height = this.properties?.height;
                this.format = this.properties?.format;
                this.width = this.properties?.width;
                this.template = this.properties?.template;
                this.isTitleDisplayed = this.properties?.option?.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;
                this.data = this.properties?.data;
                this.description = this.properties?.description;
                this.image = this.properties?.image;
                this.name = this.properties?.name;

 
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.format = this.properties?.format
            this.attribute = this.properties?.attribute
            
            this.height = this.properties?.height;

            this.width = this.properties?.width;
            this.template = this.properties?.template;


            this.data = this.properties?.data;
            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;
            this.description = this.properties?.description;
            this.image = this.properties?.image;
            this.name = this.properties?.name;
       
        },
        methods:
        {

            onChangeData: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'data', value: this.data })
            },
            onChangeWidth: function () {

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'width', value: this.width })
            },
         
            onInputHeight: function () {

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'height', value: this.height })
            },
            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'attribute', value: this.attribute })
            },
            onInputLink: function () {

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'href', value: this.link })
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'body', value: null })
            },
            onChangeTemplate: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'template', value: this.template })
            },
        
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.title', value: this.isTitleDisplayed })
            },
            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.description', value: this.isDescriptionDisplayed })
            },

            onInputFormat: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            },
            onInputDisplayName: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'name', value: this.name })
            },
            onInputDisplayImage: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'image', value: this.image })
            },
        }
    }
</script>
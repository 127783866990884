<template>
  <ContentFileListModal
    v-if="category=='document'"
    :id="`${id}`"
    @validate="onValidate"
    @close="onCloseDocumentButton"
  />
  <ContentFileListModalPress
    v-if="category=='press'"
    :id="`${id}`"
    @validate="onValidate"
  />
  <ContentFileListModalPrices
    v-if="category=='award'"
    :id="`${id}`"
    @validate="onValidate"
  />
  <ContentFileListModalISO
    v-if="category=='iso'"
    :id="`${id}`"
    @validate="onValidateIso"
  />
  <ContentFileListModalOrganic
    v-if="category=='organic'"
    :id="`${id}`"
    @validate="onValidate"
  />
  <ContentFileListModalOtherLabel
    v-if="category=='other_label'"
    :id="`${id}`"
    @validate="onValidate"
  />

  <span
    class="relative w-full flex flex-row items-center bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5       rounded dark:bg-primary-900 dark:text-primary-300"
  >
 
    <span class="justify-end text-left w-full ">
      <div
        v-if="records.length > 0"
        class="justify-end w-full text-right"
      >

        {{ records.length }}
        <span v-if="records.length==1">{{ labels['LABEL_CERTIFICATION'][lg] }}</span>
        <span v-if="records.length!=1">{{ labels['LABEL_CERTIFICATIONS'][lg] }}</span>
      </div>
      <div
        v-if="records.length == 0"
        class="w-full"
      />
    </span>

  </span>

  <div
    style="height:150px"
    class="overflow-y-auto h-100 bg-white w-full mt-2 mb-2 w-full bg-white   flex flex-row items-start"
  >
    <table class="w-12/12 w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <tbody>
        <tr
          v-for="record in records"
          :key="record.id"
          class="cursor-pointer border-b dark:border-gray-600    "
        >
          <td
            class="w-4 px-4 py-4"
            class2="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            <div class="flex items-center">
              <input
                :id="`checkbox-table-search-${record.id}`"
                v-model="checkboxes[record.id]"
                type="checkbox"
                class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @click="onClickCheckbox($event, record)"
              >
              <label
                for="checkbox-table-search-1"
                class="sr-only"
              >checkbox</label>
            </div>
          </td>
          <td
            class="px-4 py-4"
            class2="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            <div class="flex items-center">
              <img
                class="h-10 rounded-xs"
                :src="record.image"
                alt="Jese image"
              >

              <div class="pl-3">
                <div class="text-base font-semibold">
                  {{ record.name }}
                </div>
                <div class="font-normal text-gray-500">
                  {{ buildDescription(record) }}
                </div>
              </div>
            </div>
          </td>
          <td
            class="px-4 py-4"
            class2="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            <div class="  flex items-center justify-end">
              {{ buildSecondColunm(record) }}
            </div>
          </td>


          <td
            class="px-4 py-4"
            class2="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            <div class="  flex items-center justify-end">
              <span
                class2="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5       rounded dark:bg-primary-900 dark:text-primary-300"
              >
                <a
                  type="button"
                  :href="record.href"
                  target="_blanks"
                  class="flex items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  <i class="fa-solid fa-download mr-2" />
                  {{ labels['LABEL_DOWNLOAD'][lg] }}
                </a>

              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <span
    class="w-full flex flex-row items-center bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5       rounded dark:bg-primary-900 dark:text-primary-300"
  >
    <div class="w-full">
      <button
        v-if="category=='document'"
        type="button"
        :data-modal-toggle2="`addProductDocumentModal-${id}`"
        class="w-64   flex items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
        @click="onClickDocumentButton"
      >
        <i class="fa-solid fa-upload mr-2" />
        {{ labels['LABEL_BUTTON_ADD_DOCUMENT'][lg] }}  
      </button>
      <button
        v-if="category=='press'"
        type="button"
        :data-modal-toggle="`addProductPressArticleModal-${id}`"
        class="w-64   flex items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      >
        <i class="fa-solid fa-upload mr-2" />
        {{ labels['LABEL_BUTTON_ADD_ARTICLE'][lg] }}
      </button>
      <button
        v-if="category=='award'"
        type="button"
        :data-modal-toggle="`selectProductPriceModal-${id}`"
        class="w-64  flex items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      >
        <i class="fa-solid fa-upload mr-2" />
        {{ labels['LABEL_BUTTON_ADD_AWARD'][lg] }}
      </button>
      <button
        v-if="category=='iso'"
        type="button"
        :data-modal-toggle="`addISONormModal-${id}`"
        class="w-64 flex   items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      >
        <i class="fa-solid fa-upload mr-2" />
        {{ labels['LABEL_BUTTON_ADD_ISO'][lg] }}
      </button>
      <button
        v-if="category=='organic'"
        type="button"
        :data-modal-toggle="`selectBioLabelModal-${id}`"
        class="w-64 flex w-full items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      >
        <i class="fa-solid fa-upload mr-2" />
        {{ labels['LABEL_BUTTON_ADD_ORGANIC'][lg] }}
      </button>
      <button
        v-if="category=='other_label'"
        type="button"
        :data-modal-toggle="`selectOtherLabelModal-${id}`"
        class="w-64 flex w-full items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      >
        <i class="fa-solid fa-upload mr-2" />
        {{ labels['LABEL_BUTTON_ADD_OTHER_CERTIFICATION'][lg] }}
      </button>
    </div>
    <div class="w-full justify-end text-right flex ">
      <button
        v-if="Object.keys(selected).length > 0"
        type="button"
        class="w-64 flex w-full items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-red-700 hover:bg-danger-800 focus:ring-4 focus:ring-danger-300 dark:bg-danger-600 dark:hover:bg-danger-700 focus:outline-none dark:focus:ring-danger-800"
        @click="onClickDelete"
      >

        <i class="fa-solid fa-trash mr-2" />
        {{ labels['LABEL_BUTTON_DELETE'][lg] }}
      </button>
    </div>

  </span>
</template>
<script>

    // import { Modal} from 'flowbite';



    import labels from "@/assets/lg/products.json"
    import ContentFileListModal from '@/components/content/lib/ContentFileListModal.vue'
    import ContentFileListModalPress from '@/components/content/lib/ContentFileListModalPress.vue'
    import ContentFileListModalPrices from '@/components/content/lib/ContentFileListModalPrices.vue'
   
    import ContentFileListModalISO from '@/components/content/lib/ContentFileListModalISO.vue'
    import ContentFileListModalOrganic from '@/components/content/lib/ContentFileListModalOrganic.vue'
    import ContentFileListModalOtherLabel from '@/components/content/lib/ContentFileListModalOtherLabel.vue'




    export default {
        name: 'ContentFileList',
        components: {
            ContentFileListModal,
            ContentFileListModalPrices,
            ContentFileListModalPress,
            ContentFileListModalISO,
         
            ContentFileListModalOrganic,
            ContentFileListModalOtherLabel
        },
        props: {
            id: String,
            label: String,
            value: Object,
            category: String,
            config: Object
        },
        emits: [ "manageField"],
        data() {
            return {
                output: [],
                checkboxes: {},
                selected: {},
                records: [],
                addProductDocumentModal: null,
                labels: labels,
                lg: 'FR',
            }
        },
        watch:
        {
            value: function () {
                if (this.value == undefined) this.records = []
                else this.records = this.value;
            }
        },
        mounted() {
      
            // if (this.category == 'document')
            //   this.records = this.value;
           // const modalEl = document.getElementById('addProductDocumentModal');
          //  this.addProductDocumentModal = new Modal(modalEl, {
          //      placement: 'center'
          //  });




            if (this.value == undefined) this.records = []
            else this.records = this.value;


        },
        methods: {
        
            onClickDocumentButton()
            {
                this.addProductDocumentModal.show()
            },
        

            onCloseDocumentButton()
            {
                this.addProductDocumentModal.hide()
            },
        


            getData() {
                let response = JSON.parse(JSON.stringify(this.records))
         
                return response
            },
            onClickDelete() {
 
            },
            buildSecondColunm(record) {
                if (this.category == "iso") {
                    return record.year
                }
            },

            onClickCheckbox(event, record) {
                event.stopPropagation()

                if (this.selected[record.id] != undefined) {
                    delete this.selected[record.id]
                }
                else {
                    this.selected[record.id] = record
                }


            },
            buildDescription(record) {
                if (this.category == "iso") {
                    return "Certifié par " + record.company_name
                }
            },

            onValidateIso(item) {

                this.records.push(
                    {
                        "id": item.id,
                        "name": item.name,
                        "company_id": item.company,
                        "company_name": item.companyName,

                        "file_name": null,
                        "file_mimeType": "application/png",
                        "file_format": "png",
                        "file_size": "",
                        "file_href": "",
                        "year": item.year,
                        "image": item.image
                    }

                )
            },

            onValidate(item) {
                this.records.push(
                    {
                        "id": item.document.id,
                        "name": item.name,
                        "filename": item.document.name,
                        "mimeType": "application/pdf",
                        "format": "pdf",
                        "size": "",
                        "image": item.document.url
                    }
                    //
                )
            },
            buildAvatar(v1, v2, record) {
                let a1 = this.manageVariable(v1, record);
                let a2 = this.manageVariable(v2, record);

                if ((a1 == undefined) || (a1 == ""))
                    a1 = "";
                else
                    a1 = a1[0].toUpperCase();

                if ((a2 == undefined) || (a2 == ""))
                    a2 = "";
                else
                    a2 = a2[0].toUpperCase();

                if (a1 == "" && a2 == "") a1 = "-";

                return a1 + a2;
            },
        }
    }
</script>
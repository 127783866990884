<template>
  <nav class="mt-24 sm:ml-64 px-2 bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
    <div class="container flex flex-wrap items-center justify-between mx-auto">
      <button
        data-collapse-toggle="navbar-dropdown"
        type="button"
        class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-dropdown"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div
        id="navbar-dropdown"
        class="hidden w-full md:block md:w-auto"
      >
        <ul
          class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
        >
          <li>
            <a
              href="#"
              class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-white dark:bg-blue-600 md:dark:bg-transparent"
              aria-current="page"
            >Home</a>
          </li>
          <li>
            <button
              id="dropdownNavbarLink"
              data-dropdown-toggle="dropdownNavbar"
              class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
            >
              Dropdown
              <svg
                class="w-5 h-5 ml-1"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <div
              id="dropdownNavbar"
              class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
            >
              <ul
                class="py-2 text-sm text-gray-700 dark:text-gray-400"
                aria-labelledby="dropdownLargeButton"
              >
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >Dashboard</a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >Settings</a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >Earnings</a>
                </li>
              </ul>
              <div class="py-1">
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                >Sign
                  out</a>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            >Services</a>
          </li>
          <li>
            <a
              href="#"
              class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            >Pricing</a>
          </li>
          <li>
            <a
              href="#"
              class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            >Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>



  <div class="py-2  px-4 sm:ml-64 bg-white flex flex-col">
    <div class="p-2 bg-gray-100 rounded-lg dark:border-gray-700 mt-2">
      <h2 class="px-2 text-left mb-2 text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">
        Statitiques sur le scan des QR Codes
      </h2>
    </div>


    <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-2">
      <div class="w-full grid grid-cols-2 gap-6 mb-4 ">
        <div
          v-for="record in statistics"
          :key="record.id"
          class="w-full  flex items-start justify-center rounded bg-white dark:bg-gray-800"
        >
          <div class="w-full flex flex-row mb-4 ">
            <div
              class="w-auto    w-full flex items-center justify-center rounded bg-white   dark:bg-gray-800"
            >
              <div class="flex flex-col justify-center">
                <img
                  :src="record.image"
                  class="w-48"
                >
                <span class="w-48  text-sm text-center font-bold mt-1"> {{ record.name }}</span>
              </div>
            </div>
            <div
              style="height:150px"
              class=" w-full     flex items-center justify-center rounded bg-gray-50   dark:bg-gray-800"
            >
              <ColumnBarChart :data="formatStatistics(record.values)" />
            </div>
          </div>
        </div>
      </div>



      <div class=" flex items-center justify-center rounded bg-gray-50   dark:bg-gray-800" />
    </div>
  </div>
</template>

<script>
    import { mapGetters } from "vuex";

    import ColumnBarChart from "@/components/charts/ColumnBarChart.vue"

    import service from "@/services/FullService.vue"

    export default {
        name: 'DashboardProductScans',
        components: {
            ColumnBarChart
        },
        props: {
           
        },

      
        data() {
            return {
                lg: "FR",
                statistics: [],
                durations: []
            };
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch: {
            'user.tenant_id': function () {
                this.loadStatistics()
            },
            'user.lg': function (val) {
                this.lg = val.language;
            },
        },
        async mounted() {
            await this.loadStatistics()
            if (this.user == undefined) {
                this.$router.push("/login")
                return;
            }
            this.lg = this.user.language;
        },

        methods: {
            loadStatistics: async function () {
         
                let statistics = await service.getRecord("scan-logs", "stats/products", this.user?.token)

          


                let records = []

                let durations = []
                for (let k = 0; k < 24; k++) {
                    let label = k

                    label = k + "h00"


                    durations.push(label)
                }

                for (let i = 0; i < statistics.records.length; i++) {

                    let counters = {}
               

                    let p = statistics.records[i];
                    for (let k = 0; k < 24; k++) {
                        counters[k] = 0
                    }

                    for (let j = 0; j < p.result.length; j++) {
                        let s = p.result[j]
                        let date = new Date(s.timestamp)
                        // let day = date.getDay()
                        let hour = date.getHours()

                        counters[hour] = counters[hour] + 1
                    }
                    let values = []
                    let keys = Object.keys(counters)
                    for (let k = 0; k < keys.length; k++) {
                        values.push(counters[keys[k]])


                    }
                    let image = "";
                    if (p.details[0]) {
                        if (typeof p.details[0].image_front_product == "string")
                            image = p.details[0].image_front_product


                        if (typeof p.details[0].image_front_product == "object")
                            image = p.details[0].image_front_product.url

                        records.push({ id: p.product, name: p.details[0].name, image: image, values: values })

                    }

                }
                this.statistics = records;
                this.durations = durations;
            },
             
            formatStatistics: function (values) {

                return { x: this.durations, y: values }

            }
        }
       
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <main
    class="fixed start-80 end-96    z-2 bottom-0  bg-gray-300 shadow   top-0   pt-24 pb-10   overflow-y-hidden  flex justify-center "
  >
    <div
      class="relative bg-gray-50   pb-12  "
      :class="{'w-full': (mode=='desktop'), ' bg-red-500 ': (mode =='smartphone')}"
      style="width: 600px; height: 870px"
    >
      <div class="min-h-full bg-gray-100 h-full overflow-y-scroll pb-16 pt-1">
        <div
          v-for="section in sections"
          :key="section.id"
          class="w-full px-1"
        >
          <div
            v-if="section.isVisible == undefined || section.isVisible"
            :id="`editor-section-${record?.id}`"
            class="relative group   cursor-pointer mb-4 pb-2   ring-transparent "
            :class="{'ring-blue-600 ring-2' : (section?.id == selected?.id), 'ring-blue-600 ring-1': (hovered?.id == section?.id)}"
            @click="onItemClick('body', section)"
            @mouseover="onHover(section)"
            @mouseleave="onLeave(section)"
          >
            <component
              :is="mapComponent(section.type,section.action)"
              :id="section.id"
              class="w-full"
              :properties="section"
              :selected="selected"
              @item-click="onSubItemClick"
              @section-hover="onHover"
            />
            <div
              class="absolute -bottom-4 w-full opacity-0    -bottom-2   text-blue text-xs p-1 flex items-center flex justify-center  "
              :class="{'opacity-100' : (section?.id == selected?.id), 'opacity-100': (hovered?.id == section?.id)}"
            >
              <div class="  bg-blue-700 w-6  h-6 rounded-full flex justify-center items-center">
                <i class="  text-white fa-solid   shrink-0   fa-plus  fa-xs bg-blue-700" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 h-12 bg-white w-full flex flex-row items-center">
        <div class="  w-4/12   justify-start flex pl-2">
          <div
            v-for="section in record?.properties?.pages[0]?.submit?.areas?.left"
            :key="section.id"
            class=" pl-2  pr-2"
            @click="onItemClick('footer', section)"
          >
            <componentButton
              v-if="section?.isVisible == undefined || section?.isVisible==true"
              :id="section.id"
              class="w-full ring-2 ring-transparent hover:ring-blue-800"
              :properties="section"
              :class="{'ring-blue-800' : (section.id == selected?.id)}"
            />
          </div>
        </div>
        <div class="w-4/12    justify-center flex pr-2 pl-2">
          <div
            v-for="section in record?.properties?.pages[0]?.submit?.areas?.center"
            :key="section.id"
            class="pl-2  pr-2"
            @click="onItemClick('footer', section)"
          >
            <componentButton
              v-if="section?.isVisible == undefined || section?.isVisible==true"
              :id="section.id"
              class="w-full ring-2 ring-transparent hover:ring-blue-800"
              :properties="section"
              :class="{'ring-blue-800' : (section.id == selected?.id)}"
            />
          </div>
        </div>
        <div class="w-4/12   justify-end flex  pr-2">
          <div
            v-for="section in record?.properties?.pages[0]?.submit?.areas?.right"
            :key="section.id"
            class="pl-2 pr-2"
            @click="onItemClick('footer', section)"
          >
            <componentButton
              v-if="section?.isVisible == undefined || section?.isVisible==true"
              :id="section.id"
              class="w-full ring-2 ring-transparent  hover:ring-blue-800"
              :properties="section"
              :class="{'ring-blue-800' : (section.id == selected?.id)}"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>



<script>

  import componentAvatar from '@/layout/Editor/Components/componentAvatar.vue'
  import componentTitle from '@/layout/Editor/Components/componentTitle.vue'
  import componentScanCode from '@/layout/Editor/Components/componentScanCode.vue'
  import componentScanDoc from '@/layout/Editor/Components/componentScanDoc.vue'
  import componentNumber from '@/layout/Editor/Components/componentNumber.vue'
  import componentDate from '@/layout/Editor/Components/componentDate.vue'
  import componentRadio from '@/layout/Editor/Components/componentRadio.vue'
  import componentHTML from '@/layout/Editor/Components/componentHtml.vue'
  import componentEdit from '@/layout/Editor/Components/componentEdit.vue'
  import componentSeparator from '@/layout/Editor/Components/componentSeparator.vue'
  import componentImage from '@/layout/Editor/Components/componentImage.vue'
  import componentSignature from '@/layout/Editor/Components/componentSignature.vue'
  import componentTakePhoto from '@/layout/Editor/Components/componentTakePhoto.vue'
  import componentButton from '@/layout/Editor/Components/componentButton.vue'
  import componentList from '@/layout/Editor/Components/componentList.vue'
  import componentRow from '@/layout/Editor/Components/componentRow.vue'

  import componentGrid from '@/layout/Editor/Components/componentGrid.vue'

  import componentPrint from '@/layout/Editor/Components/componentPrint.vue'



  export default {
    name: 'PageEditor',
    components: {
      componentTitle,
      componentEdit,
      componentSeparator,
      componentImage,
      componentAvatar,
      componentDate,
      componentRadio,
      componentSignature,
      componentHTML,
      componentScanCode,
      componentScanDoc,
      componentNumber,
      componentButton,
      componentTakePhoto,
      componentList,
      componentRow,
      componentGrid,
      componentPrint
    },
    props:
    {
      record: Object,
      mode: String,
      selected: Object,
      sections: Object
    },
    data() {
      return ({
        hovered: null
        //sections: []
      })
    },
    watch: {

      "record": function () {


        console.log("EditorBody", "watch", "record")

        //  let sections = this.record?.properties?.pages[0].components

        //   if (sections == undefined) {
        //    this.sections = []
        //  }
        //  else {
        //     this.sections = sections
        //  }

      },

      "sections": function () {
        console.log("EditorBody", "watch", "sections")
      },

      "selected": function () {
        console.log("EditorBody", "watch", "selected")
      }
    },
    async mounted() {

    },

    methods: {
      onLeave: function () {
        console.log("EditorBody", "onLeave")
        this.hovered = null
      },

      onHover: function (item) {
        console.log("EditorBody", "onHover", item?.id)
        this.hovered = item
      },

      onItemClick: function (zone, section) {

        section.zone = zone
        this.$emit("itemClick", section)
      },
      onSubItemClick: function (item) {

        item.section.zone = item.zone
        this.$emit("itemClick", item.section)
      },
      mapComponent: function (type, action) {
        if (type == 'title') return componentTitle;
        if (type == 'scan-code') return componentScanCode;
        if (type == 'scan-qrcode') return componentScanCode;

        if (type == 'scan-barcode') return componentScanCode;

        if (type == 'list') return componentList;
        if (type == 'row') return componentRow;

        if (type == 'scan-doc') return componentScanDoc;
        if (type == 'number') return componentNumber;
        if (type == 'radio') return componentRadio;
        if (type == 'button') {
          if (action == "take-photo") return componentTakePhoto;

          return componentButton;

        }
        if (type == 'avatar') return componentAvatar;
        if (type == 'date') return componentDate;
        if (type == 'html') return componentHTML;
        if (type == 'hand-signature') return componentSignature;

        if (type == 'image') return componentImage;

        if (type == 'separator') return componentSeparator;
        if (type == 'validate') return componentButton;
        if (type == 'edit') return componentEdit;

        if (type == 'grid') return  componentGrid;
        if (type == 'print') return  componentPrint;

      },

    }
  }
</script>
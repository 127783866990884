<template>
  <div class=" w-full py-6">
    <div class="mb-6 flex flex-col items-start justify-start">
      Options pour Tag
      <div class="mb-6 flex flex-col items-start justify-start w-full">
        <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
          Categorie de Tag </label>
        <select
          v-model="currentItem.source"
          class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
        >
          <option
            v-for="choice in sources"
            :key="choice.id"
            :value="choice.id"
          >
            {{ choice.value ? choice.value[lg] : choice.id }}
          </option>
        </select>
      </div>
           

      <div class="mb-6 flex flex-col items-start justify-start w-full">
        <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white w-96">
          Nom de l'attribute
        </label>
        <input
          v-model="currentItem.data"
          type="text"
          class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
          required
        >
      </div>
    </div>
  </div>
</template>

<script>
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'DeveloperItemTag',
        props: {
            item: Object
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        data() {
            return ({
                currentItem: {

                },
                lg: 'FR',
                sources: []
            })
        },
        watch: {
            item: function (val) {
                let item = val;

                this.currentItem = item;

            }
        },
        async mounted() {

            let item = this.item;
            this.currentItem = item;

            let responsePredefined = await service.getData("select-values", this.user?.token, { category: "value-collection" })
            if (responsePredefined != undefined)
                this.sources = responsePredefined.records;
 
        }

    }
</script>
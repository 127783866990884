<template>
  <div
    class="w-full mt-12 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start bg-blue-300 "
  >
    <div class="w-full mb-4 border-b border-gray-200 dark:border-gray-700">
      <ul
        id="myTab"
        class="w-full flex flex-wrap -mb-px text-sm font-medium text-center"
        data-tabs-toggle="#myFormTabContent"
        role="tablist"
      >
        <li
          class="mr-2"
          role="tab-properties"
        >
          <button
            id="tab-properties-tab"
            class="inline-block p-4 border-b-2 rounded-t-lg"
            data-tabs-target="#tab-properties-content"
            type="button"
            role="tab"
            aria-controls="tab-properties-content"
            aria-selected="false"
          >
            Titre des colonnes
          </button>
        </li>
        <li
          class="mr-2"
          role="tab-style"
        >
          <button
            id="tab-style-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#tab-style-content"
            type="button"
            role="tab"
            aria-controls="tab-style-content"
            aria-selected="false"
          >
            Cellules
          </button>
        </li>
        <li
          class="mr-2"
          role="tab-misc"
        >
          <button
            id="tab-misc-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#tab-misc-content"
            type="button"
            role="tab"
            aria-controls="tab-misc-content"
            aria-selected="false"
          >
            Divers
          </button>
        </li>
      </ul>
    </div>
    <div
      id="myFormTabContent"
      class="w-full"
    >
      <div
        id="tab-properties-content"
        class="w-full  p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="tab-properties-tab"
      >
        <div class="mb-6 flex flex-col items-start justify-start w-full">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Titre de la colonne ***
          </label>

          <div
            v-if="currentItem.name != undefined"
            class="flex flex-col items-center   justify-start"
          >
            <div class="w-full relative flex flex-row justify-start items-center mb-1 w-full rounded-lg">
              <input
                v-model="currentItem.name.FR"
                class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              >
            </div>
            <div class="hidden flex justify-start flex-row w-full mt-2 ml-6 items-center">
              <input
                id="checked-checkbox"
                v-model="currentItem.name.displayTitle"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              >
              <label
                for="checked-checkbox"
                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Afficher le
                titre</label>
            </div>
          </div>
        </div>


        <DeveloperStyle
          :item="currentItem"
          @update="onUpdateStyle"
        />
      </div>


      <div
        id="tab-style-content"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="tab-style-tab"
      >
        <div class="mb-1 flex flex-col items-start justify-start  w-full">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Type {{ currentItem.type }}</label>
          <select
            id="default"
            v-model="currentItem.type"
            class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option
              v-for="type in types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.value[lg] ? type.value[lg] : type.value }}
            </option>
          </select>
        </div>
        <div class="bg-gray-50 border border-gray-400 rounded-lg">
          <DeveloperCellText
            v-if="currentItem.type=='text'"
            :item="currentItem"
          />
          <DeveloperCellText
            v-if="currentItem.type=='date'"
            :item="currentItem"
          />

          <DeveloperCellButton
            v-if="currentItem.type=='button'"
            :item="currentItem"
          />
          <DeveloperCellImageText
            v-if="currentItem.type=='image-title-subtitle'"
            :item="currentItem"
          />
          <DeveloperCellBadge
            v-if="currentItem.type=='badge'"
            :item="currentItem"
          />
        </div>
      </div>
      <div
        id="tab-misc-content"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="tab-misc-tab"
      >
        <DeveloperStyle
          :item="currentItem"
          @update="onUpdateStyle"
        />
        <DeveloperMisc :item="currentItem" />
      </div>
    </div>
    <span class="bg-red-300 ">** {{ currentItem }} **</span>


    <div class="w-full flex flex-row items-center mb-3">
      <div class="w-full flex justify-start">
        <button
          class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          @click="onClickDeleteSection"
        >
          Supprimer
        </button>
      </div>
      <div class="w-full flex justify-end">
        <button
          class="text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="onClickAddSection"
        >
          Ajouter une section
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    import { Tabs } from "flowbite";
    import DeveloperStyle from "@/components/developer/DeveloperListStyle.vue";
    import DeveloperMisc from "@/components/developer/DeveloperMisc.vue";


    import DeveloperCellText from "@/components/developer/DeveloperCellText.vue";
    import DeveloperCellBadge from "@/components/developer/DeveloperCellBadge.vue";
    import DeveloperCellButton from "@/components/developer/DeveloperCellButton.vue";
    import DeveloperCellImageText from "@/components/developer/DeveloperCellImageText.vue";




    import slugify from 'slugify'
    export default {
        name: 'DeveloperTab',
        components: {
            DeveloperStyle, DeveloperMisc, DeveloperCellText, DeveloperCellButton, DeveloperCellImageText, DeveloperCellBadge
        },
        props: {
            item: Object
        },

        data() {
            return ({
                lg: "FR",
                currentItem: {},
                tabElements: [],
                options: {
                    defaultTabId: 'tab-home',
                    activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
                    inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
                    // onShow: () => {

                    // }
                },
                types: [
                    {
                        id: "text",
                        value: {
                            "EN": "Text",
                            "FR": "Texte"
                        }
                    },
                    {
                        id: "date",
                        value: {
                            "EN": "Date",
                            "FR": "Date"
                        }
                    },
                    {
                        id: "date-time",
                        value: {
                            "EN": "Date & Hour",
                            "FR": "Date & Heure"
                        }
                    },
                    {
                        id: "badge",
                        value: {
                            "EN": "Badge",
                            "FR": "Badge"
                        }
                    },
                    {
                        id: "image-title-subtitle",
                        value: {
                            "EN": "Text Field",
                            "FR": "Image & Text"
                        }
                    },
                    {
                        id: "button",
                        value: {
                            "EN": "Button",
                            "FR": "Bouton"
                        }
                    }
                ],

            })
        },
        computed: {
            tag: function () {
                if (this.currentItem == undefined) return ""
                if (this.currentItem.shortName == undefined) return ""
                return slugify(this.currentItem.shortName, { lower: true })
            }
        },
        watch: {
            item: function (val) {
                let item = val;

                if (item.name == undefined) item.name = {}
                if (item.name.FR == undefined) item.name.FR = ""
                if (item.name.EN == undefined) item.name.EN = ""
                if (item.name.displayTitle == undefined) item.name.displayTitle = true





                this.currentItem = item;

            }
        },
        mounted() {

            let item = this.item;

            if (item.name == undefined) item.name = {}
            if (item.name.FR == undefined) item.name.FR = ""
            if (item.name.EN == undefined) item.name.EN = ""
            if (item.name.displayTitle == undefined) item.name.displayTitle = true




            this.currentItem = item;
            const tabElements = [
                {
                    id: 'tab-home',
                    triggerEl: document.querySelector('#tab-properties-tab'),
                    targetEl: document.querySelector('#tab-properties-content')
                },
                {
                    id: 'tab-dashboard',
                    triggerEl: document.querySelector('#tab-style-tab'),
                    targetEl: document.querySelector('#tab-style-content')
                },
                {
                    id: 'tab-settings',
                    triggerEl: document.querySelector('#tab-misc-tab'),
                    targetEl: document.querySelector('#tab-misc-content')
                }
            ]

            this.$nextTick(() => {
                const tabs = new Tabs(tabElements, this.options);

                tabs.show('tab-home');
            });
        },

        methods: {
            onUpdateStyle: function (cls) {

                this.currentItem.class = cls.class;

                this.$emit("update", this.currentItem)
            },
            onClickAddSection: function () {
                this.$emit("addSection", this.item)
            },
            onClickDeleteSection: function () {
         
                this.$emit("delete", this.item)
            }
        }

    }
</script>
<template>
  <div class="  relative    flex    w-full flex items-center         flex flex-row justify-start items-center ">
    <div class="flex  font-bold text-lg  text-white w-full justify-center text-center ">
      <button
        type="button"
        class="min-w-lg h-8 flex items-center justify-center px-4 py-1.5 text-sm font-medium text-white rounded-lg bg-green-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
      >
        <i :class="`fa-solid fa-${properties.prefix} mr-2`" />
        <span> {{ properties.title ? properties.title : properties.text }}</span>
        <i :class="`fa-solid fa-${properties.suffix} ml-2`" />
      </button>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditorComponentButton',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties?.id })
            },
            deleteMe: function () {
             
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
<template>
  <div class="relative   overflow-y-auto bg-gray-50  lg:ml-64 dark:bg-gray-900">
    <div class="relative  mt-24">
      <div class="  dark:border-gray-50 mt-2">
        <h2 class="p-2 text-left mb-2 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          {{ displayTitle() }}
        </h2>
      </div>
      <div
        class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-start md:space-y-0 md:space-x-3"
      >
        <div
          class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
        >
          <ul class="flex flex-wrap -mb-px">
            <li
              v-for="tab in configPage.tabs"
              :key="tab.id"
              class="mr-2"
            >
              <div
                :class="{ 'text-blue-600 border-blue-600 active  dark:text-blue-500 dark:border-blue-500 ': current_page==tab.id, 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': current_page!=tab.id}"
                class="cursor-pointer inline-block p-4  border-b-2 rounded-t-lg  "
                aria-current="page"
                @click="selectPage(tab.id)"
              >
                {{ tab.name[lg] }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-for="tab in configPage.tabs"
        :key="tab.id"
      >
        {{ tab.id }}
        <B4FTable
          v-if="(tab.id == 'inbound-controls-dashboard')&& (current_page =='inbound-controls-dashboard')"
          :is-loading="isLoading"
          :is-loading2="isLoading2"
          :is-loading3="isLoading3"
          :limit="limit"
          :offset="offset"
          :config="config"
          :records="records"
          @row-click="onRowClick"
          @add="onAddClick"
          @export="onExportClick"
          @refresh="onRefreshClick"
          @refresh-page="onRefreshPage"
          @refresh-table="onRefreshTable"
        />

        <B4FForm
          v-if="(tab.id == 'inbound-controls-issues')&& (current_page =='inbound-controls-issues')"
          :config="config"
          :template="template"
          @change-view="onChangeView"
          @save="onSave"
          @cancel="onCancel"
        />

        <B4FForm
          v-if="(tab.id == 'inbound-controls-rules')&& (current_page =='inbound-controls-rules')"
          :config="config"
          :template="template"
          @change-view="onChangeView"
          @save="onSave"
          @cancel="onCancel"
        />
      </div>
    </div>
  </div>
</template>
<script>
    const MAX_ITEMS = 10;
    import { Modal } from 'flowbite';

    import B4FTable from '@/components/TableComponent.vue'
    import B4FForm from '@/pages/FormView.vue'


    import service from "@/services/FullService.vue"

    import { mapGetters } from "vuex";
    export default {
        name: 'PageView',
        components: {
            B4FTable,B4FForm
        },
   
        data() {
            return {
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                config: {},
                records: [],
                offset: 0,
                filters: {},
                limit: MAX_ITEMS,
                lg: "FR",
                title: "",
                surveyModal: null,
                button: "",
                current_page: "inbound-controls-dashboard",
                configPage: {
                    tabs: [
                        {
                            "id": "inbound-controls-dashboard",
                            "name": {
                                "EN": "Wine",
                                "FR": "Tâbleaux de bord"
                            }
                        },
                        {
                            "id": "inbound-controls-issues",
                            "name": {
                                "EN": "Not Compliant",
                                "FR": "Anomalies"
                            }
                        },
                        {
                            "id": "inbound-controls-rules",
                            "name": {
                                "EN": "Not Compliant rules",
                                "FR": "Régles de contrôles"
                            }
                        }

                    ]
                }
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            '$route.params.id': async function (val) {
                let filters = {}

                //filters['category'] = config.filters;
                this.reloadPage(val, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user_id': async function () {
                this.isLoading = true;
                let filters = {}
                this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);
      
               
            }
        },
        async mounted() {
            // await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {})

            const modalEl = document.getElementById('select-product-type-modal');
            this.surveyModal = new Modal(modalEl, {
                placement: 'center'
            });


        },
        methods: {
            selectPage(value) {

                this.current_page = value;
            },
            onExportClick() {
           
            },
            displayTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "EN"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            async reloadPage(id, offset, limit, filter, level) {

                if (level == null) level = 1


                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }

                this.offset = offset;
                this.limit = limit;


                if (level == 1) this.isLoading = true;
                if (level == 2) this.isLoading2 = true;
                if (level == 3) this.isLoading3 = true;
                console.log("PageView 1", id)
                let config = await service.getConfiguration(id, this.user?.token)



                let t = await service.getFilterTabDefinition(this.$route.params.id, this.user?.token, filter)
               
          
                if (config.secondBar)
                    if ((level == 1) && (filter.category == undefined)) {
                        //TODO Check how to remove this
                        if ((this.$route.params.id == 'products') || (this.$route.params.id == 'raw-products'))
                            if (t.records[0]) filter.category = t.records[0].id
                    }
                this.filter = filter;
                let d = await service.getFilterBarDefinition(this.$route.params.id, this.user?.token, filter)
              

                config.tabs = t.records;
                config.secondBar = d.records;

                this.config = config;
                this.records = await service.getData(id, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
                if (level == 1) this.isLoading = false;
                if (level == 2) this.isLoading2 = false;
                if (level == 3) this.isLoading3 = false;
                
            },
            OnSelectProductSelected: function (item) {
                let template = "";
 


                
                if (this.$route.params.id == 'products') {
                    if (item == "yoghurt") template = "product-yoghurt";
                    if (item == "cheese") template = "product-cheese";
               
                    if (item == "milk") template = "product-milk";
                    if (item == "cream") template = "product-cream";
               
                    if (item == "wine") template = "product-wine";
                    if (item == "olive-oil") template = "product-olive-oil";

                    if (item == "biscuit") template = "product-biscuit";


                    if (item == "alcoholic-beverages") template = "product-alcoholic-beverage";
                    if (item == "none-alcoholic-beverages") template = "product-none-alcoholic-beverage";
              
              
                }
                if (this.$route.params.id == 'raw-products') {
                    if (item == "yoghurt") template = "product-yoghurt";
                    if (item == "cheese") template = "product-cheese";
                   
                    if (item == "milk") template = "product-milk-raw";
                    if (item == "cream") template = "product-cream-raw";

                    if (item == "biscuit") template = "product-biscuit-raw";
                   
                    if (item == "wine") template = "product-wine";
                    if (item == "olive-oil") template = "product-olive-oil";
                    if (item == "alcoholic-beverages") template = "product-alcoholic-beverage";
                    if (item == "none-alcoholic-beverages") template = "product-none-alcoholic-beverage";
              
              
              
                }


                this.surveyModal.hide();
                let url = "/admin/views/" + this.$route.params.id + "/new?template=" + template

     
              // this.$router.push(url)
                window.location = url

            },
            OnSelectProductClose: function () {
          
                this.surveyModal.hide();
            },
            onRefreshClick: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 2);
            },
            onRefreshPage: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 1);
            },

            onRefreshTable: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 3);
            },


            onAddClick: function () {

                if (this.$route.params.id == "products") {
                    this.surveyModal.show();
                    return;
                }

                let url = "/admin/views/" + this.$route.params.id + "/new"
                //this.$router.push(url)
                window.location = url
            },
            onRowClick: function (item) {
                let url = "/admin/views/" + this.$route.params.id + "/" + item.record
                // this.$router.push(url)
                window.location = url
            }
        }
    }
</script>
<template>
  <div
    class=" mr-1 relative  cursor-grab flex  list-group w-full flex items-center    flex flex-row justify-start items-center "
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      class="flex   w-full  pl-2  "   
      :class="{'flex-col items-start' : (properties?.layout != 'row'), 'flex-row items-center' : (properties?.layout == 'row') }"
    >
      <div
        v-if="properties?.option?.title"
        class=" w-full flex  py-0.5  items-center justify-start w-full  text-sm text-left text-gray-900 font-bold "
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option?.description"
        class="mt-2  w-full text-xs text-left text-gray-500 font-normal"
      >
        {{ properties?.description }}
      </div>


      <div
        class="  mt-2  font-bold  text-xs  text-gray-800 flex justify-start"
        :class="getClass"
      >
        <div
          class="inline-flex rounded-md shadow-sm justify-start"
          role="group"
        >
          <button
            v-for="(value,index) in properties?.values"
            :key="value.id"
            type="button"
            class="min-w-16 max-w-[150px] px-3 py-2 text-xs font-medium text-gray-100  border-gray-600 hover:bg-gray-200 hover:text-gray-800 focus:z-10 focus:ring-0 focus:ring-gray-200 focus:bg-gray-200 focus:text-gray-900  truncate"
            :class="{'border rounded-s-xl': (index==0), 'border-t border-b border-r': ((index!=0) && (index!=(properties?.values.length -1))), 'border rounded-e-xl': (index==(properties?.values?.length -1)), 'text-gray-800 bg-blue-600  text-white': (properties?.default == index), 'text-gray-900 bg-transparent': (properties?.default != index)}"
          >
            {{ value.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
                values: [
                    { id: "1", name: "A1" }, { id: "2", name: "A2" }, { id: "2", name: "A2" }, { id: "3", name: "A3" }
                ]
            }
        },
        computed:
        {
            getClass: function () {
                if (this.properties.width == undefined) return "w-full"
                if (this.properties.width == "") return "w-full"
                return this.properties.width
            },
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties.id })
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
<template>
  <div class=" w-full h-full">
    <div
      v-for="(inputValue,index) in output.rules"
      :key="inputValue.id"
      class="py-1"
    >
      <QueryBuilderGroup
        v-if="inputValue.type == 'group'"
        :input-value="inputValue"
        :index="index"
        @delete-row="onDeleteRow"
        @delete-group="onDeleteGroup"
        @update-value="onUpdateValue"
      />
      <QueryBuilderRow
        v-if="inputValue.type != 'group'"
        :input-value="inputValue"
        :index="index"
        @delete-row="onDeleteRow"
        @update-value="onUpdateValue"
      />
    </div>
  </div>
</template>

<script>
    import QueryBuilderRow from "@/components/QueryBuilder/QueryBuilderRow.vue";
    import QueryBuilderGroup from "@/components/QueryBuilder/QueryBuilderGroup.vue";

    export default {
        name: 'QueryBuilderGroups',
        components: { QueryBuilderGroup, QueryBuilderRow },
        props: {
            inputValue: Object
        },
        data: function () {
            return {
                output: {}
            }
        },
        watch:
        {
            inputValue: function () {
                this.output = this.inputValue
            }
        },
        mounted() {
            this.output = this.inputValue
        },
        methods:
        {
            onDeleteRow: function (event) {
                console.log("QueryBuildeGroups", "onDeleteRow", event.id)

                const output = this.output.rules.filter(function (item) { return item.id !== event.id; });
                console.log(event.id, output)
                this.output.rules = output

                let newevent = { id: this.output.id, rules: this.output.rules}
                this.$emit("updateValue", newevent)
            },
            onDeleteGroup: function (event) {
                console.log("QueryBuildeGroups", "onDeleteRow")
                this.$emit("deleteGroup", event)
            },
            onUpdateValue()
            {
                const output = this.output.rules.filter(function (item) { return item.id !== event.id; });
                console.log(event.id, output)
                this.output.rules = output

                let newevent = { id: this.output.id, rules: this.output.rules}
                this.$emit("updateValue", newevent)

            
            }
        }
    }
</script>
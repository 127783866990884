<template>
  <div class="bg-white w-full p-6">
    <div class="w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Largeur </label>

      <ul class="grid w-12/12  w-full gap-6 md:grid-cols-8">
        <li>
          <input
            id="size-no"
            v-model="size"
            type="radio"
            name="size"
            value="-"
            class="hidden peer"
            required=""
          >
          <label
            for="size-no"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="fa-solid fa-ban" />
          </label>
        </li>
        <li>
          <input
            id="size-2-12"
            v-model="size"
            type="radio"
            name="size"
            value="w-2/12"
            class="hidden peer"
            required=""
          >
          <label
            for="size-2-12"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            2/12
          </label>
        </li>
        <li>
          <input
            id="size-4-12"
            v-model="size"
            type="radio"
            name="size"
            value="w-4/12"
            class="hidden peer"
            required=""
          >
          <label
            for="size-4-12"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            4/12
          </label>
        </li>
        <li>
          <input
            id="size-6-12"
            v-model="size"
            t
            type="radio"
            name="size"
            value="w-6/12"
            class="hidden peer"
          >
          <label
            for="size-6-12"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            6/12
          </label>
        </li>
        <li>
          <input
            id="size-8-12"
            v-model="size"
            t
            type="radio"
            name="size"
            value="w-8/12"
            class="hidden peer"
          >
          <label
            for="size-8-12"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            8/12
          </label>
        </li>
        <li>
          <input
            id="size-10-12"
            v-model="size"
            t
            type="radio"
            name="size"
            value="w-10/12"
            class="hidden peer"
          >
          <label
            for="size-10-12"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            10/12
          </label>
        </li>
        <li>
          <input
            id="size-12-12"
            v-model="size"
            t
            type="radio"
            name="size"
            value="w-12/12"
            class="hidden peer"
          >
          <label
            for="size-12-12"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            12/12
          </label>
        </li>
      </ul>
    </div>

    <div class="w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Couleur du fond </label>

      <ul class="grid w-12/12 gap-6 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
        <li>
          <input
            id="background-color-no"
            v-model="background"
            type="radio"
            name="backgroundColor"
            value="-"
            class="hidden peer"
            required=""
          >
          <label
            for="background-color-no"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="fa-solid fa-ban" />
          </label>
        </li>
               
        <li>
          <input
            id="background-color-white"
            v-model="background"
            type="radio"
            name="backgroundColor"
            value="white"
            class="hidden peer"
            required=""
          >
          <label
            for="background-color-white"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-white border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-100"
            v-model="background"
            type="radio"
            name="backgroundColor"
            value="gray-100"
            class="hidden peer"
          >
          <label
            for="background-color-gray-100"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-100 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-200"
            v-model="background"
            type="radio" 
            name="backgroundColor"
            value="gray-200"
            class="hidden peer"
          >
          <label
            for="background-color-gray-200"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-200 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-300"
            v-model="background"
            type="radio" 
            name="backgroundColor"
            value="gray-300"
            class="hidden peer"
          >
          <label
            for="background-color-gray-300"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-300 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-400"
            v-model="background"
            type="radio" 
            name="backgroundColor"
            value="gray-400"
            class="hidden peer"
          >
          <label
            for="background-color-gray-400"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-400 focus:border-gray-100 border border" />
          </label>
        </li>
      


        <li>
          <input
            id="background-color-gray-600"
            v-model="background"
            type="radio" 
            name="backgroundColor"
            value="gray-600"
            class="hidden peer"
          >
          <label
            for="background-color-gray-600"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-600 focus:border-gray-100 border" />
          </label>
        </li>

        <li>
          <input
            id="background-color-gray-800"
            v-model="background"
            type="radio" 
            name="backgroundColor"
            value="gray-800"
            class="hidden peer"
          >
          <label
            for="background-color-gray-800"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-800 focus:border-gray-100 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-black"
            v-model="background"
            type="radio"
            name="backgroundColor"
            value="black"
            class="hidden peer"
          >
          <label
            for="background-color-black"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-black focus:border-gray-100 border" />
          </label>
        </li>
      </ul>
    </div>

    <div class="  w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Alignement *** </label>
      <ul class="grid w-12/12  gap-6 md:grid-cols-8">
        <li>
          <input
            id="aligment-no"
            v-model="alignement"
            type="radio"
            name="hosting"
            value="-"
            class="hidden peer"
            required=""
            @change="onClickElement"
          >
          <label
            for="aligment-no"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-ban" />
          </label>
        </li>
        <li>
          <input
            id="aligment-left"
            v-model="alignement"
            type="radio"
            name="hosting"
            value="left"
            class="hidden peer"
            required=""
          >
          <label
            for="aligment-left"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-left" />
          </label>
        </li>
        <li>
          <input
            id="aligment-center"
            v-model="alignement"
            t
            type="radio"
            name="hosting"
            value="center"
            class="hidden peer"
          >
          <label
            for="aligment-center"  
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="fa-solid fa-align-center" />
          </label>
        </li>
        <li>
          <input
            id="aligment-right"
            v-model="alignement"
            t
            type="radio"
            name="hosting"
            value="right"
            class="hidden peer"
          >
          <label
            for="aligment-right"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-right" />
          </label>
        </li>
      </ul>
    </div>
    
    <div class="bg-orange-300">
      == {{ currentItem.class }} ==
    </div>
    <div class="bg-green-300">
      =size=  {{ size }} =size=
    </div>
    <div class="bg-green-300">
      =alignement=  {{ alignement }} =alignement=
    </div>
    <div class="bg-green-300">
      =background=  {{ background }} =background=
    </div>
  </div>
</template>

<script>

    export default {
        name: 'DeveloperStyle',
        props: {
            item: Object
        },
        data() {
            return ({
                currentItem: {},
                size: "-",
                alignement: '-',
                background: "-"
            })
        },
        watch: {
            alignement: function()
            {
           
                this.onClickElement();
            },
            size: function()
            {
            
                this.onClickElement();
            },
            background: function()
            {
             
                this.onClickElement();
            },
            item: function (val) {
             
                let item = val;

               

                this.currentItem = item;
               this.setValues();
            }
        },

        mounted() {

            let item = this.item;

            this.currentItem = item;
            this.setValues();

        },

        methods: {
            setValues: function( )
            {
 

                if (this.currentItem  == undefined)
                {
   
                    return;
                } 
                if (this.currentItem.class == undefined){
   
                  
                    return;
                } 
                this.size ='-';
                this.alignement ='-';
                this.background = '-';
                let items = this.currentItem.class.split(" ");
                for(let i = 0; i < items.length; i++)
                {
                

                    let item = items[i];
   
                    switch(item)
                    {
                        case 'w-2/12': this.size ='w-2/12'; break;
                        case 'w-4/12': this.size ='w-4/12'; break;
                        case 'w-6/12': this.size ='w-6/12'; break;
                        case 'w-8/12': this.size ='w-8/12'; break;
                        case 'w-10/12': this.size ='w-10/12'; break;
                        case 'w-12/12': this.size ='w-12/12'; break;
                        case 'w-full': this.size ='w-12/12'; break;

                        case 'justify-start': this.alignement ='left'; break;
                        case 'justify-center': this.alignement ='center'; break;
                        case 'justify-end': this.alignement ='right'; break;
                   
                        case 'bg-black': this.background ='black'; break;
                        case 'bg-white': this.background ='white'; break;
                        case 'bg-gray-100': this.background ='gray-100'; break;
                        case 'bg-gray-200': this.background ='gray-200'; break;
                   
                        case 'bg-gray-300': this.background ='gray-300'; break;
                        case 'bg-gray-400': this.background ='gray-400'; break;
                        case 'bg-gray-500': this.background ='gray-500'; break;
                        case 'bg-gray-600': this.background ='gray-600'; break;
                        case 'bg-gray-700': this.background ='gray-700'; break;
                        case 'bg-gray-800': this.background ='gray-800'; break;
                        case 'bg-gray-900': this.background ='gray-900'; break;
                  
                    }
                

                }
            },

            buildClass : function()
            {
                let cls="";
                switch(this.size)
                {
                    case 'w-2/12': cls += 'w-2/12 ';break;
                    case 'w-4/12': cls += 'w-4/12 ';break;
                    case 'w-6/12': cls += 'w-6/12 ';break;
                    case 'w-8/12': cls += 'w-8/12 ';break;
                    case 'w-10/12': cls += 'w-10/12 ';break;
                    case 'w-12/12': cls += 'w-full w-12/12 ';break;
                }

                switch(this.background)
                {
                    case 'black': cls += 'bg-black ';break;
                    case 'white': cls += 'bg-white ';break;
                    case 'gray-100': cls += 'bg-gray-100 ';break;
                    case 'gray-200': cls += 'bg-gray-200 ';break;
                    case 'gray-300': cls += 'bg-gray-300 ';break;
                    case 'gray-400': cls += 'bg-gray-400 ';break;
                    case 'gray-500': cls += 'bg-gray-500 ';break; 
                    case 'gray-600': cls += 'bg-gray-600 ';break;
                    case 'gray-700': cls += 'bg-gray-700 ';break;
                    case 'gray-800': cls += 'bg-gray-800 ';break;
                    case 'gray-900': cls += 'bg-gray-900 ';break;
                }

                switch(this.alignement)
                {
                    case 'left': cls += 'justify-start ';break;
                    case 'center': cls += 'justify-center ';break;
                    case 'right': cls += 'justify-end ';break;
                }

                return cls
            },
            onClickElement: function ( ) {

         
                let cls = this.buildClass();
            
                this.currentItem.class = cls;
                 this.$emit("update",this.currentItem)
            }
        }

    }
</script>
<template>
  <div
    class="   cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-choice-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Choix</label>
        <div class="w-full flex flex-row">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead
                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 "
                  >
                    Titre
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3"
                  >
                    Valeur
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3"
                  >
                    Défault
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3"
                  >
                    <span class="sr-only">Supprimer</span>
                    <span class="sr-only">Ajouter</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value,index) in values"
                  :key="value.title"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <input
                      v-model="values[index].title"
                      v-focus
                      type="text"
                      class="border-1 border-gray-600 py-1 w-full text-xs"
                    >
                  </th>
                  <td class="px-6 py-2">
                    <input
                      v-model="values[index].value"
                      type="text"
                      class="border-1 border-gray-600 py-1 w-full text-xs"
                    >
                  </td>


                  <td class="px-6 py-2">
                    <div class="flex items-center justify-center p-2     dark:hover:bg-gray-600">
                      <input
                        :id="`filter-radio-example-${index}`"
                        v-model="defaultChoice"
                        type="radio"
                        name="choice-radio"
                        :value="index"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        @change="onChangeDefault(index)"
                      >
                    </div>
                  </td>

                  <td class="px-6 pb-2 text-right flex flex-row items-center pt-3">
                    <div
                      class="font-medium  dark:text-red-500   mr-3 cursor-pointer"
                      :class="{'text-red-600 cursor-pointer' : (values.length > 1), 'text-gray-300 cursor-pointer' : (values.length < 2) }"
                      @click="removeLine(value,index)"
                    >
                      <i
                        class="fa-solid fa-circle-minus fa-lg"
                      />
                    </div>
                    <div
                      class="font-medium   dark:text-green-500  "
                      :class="{'text-green-600 cursor-pointer' : (values.length < 5), 'text-gray-300 cursor-pointer' : (values.length > 4) }"
                      @click="addLine(value,index)"
                    >
                      <i class="fa-solid fa-circle-plus fa-lg" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>






      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${element.id}`"
          v-model="data"
          type="text"
          class="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeData"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              description</label>
          </div>
          <div class="hidden flex items-center">
            <input
              id="inline-disabled-checkbox"
              disabled
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="inline-disabled-checkbox"
              class="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500"
            >Inline disabled</label>
          </div>
          <div class="w-full flex flex-row">
            <div class="ml-3 flex items-center mb-4 w-64">
              <label
                for="default-radio-1"
                class="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Type</label>

              <select
                id="radio-layout"
                v-model="layout"
                class="w-16 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @change="onChangeLayout"
              >
                <option value="automatic">
                  Automatique
                </option>
                <option value="1-0">
                  1 Colonne
                </option>
                <option value="1-1">
                  2 Colonnes 50/50
                </option>
                <option value="2-1">
                  2 Colonnes 66/33
                </option>
                <option value="1-2">
                  2 Colonnes 33/66
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Contraintes</label>
        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-checked-checkbox"
              v-model="isRequired"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRequired"
            >
            <label
              for="inline-checked-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Obligatoire</label>
          </div>
        </div>
      </div>


      <div class="flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center">
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'RadioTableFormComponentDetails',
        props: {
            element: Object
        },
        data() {

            return {
                title: "",
                format: "",
                defaultChoice: "",
                description: "",
                data: "",
                layout: "1-0",
                values: [],
                isRequired: false,
                isTitleDisplayed: true,
                isDescriptionDisplayed: false

            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title;
                this.format = this.element.format;
                this.isRequired = this.element?.isRequired;
                this.isTitleDisplayed = this.element?.isTitleDisplayed;
                this.isDescriptionDisplayed = this.element?.isDescriptionDisplayed;
                this.data = this.element?.data;
                this.defaultChoice = this.element?.default;
                this.description = this.element?.description;

                this.layout = this.element.layout
                if (this.layout == undefined) this.layout = "1-0"
            }
        },
        mounted() {
            this.title = this.element?.title
            this.format = this.element.format
            this.isRequired = this.element?.isRequired;
            this.isTitleDisplayed = this.element?.isTitleDisplayed;
            this.isDescriptionDisplayed = this.element?.isDescriptionDisplayed;

            this.layout = this.element.layout
            if (this.layout == undefined) this.layout = "1-0"

            this.data = this.element?.data;
            this.values = this.element?.values;
            this.defaultChoice = this.element?.default;
            this.description = this.element?.description;


        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.element.id })
            },
            addLine: function (value, index) {
                if (this.values.length > 4) return;
               

                this.values.splice(index + 1, 0, {
                    title: "",
                    value: "",
                    default: ""
                });
            },
            removeLine: function (value, index) {
                if (this.values.length < 2) return;
             
                this.values.splice(index, 1);
            },
            onChangeDefault: function (index) {
                this.$emit("update", { att: 'default', value: index })
            },
            onChangeLayout: function () {
                this.$emit("update", { att: 'layout', value: this.layout })
            },
            onChangeData: function () {
                this.$emit("update", { att: 'data', value: this.data })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("update", { att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("update", { att: 'isDescriptionDisplayed', value: this.isDescriptionDisplayed })
            },

            onChangeIsRequired: function () {
                this.$emit("update", { att: 'isRequired', value: this.isRequired })
            },
            onInputFormat: function () {
                this.$emit("update", { att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("update", { att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("update", { att: 'description', value: this.description })
            }
        }
    }
</script>
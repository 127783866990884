<template>
  <div class="w-full flex items-center justify-center">
    <div class="mt-2 mb-1 w-full relative">
      <label
        :for="`${id}`"
        class="text-left block mb-1 text-sm font-semibold text-gray-800 dark:text-white"
      >
        {{ label }}
      </label>
      <div class="relative w-full flex justify-start items-center">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          :id="`date-${id}`"
          v-model="output"
          datepicker
          datepicker-autohide
          datepicker-format="dd/mm/yyyy"
          type="text"  
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Sélectionner date"
        >
      </div>
    </div>
  </div>
</template>
<script>
    import Datepicker from 'flowbite-datepicker/Datepicker';


    export default {
        name: 'ContentDate',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,

           
            value: String,
            config: Object
        },
        data() {
            return {
                output: ""
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        },
        mounted() {
            const datepickerEl = document.getElementById('date-' + this.id);
            new Datepicker(datepickerEl, {
                format: "dd/mm/yyyy",
                autohide: true,
                rangePicker: true,
                title: "Choisir une date"
            });
            this.output = this.value;
        },
        methods: {

            formatIso(d) {
                let s = d.split("/")
                return s[2] + "-" + s[1] + "-" + s[0]
            },
            getData() {
                var x = document.getElementById("date-" + this.id).value;

                return this.formatIso(x)
            }
        }
    }
</script>
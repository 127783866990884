<template>
  <div class="relative   overflow-y-auto bg-gray-50  lg:ml-4 dark:bg-gray-900">
    <div class="relative  mt-24 ml-12 px-6">
      <div class="  dark:border-gray-50 mt-2 ">
        *******
        <h2 class="px-2 text-left mb-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">
          {{ displayTitle() }}
        </h2>
        <h4 class="px-2 text-left mb-2 text-xl tracking-tight font-semibold text-gray-600 dark:text-white">
          {{ config.subTitle && config.subTitle[lg] }}
        </h4>
        <Diagram
          :config="config"
          :records="records"
        />
      </div>
    </div>
  </div>
</template>
<script>
    const MAX_ITEMS = 10;
   // import { Modal } from 'flowbite';
    import Diagram from '@/components/DiagramComponent.vue'
    //  import service from "@/services/FullService.vue"


    import { mapGetters } from "vuex";
    export default {
        name: 'DiagramView',
        components: {
            Diagram
        },
     
        data() {
            return {
                currentTab: 0,
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                config: {},
                globalQuery: '',
                category: null,
                productTypes: [],
                records: [],
                current_page: 2,
                lastQuery: "",
                offset: 0,
                filters: {},
                limit: MAX_ITEMS,
                lg: "FR",
                title: "",
                filterList: [],
                people: [],

                selectProductTypeModal: null,
                selectProductModal: null,
                button: ""
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
      
        watch:
        {
            '$route.params.id': async function (val) {
                let filters = {}
                this.category = "";
                //filters['category'] = config.filters;
                this.reloadPage(val, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user.tenant': async function () {
                this.isLoading = true;
                let filters = {}
                this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);

            }
        },
        async mounted() {
            // await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {})
 




            this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {});

        },
        methods: {



            displayTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "FR"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            displaySubTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "EN"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            async reloadPage(/*id, offset, limit, filter, level*/) {
 

            }
        }
    }
</script>
<template>
  <div class="flex flex-row w-full items-center">
    <div
      class="flex flex-row w-full  items-center"
      @dblclick="onClickFolder(folder)"
    >
      <div class="w-6 mr-3">
        <i class="fa-solid fa-folder  fa-xl" />
      </div>

      <h2
        class="flex-grow  text-sm font-medium leading-tight text-gray-900 dark:text-white text-clip overflow-hidden  justify-start"
      >
        <p
          v-show="edit==false"
          class="flex items-center overflow-hidden text-ellipsis h-6 text-left"
        >
          {{ title }}
        </p>
        <input
          v-show="edit==true"
          :id="'title-folder-'+folder.id"
          v-model="newTitle"
          h-6
          type="text"
          class="form-control"
          @keydown.enter="updateTitle"
          @keydown.esc="cancelEditing"
        >
      </h2>

      <button
        :id="`folder-${folder.id}-media-button`"
        class="flex items-center justify-center h-6 w-6 rounded-full inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-300 hover:bg-gray-500 :text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
        type="button"
      >
        <i class="fa-solid fa-ellipsis-vertical  fa-xl" />
      </button>
      <div
        :id="`folder-${folder.id}-media-dropdown`"
        class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
      >
        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
          <li>
            <div
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              @click="onClickFolder()"
            >
              Ouvrir
            </div>
          </li>
          <li>
            <div
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              @click="onFolderRename(folder)"
            >
              Renommer
            </div>
          </li>
          <li>
            <div
              class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white border-t-2"
              @click="onDelete(folder)"
            >
              Placer dans la corbeille
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>




<script>

    import { Dropdown } from "flowbite";


    export default {
        name: 'GridComponentFolder',
        props: {
            folder: Object
        },
        data() {
            return {
                title: "",
                newTitle: "",
                edit: false,
                options: {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                   // onHide: () => {
           
                   // },
                   // onShow: () => {
          
                   // },
                   // onToggle: () => {
                 
                   // }
                },
                dropdown: null
            }
        },
        watch: {
            folder: function () {
 

                if (this.folder?.id != undefined) {
                    this.title = this.folder.name;



                }
            }
        },
        mounted() {

            if (this.folder?.id != undefined) {
                this.title = this.folder.name;

             
                const $targetEl = document.getElementById("folder-" + this.folder.id + "-media-dropdown");
              
                const $triggerEl = document.getElementById("folder-" + this.folder.id + "-media-button");
             

                if ($triggerEl != undefined)
                    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);
                
            }



        },
        methods:
        {

            onClickFolder: function ( ) {

                if (this.edit) return;

                this.$emit("changeFolder", {   id: this.folder.id, name: this.folder.name })

            },

 

            onDelete: function () {
                this.$emit("deleteFolder", { tag: 'images', type: "folder", name: this.title, id: this.folder.id })
            },

            cancelEditing(


            ) { this.edit = false; },

            updateTitle() {
                this.edit = false;
                this.title = this.newTitle;
             
                this.$emit("updateFolderName", { tag: 'images', type: "folder", name: this.title, id: this.folder.id })

            },


            onFolderRename: function (item) {
         
                this.edit = true;
                this.newTitle = this.title;
                this.dropdown.hide()
                this.$nextTick(function () {
                    document.getElementById('title-folder-' + item.id).focus()
                })
            },
        }
    }
</script>
<template>
  <div class=" mb-3 mt-3  cursor-grab list-group mt-2 w-full     flex items-start      flex items-start        flex flex-row justify-center">
    <div class="flex flex-col items-start w-full text-white pl-2">
      <div
        v-if="properties?.title"
        class="w-full   text-sm text-left text-gray-700  font-bold"
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option.description"
        class="mt-2  w-full h-6 text-xs text-left text-gray-500 font-normal"
      >
        {{ properties?.description }}  
      </div>

      <div class="w-full  mt-2  ">
        <div class="w-full flex flex-row text-gray-800 text-xs ">
          <div
            v-for="item in items"
            :key="item.id"
            class="   px-2 shadow mx-1 rounded-lg flex flex-col items-center justify-center bg-white"
          >
            <div class="flex flex-col justify-center items-center h-24 w-24">
              <div class=" h-10 w-10 pb-2 bg-gray-300 flex items-center justify-center">
                <i
                  class="fa-solid fa-2xl  "
                  :class="item.icon"
                />
              </div>
                           
              <span class="mt-2 text-xs font-bold">Item {{ item.id }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
                items: [
                    { id: 1, icon: 'fa-user' },
                    { id: 2, icon: 'fa-user' },
                    { id: 3, icon: 'fa-user' },
                    { id: 4, icon: 'fa-user' },
                    { id: 5, icon: 'fa-user' }


                ]
            }
        },
        computed:
        {
          
        },
        methods:
        {
            addItem: function()
            {
                this.$emit("add", {type: 'avatar', id: this.properties.id} )
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
<template>
  <div class="mt-2 mb-2 w-full justify-start text-left relative">
    <ContentTimelineModal
      :phase="phase"
      @hide="hideModal"
    />
 

    <ol class="relative border-l border-gray-200 dark:border-gray-700 justify-start text-left">
      <li
        v-for="event in events"
        :key="event.id"
        class="  w-full flex  "
      >
        <div class="  w-8/12 ml-6">
          <span
            class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900"
          >
            <svg
              aria-hidden="true"
              class="w-3 h-3 text-blue-800 dark:text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <h3
            class="flex justify-start text-left items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white"
          >
            {{ event.title[lg] }}

            <span
              v-if="event.warning != undefined"
              class="bg-orange-100 text-orange-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-orange-900 dark:text-orange-300 ml-3"
            >
              <i class="fa-solid fa-triangle-exclamation mr-1" />
              {{ event.warning[lg] }}
            </span>

            <span
              v-if="event.danger != undefined"
              class="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 ml-3"
            >{{ event.danger }}</span>
          </h3>
          <time
            class="justify-start text-left block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
          >

            {{ formatDate(event.timestamp) }}
          </time>
          <p
            class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400"
            v-html="event.description[lg]"
          />
          <div
            v-if="event.link != null"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
            @click="showModal(event.step)"
          >
            <i class="fa-solid fa-circle-info mr-2" />
           Controls List
          </div>
          <div class=" mb-10" />
        </div>
        <div class="w-4/12 bg-gray-400 w-96" />
      </li>
    </ol>
  </div>
</template>
<script>
    import { Modal } from 'flowbite'

    import ContentTimelineModal from "@/components/content/lib/ContentTimelineModal.vue"
    export default {
        name: 'TimelineComponent',
        components: { ContentTimelineModal },
        props: {
            id: String,
            label: String,
            record: Object,
            required: Boolean,
            value: String,
            config: Object
        },
        data() {
            return {
                output: "",
                events: [],
                lg: "FR",
                modal: null,
                phase: "shipment",
                result: [
                    {
                        id: "111",
                        date: "13 Mars 2023",
                        title: {
                            "EN": "Shipment",
                            "FR": "Expéditon"
                        },
                        description: {
                            "EN": "Shipment",
                            "FR": "L'article a été prélévé le 13 Mars 2023 par GMT avec le numéro de suivi BE021342. La température de la palette été controlée conforme ainsi que celle du Camion. L'agent n'a pas constaté de problème de propreté dans le camion."
                        },
                        status: "OK",
                        warning: {
                            "EN": "Missing documents",
                            "FR": "Documents de transport manquant"
                        },
                        step: "shipment",
                        link: ""
                    },
                    {
                        id: "112",
                        date: "11 Mars 2023",
                        title: {
                            "EN": "Packing",
                            "FR": "Emballage"
                        },
                        description: {
                            "EN": "Packing Packing Packing Packing Packing Packing",
                            "FR": "L'emballage a été effectué sans problème de conformité. Pas d'objet intrus détecté"
                        },
                        status: "OK",
                        warning: null,
                        step: "packing",
                        link: ""
                    },
                    {
                        id: "113",
                        date: "14 février 2023",
                        title: {
                            "EN": "Packing",
                            "FR": "Affinage"
                        },
                        description: {
                            "EN": "Packing Packing Packing Packing Packing Packing",
                            "FR": "Les contôles de température, pH ont été effectués et pas de non conformité détecté."
                        },
                        status: "OK",
                        warning: null,
                        step: "refining",
                        link: ""
                    },
                    {
                        id: "114",
                        date: "14 février 2023",
                        title: {
                            "EN": "Salting",
                            "FR": "Salage"
                        },
                        description: {
                            "EN": "Packing Packing Packing Packing Packing Packing",
                            "FR": "Salage dans bain de saumur dans sel avec numèro de lot 23232424."
                        },
                        status: "OK",
                        warning: null,
                        step: "salting",
                        link: ""
                    },
                    {
                        id: "115",
                        date: "12 Février 2023",
                        title: {
                            "EN": "Making",
                            "FR": "Fabrication"
                        },
                        description: {
                            "EN": "Packing Packing Packing Packing Packing Packing",
                            "FR": "Pas de non conformité détecté."
                        },
                        status: "OK",
                        warning: null,
                        step: "inbound",
                        link: ""
                    },
                    {
                        id: "118",
                        date: "11 février 2023",
                        title: {
                            "EN": "Pasteurisation",
                            "FR": "Pasteurisation"
                        },
                        description: {
                            "EN": "Packing Packing Packing Packing Packing Packing",
                            "FR": "Cycle de pasteurisation effectué. Tous les contrôles ont été négatifs."
                        },
                        status: "OK",
                        warning: null,
                        step: "inbound",
                        link: ""
                    },
                    {
                        id: "119",
                        date: "10 Février 2023",
                        title: {
                            "EN": "Packing",
                            "FR": "Réception du lait"
                        },
                        description: {
                            "EN": "Packing Packing Packing Packing Packing Packing",
                            "FR": "Réception du lait provenant de la Ferme de La Tremblaye. Les analyses n'ont pas révélées de présence de pathogènes."
                        },
                        step: "inbound",
                        status: "OK",
                        warning: null,
                        link: ""
                    }
                ]
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            },
            record: function () {
                //  this.events = this.result
                this.events = this.record?.events;
            }
        },
        mounted() {
            this.output = this.value;

            //     this.events = this.result
            this.events = this.record?.events;
            const $targetEl = document.getElementById('large-modal-pv');
            /*   const options = {
                   placement: 'bottom-right',
                   backdrop: 'dynamic',
                   backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
                   closable: true
               }*/
            this.modal = new Modal($targetEl);
            //    data-modal-target="large-modal-pv" 
            //        data-modal-toggle="large-modal-pv"
        },
        methods: {
            formatDate(val) {
                if (val == undefined) return "";
                if (val == "") return "";


                let date = val.split("T")
                if (date.length != 2) {
                    date = val.split(" ")
                }

                let d = date[0].split("-")


                return d[2] + "/" + d[1] + "/" + d[0] + " " + date[1];

            },
            hideModal(phase) {
                this.phase = phase
                this.modal.toggle()
            },
            showModal(phase) {
                this.phase = phase
                this.phase = "inbound"
                this.modal.toggle()
            },
            getData() {
                return this.output
            }
        }
    }
</script>
<template>
  <div class=" w-full py-6 flex flex-col items-start  ">
    <div class="mb-6 flex flex-col items-start justify-start w-full">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">

        Titre du Champs


      </label>

      <div
        v-if="currentItem.label != undefined"
        class="flex flex-col items-center   justify-start"
      >
        <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
          <div
            class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
          >
            <div class="text-sm  text-gray-50">
              FR
            </div>
          </div>
          <input
            v-model="currentItem.label.FR"
            class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            required
          >
        </div>
        <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
          <div
            class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
          >
            <div class="text-sm  text-gray-50">
              EN
            </div>
          </div>
          <input
            v-model="currentItem.label.EN"
            class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            required
          >
        </div>
        <div class="flex justify-start flex-row w-full mt-2 ml-6 items-center">
          <input
            id="checked-checkbox"
            v-model="currentItem.label.displayTitle"
            type="checkbox"
            value=""
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          >
          <label
            for="checked-checkbox"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Afficher le
            titre</label>
        </div>
      </div>
    </div>
    <div class="mb-3 flex flex-col items-start justify-start  w-full">
      <label class="mt-2 text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white w-full">
        Type de valeurs </label>
      <div class="flex flex-row justify-start items-center w-full ">
        <div class="flex flex-row items-center w-96">
          <input
            id="default-radio-1"
            type="radio"
            :checked="currentItem.type=='select'"
            name="default-radio"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @click="SelectType('select')"
          >
          <label
            for="default-radio-1"
            class="  ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Prédéfini</label>
        </div>
        <div class="flex flex-row items-center w-full">
          <input
            id="default-radio-2"
            type="radio"
            :checked="currentItem.type=='selectCollection'"
            name="default-radio"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @click="SelectType('selectCollection')"
          >
          <label
            for="default-radio-2"
            class="  ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Enregistrements</label>
        </div>
      </div>
    </div>


    <div
      v-if="currentItem.type=='select'"
      class="mb-6 flex flex-col items-start justify-start w-full"
    >
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Liste Prédéfinie </label>
      <div class="flex flex-row justify-start items-center w-96">
        <select
          id="default"
          v-model="currentItem.source"
          class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option
            v-for="choice in predefined_records"
            :key="choice.id"
            :value="choice.id"
          >
            {{ choice.value ? choice.value[lg] : choice.id }}
          </option>
        </select>
      </div>
    </div>


    <div
      v-if="currentItem.type=='selectCollection'"
      class="mb-6 flex flex-col items-start justify-start w-full"
    >
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Liste Enregistrements</label>
      <div class="flex flex-row justify-start items-center w-96">
        <select
          id="default"
          v-model="currentItem.collection"
          class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option
            v-for="choice in collections"
            :key="choice.id"
            :value="choice.id"
          >
            {{ choice.value ? choice.value[lg] : choice.id }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="currentItem.type=='selectCollection'"
      class="mb-6 flex flex-col items-start justify-start w-full"
    >
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Filtre </label>
      <div class="flex flex-row justify-start items-center w-96">
        <textarea
          v-model="currentItem.filter"
          type="text"
          class="   border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
    </div>


    <div class="mb-6 flex flex-col items-start justify-start w-full w-96">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Nom de l'attribute
      </label>
      <input
        v-model="currentItem.data"
        type="text"
        class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        required
      >
    </div>
  </div>
</template>

<script>
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'DeveloperItemSelect',
        props: {
            item: Object
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        data() {
            return ({
                predefined_records: [],
                collections: [],
                lg: "FR",
                select_type: 1,
                currentItem: {}
            })
        },
        watch: {
            item: function (val) {
                let item = val;
                if (item.label == undefined) item.label = {}
                if (item.label.FR == undefined) item.label.FR = ""
                if (item.label.EN == undefined) item.label.EN = ""
                if (item.label.displayTitle == undefined) item.label.displayTitle = true


                this.currentItem = item;

            }
        },
        async mounted() {

            let item = this.item;
            if (item.label == undefined) item.label = {}
            if (item.label.FR == undefined) item.label.FR = ""
            if (item.label.EN == undefined) item.label.EN = ""
            if (item.label.displayTitle == undefined) item.label.displayTitle = true


            this.currentItem = item;
            let responsePredefined = await service.getData("select-values", this.user?.token, { category: "value-category" })
            if (responsePredefined != undefined)
                this.predefined_records = responsePredefined.records;

            let responseCollection = await service.getData("select-values", this.user?.token, { category: "value-collection" })
            if (responseCollection != undefined)
                this.collections = responseCollection.records;
        },
        methods: {
            SelectType: function (type) {
                this.currentItem.type = type;

                //if (type == "select") {
         
                //}
                //if (type == "selectCollection") {
        
                    // this.currentItem.collection =
                //}
            }
        }

    }
</script>
<template>
  <div class="mt-2 mb-2 w-full relative">
    <label
      for="default"
      class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >
      <span class="font-semibold text-gray-800  ">{{ label }}</span>

      <div class="w-full flex flex-row pt-2">
        <div
          class="inline-flex rounded-md shadow-sm"
          role="group"
        >
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            @click="selectLanguage('fr')"
          >
            Fr
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b  border-r border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            @click="selectLanguage('en')"
          >
            En
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-r  border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            @click="selectLanguage('de')"
          >
            De
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            @click="selectLanguage('sp')"
          >
            Es
          </button>

          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            @click="selectLanguage('it')"
          >
            It
          </button>
        </div>
        <div class="w-full flex justify-end">
          <div
            v-if="selectedLangage != 'fr'"
            class="flex items-center"
          >
            <input
              id="checked-checkbox"
              :checked="getCheckStatus"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @click="onClickCheck"
            >
            <label
              for="checked-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Traduction
              Automatique</label>
          </div>
          <button
            v-if="multilangual != false"
            type="button"
            class=" hidden  flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            @click="onClickTranslate"
          >
            <i class="fa-solid fa-flag  mr-2" />
            Translate
          </button>
        </div>

      </div>

    </label>

    <Editor
      v-model="output[selectedLangage]"
      class=" mb-4   w-full "
      api-key="e78c7vohupbm5oif9x9c7a1zqblpz2qnwom5rd28gyhrm4et"
      :init="initEdit"
      initial-value="output['fr']"
      api-key1="no-api-key"
    />

    <div
      class="hidden w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
    >
      <div class="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
        <div class="flex flex-wrap items-center divide-gray-200 sm:divide-x dark:divide-gray-600">
          <div class="flex items-center space-x-1 sm:pr-4">
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Attach file</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Embed map</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Upload image</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Format code</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Add emoji</span>
            </button>
          </div>
          <div class="flex flex-wrap items-center space-x-1 sm:pl-4">
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Add list</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Settings</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Timeline</span>
            </button>
            <button
              type="button"
              class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Download</span>
            </button>
          </div>
        </div>
        <button
          type="button"
          data-tooltip-target="tooltip-fullscreen"
          class="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Full screen</span>
        </button>
        <div
          id="tooltip-fullscreen"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          Show full screen
          <div
            class="tooltip-arrow"
            data-popper-arrow
          />
        </div>
      </div>
      <div
        style="min-height:250px"
        class="px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800"
      />
    </div>
  </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue";

    import "tinymce/tinymce";
    import "tinymce/themes/silver";
    import "tinymce/icons/default";
    import "tinymce/skins/ui/oxide/skin.css";
    import { compile, convert } from 'html-to-text';

    import Editor from '@tinymce/tinymce-vue';

    export default {
        name: 'ContentEditor',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            config: Object,
            value: Object,
            multilangual: Boolean
        },
        async mounted() {
            this.options["fr"] = true;
            this.options["en"] = true;
            this.options["sp"] = true;
            this.options["de"] = true;
            this.options["it"] = true;
            if (this.value != undefined) {

                if (typeof this.value == 'object') {
                    this.output['fr'] = this.value.fr;
                    this.output['en'] = this.value.en;
                    this.output['sp'] = this.value.sp;
                    this.output['it'] = this.value.it;
                    this.output['de'] = this.value.de;
                }
                else
                    this.output['fr'] = this.value;

                if (this.value.options != undefined) {
                    this.options["fr"] = this.value.options.fr != undefined ? this.value.options.fr : true;
                    this.options["de"] = this.value.options.de != undefined ? this.value.options.de : true;
                    this.options["en"] = this.value.options.en != undefined ? this.value.options.en : true;
                    this.options["sp"] = this.value.options.sp != undefined ? this.value.options.sp : true;
                    this.options["it"] = this.value.options.it != undefined ? this.value.options.it : true;
                }

            }
            else {
                this.output['fr'] = ""
            }
            // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
            // console.log(Editor)

        },

        methods: {

            async onClickCheck() {
                this.options[this.selectedLangage] = !this.options[this.selectedLangage];


                if (this.options['en'] && (this.selectedLangage == 'en')) {
                    let responseEN = await service.translate("EN", this.output['fr'], this.user?.token)
                    //this.selectedLanguage
                    this.output['en'] = responseEN.text[0].text
                }
                if (this.options['de'] && (this.selectedLangage == 'de')) {
                    let responseDE = await service.translate("DE", this.output['fr'], this.user?.token)
                    this.output['de'] = responseDE.text[0].text
                }
                if (this.options['it'] && (this.selectedLangage == 'it')) {
                    let responseIT = await service.translate("IT", this.output['fr'], this.user?.token)
                    this.output['it'] = responseIT.text[0].text
                }


                if (this.options['sp'] && (this.selectedLangage == 'sp')) {
                    let responseSP = await service.translate("ES", this.output['fr'], this.user?.token)
                    if (responseSP) if (responseSP.text)
                        this.output['sp'] = responseSP.text[0].text
                }


            },
            selectLanguage(lang) {

                this.selectedLangage = lang;

                this.onClickTranslate()
                // this.output[lang] = "kkkkk"
            },
            async TranslateAuto() {
                if ((this.options['en']) && (this.selectedLangage == 'en')) {
                    let responseEN = await service.translate("EN", this.output['fr'], this.user?.token)
                    //this.selectedLanguage
                    this.output['en'] = responseEN.text[0].text
                }
                if ((this.options['de']) && (this.selectedLangage == 'de')) {
                    let responseDE = await service.translate("DE", this.output['fr'], this.user?.token)
                    this.output['de'] = responseDE.text[0].text
                }
                if ((this.options['it']) && (this.selectedLangage == 'it')) {
                    let responseIT = await service.translate("IT", this.output['fr'], this.user?.token)
                    this.output['it'] = responseIT.text[0].text

                }

                if ((this.options['sp']) && (this.selectedLangage == 'sp')) {
                    let responseSP = await service.translate("ES", this.output['fr'], this.user?.token)
                    if (responseSP) if (responseSP.text)
                        this.output['sp'] = responseSP.text[0].text
                }

            },
            async onClickTranslate() {
                if ((this.options['en']) && (this.selectedLangage == 'en')) {
                    let responseEN = await service.translate("EN", this.output['fr'], this.user?.token)
                    //this.selectedLanguage
                    this.output['en'] = responseEN.text[0].text
                }
                if ((this.options['de']) && (this.selectedLangage == 'de')) {
                    let responseDE = await service.translate("DE", this.output['fr'], this.user?.token)
                    this.output['de'] = responseDE.text[0].text
                }
                if ((this.options['it']) && (this.selectedLangage == 'it')) {
                    let responseIT = await service.translate("IT", this.output['fr'], this.user?.token)
                    this.output['it'] = responseIT.text[0].text

                }

                if ((this.options['sp']) && (this.selectedLangage == 'sp')) {
                    let responseSP = await service.translate("ES", this.output['fr'], this.user?.token)
                    if (responseSP) if (responseSP.text)
                        this.output['sp'] = responseSP.text[0].text
                }

            },
            getData() {
                // await TranslateAuto();
            
                if (this.config.data == "name")
                {
                    const options = {
                    wordwrap: 130,
                    
                };

                const compiledConvert = compile(options);
                this.output.fr = convert(this.output.fr, compiledConvert)
                this.output.en = convert(this.output.en, compiledConvert)
                this.output.sp = convert(this.output.sp, compiledConvert)
                this.output.it = convert(this.output.it, compiledConvert)

                }

              
               
               
                let data = this.output;
                data.options = this.options;
                return this.output;
            },
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },

            getCheckStatus: function () {
                return this.options[this.selectedLangage]
            }
        },
        components: { Editor },
        data() {
            return {

                initEdit: {
                    width: '100%',
                    height: '300px',
                    menubar: false,
                    paste_remove_styles_if_webkit: false,

                    setup: function ( /*editor*/) {
                        //      console.log("TINY TINY TINY TINY TINY")
                        //      console.log("TINY TINY TINY TINY TINY")
                        //      console.log("TINY TINY TINY TINY TINY")
                        //      console.log("TINY TINY TINY TINY TINY")
                        //      console.log(editor)
                        /*     editor.addButton('mybutton', {
                                 type: 'menubutton',
                                 text: 'My split button',
                                 icon: false,
                                 onclick: function () {
                                 }
                             });
     */
                        /*  ed.addButton('mybutton', {
                              title: 'My button',
                              text: 'Insert variable',
                              onclick: function () {
                                  ed.plugins.variables.addVariable('account_id');
                              }
                          });
  
                          ed.on('variableClick', function (e) {
                          
                         //     console.log('click', e);
                          });*/
                    },

                    plugins: [
                        'autolink',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'preview', 'export', 'code'
                    ],
                    toolbar:
                        'undo redo | casechange blocks | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | fullscreen preview export code | \
                            bullist numlst checklist outdent indent | removeformat | a11ycheck   table help'
                }
                ,
                output: {
                    "fr": "",
                    "sp": "",
                    "it": "",
                    "de": "",
                    "en": ""
                },
                options: {
                    'en': true,
                    'de': true,
                    'it': true,
                    'sp': true
                },
                choices: [],
                lg: "FR",
                selectedLangage: 'fr',
                content_editor: ""
            }
        },
        watch: {
            value: function (val) {

                this.output.fr = val?.fr;
                this.output.en = val?.en;
                this.output.de = val?.de;
                this.output.sp = val?.sp;
                this.output.it = val?.it;



            }
        }
    }
</script>

<style>
    .tox .tox-promotion-link {
        display: none !important;
    }
</style>
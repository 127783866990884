<template>
  <draggable
    ghost-class="ghost"
    class="  w-full  border-0 border-solid items-start flex flex-col border-gray-300 px-6 py-2"
    :list="lines"
    item-key="id"
    :move="emitter"
    @start="drag=true"
    @end="drag=false"
  >
    <template #item="{element}">
      <div
        class=" mb-1 cursor-pointer list-group  w-full  bg-gray-50   rounded-lg   dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
      >
        <div
          class="flex flex-col items-start w-full  pl-6"
          :class="{'bg-red-300': ( parent == 'dYES')}"
        >
          <div class="   flex flex-row items-start w-full ">
            <div
              class="w-96 flex flex-col items-center"
              @click="onClick(element)"
            >
              <label class=" w-full text-left block   text-sm font-bold text-gray-900 dark:text-white">
                <div
                  class="flex flex-row w-full  items-center cursor-pointer shadow  hover:bg-gray-200"

                  :class="{'bg-blue-100': selectedItemId ==element.id}"
                >

                  <div class="w-full  "> <i
                    class=" mr-2 "
                    :class="getIcon(element)"
                  /> {{ element.name.FR }}</div>
                  <div class="flex flex-row pr-2 items-center   hidden ">
                                     
                    <span class=" ">{{ selectedItemId }}</span>
                  </div>
                                    
                </div>
              </label>
              <div v-if="element.lines != undefined">
                <nested-draggable
                  parent="false"
                  :lines="element.lines"
                  :selected-item-id="selectedItemId"
                  @move="emitter"
                  @click-item="onClickItem"
                />
              </div>
            </div>
          </div>
          <div class="hidden flex flex-row items-center w-full   h-6 pr-4">
            <hr class="w-full h-1 mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700">
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>
<script>
    import draggable from "vuedraggable";

    export default {
        name: "NestedDraggable",
        components: {
            draggable
        },
        props: {
            parent: String,
            selectedItemId: String,
            lines: {
                required: true,
                type: Array
            }
        },
        methods:
        {
            getIcon: function (el) {
             

                if (el.raw.type == "date") return 'fa-solid fa-calendar'
                if (el.raw.type == "time") return 'fa-solid fa-clock'
                if (el.raw.type == "date-time") return 'fa-solid fa-calendar-days'
                if (el.raw.type == "text") return 'fa-solid fa-font'
                if (el.raw.type == "badge") return 'fa-solid fa-tags'
                if (el.raw.type == "image-title-subtitle") return 'fa-solid fa-image'
                if (el.raw.type == "") return 'fa-solid fa-square-check'
                if (el.raw.type == "check") return 'fa-solid fa-square-check'
         
                if (el.type == "section") return 'fa-solid fa-table-cells-large'
                if (el.type == "col") return 'fa-solid fa-table-columns'
                if (el.type == "form") return 'fa-solid fa-align-justify'
                if (el.type == "tab") return 'fa-solid fa-indent'

                if (el.raw.table == "text") return 'fa-solid fa-table-list'
             
              
                if (el.raw.type == "blank") return 'fa-regular fa-square'
                if (el.raw.type == "image-upload") return 'fa-regular fa-image'
                if (el.raw.type == "file-list") return 'fa-regular fa-file'
  
                if (el.raw.table == "text") return 'fa-solid fa-list-check'
                if (el.raw.type == "note") return 'fa-regular fa-note-sticky'
                if (el.raw.type == "switch") return 'fa-solid fa-toggle-on'
                if (el.raw.type == "composer") return 'fa-solid fa-list-ol'

                if (el.raw.type == "compliant") return 'fa-solid fa-check'
                if (el.raw.type == "message") return 'fa-solid fa-message'
                if (el.raw.type == "display") return 'fa-solid fa-eye'
                if (el.raw.type == "title") return 'fa-solid fa-heading'
                
                if (el.raw.type == "timeline") return 'fa-solid fa-timeline'
                 

          

                if (el.raw.type == "qrcode") return 'fa-solid fa-qrcode'
                if (el.raw.type == "label") return 'fa-solid fa-ticket'
                if (el.raw.type == "tiny-table") return 'fa-solid fa-border-all'
                if (el.raw.type == "image-upload") return 'fa-regular fa-image'

            },
            emitter(value) {

                this.$emit("move", value);
            },
            onClickItem: function (item) {
               
                this.$emit("clickItem", item)
            },
            onClick: function (item) {
           
                event.stopImmediatePropagation()

                this.$emit("clickItem", item)
                return true;
            }
        }
    };
</script>
<style scoped>
    .dragArea {
        min-height: 50px;
        outline: 1px dashed;
    }
</style>
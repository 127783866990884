<template>
  <nav
    v-if="user != undefined"
    class="fixed top-0 left-0 z-40 w-full bg-gray-100 border-b border-gray-200  "
  >
    <div class="pl-3 pr-6 py-1.5">
      <div class="flex items-center justify-between">
        <div
          class="flex items-center justify-start cursor-pointer"
          @click="back"
        >
          <div class="flex ml-2 md:mr-24">
            <i class="fa-solid fa-arrow-right-to-bracket rotate-180 mr-2" />
            <span class="self-center text-sm font-semibold   whitespace-nowrap dark:text-white">
              Retour 
            </span>
          </div>
        </div>

        <div
          class="pl-10     "
          style="min-width: 600px"
        >
          *{{ record?.title }}*{{ record?.subTitle }}*
        </div>


        <div class="flex items-center">
          <div class="flex items-center ml-3">
            <Badge type="pink">
              Change
            </Badge>
            <button
              class="px-3  py-1.5 text-sm font-normal text-white bg-blue-700 ring-0 rounded-lg border border-blue-700   focus:outline-none   "
              @click="save"
            >
              {{ common_labels['LABEL_SAVE'][lg] }}
              <span class="sr-only">
                {{ common_labels['LABEL_SAVE'][lg] }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>


    import logo from "@/assets/logo.png"
    import { mapGetters } from "vuex";
    import { Badge } from 'flowbite-vue'

    import labels from "@/assets/lg/common.json"

    export default {
        name: 'TopNavBar',
        props: {
            msg: String,
            record : Object
        },
        async mounted() {



            if (this.user == undefined) {

                this.$router.push("/login");
                return;
            }




        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {

            save() {
                this.$emit("save")
            },

            back() {
                this.$emit("exit")
            }

        },
        components: { Badge },
        data: function () {
            return {
                common_labels: labels,
                lg: "FR",

                code: "",




                logo: logo
            };
        },
    }
</script>
<template>
  <div
    id="create-postal-address-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
  >
    <div class="relative w-full h-full max-w-3xl p-4 md:h-auto ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  ">
        <!-- Modal header -->
        <div
          class="  px-4 flex items-center justify-between pb-4 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700"
        >
          <h3 class="font-semibold text-gray-900 text-lg dark:text-white">
            {{ labels['TITLE_POSTAL_ADDRESS'][lg] }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="bg-white rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4">
          <div class="w-full">
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ labels['TITLE_POSTAL_ADDRESS_DESCRIPTION'][lg] }}
            </p>
            <div class="mt-2 w-full  text-left justify-left">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {{ labels['TITLE_POSTAL_ADDRESS_BUILDING'][lg] }}</label>
              <input
                id="building"
                v-model="building"
                v-focus
                type="text"
                name="building"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder=""
              >
            </div>
            <div class="mt-2 w-full    flex">
              <div class=" w-8/12  text-left justify-left mr-2">
                <label
                  for="forThis"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_POSTAL_ADDRESS_FOR'][lg] }}</label>
                <input
                  id="forThis"
                  v-model="forThis"
                  v-focus
                  type="text"
                  name="forThis"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder=""
                >
              </div>
              <div class=" w-4/12  text-left justify-left mr-2">
                <label
                  for="phone"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_POSTAL_ADDRESS_PHONE'][lg] }}</label>
                <input
                  id="phone"
                  v-model="phone"
                  v-focus
                  type="text"
                  name="phone"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder=""
                >
              </div>
            </div>
            <div class="mt-4 w-full  text-left justify-left">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {{ labels['TITLE_POSTAL_ADDRESS_STREET'][lg] }}</label>
              <input
                id="street"
                v-model="street"
                v-focus
                type="text"
                name="street"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder=""
              >
            </div>
            <div class="mt-2 w-full  text-left justify-left flex">
              <div class=" w-4/12  text-left justify-left mr-2">
                <label
                  for="name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_POSTAL_ADDRESS_POSTAL_CODE'][lg] }}</label>
                <input
                  id="code"
                  v-model="code"
                  v-focus
                  type="text"
                  name="code"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder=""
                >
              </div>
              <div class="w-8/12  ml-2 text-left justify-left">
                <label
                  for="name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_POSTAL_ADDRESS_CITY'][lg] }}</label>
                <input
                  id="city"
                  v-model="city"
                  v-focus
                  type="text"
                  name="city"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder=""
                >
              </div>
            </div>
            <div class="mt-2 w-full  text-left justify-left">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {{ labels['TITLE_POSTAL_ADDRESS_COUNTRY'][lg] }}</label>
              <input
                id="country"
                v-model="country"
                v-focus
                type="text"
                name="country"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder=""
              >
            </div>



            <div class="mt-2 w-full flex flex-row text-left justify-left">
              <div class="  w-6/12  text-left justify-left mr-3">
                <label
                  for="name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_GS1_GLN'][lg] }}</label>
                <input
                  id="gs1_gln"
                  v-model="gs1_gln"
                  v-focus
                  type="text"
                  name="gs1_gln"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder=""
                >
              </div>

              <div class="  w-6/12  text-left justify-left mr-3">
                <label
                  for="name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_SIRET'][lg] }}</label>
                <input
                  id="siret"
                  v-model="siret"
                  v-focus
                  type="text"
                  name="siret"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder=""
                >
              </div>

              <div class="ml-3  w-6/12 text-left justify-left">
                <label
                  for="name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ labels['TITLE_MAIN_SITE'][lg] }}</label>


                <div class="flex pt-2">
                  <div class="flex items-center me-4">
                    <input
                      id="inline-radio"
                      v-model="is_main_site"
                      type="radio"
                      value="YES"
                      name="address_main_site"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    >
                    <label
                      for="inline-radio"
                      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >Oui</label>
                  </div>
                  <div class="flex items-center me-4 ml-6">
                    <input
                      id="inline-2-radio"
                      v-model="is_main_site"
                      type="radio"
                      value="NO"
                      name="inline-address_main_site-group"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    >
                    <label
                      for="inline-2-radio"
                      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >Non</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end px-4  ">
          <button
            type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()"
          >
            Annuler
          </button>
          <button
            type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import labels from "@/assets/lg/postal-address.json"


    export default {
        name: 'CreatePostalAddressModal',
        components: {

        },
        props: {
            input: Object
        },
        data() {
            return ({
                lg: "FR",
                labels: labels,
                building: "",
                street: "",
                code: "",
                city: "",
                phone: "",
                country: "",
                is_main_site: "",
                forThis: "",
                id: null,
                siret: "",
                gs1_gln: "",

                transparent: false,
                item: {
                    id: "1",
                    label: {
                        'EN': "",
                        'FR': ''
                    }
                },
            })
        },
        watch: {
            'input': function () {

                this.id = this.input?.id
                this.building = this.input?.building
                this.street = this.input?.street
                this.phone = this.input?.phone
                this.code = this.input?.code
                this.city = this.input?.city
                this.country = this.input?.country
                this.forThis = this.input?.for

                this.siret = this.input?.siret
                this.gs1_gln = this.input?.gs1_gln

                this.is_main_site = this.input?.is_main_site
                // this.name = this.input
            }
        },
        mounted() {
            this.id = this.input?.id
            this.building = this.input?.building
            this.street = this.input?.street
            this.phone = this.input?.phone
            this.code = this.input?.code


            this.siret = this.input?.siret
            this.gs1_gln = this.input?.gs1_gln
            this.is_main_site = this.input?.is_main_site

            this.city = this.input?.city
            this.country = this.input?.country
            this.forThis = this.input?.for
        },

        methods: {
            onValidate() {

                let item =
                {
                    id: this.id,
                    building: this.building,
                    street: this.street,
                    code: this.code,
                    phone: this.phone,
                    city: this.city,
                    country: this.country,
                    for: this.forThis,
                    siret: this.siret,
                    is_main_site: this.is_main_site,
                    gs1_gln: this.gs1_gln
                }


                this.$emit("confirm", item)

                // this.name = ""
            },

            onClose() {
                this.$emit("close")
                //  this.name = ""
            }
        },
    }
</script>
<template>
  <div
    class="  w-full border-l-4 border-l-green-600 flex flex-col  bg-white  rounded-sm border-t border-b border-r border-t-gray-300 border-b-gray-300 border-r-gray-300 "
  >
    <v-grid
      theme="compact"
      :source="rows"
      :columns="columns"
      resize="true"
    />
  </div>
</template>


<script>

    import VGrid, { VGridVueTemplate } from "@revolist/vue3-datagrid";

    import Cell from "./cells/EditCell.vue"; // Custom cell template
    export default {
        name: 'TableComponentNew',
        components: { VGrid },
        props: {
            values: Object
        },
        data() {
            return {
                columns: [{ prop: "name", name: "First", size: 300 }, // Simple column definition
                { prop: "details", name: "First",cellTemplate: VGridVueTemplate(Cell), size: 300  }, // Another column definition
                ],
                rows: [{
                    name: "1",
                    details: "Item 1",
                }]
            }
        },
        watch: {
            value: function () {

            }
        },
        mounted() {

        },
        methods:
        {

        }
    }
</script>
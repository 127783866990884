<template>
  <div class="mt-2 mb-2 flex flex-row items-center w-full bg-white flex flex-row items-center relative">
    <div class="flex items-center mb-4 w-full">
      <input
        :id="`radio-1-${id}`"
        v-model="output"
        type="radio"
        value="morning2"
        name="default-radio"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      >
      <label
        :for="`radio-1-${id}`"
        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >Matin</label>
    </div>
    <div class="flex items-center mb-4 w-full">
      <input
        :id="`radio-2-${id}`"
        v-model="output"
        type="radio"
        value="evening"
        name="default-radio"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      >
      <label
        :for="`radio-2-${id}`"
        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >Soir</label>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'ContentRadio',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: String,
            config:Object
        },
        data() {
            return {
                output: "morning3"
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        },
        methods: {
      
            getData() {
                return this.output
            }
        }
    }
</script>
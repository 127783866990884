<template>
  <draggable
    class="dragArea list-group w-full"
    :list="list"
    @change="log"
  >
    <li
      v-for="item in list"
      :key="item?.id"
      class="w-full flex flex-col"
    >
      <button
        :id="`dropdown-pages-button-${item.id}`"
        type="button"
        :aria-controls="`dropdown-pages${item.id}`"
        :data-collapse-toggle2="`dropdown-pages${item.id}`"
        class="group flex flew-row items-start px-3 w-full hover:bg-gray-100   font-normal text-gray-900 rounded-lg transition duration-75  "
        :class="{'bg-gray-200':item.id == selected?.id}"
        @click="onSelectSection(item)"
      >
        <div
          class="mr-1 opacity-0"
          :class="{ 'opacity-0' : (item.type != 'list') && (item.type != 'row'), 'opacity-100' : (item.type == 'list') || (item.type == 'row')}"
        >
          <i class="fa-xs opacity:0 text-gray-600 fa-solid  hover:rotate-90 shrink-0  fa-chevron-down   " />
        </div>
        <div class="flex-1 ml-1 text-left text-sm flex flex-row items-center">
          <div class="mr-1.5 w-4  flex flex-row items-center">
            <i class="fa-solid  group-hover:flex hidden text-gray-800 fa-xs     fa-braille " />
            <i class="fa-solid   group-hover:hidden flex text-gray-800 fa-xs  fa-vector-square  " />
          </div>
          <span class=" text-left whitespace-nowrap font-normal text-gray-500 opacity-100 ">{{ item.type }}
            ({{ item?.components?.length }})
          </span>
        </div>


        <div>
          <i
            class="group-hover:opacity-100 opacity-0 text-gray-500 fa-solid   shrink-0   fa-trash  fa-xs ml-2 "
            @click="onDelete($event, item)"
          />

          <i
            class="group-hover:opacity-100 opacity-0  text-gray-500 fa-solid   shrink-0 fa-xs ml-2 mr-2    "
            :class="{'fa-eye' : (item.isVisible || (item.isVisible == undefined)), 'fa-eye-slash' : (!item.isVisible)}"
            @click="showHideItem(item)"
          />
        </div>
      </button>

      <ul
        v-if="(item.type == 'list') || (item.type == 'row') "
        :id="`dropdown-pages${item.id}`"
        class="hidden   space-y-2 pl-6"
      >
        <NestedElement
          class="  dragArea list-group w-full"
          :list="item.components"
          :item="item"
          @reload="load"
          @add-sub-section="onAddSubSection"
          @select="OnSelectItem"
          @delete="onDeleteItem"
          @move-section="onMoveSubSection"
        />



        <li class="  pl-2">
          <button
            :id="`dropdownRightEndButtonBodySubItem${item?.id}`"
            type="button"
            class="flex flew-row items-center px-2 w-full hover:bg-gray-200  py-1 font-normal text-gray-900 rounded-lg transition duration-75     dark:text-white dark:hover:bg-gray-700"
            @click="openDropdownRightEndButtonBodySubItem(item.id)"
          >
            <div class="  mr-1">
              <i
                class="fa-xs text-blue-600 fa-solid  hover:rotate-90 shrink-0  fa-plus   "
              />
            </div>
            <div class="flex-1 ml-1 text-left text-sm">
              <span class=" text-left whitespace-nowrap font-normal  text-blue-600">
                Ajouter une sous section
              </span>
            </div>
          </button>
          <!-- Dropdown menu -->
          <div
            :id="`dropdownRightEndBodySubItem${item?.id}`"
            class="z-10 hidden bg-red-200 divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700 min-w-96 min-h-96"
          >
            <popupAddSection
              class=" "
              :parent="item"
              zone="body"
              @select="onSelectComponentBodySubItem"
            />
          </div>
        </li>
      </ul>


      <button
        :id="`dropdownRightEndButtonBodyItem${item.id}`"
        type="button"
        data-dropdown-placement="right-start"
        class="group  relative   cursor-pointer  w-full   h-2  "
        @click="openDropdownRightEndButtonBodyItem(item.id)"
      >
        <div class="  absolute top-0 bottom-0 flex    items-center w-full ease-in-out  delay-75 ">
          <div
            class="transition ease-in-out duration-1000 group-hover:opacity-100 opacity-0  h-0.5 w-full bg-blue-600  w-6 group-hover:w-full "
            :class=" {'opacity-100 w-full' : selecteAddSectionButton == item.id }"
          />
        </div>
        <div
          class="group-hover:opacity-100 opacity-0 absolute top-0 bottom-0 left-0 right-0 justify-center flex items-center transition ease-in-out delay-75"
          :class=" {'opacity-100' : selecteAddSectionButton == item.id }"
        >
          <i class="   bg-white  fa-solid fa-lg  text-blue-600  fa-circle-plus   " />
        </div>
      </button>
      <!-- Dropdown menu -->
      <div
        :id="`dropdownRightEndBodyItem${item.id}`"
        class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700"
      >
        <popupAddSection
          zone="body"
          :properties="item"
          @select="onSelectComponentBodyItem"
        />
      </div>
    </li>
  </draggable>
</template>
<script>

    import popupAddSection from '@/layout/Editor/Popups/popupAddSection.vue'
    import { VueDraggableNext } from 'vue-draggable-next'
    import { Dropdown, Collapse /*,  Drawer*/ } from 'flowbite';
    export default {
        name: "NestedElement",
        components: {
            draggable: VueDraggableNext, popupAddSection
        },
        props: {
            list: Array,
            components: Array,
            item: Object
        },
        data() {
            return {
                selecteAddSectionButton: null,
                dropdownAddSection: {},
                dropdownAddSubSection: {},
            }
        },
        methods: {
            openDropdownRightEndButtonBodyItem: function (id) {
                console.log("openDropdownRightEndButtonBodyItem")

                if (this.dropdownAddSection[id] == undefined) {

                    console.log("Create DropDown ", id)
                    const options = {
                        triggerType: 'none',
                        placement: 'right-start',
                        onHide: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been hidden');
                        },
                        onShow: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been shown');
                        },
                        onToggle: () => {
                            console.log('openDropdownRightEndButtonBody  dropdown has been toggled');
                        },
                    }

                    const $targetElBody = document.getElementById('dropdownRightEndBodyItem' + id);
                    const $triggerElBody = document.getElementById('dropdownRightEndButtonBodyItem' + id);

                    console.log(">>>>>>>>", $targetElBody)
                    console.log(">>>>>>>>", $triggerElBody)
                    let dropdownBody = new Dropdown($targetElBody, $triggerElBody, options);
                    this.dropdownAddSection[id] = dropdownBody
                    this.dropdownAddSection[id].toggle();
                }
                else {
                    console.log("exit DropDown ", id)
                    this.dropdownAddSection[id].toggle();
                }
                this.selecteAddSectionButton = id

            },


            onSelectSection: function (item) {
                console.log("onSelectSection onSelectSection onSelectSection")
                const options = {
                    triggerType: 'none',

                    onCollapse: () => {
                        console.log('onSelectSectionelement has been collapsed');
                    },
                    onExpand: () => {
                        console.log('onSelectSection element has been expanded');
                    },
                    onToggle: () => {
                        console.log('onSelectSection  dropdown has been toggled');
                    },
                }

                if ((item.type == 'list') || (item.type == 'row'))
                    if (this.dropdownAddSection[item.id] == undefined) {
                        const $targetElBody = document.getElementById("dropdown-pages" + item.id);
                        const $triggerElBody = document.getElementById("dropdown-pages-button-" + item.id);

                        console.log("dropdown-pages" + item.id)
                        console.log($triggerElBody)
                        console.log($targetElBody)


                        const instanceOptions = {
                            id: '"dropdown-pages"+item.id',
                            override: true
                        };

                        this.dropdownAddSection[item.id] = new Collapse($targetElBody, $triggerElBody, options, instanceOptions);
                        if (this.dropdownAddSection[item.id] != null) this.dropdownAddSection[item.id].expand();
                    }



                // 
                this.$emit("select", item)

            },
            openDropdownRightEndButtonBodySubItem: function (id) {
                console.log("openDropdownRightEndButtonBodySubItem", id)

                if (this.dropdownAddSubSection[id] == undefined) {

                    console.log("Create DropDown ", id)
                    const options = {
                        triggerType: 'none',
                        placement: 'right-start',
                        onHide: () => {
                            console.log('openDropdownRightEndButtonBodySubItem  dropdown has been hidden');
                        },
                        onShow: () => {
                            console.log('openDropdownRightEndButtonBodySubItem  dropdown has been shown');
                        },
                        onToggle: () => {
                            console.log('openDropdownRightEndButtonBodySubItem  dropdown has been toggled');
                        },
                    }

                    const $targetElBody = document.getElementById('dropdownRightEndBodySubItem' + id);
                    const $triggerElBody = document.getElementById('dropdownRightEndButtonBodySubItem' + id);

                    console.log(">>>>>>>>", $targetElBody)
                    console.log(">>>>>>>>", $triggerElBody)
                    let dropdownBody = new Dropdown($targetElBody, $triggerElBody, options);
                    this.dropdownAddSubSection[id] = dropdownBody
                    this.dropdownAddSubSection[id].toggle();
                }
                else {
                    console.log("exit DropDown ", id)
                    this.dropdownAddSubSection[id].show();
                }
                this.selecteAddSubSectionButton = id

            },


            onSelectComponentBodySubItem: function (event) {
                console.log("onSelectComponentBodySubItem", event?.id, event.parent)
                console.log(event)
                console.log(this.dropdownAddSubSection)

                if (this.dropdownAddSubSection[event.parent].isVisible()) this.dropdownAddSubSection[event.parent].toggle();
                this.selecteAddSubSectionButton = null
                this.$emit("addSubSection", { zone: "body", section: event.section, parent: event.parent })
                this.$emit("reload")

            },

            onSelectSubSection: function (event, subitem, item) {

                event.stopPropagation()
                this.$emit("selectSubSection", { id: subitem.id, parent: item.id })

            },

            onDeleteItem: function (item) {
                //                event.stopPropagation()
                console.log("NestedElement", "onDeleteItem onDeleteItem onDeleteItem onDeleteItem onDeleteItem")
                console.log(item)


                this.$emit("delete", item)
                return false
            },
            onMoveSubSection: function (subitem) {


                //   event.stopPropagation();
                console.log("onMoveSubSection")
                console.log("subitem", JSON.stringify(subitem))

           
                // console.log(JSON.stringify(this.list))
               //if (subitem.moved != undefined) {


                    this.$emit("moveSubSection",   subitem)
                // }

            },

            onDelete: function (event, item) {
                event.stopPropagation()

                console.log("NestedElement", "onDelete onDelete onDelete onDelete onDelete")
                console.log(item)


                this.$emit("delete", item)



                return false
            },
            log(item) {
                //   event.stopPropagation();
                console.log("log")
                console.log(JSON.stringify(item))
                // console.log(JSON.stringify(this.list))
                if (item.moved != undefined) {

                    // this.$emit("moveSection", { zone: "body", id: item.moved.element.id, oldIndex: item.moved.oldIndex, newIndex: item.moved.newIndex })

                    if (this.item != null) {
                        this.$emit("moveSection", { zone: "body", id: item.moved.element.id, parent: this.item.id, oldIndex: item.moved.oldIndex, newIndex: item.moved.newIndex })

                    }
                    else {
                        this.$emit("moveSection", { zone: "body", id: item.moved.element.id,                       oldIndex: item.moved.oldIndex, newIndex: item.moved.newIndex })
                    }
                }

            },
        }
    };

</script>
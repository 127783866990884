<template>
  <div
    class="relative  cursor-grab list-group bg-gray-700 border  mt-2 mr-6 w-full flex items-start bg-gray-700 border   shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
    :class="{'h-28': (element.isDescriptionDisplayed && element.isTitleDisplayed),'h-24':(!element.isDescriptionDisplayed && element.isTitleDisplayed),'h-24':(element.isDescriptionDisplayed && !element.isTitleDisplayed),'h-12':(!element.isDescriptionDisplayed && !element.isTitleDisplayed), 'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
    <div class="hidden flex flex-col items-center w-6 ">
      <i class="fa-solid fa-grip-vertical" />
    </div>
    <div class="flex flex-col items-center w-full  pl-2 bg-gray-700 ">
      <div
        v-if="element.isTitleDisplayed"
        class="mt-2  w-full   text-xs text-left text-white"
      >
        {{ element.title }}
      </div>
      <div
        v-if="element.isDescriptionDisplayed"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ element.description }}
      </div>
      <div class="w-full mt-2  font-bold  text-xs  text-white ">
        <div
          class="inline-flex rounded-md shadow-sm"
          role="group"
        >
          <button
            v-for="(value,index) in element?.values"
            :key="value.id"
            type="button"
            class="max-w-[120px] px-3 py-1 text-xs font-medium text-gray-100  border-gray-100 hover:bg-gray-200 hover:text-gray-800 focus:z-10 focus:ring-0 focus:ring-gray-200 focus:bg-gray-200 focus:text-gray-900 dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 truncate"
            :class="{'border rounded-s-xl': (index==0), 'border-t border-b border-r': ((index!=0) && (index!=(element?.values.length -1))), 'border rounded-e-xl': (index==(element?.values.length -1)), 'text-gray-800 bg-gray-200': (element.default == index), 'text-gray-100 bg-transparent': (element.default != index)}"
          >
            {{ value.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'RadioTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {

                showheader: false,
                values: [
                    { id: "1", name: "A1" }, { id: "2", name: "A2" }, { id: "2", name: "A2" }, { id: "3", name: "A3" }
                ]
            }
        },

        computed:
        {

        },
        methods:
        {
            addItem: function()
            {
                this.$emit("add", {type: 'avatar', id: this.element.id} )
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
<template>
  <div class="  w-full py-6">
    <div class="mb-6 flex flex-col items-start justify-start">
      <div
        v-if="currentItem.label != undefined"
        class="mb-6 flex-col items-center   justify-start"
      >
        <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
          <div
            class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
          >
            <div class="text-sm  text-gray-50">
              FR
            </div>
          </div>
          <input
            v-model="currentItem.label.FR"
            class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            required
          >
        </div>
        <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
          <div
            class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
          >
            <div class="text-sm  text-gray-50">
              EN
            </div>
          </div>
          <input
            v-model="currentItem.label.EN"
            class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            required
          >
        </div>
        <div class="flex justify-start flex-row w-full mt-2 ml-6 items-center">
          <input
            id="checked-checkbox"
            v-model="currentItem.label.display"
            type="checkbox"
            value=""
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          >
          <label
            for="checked-checkbox"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Afficher le titre</label>
        </div>
      </div>
      <div class="mb-6 flex flex-col items-start justify-start w-full">
        <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
          Nom de l'attribut
        </label>
        <input
          v-model="currentItem.data"
          type="text"
          class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
          required
        >
      </div>
    </div>
  </div>
</template>

<script>

    export default {
        name: 'DeveloperItemRadio',
        props: {
            item: Object
        },
        data() {
            return ({
                currentItem: {}
            })
        },
        watch: {
            item: function (val) {
                let item = val;

                if (item.label == undefined) item.title = {};
                if (item.label.FR == undefined) item.label.FR = "";
                if (item.label.EN == undefined) item.label.EN = "";
                if (item.label.display == undefined) item.label.display = true;

                this.currentItem = item;

            }
        },



        mounted() {

            let item = this.item;

            if (item.label == undefined) item.title = {};
                if (item.label.FR == undefined) item.label.FR = "";
                if (item.label.EN == undefined) item.label.EN = "";
                if (item.label.display == undefined) item.label.display = true;

            this.currentItem = item;
           

        }

    }
</script>
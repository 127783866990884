<template>
  <div class="relative   overflow-y-auto bg-gray-50  lg:ml-64 dark:bg-gray-900">
    <div class="relative  mt-24">
      <div class="  dark:border-gray-50 mt-2">
        <h2 class="p-2 text-left mb-2 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          {{ displayTitle() }}
        </h2>
      </div>
      <B4Table
        :is-loading="isLoading"
        :is-loading2="isLoading2"
        :is-loading3="isLoading3"
        :limit="limit"
        :offset="offset"
        :config="config"
        :records="records"
        @row-click="onRowClick"
        @add="onAddClick"
        @up="onUpClick"
        @export="onExportClick"
        @refresh="onRefreshClick"
        @refresh-page="onRefreshPage"
        @refresh-table="onRefreshTable"
      />
    </div>
  </div>
  <SelectProductType
    @close="OnSelectProductClose"
    @select="OnSelectProductSelected"
  />
</template>
<script>
    const MAX_ITEMS = 10;
    import { Modal } from 'flowbite';
    import B4Table from '@/components/TableComponent.vue'
    import service from "@/services/FullService.vue"
    import SelectProductType from '@/components/modals/SelectProductType.vue'
    import { mapGetters } from "vuex";
    export default {
        name: 'DriveView',
        components: {
            B4Table, SelectProductType
        },
   
        data() {
            return {
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                config: {},
                records: [],
                offset: 0,
                filters: {},
                limit: MAX_ITEMS,
                lg: "FR",
                title: "",
                surveyModal: null,
                button: ""
            }
        },
       
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            '$route.query.path': async function () {
                let filters = {}

                //filters['category'] = config.filters;
                this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            '$route.params.id': async function (val) {
                let filters = {}

                //filters['category'] = config.filters;
                this.reloadPage(val, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user.tenant_id': async function () {
                this.isLoading = true;
                let filters = {}
                this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);
   
             
            }
        },
        async mounted() {
            // await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {})



            const modalEl = document.getElementById('select-product-type-modal');
            this.surveyModal = new Modal(modalEl, {
                placement: 'center'
            });


        },
        methods: {
            onExportClick() {
                
            },
            displayTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "EN"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            async reloadPage(id, offset, limit, filter, level) {

                if (level == null) level = 1


                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }

                this.offset = offset;
                this.limit = limit;


                if (level == 1) this.isLoading = true;
                if (level == 2) this.isLoading2 = true;
                if (level == 3) this.isLoading3 = true;
                console.log("DriveView 1", id)
                let config = await service.getConfiguration(id, this.user?.token)



                let t = await service.getFilterTabDefinition(this.$route.params.id, this.user?.token, filter)
             
        
                if (config?.secondBar)
                    if ((level == 1) && (filter.category == undefined)) {
                        //TODO Check how to remove this
                        if ((this.$route.params.id == 'products') || (this.$route.params.id == 'raw-products'))
                            if (t.records[0]) filter.category = t.records[0].id
                    }
                this.filter = filter;
                let d = await service.getFilterBarDefinition(this.$route.params.id, this.user?.token, filter)
          

                config.tabs = t.records;
                config.secondBar = d.records;

                this.config = config;

                filter['path'] = '/'
                if (this.$route.query.path != undefined) {
                    filter['path'] = this.$route.query.path
                }


                this.records = await service.getData("images", this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))

                if (level == 1) this.isLoading = false;
                if (level == 2) this.isLoading2 = false;
                if (level == 3) this.isLoading3 = false;
           
            },
            OnSelectProductSelected: function () {

            },
            OnSelectProductClose: function () {

            },
            onRefreshClick: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 2);
            },
            onRefreshPage: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 1);
            },

            onRefreshTable: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 3);
            },


            onAddClick: function () {



                let url = "/admin/drives/" + this.$route.params.id + "/new"
                //this.$router.push(url)
                window.location = url
            },


            onUpClick: function () {
                if (this.$route.query.path == undefined) return;
                if (this.$route.query.path == "/") return;
                
                let ar = this.$route.query.path.split("/");
                let path = ""
                for (let i=0; i< (ar.length -1); i++)
                {
                    path = "/" + ar[i] 
                }
              

                let url = "/admin/drive/" + this.$route.params.id + "?path=" + path
                
         
                //this.$router.push(url)
                window.location = url
            },
            onRowClick: function (item) {
         
                let url = "/admin/drive/" + this.$route.params.id + "?path=" + item.body.key

                // let url = "/admin/views/" + this.$route.params.id + "/" + item.record
                this.$router.push(url)
                // window.location = url
            }
        }
    }
</script>
<template>
  <div class="mt-2 mb-2 w-full justify-start flex flex-col relative">
    <label
      class="font-bold text-left ml-4 mb-6 px-2.5 text-left block mb-2 text-lg   text-gray-900 dark:text-white"
    >
      {{ labels['LABEL_PACKAGING_VALUE'][lg] }}
    </label>



    <div
      v-for="(line,index) in lines"
      :key="line.id"
      class="mb-3 flex flex-row items-center justify-start"
    >
      <div class="flex flex-row items-center ">
        <div class="w-64 flex items-center flex-col">
          <label
            v-if="index==0"
            class="w-full font-bold   text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_PACKAGING_TYPE'][lg] }}
          </label>
          <select
            :id="`${index}-type`"
            v-model="lines[index].type"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            @change="onChangeList1($event, index)"
          >
            <option selected />
            <option
              v-for="item1 in list1"
              :key="item1._id"
              :value="`${item1._id}`"
            >
              {{ item1.name['FR'] }}
            </option>
          </select>
        </div>
        <div class="w-64 flex items-center ml-4 flex-col">
          <label
            v-if="index==0"
            class="w-full font-bold  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_PACKAGING_MATERIAL'][lg] }}
          </label>


          <select
            :id="`${index}-material`"
            v-model="lines[index].material"
            :disabled="!active2"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :class="{'bg-gray-200': !active2 }"
            @change="onChangeList2($event, index)"
          >
            <option selected />
            <option
              v-for="item2 in list2"
              :key="item2._id"
              :value="`${item2._id}`"
            >
              {{ item2.name[lg] }}
            </option>
          </select>
        </div>
        <div class="w-48 flex items-center ml-4 flex-col">
          <label
            v-if="index==0"
            class="w-full font-bold  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_PACKAGING_OPTIONS'][lg] }}
          </label>

          <select
            :id="`${index}-option`"
            v-model="lines[index].color"
            :disabled="!active3"
            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :class="{'bg-gray-200': !active3 }"
          >
            <option selected />
            <option
              v-for="item3 in list3"
              :key="item3._id"
              :value="`${item3._id}`"
            >
              {{ item3.name[lg] }}
            </option>
          </select>
        </div>
        <form class="w-48 pl-6 ml-4 flex flex-col   items-center text-left justify-start">
          <label
            v-if="index==0"
            :for="`${id}`"
            class="w-full font-bold  text-left ml-4 px-2.5 text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {{ labels['LABEL_INGREDIENT_VALUE'][lg] }}
          </label>
          <div class="flex flex-row   ">
            <div class="relative w-full">
              <input
                id="location-search"
                v-model="lines[index].value"
                type="text"
                class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50  border   border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                placeholder=""
                required
              >
            </div>

            <button
              :id="`dropdown-button-2-${id}-${index}`"
              :data-dropdown-toggle="`dropdown-unit-${id}-${index}`"
              class="w-12 flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300   hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              type="button"
            >
              {{ lines[index].unit }}
              <svg
                aria-hidden="true"
                class="  w-4 h-4 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>


            <div
              :id="`dropdown-unit-${id}-${index}`"
              class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                class="py-2 text-sm text-gray-700 dark:text-gray-200"
                :aria-labelledby="`dropdown-button-2-${id}-${index}`"
              >
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index,'g')"
                  >
                    <div class="inline-flex items-center">
                      g
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index, 'mg')"
                  >
                    <div class="inline-flex items-center">
                      mg
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index, 'cl')"
                  >
                    <div class="inline-flex items-center">
                      cl
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                    @click="selectValue(index, '%')"
                  >
                    <div class="inline-flex items-center">
                      %
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </form>
        <div class="w-auto flex flex-col items-center justify-center">
          <label
            v-if="index==0"
            class="opacity-0  text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            -
          </label>
          <div class="w-auto flex flex-row items-center justify-center">
            <div
              href="#"
              class="w-4 cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg px-3   mr-2    focus:outline-none  "
              @click="onClickMinus(line)"
            >
              <i class="fa-solid fa-circle-minus" />
            </div>

            <div
              href="#"
              class="w-4 cursor-pointer text-gray-800 hover:text-gray-900 font-bold text-lg px-3 mr-2 focus:outline-none  "
              @click="onClickPlus"
            >
              <i class="fa-solid fa-circle-plus" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import labels from "@/assets/lg/products.json"
    import { Dropdown } from 'flowbite';


    import { mapGetters } from "vuex";

    import service from "@/services/FullService.vue"

    export default {
        name: 'ContentListPackaging',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: String,
            config: Object
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        watch: {
            value: function () {
                if (this.value != undefined) {
                    this.lines = this.value;
                    //PV A REVOIR
                    //for (let i = 0; i < this.list1.length; i++) {

                    //if (this.dropdowns[i] == undefined) {
                    //  const $targetEl = document.getElementById('dropdown-unit-' + this.id + "-" + i);
                    //  const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + "-" + i);
                    //  this.dropdowns[i] = new Dropdown($targetEl, $triggerEl, this.options);
                    // }
                    //}
                }

            }
        },
        async mounted() {


            if (this.value != undefined)
                this.lines = this.value;


            for (let i = 0; i < this.list1.length; i++) {
                const $targetEl = document.getElementById('dropdown-unit-' + this.id + "-" + i);
                const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + "-" + i);
                this.dropdowns[i] = new Dropdown($targetEl, $triggerEl, this.options);

            }
            // let collection_packagings = "packagings"
            let collection_packagings = "data-sources/668d49b09535b23c096b6459/records"


            let types = await service.getData(collection_packagings, this.user?.token, {})



            // let collection_materials = "materials"
            let collection_materials = "data-sources/668d491a9535b23c096b6458/records"

            let materials = await service.getData(collection_materials, this.user?.token, {})

            if (types != undefined) this.list1 = types.records
            if (materials != undefined) this.list2 = materials.records
        },
        methods: {


            onChangeList1(event, index) {


                let item1 = this.list1.find(function (a) { return (a._id == event.target.value) })

                this.active2 = !(item1.material == false)

                if (this.active2 == false) this.lines[index].material = ""
            },
            onChangeList2(event, index) {


                let format = this.lines[index].type;

                let item1 = this.list1.find(function (a) { return (a._id == format) })
                let item2 = this.list2.find(function (a) { return (a._id == event.target.value) })


                if ((item1?.color == true) && (item2?.color == true)) {
                    this.active3 = true;
                    this.list3 = this.colors;
                }
                else {
                    this.active3 = false
                    this.lines[index].color = ""
                    this.list3 = []
                }




            },
            getData() {

                return this.lines
            },

            selectValue: function (index, unit) {
                //this.output = a

                if (this.lines[index] == undefined) this.lines[index] = { unit: '' }
                this.lines[index].unit = unit;

                this.dropdowns[index].toggle()
            },
            onClickPlus: function () {


                this.lines.push(
                    {
                        id: this.lines.length,
                        type: "",
                        material: "",
                        value: ""
                    }
                )

            },
            onClickMinus: function (item) {
                function removeItemOnce(arr, index) {

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    return arr;
                }
                for (let i = 0; i < this.lines.length; i++) {

                    if (this.lines[i].id == item.id) {

                        //   this.lines = this.output;
                        this.lines = removeItemOnce(this.lines, i);

                        return
                    }
                }
            }
        },
        data() {
            return {

                active2: true,
                active3: false,


                list1: [],
                list2: [],
                list3: [],
                colors: [

                    {
                        id: 'transparent',
                        name: {
                            EN: 'Transparent',
                            FR: 'Transparent'
                        }
                    },
                    {
                        id: 'opaque',
                        name: {
                            EN: 'Opaque',
                            FR: 'Opaque'
                        }
                    }
                ],
                options: {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    onHide: () => {

                    },
                    onShow: () => {

                    },
                    onToggle: () => {


                    },
                },
                output: [],
                labels: labels,
                lg: 'FR',
                el_dropdown: null,
                dropdowns: [],
                lines: [

                    {
                        id: 1,
                        type: "",
                        material: "",
                        value: ""
                    }
                ]
            };
        }
    }
</script>
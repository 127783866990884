<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Hauteur
          du composant</label>
        <input
          id="default-input"
          v-model="height"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputHeight"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Hauteur de la ligne</label>
        <input
          id="default-description-input"
          v-model="line_height"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputLineHeight"
        >
      </div>


      <div class="flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center">
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'SeparatorTableFormComponentDetails',
        props: {
            element: Object,
            index: Number,
        },
        data() {

            return {
                height: "30px",
                line_height: "2px",
                format: "",
                template: "title-tpl-1"
            }
        },
        watch:
        {
            element: function () {
                this.height = this.element?.height
                this.line_height = this.element.line_height

                if (this.element.template != undefined)
                    this.template = this.element.template
            }
        },
        mounted() {
            this.height = this.element?.height
            this.line_height = this.element.line_height

            if (this.element.template != undefined)
                this.template = this.element.template

        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'separator', id: this.element.id })
            },
            onInputHeight: function () {
                this.$emit("update", { att: 'height', value: this.height })
            },
            onInputLineHeight: function () {
                this.$emit("update", { att: 'line_height', value: this.line_height })
            },
            
        }
    }
</script>
<template>
  <div class=" mx-auto  h-full">
    <div
      class="absolute top-0 w-full h-full bg-slate-800 bg-no-repeat bg-full"
      :style="`background-image: url('${registerBg2}');background-repeat: no-repeat;background-attachment: fixed;background-size: cover;`"
    />


    <div class="relative shadow-xl flex content-center items-center justify-center h-full">
      <div
        class="  lg:w-4/12 px-4"
        :class="{
          'opacity-50': loginInProgress,
          block: !loginInProgress,
        }"
      >
        <div
          class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
              mb-6
              shadow-lg
              rounded-lg
              bg-slate-200
              border-0
            "
        >
          <div class="flex-auto justify-end px-4 lg:px-10 py-6 pt-0">
            <div class="w-full bg-transparent text-center flex justify-center">
              <a
                href="https://www.b4food.io"
                class="flex  "
              >
                <img
                  :src="logo"
                  class="h-24  "
                  alt="B4food.io"
                >

              </a>
            </div>
            <div class="text-slate-600 text-center mb-3 font-bold text-2xl">
              <br>
              {{ $t("RESET_PASSWORD_TITLE") }}
            </div>


           
 
            <br>
            <div
              v-if="showError == 'ERROR_INTERNAL'"
              class="mb-6"
            >
              <div class="bg-red-100  border-l-4 border-l-red-600 w-full py-6 px-3">
                {{ $t("RESET_PASSWORD_ERROR_INTERNAL") }}

                <div
                  class="mt-6 text-sm underline cursor-pointer flex items-center justify-center"
                  @click="onRequestPasswordChange"
                >
                  <div>{{ $t("RESET_PASSWORD_ERROR_REQUEST") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="showError == 'SENT_OK'"
              class="mb-6"
            >
              <div class="bg-green-100  border-l-4 border-l-green-600 w-full py-6 px-3">
                {{ $t("RESET_PASSWORD_SENT_DONE") }}

                <div
                  class="mt-6 text-sm underline cursor-pointer flex items-center justify-center"
                  @click="BackToLogin"
                >
                  <div>{{ $t("CHANGE_PASSWORD_GO_LOGIN") }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="showError != 'SENT_OK'"
              class="text-slate-600 text-center mb-3 font-normal text-lg"
            >
              {{ $t("RESET_PASSWORD_DESCRIPTION") }}
            </div>

            <form
              v-if="showError != 'SENT_OK'"
              @submit.prevent="submit"
            >
              <div class="relative w-full mb-3">
                <label
                  class="
                      block
                      text-left
                      uppercase
                      text-slate-600 text-xs
                      font-bold
                      mb-2
                    "
                  htmlFor="grid-password"
                >


                  {{ $t("RESET_PASSWORD_LABEL_EMAIL") }}


                </label>
                <input
                  ref="login"
                  v-model="form.email"
                  type="email"
                  class="  
                      border-0
                      px-3
                      py-3
                      text-left
                      placeholder-slat-300
                      text-slate-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  placeholder="Adresse e-mail"
                >
              </div>



              <div class="text-center mt-6">
                <button
                  class="
                      bg-gray-800
                      text-white
                      active:bg-gray-600
                      text-sm
                      font-bold
                      uppercase
                      px-6
                      py-3
                      rounded
                      shadow
                      hover:shadow-lg
                      outline-none
                      focus:outline-none
                      mr-1
                      mb-1
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  :disabled="loginInProgress"
                  type="submit"
                >
                  {{ $t("RESET_PASSWORD_BUTTON") }}
                </button>
              </div>
            </form>
        
            <div
              v-if="showError != 'SENT_OK'"
              class=" text-left flex flex-wrap mt-6 relative "
            >
              <div class="w-1/2">
                <div
                  class="cursor-pointer flex items-center"
                  @click="BackToLogin"
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white mr-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M5 12l4-4m-4 4 4 4"
                    />
                  </svg>
                  
                  <small>
                    {{ $t("LOGIN_LABEL_BACK") }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="absolute top-0 right-0 flex flex-row bg-black px-2 py-2">
        <div
          class="px-1 text-white   cursor-pointer"
          :class="{ 'font-bold' : ( $i18next?.language === 'en' ), 'font-normal' : ( $i18next?.language !== 'en' ),}"
          @click="onChangeLanguage('en')"
        >
          en
        </div>
        <div class="px-1 text-white font-normal">
          |
        </div>
        <div
          class="px-1 text-white  cursor-pointer"
          :class="{ 'font-bold' : ( $i18next?.language === 'fr' ), 'font-normal' : ( $i18next?.language !== 'fr' ),}"
          @click="onChangeLanguage('fr')"
        >
          fr
        </div>
        <div class="px-1 text-white font-normal">
          |
        </div>
        <div
          class="px-1 text-white  cursor-pointer"
          :class="{ 'font-bold' : ( $i18next?.language === 'sp' ), 'font-normal' : ( $i18next?.language !== 'sp' ),}"
          @click="onChangeLanguage('sp')"
        >
          sp
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import registerBg2 from "@/assets/img/bg_login_1.jpg";

  import service from "@/services/FullService.vue"

  import labels from "@/assets/lg/login.json";

  import logo from "@/assets/logo.png"

  import github from "@/assets/img/github.svg";
  import google from "@/assets/img/google.svg";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name:"ResetPasswordView",
    data() {
      return {
        registerBg2,
        logo: logo,
        labels: labels,

        passwordType: "password",
        form: {
          email: "",
          password: "",
        },
        autofocus: false,
        loginInProgress: false,
        showError: false,
        github,
        google,
      };
    },
    computed: {
      ...mapGetters({ Remember: "StateRememberMe", lg: "StateLangage" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    mounted() {




      if (this.Remember.flag == true) {
        this.form.email = this.Remember.login;

        this.autofocus = true;
        /* eslint-disable */
        setTimeout(x => {

          this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
        }, 800);
      }
      else {
        /* eslint-disable */
        setTimeout(x => {

          this.$nextTick(() => this.setFocusLogin()); // this works great, just watch out with going to fast !!!
        }, 800);
      }
    },
   
    methods: {
      ...mapActions([  "Langage", "LogIn"]),

 
      BackToLogin: function()
      {
        this.$router.push("/login")
      },

      onChangeLanguage: function (lang) {
        this.$i18next.changeLanguage(lang);
      },

      setFocus: function () {
        // Note, you need to add a ref="search" attribute to your input.
        this.$refs.login.focus();
      },
      setFocusLogin: function () {
        // Note, you need to add a ref="search" attribute to your input.
        this.$refs.login.focus();
      },

      async submit() {
        let user = {
          email: this.form.email
        };
        // const User = new FormData();
        // User.append("email", this.form.email);
        // User.append("password", this.form.password);
        try {
         

          this.loginInProgress = true;
         let response =  await service.ResetPassword(user);


         if (response.code == 0)
         {
          this.showError = "SENT_OK"
        
          this.loginInProgress = false;
     
          return ;

         }

 
          this.showError =  "ERROR_INTERNAL";
        } catch (error) {
          this.loginInProgress = false;

          this.showError =  "ERROR_INTERNAL";
        }
      },
    },
  };
</script>
<style scoped>
  .error {
    color: white;
    background-color: red;
    text-align: center;
  }
</style>
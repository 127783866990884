<template>
  <div
    class="block max-w-sm mx-auto mt-2 p-2 bg-white border border-gray-200 rounded-lg   hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
  >
    <div class="w-full flex flex-row items-center justify-start mb-3">
      <div class="w-4 text-gray-300">
        <i class="fa-solid fa-grip-vertical" />
      </div>
      <div class="w-4 text-gray-500 ml-2">
        <i class="fa-solid fa-font" />
      </div>

      <div class="w-full ml-2">
        <label
          for="small"
          class="block  text-sm font-semibold text-gray-900 dark:text-white"
        >
          Trier par
        </label>
      </div>

      <div class="w-4 text-gray-500">
        <i class="w-3 fa-solid fa-trash" />
      </div>
    </div>

    <div class="w-full">
      <label
        for="small"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Aggregate
      </label>
      <div class="max-w-sm flex flex-row mx-auto items-center">
        <select
          id="small"
          class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option selected>
            sum
          </option>
          <option value="US">
            count
          </option>
          <option value="US">
            count by value
          </option>
          <option value="US">
            distinct
          </option>
          <option value="US">
            mean
          </option>
          <option value="US">
            median
          </option>
          <option value="US">
            min
          </option>
          <option value="US">
            max
          </option>
          <option value="US">
            variance
          </option>
          <option value="US">
            variance population
          </option>
          <option value="US">
            standard deviation
          </option>
          <option value="US">
            standard deviation population
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
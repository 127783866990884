<template>

    <div id="ai-sidebar"
        class=" w-fill flex flex-col bg-white  border-l-2 border-l-gray-200 z-10 h-full transition-transform -translate-x-full sm:translate-x-0 pt-2  "
        aria-label="Sidebar">
        <div class="relative   w-full px-1     dark:bg-gray-800 ">


            <ul class="  font-medium   px-1  ">
                <li class="flex flex-row w-full items-center border   border-gray-300 px-2 h-10 bg-gray-100 rounded-lg">
                    <div class="w-full text-left justify-left cursor-pointer flex flex-row items-center"
                        @click="onCloseEdit">
                        <div class="flex flex-row items-center px-1  ">

                            <span class="text-gray-600 font-bold text-base">Conversation</span>
                        </div>
                    </div>
                    <div class="w-full text-left justify-end cursor-pointer flex px-2" @click="onCloseEdit">
                        <i class="fa-solid fa-trash" />
                    </div>
                    <div class="w-full text-left justify-end cursor-pointer flex px-2" @click="onCloseEdit">
                        <i class="fa-solid fa-xmark" />
                    </div>
                </li>
            </ul>
        </div>
        <div class="relative  h-full w-full px-1   overflow-y-auto    dark:bg-gray-800 " id="div-message-atlas">

            <ul class="  font-medium   px-1  pt-2">

                <li class="flex flex-row w-full items-center border-b-1 border-b-gray-300 px-2 py-1 w-full max-w-[520px]"
                    v-for="message in messages" :key="message.id">

                    <div v-if="message.type=='message'" class="flex items-start gap-2.5 w-full">

                        <div class=" flex flex-col   leading-1.5 p-4 border-gray-200 rounded-e-xl rounded-es-xl dark:bg-gray-700 w-full"
                            :class="message.class">
                            <div class="flex items-center space-x-2 rtl:space-x-reverse">
                                <img class="w-8 h-8 rounded-full bg-white"
                                    :src="`https://files.b4food.io/b4food/images/${message.image}?icon=true`"
                                    alt="Jese image">
                                <span
                                    class="text-sm font-semibold text-gray-900 dark:text-white">{{message.author}}</span>
                                <span
                                    class="text-sm font-normal text-gray-500 dark:text-gray-400">{{message.date}}</span>
                            </div>
                            <p class="justify-start text-left text-sm font-normal py-2.5 text-gray-900 dark:text-white"
                                v-html="message.message">
                            </p>
                            <p>
                                <button type="button"
                                    class="hidden text-xs  text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-0.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">Default</button>
                                <button type="button"
                                    class="hidden px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg class="w-3 h-3 text-white me-2" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                        <path
                                            d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                                        <path
                                            d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                                    </svg>
                                    Extra small
                                </button>

                            </p>

                            <span class="hidden text-sm font-normal text-gray-500 dark:text-gray-400">Delivered</span>
                        </div>
                        <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots"
                            data-dropdown-placement="bottom-start"
                            class="hidden inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
                            type="button">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                                <path
                                    d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                            </svg>
                        </button>
                        <div id="dropdownDots"
                            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                aria-labelledby="dropdownMenuIconButton">
                                <li>
                                    <a href="#"
                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reply</a>
                                </li>
                                <li>
                                    <a href="#"
                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Forward</a>
                                </li>
                                <li>
                                    <a href="#"
                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Copy</a>
                                </li>
                                <li>
                                    <a href="#"
                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Report</a>
                                </li>
                                <li>
                                    <a href="#"
                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
                                </li>
                            </ul>
                        </div>
                    </div>




                    <div v-if="message.type=='wait'" class="flex flex-row items-center py-3 w-full justify-center">
                        <div role="status">
                            <svg aria-hidden="true"
                                class="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill" />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="text-gray-700 text-sm">Réponse en attente</div>
                    </div>

                </li>


            </ul>


        </div>
        <div
            class="cursor-pointer  w-full flex flex-col items-center px-2 bg-gray-100 pb-4  w-full border-t border-t-gray-300">


            <div class="relative w-full pt-2 pb-4  focus:outline-none ">
                <textarea id="message-atlas" rows="1" dir="" autocapitalize="off" autocomplete="off"
                    v-model="text_message" aria-autocomplete="both" spellcheck="false" writingsuggestions="false"
                    aria-description="Vos données personnelles et d’entreprise sont protégées"
                    aria-label="Posez-moi une question..." enterkeyhint="send" autocorrect="off" maxlength="4000"
                    placeholder="Posez-moi une question..."
                    class="relative overflow-hidden resize-none focus:outline-none  p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300   focus:outline-none   ring:outline-none  focus:ring-0"></textarea>
                <div class="flex flex-row absolute bottom-9 right-5 ">
                    <i class="fa-solid fa-play mr-2 hidden"></i>
                    <i class="fa-solid fa-play mr-2 hidden"></i>
                    <i class="fa-solid fa-play mr-2 hidden"></i>
                    <i class="fa-solid fa-play mr-2 hidden"></i>

                    <svg class="w-5 h-5 rotate-90 rtl:-rotate-90 cursor-pointer" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" @click="sendMessage" fill="currentColor" viewBox="0 0 18 20">
                        <path
                            d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                    </svg>
                </div>

            </div>


            <button
                class="hidden h-10 cursor-pointer list-group mt-2 w-full flex items-center   border bg-red-800 text-white border-gray-200 dotted rounded-lg shadow      flex-row justify-center"
                type="button" data-drawer-placement="right" aria-controls="drawer-right-examples"
                @click="onOpenIndicatorsDrawer">
                <div class="flex flex-col items-center w-full  pl-1  w-full  justify-center ">
                    <div class=" h-6 flex flex-row items-center   text-center justify-center ">

                        <div class="flex flex-col items-center w-6 ">
                            <i class="fa-solid fa-trash  " />
                        </div>



                        <div class="  w-full flex items-center  w-full cursor-pointer ">
                            <label class=" pl-2 cursor-pointe   block  text-sm    font-semibold  ">
                                Supprimer

                            </label>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    </div>

</template>


<script>
    import { io } from "socket.io-client"; // Importer le client Socket.IO
    export default {
        name: 'ChatComponent',
        data() {
            return {
                text_message: "",
                socket: null,
                messages: [],
            }
        },
        created() {
            {
                let v = this
                // Se connecter au serveur via Socket.IO lors de la création du composant
                this.socket = io('https://files-post.b4food.io:5555'); // Adresse du serveur WebSocket

                // Écouter un événement appelé "message" envoyé par le serveur
                this.socket.on('message-2', (data) => {
                    // this.message = data; // Mettre à jour le message reçu

                    v.addAtlasMessage(data)

                    console.log('Message reçu du serveur:');
                    console.log(JSON.stringify(data))

                    if (data.message.action == "navigation") {
                        this.$router.push(data.message.properties.url)
                        return
                    }


                });

                // Gérer la connexion établie
                this.socket.on('connect', () => {
                    console.log('Connecté au serveur WebSocket');
                    this.socket.emit('login', "chat");

                });

                // Gérer les erreurs de connexion
                this.socket.on('connect_error', (err) => {
                    console.log('Erreur de connexion :', err);
                });
            }


        },
        mounted() {

            const textarea = document.getElementById('message-atlas');
            textarea.addEventListener('input', autoResize);
            console.log("Mounted -----------------------------")
            function autoResize() {
                console.log("Resize ", (this.scrollHeight) + 'px')
                this.style.height = 'auto'; // Réinitialiser la hauteur
                this.style.height = (this.scrollHeight) + 'px'; // Ajuster la hauteur selon le contenu
                const div = document.getElementById('div-message-atlas');
                div.scrollTop = div.scrollHeight;
            }
            this.setScrollEnd()

        },
        methods: {
            sendMessageViaSocket(message) {
                console.log(message)
                this.socket.emit('message-2', message);
            },
            onCloseEdit: function () {

                this.$emit("closeChat")
            },
            addAtlasMessage(message) {
                if (message?.message == undefined) return
                if (message?.message == "") return

                console.log("addAtlasMessage")
                console.log(message)
                let index = this.messages.findIndex(a => (a.type == "wait"))
                this.messages.splice(index, 1);

                let d = new Date()

                let h = d.getHours()
                let m = d.getMinutes();
                /*
                          how many games we received yesterday?
                                             let messageToDisplay = {
                                                 id: (this.messages.length + 1).toString(),
                                                 message: message?.message,
                                                 type: "message",
                                                 author: "Atlas",
                                                 image: "65112cef9115a8fa4fbc065d",
                                                 date: h + ":" + m,
                                                 class: " bg-green-100"
                                             }
                             console.log(messageToDisplay)
                */
                if (m < 10) m = "0" + m
                message.message.date = h + ":" +  m
                this.messages.push(message.message)


                this.$nextTick(() => {
                    // Une fois le DOM mis à jour, faire défiler jusqu'à la fin
                    this.setScrollEnd();
                });

            },
            setScrollEnd() {
                const div = document.getElementById('div-message-atlas');
                div.scrollTop = div.scrollHeight;
            },
            sendMessage() {
                if (this.text_message == undefined) return
                if (this.text_message == "") return

                let d = new Date()

                let h = d.getHours()
                let mo = d.getMinutes();



                //  let v = this;
                let message = {
                    id: (this.messages.length + 1).toString(),
                    message: this.text_message,
                    author: "Vous",
                    type: "message",
                    image: "64b583450ff4d4705bcea51f",
                    date: h + ":" + mo,
                    class: " bg-gray-100"
                }

                this.messages.push(message)
                this.sendMessageViaSocket(message)
                this.text_message = ""
                this.$nextTick(() => {
                    // Une fois le DOM mis à jour, faire défiler jusqu'à la fin
                    this.setScrollEnd();



                    let wait = {
                        id: "99",
                        type: "wait"

                    }

                    this.messages.push(wait)

                    this.$nextTick(() => {
                        this.setScrollEnd();

                        // Lancer une action après 2 secondes
                        // setTimeout(() => v.addAtlasMessage(), 2000); // Délai de 2 secondes (2000 millisecondes)

                    });
                });
            }
        },
    }
</script>
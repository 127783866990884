<template>
  <div class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start    flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          :for="`copies-input-${properties?.id}`"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Nombre de copie</label>
        <input
          :id="`copies-input-${properties?.id}`"
          v-model="copies"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeCopies"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          :for="`product-input-${properties?.id}`"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Produit</label>
        <input
          :id="`product-input-${properties?.id}`"
          v-model="product"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeProduct"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          :for="`image-input-${properties?.id}`"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Image Produit</label>
        <input
          :id="`image-input-${properties?.id}`"
          v-model="image"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeimage"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Hauteur
          de la zone de signature</label>


        <div class="flex w-full relative mt-3">
          <input
            id="separator-range"
            v-model="height"
            type="range"
            min="100"
            max="500"
            value="100"
            step="1"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          >

          <label
            for="separator-range"
            class="absolute -bottom-9 start-0 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            @click="setHeight(100)"
          >100px</label>

          <label
            for="separator-range"
            class="absolute -bottom-9 start-1/4 -translate-x-1/2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            @click="setHeight(200)"
          >200px</label>

          <label
            for="separator-range"
            class="absolute -bottom-9 start-2/4 -translate-x-1/2 block mb-2 text-sm font-medium text-gray-900 dark:text-white "
            @click="setHeight(300)"
          >300px</label>

          <label
            for="separator-range"
            class="absolute -bottom-9  start-3/4 -translate-x-1/2 block mb-2 text-sm font-medium text-gray-900 dark:text-white "
            @click="setHeight(400)"
          >400px</label>


          <label
            for="separator-range"
            class="absolute -bottom-9 end-0  block mb-2 text-sm font-medium text-gray-900 dark:text-white justify-end"
            @click="setHeight(500)"
          >500px</label>
        </div>

        <div class="w-full flex flex-row hidden">
          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="default-radio-1"
              v-model="format"
              type="radio"
              value="date-time"
              name="default-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputFormat"
            >
            <label
              for="default-radio-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Toute la hauteur</label>
          </div>


          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="default-radio-2"
              v-model="format"
              checked
              type="radio"
              value="date"
              name="default-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputFormat"
            >
            <label
              for="default-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Hauteur Fixe : </label>
          </div>

          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="default-input"
              v-model="height"
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @input="onInputTitle"
            >
          </div>
        </div>
      </div>



      <div class=" mt-6  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Options</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditorPropertiesPrint',
        props: {
            properties: Object
        },
        data() {

            return {
                title: "",
                description: "",
                format: "",
                attribute: "",
                height: "300",
                image: "",
                product: "",
                copies: 50,
                isTitleDisplayed: false,
                isDescriptionDisplayed: false,


            }
        },
        watch:
        {
            properties: function () {
                this.title = this.properties?.title;
                this.format = this.properties?.format;

                this.product = this.properties?.product
                this.image = this.properties?.image
                this.copies = this.properties?.copies


                if (this.properties?.height == undefined)
                    this.height = 300
                else
                    this.height = parseInt(this.properties?.height.replace("px", ""))
                this.isTitleDisplayed = this.properties?.option?.title;
                this.attribute = this.properties?.attribute;
                this.isDescriptionDisplayed = this.properties?.option?.description;
                this.description = this.properties?.description;
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.format = this.properties?.format



            this.product = this.properties?.product
            this.image = this.properties?.image
            this.copies = this.properties?.copies


            if (this.properties?.height == undefined)
                this.height = 300
            else
                this.height = parseInt(this.properties?.height.replace("px", ""))

            this.isTitleDisplayed = this.properties?.option?.title;
            this.attribute = this.properties?.attribute;
            this.isDescriptionDisplayed = this.properties?.option?.description;
            this.description = this.properties?.description;
        },
        methods:
        {

            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'attribute', value: this.attribute })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.title', value: this.isTitleDisplayed })
            },
            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.description', value: this.isDescriptionDisplayed })
            },
            onChangeHeight: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'height', value: this.height + "px" })
            },


            onChangeCopies: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'copies', value: this.copies    })
            },
            onChangeProduct: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'product', value: this.product })
            },
            onChangeImage: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'image', value: this.image })
            },




            onInputFormat: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            }
        }
    }
</script>
<template>
  <div class="w-full ">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres
    </h3>
 
    <div class="  w-full grid gap-4 mb-4 sm:grid-cols-1  ">
      <div class="flex flex-col  w-full ">
        <label
          for="Type"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Type   </label>
        <ul
          class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-1"
                v-model="editFormat"
                type="radio"
                value="text"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('text')"
              >
              <label
                for="edit-type-radio-1"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Texte</label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-2"
                v-model="editFormat"
                type="radio"
                value="number"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('number')"
              >
              <label
                for="edit-type-radio-2"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Nombre</label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-3"
                v-model="editFormat"
                type="radio"
                value="distance"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('distance')"
              >
              <label
                for="edit-type-radio-3"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Distance</label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-4"
                v-model="editFormat"
                type="radio"
                value="mass"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('mass')"
              >
              <label
                for="edit-type-radio-4"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Masse</label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-5"
                v-model="editFormat"
                type="radio"
                value="volume"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('volume')"
              >
              <label
                for="edit-type-radio-5"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Volume</label>
            </div>
          </li>
          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-6"
                v-model="editFormat"
                type="radio"
                value="percent"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('percent')"
              >
              <label
                for="edit-type-radio-6"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Pourcentage</label>
            </div>
          </li>

          <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                id="edit-type-radio-7"
                v-model="editFormat"
                type="radio"
                value="energy"
                name="edit-type-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                @change="onChangeEditFormat('energy')"
              >
              <label
                for="edit-type-radio-7"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Energie</label>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="units.length > 0"
      class="w-full grid gap-4 mb-2 sm:grid-cols-1   "
    >
      <div class="flex flex-col  w-full ">
        <label
          for="Type"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Unité
          par défaut {{ editUnit }}</label>
      </div>
      <ul
        class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      >
        <li
          v-for="(unit,index) in units"
          :key="unit"
          class="w-24  border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
        >
          <div class="w-full flex flex-row   items-center ps-3">
            <input
              :id="`edit-unit-radio-${index}`"
              v-model="editUnit"
              type="radio"
              :value="unit"
              name="edit-unit-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              @change="onChangeEditUnit(unit)"
            >
            <label
              :for="`edit-unit-radio-${index}`"
              class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >{{ unit }}</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditField',
        components: {

        },
        props: {
input: Object
          
        },
        data() {
            return ({


                editUnit: "",
                editFormat: "",
                units: [],


                percent: [
                    '%'
                ],
                volume: [
                    'l',
                    'cl',
                    'ml',
                    "m3"
                ],
                mass: [
                    'kg',
                    'g',
                    'mg',
                    'µg'
                ],
                energy: [
                    'kcal',
                    'cal',
                    'kj',
                    'j'
                ]
            })
        },
        watch: {

            unit: function () {


                this.editUnit = this.unit
                if (this.format == "mass") { this.units = this.mass; }
                else if (this.format == "volume") { this.units = this.volume; }
                else if (this.format == "distance") { this.units = this.distance; }
                else if (this.format == "energy") { this.units = this.energy; }
                else {
                    this.units = [];
                }

            },
            format: function () {

                this.editUnit = this.input?.unit
                if (this.input?.format != undefined)
                    this.editFormat = this.input?.format
                else
                    this.editFormat = "text"
                if (this.editFormat == "mass") { this.units = this.mass; }
                else if (this.editFormat == "volume") { this.units = this.volume; }
                else if (this.editFormat == "distance") { this.units = this.distance; }
                else if (this.editFormat == "energy") { this.units = this.energy; }
                else {
                    this.units = [];

                }
            }
        },

        mounted() {
          //  console.log("EditField Mounted")

            this.editUnit = this.input?.unit

            if (this.input?.format != undefined)
                this.editFormat =  this.input?.format
            else
                this.editFormat = "text"



                if (this.editFormat == "mass") { this.units = this.mass; }
                else if (this.editFormat == "volume") { this.units = this.volume; }
                else if (this.editFormat == "distance") { this.units = this.distance; }
                else if (this.editFormat == "energy") { this.units = this.energy; }
                else {
                    this.units = [];}
        },
        methods: {


            onChangeEditUnit: function (unit) {
                this.$emit("changeValue", { format: this.editFormat, unit: unit })
            },


            onChangeEditFormat: function (format) {

                if (format == "mass") { this.units = this.mass; this.editUnit = "g"; }
                else if (format == "volume") { this.units = this.volume; this.editUnit = "l"; }
                else if (format == "distance") { this.units = this.distance; this.editUnit = "cm"; }
                else if (format == "energy") { this.units = this.energy; this.editUnit = "kcal"; }
                else {
                    this.units = [];
                    this.editUnit = "";
                    if (format == "percent") { this.editUnit = "%"; }
                }

                this.$emit("changeValue", { format: this.editFormat, unit: this.editUnit })

            },
            getEditUnit: function (format, unit) {
                if (format == 'percent') return unit;
                if (format == 'mass') return unit;
                if (format == 'volume') return unit;
                if (format == 'distance') return unit;
                if (format == 'energy') return unit;



                return "";
            },


        }
    }
</script>
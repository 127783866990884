<template>
  <div class="w-full  flex flex-col relative  h-screen">
    <div class="h-16   w-full  flex flex-row relative">
      <div class="text-4xl">
        {{ template?.name?.fr }}
      </div>
    </div>
    <div class="h-16   w-full  flex flex-row relative">
      <div
        class=" w-72  border border-gray-800 text-gray-800  h-full flex flex-col items-start justify-center p-2"
      >
        <button
          type="button"
          class="w-56 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-base px-3 py-1.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
          @click="onSelectDataSource()"
        >
          {{ currentDataSourceName }}
        </button>
      </div>
      <div class=" w-full bg-gray-100 border border-gray-800 text-gray-800  ">
        ...
      </div>
    </div>

    <div class="h-full   w-full  flex flex-row relative">
      <div
        class=" w-72   border border-gray-800 text-gray-800  h-full flex flex-col items-start justify-center p-2"
      >
        <div
          class="w-full text-gray-900  h-full border border-gray-800 font-medium rounded-lg text-base px-2 py-1.5 text-center me-2 mb-2  flex flex-col "
        >
          <div class="flex flex-row w-full justify-start items-center">
            <div class="w-full text-left font-semibold text-xs">
              ATTRIBUTS
            </div>
            <div class="w-6 font-semibold">
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          {{ chartOptions.chart.type }}
          <div class="flex w-full mb-2">
            <form class="max-w-md mx-auto  w-full">
              <div class="relative  w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                  <svg
                    class="w-3 h-3 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  id="default-search"
                  type="search"
                  class="block w-full p-2 ps-6 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Attribut..."
                  required
                >
              </div>
            </form>
          </div>
          <div class="flex flex-col  w-full overflow-y-show">
            <draggable
              ghost-class="ghost"
              :group="{ name: 'people', pull: 'clone', put: false }"
              :component-data="getComponentData()"
              class="mt-4 w-full h-96 min-h-96 border-1 border-solid items-start flex flex-col   mb-3 border-gray-300   "
              :list="items"
              item-key="id"
              @start="dragging=true"
              @move="dragging=true"
              @end="dragging=false"
            >
              <template #item="{element}">
                <div class="w-full">
                  <button
                    type="button"
                    class="w-full flex flex-row items-center justify-start  border border-red-100  hover:border-gray-800 hover:bg-gray-200 focus:ring-2 focus:outline-none focus:ring-gray-300  rounded-lg text-xs   py-1.5   mb-2   "
                  >
                    <div
                      v-if="element.type!='object'"
                      class="w-4 text-gray-300"
                    >
                      <i class="fa-solid fa-grip-vertical" />
                    </div>
                    <div class="w-4 text-gray-500 ml-2">
                      <i
                        v-if="element.type=='string'"
                        class="fa-solid fa-font"
                      />
                      <i
                        v-if="element.type=='numeric'"
                        class="fa-solid fa-hashtag"
                      />
                      <i
                        v-if="element.type=='object'"
                        class="fa-solid fa-caret-right"
                      />
                      <i
                        v-if="element.type=='geo'"
                        class="fa-solid fa-earth-americas"
                      />
                    </div>
                    <div class="ml-2 text-gray-900 font-semibold text-left text-xs w-full">
                      {{ element?.name?.FR }}
                    </div>
                    <div class="w-4 text-gray-500">
                      <i
                        v-if="element.type=='object'"
                        class="fa-solid fa-bars-staggered"
                      />
                    </div>
                  </button>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>




      <div class="w-72  flex flex-row    border border-gray-800 text-gray-800  w-full  h-full">
        <div
          class="w-72 text-gray-900  h-full border-r border-gray-800 font-medium  text-base px-2 py-1.5 text-center me-2 mb-2  flex flex-col  "
        >
          <div class="  w-full    text-gray-800 rounded  flex flex-col py-2 ">
            <div class="w-full font-semibold text-xs text-left">
              chart type
            </div>
            <div class="w-full">
              <button
                id="dropdownUsersButton2"
                data-dropdown-toggle="dropdownUsers2"
                data-dropdown-placement="bottom"
                class="w-full text-gray-800 border border-gray-600 bg-gray-100 hover:bg-gray-100 focus:ring-2 focus:outline-none focus:ring-gray-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center "
                type="button"
              >
                <div class="w-full text-left text-xs flex items-center">
                  <img
                    class="w-6 h-6 me-2 "
                    :src="selectedType.image"
                    :alt="selectedType.name"
                  >
                  {{ selectedType.name }}
                </div>



                <svg
                  class="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>


              <!-- Dropdown menu -->
              <div
                id="dropdownUsers2"
                class="z-10 hidden bg-white rounded-lg shadow w-64 dark:bg-gray-700"
              >
                <ul
                  class="h-96 py-2 overflow-y-auto text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownUsersButton2"
                >
                  <div
                    v-for="report in reports"
                    :key="report.id"
                  >
                    <li
                      class="text-bold text-sm border-b border-t-gray-200 pt-1 w-full text-left px-2"
                    >
                      {{ report.name }}
                    </li>
                    <li>
                      <div
                        v-for="type in report.types"
                        :key="type.id"
                        class="cursor-pointer flex items-center px-4 py-2 text-xs  hover:bg-blue-100    border-l-4  hover:border-blue-400 hover:border-l-4   dark:hover:bg-gray-600 dark:hover:text-white"
                        :class="{ 'border-green-400  ' : (selectedType.id == type.id), 'border-white ' : (selectedType.id != type.id)}"
                        @click="onSelectChartType(report.id, type)"
                      >
                        <img
                          class="w-6 h-6 me-2"
                          :src="type.image"
                          :alt="type.name"
                        >
                        {{ type.name }} {{ selectedType.id }} - {{ type.id }}
                      </div>
                    </li>
                  </div>
                </ul>
                <a
                  href="#"
                  class="flex items-center p-3 text-sm font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-blue-500 hover:underline"
                >
                  <svg
                    class="w-4 h-4 me-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path
                      d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z"
                    />
                  </svg>
                  Add new user
                </a>
              </div>
            </div>
          </div>

          <div
            class=" w-full text-gray-900  h-full border border-gray-800 font-medium rounded-lg text-base px-2 py-1.5 text-center me-2 mb-2  flex flex-col   "
          >
            <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
              <ul
                id="default-tab"
                class="flex flex-row justify-between -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#default-tab-content"
                role="tablist"
              >
                <li
                  role="presentation"
                  class="me-1 border-b-2 border-b-blue-500"
                >
                  <button
                    id="profile-tab"
                    class="inline-block py-2 px-2 border-b-2 rounded-t-lg font-semibold"
                    data-tabs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Définition
                  </button>
                </li>
                <li
                  class="me-1"
                  role="presentation"
                >
                  <button
                    id="dashboard-tab"
                    class="inline-block py-2  px-2  border-b-2 rounded-t-lg font-semibold hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    data-tabs-target="#dashboard"
                    type="button"
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="false"
                  >
                    Filtres
                  </button>
                </li>
                <li
                  class="me-1"
                  role="presentation"
                >
                  <button
                    id="settings-tab"
                    class="inline-block py-2  px-2  border-b-2 rounded-t-lg font-semibold hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    data-tabs-target="#settings"
                    type="button"
                    role="tab"
                    aria-controls="settings"
                    aria-selected="false"
                  >
                    Théme
                  </button>
                </li>
              </ul>
            </div>
            <div id="default-tab-content">
              <div
                id="profile"
                class="hidden p-1 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <ContinuousLineProperties v-if="selectedType.id=='chart1'" />
                <StackedColumn
                  v-if="selectedType.id=='chart2'"
                  @add-axis-x="onAddAxisX"
                />
              </div>
              <div
                id="dashboard"
                class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="dashboard-tab"
              >
                <p class="text-sm text-gray-500 dark:text-gray-400">
                  This is some placeholder content the
                  <strong class="font-medium text-gray-800 dark:text-white">Dashboard tab's associated
                    content</strong>. Clicking another tab will toggle the visibility of this one
                  for the next. The tab JavaScript swaps classes to control the content visibility and
                  styling.
                </p>
              </div>
              <div
                id="settings"
                class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="settings-tab"
              >
                <p class="text-sm text-gray-500 dark:text-gray-400">
                  This is some placeholder content the
                  <strong class="font-medium text-gray-800 dark:text-white">Settings tab's associated
                    content</strong>. Clicking another tab will toggle the visibility of this one
                  for the next. The tab JavaScript swaps classes to control the content visibility and
                  styling.
                </p>
              </div>
            </div>
          </div>
        </div>




        <ChartView
          :chart-options="chartOptions"
          :series="series"
        />


        <div class="w-16 bg-black h-full">
          *
        </div>
      </div>
    </div>
    <ChartViewSourceSelectModal 
      :sources="datasources" 
      :input="inputDataSource"  
      @close="OnDataSourceSelectClose"
      @confirm="OnDataSourceSelectConfirm"
    />
  </div>
</template>
<script>
    import draggable from 'vuedraggable'
    import ContinuousLineProperties from "@/pages/ChartEditorView/ContinuousLine.vue"
    import StackedColumn from "@/pages/ChartEditorView/StackedColumn.vue"
    import ChartView from "@/pages/ChartEditorView/ChartView.vue"


    import ChartViewSourceSelectModal from "@/pages/ChartEditorView/CharViewSourceSelectModal.vue"
    import { Modal /*, Dismiss*/ } from 'flowbite';


    import service from "@/services/FullService.vue"


    import { mapGetters } from "vuex";
    export default {
        name: 'ChartEditorView',
        components: {
            draggable, StackedColumn, ContinuousLineProperties, ChartView, ChartViewSourceSelectModal
        },
    
        data() {
            return {
                modalSelectSource: null,
                datasources: [],
                inputDataSource:
                {
                    id: null,
                    building: null,
                    street: null,
                    code: null,
                    city: null,
                    country: null,
                    for: null,
                    phone: null
                },
                template: {},
                currentDataSourceName: "locations",
                currentDataSourceId: null,
                activeNames: null,
                dragging: false,
                chartOptions: {
                    chart: {
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },

                    title: {
                        text: 'Area with Negative Values',
                        align: 'left',
                        style: {
                            fontSize: '14px'
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    yaxis: {
                        tickAmount: 4,
                        floating: false,

                        labels: {
                            style: {
                                colors: '#8e8da4',
                            },
                            offsetY: -7,
                            offsetX: 0,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    fill: {
                        opacity: 0.5
                    },
                    tooltip: {
                        x: {
                            format: "yyyy",
                        },
                        fixed: {
                            enabled: false,
                            position: 'topRight'
                        }
                    },
                    grid: {
                        yaxis: {
                            lines: {
                                offsetX: -30
                            }
                        },
                        padding: {
                            left: 20
                        }
                    }
                },
                series: [
                    {
                        name: 'north',
                        data: [{
                            x: 1996,
                            y: 322
                        },
                        {
                            x: 1997,
                            y: 324
                        },
                        {
                            x: 1998,
                            y: 329
                        },
                        {
                            x: 1999,
                            y: 342
                        },
                        {
                            x: 2000,
                            y: 348
                        },
                        {
                            x: 2001,
                            y: 334
                        },
                        {
                            x: 2002,
                            y: 325
                        },
                        {
                            x: 2003,
                            y: 316
                        },
                        {
                            x: 2004,
                            y: 318
                        },
                        {
                            x: 2005,
                            y: 330
                        },
                        {
                            x: 2006,
                            y: 355
                        },
                        {
                            x: 2007,
                            y: 366
                        },
                        {
                            x: 2008,
                            y: 337
                        },
                        {
                            x: 2009,
                            y: 352
                        },
                        {
                            x: 2010,
                            y: 377
                        },
                        {
                            x: 2011,
                            y: 383
                        },
                        {
                            x: 2012,
                            y: 344
                        },
                        {
                            x: 2013,
                            y: 366
                        },
                        {
                            x: 2014,
                            y: 389
                        },
                        {
                            x: 2015,
                            y: 334
                        }
                        ]
                    }, {
                        name: 'south',
                        data: [
                            {
                                x: 1996,
                                y: 162
                            },
                            {
                                x: 1997,
                                y: 90
                            },
                            {
                                x: 1998,
                                y: 50
                            },
                            {
                                x: 1999,
                                y: 77
                            },
                            {
                                x: 2000,
                                y: 35
                            },
                            {
                                x: 2001,
                                y: -45
                            },
                            {
                                x: 2002,
                                y: -88
                            },
                            {
                                x: 2003,
                                y: -120
                            },
                            {
                                x: 2004,
                                y: -156
                            },
                            {
                                x: 2005,
                                y: -123
                            },
                            {
                                x: 2006,
                                y: -88
                            },
                            {
                                x: 2007,
                                y: -66
                            },
                            {
                                x: 2008,
                                y: -45
                            },
                            {
                                x: 2009,
                                y: -29
                            },
                            {
                                x: 2010,
                                y: -45
                            },
                            {
                                x: 2011,
                                y: -88
                            },
                            {
                                x: 2012,
                                y: -132
                            },
                            {
                                x: 2013,
                                y: -146
                            },
                            {
                                x: 2014,
                                y: -169
                            },
                            {
                                x: 2015,
                                y: -184
                            }
                        ]
                    }
                ],

                series2: [
                    {
                        name: 'north',
                        data: [{
                            x: 1996,
                            y: 5
                        },
                        {
                            x: 1997,
                            y: 5
                        },
                        {
                            x: 1998,
                            y: 5
                        },
                        {
                            x: 1999,
                            y: 5
                        },
                        {
                            x: 2000,
                            y: 5
                        },
                        {
                            x: 2001,
                            y: 5
                        },
                        {
                            x: 2002,
                            y: 5
                        },
                        {
                            x: 2003,
                            y: 5
                        },
                        {
                            x: 2004,
                            y: 5
                        },
                        {
                            x: 2005,
                            y: 5
                        },
                        {
                            x: 2006,
                            y: 5
                        },
                        {
                            x: 2007,
                            y: 5
                        },
                        {
                            x: 2008,
                            y: 5
                        },
                        {
                            x: 2009,
                            y: 5
                        },
                        {
                            x: 2010,
                            y: 5
                        },
                        {
                            x: 2011,
                            y: 5
                        },
                        {
                            x: 2012,
                            y: 5
                        },
                        {
                            x: 2013,
                            y: 5
                        },
                        {
                            x: 2014,
                            y: 5
                        },
                        {
                            x: 2015,
                            y: 5
                        }
                        ]
                    }, {
                        name: 'south',
                        data: [
                            {
                                x: 1996,
                                y: 9
                            },
                            {
                                x: 1997,
                                y: 9
                            },
                            {
                                x: 1998,
                                y: 9
                            },
                            {
                                x: 1999,
                                y: 9
                            },
                            {
                                x: 2000,
                                y: 9
                            },
                            {
                                x: 2001,
                                y: 9
                            },
                            {
                                x: 2002,
                                y: 9
                            },
                            {
                                x: 2003,
                                y: 9
                            },
                            {
                                x: 2004,
                                y: 9
                            },
                            {
                                x: 2005,
                                y: 9
                            },
                            {
                                x: 2006,
                                y: 9
                            },
                            {
                                x: 2007,
                                y: 9
                            },
                            {
                                x: 2008,
                                y: 9
                            },
                            {
                                x: 2009,
                                y: 9
                            },
                            {
                                x: 2010,
                                y: 9
                            },
                            {
                                x: 2011,
                                y: 9
                            },
                            {
                                x: 2012,
                                y: 9
                            },
                            {
                                x: 2013,
                                y: 9
                            },
                            {
                                x: 2014,
                                y: 9
                            },
                            {
                                x: 2015,
                                y: 9
                            }
                        ]
                    }
                ],


                selectedType: {},
                items: [

                    {
                        id: "_id",
                        name: "identifiant",
                        type: "string"
                    },
                    {
                        id: "agent",
                        name: "Agent",
                        type: "string"
                    },
                    {
                        id: "batch",
                        name: "Batch",
                        type: "string"
                    },

                    {
                        id: "city",
                        name: "City",
                        type: "string"
                    },

                    {
                        id: "country",
                        name: "Country",
                        type: "string"
                    },

                    {
                        id: "date",
                        name: "Date",
                        type: "date"
                    },


                    {
                        id: "geo",
                        name: "Geo",
                        type: "geo"
                    },


                    {
                        id: "header",
                        name: "Headers",
                        type: "object"
                    },


                    {
                        id: "id",
                        name: "Identifiant",
                        type: "string"
                    },


                    {
                        id: "ip",
                        name: "Addresse IP",
                        type: "string"
                    }
                    ,


                    {
                        id: "item",
                        name: "Article",
                        type: "string"
                    }



                ],
                reports: [
                    {
                        id: "bar",
                        name: "Bars",
                        types: [
                            {
                                id: "chart1",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "colum1",
                                name: "Column 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMgMjJ2LThoMnY4SDNabS0zIDBWNGgydjE4SDBabTYgMHYtN2gydjdINlptMyAwdi0yaDJ2Mkg5Wm0zIDB2LTVoMnY1aC0yWm0zIDB2LTJoMnYyaC0yWm0zIDB2LTZoMnY2aC0yWm0zIDB2LTNoMnYzaC0yWiIgZmlsbD0iIzNENEY1OCIvPjxwYXRoIGQ9Ik0zIDEzdi0zaDJ2M0gzWm0zIDFWN2gydjdINlptMyA1di0yaDJ2Mkg5Wm0zLTN2LTNoMnYzaC0yWm0zIDN2LTRoMnY0aC0yWm0zLTR2LTNoMnYzaC0yWm0zIDN2LTNoMnYzaC0yWk0wIDNWMGgydjNIMFoiIGZpbGw9IiNDMUM3QzYiLz48L3N2Zz4="
                            },
                            {
                                id: "chart2",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "chart3",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "chart4",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "chart5",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            }
                        ]
                    }
                    ,
                    {
                        id: "bar",
                        name: "Bar",
                        types: [
                            {
                                id: "chart10",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "colum11",
                                name: "Column 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMgMjJ2LThoMnY4SDNabS0zIDBWNGgydjE4SDBabTYgMHYtN2gydjdINlptMyAwdi0yaDJ2Mkg5Wm0zIDB2LTVoMnY1aC0yWm0zIDB2LTJoMnYyaC0yWm0zIDB2LTZoMnY2aC0yWm0zIDB2LTNoMnYzaC0yWiIgZmlsbD0iIzNENEY1OCIvPjxwYXRoIGQ9Ik0zIDEzdi0zaDJ2M0gzWm0zIDFWN2gydjdINlptMyA1di0yaDJ2Mkg5Wm0zLTN2LTNoMnYzaC0yWm0zIDN2LTRoMnY0aC0yWm0zLTR2LTNoMnYzaC0yWm0zIDN2LTNoMnYzaC0yWk0wIDNWMGgydjNIMFoiIGZpbGw9IiNDMUM3QzYiLz48L3N2Zz4="
                            },
                            {
                                id: "chart11",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "chart12",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "chart13",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            },
                            {
                                id: "chart14",
                                name: "Chart 1",
                                image: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgyMnYySDBWMFptMCA5aDV2MkgwVjlabTAgOWgxMHYySDB2LTJaIiBmaWxsPSIjM0Q0RjU4Ii8+PHBhdGggZD0iTTAgM2gxMnYySDBWM1ptMCA5aDl2Mkgwdi0yWm0wIDloN3YySDB2LTJaIiBmaWxsPSIjQzFDN0M2Ii8+PC9zdmc+"
                            }
                        ]
                    }
                ]
            }
        },
       
        computed: {
            draggingInfo() {
                return this.dragging ? "under drag" : "not";
            },
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            '$route.query.path': async function () {

            },
            '$route.params.id': async function () {
                await this.getDashboardTemplate()
            },
            config: function () {


            },
            'user.tenant_id': async function () {


            }
        },
        async mounted() {
            this.selectedType = this.reports[0].types[0]
            await this.getDashboardTemplate()


            const modalEl4 = document.getElementById('chart-view-source-modal');
            this.modalSelectSource = new Modal(modalEl4, {
                placement: 'center'
            });




        },
        methods: {



            async LoadAttributes(id) {
                let filters = {}
                let items = await service.getData("data-sources/666f27384192946eaf7b1420/records/"+id,   this.user?.token, filters)
              
              console.log("----------------------------------------------------------------")
              console.log("----------------------------------------------------------------")
              console.log("----------------------------------------------------------------")
              console.log("----------------------------------------------------------------")
              console.log("----------------------------------------------------------------")
              console.log("----------------------------------------------------------------")
                console.log(items.items)
                this.items = items.items
            },



            async LoadDataSource() {
                let filters = {limit:1000, type:"data"}
                let records = await service.getData("data-sources",   this.user?.token, filters)
                console.log(this.datasources)
                this.datasources = records

                this.LoadAttributes("66184dea2cdd7ea01681abd8") 
            },
            OnDataSourceSelectClose() {
                if (this.modalSelectSource == null) return;
                this.modalSelectSource.hide()
            },
            OnDataSourceSelectConfirm() {
                if (this.modalSelectSource == null) return;
                this.modalSelectSource.hide()
            },
            async onSelectDataSource() {
                if (this.modalSelectSource == null) return;

                await this.LoadDataSource();
                this.modalSelectSource.show()
            },

            buildChartOptions() {


                let chart = this.template?.items[0]

                if (chart != undefined) {

                    //Chart Type
                    this.chartOptions.chart = {
                        chart: {
                            type: chart.graph.type
                        }


                    }

                }
            },




            async getDashboardTemplate() {
                const id = "664c654d055a7b7e00394ca0"
                let collection = "data-sources/" + id + '/records'
                let record = await service.getRecord(collection, this.$route.params.id, this.user?.token)
                console.log(record)
                this.template = record
                this.buildChartOptions()
            },
            onAddAxisX(item) {
                console.log(item)
                this.series = this.series2
            },

            onSelectChartType(report, type) {
                this.chartOptions.chart.type = 'area'
                this.selectedType = type;

            },
            cloneDog({ id }) {
                return {
                    id: id,
                    name: `cat ${id}`
                };
            },
            handleChange() {
                console.log('changed');
            },
            inputChanged(value) {
                console.log('inputChanged', value);
            },

            getComponentData() {
                return {
                    onChange: this.handleChange,
                    onInput: this.inputChanged,
                    wrap: true,
                    value: this.activeNames
                };
            },
        }
    }
</script>


<style scoped>
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }
</style>
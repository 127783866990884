<template>
  <div
    class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6 pb-6">
      <h3 class="mb-5 text-lg font-medium text-gray-900 dark:text-white">
        Sélectionner le type de composant : {{ index }}
      </h3>
      <ul class="grid w-full gap-6 md:grid-cols-2">
        <li @click="selectType('title')">
          <input
            id="hosting-small"
            type="radio"
            name="hosting"
            value="hosting-small"
            class="hidden peer"
            required
          >
          <label
            for="hosting-small"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Titre</div>
              <div class="w-full">Good for small websites</div>
            </div>

          </label>
        </li>
        <li @click="selectType('edit')">
          <input
            id="hosting-big"
            type="radio"
            name="hosting"
            value="hosting-big"
            class="hidden peer"
          >
          <label
            for="hosting-big"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Champ de saisie</div>
              <div class="w-full">Permet de saisir un texte ou un nombre avec le clavier</div>
            </div>

          </label>
        </li>
        <li @click="selectType('number')">
          <input
            id="hosting-4"
            type="radio"
            name="hosting"
            value="hosting-4"
            class="hidden peer"
          >
          <label
            for="hosting-4"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Liste de Nombres</div>
              <div class="w-full">Permet de sélectionner un nombre, température ou poucentage</div>
            </div>

          </label>
        </li>
        <li @click="selectType('avatar')">
          <input
            id="hosting-5"
            type="radio"
            name="hosting"
            value="hosting-5"
            class="hidden peer"
          >
          <label
            for="hosting-5"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Liste d'élements</div>
              <div class="w-full">Permet de sélection un emplacement, personne, produit,...</div>
            </div>

          </label>
        </li>
        <li @click="selectType('radio')">
          <input
            id="hosting-15"
            type="radio"
            name="hosting"
            value="hosting-15"
            class="hidden peer"
          >
          <label
            for="hosting-15"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Liste de choix</div>
              <div class="w-full">Permet de sélection une value parmis plusieurs</div>
            </div>

          </label>
        </li>
        <li @click="selectType('date')">
          <input
            id="hosting-50"
            type="radio"
            name="hosting"
            value="hosting-50"
            class="hidden peer"
          >
          <label
            for="hosting-50"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Sélection Date</div>
              <div class="w-full">Permet de saisir une date et ou une heure</div>
            </div>

          </label>
        </li>

        <li @click="selectType('table')">
          <input
            id="hosting-40"
            type="table"
            name="hosting"
            value="hosting-40"
            class="hidden peer"
          >
          <label
            for="hosting-40"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Tableau</div>
              <div class="w-full">Permet d'afficher des informations dans un tableau</div>
            </div>
          </label>
        </li>



        <li @click="selectType('hand-signature')">
          <input
            id="hosting-6"
            type="radio"
            name="hosting"
            value="hosting-6"
            class="hidden peer"
          >
          <label
            for="hosting-6"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Signature</div>
              <div class="w-full">Permet de présenter une zone pour signer</div>
            </div>
          </label>
        </li>


        <li @click="selectType('button')">
          <input
            id="hosting-7"
            type="radio"
            name="hosting"
            value="hosting-7"
            class="hidden peer"
          >
          <label
            for="hosting-7"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Bouton</div>
              <div class="w-full">Permet de déclencher des actions</div>
            </div>
          </label>
        </li>


        <li @click="selectType('take-photo')">
          <input
            id="hosting-8"
            type="radio"
            name="hosting"
            value="hosting-8"
            class="hidden peer"
          >
          <label
            for="hosting-8"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Prise Photo</div>
              <div class="w-full">Permet de prendre une photo </div>
            </div>
          </label>
        </li>

        <li @click="selectType('scan-doc')">
          <input
            id="hosting-9"
            type="radio"
            name="hosting"
            value="hosting-9"
            class="hidden peer"
          >
          <label
            for="hosting-9"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Scan Document</div>
              <div class="w-full">Permet de prendre en photo un document</div>
            </div>
          </label>
        </li>


        <li @click="selectType('scan-code')">
          <input
            id="hosting-10"
            type="radio"
            name="hosting"
            value="hosting-10"
            class="hidden peer"
          >
          <label
            for="hosting-10"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Code-barre / QR Code</div>
              <div class="w-full">Permet de scanner un code barre, QR code</div>
            </div>
          </label>
        </li>


        <li @click="selectType('html')">
          <input
            id="hosting-11"
            type="radio"
            name="hosting"
            value="hosting-11"
            class="hidden peer"
          >
          <label
            for="hosting-11"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">HTML / Markdown</div>
              <div class="w-full">Permet d'afficher sur zone en HTML ou Markdown</div>
            </div>
          </label>
        </li>
        <li @click="selectType('separator')">
          <input
            id="hosting-12"
            type="separator"
            name="hosting"
            value="hosting-12"
            class="hidden peer"
          >
          <label
            for="hosting-12"
            class="h-24 inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Séparateur</div>
              <div class="w-full">Permet de séparer les comppsants entre eux</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'GhostTableFormComponentDetails',
        props: {
            element: Object,
            index: Number
        },
        data() {

            return {
                title: "",
                format: "",
                data: "",
                isDisplayScan: false,
                isRequired: false,
                isTitleDisplayed: false

            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title;
                this.format = this.element.format;
                this.isRequired = this.element?.isRequired;
                this.isTitleDisplayed = this.element?.isTitleDisplayed;
                this.data = this.element?.data;
                this.isDisplayScan = this.element?.isDisplayScan;
            }
        },
        mounted() {
            this.title = this.element?.title
            this.format = this.element.format
            this.isRequired = this.element?.isRequired;
            this.isTitleDisplayed = this.element?.isTitleDisplayed;
            this.data = this.element?.data;
            this.isDisplayScan = this.element?.isDisplayScan;
        },
        methods:
        {
           
            selectType: function (type) {
                this.$emit("selectType", {type: type, id: this.element.id} )
            } 
        }
    }
</script>
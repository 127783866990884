<template>
  <div class="w-full">
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
      Paramètres {{ category }} (tag)
    </h3>

    <div class=" w-full grid gap-4 mb-4 sm:grid-cols-2">
      <div class="flex flex-col w-full pr-6">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Category</label>
        <select
          id="category"
          v-model="category"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @change="onChangeCategory"
        >
          <option
            v-for="item in values"
            :key="item._id"
            :value="item._id"
          >
            {{ item.name[lg] }} 
          </option>
        </select>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TagField',
        components: {

        },
        props: {
            values : Array,
            input: String
        },
        data() {
            return ({

                category: "" ,
                lg : "FR"
                
            })
        },
        watch: {

            input: function () { this.category = this.input?.source}
        },

        mounted() {
            console.log("EditField Mounted")
            this.category = this.input?.source
        },
        methods: {
            onChangeCategory: function ( ) {
                this.$emit("changeValue", this.category)
     
            } 

        }
    }
</script>
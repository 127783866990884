<template>
    <div class="relative   w-full   flex justify-center items-center p-1    ">
        <apexchart :id="`apex-${parent}`" :ref="`graph-chart-${parent}`" @updated="onUpdate()" @mounted="onMounted()"
            class="    w-full flex items-center  justify-center p-1 h-96"
            :class="{'opacity-0' : !loaded, 'opacity-100': loaded }" type="bar" :options="chartOptions" :series="series"
            height="350" />
    </div>
</template>

<script>

    let unit = null
    import VueApexCharts from "vue3-apexcharts";
    export default {
        name: 'ColumnBarChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            parent: String,
            title: String,
            records: Object,
            period: Object,
            config: Object
        },
        data: function () {
            return {
                myseries: [],
                loaded: false,
                chartOptions: {
                    chart: {
                        type: 'bar',
                        stacked: true,
                        height: 'auto',
                        width: '100%',
                        zoom: {
                            enabled: false
                        },

                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },

                    },
                    title: {
                        text: this.title
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 1,
                            horizontal: false,
                        }
                    },
                    legend: {
                        position: 'top'
                    },

                    tooltip: {
                        shared: true,
                        intersect: false
                    },
                    dataLabels: {
                        enabled: false
                    },

                    //xaxis: {
                    //  type: 'datetime',
                    //  axisBorder: {
                    //      offsetX: 13
                    //  }
                    //},
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: function (value) {

                                if (unit != undefined) return value + " " + unit;
                                return value //+ "kWh";
                            }
                        }
                    },
                    xaxis: {
                        //  categories: [],

                        type: 'datetime',
                        labels: {
                            show: true,
                            formatter2: function (value) {
                                let d = new Date(value).getDate()
                                let m = new Date(value).getMonth() + 1
                                return d + "/" + m
                                //   value = value.toString()
                                //    return new Date().toISOString()
                                //     let y = value.substring(0, 4)
                                //     let m = value.substring(4, 6)

                                //     return m + "/" + y.substring(2, 4);
                            }
                        }


                        /*  labels: {
                              show: true,
                              formatter: function (value) {
                                  value = value.toString()
                                  let y = value.substring(0, 4)
                                  let m = value.substring(4, 6)
  
                                  return m + "/" + y.substring(2, 4);
                              }
                          }*/
                    }


                },
                series: [
                    {
                        data: [],
                        name: ""
                    }
                ]
            };
        },
        watch: {

            'records': async function (val) {

                //console.log("ColumnBarChart WATCH records")
                //   console.log(val)
                unit = this.config?.graph?.unit;
                let t = this.transformData(val);

                let keys = Object.keys(t)


                //        let series = []

                //  console.log(JSON.stringify(keys))

                for (let i = 0; i < keys.length; i++) {

                    console.log(keys[i], t[keys[i]].data.length)

                    this.series[i] = { data: t[keys[i]].data }
                    // console.log(i, keys[i], this.series[i].data.length)
                }

                //   console.log(JSON.stringify(this.series[0].data))
                // this.series[0] = series

                /*  for (let i = 0; i < keys.length; i++) {
                      this.series[i] = { name: t[keys[i]].name, data: t[keys[i]].data }
  
                  }*/

                // this.chartOptions =
                // {
                //     xaxis: {
                //         categories: r.categories
                //     }
                //    labels : r.categories
                // }
                //     
            }


        },
        async mounted() {

            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.parent })

        },
        methods:
        {
            getNbDays(period) {
                switch (period.type) {
                    case '7days': return 7;
                    case '15days': return 15;
                    case '30days': return 30;
                    case '90days': return 90;
                    case '180days': return 180;
                    case '365days': return 365;
                    case 'custom': return 365;
                }
                return 7;
            },
            onUpdate() {
                console.log("onUpdate")
                this.loaded = true;
            },
            onMounted() {
                console.log("onMounted")
                // this.loaded = true;
            },



            transformData(records) {


                function formatDigit(n) {
                    if (parseInt(n) == 1) return "01"
                    if (parseInt(n) == 2) return "02"
                    if (parseInt(n) == 3) return "03"
                    if (parseInt(n) == 4) return "04"
                    if (parseInt(n) == 5) return "05"
                    if (parseInt(n) == 6) return "06"
                    if (parseInt(n) == 7) return "07"
                    if (parseInt(n) == 8) return "08"
                    if (parseInt(n) == 9) return "09"

                    return n
                }


                let data = {}

                let color = "color"

                //    console.log(" records.records.length", records.records.length)


                // data[color].data = []


                for (let i = 0; i < records.records.length; i++) {

                    if (records.records[i].color != undefined) {

                        color = records.records[i].color
                    }

                    if (data[color] == undefined) {
                        data[color] = { name: records.records[i].name, data: [] }

                        if (this.period.type != "custom") {
                            // By Day
                            let nb_day = this.getNbDays(this.period);
                            //console.log("period", this.period)
                            // console.log("NB Dates", nb_day)

                            for (let i = 0; i < nb_day; i++) {
                                let day = new Date();
                                day.setDate(day.getDate() - i);
                                let sday = day.getFullYear() + "-" + formatDigit(day.getMonth() + 1) + "-" + formatDigit(day.getDate())
                                data[color].data.push({ x: new Date(sday).getTime(), y: 0 })
                            }
                        }
                        else {


                            const startDate = new Date(this.period.started_at);
                            const endDate = new Date(this.period.ended_at);


                            for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {

                                let sday = d.getFullYear() + "-" + formatDigit(d.getMonth() + 1) + "-" + formatDigit(d.getDate())
                                data[color].data.push({ x: new Date(sday).getTime(), y: 0 })
                            }
                        }

                    }



                    // console.log("NB Dates 2", data[color].data.length)


                    let date_x = records.records[i].x.year + "-" + formatDigit(records.records[i].x.month + 1) + "-" + formatDigit(records.records[i].x.date)
                    // console.log(date_x)

                    let date_gt = new Date(date_x).getTime()
                    //  console.log(date_gt)
                    //  console.log(data[color].data)

                    let index = data[color].data.findIndex(function (a) { return a.x == date_gt })
                    //  console.log("////", date_x, index)
                    if (index != -1) {
                        //     console.log("FindIndex")
                        data[color].data[index] = {

                            x: new Date(date_x).getTime(),
                            y: records.records[i].y
                        }

                        ////      x: records.records[i].x,//new Date( records.records[i].x).getTime(),

                    } else console.log("NotFindIndex-----------------------------------------", date_x)



                }

                return data

            }
        }



    }
</script>
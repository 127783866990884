<template>
  <div class="   fixed top-0 bottom-0 left-0 right-0  bg-green-100">
    <div class="flex flew-row w-full bg-orange-100">
      <QueryBuilder
        :values="values"
        class="max-w-2xl"
        @update-value="onUpdateValue"
      />
      <div class="bg-gray-700 w-full text-white">
        {{ debug }}
      </div>
    </div>
  </div>
</template>

<script>

  import QueryBuilder from "@/components/QueryBuilder.vue";

  export default {
    name: 'QueryView',
    components: { QueryBuilder },
    props: {
    
    },
    data() {
      return {
        values: {
          rules: [
            {
              id: 0,
              type: "group",
              rules: [
                {
                  id: 1,
                  name: "Test",
                  type: "string",
                  attribute: "name",
                  action: "contains",
                  value: "Philippe"

                },
                {
                  id: 2,
                  name: "Test",
                  attribute: "name",
                  type: "string",
                  action: "contains",
                  value: "Philippe"

                },
                {
                  id: 3,
                  name: "Test",
                  attribute: "name",
                  type: "list",
                  action: "contains",
                  value: "Philippe"

                }
              ]
            },
            {
              id: 4,
              name: "Test",
              type: "string",
              attribute: "name",
              action: "contains",
              value: "Paul"

            },
            {
              id: 5,
              type: "group",
              rules: [
                {
                  id: 6,
                  name: "Test",
                  attribute: "name",
                  type: "string",
                  action: "contains",
                  value: "Paul"

                },
                {
                  id: 7,
                  name: "Test",
                  type: "string",
                  attribute: "name",
                  action: "contains",
                  value: "Paul"

                },
                {
                  id: 8,
                  name: "Test",
                  type: "string",
                  attribute: "name",
                  action: "contains",
                  value: "Paul"

                }
              ]
            }
          ]
        }

      }
    },
    computed: {
      debug: function () {
        return JSON.stringify(this.values, null, "\t")
      }
    },
    watch:
    {
      values: function () {
        console.log("QueryView", "Change Value")
      }
    },
    async mounted() {


    },
    methods : {

      onUpdateValue:function(event )
      {
        console.log("QueryView", "Change Value")

        for (let i=0; i< this.values.rules.length; i++)
                {
                    if (event.id == this.values.rules[i].id)
                    {
                        this.values.rules[i] = event.rules
                    }
                }



        console.log(JSON.stringify(this.values, null,"\t"))
      }

    }
  }
</script>


<style scoped>

</style>
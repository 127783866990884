<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center mb-6  "
  >
    <div class="flex flex-col items-start w-full   pt-6   px-6 ">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Hauteur du composant ({{ height }}px)
        </label>

        <div class="flex w-full relative mt-3">
          <input
            id="separator-range"
            v-model="height"
            type="range"
            min="0"
            max="100"
            value="50"
            step="1"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          >

          <label
            for="separator-range"
            class="absolute -bottom-9 start-0 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            @click="setHeight(0)"
          >0px</label>

          <label
            for="separator-range"
            class="absolute -bottom-9 start-1/4 -translate-x-1/2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            @click="setHeight(25)"
          >25px</label>

          <label
            for="separator-range"
            class="absolute -bottom-9 start-2/4 -translate-x-1/2 block mb-2 text-sm font-medium text-gray-900 dark:text-white bg-red-100"
            @click="setHeight(50)"
          >50px</label>

          <label
            for="separator-range"
            class="absolute -bottom-9  start-3/4 -translate-x-1/2 block mb-2 text-sm font-medium text-gray-900 dark:text-white bg-red-100"
            @click="setHeight(75)"
          >75px</label>


          <label
            for="separator-range"
            class="absolute -bottom-9 end-0  block mb-2 text-sm font-medium text-gray-900 dark:text-white justify-end"
            @click="setHeight(100)"
          >100px</label>
        </div>
      </div>

      <div class="mt-6  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Hauteur de la ligne</label>

        <div
          class="inline-flex rounded-md shadow-sm w-full"
          role="group"
        >
          <button
            type="button"
            :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (selected_height =='small') , 'text-gray-700 bg-gray-200 font-medium' : (selected_height !='small') , }"
            class="w-4/12 px-4 py-2 text-sm   border border-gray-300 rounded-s-lg hover:bg-gray-50 hover:text-gray-900            "
            @click="onClickHeight('small')"
          >
            Petit
          </button>
          <button
            type="button"
            :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (selected_height =='medium') , 'text-gray-700 bg-gray-200 font-medium' : (selected_height !='medium') , }"
            class="w-4/12 px-4 py-2 text-sm font-medium   border-t border-b border-gray-300 hover:bg-gray-50 hover:text-gray-900         "
            @click="onClickHeight('medium')"
          >
            Moyen
          </button>
          <button
            type="button"
            :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (selected_height =='large') , 'text-gray-700 bg-gray-200 font-medium' : (selected_height !='large') , }"
            class="w-4/12 px-4 py-2 text-sm font-medium   border border-gray-300 rounded-e-lg hover:bg-gray-50 hover:text-gray-900      "
            @click="onClickHeight('large')"
          >
            Grand
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'SeparatorTableFormComponentDetails',
        props: {
            properties: Object,
            index: Number,
        },
        data() {

            return {
                height: "50",
                line_height: "2px",
                selected_height: "medium",
                format: "",
                template: "title-tpl-1"
            }
        },
        watch:
        {
            properties: function () {
                this.height = parseInt(this.properties?.height.replace("px", ""))
                this.line_height = this.properties?.line_height
                if (this.properties?.line_height == "2px") {
                    this.selected_height = "small"
                } else
                    if (this.properties?.line_height == "4px") {
                        this.selected_height = "medium"
                    } else
                        if (this.properties?.line_height == "6px") {
                            this.selected_height = "large"
                        } else {
                            this.selected_height = "small"
                        }
                if (this.properties?.template != undefined)
                    this.template = this.properties?.template
            }
        },
        mounted() {
            this.height = parseInt(this.properties?.height.replace("px", ""))

            if (this.properties?.line_height == "2px") {
                this.selected_height = "small"
            } else
                if (this.properties?.line_height == "4px") {
                    this.selected_height = "medium"
                } else
                    if (this.properties?.line_height == "6px") {
                        this.selected_height = "large"
                    } else {
                        this.selected_height = "small"
                    }
            //this.line_height = this.properties?.line_height

            if (this.properties?.template != undefined)
                this.template = this.properties?.template

        },
        methods:
        {

            onClickHeight: function (choice) {
                this.selected_height = choice
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'height_range', value: choice })
                switch (choice) {
                    case 'small': this.line_height = "2px"; break;
                    case 'medium': this.line_height = "4px"; break;
                    case 'large': this.line_height = "6px"; break;
                }
            },
            setHeight: function (height) {
                this.height = height;
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'height', value: this.height + "px" })
            },

            onInputHeight: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'height', value: this.height + "px" })
            },
            onInputLineHeight: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'line_height', value: this.line_height })
            },

        }
    }
</script>
<template>
  <div class="relative mt-24  overflow-y-auto bg-gray-50 lg:ml-64 dark:bg-gray-900 justify-start flex">
    <main>
      <div class="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
        <div class="col-span-full mb-4 xl:mb-2">
          <h1 class="text-4xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
            {{ common_labels['TITLE_USER_SETTINGS'][lg] }}
          </h1>
        </div>
        <div class="col-span-full mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul
            id="myTab"
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            role="tablist"
          >
            <li
              class="mr-2"
              role="presentation"
            >
              <button
                id="profile-tab"
                class="inline-block p-4 border-b-2 rounded-t-lg"
                type="button"
                role="tab"
                :aria-selected="current_tab==0"
                @click="selectTab(0)"
              >
                Profile
              </button>
            </li>
            <li
              class="mr-2"
              role="presentation"
            >
              <button
                id="dashboard-tab"
                class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                type="button"
                role="tab"
                aria-controls="dashboard"
                :aria-selected="current_tab==1"
                @click="selectTab(1)"
              >
                Préférences
              </button>
            </li>
            <li
              class="mr-2"
              role="presentation"
            >
              <button
                id="settings-tab"
                class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                type="button"
                role="tab"
                aria-controls="settings"
                :aria-selected="current_tab==2"
                @click="selectTab(2)"
              >
                Sécurité
              </button>
            </li>
            <li role="presentation">
              <button
                id="contacts-tab"
                class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                type="button"
                role="tab"
                aria-controls="contacts"
                :aria-selected="current_tab==3"
                @click="selectTab(3)"
              >
                Historique
              </button>
            </li>
          </ul>
        </div>
       
        <!-- Right Content -->
        <div
          v-if="current_tab == 0"
          class="  col-span-full xl:col-auto"
        >
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <div class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
              <v-lazy-image
                v-if="user && (user.avatar != undefined)"
                id="image-user"
                class="mb-4 w-28 h-28 block h-full w-full object-cover object-center rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0  transition duration-300 ease-in-out "
                :src="user.avatar"
              />
              <div>
                <h3 class="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                  {{ user.first_name }} {{ user.last_name }}
                </h3>
                <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Software Engineer
                </div>
                <a
                  href="#"
                  class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                >
                  <svg
                    class="mr-2 -ml-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                    />
                    <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                  </svg>
                  {{ common_labels['BUTTON_CHANGE_PHOTO'][lg] }}
                </a>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <h3 class="justify-start flex mb-4 text-2xl font-bold dark:text-white">
              {{ common_labels['TITLE_LANGUAGE_TIME'][lg] }}
            </h3>
            <div class="mb-4">
              <label
                for="settings-language"
                class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-white"
              >
                {{ common_labels['LABEL_LANGUAGE'][lg] }}


              </label>
              <select
                id="settings-language"
                name="countries"
                class="bg-gray-50 border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option>English (US)</option>
                <option>Italiano</option>
                <option selected>
                  Français (France)
                </option>
                <option>正體字</option>
                <option>Español (España)</option>
                <option>Deutsch</option>
                <option>Português (Brasil)</option>
              </select>
            </div>
            <div class="mb-6">
              <label
                for="settings-timezone"
                class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-white"
              >
                {{ common_labels['LABEL_TIMEZONE'][lg] }}

              </label>
              <select
                id="settings-timezone"
                name="countries"
                class="text-left  bg-gray-50 border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option>GMT+0 Greenwich Mean Time (GMT)</option>
                <option>GMT+1 Central European Time (CET)</option>
                <option>GMT+2 Eastern European Time (EET)</option>
                <option>GMT+3 Moscow Time (MSK)</option>
                <option>GMT+5 Pakistan Standard Time (PKT)</option>
                <option>GMT+8 China Standard Time (CST)</option>
                <option>GMT+10 Eastern Australia Standard Time (AEST)</option>
              </select>
            </div>
            <div>
              <button
                class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Save
                all
              </button>
            </div>
          </div>
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <div class="flow-root">
              <h3 class="text-xl font-bold dark:text-white">
                Social accounts
              </h3>
              <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                <li class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="facebook-f"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="currentColor"
                          d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Facebook account
                      </span>
                      <a
                        href="#"
                        class="block text-sm font-normal text-primary-700 truncate hover:underline dark:text-primary-500"
                      >
                        www.facebook.com/themesberg
                      </a>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >Disconnect</a>
                    </div>
                  </div>
                </li>
                <li class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Twitter account
                      </span>
                      <a
                        href="#"
                        class="block text-sm font-normal text-primary-700 truncate hover:underline dark:text-primary-500"
                      >
                        www.twitter.com/themesberg
                      </a>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >Disconnect</a>
                    </div>
                  </div>
                </li>
                <li class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="github"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path
                          fill="currentColor"
                          d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Github account
                      </span>
                      <span class="block text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                        Not connected
                      </span>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >Connect</a>
                    </div>
                  </div>
                </li>
                <li class="pt-4 pb-6">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="dribbble"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Dribbble account
                      </span>
                      <span class="block text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                        Not connected
                      </span>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >Connect</a>
                    </div>
                  </div>
                </li>
              </ul>
              <div>
                <button
                  class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Save
                  all
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="current_tab == 0"
          class=" col-span-2"
        >
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <h3 class="mb-4 text-2xl text-left font-bold dark:text-white text-lg">
              {{ common_labels['TITLE_GENERAL_INFORMATION'][lg] }}
            </h3>
            <form action="#">
              <div class="grid grid-cols-6 gap-6">
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="first-name"
                    class="block mb-2 text-xl font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_FIRSTNAME'][lg] }}
                  </label>
                  <input
                    id="first-name"
                    v-model="current_user.first_name"
                    type="text"
                    name="first-name"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Bonnie"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="last-name"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_LASTNAME'][lg] }}</label>
                  <input
                    id="last-name"
                    v-model="current_user.last_name"
                    type="text"
                    name="last-name"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Green"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="address"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >{{ common_labels['LABEL_ADDRESS'][lg] }}</label>
                  <input
                    id="address"
                    v-model="current_user.location.address"
                    type="text"
                    name="address"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="e.g. California"
                    required
                  >
                </div>

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="zip-code"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >{{ common_labels['LABEL_ZIPCODE'][lg] }}
                  </label>
                  <input
                    id="zip-code"
                    v-model="current_user.location.postalCode"
                    type="number"
                    name="zip-code"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="123456"
                    required
                  >
                </div>

                <div class="text-lg text-left col-span-6 sm:col-span-3">
                  <label
                    for="city"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_CITY'][lg] }}</label>
                  <input
                    id="city"
                    v-model="current_user.location.city"
                    type="text"
                    name="city"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="e.g. San Francisco"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="country"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_COUNTRY'][lg] }}</label>
                  <input
                    id="country"
                    v-model="current_user.location.country"
                    type="text"
                    name="country"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="United States"
                    required
                  >
                </div>

                <hr class="h-px my-4 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_EMAIL'][lg] }}</label>
                  <input
                    id="email"
                    v-model="current_user.email"
                    type="email"
                    name="email"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="example@company.com"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="phone-number"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_PHONE'][lg] }}
                    Number</label>
                  <input
                    id="phone-number"
                    v-model="current_user.mobile"
                    type="number"
                    name="phone-number"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="e.g. +(12)3456 789"
                    required
                  >
                </div>
                <hr class="h-px my-4 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="organization"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white  text-lg"
                  >
                    {{ common_labels['LABEL_ORGANISATION'][lg] }}</label>
                  <input
                    id="organization"
                    type="text"
                    name="organization"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Company Name"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="department"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_DEPARTMENT'][lg] }}</label>
                  <input
                    id="department"
                    type="text"
                    name="department"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Development"
                    required
                  >
                </div>

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="role"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >{{ common_labels['LABEL_ROLE'][lg] }}</label>
                  <input
                    id="role"
                    type="text"
                    name="role"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="React Developer"
                    required
                  >
                </div>


                <div class="text-left col-span-6 sm:col-full">
                  <button
                    class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 text-lg"
                    type="submit"
                  >
                    {{ common_labels['BUTTON_SAVE_INFORMATION'][lg] }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          v-if="current_tab == 3"
          class="  col-span-full xl:col-auto"
        >
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <div class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
              <v-lazy-image
                v-if="user && (user.avatar != undefined)"
                id="image-user"
                class="mb-4 w-28 h-28 block h-full w-full object-cover object-center rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0  transition duration-300 ease-in-out "
                :src="user.avatar"
              />
              <div>
                <h3 class="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                  {{ user.first_name }} {{ user.last_name }}
                </h3>
                <div class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Software Engineer
                </div>
                <a
                  href="#"
                  class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                >
                  <svg
                    class="mr-2 -ml-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                    />
                    <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                  </svg>
                  {{ common_labels['BUTTON_CHANGE_PHOTO'][lg] }}
                </a>
              </div>
            </div>
          </div>
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <h3 class="justify-start flex mb-4 text-2xl font-bold dark:text-white">
              {{ common_labels['TITLE_LANGUAGE_TIME'][lg] }}
            </h3>
            <div class="mb-4">
              <label
                for="settings-language"
                class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-white"
              >
                {{ common_labels['LABEL_LANGUAGE'][lg] }}


              </label>
              <select
                id="settings-language"
                name="countries"
                class="bg-gray-50 border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option>English (US)</option>
                <option>Italiano</option>
                <option selected>
                  Français (France)
                </option>
                <option>正體字</option>
                <option>Español (España)</option>
                <option>Deutsch</option>
                <option>Português (Brasil)</option>
              </select>
            </div>
            <div class="mb-6">
              <label
                for="settings-timezone"
                class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-white"
              >
                {{ common_labels['LABEL_TIMEZONE'][lg] }}

              </label>
              <select
                id="settings-timezone"
                name="countries"
                class="text-left  bg-gray-50 border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option>GMT+0 Greenwich Mean Time (GMT)</option>
                <option>GMT+1 Central European Time (CET)</option>
                <option>GMT+2 Eastern European Time (EET)</option>
                <option>GMT+3 Moscow Time (MSK)</option>
                <option>GMT+5 Pakistan Standard Time (PKT)</option>
                <option>GMT+8 China Standard Time (CST)</option>
                <option>GMT+10 Eastern Australia Standard Time (AEST)</option>
              </select>
            </div>
            <div>
              <button
                class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Save
                all
              </button>
            </div>
          </div>
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <div class="flow-root">
              <h3 class="text-xl font-bold dark:text-white">
                Social accounts
              </h3>
              <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                <li class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="facebook-f"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="currentColor"
                          d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Facebook account
                      </span>
                      <a
                        href="#"
                        class="block text-sm font-normal text-primary-700 truncate hover:underline dark:text-primary-500"
                      >
                        www.facebook.com/themesberg
                      </a>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >Disconnect</a>
                    </div>
                  </div>
                </li>
                <li class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Twitter account
                      </span>
                      <a
                        href="#"
                        class="block text-sm font-normal text-primary-700 truncate hover:underline dark:text-primary-500"
                      >
                        www.twitter.com/themesberg
                      </a>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >Disconnect</a>
                    </div>
                  </div>
                </li>
                <li class="py-4">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="github"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path
                          fill="currentColor"
                          d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Github account
                      </span>
                      <span class="block text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                        Not connected
                      </span>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >Connect</a>
                    </div>
                  </div>
                </li>
                <li class="pt-4 pb-6">
                  <div class="flex items-center space-x-4">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 dark:text-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="dribbble"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"
                        />
                      </svg>
                    </div>
                    <div class="flex-1 min-w-0">
                      <span class="block text-base font-semibold text-gray-900 truncate dark:text-white">
                        Dribbble account
                      </span>
                      <span class="block text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                        Not connected
                      </span>
                    </div>
                    <div class="inline-flex items-center">
                      <a
                        href="#"
                        class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >Connect</a>
                    </div>
                  </div>
                </li>
              </ul>
              <div>
                <button
                  class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Save
                  all
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="current_tab == 3"
          class=" col-span-2"
        >
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
            <h3 class="mb-4 text-2xl text-left font-bold dark:text-white text-lg">
              {{ common_labels['TITLE_GENERAL_INFORMATION'][lg] }}
            </h3>
            <form action="#">
              <div class="grid grid-cols-6 gap-6">
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="first-name"
                    class="block mb-2 text-xl font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_FIRSTNAME'][lg] }}
                  </label>
                  <input
                    id="first-name"
                    v-model="current_user.first_name"
                    type="text"
                    name="first-name"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Bonnie"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="last-name"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_LASTNAME'][lg] }}</label>
                  <input
                    id="last-name"
                    v-model="current_user.last_name"
                    type="text"
                    name="last-name"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Green"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="address"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >{{ common_labels['LABEL_ADDRESS'][lg] }}</label>
                  <input
                    id="address"
                    v-model="current_user.location.address"
                    type="text"
                    name="address"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="e.g. California"
                    required
                  >
                </div>

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="zip-code"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >{{ common_labels['LABEL_ZIPCODE'][lg] }}
                  </label>
                  <input
                    id="zip-code"
                    v-model="current_user.location.postalCode"
                    type="number"
                    name="zip-code"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="123456"
                    required
                  >
                </div>

                <div class="text-lg text-left col-span-6 sm:col-span-3">
                  <label
                    for="city"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_CITY'][lg] }}</label>
                  <input
                    id="city"
                    v-model="current_user.location.city"
                    type="text"
                    name="city"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="e.g. San Francisco"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="country"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_COUNTRY'][lg] }}</label>
                  <input
                    id="country"
                    v-model="current_user.location.country"
                    type="text"
                    name="country"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="United States"
                    required
                  >
                </div>

                <hr class="h-px my-4 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_EMAIL'][lg] }}</label>
                  <input
                    id="email"
                    v-model="current_user.email"
                    type="email"
                    name="email"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="example@company.com"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="phone-number"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_PHONE'][lg] }}
                    Number</label>
                  <input
                    id="phone-number"
                    v-model="current_user.mobile"
                    type="number"
                    name="phone-number"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="e.g. +(12)3456 789"
                    required
                  >
                </div>
                <hr class="h-px my-4 col-span-12 sm:col-span-6 bg-gray-200 border-0 dark:bg-gray-700 w-full">

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="organization"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white  text-lg"
                  >
                    {{ common_labels['LABEL_ORGANISATION'][lg] }}</label>
                  <input
                    id="organization"
                    type="text"
                    name="organization"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Company Name"
                    required
                  >
                </div>
                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="department"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >
                    {{ common_labels['LABEL_DEPARTMENT'][lg] }}</label>
                  <input
                    id="department"
                    type="text"
                    name="department"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Development"
                    required
                  >
                </div>

                <div class="text-left col-span-6 sm:col-span-3">
                  <label
                    for="role"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-lg"
                  >{{ common_labels['LABEL_ROLE'][lg] }}</label>
                  <input
                    id="role"
                    type="text"
                    name="role"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="React Developer"
                    required
                  >
                </div>


                <div class="text-left col-span-6 sm:col-full">
                  <button
                    class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 text-lg"
                    type="submit"
                  >
                    {{ common_labels['BUTTON_SAVE_INFORMATION'][lg] }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>



      <div
        v-if="current_tab == 1"
        class="grid grid-cols-1 px-4 pt-2 xl:grid-cols-2 xl:gap-4"
      >
        <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800 xl:mb-0">
          <div class="flow-root">
            <h3 class="text-left text-2xl font-bold dark:text-white">
              Alerts & Notifications
            </h3>
            <p class="text-left text-lg font-normal text-gray-500 dark:text-gray-400">
              You can set up Themesberg to get
              notifications
            </p>
            <div class="mt-3  divide-y divide-gray-200 dark:divide-gray-700">
              <!-- Item 1 -->
              <div class="flex justify-between items-center py-4">
                <div class="text-left flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Company News
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get Themesberg news,
                    announcements, and product updates
                  </div>
                </div>
                <label class="relative inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  >
                  <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  />

                </label>
              </div>
              <!-- Item 2 -->
              <div class="text-left flex justify-between items-center py-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Account Activity
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get important notifications about
                    you or activity you've missed
                  </div>
                </div>
                <label class="relative inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  >
                  <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  />

                </label>
              </div>
              <!-- Item 3 -->
              <div class="flex  justify-between items-center py-4">
                <div class="text-left flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    Meetups Near You
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get an email when a Dribbble
                    Meetup is posted close to my location
                  </div>
                </div>
                <label class="relative inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  >
                  <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  />

                </label>
              </div>
              <!-- Item 4 -->
              <div class="text-left flex justify-between items-center pt-4">
                <div class="flex flex-col flex-grow">
                  <div class="text-lg font-semibold text-gray-900 dark:text-white">
                    New Messages
                  </div>
                  <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Get Themsberg news, announcements,
                    and product updates
                  </div>
                </div>
                <label class="relative inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  >
                  <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  />

                </label>
              </div>
            </div>
            <div class="mt-6">
              <button
                class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Save
                all
              </button>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 px-4 pt-2 xl:grid-cols-2 xl:gap-4">
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800 xl:mb-0">
            <div class="flow-root">
              <h3 class="text-left text-2xl font-bold dark:text-white">
                Email Notifications
              </h3>
              <p class="text-left text-lg font-normal text-gray-500 dark:text-gray-400">
                You can set up Themesberg to get
                email notifications
              </p>
              <div class="mt-3 divide-y divide-gray-200 dark:divide-gray-700">
                <!-- Item 1 -->
                <div class="text-left flex justify-between items-center py-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Rating reminders
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Send an email reminding me to
                      rate
                      an item a week after purchase
                    </div>
                  </div>
                  <label class="relative inline-flex items-center mb-4 cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      class="sr-only peer"
                    >
                    <div
                      class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    />

                  </label>
                </div>
                <!-- Item 2 -->
                <div class="text-left flex justify-between items-center py-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Item update notifications
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Send user and product
                      notifications for you
                    </div>
                  </div>
                  <label class="relative inline-flex items-center mb-4 cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      class="sr-only peer"
                    >
                    <div
                      class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    />

                  </label>
                </div>
                <!-- Item 3 -->
                <div class="text-left flex justify-between items-center py-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Item comment notifications
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Send me an email when someone
                      comments on one of my items
                    </div>
                  </div>
                  <label class="relative inline-flex items-center mb-4 cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      class="sr-only peer"
                    >
                    <div
                      class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    />

                  </label>
                </div>
                <!-- Item 4 -->
                <div class="text-left flex justify-between items-center pt-4">
                  <div class="flex flex-col flex-grow">
                    <div class="text-lg font-semibold text-gray-900 dark:text-white">
                      Buyer review notifications
                    </div>
                    <div class="text-base font-normal text-gray-500 dark:text-gray-400">
                      Send me an email when someone
                      leaves a review with their rating
                    </div>
                  </div>
                  <label class="relative inline-flex items-center mb-4 cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      class="sr-only peer"
                    >
                    <div
                      class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    />

                  </label>
                </div>
              </div>
              <div class="mt-6">
                <button
                  class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Save
                  all
                </button>
              </div>
            </div>
          </div>
        </div> 
      </div>

      <div
        v-if="current_tab == 2"
        class="grid grid-cols-1 px-4 pt-2 xl:grid-cols-2 xl:gap-4"
      >
        <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800 xl:mb-0">
          <div class="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800 xl:mb-0">
            <div class="flow-root">
              <h3 class="mb-4 text-xl font-bold dark:text-white">
                Password information
              </h3>
            </div>
          </div>

          <form action="#">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="current-password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Current password</label>
                <input
                  id="current-password"
                  type="text"
                  name="current-password"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="••••••••"
                  required
                >
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="new-password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >New
                  password</label>
                <input
                  id="new-password"
                  type="text"
                  name="new-password"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="••••••••"
                  required
                >
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="confirm-password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Confirm password</label>
                <input
                  id="confirm-password"
                  type="text"
                  name="confirm-password"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="••••••••"
                  required
                >
              </div>
              <div class="col-span-full">
                <div class="text-sm font-medium dark:text-white">
                  Password requirements:
                </div>
                <div class="mb-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                  Ensure that these requirements
                  are met:
                </div>
                <ul class="pl-4 space-y-1 text-gray-500 dark:text-gray-400">
                  <li class="text-xs font-normal">
                    At least 10 characters (and up to 100 characters)
                  </li>
                  <li class="text-xs font-normal">
                    At least one lowercase character
                  </li>
                  <li class="text-xs font-normal">
                    Inclusion of at least one special character, e.g., ! @ # ?
                  </li>
                  <li class="text-xs font-normal">
                    Some text here zoltan
                  </li>
                </ul>
              </div>
              <div class="col-span-6 sm:col-full">
                <button
                  class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  type="submit"
                >
                  Save all
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import common_labels from "@/assets/lg/user-profile.json"
  import { mapGetters } from "vuex";
  import VLazyImage from "v-lazy-image";
  export default {
    name: 'ProfileView',
    components: {
      VLazyImage
    },
    props: {
    
    },
    data() {
      return {
        current_tab: 0,
        current_user: {
          location: {}
        },
        common_labels: common_labels,
        lg: "FR"
      }

    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
  
    async mounted() {
      if (this.user == undefined) {
        this.$router.push("/login");
        return;
      }
      this.current_user = this.user
      if (this.current_user.location == undefined)
        this.current_user.location = {}

 
    },
    methods: {
      selectTab: function (n) {
     
        this.current_tab = n;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
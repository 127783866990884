<template>
  <div
    class="   cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Titre
          Table</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Definition du Tableau</label>

        <div class="w-full flex flex-row   items-center">
          <div class="flex flex-row items-center   text-left   ">
            <button
              :id="`dropdownSearchButton-${element.id}`"
              :data-dropdown-toggle="`dropdownSearch-${element.id}`"
              class="w-96 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
            >
              <span class="w-full text-left truncate">{{ form?.name }}</span>

              <svg
                class="w-2.5 h-2.5 ms-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div
              :id="`dropdownSearch-${element.id}`"
              class="w-96 z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700"
            >
              <div class="p-3">
                <label
                  for="input-group-search"
                  class="sr-only"
                >Search</label>
                <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    id="input-group-search"
                    type="text"
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 pr-2.5  py-2.5 pl-6 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    style="padding-left:40px"
                    placeholder="Rechercher une définition"
                  >
                </div>
              </div>
              <ul
                class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSearchButton"
              >
                <li
                  v-for="item in forms"
                  :id="item.id"
                  :key="item.id"
                >
                  <div
                    class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                    @click="selectSource(item)"
                  >
                    <label
                      class="  w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300   cursor-pointer"
                    >
                      {{ item.name }}
                    </label>
                  </div>
                </li>
              </ul>
              <a
                href="#"
                class="flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline"
              >
                <svg
                  class="w-4 h-4 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path
                    d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z"
                  />
                </svg>
                Delete user
              </a>
            </div>
          </div>
        </div>
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${element.id}`"
          v-model="data"
          type="text"
          class="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeData"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              description</label>
          </div>
          <div class="hidden flex items-center">
            <input
              id="inline-disabled-checkbox"
              disabled
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="inline-disabled-checkbox"
              class="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500"
            >Inline disabled</label>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Contraintes</label>
        <div class="flex">
          <div class="flex items-center me-4">
            <input
              id="inline-checked-checkbox"
              v-model="isRequired"
              checked
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRequired"
            >
            <label
              for="inline-checked-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Obligatoire</label>
          </div>
        </div>
      </div>


      <div class="flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center">
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    import { Dropdown } from 'flowbite';
    export default {
        name: 'TableTableFormComponentDetails',
        props: {
            element: Object
        },
        data() {

            return {
                title: "",
                format: "",
                defaultChoice: "",
                description: "",
                data: "",
                values: [],
                form: { name: "Réception Marchandise", id: "65be5c762e14b1ce62767dbd" },
                isRequired: false,
                isTitleDisplayed: true,
                isDescriptionDisplayed: false,

                forms: [
                    { id: "65be5c762e14b1ce62767dbd", name: "Réception Marchandise" },
                    { id: "batches", name: "Distribution du Lait Tanks" }
                ]

            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title;
                this.format = this.element.format;


                this.form = {
                    id: this.element.form_id,
                    name: "Name of " + this.element.form_id,
                }

                this.isRequired = this.element?.isRequired;
                this.isTitleDisplayed = this.element?.isTitleDisplayed;
                this.isDescriptionDisplayed = this.element?.isDescriptionDisplayed;
                this.data = this.element?.data;
                this.defaultChoice = this.element?.default;
                this.description = this.element?.description;
            }
        },
        mounted() {
            this.title = this.element?.title
            this.format = this.element.format
            this.isRequired = this.element?.isRequired;
            this.isTitleDisplayed = this.element?.isTitleDisplayed;
            this.isDescriptionDisplayed = this.element?.isDescriptionDisplayed;



            this.form = {
                id: this.element.form_id,
                name: "Name of " + this.element.form_id,
            }

            this.data = this.element?.data;
            this.values = this.element?.values;
            this.defaultChoice = this.element?.default;
            this.description = this.element?.description;

            {
                const $targetEl = document.getElementById('dropdownSearch-' + this.element.id);

                // set the element that trigger the dropdown menu on click
                const $triggerEl = document.getElementById('dropdownSearchButton-' + this.element.id);

                // options with default values
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    ignoreClickOutsideClass: false,
             //       onHide: () => {
                         
             //       },
             //       onShow: () => {
                         
             //       },
             //       onToggle: () => {
                         
             //       },
                };

                // instance options object
                const instanceOptions = {
                    id: 'dropdownSearchButton-' + this.element.id,
                    override: true
                };
                const dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
                dropdown.hide()
            }

        },
        methods:
        {
            selectSource: function (item) {
            
             
                this.form = item;

                const $targetEl = document.getElementById('dropdownSearch-' + this.element.id);

                // set the element that trigger the dropdown menu on click
                const $triggerEl = document.getElementById('dropdownSearchButton-' + this.element.id);

                // options with default values
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    dependency: 'no',
                    dependencyId: "",
                    ignoreClickOutsideClass: false,
                //    onHide: () => {
                     
                //    },
                //    onShow: () => {
                       
                //    },
                //    onToggle: () => {
                       
                //    },
                };

                // instance options object
                const instanceOptions = {
                    id: 'dropdownSearchButton-' + this.element.id,
                    override: true
                };
                const dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
                dropdown.hide()
                this.$emit("update", { att: 'form_id', value: this.form.id })
            },

            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.element.id })
            },
            addLine: function (value, index) {
                if (this.values.length > 4) return;
              

                this.values.splice(index + 1, 0, {
                    title: "",
                    value: "",
                    default: ""
                });
            },
            removeLine: function (value, index) {
                if (this.values.length < 2) return;
            
                this.values.splice(index, 1);
            },
            onChangeDefault: function (index) {
                this.$emit("update", { att: 'default', value: index })
            },

            onChangeData: function () {
                this.$emit("update", { att: 'data', value: this.data })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("update", { att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("update", { att: 'isDescriptionDisplayed', value: this.isDescriptionDisplayed })
            },

            onChangeIsRequired: function () {
                this.$emit("update", { att: 'isRequired', value: this.isRequired })
            },
            onInputFormat: function () {
                this.$emit("update", { att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("update", { att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("update", { att: 'description', value: this.description })
            }
        }
    }
</script>
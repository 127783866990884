<template>
  <div
    class="   cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6  px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Attribut</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>


      <div class="  mb-6 w-full text-left">
        <label
          for="default-choice-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Choix</label>
        <div class="w-full flex flex-row">
          <div class="relative w-full overflow-x-auto    w-full">
            <table class="w-full text-xs text-left rtl:text-right text-gray-500  ">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                <tr>
                  <th
                    scope="col"
                    class="  px-6 py-3 "
                  >
                    Titre / Valeur
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3"
                  >
                    Défault
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3"
                  >
                    <span class="sr-only">Supprimer</span>
                    <span class="sr-only">Ajouter</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value,index) in values"
                  :key="value.title"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    class="px-0 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div class="flex flex-col w-full">
                      <input
                        v-model="values[index].title"
                        v-focus
                        type="text"
                        placeholder="Entrer le libélé"
                        class=" border-1 border-gray-300 py-1 w-full text-xs"
                      >
                      <input
                        v-model="values[index].value"
                        type="text"
                        placeholder="Entrer la valeur"
                        class="mt-1 border-1 border-gray-300 py-1 w-full text-xs"
                      >
                    </div>
                  </th>

                  <td class="px-1 py-2">
                    <div class="flex items-center justify-center p-2    ">
                      <input
                        :id="`filter-radio-example-${index}`"
                        v-model="defaultChoice"
                        type="radio"
                        name="choice-radio"
                        :value="index"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        @change="onChangeDefault(index)"
                      >
                    </div>
                  </td>

                  <td class="px-1 py-2">
                    <div class="px-1 flex justify-center items-center   ">
                      <div class="flex flex-row  ">
                        <div
                          class="font-medium  dark:text-red-500   mr-3 cursor-pointer"
                          :class="{'text-red-600 cursor-pointer' : (values.length > 1), 'text-gray-300 cursor-pointer' : (values.length < 2) }"
                          @click="removeLine(value,index)"
                        >
                          <i
                            class="fa-solid fa-circle-minus fa-lg"
                          />
                        </div>
                        <div
                          class="font-medium   dark:text-green-500  "
                          :class="{'text-green-600 cursor-pointer' : (values.length < 5), 'text-gray-300 cursor-pointer' : (values.length > 4) }"
                          @click="addLine(value,index)"
                        >
                          <i
                            class="fa-solid fa-circle-plus fa-lg"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-xs text-gray-800 w-full flex justify-end items-center"
              @click="reset"
            >
              <i
                class="fa-solid fa-xmark mr-1"
              /> Pas de sélection par défaut
            </div>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="template-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Width</label>

        <select
          :id="`att-input-${properties?.id}`"
          v-model="width"
          class="w-full  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @change="onChangeWidth"
        >
          <option value="">
            None
          </option>
          <option value="w-full">
            w-full
          </option>
          <option value="w-11/12">
            w-11/12
          </option>
          <option value="w-10/12">
            w-10/12
          </option>
          <option value="w-9/12">
            w-9/12
          </option>
          <option value="w-8/12">
            w-8/12
          </option>
          <option value="w-7/12">
            w-7/12
          </option>
          <option value="w-6/12">
            w-6/12
          </option>
          <option value="w-5/12">
            w-5/12
          </option>
          <option value="w-4/12">
            w-4/12
          </option>
          <option value="w-3/12">
            w-3/12
          </option>
          <option value="w-2/12">
            w-2/12
          </option>
          <option value="w-1/12">
            w-1/12
          </option>
        </select>
      </div>





      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              description</label>
          </div>
          <div class="flex items-center me-4 mt-2">
            <input
              id="inline-2-checkbox"
              v-model="isRowDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsRowDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage sur une
              ligne</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'RadioTableFormComponentDetails',
        props: {
            properties: Object
        },
        data() {

            return {
                title: "",
                format: "",
                defaultChoice: "",
                description: "",
                attribute: "",
                layout: "1-0",
                width: "",
                values: [],
                isRequired: false,
                isTitleDisplayed: true,
                isDescriptionDisplayed: false,
                isRowDisplayed: false

            }
        },
        watch:
        {
            properties: function () {

                this.title = this.properties?.title;
                this.format = this.properties?.format;
                this.width = this.properties?.width
                this.isTitleDisplayed = this.properties?.option?.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;
                this.attribute = this.properties?.attribute;
                this.defaultChoice = this.properties?.default;
                this.description = this.properties?.description;
                this.isRowDisplayed = this.properties?.layout == 'row';
                 this.layout = this.properties?.layout
                if (this.layout == undefined) this.layout = "1-0"
                this.values = this.properties?.values


                //  this.values =  [{ title: "Choix 1", value: "1", default: "on" }, { title: "Choix 2", value: "2", default: "off" }, { title: "Choix 3", value: "3", default: "off" }]





            }
        },
        mounted() {
            this.title = this.properties?.title
            this.format = this.properties?.format
            this.width = this.properties?.width
            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;
            this.isRowDisplayed = this.properties?.layout == 'row';

              this.layout = this.properties?.layout
         //   if (this.layout == undefined) this.layout = "1-0"

            this.data = this.properties?.data;
            this.attribute = this.properties?.attribute;
            this.defaultChoice = this.properties?.default;
            this.description = this.properties?.description;
            this.values = this.properties?.values

            //[{ title: "Choix 1", value: "", default: "on" }, { title: "Choix 2", value: "", default: "off" }, { title: "Choix 3", value: "", default: "off" }]


        },
        methods:
        {
            reset: function () {
                this.defaultChoice = null;
            },
            addLine: function (value, index) {
                if (this.values.length > 4) return;


                this.values.splice(index + 1, 0, {
                    title: "",
                    value: "",
                    default: ""
                });
            },
            removeLine: function (value, index) {
                if (this.values.length < 2) return;

                this.values.splice(index, 1);
            },
            onChangeWidth: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'width', value: this.width })
            },
            onChangeDefault: function (index) {

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'default', value: index })
            },
            onChangeLayout: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'layout', value: this.layout })
            },
            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'attribute', value: this.attribute })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.title', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.description', value: this.isDescriptionDisplayed })
            },
            onChangeIsRowDisplayed: function () {


                if (this.isRowDisplayed)
                    this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'layout', value: 'row' })
                else
                    this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'layout', value: '1-0' })
            },

            onInputFormat: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            }
        }
    }
</script>
<template>
  <section
    :class="`h-screen flex items-center justify-center bg-[url('${registerBg2}')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply`"
  >
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-red-600 dark:text-red-500">
          500
        </h1>
        <p class="mb-4 text-3xl tracking-tight font-bold text-white md:text-4xl">
          Internal server Error!
        </p>
        <p class="mb-4 text-lg font-light text-gray-400">
          We'll be back soon..
        </p>
        <a
          href="/admin/dashboard"
          class="inline-flex items-center text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
        >
          <svg
            class="mr-2 -ml-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
              clip-rule="evenodd"
            />
          </svg>
         Revenir à l'accueil</a>
      </div>
    </div>
  </section>
</template>

<script>
  import registerBg2 from "@/assets/img/bg_login_1.jpg";
  export default {
    name: "ErrorView",
    data() {
      return {
        registerBg2,
      }
    }
  }

</script>
<template>
  <div
    class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="mb-4 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>





      <div class="  mb-4 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          :id="`att-input-description-${properties?.id}`"
          v-model="description"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>




      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Valeur Initiale</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="defaultval"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeDefault"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Position Initiale</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="position"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangePosition"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Type de
          données</label>

        <div class="w-full flex flex-row">
          <div class="w-36 ml-3 flex items-center mb-4">
            <input
              id="default-radio-1"
              v-model="format"
              type="radio"
              value="number"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeFormat"
            >
            <label
              for="default-radio-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Nombre</label>
          </div>


          <div class="w-36 ml-3 flex items-center mb-4">
            <input
              id="default-radio-2"
              v-model="format"
              checked
              type="radio"
              value="temperature"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeFormat"
            >
            <label
              for="default-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Température</label>
          </div>

          <div class="w-36 ml-3 flex items-center mb-4">
            <input
              id="default-radio-3"
              v-model="format"
              checked
              type="radio"
              value="percent"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeFormat"
            >
            <label
              for="default-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Pourcentage</label>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Plage d'affichage</label>

        <div class="grid gap-2 mb-3 md:grid-cols-1 text-left">
          <div class="flex items-center">
            <label
              for="min"
              class="block mr-2 w-36 text-sm font-medium text-gray-900 dark:text-white"
            >Minimum</label>
            <input
              id="minDisplayValue"
              v-model="minDisplayValue"
              type="number"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="12"
              required
              @input="onChangeMinDisplayValue"
            >
          </div>
          <div class="flex items-center">
            <label
              for="max"
              class="block   mr-2 w-36 text-sm font-medium text-gray-900 dark:text-white"
            >Maximum</label>
            <input
              id="last_name"
              v-model="maxDisplayValue"
              type="number"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="29"
              required
              @input="onChangeMaxDisplayValue"
            >
          </div>
          <div class="flex items-center">
            <label
              for="step"
              class="block mr-2 w-36 text-sm font-medium text-gray-900 dark:text-white"
            >Pas</label>


            <select
              id="step"
              v-model="displayStepValue"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="onChangeDisplayStepValue"
            >
              <option value="0.01">
                0.01
              </option>
              <option value="0.05">
                0.05
              </option>
              <option value="0.1">
                0.1
              </option>
              <option value="0.2">
                0.2
              </option>
              <option value="0.5">
                0.5
              </option>
              <option value="1">
                1
              </option>
              <option value="2">
                2
              </option>
              <option value="5">
                5
              </option>
              <option value="10">
                10
              </option>
              <option value="100">
                100
              </option>
            </select>
          </div>
        </div>
      </div>






      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la description</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'NumberTableFormComponentDetails',
        props: {
            properties: Object
        },
        data() {
            return {
                title: "",
                description: "",
                format: "",
                attribute: "",

                position: "",
                defaultval: "",
             
                isTitleDisplayed: false,
                isDescriptionDisplayed: false,
              
                minDisplayValue: "",
                maxDisplayValue: "",
                displayStepValue: "1"
            }
        },
        watch:
        {
            properties: function () {
                this.title = this.properties?.title
                this.description = this.properties?.description
          
                this.isTitleDisplayed = this.properties?.option?.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;
                this.attribute = this.properties?.attribute;
                this.format = this.properties?.format;
               

                this.defaultval = this.properties?.default;
            this.position = this.properties?.position;

                this.minDisplayValue = this.properties?.values?.min
                this.maxDisplayValue = this.properties?.values?.max
                this.displayStepValue = this.properties?.values?.step
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.description = this.properties?.description
          
            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;

            this.attribute = this.properties?.attribute;
            this.format = this.properties?.format;
          

            this.defaultval = this.properties?.default;
            this.position = this.properties?.position;


            this.minDisplayValue = this.properties?.values?.min
            this.maxDisplayValue = this.properties?.values?.max
            this.displayStepValue = this.properties?.values?.step
        },
        methods:
        {
       
            onChangeDisplayStepValue: function () {
                this.$emit("updateField", {id: this.properties?.id, tab: "properties", att: 'values.step', value: parseFloat(this.displayStepValue) })
            },
            onChangeMinDisplayValue: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'values.min', value: parseFloat(this.minDisplayValue) })
            },

            onChangeMaxDisplayValue: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'values.max', value: parseFloat(this.maxDisplayValue) })
            },
       
            onChangeFormat: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'format', value: this.format })
            },
            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'attribute', value: this.attribute })
            },

            onChangeDefault: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'default', value: this.defaultval })
            },

            onChangePosition: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'position', value: this.position })
            },



            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'option.title;', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'option.description;', value: this.isDescriptionDisplayed })
            },

        
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'title', value: this.title })
            },

            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'description', value: this.description })
            }
        }
    }
</script>
<template>
  <div class="cursor-grab  list-group mt-2 w-full flex items-start       flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full  pl-2  pr-2  ">
      <div
        v-if="properties?.option?.title"
        class="w-full text-sm text-left text-gray-700 font-bold"
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option?.description"
        class=" w-full h-6 text-xs text-left text-gray-700 font-normal"
      >
        {{ properties?.description }}
      </div>
      <div
        class=" h-64 font-bold text-lg  text-white  bg-gray-800 w-full"
        :style="getStyle"
      />
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditorComponentPrint',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
           getStyle : function()
           {
            if (this.properties.height == undefined) return "height: 250px"

            if (this.properties.height == 'full') return "height: 650px"

            return "height: "+this.properties.height+"px"

           }
        },
        methods:
        {
            
        },
    }
</script>
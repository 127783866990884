<template>
  <div class="  w-full relative mt-4">
    <label
      for="default"
      class="text-left block  text-lg font-bold    "
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue";

    export default {
        name: 'ContentTitle',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,

            value: String,
            multilangual: Boolean,
            config: Object

        },
        async mounted() {

            this.output = this.value;
        },

        methods: {
        
            async onClickTranslate() {


                let response = await service.translate("EN", this.output, this.user?.tokenn)

                this.output = response.text[0].text
            
            },
            getData() {
                return this.output;
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        components: {},
        data() {
            return {
                output: "",
                choices: [],
                lg: "FR"

            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        }
    }
</script>
<template>
  <div id="create-hunting-batch-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full h-full max-w-5xl p-4 md:h-auto ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  ">
        <!-- Modal header -->
        <div
          class="px-4 flex items-center justify-between pb-4 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700">
          <h3 class="font-semibold text-gray-900 text-lg dark:text-white  ">
            {{ labels['TITLE_MAIN'][lg] }} {{batch_number}} {{delivery_number}} {{state}}
          </h3>
           

          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="relative bg-gray-50 rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4">
          <div class="w-full  flex flex-col ">
            <p v-if="input?.source?.state=='RECEIVED'" class="rounded-lg text-xl mb-2 font-bold text-gray-500 dark:text-gray-400 py-4 my-2 bg-blue-100">
              Le Gibier a été reçu au centre Nemrod
            </p>
            <p v-if="input?.source?.state=='COLLECTED'"  class="rounded-lg  text-xl mb-2 font-bold text-gray-500 dark:text-gray-400  py-4  my-2 bg-green-100">
                Le Gibier a été reçu au centre Nemrod et inspecté par un vétérinaire   -{{input?.source?.state}}-
            </p>
            <div class="w-full  rounded-lg p-3 mb-3  bg-gray-700 text-white">
              <div class="mt-2 w-full  text-left justify-start    flex flex-row">

                <div class=" w-3/12  text-left justify-start  ">

                  <label for="hunting-item-name" class="block   text-sm font-medium  text-gray-200">
                    {{ labels['TITLE_GAME_TYPE'][lg] }}  
                  
                
                  </label>

                  <div class="font-bold  truncate  text-lg rounded-sm focus:ring-primary-600 block w-full "
                    placeholder="">
                    {{name}}
                  </div>
                </div>


                <div class=" w-3/12  text-left justify-start   ">
                  <label for="hunting-item-name" class="block   text-sm font-medium   text-gray-200">
                    {{ labels['TITLE_GAME_REFERENCE'][lg] }}</label>

                  <div class="font-bold    text-lg rounded-sm focus:ring-primary-6004 block w-full   " placeholder="">
                    {{reference}}
                  </div>
                </div>
                <div class=" w-3/12  text-left justify-start  ">
                  <label for="hunting-item-name" class="block    text-sm font-medium  text-gray-200">
                    {{ labels['TITLE_UNIT_PRICE'][lg] }}</label>

                  <div
                    class="font-bold    text-lg rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                    {{unit_price}} €
                  </div>
                </div>

                <div class=" w-3/12  text-left justify-start   ">
                  <label for="hunting-item-name" class="block   text-sm font-medium text-gray-200">
                    {{ labels['TITLE_PRICE_CATALOG'][lg] }}</label>

                  <div
                    class="font-bold     text-lg rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                    {{price_catalog_name}}
                  </div>
                </div>
              </div>
              <div class="mt-4 w-full  text-left justify-start    flex flex-row  ">

                <div class=" w-3/12  text-left justify-start  ">
                  <label for="hunting-item-price" class="w-48  block mb-1 text-sm font-medium   text-gray-200">
                    {{ labels['TITLE_GROSS_WEIGHT'][lg] }} </label>

                  <div
                    class="font-bold    text-lg rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                    {{weight_gross}} kg
                  </div>

                </div>



                <div class=" w-3/12  text-left justify-start   ">
                  <label for="hunting-item-price" class="w-48  block mb-1 text-sm font-medium  text-gray-200">
                    {{ labels['TITLE_DISCOUNT_WEIGHT'][lg] }} </label>
                  <div
                    class="font-bold    text-lg rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                    {{calculated_discount_net}} kg
                  </div>
                </div>


                <div class=" w-3/12  text-left justify-start   ">
                  <label for="hunting-item-price" class="w-48  block mb-1 text-sm font-medium  text-gray-200">
                    {{ labels['TITLE_NET_WEIGHT'][lg] }} </label>

                  <div
                    class="font-bold    text-lg rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                    {{calculated_weight_net}} kg
                  </div>



                </div>


                <div class=" w-3/12  text-left justify-start   ">
                  <label for="hunting-item-price" class="w-48  block mb-1 text-sm font-medium   text-gray-200">
                    {{ labels['TITLE_PRICE'][lg] }} </label>

                  <div
                    class="font-bold    text-lg rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                    {{calculated_price}} €
                  </div>



                </div>


              </div>




            </div>




            <div class="mt-2 w-full    flex">



              <div class=" w-3/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="game_specie_id" class="w-24  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{ labels['TITLE_SPECIES_TYPE'][lg] }} </label>
                <select id="hunting-item-nemrod_comment_id" v-model="game_species_id" disabled
                  class="w-56  bg-gray-200 border border-gray-300 text-gray-900    text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  @change="onSelectGameSpecie">
                  <option v-for="game_specie in game_species" :key="game_specie._id" :value="game_specie._id">
                    {{ game_specie?.name?.toUpperCase() }}
                  </option>
                </select>

              </div>
              <div class=" w-1/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                &nbsp;
              </div>
              <div class=" w-3/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="game_specie_id" class="w-24  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{ labels['TITLE_TYPE'][lg] }} </label>
                <select id="hunting-item-nemrod_comment_id" v-model="type" disabled
                  class="w-56  bg-gray-200 border border-gray-300 text-gray-900   text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2.5  "
                  @change="onSelectType">
                  <option   value="depot"> RECEPTIONNE  </option>
                  <option   value="collecte"> COLLECTE  </option>
                </select>

              </div>
              <div class=" w-1/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                &nbsp;
              </div>

              <div class=" w-3/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="hunting-item-number"
                  class="w-24 block   text-sm font-semibold text-gray-900 dark:text-white mr-2">
                  {{ labels['TITLE_GAME_RING'][lg] }}</label>
                <input id="hunting-item-number" v-model="number" v-focus type="text" name="hunting-item-number" diasbled
                  class="font-bold bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
            </div>


            <div class=" w-full flex flex-row   mt-6 py-2">
              <div class="w-72 bg-gray-100  justify-center flex flex-col h-96">
                <ul
                  class="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400   mb-4 md:mb-0 justify-center p-2">

                  <li v-if="tt==0">
                    <div @click="onClickTab(0)"
                      class="cursor-pointer inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900   hover:bg-gray-200  focus:border-gray-400 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                      :class="{'font-bold  bg-gray-200 text-gray-600':(current_tab==0), 'font-bold  bg-gray-100 text-gray-900 ':(current_tab!=0)}">
                      <svg class="w-4 h-4 me-2 text-gray-400 dark:text-gray-500" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                      </svg>
                      Collecte
                    </div>
                  </li>
                  <li>
                    <div @click="onClickTab(1)"
                      class="cursor-pointer inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900   hover:bg-gray-200  focus:border-gray-400 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                      :class="{'font-bold  bg-gray-200 text-gray-900':(current_tab==1), 'font-bold  bg-gray-100   text-gray-700 ':(current_tab!=1)}">
                      <svg class="w-4 h-4 me-2 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                        <path
                          d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                      </svg>
                      Reception
                    </div>
                  </li>
                  <li>
                    <div @click="onClickTab(2)"
                      class="font-bold inline-flex items-center px-4 py-3 rounded-lg   bg-gray-100   w-full  "
                      :class="{'cursor-pointer hover:text-gray-900  hover:bg-gray-200 focus:bg-gray-400': !calc_disabled_veto_menu(), 'text-gray-500':calc_disabled_veto_menu(), 'font-bold  bg-gray-200 text-gray-900':(current_tab==2), 'font-bold  bg-gray-100 text-gray-500 ': (calc_disabled_veto_menu()) && (current_tab!=2), 'font-bold  bg-gray-100 text-gray-700 ': (!calc_disabled_veto_menu()) && (current_tab!=2)}">
                      <svg class="w-4 h-4 me-2 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z" />
                      </svg>
                      Contrôle Vétérinaire
                    </div>
                  </li>
                  <li :disable="calc_disabled_nemrod_menu">
                    <div @click="onClickTab(3)" class="  inline-flex items-center px-4 py-3 rounded-lg   w-full"
                      :class="{
                         'cursor-pointer hover:text-gray-900   hover:bg-gray-200 focus:bg-gray-400': !calc_disabled_nemrod_menu(), 'text-gray-500':calc_disabled_nemrod_menu(), 'font-bold  bg-gray-200 text-gray-900':(current_tab==3), 'font-bold  bg-gray-100 text-gray-500 ': (calc_disabled_nemrod_menu()) && (current_tab!=3), 'font-bold  bg-gray-100 text-gray-700 ': (!calc_disabled_nemrod_menu()) && (current_tab!=3)}">
                      <svg class="w-4 h-4 me-2 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          d="M7.824 5.937a1 1 0 0 0 .726-.312 2.042 2.042 0 0 1 2.835-.065 1 1 0 0 0 1.388-1.441 3.994 3.994 0 0 0-5.674.13 1 1 0 0 0 .725 1.688Z" />
                        <path
                          d="M17 7A7 7 0 1 0 3 7a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V7a5 5 0 1 1 10 0v7.083A2.92 2.92 0 0 1 12.083 17H12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1a1.993 1.993 0 0 0 1.722-1h.361a4.92 4.92 0 0 0 4.824-4H17a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3Z" />
                      </svg>
                      Nemrod
                    </div>
                  </li>

                </ul>
                <div class="h-full flex items-stretch bg-gray-100">
                  &nbsp;
                </div>
              </div>
              <div class="w-full pl-4 h-96  ">

                <div class="w-full" v-if="current_tab==0">
                  <div
                    class="mt-4  w-full flex flex-col text-left justify-start font-bold mb-2 border-b border-gray-300  ">
                    <div>Collecte Nemrod</div>
                  </div>
                </div>
                <div class="w-full" v-if="current_tab==1">

                  <div
                    class="mt-2  w-full flex flex-col text-left justify-start font-bold mb-2 border-b border-gray-300  ">

                    <div>Réception Gibier</div>
                  </div>


                  <div class="mt-2 w-full   text-left justify-start flex flex-col items-center">


                    <div class="  w-full  text-left justify-start   flex flex-row items-center">
                      <div class="w-48 font-semibold ">
                        Décision : 
                      </div>

                      <div class="mt-4  pt-2 w-full flex flex-col  text-left justify-start mb-4">

                        <div class="inline-flex rounded-md shadow-sm  w-96">
                          <div aria-current="page" @click="receptionCompliant"
                            class="cursor-pointer px-4 py-2 text-sm font-medium    border border-gray-200 rounded-s-lg focus:z-10 focus:ring-2 "
                            :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': reception_state=='OK', 'bg-gray-100 focus:text-blue-700 text-gray-700   focus:ring-blue-700 hover:bg-blue-500 ': reception_state != 'OK', } ">
                            Conforme
                          </div>
                          <div @click="receptionNoCompliant"
                            class="cursor-pointer px-4 py-2 text-sm font-medium t  border border-gray-200 rounded-e-lg  focus:z-10 focus:ring-2 "
                            :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': reception_state=='KO', 'bg-gray-100 focus:text-blue-700 text-gray-700   focus:ring-blue-700 hover:bg-blue-500 ': reception_state != 'KO', } ">
                            Non Conforme
                          </div>


                        </div>
                      </div>
                    </div>


                    <div class="  w-full  text-left justify-start  flex flex-row items-center "
                      v-if="reception_state != 'KO'">
                      <label for="hunting-item-weight_gross"
                        class="w-48 mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                        {{ labels['TITLE_GROSS_WEIGHT'][lg] }}
                      </label>
                      <input id="hunting-item-weight_gross" v-model="weight_gross" v-focus type="number"
                        @keyup2="onChangeGrossWeight($event)" name="hunting-item-weight_gross"
                        class=" bg-gray-50 w-56 p-2  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="">
                    </div>



                    <div v-if="reception_state == 'KO'"
                      class=" w-full flex flex-row items-center   text-left justify-start mr-2  py-2 ">
                      <label for="hunting-item-veto_comment_id"
                        class="w-48  block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                        {{ labels['TITLE_COMMENT_TYPE'][lg] }}

                        {{disabled_reception_comment}}

                      </label>

                      <select id="hunting-item-veto_comment_id" v-model="reception_comment_id"
                        class=" w-72 border border-gray-300 bg-gray-50  text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        @change="onSelectCommentReception">
                        <option v-for="comment in reception_comments" :key="comment._id" :value="comment._id">
                          {{ comment.name }}
                        </option>
                      </select>

                    </div>
                    <div v-if="reception_state == 'KO' && (!disabled_reception_comment)"
                      class="w-full   flex flex-row items-center  text-left justify-start  py-2"
                      :class="{'hidden':disabled_reception_comment }">
                      <label for="hunting-item-veto_comment"
                        class="w-48  mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                        {{ labels['TITLE_COMMENT_TEXT'][lg] }} </label>
                      <input id="hunting-item-veto_comment" v-model="reception_comment" v-focus type="text"
                        name="hunting-item-veto_comment"
                        class=" w-72  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        :class="{'bg-gray-50 text-gray-900 ' : (!disabled_reception_comment  ), '  bg-gray-100 text-gray-600' :disabled_reception_comment  }"
                        placeholder="">
                    </div>




                  </div>
                </div>
                <div v-if="current_tab==2">
                  <div
                    class="mt-2  w-full flex flex-col text-left justify-start font-bold mb-2 border-b border-gray-300  ">
                    <div>Contrôle Vétérinaire</div>
                  </div>

                  <div class="mt-4  pt-2 w-full flex flex-row items-center  text-left justify-start mb-4 w-full">

                    <div class="w-48 font-semibold ">
                      Décision :
                    </div>

                    <div class="inline-flex rounded-md shadow-sm justify-start">
                      <div aria-current="page" @click="vetoCompliant"
                        class="cursor-pointer px-4 py-2 text-sm font-medium   border border-gray-200 rounded-s-lg focus:z-10 focus:ring-2    dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': veto_state=='OK', 'bg-gray-100 focus:text-blue-700 text-gray-700 focus:ring-blue-700 hover:bg-gray-300': veto_state != 'OK', } ">
                        Conforme
                      </div>
                      <div @click="vetoPartial"
                        class="cursor-pointer px-4 py-2 text-sm font-medium   border-t border-b border-gray-200   focus:z-10 focus:ring-2   dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': veto_state=='PARTIAL', 'bg-gray-100 focus:text-blue-700 text-gray-700   focus:ring-blue-700 hover:bg-gray-300': veto_state != 'PARTIAL', } ">
                        Saisie Partielle
                      </div>
                      <div @click="vetoConsigne"
                        class="cursor-pointer  px-4 py-2 text-sm font-medium   border border-gray-200    focus:z-10 focus:ring-2    dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': veto_state=='CONSIGNE', 'bg-gray-100 focus:text-blue-700 text-gray-700   focus:ring-blue-700 hover:bg-gray-300': veto_state != 'CONSIGNE', } ">
                        Consigne
                      </div>
                      <div @click="vetoFull"
                        class="cursor-pointer px-4 py-2 text-sm font-medium t  border border-gray-200 rounded-e-lg    0 focus:z-10 focus:ring-2     dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': veto_state=='KO', 'bg-gray-100 focus:text-blue-700 text-gray-700   focus:ring-blue-700 hover:bg-gray-300 ': veto_state != 'KO', } ">



                        Saisie Totale
                      </div>
                    </div>
                  </div>




                  <div class="mt-2 w-full  text-left justify-start flex  h-48 ">

                    <div v-if="veto_state=='OK'"
                      class="  w-full h-48 flex flex-row items-center justify-center  text-left  mr-3 py-2 w-56">
                      <label for="hunting-item-weight_discount_veto"
                        class="w-96  block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                        Pas de décôte appliquée sur Gibier Conforme
                      </label>
                    </div>

                    <div v-if="veto_state !='OK' && veto_state!='CONSIGNE'"
                      class="mt-2 w-full flex flex-col text-left justify-start ">

                      <div v-if="veto_state =='PARTIAL'"
                        class="  w-full flex flex-row items-center  text-left justify-start mr-3 py-2 ">
                        <label for="hunting-item-weight_discount_veto"
                          class="w-48 block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                          {{ labels['TITLE_DISCOUNT_VETO'][lg] }}</label>
                        <input id="hunting-item-weight_discount_veto max-w-96" v-model="weight_discount_veto" v-focus
                          type="text" @keyup="onChangeDiscountNemrodWeight($event)" name="weight_discount_veto"
                          :disabled="weight_discount_veto_disabled"
                          class=" w-72 border border-gray-300  text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          :class="{'bg-gray-100 text-gray-600': view != 'batch', 'bg-gray-50  text-gray-900':  view == 'batch'}"
                          placeholder="">
                      </div>

                      <div v-if="veto_state =='PARTIAL' || veto_state =='KO'"
                        class=" w-full flex flex-row items-center   text-left justify-start mr-2  py-2 ">
                        <label for="hunting-item-veto_comment_id"
                          class="w-48  block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                          {{ labels['TITLE_COMMENT_TYPE'][lg] }}</label>

                        <select id="hunting-item-veto_comment_id" v-model="veto_comment_id"
                          class=" w-72 border border-gray-300 bg-gray-50  text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          @change="onSelectCommentVeto">
                          <option v-for="comment in veto_comments" :key="comment._id" :value="comment._id">
                            {{ comment.name }}
                          </option>
                        </select>

                      </div>
                      <div v-if="(veto_state =='PARTIAL' || veto_state =='KO') && (!disabled_veto_comment)"
                        class="w-full   flex flex-row items-center  text-left justify-start  py-2"
                        :class="{'hidden':disabled_veto_comment }">
                        <label for="hunting-item-veto_comment"
                          class="w-48  mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                          {{ labels['TITLE_COMMENT_TEXT'][lg] }} </label>
                        <input id="hunting-item-veto_comment" v-model="veto_comment" v-focus type="text"
                          name="hunting-item-veto_comment" :disabled="disabled_veto_comment"
                          class=" w-72  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          :class="{'bg-gray-50 text-gray-900 ' : (!disabled_veto_comment  ), '  bg-gray-100 text-gray-600' :disabled_veto_comment  }"
                          placeholder="">
                      </div>
                    </div>
                  </div>
                  <div v-if="veto_state=='CONSIGNE'" class="mt-2 w-full  text-left justify-start flex  h-48">
                    <label for="hunting-item-weight_discount_veto"
                      class="w-96  block mb-2 text-sm font-semibold text-gray-900 dark:text-white">

                      Pas de décôte appliquée pour le Gibier Consigné


                    </label>
                  </div>
                </div>
                <div v-if="current_tab==3">
                  <div
                    class="mt-4  w-full flex flex-col text-left justify-start font-bold mb-2 border-b border-gray-300  ">
                    <div class=" ">Contrôle Nemrod</div>
                  </div>
                  <div class="mt-4 pt-2 w-full flex flex-row items-center text-left justify-start mb-4">

                    <div class="  font-semibold w-48">
                      Résultat :
                    </div>

                    <div class="inline-flex rounded-md shadow-sm">
                      <div aria-current="page" @click="nemrodCompliant"
                        class="cursor-pointer px-4 py-2 text-sm   text-gray-600 border border-gray-200 rounded-s-lg  focus:z-10 focus:ring-2    dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': nemrod_state=='OK', 'bg-gray-100 focus:text-blue-700 text-gray-700 focus:ring-blue-700 hover:bg-gray-300': nemrod_state!='OK', } ">
                        Conforme
                      </div>
                      <div @click="nemrodDiscount"
                        class="cursor-pointer px-4 py-2 text-sm   border-t border-b border-gray-200   focus:z-10 focus:ring-2   dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': nemrod_state=='PARTIAL', 'bg-gray-100 focus:text-blue-700 text-gray-700 focus:ring-blue-700 hover:bg-gray-300': nemrod_state!='PARTIAL'} ">
                        Decôte
                      </div>

                      <div @click="nemrodFull"
                        class="cursor-pointer px-4 py-2 text-sm  text-gray-600 border border-gray-200 rounded-e-lg    focus:z-10 focus:ring-2  dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                        :class="{'bg-blue-700 text-white hover:bg-blue-500  focus:text-gray-100': nemrod_state=='KO', 'bg-gray-100 focus:text-blue-700 text-gray-700 focus:ring-blue-700 hover:bg-gray-300': nemrod_state!='KO'} ">
                        Equarissage
                      </div>
                    </div>
                  </div>


                  <div class="mt-2 w-full  text-left justify-start flex  ">

                    <div v-if="nemrod_state=='OK'"
                      class="  w-full h-48 flex flex-row items-center justify-center  text-left  mr-3 py-2 w-56">
                      <label for="hunting-item-weight_discount_veto"
                        class="w-96  block mb-2 text-sm font-semibold text-gray-900 dark:text-white">

                        Pas de décôte appliquée sur Gibier Conforme

                      </label>
                    </div>


                    <div v-if="nemrod_state !='OK'" class="mt-2 w-full  text-left justify-start flex  flex-col">

                      <div v-if="nemrod_state=='PARTIAL'"
                        class="  w-full  text-left justify-start mr-3 flex flex-row items-center py-2">
                        <label for="hunting-item-weight_discount_nemrod"
                          class="w-48 block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                          {{ labels['TITLE_DISCOUNT_NEMROD'][lg] }}</label>
                        <input id="hunting-item-weight_discount_nemrod" v-model="weight_discount_nemrod" v-focus
                          type="text" @keyup="onChangeDiscountNemrodWeight($event)" name="weight_discount_nemrod"
                          :disabled="weight_discount_nemrod_disabled"
                          class="w-56   border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          :class="{'bg-gray-100 text-gray-600': view != 'batch', 'bg-gray-50  text-gray-900':  view == 'batch'}"
                          placeholder="">
                      </div>


                      <div v-if="nemrod_state=='PARTIAL' || nemrod_state=='KO'"
                        class=" w-full  text-left justify-start mr-2 flex flex-row items-center py-2">
                        <label for="nemrod_comment_id"
                          class="w-48  block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                          {{ labels['TITLE_COMMENT_TYPE'][lg] }} </label>
                        <select id="hunting-item-nemrod_comment_id" v-model="nemrod_comment_id"
                          class="w-56  bg-gray-50 border border-gray-300 text-gray-900 bg-gray-50  text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          @change="onSelectCommentNemrod">
                          <option v-for="comment in nemrod_comments" :key="comment._id" :value="comment._id">
                            {{ comment.name }}
                          </option>
                        </select>

                      </div>
                      <div v-if="(nemrod_state=='PARTIAL' || nemrod_state=='KO') && (!disabled_nemrod_comment)"
                        class="w-full   text-left justify-start flex flex-row items-center py-2">
                        <label for="hunting-item-nemrod_comment"
                          class="w-48 block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
                          {{ labels['TITLE_COMMENT_TEXT'][lg] }} </label>
                        <input id="hunting-item-nemrod_comment" v-model="nemrod_comment" v-focus type="text"
                          name="hunting-item-nemrod_comment" :disabled="disabled_nemrod_comment"
                          class=" w-56 border border-gray-300  text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  -2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          :class="{'bg-gray-50 text-gray-900' : !disabled_nemrod_comment, 'bg-gray-100 text-gray-600' : (disabled_nemrod_comment )}"
                          placeholder="">
                      </div>
                    </div>


                  </div>

                  <div class="mt-2 h-3 w-full flex flex-row text-left justify-start  ">

                  </div>
                </div>
              </div>
            </div>





          </div>
          <div class="absolute top-0 left-0 w-full h-full  bg-gray-50 flex items-center justify-center"
            :class="{ 'block' : !show_form, 'hidden':show_form}">
            <div class="flex items-center">


              <div role="status">
                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <div class="font-bold text-lg ml-2">
                Chargement en cours
              </div>

            </div>
          </div>
        </div>

        <div class="flex items-center justify-end px-4  ">
          <button type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()">
            Annuler
          </button>
          <button type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate">
            Modifier
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import labels from "@/assets/lg/hunting-batch.json"
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";


  export default {
    name: 'CreateHuntingModalModal',
    components: {

    },
    props: {
      input: Object
    },
    data() {
      return ({
        show_form: false,
        current_tab: 1,

        view: "ba",


        lg: "FR",
        labels: labels,

        item_id: "",
        id: "",
        name: "",
        reference: "",
        number: "",
        reception_state: "OK",
        veto_state: "",
        nemrod_state: "",
        delivery_number: "",
        batch_number: "",


        weight_gross: 0,
        weight_discount_nemrod: 0,
        weight_discount_veto: 0,
        unit_price: 0,
        amount: 0,
        type : "",
        veto_comment_id: "",
        veto_comment: "",

        nemrod_comment_id: "",
        nemrod_comment: "",

        reception_comment_id: "",
        reception_comment: "",


        game_species_id: "",
        reception_comments: [],
        nemrod_comments: [],
        veto_comments: [],
        price_catalog_name: "",
        price_catalog_id: "",
        game_species: [],
        pricing: {},
        disabled_veto_comment: true,
        disabled_nemrod_comment: true,
        disabled_reception_comment: true,
        state: "",
        transparent: false,
        item: {
          id: "1",
          label: {
            'EN': "",
            'FR': ''
          }
        },
      })
    },
    watch: {


      weight_discount_veto_disabled: function () {
        return this.veto_comment_id == "66c83a3afdf9b2e122bd8fa2"
      },

      weight_discount_nemrod_disabled: function () {
        return this.nemrod_comment_id == "66c83a3afdf9b2e122bd8fa2"
      },

      'input': async function () {
        this.show_form = false
        this.view = this.input?.view

        //if (this.view == "batch")
 

        if (this.nemrod_comments.length == 0) {
          await this.loadComments()
        }


        if (this.game_species.length == 0) {
          await this.loadGameSpecies()
        }
        await this.loadPricing()


        this.id = this.input?.id
       
        this.price_catalog_id = this.input?.price_catalog_id
        this.price_catalog_name = this.input?.price_catalog_name


        this.item_id = this.input?.source?.item_id
        this.name = this.input?.source?.name


        this.reference = this.input?.source?.reference
        this.number = this.input?.source?.number


        this.weight_gross = this.input?.source?.weight_gross.value
        this.unit_price = this.input?.source?.unit_price


        if (this.input?.source?.weight_discount_nemrod?.value != undefined) {
          this.weight_discount_nemrod = this.input?.source?.weight_discount_nemrod?.value
        }

        if (this.input?.source?.weight_discount_veto?.value != undefined) {
          this.weight_discount_veto = this.input?.source?.weight_discount_veto?.value
        }

        this.type = this.input?.type
        if (this.type == undefined)
        {
          this.type = this.initType(this.reference)
        }

        this.game_species_id = this.input?.source?.species_id

        if (this.game_species_id == undefined )
        {
          this.game_species_id = this.initSpecies(this.reference)
        }

        this.veto_state = this.input?.source?.veto_state
        this.veto_comment_id = this.input?.source?.veto_comment_id
        this.veto_comment = this.input?.source?.veto_comment

        this.nemrod_state = this.input?.source?.nemrod_state
        this.nemrod_comment_id = this.input?.source?.nemrod_comment_id
        this.nemrod_comment = this.input?.source?.nemrod_comment


        this.reception_state = this.input?.source?.reception_state
        this.reception_comment_id = this.input?.source?.reception_comment_id
        this.reception_comment = this.input?.source?.reception_comment

        this.delivery_number = this.input?.source?.delivery_number
        this.batch_number = this.input?.source?.batch_number

        this.disabled_nemrod_comment = (this.nemrod_comment_id != "66c83a3afdf9b2e122bd8fa2")
        this.disabled_veto_comment = (this.veto_comment_id != "66c83a3afdf9b2e122bd8fa2")
        this.disabled_reception_comment = (this.reception_comment_id != "66c83a3afdf9b2e122bd8fa2")

        this.show_form = true

    
        // this.name = this.input
      }
    },
    async mounted() {
      this.view = this.input?.view
   

    },
    computed:
    {
      ...mapGetters({ user: "StateUser" }),
      calculated_weight_net: function () {


        if (this.reception_state == 'KO') return '0.00'
        if (this.veto_state == 'KO') return '0.00'
        if (this.nemrod_state == 'KO') return '0.00'
        if (this.veto_state == 'CONSIGNE') return parseFloat(this.weight_gross).toFixed(0)


        let n = parseFloat(this.weight_gross) - parseFloat(this.weight_discount_nemrod) - parseFloat(this.weight_discount_veto)
        if (n < 0) n = 0
        return parseFloat(n.toFixed(2))
      },


      calculated_discount_net: function () {


        if (this.reception_state == 'KO') return parseFloat(this.weight_gross).toFixed(0)
        if (this.veto_state == 'KO') return parseFloat(this.weight_gross).toFixed(0)
        if (this.nemrod_state == 'KO') return parseFloat(this.weight_gross).toFixed(0)
        if (this.veto_state == 'CONSIGNE') return '0.00'

        let n = parseFloat(this.weight_discount_nemrod) + parseFloat(this.weight_discount_veto)
        if (n < 0) n = 0
        return parseFloat(n.toFixed(2))
      },

      calculated_price: function () {

        if (this.reception_state == 'KO') return '0.00'
        if (this.veto_state == 'KO') return '0.00'
        if (this.nemrod_state == 'KO') return '0.00'

        if (this.veto_state == 'CONSIGNE') {
          let n = parseFloat(this.unit_price) * (parseFloat(this.weight_gross))
          if (n < 0) n = 0
          return parseFloat(n.toFixed(2))
        }


        let n = parseFloat(this.unit_price) * (parseFloat(this.weight_gross) - parseFloat(this.weight_discount_nemrod) - parseFloat(this.weight_discount_veto))
        if (n < 0) n = 0
        return parseFloat(n.toFixed(2))
      }
    },
    methods: {



      calc_disabled_nemrod_menu: function () {
        return ((this.veto_state == 'KO') || (this.reception_state == 'KO')) ||  (this.input?.source?.state != 'COMPLETED')
      },


      calc_disabled_veto_menu: function () {
        return (this.reception_state == 'KO')||  (this.input?.source?.state != 'COMPLETED')
      },


      onClickTab(n) { this.current_tab = n; },


      receptionCompliant() {
        this.reception_state = "OK"

      },
      receptionNoCompliant() {
        this.reception_state = "KO"
      },
      vetoCompliant() {
        this.veto_state = "OK"
        this.weight_discount_veto = 0
        this.veto_comment_id = ""
        this.veto_comment = ""



      },
      vetoPartial() {
        this.veto_state = "PARTIAL"
      },
      vetoFull() {
        this.veto_state = "KO"

        this.weight_discount_nemrod = 0
        this.nemrod_comment_id = ""
        this.nemrod_comment = ""

      },
      vetoConsigne() {
        this.veto_state = "CONSIGNE"
      },
      nemrodCompliant() {
        this.nemrod_state = "OK"
        this.nemrod_comment_id = ""
        this.nemrod_comment = ""

      },
      nemrodDiscount() {
        this.nemrod_state = "PARTIAL"
      },
      nemrodFull() {
        this.nemrod_state = "KO"
      },
      onChangeGrossWeight() {

      },
      onChangeDiscountNemrodWeight() {

      },
      onChangeDiscountVetoWeight() {

      },


      onSelectGameSpecie() {

        this.reference = ""

      },
      onSelectCommentReception() {

        this.disabled_reception_comment = (this.reception_comment_id != "66c83a3afdf9b2e122bd8fa2")

        if (this.disabled_reception_comment) this.reception_comment = ""
      },


      onSelectCommentVeto() {

        this.disabled_veto_comment = (this.veto_comment_id != "66c83a3afdf9b2e122bd8fa2")

        if (this.disabled_veto_comment) this.veto_comment = ""
      },


      onSelectCommentNemrod() {
        this.disabled_nemrod_comment = (this.nemrod_comment_id != "66c83a3afdf9b2e122bd8fa2")
        if (this.disabled_nemrod_comment) this.nemrod_comment = ""
      },


      async loadPricing() {

        {
          //inbound-pricing-lists
          let datasource = "661f6d4207df9f4df612d361"
          let id = "66f5528862f35c91d33d2b98"
          let filters = {}
          let collection = "data-sources/" + datasource + "/records/" + id
          let pricing = await service.getData(collection, this.user?.token, filters)
          this.pricing = pricing;


        }

      },

      async loadGameCatalog() {

        {
          let datasource = "66f29153d787f83c72d45a68"
          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=100"
          let catalog = await service.getData(collection, this.user?.token, filters)
          this.game_catalog = catalog.records;
        }

      },
      async loadGameSpecies() {

        {
          let datasource = "66f29153d787f83c72d45a68"
          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=100"
          let gameSpecies = await service.getData(collection, this.user?.token, filters)
          this.game_species = gameSpecies.records;
        }

      },
      async loadComments() {


        let none = [{
          "_id": "",
          "name": "Aucun",

          "rank": {
            "value": "-1",
            "sign": ""
          }

        }]

        {
          let datasource = "66d5abf53c65f285d84eeb41"
          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=100"
          let comments = await service.getData(collection, this.user?.token, filters)
          this.nemrod_comments = [...none, ...comments.records];
        }
        {
          let datasource = "66d5acd53c65f285d84eeb44"
          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=100"
          let comments = await service.getData(collection, this.user?.token, filters)
          this.veto_comments = [...none, ...comments.records];
        }

 
        this.reception_comments =[...this.nemrod_comments,...this.veto_comments ]
      },


      initType(reference)
      {
        switch(reference)
        {
          case "L_SANG" : return "depot";  
          case "L_SANG_20" :return "depot";  

          case "C_SANG" : return "collecte";  
          case "C_SANG_20" : return "collecte";  

          case "C_CERF" : return "collecte";  
          case "L_CERF" : return "depot";  

          case "C_CHAM" : return "collecte";  
          case "L_CHAM" :  return "depot";  

          case "C_CHEV" :  return "collecte"; 
          case "C_CHEV_10" :  return "collecte";  

          case "L_CHEV" :  return "depot"; 
          case "L_CHEV_10" :  return "depot";  

          case "C_DAIM" :  return "collecte"; 
          case "L_DAIM" :  return "depot";  

      
        }
      },
     




      initSpecies(reference)
      {
        switch(reference)
        {
          case "L_SANG" : return "66eda7944e77b8e159702312";  
          case "L_SANG_20" :return "66eda7944e77b8e159702312";  

          case "C_SANG" : return "66eda7944e77b8e159702312";  
          case "C_SANG_20" : return "66eda7944e77b8e159702312";  

          case "C_CERF" : return "66f28a28d787f83c72d45a5c";  
          case "L_CERF" : return "66f28a28d787f83c72d45a5c";  

          case "C_CHAM" : return "66f28991d787f83c72d45a5a";  
          case "L_CHAM" :  return "66f28991d787f83c72d45a5a";  

          case "C_CHEV" :  return "66f2889fd787f83c72d45a59"; 
          case "C_CHEV_10" :  return "66f2889fd787f83c72d45a59";  

          case "L_CHEV" :  return "66f2889fd787f83c72d45a59"; 
          case "L_CHEV_10" :  return "66f2889fd787f83c72d45a59";  

          case "C_DAIM" :  return "66f289dfd787f83c72d45a5b"; 
          case "L_DAIM" :  return "66f289dfd787f83c72d45a5b";  

      
        }
      },
      onValidate() {
        this.onValidateBa();
        //   if (this.view == "ba") this.onValidateBa()
        //   if (this.view == "batch") this.onValidateBatch()
      },

      onValidateBa() {

        let item =
        {
          id: this.id, 

          action: this.input.action,
          target:
          {
            id: this.item_id,

            number: this.number,
            species_id: this.game_species_id,
            weight_gross: { unit: "kg", value: this.weight_gross },

            weight_discount_veto: { unit: "kg", value: this.weight_discount_veto },
            weight_discount_nemrod: { unit: "kg", value: this.weight_discount_nemrod },

            reception_state: this.reception_state,
            veto_state: this.veto_state,
            nemrod_state: this.nemrod_state,

            reception_comment_id: this.reception_comment_id,
            reception_comment: this.reception_comment,

            veto_comment_id: this.veto_comment_id,
            nemrod_comment_id: this.nemrod_comment_id,

            veto_comment: this.veto_comment,
            nemrod_comment: this.nemrod_comment,
          }


        }
        this.$emit("confirm", item)
        this.show_form = false
        // this.name = ""
      },


 
      onClose() {
        this.$emit("close")
        this.show_form = false
        //  this.name = ""
      }
    },
  }
</script>
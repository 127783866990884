<template>
    <div class="relative  h-screen   bg-gray-50  overflow-x-hidden   overflow-y-show   ">
       
        <div class="relative  mt-2 ml-10 px-6 overflow-x-hidden">

            <div class=" w-full   pb-6 flex items-center   mb-1  ">
                <h2
                  class="  pl-2 text-left w-full   font-bold text-gray-900    dark:text-white flex items-center justify-start w-full "
                >
                  <div
                    v-if="isBack"
                    class="pr-3 "
                  >
                    <div
                      class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      @click="onBack"
                    >
                      <svg
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                          
                  <div class="w-full text-3xl   ">
                    {{ config?.title?.FR }}
                  </div> 
                     
                  <h4 class="flex   justify-end  ml-4 text-right   font-semibold text-gray-500    items-center">
                    <div class=" w-48 text-gray-600  bg-gray-100   font-semibold  text-center  rounded-sm text-sm">
                      {{ config?.subtitle?.FR }}
                    </div>
                  </h4>
                </h2>
              </div>
                 
 

            <section class="w-full bg-gray-50 dark:bg-gray-900 mt-2  ">
                <div class="w-full flex items-center h-full w-full bg-orange-100 ">
                    <WeekComponent class="hidden" :config="config" :records="records" :offset="offset" :limit="limit"
                        :currentTab="currentTab" :isLoading="isLoading" :isLoading2="isLoading2"
                        :isLoading3="isLoading3" :filterList="filterList" :results="results" @refresh="onRefresh" />
                </div>
            </section>



            <section class="w-full bg-gray-50 dark:bg-gray-900 mt-2  ">
                <div class="w-full flex items-center  h-full w-full bg-green-100 ">
                    <FiveColComponent class="w-full " :id="getId" :config="config" :records="records" :offset="offset"
                        :limit="limit" :currentTab="currentTab" :isLoading="isLoading" :isLoading2="isLoading2"
                        :isLoading3="isLoading3" :filterList="filterList" :results="results" @refresh="onRefresh" />
                </div>
            </section>
        </div>
    </div>
</template>
<script>

    const MAX_ITEMS = 300;

    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    import WeekComponent from '@/components/WeekComponent.vue'
    import FiveColComponent from '@/components/FiveColComponent.vue'
    export default {
        name: 'WeekView',
        components: {
            FiveColComponent, WeekComponent
        },

        data() {
            return {
                records: [],
                config: {
                    options : {}
                },
                filters: {},
                offset: 0,
                limit: MAX_ITEMS,
                lg: "FR",
                currentTab: 0,
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                current_page: 1,
                lastQuery: "",
                results: {
                    latest: [],
                    matching: [],
                    suggestions: [],
                    buttons: []

                },
                filterList: [],
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            isBack: function () {
                return (this.$route?.query?.back) || (this.config?.options?.displayBack == true)
            },

            isDeveloper: function () {
                return this.user?.isDeveloper;
            },
            getId : function()
            {
                return this.$route?.params?.id
            }
        },
        watch:
        {
            'refresh': async function( )
            {
             //   console.log("Change ROUTE =", this.$route.params.id)
                
                //filters['category'] = config.filters;
                this.current_page = 1;

        //        let filters = {}
        
          //      let val  = this.$route.params.id
              //  this.reloadPage(val, 0, MAX_ITEMS, filters, 1, this.current_page);
                window.scrollTo(0, 0);
                 
            },
            '$route.params.id': async function (/*val*/) {
           
               // console.log("Change ROUTE =", this.$route.params.id)
               
                //filters['category'] = config.filters;
                this.current_page = 1;
           
                // let filters = {}
         
               // this.reloadPage(val, 0, MAX_ITEMS, filters, 1, this.current_page);
                window.scrollTo(0, 0);
               
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user.tenant': async function () {
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                this.isLoading = true;
                //let filters = {}
      
         
           
               // this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);

            }
        },
        async mounted() {
            let filter = {}
        
          //  let offset = 0;
          //  if (this.$route.query?.page != undefined) {
          //      offset = (this.$route?.query?.page - 1) * MAX_ITEMS
          //  }
            this.filters = filter;

            let page = this.current_page
            if (this.$route.query.page != undefined) {
                page = parseInt(this.$route.query.page)
                this.current_page = page
                if (this.current_page < 1) this.current_page = 1;
            }
             //   
        //    this.reloadPage(this.$route.params.id, offset, MAX_ITEMS, filter, 1, page);
        },
        methods: {


            onRefresh(period) {
              
                this.reloadPage(this.$route.params.id,  0, MAX_ITEMS,  period.filter, 1, this.current_page);
             
            },


            async reloadPage(id, offset, limit, filter, level, page) {

                if (level == null) level = 1
                if (this.user == undefined) {
                    //                    this.$router.push("/login");
                    window.location = "/login"
                    return;
                }

                this.current_page = page;

                let loffset = parseInt((page - 1) * limit);

                if (loffset == undefined) loffset = 0

                this.limit = limit;

              //  if (level == 1) this.isLoading = true;
                if (level == 2) this.isLoading2 = true;
                if (level == 3) this.isLoading3 = true;

                let code = null;

                if (this.$route.query.code != undefined)
                    code = this.$route.query.code;
                //  console.log("TableView " + code)
                let config = await service.getConfiguration(id, code, this.user?.token)

                if (config == undefined) {

                    return;
                }

                //TODO Remove this, tabs must be delivered via Backoffice


                if (config.tabs == undefined) {
                    let t = await service.getFilterTabDefinition(this.$route.params.id, this.user?.token, { template: this.$route.params.id })
                    {
                        //TODO Check how to remove this
                        if ((this.$route.params.id == 'suppliers') || (this.$route.params.id == 'products') || (this.$route.params.id == 'raw-products') || (this.$route.params.id == 'suppliers-product') || (this.$route.params.id == 'manufacturing-orders') || (this.$route.params.id == 'data-errors')) {
                            if (this.$route.query.tab == undefined)
                                if (t.records && t.records[0]) filter.category = t.records[0].id
                        }
                        if (t) {
                            config.tabs = t.records;

                        }
                    }
                }



                if (config?.search?.buttons != undefined) {

                    if (filter.category != undefined) {
                        let buttons = config.search.buttons[filter.category]
                        if (buttons != undefined) {
                            this.results.buttons = buttons
                        }
                        else {
                            this.results.buttons = []
                        }
                    }
                    else {
                        let buttons = config.search.buttons['*']
                        if (buttons != undefined) {
                            this.results.buttons = buttons
                        }
                        else {
                            this.results.buttons = []
                        }
                    }


                }
                else {
                    this.results.buttons = []
                }

                this.filter = filter;

                let filterBarFilter = {}
                if (this.$route.query.category != undefined) {
                    filterBarFilter.category = this.$route.query.category
                }


                let d = await service.getFilterBarDefinition(this.$route.params.id, this.user?.token, filterBarFilter)

                config.secondBar = d.records;

                for (let i = 0; i < config.secondBar.length; i++) {
                    if (config.secondBar[i].data == "supplier_id") {
                        if (filter["supplier_id"] != undefined) {

                            config.secondBar[i].selected = filter["supplier_id"]

                            if (filter["supplier_id"] == "*") {
                                delete filter["supplier_id"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }


                    if (config.secondBar[i].data == "item_organic") {
                        if (filter["item_organic"] != undefined) {

                            config.secondBar[i].selected = filter["item_organic"]

                            if (filter["item_organic"] == "*") {
                                delete filter["item_organic"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }


                    if (config.secondBar[i].data == "entity_id") {
                        if (filter["entity_id"] != undefined) {

                            config.secondBar[i].selected = filter["entity_id"]

                            if (filter["entity_id"] == "*") {
                                delete filter["entity_id"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }

                    if (config.secondBar[i].data == "location_id") {
                        if (filter["location_id"] != undefined) {

                            config.secondBar[i].selected = filter["location_id"]

                            if (filter["location_id"] == "*") {
                                delete filter["location_id"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }
                    if (config.secondBar[i].data == "supplier_type") {
                        if (filter["supplier_type"] != undefined) {

                            config.secondBar[i].selected = filter["supplier_type"]

                            if (filter["supplier_type"] == "*") {
                                delete filter["supplier_type"]

                            }


                        }
                        else config.secondBar[i].selected = "*"


                    }








                }



               
                if (config.tabs != undefined) {
                    if (config.tabs.length > 0) {
                        if (this.currentTab == undefined) this.currentTab = 0;
                        filter = { ...filter, ...config.tabs[this.currentTab].filter }
                    }

 
                }


                this.config = config;



                // this.records = await service.getData(id, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))



                let query = Object.assign({}, filter)



                if (query == undefined) query = {}


                query['page'] = page

                this.$router.replace({ path: this.$route.path, query: query })
 
                this.records = await service.getData("data-sources/" + config.source_id + '/records', this.user?.token, Object.assign({}, { limit: limit, offset: loffset }, filter))
 

                if (level == 1) this.isLoading = false;
                if (level == 2) this.isLoading2 = false;
                if (level == 3) this.isLoading3 = false;
                this.filters = filter


            },

        }


    }
</script>
<template>
  <div class="mt-2 mb-2 w-full relative">
    <label for="default" class="text-left block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
      {{ label }}
    </label>

    <div class="w-full flex justify-start items-center  pr-3 py-4 bg-orange-100">
      <div class="relative w-full pl-3">
        <div class="absolute inset-y-0 start-3 flex items-center ps-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
          </svg>
        </div>
        <input id="voice-search" v-model="query" type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Rechercher par nom..." required @input="onSearchInput">
        <button type="button" class="absolute inset-y-0 end-0 flex items-center pe-3"
          :class="{'hidden': query.length==0} " @click="onDeleteQuery">
          <i
            class="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white fa-regular fa-circle-xmark" />
        </button>



      </div>
      <div class=" w-full  px-3" v-if="config.showbar">


        <div class="inline-flex rounded-md shadow-sm" role="group">
          <button v-for="(item, index) in items_bar" :key="item.id" type="button" @click="selectFilter(item)"
            class="w-36 px-1 py-2 font-medium  border border-gray-200  hover:bg-gray-100 hover:text-blue-700"
            :class="{'rounded-s-lg': index ==0, 'rounded-e-lg': index == (items_bar.length-1), 'text-gray-900 bg-white  text-sm' : selected != item.id, ' text-semibold bg-blue-700 text-white' : selected == item.id, }">
            {{item?.name[lg]}}
          </button>

        </div>

      </div>

      <div class="w-96 px-3">
        <button id="dropdownHelperButton" data-dropdown-toggle="dropdownHelper"
          class="hidden w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button">
          <div class="w-full text-left justify-start">
            Dropdown checkbox
          </div>

          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 4 4 4-4" />
          </svg>
        </button>

        <!-- Dropdown menu -->
        <div id="dropdownHelper"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600">
          <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 justify-start text-left"
            aria-labelledby="dropdownHelperButton">
            <li>
              <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div class="flex items-center h-5">
                  <input id="helper-checkbox-1" aria-describedby="helper-checkbox-text-1" type="checkbox" value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                </div>
                <div class="ms-2 text-sm">
                  <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>Enable notifications</div>
                    <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">Some
                      helpful
                      instruction goes over here.</p>
                  </label>
                </div>
              </div>
            </li>
            <li>
              <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div class="flex items-center h-5">
                  <input id="helper-checkbox-2" aria-describedby="helper-checkbox-text-2" type="checkbox" value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                </div>
                <div class="ms-2 text-sm">
                  <label for="helper-checkbox-2" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>Enable 2FA auth</div>
                    <p id="helper-checkbox-text-2" class="text-xs font-normal text-gray-500 dark:text-gray-300">Some
                      helpful
                      instruction goes over here.</p>
                  </label>
                </div>
              </div>
            </li>
            <li>
              <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div class="flex items-center h-5">
                  <input id="helper-checkbox-3" aria-describedby="helper-checkbox-text-3" type="checkbox" value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                </div>
                <div class="ms-2 text-sm">
                  <label for="helper-checkbox-3" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>Subscribe newsletter</div>
                    <p id="helper-checkbox-text-3" class="text-xs font-normal text-gray-500 dark:text-gray-300">Some
                      helpful
                      instruction goes over here.</p>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-96 px-3">
        <button id="dropdownHelperButton2" data-dropdown-toggle="dropdownHelper2"
          class="hidden w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button">
          <div class="w-full text-left justify-start">
            Dropdown checkbox
          </div>

          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 4 4 4-4" />
          </svg>
        </button>

        <!-- Dropdown menu -->
        <div id="dropdownHelper2"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600">
          <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 justify-start text-left"
            aria-labelledby="dropdownHelperButton2">
            <li>
              <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div class="flex items-center h-5">
                  <input id="helper-checkbox-1" aria-describedby="helper-checkbox-text-1" type="checkbox" value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                </div>
                <div class="ms-2 text-sm">
                  <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>Enable notifications</div>
                    <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">Some
                      helpful
                      instruction goes over here.</p>
                  </label>
                </div>
              </div>
            </li>
            <li>
              <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div class="flex items-center h-5">
                  <input id="helper-checkbox-2" aria-describedby="helper-checkbox-text-2" type="checkbox" value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                </div>
                <div class="ms-2 text-sm">
                  <label for="helper-checkbox-2" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>Enable 2FA auth</div>
                    <p id="helper-checkbox-text-2" class="text-xs font-normal text-gray-500 dark:text-gray-300">Some
                      helpful
                      instruction goes over here.</p>
                  </label>
                </div>
              </div>
            </li>
            <li>
              <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div class="flex items-center h-5">
                  <input id="helper-checkbox-3" aria-describedby="helper-checkbox-text-3" type="checkbox" value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                </div>
                <div class="ms-2 text-sm">
                  <label for="helper-checkbox-3" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>Subscribe newsletter</div>
                    <p id="helper-checkbox-text-3" class="text-xs font-normal text-gray-500 dark:text-gray-300">Some
                      helpful
                      instruction goes over here.</p>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-8   md:grid-cols-5 gap-4 max-h-dvh overflow-x-auto px-3">
      <div v-for="choice in choices_filtered" :key="choice._id"
        class="cursor-pointer max-w-sm  border border-gray-200 hover:border-gray-300 hover:border  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
        :class="{ 'ring-gray-300 ring-2 bg-gray-700 text-white':(selected_item?._id == choice._id), '  ring-0 bg-white  text-gray-700':(selected_item?._id != choice._id)}"
        @click="onSelectItem(choice)" @dblclick="onSelectItemAndContinue(choice)">
        <div class="flex justify-center   bg-gray-100">
          <img class="rounded-t-lg" :src="`${host_media}${choice.image_id}?icon=true`" alt="">
        </div>
        <div class="pt-4">
          <h5 class="mb-2 pb-2 text-sm font-bold tracking-tight dark:text-white">
            {{ formatOption(choice) }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  export default {
    name: 'ContentSelectCollection',
    props: {
      id: String,
      label: String,
      placeholder: String,
      required: Boolean,
      value: String,
      datasource: String,
      mode: String,
      filters: Object,
      config: Object
    },
    async mounted() {

      this.loadRecords()
      this.output = this.value;
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;

      },
    },
    methods: {

      async loadRecords() {
        let datasource = this.datasource;
        if (datasource == undefined) { return; }

        let filters = {};

        if (this.filters != undefined) {
          if (typeof this.filters == 'object') filters = this.filters
        }

        filters.limit = 200;
        filters.offset = 0;


        filters = { ...filters, ...this.local_filters, ...this.setFilter(this.config?.filter) }
        // filters.lg = "FR";

        let collection = "data-sources/" + datasource + "/records"

        if ((collection != undefined) && (collection != "")) {
          let choices = await service.getData(collection, this.user?.token, filters)
          if (choices?.records == undefined) this.choices = []
          else this.choices = choices?.records
        }
        else {
          this.choices = []
        }


        this.choices_filtered = this.choices
      },

      selectFilter(item) {
        this.selected = item.id
        this.local_filters = item.filter

        this.loadRecords()


      },
      onDeleteQuery() {
        this.query = ""
        this.onSearchInput()
      },
      onSelectItem(item) {


        console.log(item)
        if (this.selected_item?._id == item?._id) { this.selected_item = null; return }
        this.output = item._id;
        this.selected_item = item
        let notif = { id: this.config.data, value: item?._id, index: this.index }
        this.$emit("valueChanged", notif)
      },
      onSelectItemAndContinue(item) {


        console.log(item)
        if (this.selected_item?._id == item?._id) { this.selected_item = null; return }
        this.output = item._id;
        this.selected_item = item
        let notif = { id: this.config.data, value: item?._id, index: this.index, action: "validate" }
        this.$emit("valueChanged", notif)
      },
      onSearchInput() {
        console.log(this.query)

        let q = this.query
        let items = this.choices;

        items = items.filter(function (a) {


          console.log(a.name, a.name.indexOf(q))

          if (a.name.toUpperCase().indexOf(q.toUpperCase()) == -1) return false


          return true
        })
        this.choices_filtered = items

      },
      setFilter(filter) {

        let newFilter = {}
        if (filter == undefined) return filter;

        if (typeof filter != "string") return filter
        let rules = filter.split("&")

        for (let i = 0; i < rules.length; i++) {
          let rule = rules[i];
          let tags = rule.split("=")
          if (tags.length == 2) {
            newFilter[tags[0]] = tags[1]
          }
        }

        return newFilter
      },
      onChange() {

        this.$emit("valueChanged", { id: this.config.data, value: this.output })
      },
      getData() {

        return this.output;
      },
      formatOption(choice) {

        // if ( (this.config.format != undefined) && (this.config.format != "")) {
        //    return choice.name + " / FT-" + choice.reference
        // }

        let name = choice.name
        if (typeof choice.name == "object")
          name = choice.name[this.lg]

        return name

      }
    },

    data() {
      return {
        output: "",
        query: "",
        choices: [],
        lg: "FR",
        selected: 0,
        local_filters:{},
        choices_filtered: [],
        selected_item: null,
        items_bar: [
          {
            id: 0,
            name: { "FR": "Tous", "EN": "Tous" },
            filter: {}
          },
          {
            id: 1,
            name: { "FR": "Matière Première", "EN": "Matière Première" },
            filter: { "grid_product_type": "ingredients" }
          },
          {
            id: 2,
            name: { "FR": "Emballage", "EN": "Emballage" },
            filter: { "grid_product_type": "packaging" }
          },
          {
            id: 3,
            name: { "FR": "Produits Entretien", "EN": "Produits Entretien" },
            filter: { "grid_product_type": "cleaning" }
          },
        ]

      }
    },
    watch: {
      value: function (val) {
        this.output = val;
      },
      datasource: async function () {
        {
         this.loadRecords()
        }

      }
    }
  }
</script>
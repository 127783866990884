<template>
  <aside class="aside2 bg-gray-700 px-3" style="width:600px !important">
    <EditCodeModal :profile="output.data.profile" @save="onSaveCodeModal" @close="onCloseCodeModal" />

    <EditTabletFormModal :profile="output.data.profile" @save="onSaveForm" @update="onUpdateForm"
      @close="onCloseForm" />


    <div v-if="properties != undefined" class="h-full relative  ">
      {{ properties?.type }}
      <div v-if="properties && properties.type != 'default'" class="  text-left description text-xl center">
        Propriétés d'une Tâche
      </div>

      <div v-if="properties && properties.type == 'default'" class="text-left description text-xl center">
        Propriétés
        d'un
        {{ lg_register['LABEL_LINK_TYPE'][lg] }}
      </div>

      <div v-if="properties && properties.active2 == undefined" class="relative w-full mb-3 flex flex-wrap pt-3   ">
        <div class=" w-full px-2 mt-6">
          <label v-if="properties && properties.type != 'default'" class="text-left block   text-white text-xs  mb-2"
            htmlFor="grid-name">

            {{ lg_register['LABEL_NAME_TASK'][lg] }}

          </label>
          <label v-if="properties && properties.type == 'default'" class="text-left block   text-white text-xs  mb-2"
            htmlFor="grid-name">

            {{ lg_register['LABEL_NAME_LINK'][lg] }}
          </label>
          <input id="properties && properties.label" v-model="output.data.label" type="string"
            class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldName(properties)">
        </div>

        <div v-if="properties && properties.type != undefined" class=" w-full px-2 mt-6">
          <label class="text-left block   text-white text-xs  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_DESCRIPTION'][lg] }}
          </label>
          <textarea id="properties && properties.description" v-model="output.data.description" type="string"
            class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldDescription(properties)" />
        </div>
        <div class=" w-full px-2 mt-6">
          <label class="text-left block   text-white text-xs  mb-2" htmlFor="grid-name">

            {{ lg_register['LABEL_INDEX'][lg] }}
          </label>

          <input id="properties && properties.index" v-model="output.data.index" type="string"
            class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldIndex(properties)">
        </div>
      </div>
      <div v-if="properties && properties.active == undefined" class="relative w-full mb-3 flex flex-wrap pt-3">
        <div class=" w-full px-2 mt-6">
          <label class="text-left block   text-white text-sm  mb-2" htmlFor="grid-name">

            {{ lg_register['INFO_NOT_SELECTED'][lg] }}


          </label>
        </div>
      </div>
      <hr v-if="properties && properties.active != undefined" class="text-white">

      <div v-if="properties && (properties.type == 'custom' )" class="relative w-full mb-2 flex flex-wrap pt-3"
        style="height:500px; overflow-y:auto; overflow-x: hidden">
        <div class=" w-full px-2 mt-6">
          <label v-if="properties && properties.type != undefined" class="text-left block   text-white text-xs  mb-2"
            htmlFor="grid-name">

            {{ lg_register['LABEL_SHORTNAME_TASK'][lg] }}

          </label>
          <label v-if="properties && properties.markerEnd != undefined"
            class="text-left block   text-white text-xs  mb-2" htmlFor="grid-name">

            {{ lg_register['LABEL_SHORTNAME_LINK'][lg] }}
          </label>
          <input id="properties.data.shortlabel" v-model="output.data.shortlabel" type="string"
            class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldShortName(properties)">
        </div>
        <label class="block   text-white text-sm  mb-2 w-full" htmlFor="grid-name">
          <div class="flex flex-row w-full items-start">

            <div class="w-full justify-center px-10 pt-3">
              <span class="mb-6 font-normal ">texte explication Configuration du formulaire</span>
              <div class="w-full h-6" />
              <button v-if="properties && properties.active != undefined" style="float:right" class="
                            hidden
                                w-full
                                cursor-pointer
                                text-xs
                                leading-none
                                px-3
                                py-1
                                border 
                                border-solid 
                                border-emerald
                                rounded
                                bg-slate-600
                                text-white
                                block
                                font-semibold
                                outline-none
                                focus:outline-none
                        " type="button" @click="onEditForm(properties)">
                {{ lg_register['LABEL_BT_CONFIG_FORM'][lg] }}
              </button>

              <button v-if="properties?.data?.action_id != undefined" style="float:right" class="
                            w-full
                            cursor-pointer
                            text-xs
                            leading-none
                            px-3
                            py-1
                            border 
                            border-solid 
                            border-emerald
                            rounded
                            bg-slate-600
                            text-white
                            block
                            font-semibold
                            outline-none
                            focus:outline-none
                    " type="button" @click="onEditTab(properties)">
                {{ lg_register['LABEL_BT_CONFIG_FORM'][lg] }}
              </button>





              <button v-if="properties?.data?.action_id == undefined" style="float:right" class="
                            w-full
                            cursor-pointer
                            text-xs
                            leading-none
                            px-3
                            py-1
                            border 
                            border-solid 
                            border-emerald
                            rounded
                            bg-slate-600
                            text-white
                            block
                            font-semibold
                            outline-none
                            focus:outline-none
                    " type="button" @click="onCreateTab(properties)">
                {{ lg_register['LABEL_BT_CONFIG_FORM'][lg] }} (NEW)
              </button>


            </div>
          </div>
          <div v-if="properties?.type =='custom-batch'" class="relative w-full mb-3 flex flex-wrap pt-3   ">
            <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">

              {{ lg_register['LABEL_BATCH_FORMAT'][lg] }}
            </label>
            <textarea id="properties.data.init_context" v-model="output.data.init_context" type="string"
              class="mr-2 h-48 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              @input="updateInitContext()" />

          </div>

        </label>
      </div>



      <div v-if="properties && (properties.type == 'custom-code' )" class="relative w-full mb-2 flex flex-wrap pt-3"
        style="height:400px; overflow-y:auto; overflow-x: hidden">
        <label class="block   text-white text-sm  mb-2 w-full" htmlFor="grid-name">
          <div class="flex flex-row w-full">

            <div class="w-full justify-center px-24 pt-3">
              <span class="mb-6 font-normal "> {{ lg_register['LABEL_EDIT_CODE'][lg] }}</span>
              <div class="w-full h-6" />
              <button v-if="properties && properties.active != undefined" style="float:right" class="
                            w-full
                            cursor-pointer
                            text-xs
                            leading-none
                            px-3
                            py-1
                            border 
                            border-solid 
                            border-emerald
                            rounded
                            bg-slate-600
                            text-white
                            block
                            font-semibold
                            outline-none
                            focus:outline-none
                    " type="button" @click="onEditCode(properties)">
                {{ lg_register['LABEL_BT_EDIT_CODE'][lg] }}
              </button>


            </div>
          </div>


        </label>
      </div>


      <div v-if="properties && properties.type == 'custom-start'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">
        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_TRIGGER_TYPE'][lg] }} [{{ output.data.trigger }}]
        </label>
        <div class="flex flex-col w-full text-gray-100">
          <div class="flex items-center mb-4">
            <input id="trigger-radio-1" v-model="output.data.trigger" type="radio" value="console" name="trigger-type"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="updateFieldTrigger(properties)">
            <label for="default-radio-1" class="ms-2 text-sm font-medium text-gray-100 dark:text-gray-300">Action
              Console</label>
          </div>
          <div class="flex items-center">
            <input id="trigger-radio-2" v-model="output.data.trigger" type="radio" value="time" name="trigger-type"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="updateFieldTrigger(properties)">
            <label for="default-radio-2" class="ms-2 text-sm font-medium text-gray-100 dark:text-gray-300">Prévue à une
              date</label>
          </div>
        </div>
      </div>


      <div v-if="properties && properties.type == 'custom-save'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">
        <label class="block  mt-2 text-white text-sm  mb-2" htmlFor="grid-name">

          Mode de Recherche
        </label>
        <div class="flex flex-row w-full text-gray-100 items-center w-full justify-between  mb-4">
          <div class="flex items-center  w-full ">
            <input id="save-action-radio-1" v-model="output.data.action" type="radio" value="add" name="action"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="updateFieldAction(properties)">
            <label for="default-radio-1"
              class="ms-2 text-sm font-medium text-gray-100 dark:text-gray-300">Ajouter</label>
          </div>
          <div class="flex items-center  w-full ">
            <input id="save-action-radio-2" v-model="output.data.action" type="radio" value="update" name="action"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="updateFieldAction(properties)">
            <label for="default-radio-2" class="ms-2 text-sm font-medium text-gray-100 dark:text-gray-300">Mettre à
              jour</label>
          </div>
        </div>

        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_SOURCE'][lg] }} ***
        </label>
        <input id="properties && properties.label" v-model="output.data.source" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldSource(properties)">

        <label class="block   text-white text-sm  mb-2 mt-2" htmlFor="grid-name">

          {{ lg_register['LABEL_DATASOURCE'][lg] }}
        </label>
        <input id="properties && properties.label" v-model="output.data.destination" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldDestination(properties)">

        <label v-if="output.data.action=='update'" class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_ID'][lg] }}
        </label>
        <input v-if="output.data.action=='update'" id="properties && properties.label" v-model="output.data.identifier"
          type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldId(properties)">
      </div>



      <div v-if="properties && properties.type == 'custom-collection'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">
        <label class="block  mt-2 text-white text-sm  mb-2" htmlFor="grid-name">

          Mode de Recherche
        </label>
        <div class="flex flex-row w-full text-gray-100">
          <div class="flex items-center mb-4">
            <input id="mode-radio-1" v-model="output.data.mode" type="radio" value="find" name="mode-type"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="updateFieldMode(properties)">
            <label for="default-radio-1" class="ms-2 text-sm font-medium text-gray-100 dark:text-gray-300">Rechercher
              des
              Elements</label>
          </div>
          <div class="flex items-center">
            <input id="mode-radio-2" v-model="output.data.mode" type="radio" value="get" name="mode-type"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="updateFieldMode(properties)">
            <label for="default-radio-2" class="ms-2 text-sm font-medium text-gray-100 dark:text-gray-300">Retourner un
              Element</label>
          </div>
        </div>










        <label class="block mt-2   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_SOURCE'][lg] }}
        </label>
        <input id="properties && properties.label" v-model="output.data.source" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldSource(properties)">



        <label v-if="output.data?.mode == 'get'" class="block mt-2   text-white text-sm  mb-2" htmlFor="grid-name">
          Tableau
        </label>
        <input v-if="output.data?.mode == 'get'" id="properties && properties.items" v-model="output.data.items"
          type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldItems(properties)">





        <label v-if="output.data?.mode == 'get'" class="block  mt-2  text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_ID'][lg] }}
        </label>
        <input v-if="output.data?.mode == 'get'" id="properties && properties.id" v-model="output.data.identifier"
          type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldId(properties)">




        <label v-if="output.data?.mode == 'find'" class="block mt-2   text-white text-sm  mb-2" htmlFor="grid-name">
          Filtre
        </label>
        <input v-if="output.data?.mode == 'find'" id="properties && properties.filter" v-model="output.data.filter"
          type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldFilter(properties)">







        <label class="block mt-2   text-white text-sm  mb-2" htmlFor="grid-name">
          Projection
        </label>
        <input id="properties && properties.projection" v-model="output.data.projection" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldProjection(properties)">

        <label class="block mt-2   text-white text-sm  mb-2" htmlFor="grid-name">
          Remote ID
        </label>
        <input id="properties && properties.remote_identifier" v-model="output.data.remote_identifier" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldRemoteIdentifier(properties)">





        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_DATASOURCE'][lg] }}
        </label>
        <input id="properties && properties.label" v-model="output.data.destination" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldDestination(properties)">
      </div>



      <div v-if="properties && properties.type == 'custom-code'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">
        <label class="hidden block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_DATASOURCE'][lg] }}
        </label>
        <input id="properties && properties.label" v-model="output.data.destination" type="string"
          class="hidden mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldDestination(properties)">
      </div>




      <div v-if="properties && properties.type == 'custom-stock'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">
        <label class=" block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_STOCK_WAY'][lg] }}
        </label>
        <div class="block mb-2">
          <div class="flex ml-3">
            <div class="flex items-center me-4">
              <input id="stock-way-add-radio" v-model="output.data.action" type="radio" value="ADD"
                name="inline-radio-group" checked
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @input="updateFieldAction(properties)">
              <label for="stock-way-add-radio"
                class="ms-2 text-sm font-medium text-white dark:text-gray-300">Ajouter</label>
            </div>
            <div class="flex items-center me-4">
              <input id="stock-way-remove-radio" v-model="output.data.action" type="radio" value="REMOVE"
                name="inline-radio-group"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @input="updateFieldAction(properties)">
              <label for="stock-way-remove-radio"
                class="ms-2 text-sm font-medium text-white dark:text-gray-300">Supprimer</label>
            </div>
          </div>
        </div>

        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_LOCATION_OUT'][lg] }}
        </label>
        <input id="properties && properties.from_id" v-model="output.data.from_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldLocationIn(properties)">

        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_LOCATION_IN'][lg] }}
        </label>
        <input id="properties && properties.to_id" v-model="output.data.to_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldLocationOut(properties)">


        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_PRODUCT'][lg] }}
        </label>
        <input id="properties && properties.product_id" v-model="output.data.product_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldProduct(properties)">


        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_QUANTITY'][lg] }}
        </label>
        <input id="properties && properties.quantity" v-model="output.data.quantity" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldQuantity(properties)">


        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_UNIT'][lg] }}
        </label>
        <input id="properties && properties.unit" v-model="output.data.unit" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldUnit(properties)">

        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_BATCH_NUMBER'][lg] }}
        </label>
        <input id="properties && properties.batch_number" v-model="output.data.batch_number" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldBatchNumber(properties)">


        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_END_OF_LIFE'][lg] }}
        </label>
        <input id="properties && properties.end_of_life" v-model="output.data.end_of_life" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldEndOfLife(properties)">


        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_MANUFACTURE_DATE'][lg] }}
        </label>
        <input id="properties && properties.manufacture_date" v-model="output.data.manufacture_date" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldManufactureDate(properties)">
        <hr>

        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          Products
        </label>
        <input id="properties && properties.products" v-model="output.data.products" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldProducts(properties)">

        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          Information
        </label>
        <input id="properties && properties.information" v-model="output.data.information" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldInformation(properties)">
      </div>

      <div v-if="properties && properties.type == 'custom-stock-new'"
        class="relative w-full mb-3 flex flex-wrap pt-3  pl-2  ">
        <div class="mt-2  text-left w-full">
          <label class="w-full  block mt-6  text-white text-sm  mb-2  text-left" htmlFor="grid-name">
            {{ lg_register['LABEL_SOURCE'][lg] }}
          </label>
          <input id="properties && properties.label" v-model="output.data.source" type="string"
            class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldSource(properties)">
        </div>

        <div class="mt-2  text-left w-full">
          <label class="  block   text-white text-sm  mb-2  text-left" htmlFor="grid-name">
            {{ lg_register['LABEL_LOCATION_OUT'][lg] }}
          </label>
          <input id="properties && properties.from_id" v-model="output.data.from_id" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldLocationIn(properties)">
        </div>


        <div class="mt-2 text-left w-full">
          <label class="  block   text-white text-sm  mb-2  text-left" htmlFor="grid-name">
            default {{ lg_register['LABEL_LOCATION_IN'][lg] }}
          </label>
          <input id="properties && properties.default_to_id" v-model="output.data.default_to_id" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldLocationDefaultTo(properties)">
        </div>

        <hr>

        <label class="mt-6 block text-left w-full text-white text-sm  mb-2" htmlFor="grid-name">
          Attributs
        </label>
        <div class="mt-2 text-left text-left w-full flex">
          <label class=" block  w-full text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_PRODUCT'][lg] }}
          </label>
          <input id="properties && properties.product_id" v-model="output.data.product_id" type="string"
            class=" w-48  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring   ease-linear transition-all duration-150"
            @input="updateFieldProduct(properties)">
        </div>

        <div class="mt-2 text-left w-full  flex">
          <label class="block w-full  text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_QUANTITY'][lg] }}
          </label>
          <input id="properties && properties.quantity" v-model="output.data.quantity" type="string"
            class="w-48   mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
            @input="updateFieldQuantity(properties)">
        </div>

        <div class="mt-2 text-left w-full  flex">
          <label class="block w-full text-white text-sm  mb-2 w-full" htmlFor="grid-name">
            {{ lg_register['LABEL_UNIT'][lg] }}
          </label>
          <input id="properties && properties.unit" v-model="output.data.unit" type="string"
            class=" w-48  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
            @input="updateFieldUnit(properties)">
        </div>

        <div class="mt-2 text-left w-full  flex">
          <label class="block w-full  text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_BATCH_NUMBER'][lg] }}
          </label>
          <input id="properties && properties.batch_number" v-model="output.data.batch_number" type="string"
            class=" w-48  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
            @input="updateFieldBatchNumber(properties)">
        </div>

        <div class="mt-2 text-left w-full  flex">
          <label class="block  w-full text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_END_OF_LIFE'][lg] }}
          </label>
          <input id="properties && properties.end_of_life" v-model="output.data.end_of_life" type="string"
            class="w-48  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
            @input="updateFieldEndOfLife(properties)">
        </div>

        <div class="mt-2 text-left w-full flex">
          <label class="block  w-full text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_MANUFACTURE_DATE'][lg] }}
          </label>
          <input id="properties && properties.manufacture_date" v-model="output.data.manufacture_date" type="string"
            class=" w-48 mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring   ease-linear transition-all duration-150"
            @input="updateFieldManufactureDate(properties)">
        </div>

        <div class="mt-2 text-left w-full flex">
          <label class="block w-full text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_LOCATION_IN'][lg] }}
          </label>
          <input id="properties && properties.to_id" v-model="output.data.to_id" type="string"
            class="  w-48 mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldLocationOut(properties)">
        </div>

        <div class="mt-2 text-left w-full flex">
          <label class="block  w-full text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_SUPPLIER'][lg] }}
          </label>
          <input id="properties && properties.supplier_id" v-model="output.data.supplier_id" type="string"
            class=" w-48  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldSupplier(properties)">
        </div>


        <div class="hidden">
          <label class=" block   text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_STOCK_WAY'][lg] }}
          </label>
          <div class="block mb-2">
            <div class="flex ml-3">
              <div class="flex items-center me-4">
                <input id="stock-way-add-radio" v-model="output.data.action" type="radio" value="ADD"
                  name="inline-radio-group" checked
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  @input="updateFieldAction(properties)">
                <label for="stock-way-add-radio"
                  class="ms-2 text-sm font-medium text-white dark:text-gray-300">Ajouter</label>
              </div>
              <div class="flex items-center me-4">
                <input id="stock-way-remove-radio" v-model="output.data.action" type="radio" value="REMOVE"
                  name="inline-radio-group"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  @input="updateFieldAction(properties)">
                <label for="stock-way-remove-radio"
                  class="ms-2 text-sm font-medium text-white dark:text-gray-300">Supprimer</label>
              </div>
            </div>
          </div>
        </div>





        <div class="hidden">
          <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_LOCATION_IN'][lg] }}
          </label>
          <input id="properties && properties.to_id" v-model="output.data.to_id" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldLocationOut(properties)">
        </div>






        <div class="hidden">
          <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_END_OF_LIFE'][lg] }}
          </label>
          <input id="properties && properties.end_of_life" v-model="output.data.end_of_life" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldEndOfLife(properties)">
        </div>
        <div class="hidden">
          <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_MANUFACTURE_DATE'][lg] }}
          </label>
          <input id="properties && properties.manufacture_date" v-model="output.data.manufacture_date" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldManufactureDate(properties)">
        </div>

        <hr>
        <div class="hidden">
          <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
            Products
          </label>
          <input id="properties && properties.products" v-model="output.data.products" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldProducts(properties)">
        </div>


        <div class="hidden">
          <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
            Information
          </label>
          <input id="properties && properties.information" v-model="output.data.information" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldInformation(properties)">
        </div>
      </div>




      <div v-if="properties && properties.type == 'custom-push'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">
        <label class=" block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_STOCK_WAY'][lg] }}
        </label>
        <div class="block mb-2">
          <div class="flex ml-3">
            <div class="flex items-center me-4">
              <input id="push-way-add-radio" v-model="output.data.action" type="radio" value="NOTIFICATION"
                name="inline-radio-group" checked
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @input="updateFieldAction(properties)">
              <label for="push-way-add-radio"
                class="ms-2 text-sm font-medium text-white dark:text-gray-300">Notification</label>
            </div>
            <div class="flex items-center me-4">
              <input id="push-way-remove-radio" v-model="output.data.action" type="radio" value="DATA"
                name="inline-radio-group"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @input="updateFieldAction(properties)">
              <label for="push-way-remove-radio"
                class="ms-2 text-sm font-medium text-white dark:text-gray-300">Données</label>
            </div>
          </div>
        </div>
        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_TITLE'][lg] }}
        </label>
        <input id="properties && properties.title" v-model="output.data.title" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldTitle(properties)">

        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_BODY'][lg] }}
        </label>
        <input id="properties && properties.body" v-model="output.data.body" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldBody(properties)">


        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_SCOPE'][lg] }}
        </label>
        <input id="properties && properties.scope" v-model="output.data.scope" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldScope(properties)">
      </div>





      <div v-if="properties && properties.type == 'custom-push-document-mail'"
        class="relative w-full mb-3 flex flex-wrap pt-6  pl-2  ">

        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_TITLE'][lg] }}
        </label>
        <input id="properties && properties.title" v-model="output.data.title" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldTitle(properties)">

        <label class="mt-2 block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_FILE_TEMPLATE'][lg] }}
        </label>
        <input id="properties && properties.document_tpl_id" v-model="output.data.document_tpl_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldDocumentTplId(properties)">

        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_EMAIL_ADDRESSES'][lg] }}
        </label>
        <textarea id="properties.data.emails" v-model="output.data.emails" type="string"
          class="mr-2 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateEmails()" />
        <label class="mt-2 block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_EMAIL_TEMPLATE'][lg] }}
        </label>
        <input id="properties && properties.mail_tpl_id" v-model="output.data.mail_tpl_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldMailTplId(properties)" @change="updateFieldMailTplId(properties)">


          <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
            {{ lg_register['LABEL_FUNCTION'][lg] }}
          </label>
          <input id="properties && properties.scope" v-model="output.data.scope" type="string"
            class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            @input="updateFieldScope(properties)">



      </div>




      <div v-if="properties && properties.type == 'custom-api'"
        class="relative w-full mb-3 flex flex-col text-left justify-start pt-6  pl-2  ">
        <label class=" block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_STOCK_WAY'][lg] }}
        </label>
        <div class="block mb-2">
          <div class="flex ml-3">
            <div class="flex items-center me-4">
              <input id="push-way-add-radio" v-model="output.data.action" type="radio" value="NOTIFICATION"
                name="inline-radio-group" checked
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @input="updateFieldAction(properties)">
              <label for="push-way-add-radio" class="ms-2 text-sm font-medium text-white dark:text-gray-300">Get</label>
            </div>
            <div class="flex items-center me-4">
              <input id="push-way-remove-radio" v-model="output.data.action" type="radio" value="DATA"
                name="inline-radio-group"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @input="updateFieldAction(properties)">
              <label for="push-way-remove-radio"
                class="ms-2 text-sm font-medium text-white dark:text-gray-300">Post</label>
            </div>
          </div>
        </div>


        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          URL
        </label>
        <input id="properties && properties.title" v-model="output.data.title" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldTitle(properties)">

        <label class="  block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_BODY'][lg] }}
        </label>
        <input id="properties && properties.body" v-model="output.data.body" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldBody(properties)">
      </div>




      <div v-if="properties && properties.type == 'custom-batch'"
        class="  w-full mb-3 flex flex-col pt-3  justify-start  ">
        <label class="block w-full  text-white text-sm  mb-2  text-left" htmlFor="grid-name">

          {{ lg_register['LABEL_BATCH_FORMAT'][lg] }}
        </label>
        <textarea id="properties.data.batchformula" v-model="output.data.batchformula" type="string"
          class="w-full  mr-2 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateBatchFormula()" />
        <label class="text-left">{{ getBatchNumberSample }}</label>



        <label class="w-full  block mt-6  text-white text-sm  mb-2  text-left" htmlFor="grid-name">
          {{ lg_register['LABEL_DATASOURCE'][lg] }}
        </label>
        <input id="properties && properties.label" v-model="output.data.destination" type="string"
          class="mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldDestination(properties)">
      </div>


      <div v-if="properties && properties.type == 'custom-send-mail'"
        class="relative w-full mb-3 flex flex-wrap pt-3   ">
        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_EMAIL_ADDRESSES'][lg] }}
        </label>
        <textarea id="properties.data.emails" v-model="output.data.emails" type="string"
          class="mr-2 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateEmails()" />
        <label class="mt-2 block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_EMAIL_TEMPLATE'][lg] }}
        </label>
        <input id="properties && properties.mail_tpl_id" v-model="output.data.mail_tpl_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldMailTplId(properties)" @change="updateFieldMailTplId(properties)">
      </div>

      <div v-if="properties && properties.type == 'custom-document'"
        class="relative w-full mb-3 flex flex-wrap pt-3   ">
        <label class="mt-2 block   text-white text-sm  mb-2" htmlFor="grid-name">

          {{ lg_register['LABEL_EMAIL_TEMPLATE'][lg] }}
        </label>
        <input id="properties && properties.document_tpl_id" v-model="output.data.document_tpl_id" type="string"
          class="  mr-6 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateFieldDocumentTplId(properties)">
      </div>



      <div v-if="properties && properties.type == 'custom-export'" class="relative w-full mb-3 flex flex-wrap pt-3   ">
        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_MODEL_TYPE'][lg] }}
        </label>

        <select v-model="output.data.xlsmodel" class=" appearance-none
            block
            w-full
            px-3
            py-1
            text-base
            font-normal
            text-gray-700
            bg-white 
            bg-clip-padding 
            bg-no-repeat
            border-0 
            border-solid 
            border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 
            focus:bg-white 
            
            focus:outline-none
            placeholder-slat-300
            text-slate-600 
            bg-white 
            rounded 
            text-xs 
            shadow
            w-full 
            ease-linear 
            transition-all 
            duration-150
            " aria-label="borderleftstyle" @change="updateXLSModel()">
          <option value="file_fromage">
            Fichier Fromage
          </option>
          <option value="file_yaourt">
            Fichier Yaourt
          </option>
          <option value="file_traite">
            Fichier Traite
          </option>
        </select>
      </div>

      <div v-if="properties && properties.type == 'default'" class="relative w-full mb-3 flex flex-wrap pt-3   ">
        <label class="block   text-white text-sm  mb-2" htmlFor="grid-name">
          {{ lg_register['LABEL_CONDITION'][lg] }} **
        </label>
        <textarea id="properties.condition" v-model="output.condition" type="string"
          class="mr-2 border-0 px-3 py-1 placeholder-slat-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          @input="updateConditionField()" />
        <label class="block   text-white text-sm  mb-2 w-full" htmlFor="grid-name">
          <div class="flex flex-row w-full">

            <div class="w-full justify-center px-24 pt-3">
              <span class="mb-6 font-normal "> {{ lg_register['LABEL_EDIT_CODE'][lg] }}</span>
              <div class="w-full h-6" />
              <button v-if="properties && properties.active != undefined" style="float:right" class="
                                w-full
                                cursor-pointer
                                text-xs
                                leading-none
                                px-3
                                py-1
                                border 
                                border-solid 
                                border-emerald
                                rounded
                                bg-slate-600
                                text-white
                                block
                                font-semibold
                                outline-none
                                focus:outline-none
                        " type="button" @click="onEditCondition(properties)">
                {{ lg_register['LABEL_BT_EDIT_CODE'][lg] }}
              </button>


            </div>
          </div>


        </label>
      </div>
      <hr class="mt-12">
      <div class="absolute bottom-1  w-full mb-3 flex flex-wrap pt-3 px-24  ">
        <button v-if="properties && properties.active != undefined" style="float:right"
          class=" w-full cursor-pointer text-xs leading-none px-3 py-2 border border-solid border-red-800 rounded bg-red-600 text-white block font-semibold outline-none focus:outline-none"
          type="button" @click="onRemove(properties)">
          {{ lg_register['LABEL_BUTTON_DELETE'][lg] }}
        </button>
      </div>
    </div>


    <!-- Modal -->
    <div id="popup_confirmation_delete" tabindex="-1"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
      <div class="relative w-full h-full max-w-2xl md:h-auto">
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div
            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 id="exampleModalLabel" class="text-xl font-medium leading-normal text-gray-800">
              Remove
              Confirmation
            </h5>
            <button type="button"
              class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body relative p-4 text-black text-sm">
            <br>
            Are you sure you want to delete the <span v-if="properties &&properties.type != undefined">task:
            </span>
            <span v-if="properties && properties.markerEnd != undefined">edge: </span>
            <strong>{{ output.data.label }}</strong> ?
            <br><br>
          </div>
          <div
            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button" class="px-6
    py-2.5
    bg-slate-200
    text-slate-800
    font-medium
    text-xs
    leading-tight
    uppercase
    rounded
    shadow-md
    hover:bg-slate-700 hover:shadow-lg hover:text-slate-200
    focus:bg-slate-700 focus:shadow-lg focus:outline-none focus:ring-0 focus:text-slate-200
    active:bg-slate-800 active:shadow-lg active:text-slate-200
    transition
    duration-150
    ease-in-out" data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="px-6
py-2.5
bg-red-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-res-700 hover:shadow-lg
focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-red-800 active:shadow-lg
transition
duration-150
ease-in-out
ml-1" @click="confirmRemove">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="popup_confirmation_parameter_delete" tabindex="-1"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
      <div class="relative w-full h-full max-w-2xl md:h-auto">
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div
            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 id="exampleModalLabel" class="text-xl font-medium leading-normal text-gray-800">
              Delete
              Confirmation Parameter
            </h5>
            <button type="button"
              class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body relative p-4 text-black text-sm">
            <br>
            Are you sure you want to delete the parameter :
            <br>
            <div class="pt-2">
              <strong>{{ parameter_name }}</strong> ?
            </div>

            <br><br>
          </div>
          <div
            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button" class="px-6
py-2.5
bg-slate-200
text-slate-800
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-slate-700 hover:shadow-lg hover:text-slate-200
focus:bg-slate-700 focus:shadow-lg focus:outline-none focus:ring-0 focus:text-slate-200
active:bg-slate-800 active:shadow-lg active:text-slate-200
transition
duration-150
ease-in-out" data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="px-6
py-2.5
bg-red-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-res-700 hover:shadow-lg
focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-red-800 active:shadow-lg
transition
duration-150
ease-in-out
ml-1" @click="confirmDeleteParameter">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
  import { Modal } from 'flowbite'
  import { defineComponent } from 'vue'
  // import jq from "jquery"
  import lg_register from "@/assets/lg/flow_designer.json"
  import EditTabletFormModal from '@/components/modals/EditTabletForm.vue'

  import EditCodeModal from '@/components/modals/EditCodeModal.vue'

  import Handlebars from "handlebars";


  export default defineComponent({
    components: { EditTabletFormModal, EditCodeModal },
    props: {
      properties: {}
    },
    data() {
      return {
        profile: { title: "", lines: [] },
        lg_register: lg_register,
        lg: "FR",
        dlg: null,
        selectProductTypeModal: null,
        editCodeModal: null,
        modal: null,
        parameter_name: "",
        output:
        {
          label: null,
          active: null,
          condition: null,
          data: {
            items: "",
            parameters: [],
            condition: null,
            supplier_id: null,
            label: null,
            remote_identifier: null,
            action: "add",
            shortlabel: null,
            description: null
          }
        }
      }
    },
    computed: {
      // a computed getter
      getBatchNumberSample() {
        // `this` points to the component instance



        let js_result = this.runFormula()

        if (js_result == undefined) return ""
        return "Example: " + js_result
      }
    },
    watch:
    {
      properties: function (val) {
        if (val != undefined) {
          //  
          if (val == undefined)
            this.output = {
              label: null,

              active: null,
              condition: null,
              data: {
                parameters: [],
                condition: null,
                label: null,
                supplier_id: null,
                action: "add",
                remote_identifier: null,
                shortlabel: null,
                description: null
              }
            }
          else {
            this.output = val
          }



        }


      }
    },
    mounted() {
      {
        const $modalElement = document.querySelector('#edit-tablet-form-modal');

        const modalOptions = {
          placement: 'center',
          backdrop: 'dynamic',
          backdropClasses:
            'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
          closable: true,
          //     onHide: () => {
          //         
          //     },
          //     onShow: () => {
          //        
          //     },
          //     onToggle: () => {
          //        
          //     },
        };

        // instance options object
        const instanceOptions = {
          id: 'edit-tablet-form-modal',
          override: true
        };

        this.selectProductTypeModal = new Modal($modalElement, modalOptions, instanceOptions);

      }
      {
        const $modalElement = document.querySelector('#edit-code-source-modal');


        const modalOptions = {
          placement: 'center',
          backdrop: 'dynamic',
          backdropClasses:
            'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
          closable: true,
          //       onHide: () => {

          //       },
          //       onShow: () => {

          //        },
          //       onToggle: () => {

          //        },
        };

        // instance options object
        const instanceOptions = {
          id: 'edit-code-source-modal',
          override: true
        };

        this.editCodeModal = new Modal($modalElement, modalOptions, instanceOptions);

      }

    },
    methods:
    {
      onSaveCodeModal: function (code) {
        this.output.data.profile = code

        this.editCodeModal.hide()
      },
      onCloseCodeModal: function () {


        this.editCodeModal.hide()
      },
      onCloseForm: function () {


        this.selectProductTypeModal.hide()
      },
      onUpdateForm: function (/*prf*/) {

        //  this.output.data.profile = prf
      },
      onSaveForm: function (prf) {

        let prf2 = {}

        prf2.lines = prf.lines[0];
        prf2.title = prf.title[0];

        prf2.modals = [];

        let prf3 = {}

        prf3.lines = prf.lines[1];
        prf3.title = prf.title[1];

        prf2.modals.push(prf3)

        let prf4 = {}

        prf4.lines = prf.lines[2];
        prf4.title = prf.title[2];

        prf2.modals.push(prf3)



        this.output.data.profile = prf2



        this.selectProductTypeModal.hide()
        this.$emit("updateProperties", JSON.stringify({ type: "profile", data: this.output }))
      },

      array_move: function (arr, old_index, new_index) {

        let arr_new = JSON.parse(JSON.stringify(arr))
        var element = arr_new[old_index];
        arr_new.splice(old_index, 1);
        arr_new.splice(new_index, 0, element);
        return arr_new
      },
      onEditForm: function () {
        this.profile = this.output.data.profile

        this.selectProductTypeModal.show()
        //   this.$emit("showFormEdit", this.output);
      },
      onEditTab: function () {



        if (this.output.data.action_id != null) {
          this.$router.push("/admin/new-editor/" + this.output.data.action_id);
        }



        // this.profile = this.output.data.profile
        // this.selectProductTypeModal.show()
        //   this.$emit("showFormEdit", this.output);
      },
      onCreateTab: function () {

        this.$emit("createAction", this.output)



        // this.profile = this.output.data.profile
        // this.selectProductTypeModal.show()
        //   this.$emit("showFormEdit", this.output);
      },

      onEditCode: function () {
        this.profile = this.output.data.profile
        if (this.editCodeModal != undefined)
          this.editCodeModal.show()
        //   this.$emit("showFormEdit", this.output);
      },

      onEditCondition: function (properties) {
        console.log("onEditCondition")
        console.log(JSON.stringify(properties))
        console.log(JSON.stringify(this.output))
        this.profile = this.output.data.profile
        if (this.editCodeModal != undefined)
          this.editCodeModal.show()
        //   this.$emit("showFormEdit", this.output);
      },




      initiateDeleteParameter: function (a) {

        this.current_parameter = a;

        this.parameter_name = a.name;

        // this.dlg = new window.Modal(jq("#popup_confirmation_parameter_delete"), {})
        // this.dlg.show()
        //let el = jq("#popup_confirmation_parameter_delete")
        //if (el) {
        //    this.dlg_parameter = el;
        //    el.modal('show')
        // }





      },

      formatNumber(v, n) {
        v = v.toString()
        for (let i = 0; i < n; i++) {
          if (v.length < (i + 1)) v = '0' + v
        }
        return v;
      },
      /*
      {{year}}{{month}}{{day}}{{day_number}}
      L{{year}}{{day_number}}{{hour}}{{minute}}
      */
      runFormula: function () {
        try {
          const template = Handlebars.compile(this.output.data.batchformula);

          let d = new Date()

          let record =
          {
            code2: "YY",
            year: this.formatNumber(d.getYear(), 2),
            month: this.formatNumber(d.getMonth(), 2),
            day: this.formatNumber(d.getDay(), 2),
            day_number: this.formatNumber(Math.floor((d - new Date(d.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24), 3),
            hour: this.formatNumber(d.getHours(), 2),
            minute: this.formatNumber(d.getMinutes(), 2),
          }

          return template(record)
        }
        catch (e) {
          console.log(e)
        }
        return ""
      },

      updateParameterField: function () {

      },

      updateConditionField: function () {

      },

      updateInitContext: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "init_context", data: this.output }))
      },

      updateEmails: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "emails", data: this.output }))
      },


      updateFieldMailTplId: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "mail_tpl_id", data: this.output }))
      },


      updateFieldDocumentTplId: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "document_tpl_id", data: this.output }))
      },
      updateFieldMode: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "mode", data: this.output }))
      },




      updateFieldTrigger: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "trigger", data: this.output }))
      },
      updateFieldDestination: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "destination", data: this.output }))
      },

      updateFieldFilter: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "filter", data: this.output }))
      },
      updateFieldProjection: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "projection", data: this.output }))
      },
      updateFieldRemoteIdentifier: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "remote_identifier", data: this.output }))
      },

      updateFieldQuantity: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "quantity", data: this.output }))
      },

      updateFieldUnit: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "unit", data: this.output }))
      },


      updateFieldManufactureDate: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "manufacture_date", data: this.output }))
      },
      updateFieldProducts: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "products", data: this.output }))
      },
      updateFieldInformation: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "information", data: this.output }))
      },


      updateFieldBatchNumber: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "batch_number", data: this.output }))
      },


      updateFieldEndOfLife: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "end_of_life", data: this.output }))
      },
      updateFieldAction: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "action", data: this.output }))
      },

      updateFieldLocationIn: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "from_id", data: this.output }))
      },
      updateFieldLocationOut: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "to_id", data: this.output }))
      },


      updateFieldSupplier: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "supplier_id", data: this.output }))
      },



      updateFieldLocationDefaultTo: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "default_to_id", data: this.output }))
      },



      updateFieldTitle: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "title", data: this.output }))
      },

      updateFieldBody: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "body", data: this.output }))
      },



      updateFieldScope: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "scope", data: this.output }))
      },






      updateFieldProduct: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "product_id", data: this.output }))
      },
      updateFieldId: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "identifier", data: this.output }))
      },
      updateFieldItems: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "items", data: this.output }))
      },

      updateFieldSource: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "source", data: this.output }))
      },


      updateFieldName: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "name", data: this.output }))
      },

      updateFieldShortName: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "shortname", data: this.output }))
      },


      updateFieldIndex: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "index", data: this.output }))
      },
      updateParameterFieldName: function (event, item) {

        item.name = event.target.value
      },
      updateFieldDescription: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "description", data: this.output }))
      },

      updateBatchFormula: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "batchformula", data: this.output }))
      },
      updateXLSModel: function () {
        if (this.output.data == undefined) this.output.data = {}

        this.$emit("updateProperties", JSON.stringify({ type: "xlsmodel", data: this.output }))
      },



      confirmRemove: function () {

        if (this.properties && this.properties.type != undefined) this.$emit("removeNode", this.properties)
        if (this.properties && this.properties.source != undefined) this.$emit("removeEdge", this.properties)
        //this.dlg.hide()
        //this.$emit("removeNode", this.properties)
        //this.$emit("removeEdge", this.properties)
        this.modal.toggle()
      },
      onRemove: function (/*properties*/) {
        const $targetEl = document.getElementById('popup_confirmation_delete');
        /*   const options = {
               placement: 'bottom-right',
               backdrop: 'dynamic',
               backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
               closable: true
           }*/
        this.modal = new Modal($targetEl);
        //this.dlg = new window.Modal(jq("#popup_confirmation_delete"), {})
        //this.dlg.show()
        this.modal.toggle()

      }
    }

  }
  )

</script>


<style>
  aside2 {

    color: #fff;
    font-weight: 700;
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: rgba(242, 250, 247, 0.75);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3);
    box-shadow: 0 5px 10px #0000004d
  }

  aside2 .nodes>* {
    margin-bottom: 10px;
    cursor: grab;
    font-weight: 500;
    -webkit-box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, .25);
    box-shadow: 5px 5px 10px 2px #00000040
  }

  aside2 .description {
    margin-bottom: 10px
  }

  @media screen and (min-width: 640px) {
    aside2 {
      min-width: 25% !important
    }
  }

  @media screen and (max-width: 639px) {
    aside2 .nodes {
      /*display: flex;*/
      flex-direction: row;
      gap: 5px
    }
  }
</style>
<template>
  <div
    id="app2"
    class="h-full bg-gray-50 w-full"
  >
    <router-view />
  </div>
</template>

<script>

import { i18nextPromise } from './i18n.js'

export default {
  name: 'App',
  components: {
     
  },
  // used in combination with Suspense.
  // useful when translations are not in-memory...
  async setup() {
    await i18nextPromise
    return {}
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
  }
</style>
<template>
  <div class="mt-2 mb-2 p-6 w-full bg-white flex flex-col items-center relative">
    <label
      for="default"
      class="text-left block mb-2 text-lg font-bold font-medium text-gray-900 dark:text-white"
    >
      {{ label }}  
    </label>

    <div class="flex items-center justify-center w-full rounded-lg shadow-xl dark:shadow-gray-800">
      <label
        :for="`dropzone-file-${id}`"
        class=" px-12 flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div
          v-if="output[id]!=undefined"
          class=""
        >
          <div
            :id="`file-${id}`"
            style="max-height:230px"
            :class="{ 'opacity-50': isLoading }"
            class="  transition duration-300 ease-in-out  block h-full w-full object-cover object-center"
            :src="output[id]"
            :src-placeholder="empty[id]"
            @load="onImgLoad(index)"
          />


        </div>
        <div
          v-if="isLoading"
          role="status"
          class="absolute   "
        >
          <svg
            aria-hidden="true"
            class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>

        <div
          v-if="output[id]==undefined"
          class="flex flex-col items-center justify-center pt-5 pb-6"
        >
          <svg
            aria-hidden="true"
            class="w-10 h-10 mb-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span class="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        </div>
        <input
          :id="`dropzone-file-${id}`"
          :ref="`fileDocument-${id}`"
          type="file"
          class="hidden"
          @change="onFileChange"
        >

      </label>
    </div>
  </div>
</template>
<script>
    import axios from "axios";
    import { mapGetters } from "vuex";

    export default {
        name: 'ContentFileUpload',
        props: {
            id: String,
            label: String,
            source: String,
            value: String,
            config: Object
        },
        data() {
            return {
                output: {},
                empty: {},
                isLoading: false
            }
        },
        mounted() {
           
            this.output[this.id] = this.source;
            this.empty[this.id] = this.source;
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        components: {

        },

        methods: {
        
            onImgLoad() {
               
            },
            onClickDelete() {
                this.empty[this.id] = this.source;
                this.output[this.id] = null;
            },
            onFileChange() {
               
 

                this.isLoading = true;

                this.filelist = [...this.$refs['fileDocument-' + this.id].files];
              
                if (this.filelist.length > 0) {
                    // this.SetLoadingMyLots(true)
                    // this.$emit("showList", false);
                 
                    const formData = new FormData();

                    //  formData.append("path", "/products"); // appending file
                    
                    formData.append("files", this.filelist[0]); // appending file

                   

                    axios
                        .post("https://files.b4food.io/b4food/files", formData, {
                            headers: {
                                "x-access-token": this.user?.token,
                                "content-type": "multipart/form-data"
                            },
                        })
                        .then((res) => {
                            
                        
                            this.output[this.id] = res.data
                       

                            this.filelist = [];

                            // this.$refs.form.reset();
 
                            //this.SetLoadingMyLots(false)
                            this.isLoading = false;
                            // this.displayModal(false)
                            //  this.showModal2 = !this.showModal2;
                        })
                        .catch((err) => {
                            
                         
                            console.log("ERROR =========================", err);
                        });   // sending file to the backend
                    // "https://mdw-pvalette.vercel.app/api/lots/upload"
                    // "http://localhost:3000/api/lots/upload"


                }
            },

        },
        watch: {
            source: function (val) {
                this.empty[this.id] = this.source;
                this.output[this.id] = val;
            }
        }
    }
</script>
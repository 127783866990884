<template>
  <div
    class=" mr-1 relative h-18 cursor-grab flex  list-group w-full flex items-center    flex flex-row justify-start items-center "
  >
    <div class="flex flex-col items-start w-full  pl-2   ">
      <div
        v-if="properties?.option.title"
        class=" w-full flex  py-0.5  items-center justify-start w-full  font-bold text-left text-gray-700 "
        :class="{'bg-gray-200 text-sm font-bold':(properties?.template=='title-tpl-1'), 'text-base  font-bold':(properties?.template=='title-tpl-2') }"
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option.description"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-500 font-normal"
      >
        {{ properties?.description }}
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {

        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties.id })
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
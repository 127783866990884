<template>
  <div
    v-if="chartOptions != undefined"
    class="bg-gray-50 items-center  flex grow  h-auto p-16 "
  >
    **{{ chartOptions.chart }}**<br>
    **{{ lchartOptions.chart }}**
    <apexchart
      class=" flex grow h-auto  w-auto"
      :options="lchartOptions"
      :series="series"
    />
  </div>
</template>


<script>
     
     import VueApexCharts from "vue3-apexcharts";

    export default {
        name: 'ChartView',
        components: { apexchart: VueApexCharts,  },
        props: {
            chartOptions :Object ,
            series :Array
        },
        data() {
            return {
             
                lchartOptions : {
                    chart: {
                        type: 'area'
                    }
                }
            }
        },



        computed:
        {

        },

        watch: 
        {
            'chartOptions' : function()
            {
             
                this.lchartOptions = this.chartOptions;
            },
            'chartOptions.chart' : function()
            {
       
                //his.lchartOptions.chart = {type:'area'} // = this.chartOptions;
            },
            'chartOptions.chart.type' : function()
            {
         
                //this.lchartOptions = this.chartOptions;
                this.lchartOptions.chart   = this.chartOptions.chart;
            },
            'series' : function()
            {
           
                this.lchartOptions = this.chartOptions;
            }
        },
        mounted()
        {
            if( this.chartOptions != undefined)
                  this.lchartOptions = this.chartOptions;
        },
    }
</script>
<template>
  <div
    id="upload-file-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
  >
    <div class="relative w-full h-full max-w-3xl p-4 md:h-auto">
      <!-- Modal content -->
      <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between pb-4 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700"
        >
          <h3 class="font-semibold text-gray-900 dark:text-white">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE'][lg] }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5">
          <div class="w-full">
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ labels['TITLE_SELECT_PRODUCT_TYPE_COMMENT'][lg] }}
            </p>
            <div class="mt-2 w-full  text-left justify-left">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Name</label>
              <input
                id="name"
                v-model="name"
                type="text"
                name="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Ex. Nom du fichier"
              >
            </div>
                        
            <ContentFileUpload
              :id="`${item.id}-image-upload`"
              :ref="`content-file-upload-${item.id}`"
              :label="item.label && item.label[lg]"
            />
          </div>
        </div>
        <div class="flex items-center justify-end">
          <button
            type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()"
          >
            Annuler
          </button>
          <button
                    
            type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import labels from "@/assets/lg/upload-file-modal.json"
    import ContentFileUpload from '@/components/content/ContentFileUpload.vue'


    export default {
        name: 'UploadFileModal',
        components: {
            ContentFileUpload
        },
        data() {
            return ({
                lg: "FR",
                labels: labels,
                name: "",
                transparent: false,
                item: {
                    id: "1",
                    label: {
                        'EN': "",
                        'FR': ''
                    }
                },
             })
        },
        methods: {
            onValidate()
            {
             
                let ref = "content-image-upload-1"
                let file = 
                {
                    name:"",
                    url: null
                }
                if (this.$refs[ref])
                {
                    file =   this.$refs[ref].getData()
                
                }
                
                if (file.url == undefined)
                {
                    alert("Sélectionner un fichier");
                    return;
                }

                if ((this.name == undefined) && (this.name == undefined))
                {
                    alert("Saissir un nom pour l'image");
                    return;
                }
             
                let item = 
                {
                    name: this.name,
                    transparent: this.transparent,
                    file: file
                }

                this.$emit("addImage", item)


            },
           
            onClose() {
                this.$emit("close")
            }
        },
    }
</script>
<template>
  <div class="p-4 bg-orange-100 h-full w-full flex flex-row space-x-6">
    <div class="hidden sm:flex w-48">
      SM
    </div>
    <div class="hidden md:flex w-48">
      MD
    </div>
    <div class="hidden lg:flex w-48">
      LG
    </div>
    <div class="hidden xl:flex w-48">
      XL
    </div>
    <div class="hidden 2xl:flex w-48">
      2XL
    </div>
    <div class="hidden 3xl:flex w-48">
      3XL
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      
    },
    async mounted() {
         
    
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<script>

    import { defineComponent } from 'vue'
    import lg_register from "@/assets/lg/flow_designer.json"

    export default defineComponent({
        data() {
            return {
                lg_register: lg_register,
                lg: "FR"
            }
        },
        methods:
        {
            onDragStart: function (event, nodeType) {
                if (event.dataTransfer) {
                    event.dataTransfer.setData('application/vueflow', nodeType)
                    event.dataTransfer.effectAllowed = 'move'
                }
            }
        }
    })

</script>

<template>
  <aside class="dndflow_aside h-screen overflow-hidden ">
    <div class="description">
      {{ lg_register['TASK_SIDEBAR_INFO'][lg] }}
    </div>
    <div
      class="nodes  overflow-auto"
      style="height:calc(100% - 40px)"
    >
      <div
        class="h-16  flex items-center vue-flow__node-input"
        :draggable="true"
        @dragstart="onDragStart($event, 'input')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa  fa-cube mr-6"
              style="font-size:20px"
            />
          </div>
          <div
            class="w-10/12 flex    items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_START'][lg] }}</strong>
          </div>
        </div>
      </div>


      <div
        class="h-16  flex items-center vue-flow__node-input"
        :draggable="true"
        @dragstart="onDragStart($event, 'packaging')"
      >
        <div class="flex flex-row">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-cube mr-6"
              style="font-size:20px"
            />
          </div>
          <div
            class="w-10/12 flex    items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_START'][lg] }}</strong>
          </div>
        </div>
      </div>
     

      <div
        class="h-16  flex items-center vue-flow__node-default"
        :draggable="true"
        @dragstart="onDragStart($event, 'default')"
      >
        <div class="flex flex-row">
          <div class="w-2/12">
            <i
              class="fa-solid fa-boxes-stacked mr-6"
              style="font-size:28px"
            />
          </div>
          <div
            class="w-10/12  flex  items-center"
            style="text-align:center"
          >
            <strong
              class="ml-2"
            >{{ lg_register['TASK_MIDDLE'][lg] }}</strong>
          </div>
        </div>
      </div>
      <div
        class="h-16  flex items-center  vue-flow__node-output"
        :draggable="true"
        @dragstart="onDragStart($event, 'output')"
      >
        <div class="flex flex-wrap">
          <div class="w-2/12 flex  items-center">
            <i
              class="fa fa-cube  mr-6"
              style="font-size:28px"
            />
          </div>
          <div class="w-10/12 flex  justify-center items-center">
            <strong
              class="ml-2"
            >{{ lg_register['TASK_END'][lg] }}</strong>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<style>
    .dndflow aside {

        color: #fff;
        font-weight: 700;
        border-right: 1px solid #eee;
        top: 60px;
        left: 0px;

        font-size: 12px;
        /*  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3);
        box-shadow: 0 5px 10px #0000004d;*/
        min-width: 120px !important;
        width: 260px;
        padding-top: 60px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-left: 10px;
    }


    .dndflow aside .nodes>* {
        margin-bottom: 0px;
        cursor: grab;
        font-weight: 500;
        /*  -webkit-box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, .25);
        box-shadow: 5px 5px 10px 2px #00000040*/
    }

    .dndflow aside .description {
        margin-bottom: 10px;
        color: cornflowerblue
    }

    .dndflow .vue-flow-wrapper {
        flex-grow: 1;
        height: 100%
    }

    .dndflow aside .nodes {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;
    }

    .dndflow {
        flex-direction: column;
        display: flex;
        height: 100%
    }

    @media screen and (min-width: 640px) {
        .dndflow {
            flex-direction: row
        }

        .dndflow aside {
            min-width: 120px
        }
    }

    @media screen and (max-width: 639px) {
        .dndflow aside .nodes {
            display: flex;
            flex-direction: row;
            gap: 5px
        }


        @media screen and (min-width: 640px) {
            .dndflow {
                flex-direction: row
            }


        }

        @media screen and (max-width: 639px) {
            .dndflow aside .nodes {
                display: flex;
                flex-direction: row;
                gap: 5px
            }
        }
    }
</style>
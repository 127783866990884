<template>
  <div class="relative   overflow-y-auto bg-gray-50  lg:ml-4 dark:bg-gray-900">
    <div class="relative  mt-24 ml-12 px-6">
      <div class="  dark:border-gray-50 mt-2 ">
        <h2 class="px-2 text-left mb-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">
          {{ displayTitle() }}
        </h2>
        <h4 class="px-2 text-left mb-2 text-xl tracking-tight font-semibold text-gray-600 dark:text-white">
          {{ config.subtitle && config.subtitle[lg] }}
        </h4>
      </div>

      <B4FTable
        :page_id="$route.params.id"
        :current-tab="currentTab"
        :is-loading="isLoading"
        :is-loading2="isLoading2"
        :is-loading3="isLoading3"
        :limit="limit"
        :offset="offset"
        :config="config"
        :records="records"
        :filter-list="filterList"
        @row-click="onRowClick"
        @add="onAddClick"
        @query="onQuery"
        @export="onExportClick"
        @refresh="onRefreshClick"
        @refresh-tab="onRefreshTab"
        @refresh-page="onRefreshPage"
        @refresh-table="onRefreshTable"
        @download="onDownloadFile"
        @select-tab="onSelectTab"
      />
    </div>
  </div>
  <SelectProductType
    @close="OnSelectProductTypeClose"
    @select="OnSelectProductTypeSelected"
  />
  <SelectProduct
    :records="productTypes"
    @close="OnSelectProductClose"
    @select="OnSelectProductSelected"
  />
</template>
<script>
    const MAX_ITEMS = 10;
    import { Modal } from 'flowbite';
    import B4FTable from '@/components/TableComponent.vue'
    import service from "@/services/FullService.vue"
    import SelectProductType from '@/components/modals/SelectProductType.vue'
    import SelectProduct from '@/components/modals/SelectProduct.vue'


    import { mapGetters } from "vuex";
    export default {
        name: 'TableViewCollection',
        components: {
            B4FTable, SelectProductType, SelectProduct
        },
      
        data() {
            return {
                currentTab: 0,
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                config: {},
                globalQuery: '',
                category: null,
                productTypes: [],
                records: [],
                lastQuery: "",
                offset: 0,
                filters: {},
                limit: MAX_ITEMS,
                lg: "FR",
                title: "",
                filterList: [],
                people: [],

                selectProductTypeModal: null,
                selectProductModal: null,
                button: ""
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            '$route.params.id': async function (val) {
                let filters = {}
                this.category = "";
                //filters['category'] = config.filters;
                this.reloadPage(val, 0, MAX_ITEMS, filters, 1);
                window.scrollTo(0, 0);
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user.tenant': async function () {
                this.isLoading = true;
                let filters = {}
                this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);

            }
        },
        async mounted() {
            // await this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {})
            let productTypes = [];


            //Verrue pour Product
            if (this.$route.params.id == 'products') {

                let records = await service.getData("raw-products", this.user?.token, Object.assign({}, { limit: 100, offset: 0 }, {}))

                for (let i = 0; i < records.records.length; i++) {
                    let url = null;

                    if (records.records[i].image_front_product != undefined)
                        if (records.records[i].image_front_product.url != undefined)
                            url = records.records[i].image_front_product.url

                    productTypes.push(
                        {
                            id: records.records[i]._id,
                            type: records.records[i].type,
                            url: url,
                            category: records.records[i].category,
                            name: records.records[i].name
                        }
                    )
                }

                this.productTypes = productTypes




            }


            const modalEl = document.getElementById('select-product-type-modal');
            this.selectProductTypeModal = new Modal(modalEl, {
                placement: 'center'
            });

            const modalEl2 = document.getElementById('select-product-modal');
            this.selectProductModal = new Modal(modalEl2, {
                placement: 'center'
            });

            this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, {});

        },
        methods: {
            onSelectTab: function (tab) {

                let index = this.config.tabs.findIndex(function (a) { return (a.id == tab) })
                this.currentTab = index;


                let filter = this.config.tabs[this.currentTab].filter;
                this.offset = 0;
                this.limit = MAX_ITEMS;

                this.$router.push({ path: this.$route.fullPath, query: { tab: tab } });


                this.reloadPage(this.$route.params.id, this.offset, this.limit, filter, 3);

            },
            onQuery: async function (query) {

                if (this.lastQuery == query)
                    return this.filterList;


                let id = this.$route.params.id;
                let filter = { q: query }
                let records = await service.getData(id, this.user?.token, Object.assign({}, { limit: 5, offset: 0 }, filter))

                let result = []

                if (records == undefined) return [];
                if (records.records == undefined) return []
                for (let i = 0; i < records.records.length; i++) {
                    result.push({ name: records.records[i].name, reference: records.records[i].reference })
                }

                this.lastQuery = query;
                this.globalQuery = query;
                this.filterList = result
            },

            onDownloadFile: async function (record) {

                let base64 = await service.getFileBase64(record.file, this.user?.token)

                // Create an anchor element
                var link = document.createElement("a");
                link.href = base64.data;
                link.download = record.name;
                document.body.appendChild(link);
                // Click the link
                link.click();
                // Remove the link
                document.body.removeChild(link);
            },

            async onExportClick() {

                let filter = {}
                let csv = await service.getData('products', this.user?.token, Object.assign({}, { limit: 3000, offset: 0 }, filter), "csv")

                var link = document.createElement("a");
                link.href = "data:text/csv;charset=utf-8," + escape(csv);
                link.download = "export.csv";
                document.body.appendChild(link);
                // Click the link
                link.click();
                // Remove the link
                document.body.removeChild(link);


            },
            displayTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "FR"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            displaySubTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "EN"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            async reloadPageDebug(id, offset, limit, filter, level) {
console.log("id = ", id)
console.log("offset = ", offset)
console.log("limit = ", limit)
console.log("filter = ", filter)
console.log("level = ", level)

            },
            async reloadPage(id, offset, limit, filter, level) {

                //Bug de Chargement
                if (level == null) level = 1
                if (this.user == undefined) {
               //     this.$router.push("/login");
               window.location = "/login"
                    return;
                }
 
                this.offset = offset;
                this.limit = limit;

                if (level == 1) this.isLoading = true;
                if (level == 2) this.isLoading2 = true;
                if (level == 3) this.isLoading3 = true;
 
                let code = null;

                if (this.$route.query.code != undefined)
                
                    code = this.$route.query.code;
                    console.log("TabelViewCollection 2", typeof code, code, this.$route.query)
                let config = await service.getConfiguration(id, code, this.user?.token)

                if (config == undefined) {

                    return;
                } 

                //TODO Remove this, tabs must be delivered via Backoffice
                if (config.tabs == undefined) {
                    let t = await service.getFilterTabDefinition(this.$route.params.id, this.user?.token, { template: this.$route.params.id })

                    {
                        //TODO Check how to remove this
                        if ((this.$route.params.id == 'products') || (this.$route.params.id == 'raw-products') || (this.$route.params.id == 'suppliers-product')) {
                            if (this.$route.query.tab == undefined)
                                if (t.records && t.records[0]) filter.category = t.records[0].id
                        }
                        if (t) {
                            config.tabs = t.records;

                        }
                    }
                }





                /* A revoir
                                if (filter.category)
                                    this.category = filter.category;
                                else
                                    filter.category = this.category;
                */
                this.filter = filter;

                let filterBarFilter = {}
                if (this.filter?.category != undefined) {
                    filterBarFilter.category = this.filter.category
                }

                let d = await service.getFilterBarDefinition(this.$route.params.id, this.user?.token, filterBarFilter)


                config.secondBar = d.records;

                if (this.$route.query.code) {
                    filter['code'] = 'events_' + this.$route.query.code
                }

                if (this.$route.query.type) {
                    filter['type'] = this.$route.query.type
                }

                if (this.$route.query.tags) {
                    filter['tags'] = this.$route.query.tags
                }

                if (config.tabs != undefined) {
                    if (this.$route?.query?.tab == undefined) {



                        if (config.tabs.length > 0)
                            filter = config.tabs[this.currentTab].filter
                    }

                    else {
                        let q = this.$route.query.tab.toLowerCase()
                        let index = config.tabs.findIndex(function (a) { return a.id.toLowerCase() == q })

                        if (index == -1) index = 0;
                        this.currentTab = index;
                        filter = config.tabs[index].filter
                    }
                }



                this.config = config;
                this.records = await service.getData("collections/" + id, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
                if (level == 1) this.isLoading = false;
                if (level == 2) this.isLoading2 = false;
                if (level == 3) this.isLoading3 = false;

            },
            OnSelectProductTypeSelected: function (selection) {

                let template = "";

                //TODO
                if (this.$route.params.id == 'products') {

                    template = selection.item;

                }
                if (this.$route.params.id == 'raw-products') {

                    template = selection.product;
                }

                if (this.$route.params.id == 'product-suppliers') {

                    template = selection.product;
                }

                this.selectProductTypeModal.hide();

                let root = "/admin/views"
                if (this.config?.options?.url != undefined) {
                    root = this.config?.options?.url
                }

                let url = root + "/" + this.$route.params.id + "/new?template=" + template
                //this.$router.push(url)
                window.location = url

            },

            OnSelectProductSelected: function (item) {
                let template = "";

                //TODO


                if (this.$route.params.id == 'products') {
                    if (item == "yoghurt") template = "product-yoghurt";
                    if (item == "cheese") template = "product-cheese";
                    if (item == "milk") template = "product-milk";
                    if (item == "cream") template = "product-cream";
                    if (item == "wine") template = "product-wine";
                    if (item == "olive-oil") template = "product-olive-oil";
                    if (item == "alcoholic-beverages") template = "product-alcoholic-beverage";
                    if (item == "none-alcoholic-beverages") template = "product-none-alcoholic-beverages";
                    template = item.category.replace("-raw", "")
                }
                if (this.$route.params.id == 'raw-products') {
                    if (item == "yoghurt") template = "product-yoghurt-raw";
                    if (item == "cheese") template = "product-cheese-raw";
                    if (item == "milk") template = "product-milk-raw";
                    if (item == "cream") template = "product-cream-raw";
                    if (item == "wine") template = "product-wine";
                    if (item == "olive-oil") template = "product-olive-oil";
                    if (item == "alcoholic-beverages") template = "product-alcoholic-beverage";
                    if (item == "alcoholic-none-beverages") template = "product-none-alcoholic-beverage";
                }

                this.selectProductModal.hide();

                let root = "/admin/views"
                if (this.config.options.url != undefined) {
                    root = this.config.options.url
                }
                let url = root + "/" + this.$route.params.id + "/new?template=" + template
                this.$router.push(url)
                //window.location = url
            },
            OnSelectProductClose: function () {
                this.selectProductModal.hide();
            },
            OnSelectProductTypeClose: function () {
                this.selectProductTypeModal.hide();
            },

            onRefreshTab: function (item) {

                if (item?.filter?.category != undefined)
                    this.$router.replace({ path: this.$route.path, query: { tab: item.filter.category } })

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 2);
            },
            onRefreshClick: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 2);
            },
            onRefreshPage: function (item) {

                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 1);
            },

            onRefreshTable: function (item) {

                let filter = item.filter;
                this.reloadPage(this.$route.params.id, item.offset, item.limit, filter, 3);
            },

            onAddClick: function () {

                if (this.$route.params.id == "batches") {
                    let root = "/admin/wizard"
                    let url = root + "/" + this.$route.params.id + "/new"
                    this.$router.push(url)

                    return;
                }
                //TODO
                let template = null;

                if (this.$route.params.id == 'product-suppliers') {


                    if (this.config.tabs != undefined) {

                        template = this.config.tabs[this.currentTab].id

                    }


                }



                if (this.$route.params.id == 'products') {


                    this.selectProductModal.show();
                    return;
                }

                if (this.$route.params.id == 'raw-products') {
                    this.selectProductTypeModal.show();
                    return;
                }
                let root = "/admin/views"
                if (this.config.options.url != undefined) {
                    root = this.config.options.url
                }

                let url = root + "/" + this.$route.params.id + "/new"

                //TODO Should be more precize for Ingredients

                if (template != undefined)
                    url = url + "?template=product-" + template;
                this.$router.push(url)
                //window.location = url
            },
            onRowClick: function (item) {

                if (this.config)
                    if (this.config.options)
                        if (this.config.options.rowClick == false) {
                            return;
                        }

                let root = "/admin/views"
                if (this.config.options.url != undefined) {
                    root = this.config.options.url
                }

                let url = root + "/" + this.$route.params.id + "/" + item.record
                this.$router.push(url)
                // window.location = url


            }
        }
    }
</script>
<template>
  <section class="flex items-center bg-gray-50 dark:bg-gray-900">
    <div class="w-full     mx-auto  ">
      <!-- Start coding here -->
      <div class="relative overflow-hidden bg-white  sdark:bg-gray-800 sm:rounded-lg">
        <div class="px-4 divide-y dark:divide-gray-700">
          <div class="flex items-center justify-between py-3">
            <div class="flex items-center flex-1 space-x-2">
              <h5 class="font-semibold dark:text-white text-3xl">
                {{ labels['LABEL_TITLE'][lg] }}
              </h5>
            </div>


            <h5 class="font-semibold dark:text-white mr-2">
              {{ labels['LABEL_LEVEL'][lg] }}
            </h5>
                      
            <div class="md:mr-4 min-w-[10rem]">
              <select
                id="compare"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option value="last_year">
                  {{ labels['LABEL_LAST_YEAR'][lg] }}
                </option>
                <option value="last_6_month">
                  {{ labels['LABEL_LAST_SIX_MONTHS'][lg] }}
                </option>
                <option value="last_month">
                  {{ labels['LABEL_LAST_MONTH'][lg] }}
                </option>
                <option
                  selected
                  value="yesterday"
                >
                  {{ labels['LABEL_LAST_WEEK'][lg] }}
                </option>
              </select>
            </div>



            <h5 class="font-semibold dark:text-white mr-2">
              {{ labels['LABEL_DURATION'][lg] }}
            </h5>
                      
            <div class="md:mr-4 min-w-[10rem]">
              <select
                id="compare"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option value="last_year">
                  {{ labels['LABEL_LAST_YEAR'][lg] }}
                </option>
                <option value="last_6_month">
                  {{ labels['LABEL_LAST_SIX_MONTHS'][lg] }}
                </option>
                <option value="last_month">
                  {{ labels['LABEL_LAST_MONTH'][lg] }}
                </option>
                <option
                  selected
                  value="yesterday"
                >
                  {{ labels['LABEL_LAST_WEEK'][lg] }}
                </option>
              </select>
            </div>
                        
            <div class="flex items-center space-x-2 md:space-x-4">
              <button
                type="button"
                class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                  />
                </svg>
                {{ labels['BUTTON_EXPORT'][lg] }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import { mapGetters } from "vuex";

    import labels from "@/assets/lg/maps.json"

    export default {
        props:
        {
            title: String,
            subTitle: String
        },
        data()
        {
            return ({
                labels: labels,
                lg : "EN"
            })
        },
        computed:
        {
            ...mapGetters({ user: "StateUser" }),
            mapRef: function (a) {
                if (a == undefined) return null;
                return a;
            }


        },
      
        mounted: async function () {
          

            if (this.user == undefined) {
                //this.$router.push("/login");
                window.location = "/login"
                return;
            }

            this.lg = this.user.language;
        }
    }


</script>
<template>
  <div class="bg-white w-full p-6">
    <h1 class="hidden text-left mb-3">
      Titre des colonnes +++
    </h1>


    <div class="w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Couleur du fond </label>

      <ul class="grid w-12/12 gap-3 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 w-full ">
        <li>
          <input
            id="background-color-no"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="-"
            class="hidden peer"
            required=""
          >
          <label
            for="background-color-no"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="w-6 h-6 fa-solid fa-ban" />
          </label>
        </li>

        <li>
          <input
            id="background-color-white"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="white"
            class="hidden peer"
            required=""
          >
          <label
            for="background-color-white"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-white border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-100"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-100"
            class="hidden peer"
          >
          <label
            for="background-color-gray-50"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-50 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-100"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-100"
            class="hidden peer"
          >
          <label
            for="background-color-gray-100"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-100 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-200"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-200"
            class="hidden peer"
          >
          <label
            for="background-color-gray-200"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-200 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-300"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-300"
            class="hidden peer"
          >
          <label
            for="background-color-gray-300"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-300 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-400"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-400"
            class="hidden peer"
          >
          <label
            for="background-color-gray-400"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-400 focus:border-gray-100 border border" />
          </label>
        </li>



        <li>
          <input
            id="background-color-gray-600"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-600"
            class="hidden peer"
          >
          <label
            for="background-color-gray-600"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-600 focus:border-gray-100 border" />
          </label>
        </li>

        <li>
          <input
            id="background-color-gray-800"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="gray-800"
            class="hidden peer"
          >
          <label
            for="background-color-gray-800"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-800 focus:border-gray-100 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-black"
            v-model="backgroundHeader"
            type="radio"
            name="backgroundColor"
            value="black"
            class="hidden peer"
          >
          <label
            for="background-color-black"
            class="inline-flex items-center   w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-black focus:border-gray-100 border" />
          </label>
        </li>
      </ul>
    </div>

    <div class="w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Alignement {{ alignementHeader }}</label>
      <ul class="grid w-12/12  gap-6 md:grid-cols-8">
        <li>
          <input 
            id="aligment-header-no" 
            v-model="alignementHeader"
            type="radio"
            name="header-hosting"
            value="-"
            class="hidden peer"
            required=""
            @change="onClickElement"
          >
          <label
            for="aligment-header-no"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-ban" />
          </label>
        </li>
        <li>
          <input
            id="aligment-header-left"
            v-model="alignementHeader"
            type="radio"
            name="header-hosting"
            value="left"
            class="hidden peer"
            required=""
          >
          <label
            for="aligment-header-left"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-left" />
          </label>
        </li>
        <li>
          <input
            id="aligment-header-center"
            v-model="alignementHeader"
            t
            type="radio"
            name="header-hosting"
            value="center"
            class="hidden peer"
          >
          <label
            for="aligment-header-center"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="fa-solid fa-align-center" />
          </label>
        </li>
        <li>
          <input
            id="aligment-header-right"
            v-model="alignementHeader"
            t
            type="radio"
            name="header-hosting"
            value="right"
            class="hidden peer"
          >
          <label
            for="aligment-header-right"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-right" />
          </label>
        </li>
        <li>
          <input
            id="aligment-header-right"
            v-model="alignementHeader"
            t
            type="radio"
            name="header-hosting"
            value="right"
            class="hidden peer"
          >
          <label
            for="aligment-header-right"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-justified" />
          </label>
        </li>
      </ul>
    </div>

    <h1 class="hidden text-left mb-3">
      Cellules
    </h1>


    <div class="hidden w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Couleur du fond </label>

      <ul class="grid w-12/12 gap-6 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
        <li>
          <input
            id="background-color-no"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="-"
            class="hidden peer"
            required=""
          >
          <label
            for="background-color-no"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="fa-solid fa-ban" />
          </label>
        </li>

        <li>
          <input
            id="background-color-white"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="white"
            class="hidden peer"
            required=""
          >
          <label
            for="background-color-white"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-white border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-100"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="gray-100"
            class="hidden peer"
          >
          <label
            for="background-color-gray-100"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-100 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-200"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="gray-200"
            class="hidden peer"
          >
          <label
            for="background-color-gray-200"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-200 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-300"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="gray-300"
            class="hidden peer"
          >
          <label
            for="background-color-gray-300"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-300 border-gray-900 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-gray-400"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="gray-400"
            class="hidden peer"
          >
          <label
            for="background-color-gray-400"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-400 focus:border-gray-100 border border" />
          </label>
        </li>



        <li>
          <input
            id="background-color-gray-600"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="gray-600"
            class="hidden peer"
          >
          <label
            for="background-color-gray-600"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-600 focus:border-gray-100 border" />
          </label>
        </li>

        <li>
          <input
            id="background-color-gray-800"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="gray-800"
            class="hidden peer"
          >
          <label
            for="background-color-gray-800"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-gray-800 focus:border-gray-100 border" />
          </label>
        </li>
        <li>
          <input
            id="background-color-black"
            v-model="backgroundCell"
            type="radio"
            name="backgroundColor"
            value="black"
            class="hidden peer"
          >
          <label
            for="background-color-black"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <div class="w-6 h-6 bg-black focus:border-gray-100 border" />
          </label>
        </li>
      </ul>
    </div>

    <div class="hidden w-full mb-6 flex flex-col items-start justify-start">
      <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        Alignement {{ alignementCell }}</label>
      <ul class="grid w-12/12  gap-6 md:grid-cols-8">
        <li>
          <input
            id="aligment-no"
            v-model="alignementCell"
            type="radio"
            name="hosting"
            value="-"
            class="hidden peer"
            required=""
            @change="onClickElement"
          >
          <label
            for="aligment-no"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-ban" />
          </label>
        </li>
        <li>
          <input
            id="aligment-left"
            v-model="alignementCell"
            type="radio"
            name="hosting"
            value="left"
            class="hidden peer"
            required=""
          >
          <label
            for="aligment-left"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-left" />
          </label>
        </li>
        <li>
          <input
            id="aligment-center"
            v-model="alignementCell"
            t
            type="radio"
            name="hosting"
            value="center"
            class="hidden peer"
          >
          <label
            for="aligment-center"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
          >
            <i class="fa-solid fa-align-center" />
          </label>
        </li>
        <li>
          <input
            id="aligment-right"
            v-model="alignementCell"
            t
            type="radio"
            name="hosting"
            value="right"
            class="hidden peer"
          >
          <label
            for="aligment-right"
            class="inline-flex items-center   w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-sm text-center justify-center"
            @click="onClickElement"
          >
            <i class="fa-solid fa-align-right" />
          </label>
        </li>
      </ul>
    </div>

    <div class="bg-orange-300">
      =style= {{ currentItem.style }} ==
    </div>
    <div class="bg-orange-300">
      =styleh= {{ currentItem.styleh }} ==
    </div>

    <div class="bg-green-300">
      =alignementCell= {{ alignementCell }} =alignement=
    </div>
    <div class="bg-green-300">
      =backgroundCell= {{ backgroundCell }} =background=
    </div>
    <div class="bg-green-300">
      =alignementHeader= {{ alignementHeader }} =alignement=
    </div>
    <div class="bg-green-300">
      =backgroundHeader= {{ backgroundHeader }} =background=
    </div>
  </div>
</template>

<script>

    export default {
        name: 'DeveloperStyle',
        props: {
            item: Object
        },
        data() {
            return ({
                currentItem: {},

                alignementCell: '-',
                backgroundCell: "-",
                alignementHeader: '-',
                backgroundHeader: "-"
            })
        },
        watch: {
            alignementCell: function () {
          
                this.onClickElement();
            },
            alignementHeader: function () {
          
                this.onClickElement();
            },
            backgroundCell: function () {
             
                this.onClickElement();
            },
            backgroundHeader: function () {
           
                this.onClickElement();
            },
            item: function (val) {
       
                let item = val;


                this.currentItem = item;
                this.setValues();
            }
        },

        mounted() {
 
            let item = this.item;

            this.currentItem = item;
            this.setValues();

        },

        methods: {
            setValues: function () {
          
                if (this.currentItem == undefined) {
               
                    return;
                }
                if (this.currentItem.style == undefined) {
                    this.currentItem.style = ""
                }
                if (this.currentItem.styleh == undefined) {
                    this.currentItem.styleh = ""
                }
                this.alignementCell = '-';
                this.backgroundCell = '-';



                this.alignementHeader = '-';
                this.backgroundHeader = '-';
                let items = this.currentItem.style.split(" ");
            
                for (let i = 0; i < items.length; i++) {

                    let item = items[i];
         
                    switch (item.trim()) {

                        case 'justify-start': this.alignementCell = 'left'; break;
                        case 'justify-center': this.alignementCell = 'center'; break;
                        case 'justify-end': this.alignementCell = 'right'; break;

                        case 'text-left': this.alignementCell = 'left'; break;
                        case 'text-center': this.alignementCell = 'center'; break;
                        case 'text-right': this.alignementCell = 'right'; break;


                        case 'bg-black': this.backgroundCell = 'black'; break;
                        case 'bg-white': this.backgroundCell = 'white'; break;
                        case 'bg-gray-100': this.backgroundCell = 'gray-100'; break;
                        case 'bg-gray-200': this.backgroundCell = 'gray-200'; break;
                        case 'bg-gray-300': this.backgroundCell = 'gray-300'; break;
                        case 'bg-gray-400': this.backgroundCell = 'gray-400'; break;
                        case 'bg-gray-500': this.backgroundCell = 'gray-500'; break;
                        case 'bg-gray-600': this.backgroundCell = 'gray-600'; break;
                        case 'bg-gray-700': this.backgroundCell = 'gray-700'; break;
                        case 'bg-gray-800': this.backgroundCell = 'gray-800'; break;
                        case 'bg-gray-900': this.backgroundCell = 'gray-900'; break;
                    }
                }
         


                let itemsH = this.currentItem.styleh.split(" ");
                for (let i = 0; i < itemsH.length; i++) {
                    let item = itemsH[i];
                  
                    switch (item) {

                        case 'justify-start': this.alignementHeader = 'left'; break;
                        case 'justify-center': this.alignementHeader = 'center'; break;
                        case 'justify-end': this.alignementHeader = 'right'; break;

                        case 'text-left': this.alignementHeader = 'left'; break;
                        case 'text-center': this.alignementHeader = 'center'; break;
                        case 'text-right': this.alignementHeader = 'right'; break;

                        case 'bg-black': this.backgroundHeader = 'black'; break;
                        case 'bg-white': this.backgroundHeader = 'white'; break;
                        case 'bg-gray-100': this.backgroundHeader = 'gray-100'; break;
                        case 'bg-gray-200': this.backgroundHeader = 'gray-200'; break;
                        case 'bg-gray-300': this.backgroundHeader = 'gray-300'; break;
                        case 'bg-gray-400': this.backgroundHeader = 'gray-400'; break;
                        case 'bg-gray-500': this.backgroundHeader = 'gray-500'; break;
                        case 'bg-gray-600': this.backgroundHeader = 'gray-600'; break;
                        case 'bg-gray-700': this.backgroundHeader = 'gray-700'; break;
                        case 'bg-gray-800': this.backgroundHeader = 'gray-800'; break;
                        case 'bg-gray-900': this.backgroundHeader = 'gray-900'; break;
                    }
                }


            },

            buildStyle: function (background, alignement) {
                let cls = "";


                switch (background) {
                    case 'black': cls += 'bg-black '; break;
                    case 'white': cls += 'bg-white '; break;
                    case 'gray-100': cls += 'bg-gray-100 '; break;
                    case 'gray-200': cls += 'bg-gray-200 '; break;
                    case 'gray-300': cls += 'bg-gray-300 '; break;
                    case 'gray-400': cls += 'bg-gray-400 '; break;
                    case 'gray-500': cls += 'bg-gray-500 '; break;
                    case 'gray-600': cls += 'bg-gray-600 '; break;
                    case 'gray-700': cls += 'bg-gray-700 '; break;
                    case 'gray-800': cls += 'bg-gray-800 '; break;
                    case 'gray-900': cls += 'bg-gray-900 '; break;
                }

                switch (alignement) {
                    case 'left': cls += 'justify-start '; break;
                    case 'center': cls += 'justify-center '; break;
                    case 'right': cls += 'justify-end '; break;
                }

                return cls
            },
            onClickElement: function () {
           
                let styleCell = this.buildStyle(this.backgroundCell, this.alignementCell);
                this.currentItem.style = styleCell;

                let styleHeader = this.buildStyle(this.backgroundHeader, this.alignementHeader);
                this.currentItem.styleh = styleHeader;

                this.$emit("update", this.currentItem)
            }
        }

    }
</script>
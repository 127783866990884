<template>
  <div class="w-full mt-12 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start  ">
    <div id="myFormTabContent">
      <div
        id="tab-properties-content"
        class="  p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="tab-properties-tab"
      >
        <div
          v-if="currentItem != undefined"
          class="mb-6 flex flex-col items-start justify-start w-full"
        >
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Texte a afficher </label>
          <div class="flex flex-row justify-start items-center w-96">
            <textarea
              v-model="data"
              type="text"
              class="h-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


    export default {
        name: 'DeveloperCellBadge',
        components: {

        },
        props: {
            item: Object
        },

        data() {
            return ({
                lg: "FR",
                currentItem: {},
                data: ""
            })
        },
        computed: {

        },
        watch: {
            item: function () {
                this.data = this.item.data
                this.currentItem = this.item;
            }
        },
        mounted() {
            this.currentItem =this.item;
            this.data = this.item.data
        },

        methods: {

        }

    }
</script>
<template>
  <div class="w-full">
    ContinuousLineProperties
  </div>
</template>


<script>
    export default {
        name: 'ContinuousLineProperties',
        props: {
          
        },
        data() {
            return {

                
            }
        },

        computed:
        {

        },
        methods:
        {
           
        },
    }
</script>
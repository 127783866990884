<template>
  <div class="w-full  flex flex-col  " />
</template>

<script>



  import service from "@/services/FullService.vue"


  import { mapGetters } from "vuex";


  export default {
    name: 'DashboardViewMain',

    data: function () {
      return {
        mode: "",
        lg: "FR",
        tenant: {}

      };
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },

    async mounted() {
      this.tenant = await service.getData("dashboards", this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, {}))
      console.log(this.tenant?.records)

      if (this.tenant?.records)
        this.$router.push("/admin/dashboard/" + this.tenant?.records[0]?._id)

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
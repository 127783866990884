<!-- vue/script-setup-uses-vars -->
<template>
  <GoogleMap
    id="map"
    ref="mapRef"
    class="h-screen"
    :api-key="map.key"
    :libraries="map.libraries"
    :language="map.language"
    :region="map.region"
    :center="map.center"
    :zoom="map.zoom"
    :min-zoom="map.minZoom"
    :max-zoom="map.maxZoom"
    :street-view-control="map.streetViewControl"
    @zoom_changed="zoomChanged"
    @center_changed="centerChanged"
  >
    <HeatmapLayer :options="{ data: heatmapData, radius:50 }" />
  </GoogleMap>
</template>
<!--  -->

<script setup>
	//defineProps,
	import { ref, watch } from 'vue';
 
	/* eslint-disable */
	const props = defineProps({
		heatmapData: String,
	});


	// eslint-disable-next-line
	import { GoogleMap, HeatmapLayer } from 'vue3-google-map';

	// eslint-disable-next-line
	const map = {
		key: 'AIzaSyCyvxVv-VQiwvkQWAx09B3Z0N7yL2HGaXI', // Place a Google API Key here
		libraries: ['visualization'],
		language: 'fr-FR',
		region: 'FR',
		center: {
			lat: 47.8232,
			lng: 2.278
		},
		radius: 30,
		zoom: 6,
		minZoom: 2,
		maxZoom: null,
		streetViewControl: false
	};
	// eslint-disable-next-line


	let gmap = null;

	const mapRef = ref(null);



	watch(() => mapRef.value?.ready, ready => {
		if (ready) {
			gmap = mapRef.value.map;

		}
	});
	// eslint-disable-next-line
	function zoomChanged() {

	}

	// eslint-disable-next-line
	function centerChanged() {
		const center = gmap.getCenter();

	}
</script>
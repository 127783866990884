<template>
  <div class="  h-16 cursor-pointer list-group mt-2  w-full flex items-start    flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm  text-gray-900 font-bold "
        >Titre
        </label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  px-2.5  py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-4 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm   text-gray-900 dark:text-white font-bold"
        >Description</label>
        <input
          :id="`att-input-description-${properties?.id}`"
          v-model="description"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-2.5  py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeDescription"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-1 text-sm   text-gray-900 dark:text-white font-bold"
        >Attribute</label>
        <input
          :id="`att-input-data-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-2.5  py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>
      <div class="   mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >Type de
          Code</label>
        <div class="w-full flex flex-row">
          <div
            class="inline-flex rounded-md shadow-sm w-full"
            role="group"
          >
            <button
              type="button"
              :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (mode =='both') , 'text-gray-700 bg-gray-200 font-medium' : (mode !='both') , }"
              class="w-4/12 px-2 py-2 text-xs border border-gray-300 rounded-s-lg hover:bg-gray-50 hover:text-gray-900"
              @click="onClickMode('both')"
            >
              tous types
            </button>
            <button
              type="button"
              :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (mode =='barcode') , 'text-gray-700 bg-gray-200 font-medium' : (mode !='barcode') , }"
              class="w-4/12 px-2 py-2 text-xs font-medium   border-t border-b border-gray-300 hover:bg-gray-50 hover:text-gray-900"
              @click="onClickMode('barcode')"
            >
              Barcode
            </button>
            <button
              type="button"
              :class="{'bg-gray-50 text-gray-900 font-semibold ring-gray-300 ring-2 z-10' : (mode =='qrcode') , 'text-gray-700 bg-gray-200 font-medium' : (mode !='qrcode') , }"
              class="w-4/12 px-2 py-2 text-xs font-medium   border border-gray-300 rounded-e-lg hover:bg-gray-50 hover:text-gray-900"
              @click="onClickMode('qrcode')"
            >
              QR code
            </button>
          </div>
        </div>

        <div class="flex items-center me-4 mt-2">
          <input
            id="inline-2-checkbox"
            v-model="isReadOnly"
            type="checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @change="onChangeIsReadOnly"
          >
          <label
            for="inline-2-checkbox"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >Interdire la saisie manuelle
            du code</label>
        </div>
      </div>





      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre
              s</label>
          </div>
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"  
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              description</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'CodeTableFormComponentDetails',
        props: {
            properties: Object
        },
        data() {

            return {
                title: "",
                description: "",
                format: "",
                mode: "barcopde",
                data: "",
                dataphoto: "",
                isPhotoSaved: false,
                isReadOnly: true,

                isTitleDisplayed: false

            }
        },
        watch:
        {
            properties: function () {
                this.title = this.properties?.title;
                this.format = this.properties?.format;
                this.isPhotoSaved = this.properties?.isPhotoSaved;
                this.mode = this.properties?.mode

                if (this.mode == undefined) this.mode = "barcode"


                this.isReadOnly = this.properties?.isReadOnly;
                this.isTitleDisplayed = this.properties?.isTitleDisplayed;
                this.data = this.properties?.data;
                this.dataphoto = this.properties?.dataphoto;
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.format = this.properties?.format

            this.isPhotoSaved = this.properties?.isPhotoSaved;

            this.mode = this.properties?.mode
            if (this.mode == undefined) this.mode = "barcode"
            this.isReadOnly = this.properties?.isReadOnly;
            this.isTitleDisplayed = this.properties?.isTitleDisplayed;
            this.data = this.properties?.data;
            this.dataphoto = this.properties?.dataphoto;
        },
        methods:
        {
         
            onChangeData: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'data', value: this.data })
            },




            onChangeIsReadOnly: function () {
                this.$emit("updateField", {id: this.properties?.id, tab: "properties", att: 'isReadOnly', value: this.isReadOnly })
            },
            onChangeDataPhoto: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'dataphoto', value: this.dataphoto })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", {id: this.properties?.id, tab: "properties", att: 'isTitleDisplayed', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", {id: this.properties?.id, tab: "properties", att: 'isDescriptionDisplayed', value: this.isTitleDisplayed })
            },



            onChangeIsPhotoSaved: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'isPhotoSaved', value: this.isPhotoSaved })
            },


            onClickMode: function (mode) {
                this.mode = mode
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'mode', value: this.mode })


            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'description', value: this.description })
            }
        }
    }
</script>
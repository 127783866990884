<template>
    <div class="mt-2 mb-2 w-full relative">
        <label for="default" class="text-left block mb-1 text-sm font-semibold text-gray-900 dark:text-white">
            {{ label }}
        </label>

        <div class="z-10   w-48 bg-white  dark:bg-gray-700 w-full">
            <ul class="p-3   text-sm text-gray-700 dark:text-gray-200 flex flex-row items-center"
                aria-labelledby="dropdownBgHoverButtonxxx">
                <li v-for=" choice in output" :key="choice.id" class="flex items-center ">
                    <div
                        class="  flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer">
                        <input :id="`checkbox-item-${choice.id}`" v-model="choice.value" type="checkbox"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label :for="`checkbox-item-${choice._id}`"
                            class="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                            {{ formatOption(choice) }}
                        </label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'ContentSelectCheck',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: String,
            collection: String,
            datasource: String,
            mode: String,
            filters: Object,
            config: Object
        },
        async mounted() {
            {


                let filters = {};

                if (this.filters != undefined) {
                    if (typeof this.filters == 'object') filters = this.filters
                }

                filters.limit = 100;
                filters.offset = 0;


                filters = {
                    ...filters, ...this.setFilter(this.config?.filter)
                }


                {

                    let datasource = this.datasource;
                    if (datasource == undefined) { return; }
                    if ((datasource != undefined) && (datasource != "")) {
                        let choices = await service.getData("data-sources/" + datasource + "/records", this.user?.token, filters)
                        if (choices?.records == undefined) this.choices = []
                        else this.choices = choices?.records
                    }
                    else {
                        this.choices = []
                    }



                }

                if (this.choices != undefined) {


                    if (this.value == undefined) {
                        this.output = []
                        for (let i = 0; i < this.choices.length; i++) {
                            this.output.push({ id: this.choices[i]._id, name: this.choices[i].name, value: false });
                        }
                    }
                    else {
                        this.output = []
                        for (let i = 0; i < this.choices.length; i++) {
                            this.output.push({ id: this.choices[i]._id, name: this.choices[i].name, value: this.value.includes(this.choices[i]._id) });
                        }
                    }
                }
            }







        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {
            setFilter(filter) {
                let newFilter = {}
                if (filter == undefined) return filter;

                if (typeof filter != "string") return filter
                let rules = filter.split("&")

                for (let i = 0; i < rules.length; i++) {
                    let rule = rules[i];

                    let tags = rule.split("=")

                    if (tags.length == 2) {

                        newFilter[tags[0]] = tags[1]

                    }
                }

                return newFilter
            },
            onChange() {
                let response = []
                for (let i = 0; i < this.output.length; i++) {
                    if (this.output[i].value) response.push(this.output[i].id)
                }
                this.$emit("valueChanged", { id: this.config.data, value: response })
            },
           

            getData() {
                let response = []
                let count = 0
                for (let i = 0; i < this.output.length; i++) {
                    if (this.output[i].value){
                        response.push(this.output[i].id)
                        count++
                    } 
                }

                console.log(">>>>>>>>>>>>>ContentSelectCheck", {data: response , isError: (count == 0)})
                return {data: response , isError: (count ==0)};
            },

            formatOption(choice) {
                // if ( (this.config.format != undefined) && (this.config.format != "")) {
                //    return choice.name + " / FT-" + choice.reference
                // }
                let name = choice.name
                if (typeof choice.name == "object")
                    name = choice.name[this.lg]
                return name
            }
        },
        data() {
            return {
                checkedNames: [],
                output: [],
                att: "_id",
                choices: [],
                lg: "FR"
            }
        },
        watch: {
            config: function () {
                if (this.config?.attribut == undefined) this.att = "_id"
                else this.att = this.config?.attribut
            },
            value: function () {
                //Load Data?





                if (this.value == undefined) {
                    this.output = []
                    for (let i = 0; i < this.choices.length; i++) {
                        this.output.push({ id: this.choices[i]._id, name: this.choices[i].name, value: false });
                    }
                }
                else {
                    this.output = []
                    for (let i = 0; i < this.choices.length; i++) {
                        this.output.push({ id: this.choices[i]._id, name: this.choices[i].name, value: this.value.includes(this.choices[i]._id) });
                    }
                }



            },
            datasource: async function () {
                let datasource = this.datasource
                let filters = {}
                filters = { ...filters, ...this.setFilter(this.config?.filter) }

                if ((datasource != undefined) && (datasource != "")) {
                    let choices = await service.getData("data-sources/" + datasource + "/records", this.user?.token, filters)
                    if (choices?.records == undefined) this.choices = []
                    else this.choices = choices?.records
                }
                else {
                    this.choices = []
                }



                if (this.value == undefined) {
                    for (let i = 0; i < this.choices.length; i++) {
                        this.output.push(this.choice[i]._id);
                    }

                }
                else this.output = this.value;


            }
        }
    }
</script>
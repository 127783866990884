<template>
  <div class="  w-full relative mt-4">
    
    
    <div :id="`accordion-${id}`">
      <div class="w-full" v-for="(section,index) in output" :key="section.id">
        <h2 :id="`accordion-heading-${section.id}`">
          <button type="button" :class="{
            'flex items-center justify-between w-full p-5 rounded-t-xl font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200   focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3' : (index==0),
            
            'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200   focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3' : ((index !=0) && (index !=(output.length-1))),
      
      
            'flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200  focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3': (index ==(output.length-1)) 
       
          }" aria-expanded="true" aria-controls="accordion-example-body-1">
            <span class="flex flex-row items-center w-full justify-items-stretch">
              <div class="mr-1 w-10  ">
                <i v-if="section.checked" class="fa-solid fa-check text-green-500"></i>
                <i v-if="!section.checked" class="fa-solid fa-xmark text-gray-500"></i>
              </div>
              <div class="font-semibold w-full flex text-gray-600">{{section.name}}</div>
              <div class="justify-self-end flex flex-row items-center bg-green-100 mx-6">
                <div class="text-xs font-semibold mr-2">45%</div>
                <div class=" justify-end w-48 bg-gray-200 rounded-full h-2.5  dark:bg-gray-700">

                  <div class="bg-gray-600 h-2.5 rounded-full dark:bg-gray-300" style="width: 100%"></div>
                </div>
              </div>

            </span>

            <svg data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </h2>
        <div :id="`accordion-body-${section.id}`" class="hidden" :aria-labelledby="`accordion-heading-${section.id}`">
          <div
            class="grid grid-cols-4 px-10 py-5 border border-b-0 border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900 focus:ring-4 focus:ring-gray-200">

            <div class="flex flex-row items-center justify-start" v-for="item in section.items" :key="item.id">
              <div>
                <i v-if="item.checked" class="fa-solid fa-check text-green-500 mr-1"></i>
                <i v-if="!item.checked" class="fa-solid fa-xmark text-gray-500 mr-1"></i>
              </div>
              <div class="font-semibold text-gray-600 py-2">
                {{item.name}}
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  //import service from "@/services/FullService.vue";
  import { Accordion } from 'flowbite';
  export default {
    name: 'ContentTitle',
    props: {
      id: String,
      label: String,
      placeholder: String,
      required: Boolean,
      value: String,
      multilangual: Boolean,
      config: Object

    },
    async mounted() {





      // create an array of objects with the id, trigger element (eg. button), and the content element
      /*  const accordionItems = [
      {
          id: 'accordion-example-heading-1',
          triggerEl: document.querySelector('#accordion-example-heading-1'),
          targetEl: document.querySelector('#accordion-example-body-1'),
          active: true
        },
        {
          id: 'accordion-example-heading-2',
          triggerEl: document.querySelector('#accordion-example-heading-2'),
          targetEl: document.querySelector('#accordion-example-body-2'),
          active: false
        },
        {
          id: 'accordion-example-heading-3',
          triggerEl: document.querySelector('#accordion-example-heading-3'),
          targetEl: document.querySelector('#accordion-example-body-3'),
          active: false
        }
      ];*/


      if (this.value != undefined)
        this.output = this.value;

      this.activateWidget()
    },

    methods: {



      activateWidget() {

        if (this.output.length == 0) return
        const accordionElement = document.getElementById('accordion-' + this.id);

        console.log("Widget", 'accordion-' + this.id)
        console.log(accordionElement)
        if (accordionElement == undefined) return;
        const accordionItems = [];

        let bError = false;

        for (let i = 0; i < this.output.length; i++) {

          let y = document.querySelector('#accordion-heading-' + this.output[i].id);
          let z = document.querySelector('#accordion-body-' + this.output[i].id)

          if (y == undefined)  bError = true
          if (z == undefined)  bError = true
          
          accordionItems.push({
            id: 'accordion-heading-' + this.output[i].id,
            triggerEl: document.querySelector('#accordion-heading-' + this.output[i].id),
            targetEl: document.querySelector('#accordion-body-' + this.output[i].id),
            active: (i == 0)
          })
        }

        if(bError) return;
        console.log(JSON.stringify(accordionItems))
        // options with default values
        const options = {
          alwaysOpen: true,
          activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
          inactiveClasses: 'text-gray-500 dark:text-gray-400',
          onOpen: (item) => {
            console.log('accordion item has been shown');
            console.log(item);
          },
          onClose: (item) => {
            console.log('accordion item has been hidden');
            console.log(item);
          },
          onToggle: (item) => {
            console.log('accordion item has been toggled');
            console.log(item);
          },
        };

        // instance options object
        const instanceOptions = {
          id: 'accordion-' + this.id,
          override: true
        };


        this.accordion = new Accordion(accordionElement, accordionItems, options, instanceOptions);

      },

      getData() {
        return null;
      }
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    components: {},
    data() {
      return {
        output: [],
        choices: [],
        lg: "FR",
        accordion: null




      }
    },
    watch: {
      value: function (val) {
        this.output = val;




      }
    }
  }
</script>
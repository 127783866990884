<template>
  <div
    class="  relative h-20 cursor-grab list-group mt-2  pt-1 w-full flex items-start bg-gray-700 border   shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
    :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
    <div class="flex flex-col items-center w-full  pt-3 pl-2 bg-gray-700 ">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full h-6 text-xs text-left text-white"
      >
        {{ element.title }}
      </div>
      <div
        v-if="element.isDescriptionDisplayed"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ element.description }}  
      </div>
      <div class="w-full h-6   font-bold text-lg  text-white flex justify-center">
        <div class="  mb-6 w-full text-center flex justify-center">
          <input
            :id="`att-input-${element.id}`"
            v-model="data"
            type="text"
            class="w-48 py-3 bg-transparent border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            @input="onChangeData"
          >
          <div
            v-if="element.isDisplayScan"
            class="ml-2 h-6 w-6 bg-gray-400 rounded-sm"
          >
            <i class="fa-solid fa-barcode fa-xs" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
                data: ""
            }
        },
        computed:
        {
            displayDateTime: function () {
                if (this.element.format == "date") return "12/1/2023";
                if (this.element.format == "date-time") return "12/1/2023 2:44";
                if (this.element.format == "time") return "2:44";
                return ""
            }
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
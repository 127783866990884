<template>
  <div
    id="manage-field-edit-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-6xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Paramètre du champ {{ config?.type }} - {{ config?.id }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="closeDialog"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="px-4 md:px-5 flex flex-col">
          <form @submit.prevent="closeDialog">
            <div class="  mb-6 w-full text-left flex flex-row items-center">
              <div class="w-full flex flex-col min-h-96 max-h-96  overflow-y-auto   pb-6">
                <div
                  v-if="showTitle"
                  class="grid gap-4 mb-4 sm:grid-cols-2"
                >
                  <div>
                    <label
                      for="labelFR"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Titre
                      en Français</label>
                    <input
                      id="labelEN"
                      v-model="label.FR"
                      type="text"
                      name="labelFR"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=""
                    >
                  </div>
                  <div class=" ">
                    <label
                      for="labelEN"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Titre
                      en Anglais</label>
                    <input
                      id="labelEN"
                      v-model="label.EN"
                      type="text"
                      name="labelEN"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=""
                    >
                  </div>
                </div>
                <EditField
                  v-if="config?.type == 'text'"
                  :input="config"
                  @change-value="onChangeEditField"
                />
                <SelectField
                  v-if="config?.type == 'select'"
                  :input="config"
                  :values="selectValues"
                  @change-value="onChangeSelectField"
                />

                <TagField
                  v-if="config?.type == 'tag'"
                  :input="config"
                  :values="tagValues"
                  @change-value="onChangeTagField"
                />

                <DisplayField
                  v-if="config?.type == 'display'"
                  :input="config"
                  @change-value="onChangeDisplayField"
                />

                <SelectCollectionField
                  v-if="config?.type == 'selectCollection'"
                  :input="config"
                  :values="collectionValues"
                  @change-value="onChangeSelectCollectionField"
                />

                <div
                  v-if="showAttribute"
                  class="w-full"
                >
                  <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
                    Attribut
                  </h3>
                  <div class="grid gap-4 mb-4 sm:grid-cols-2 ">
                    <div>
                      <label
                        for="Nom"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >Name</label>
                      <input
                        id="att_name"
                        v-model="attribut"
                        type="text"
                        name="att_name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder=""
                      >
                    </div>
                    <div>
                      <label
                        for="Type"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Type
                      </label>

                      <div class="hidden">
                        <label
                          for="collection"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >collection</label>
                        <select
                          id="collection"
                          class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option value="string">
                            Texte
                          </option>
                          <option value="number">
                            Chiffre
                          </option>
                        </select>
                      </div>

                      <input
                        id="att_type"
                        type="text"
                        name="att_name"
                        value="String"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder=""
                      >
                    </div>
                  </div>
                </div>
 
                <div class="w-full">
                  <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
                    Largeur
                  </h3>
                  <ul
                    class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <li
                      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                    >
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-1"
                          v-model="layout"
                          type="radio"
                          value="1/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-1"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >1/12</label>
                      </div>
                    </li>
                    <li
                      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                    >
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-2"
                          v-model="layout"
                          type="radio"
                          value="w-2/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-2"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >2/12</label>
                      </div>
                    </li>
                    <li
                      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                    >
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-3"
                          v-model="layout"
                          type="radio"
                          value="w-3/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-3"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >3/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-4"
                          v-model="layout"
                          type="radio"
                          value="w-4/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-4"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >4/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-5"
                          v-model="layout"
                          type="radio"
                          value="w-5/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-5"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >5/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-6"
                          v-model="layout"
                          type="radio"
                          value="w-6/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-6"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >6/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-7"
                          v-model="layout"
                          type="radio"
                          value="w-7/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-7"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >7/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-8"
                          v-model="layout"
                          type="radio"
                          value="w-8/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-8"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >8/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-9"
                          v-model="layout"
                          type="radio"
                          value="w-9/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-9"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >9/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-10"
                          v-model="layout"
                          type="radio"
                          value="w-10/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-10"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >10/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-11"
                          v-model="layout"
                          type="radio"
                          value="w-11/12"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-11"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >11/12</label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center ps-3">
                        <input
                          id="layout-radio-12"
                          v-model="layout"
                          type="radio"
                          value="w-full"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="layout-radio-12"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >12/12</label>
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  v-if="showAlignment"
                  class="w-full"
                >
                  <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
                    Alignement
                  </h3>
                  <ul
                    class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <li
                      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                    >
                      <div class="flex items-center ps-3">
                        <input
                          id="alignment-radio-1"
                          v-model="alignment"
                          type="radio"
                          value="justify-start"
                          name="alignment-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="alignment-radio-1"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >Gauche</label>
                      </div>
                    </li>
                    <li
                      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                    >
                      <div class="flex items-center ps-3">
                        <input
                          id="alignment-radio-2"
                          v-model="alignment"
                          type="radio"
                          value="justify-center"
                          name="layout-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="alignment-radio-2"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >Centre</label>
                      </div>
                    </li>
                    <li
                      class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600"
                    >
                      <div class="flex items-center ps-3">
                        <input
                          id="alignment-radio-3"
                          v-model="alignment"
                          type="radio"
                          value="justify-end"
                          name="alignment-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        >
                        <label
                          for="alignment-radio-3"
                          class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >Droit</label>
                      </div>
                    </li>
                  </ul>
                </div>




                <ImageField
                  v-if="config?.type == 'image'"
                  @change="onChangeTagField"
                />



                <SectionField
                  v-if="config?.type == 'section'"
                  @change="onChangeSectionField"
                />
                <ContainerField
                  v-if="config?.type == 'zone'"
                  @change="onChangeContainerField"
                />


                <div>
                  <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
                    Thème
                  </h3>

                  <div class="w-full grid gap-4 mb-2 sm:grid-cols-2   ">
                    <div class="w-full flex flex-col">
                      <label
                        for="color-picker"
                        class="block mb-1 font-normal"
                      >Couleur du
                        texte {{ currentColor }}</label>
                      <div class="flex flex-row relative items-center  ">
                        <div
                          id="color-picker"
                          class=" w-48 border border-gray-200 p-1 rounded-lg bg-white flex   items-center justify-center"
                          @click="onInputClick"
                        >
                          <div
                            class="w-full h-8 rounded-sm"
                            :class="`bg-${currentColor}`"
                          />
                        </div>


                        <div
                          id="dropdown-input-color"
                          class="z-50 hidden bg-gray-50 divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 flex "
                        >
                          <div
                            class="flex bg-gray-50"
                            aria-labelledby="dropdown-input-color"
                          >
                            <div class="">
                              <div
                                class="bg-white cursor-pointer w-6 h-6 rounded-lg mx-1 my-1 border border-gray-400"
                                @click="selectColor('white','')"
                              />
                              <div
                                class="bg-black cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                                @click="selectColor('black','')"
                              />
                            </div>

                            <template
                              v-for="color in colors"
                              :key="color"
                            >
                              <div class="">
                                <template
                                  v-for="variant in variants"
                                  :key="variant"
                                >
                                  <div
                                    class="cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                                    :class="`bg-${color}-${variant}`"
                                    @click="selectColor(color,variant)"
                                  />
                                </template>
                              </div>
                            </template>
                          </div>
                          <div />
                        </div>
                      </div>
                    </div>

                    <div class="w-full flex flex-col relative">
                      <label
                        for="bg-color-picker"
                        class="block mb-1 font-normal"
                      >Couleur du
                        fond {{ currentBgColor }}</label>
                      <div class="flex flex-row relative items-center  ">
                        <div
                          id="bg-color-picker"
                          class=" w-48 border border-gray-200 p-1 rounded-lg bg-white flex   items-center justify-center"
                          @click="onInputBgClick"
                        >
                          <div
                            class="w-full h-8 rounded-sm"
                            :class="`bg-${currentBgColor}`"
                          />
                        </div>


                        <div
                          id="dropdown-input-bg-color"
                          class="z-50 hidden bg-white divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 flex "
                        >
                          <div
                            class="flex bg-gray-50"
                            aria-labelledby="dropdown-input-bg-color"
                          >
                            <div class="">
                              <div
                                class="bg-white cursor-pointer w-6 h-6 rounded-lg mx-1 my-1 border border-gray-400"
                                @click="selectBgColor('white','')"
                              />
                              <div
                                class="bg-black cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                                @click="selectBgColor('black','')"
                              />
                            </div>

                            <template
                              v-for="color in colors"
                              :key="color"
                            >
                              <div class="">
                                <template
                                  v-for="variant in variants"
                                  :key="variant"
                                >
                                  <div
                                    class="cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                                    :class="`bg-${color}-${variant}`"
                                    @click="selectBgColor(color,variant)"
                                  />
                                </template>
                              </div>
                            </template>
                          </div>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="flex items-center space-x-4 hidden">
                  <button
                    type="submit"
                    class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Update product
                  </button>
                  <button
                    type="button"
                    class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                  >
                    <svg
                      class="mr-1 -ml-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class=" rounded-b  flex flex-col  border-t border-gray-200">
          <div class="grid gap-4    grid-cols-2 w-full bg-gray-100 items-center px-4">
            <div class="flex flex-row justify-start items-center mb-4">
              <div
                v-if="(config?.id != undefined) && (config?.id != 'new')"
                class="mt-3 text-white inline-flex w-36 justify-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                @click="deleteField"
              >
                Supprimer
              </div>
            </div>
            <div class="flex flex-row justify-end items-center mb-4">
              <div
                class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                @click="closeDialog"
              >
                Annuler
              </div>
              <div
                class="mt-3 ml-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                @click="saveForm"
              >
                Valider
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

    import { Dropdown } from 'flowbite';
    import service from "@/services/FullService.vue"
    import labels from "@/assets/lg/product-types.json"
    import { mapGetters } from "vuex";

    import TagField from "@/components/modals/fields/fieldTag.vue"
    import SelectField from "@/components/modals/fields/fieldSelect.vue"
    import EditField from "@/components/modals/fields/fieldEdit.vue"
    // import BlankField from "@/components/modals/fields/fieldBlank.vue"

    import ImageField from "@/components/modals/fields/fieldImage.vue"
    import ContainerField from "@/components/modals/fields/fieldContainer.vue"
    import SectionField from "@/components/modals/fields/fieldSection.vue"
    import SelectCollectionField from "@/components/modals/fields/fieldSelectCollection.vue"

    import DisplayField from "@/components/modals/fields/fieldDisplay.vue"

    export default {
        name: 'ManageFieldEditModal',
        props: {
            after: Object,
            config: Object
        },
        data() {
            return ({
                lg: "FR",
                labels: labels,
                label: {
                    "FR": "",
                    "EN": ""
                },
                source: "",


                collection: "",
                filter: "",

                attribut: "",


                subdata: "",
                image_url: "",
                image_class: "",
                format: "",
                template: "",

                editFormat: "text",
                selectTags: [],
                layout: "w-6/12",
                selected: "0",
                alignment: "justify-start",
                units: [],
                selectValues: [],
                tagValues: [],
                collectionValues: [],
                unit: "",
                category: "",
                colors: ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'],
                variants: [100, 200, 300, 400, 500, 600, 700, 800, 900],
                currentColor: 'red-100',
                currentBgColor: 'red-400',
                iconColor: '',
                isOpen: true,

                options: {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,

                },
                dropdown: null,
                dropdownBg: null

            })
        },
        watch: {

            config: async function () {
                this.attribut = this.config?.data;
                this.editFormat = this.config?.format;
                this.editUnit = this.config?.unit;
                this.source = this.config?.source;
                if (this.editFormat == undefined) {
                    this.editFormat = "text";
                    this.editUnit = ""
                }
                this.label.FR = this.config?.label?.FR;
                this.label.EN = this.config?.label?.EN;
                this.category = this.config?.source_id
                this.layout = this.findLayout(this.config?.class)
                this.currentColor = this.findTextColor(this.config?.class)
                this.currentBgColor = this.findBgColor(this.config?.class)
                this.alignment = this.findAlignment(this.config?.class)

            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            showAttribute: function () {
                if (this.config?.type == undefined) return false;
                if (this.config?.type == "zone") return false;
                if (this.config?.type == "section") return false
                if (this.config?.type == "blank") return false;
                if (this.config?.type == "display") return false
                return true;
            },
            showTitle: function () {
                if (this.config?.type == "blank") return false;

                return true;
            },
            showAlignment: function () {
                if (this.config?.type == "blank") return false;

                return true;
            }
        },
        async mounted() {

            this.alignment = this.findAlignment(this.config?.class)

            //let selectTags = await  service.getData("tag-categories", this.user?.token, {})

            // if (selectTags != null) this.selectTags = selectTags.records;


            //  let collectionTag = "tag-categories"
            let collectionTag = "data-sources/668d065c9535b23c096b643f/records"

            let selectTags = await service.getData(collectionTag, this.user?.token, {})
            if (selectTags != null) this.tagValues = selectTags.records;

            let collectionValues = await service.getData("collections", this.user?.token, {})
            if (collectionValues != null) this.collectionValues = collectionValues.records;

          //  let collection = "select-value-categories"
            let collection = "data-sources/668d04cb9535b23c096b643e/records"
            let selectValues = await service.getData(collection, this.user?.token, {})

            let sv = []
            for (let i = 0; i < selectValues?.records?.length; i++) {
                sv.push({ id: selectValues?.records[i].id, name: selectValues?.records[i].category })
            }
            this.selectValues = sv;

        },

        methods: {

            onChangeDisplayField: function (item) {
                this.editFormat = item.format
                this.attribut = item.data
                this.subdata = item.subdata
                this.image_url = item.image_url
                this.image_class = item.image_class
                this.text_class = item.text_class
                this.template = item.template


 
            },
            onChangeTagField: function (item) {

                this.source = item

            },


            onChangeSelectField: function (item) {
                this.source = item

            },
            onChangeSelectCollectionField: function (item) {

                this.collection = item?.collection
                this.filter = item?.filter

                this.source = item?.collection
            },



            onChangeTextField: function (/*item*/) {
              //  console.log(item)
            },

            onChangeEditField: function (item) {
                this.editUnit = item.unit
                this.editFormat = item.format
              
            },

            findAlignment: function (item) {
                if (item == undefined) return "";

                if (item.indexOf("justify-start") != -1) return "justify-start";
                if (item.indexOf("justify-center") != -1) return "justify-center";
                if (item.indexOf("justify-end") != -1) return "justify-end";
                return "justify-start";
            },

            findLayout: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("w-1/12") != -1) return "w-1/12";
                if (item.indexOf("w-2/12") != -1) return "w-2/12";
                if (item.indexOf("w-3/12") != -1) return "w-3/12";
                if (item.indexOf("w-4/12") != -1) return "w-4/12";
                if (item.indexOf("w-5/12") != -1) return "w-5/12";
                if (item.indexOf("w-6/12") != -1) return "w-6/12";
                if (item.indexOf("w-7/12") != -1) return "w-7/12";
                if (item.indexOf("w-8/12") != -1) return "w-8/12";
                if (item.indexOf("w-9/12") != -1) return "w-9/12";
                if (item.indexOf("w-10/12") != -1) return "w-10/12";
                if (item.indexOf("w-11/12") != -1) return "w-11/12";
                if (item.indexOf("w-12/12") != -1) return "w-full";
                if (item.indexOf("w-full") != -1) return "w-full";
                return "w-full";
            },

            findTextColor: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("bg-") != -1) {
                    if (item.indexOf("text-gray") != -1) {
                        return this.findColorVariant(item, "text-gray", "gray");
                    }
                    if (item.indexOf("text-red") != -1) {
                        return this.findColorVariant(item, "text-red", "red");
                    }
                    if (item.indexOf("text-yellow") != -1) {
                        return this.findColorVariant(item, "text-yellow", "yellow");
                    }

                    if (item.indexOf("text-green") != -1) {
                        return this.findColorVariant(item, "text-green", "green");
                    }
                    if (item.indexOf("text-blue") != -1) {
                        return this.findColorVariant(item, "text-blue", "blue");
                    }
                    if (item.indexOf("text-indigo") != -1) {
                        return this.findColorVariant(item, "text-indigo", "indigo");
                    }
                    if (item.indexOf("text-purple") != -1) {
                        return this.findColorVariant(item, "text-purple", "purple");
                    }
                    if (item.indexOf("text-pink") != -1) {
                        return this.findColorVariant(item, "text-pink", "pink");
                    }
                    if (item.indexOf("text-white") != -1) {
                        return "white";
                    }
                    if (item.indexOf("text-black") != -1) {
                        return "black";
                    }
                    return "gray-900"
                }

                return "gray-900"
            },
            findColorVariant(item, tag, color) {
                if (item == undefined) return "";
                if (item.indexOf(tag + "-100") != -1) {
                    return color + "-100"
                }
                if (item.indexOf(tag + "-200") != -1) {
                    return color + "-200"
                }
                if (item.indexOf(tag + "-300") != -1) {
                    return color + "-300"
                }
                if (item.indexOf(tag + "-400") != -1) {
                    return color + "-400"
                }
                if (item.indexOf(tag + "-500") != -1) {
                    return color + "-500"
                }
                if (item.indexOf(tag + "-600") != -1) {
                    return color + "-600"
                }
                if (item.indexOf(tag + "-700") != -1) {
                    return color + "-700"
                }
                if (item.indexOf(tag + "-800") != -1) {
                    return color + "-800"
                }
                if (item.indexOf(tag + "-900") != -1) {
                    return color + "-900"
                }
                if (item.indexOf(tag) != -1) {
                    return color
                }
                return ""
            },
            findBgColor: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("bg-") != -1) {
                    if (item.indexOf("bg-gray") != -1) {
                        return this.findColorVariant(item, "bg-gray", "gray");
                    }
                    if (item.indexOf("bg-red") != -1) {
                        return this.findColorVariant(item, "bg-red", "red");
                    }
                    if (item.indexOf("bg-yellow") != -1) {
                        return this.findColorVariant(item, "bg-yellow", "yellow");
                    }

                    if (item.indexOf("bg-green") != -1) {
                        return this.findColorVariant(item, "bg-green", "green");
                    }
                    if (item.indexOf("bg-blue") != -1) {
                        return this.findColorVariant(item, "bg-blue", "blue");
                    }
                    if (item.indexOf("bg-indigo") != -1) {
                        return this.findColorVariant(item, "bg-indigo", "indigo");
                    }
                    if (item.indexOf("bg-purple") != -1) {
                        return this.findColorVariant(item, "bg-purple", "purple");
                    }
                    if (item.indexOf("bg-pink") != -1) {
                        return this.findColorVariant(item, "bg-pink", "pink");
                    }
                    if (item.indexOf("bg-white") != -1) {
                        return "white";
                    }
                    if (item.indexOf("bg-black") != -1) {
                        return "black";
                    }
                    return "white"
                }

                return "white"
            },


            onInputClick: function () {
                const $targetEl = document.getElementById("dropdown-input-color");
                const $triggerEl = document.getElementById("color-picker");

                if ($triggerEl != undefined) {
                    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdown.show()
            },
            onInputBgClick: function () {
                const $targetEl = document.getElementById("dropdown-input-bg-color");
                const $triggerEl = document.getElementById("bg-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownBg.show()
            },
            selectColor: function (color, variant) {
                if (color == "black") {
                    this.currentColor = color;
                }
                else if (color == "white") {
                    this.currentColor = color;
                }
                else
                    this.currentColor = color + "-" + variant;
                this.dropdown.hide()
            },
            selectBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentBgColor = color;
                }
                else if (color == "white") {
                    this.currentBgColor = color;
                }
                else this.currentBgColor = color + "-" + variant;
                this.dropdownBg.hide()
            },
            showPopupSign: function () {
                //   this.dropdown.show()
            },
            controlForm() {
                return true;
            },
            closeDialog() {
                this.$emit("close", null)
            },

            buildClass() {
                return this.layout + " bg-" + this.currentBgColor + " text-" + this.currentColor + " " + this.alignment;
            },
            saveForm() {



                this.$emit("save", {

                    id: this.config.id,
                    after: this.config.after,
                    tab: this.config.tab,
                    section: this.config.section,
                    type: this.config.type,
                    inside: this.config.inside,
                    attributes: {
                        label: this.label,

                        subdata: this.subdata,
                        data: this.attribut,
                        image_class: this.image_class,
                        text_class: this.text_class,
                        image_url: this.image_url,

                        source_id: this.category,
                        class: this.buildClass(),
                        type: this.editType,
                        source: this.source,
                        collection: this.collection,
                        filter: this.filter,
                        format: this.editFormat,
                        unit: this.editUnit,

                        template: this.template,
                    }
                })
                //  e.preventDefault()
            },
            deleteField() {

                this.$emit("delete", { id: this.config.id, type: this.config.type })
                //  e.preventDefault()
            },
            onClose() {
                this.$emit("close")
            }
        },
        components: {
            EditField, SelectField, TagField, ImageField, ContainerField, SectionField, SelectCollectionField, DisplayField //, BlankField
        },
    }
</script>
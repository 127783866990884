<template>
  <div
    class="mb-3 mr-1 relative h-18 cursor-grab flex  list-group w-full flex items-center    flex flex-row justify-start items-center "
  
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div class="flex flex-col items-start w-full  px-2   ">
      <div
        class=" w-full flex  py-0.5  items-center justify-start w-full   text-left text-gray-900 "
            
        :class="{'bg-gray-500 text-sm font-bold':(properties?.template=='title-tpl-1'), 'text-xl font-normal':(properties?.template=='title-tpl-2') }"
      >
        <hr class="bg-gray-500 border-gray-300 text-gray-500 border w-full">
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
          
        },
        methods:
        {
            addItem: function()
            {
                this.$emit("add", {type: 'avatar', id: this.properties.id} )
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
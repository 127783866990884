<template>
  <div
    v-if="result == 2"
    class="hidden"
  >
    {{ calculateScore(lfacts) }}
  </div>


  <div
    v-if="result?.letterScore?.value==undefined"
    class="relative bg-white flex justify-center  mt-2 w-full"
  >
    <div class="text-lg font-bold h-96  w-96 flex flex-col items-center justify-center">
      <i
        class="fa-solid fa-triangle-exclamation fa-lg mb-6"
        style="color: #fd8a08;"
      />

      <span>Le nutriscore ne peut être calculé par manque d'information</span>
    </div>
  </div>
  <div
    v-if="result?.letterScore?.value!=undefined"
    class="relative  mt-2 w-full"
  >
    <div class="w-full mb-6 ">
      <ScaleGraph
        :mark="mark"
        :marksection="marksection"
        :result="result"
      />
      <div class="w-full text-lg font-bold">
        {{ result?.letterScore?.value }} points (type {{ mapMode }})
      </div>
    </div>

    <div class="w-full flex flex-row  ">
      <div class="w-full  flex flex-col  justify-start items-center mr-3">
        <div class="w-full justify-start text-left font-bold text-lg mb-2 ">
          Ingrédients Négatifs
        </div>

        <div class="w-full  flex flex-row justify-start text-left border-b border-black">
          <div class="basis-3/4">
            Énergie {{ result?.negatives?.get('kJ')?.value }} kJ
          </div>
          <div class="basis-1/4 flex justify-end">
            <span class="font-bold">+ {{ result?.negatives?.get('kJ')?.points }}</span>&nbsp;points
          </div>
        </div>
        <div class="w-full  flex flex-row justify-start text-left border-b border-black">
          <div class="basis-3/4">
            Sucres {{ result?.negatives?.get('sugar').value }} g
          </div>
          <div class="basis-1/4 flex justify-end">
            <span class="font-bold">+ {{ result?.negatives?.get('sugar').points }}</span>&nbsp;points
          </div>
        </div>
        <div class="w-full  flex  justify-start text-left border-b border-black">
          <div class="basis-3/4">
            Acides gras saturés {{ result?.negatives?.get('satFats')?.value }} g
          </div>
          <div class="basis-1/4 flex justify-end  ">
            <span class="font-bold">+ {{ result?.negatives?.get('satFats')?.points }}</span>&nbsp;points
          </div>
        </div>
        <div class="w-full  flex flex-row justify-start text-left border-b border-black">
          <div class="basis-3/4">
            Sodium {{ result?.negatives?.get('sodium')?.value }}&nbsp;mg
          </div>
          <div class="basis-1/4 flex justify-end">
            <span class="font-bold">+ {{ result?.negatives?.get('sodium')?.points }}</span>&nbsp;points
          </div>
        </div>
      </div>

      <div class="w-full  flex flex-col ml-3">
        <div class="w-full justify-start text-left font-bold text-lg mb-2">
          Ingrédients Positifs
        </div>

        <div class="w-full  flex flex-row justify-start text-left border-b border-black">
          <div class="basis-3/4">
            Protéines {{ result?.positives?.get('protein')?.value }} g
          </div>
          <div class="basis-1/4 flex justify-end">
            <span class="font-bold">- {{ result?.positives?.get('protein')?.points }}</span>&nbsp;points
          </div>
        </div>

        <div class="w-full  flex flex-row justify-start text-left border-b border-black">
          <div class="basis-3/4">
            Fibres {{ result?.positives?.get('fiber')?.value }} g
          </div>
          <div class="basis-1/4 flex justify-end">
            <span class="font-bold">- {{ result?.positives?.get('fiber')?.points }}</span>&nbsp;points
          </div>
        </div>
        <div class="w-full  flex flex-row justify-start text-left border-b border-black items-center">
          <div class="basis-3/4">
            Fruits, légumes et noix {{ result?.positives?.get('goodStuff')?.value }} %
          </div>
          <div class="basis-1/4 flex justify-end">
            <span class="font-bold">-
              {{ result?.positives?.get('goodStuff')?.points }}</span>&nbsp;points
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 w-full text-left text-lg font-bold">
      Ingrédients ayant un impact négatif
    </div>
    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.negatives?.get('kJ').points"
        title="Energie"
      />
    </div>

    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.negatives?.get('sugar').points"
        title="Sucres "
      />
    </div>

    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.negatives?.get('satFats').points"
        title="Acides gras saturés"
      />
    </div>
    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.negatives?.get('sodium').points"
        title="Sodium"
      />
    </div>
    <div class="mt-6 w-full text-left text-lg font-bold">
      Ingrédients ayant un impact positif
    </div>

    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.positives?.get('protein').points"
        title="Protéines"
      />
    </div>

    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.positives?.get('fiber').points"
        title="Fibres "
      />
    </div>

    <div class="w-full">
      <ScaleGraphBis
        :mark="result?.positives?.get('goodStuff').points"
        title="Fruits, légumes et noix"
      />
    </div>
  </div>
</template>


<script>
/****
    import { mapGetters } from "vuex";
    import ScaleGraph from "@/components/content/nutriscore/ScaleGraph.vue"
    import ScaleGraphBis from "@/components/content/nutriscore/ScaleGraphBis.vue"
    import { GeneralTable, FatsTable, CheeseTable, DrinksTable, getUnit, WasPropUsedInCalculation } from "@/components/content/nutriscore/calc.js";
    import {
        GetDisplayNames,
        GetInputInfoTexts,
        GetInfoTexts,
        GetPlaceholderText,
        trans,
        AvailableModalInfos,
        CurrentLocale,
    } from "@/components/content/nutriscore/str_functions";


    export default {
        name: 'ContentNutriscore',
        emits: [ "manageField"],
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            mode: String,
            facts: Object,
            record: Object,
            config: Object

        },
        components: {
            ScaleGraph, ScaleGraphBis
        },
        computed:
        {
            ...mapGetters({ user: "StateUser" }),
            mapRef: function (a) {
                if (a == undefined) return null;
                return a;
            },
            mapMode() {
                switch (this.mode) {
                    case 'cheese': return "Fromages";
                    case 'fats': return 'Matiéres grasses ajoutées';
                    case 'drinks': return "Boissons";
                    case 'general': return "Cas Général";
                }
                return "Cas Général";
            },
            mark() {
                return "30";
            },
            mark_kj() {
                return "4";
            },
            marksection() {

                switch (this?.result?.letterScore?.points) {
                    case 'A': return '0';
                    case 'B': return '1';
                    case 'C': return '2';
                    case 'D': return '3';
                    case 'E': return '4';
                }
                return "2"
            },
            resultNavVisible() {
                return false;
            },
            currentTable() {
                switch (this.mode) {
                    case 'general':
                        return GeneralTable
                    case 'fats':
                        return FatsTable
                    case 'cheese':
                        return CheeseTable
                    case 'drinks':
                        return DrinksTable
                    default:
                        return GeneralTable
                }
            },
            resultScale() {
                return this.resultTable.nutriprops;
            },
            currentPointScale() {
                return this.resultTable.pointsToScore;
            },
            resultColors() {
                const allColorsPresent = (this.result.negatives.size + this.result.positives.size) ===
                    Object.keys(this.colors).length
                if (allColorsPresent) {
                    return this.colors
                }
                return null;
            },
            modeInfoText() {
                return GetInfoTexts(this.mode)
            },
        },

        data() {
            return {
                result: {},


                factsCheese: {
                    kJ: "1337",
                    sugar: "0.5",
                    satFats: "19",
                    sodium: "2.3",
                    protein: "19",
                    fiber: "0",
                    oil: "0",
                    goodStuff: "0",
                    totalFats: "27",
                    salt: "2.3"
                },

                lfacts: {},
                //{
                //    kJ: "133",
                //    sugar: "0.5",
                //    satFats: "2.33",
               //     sodium: "0.110",
                //    protein: "2.6",
                //   fiber: "0",
                //    oil: "0",
                //    goodStuff: "0",
                //    totalFats: "3.4",
                //    salt: "0.110"
               // },


                tableNames: ['general', 'fats', 'drinks', 'cheese'],


                series: [34],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'radialBar',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                                margin: 0,
                                size: '70%',
                                background: '#fff',
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: 'front',
                                dropShadow: {
                                    enabled: true,
                                    top: 3,
                                    left: 0,
                                    blur: 4,
                                    opacity: 0.24
                                }
                            },
                            track: {
                                background: '#fff',
                                strokeWidth: '67%',
                                margin: 0, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: -3,
                                    left: 0,
                                    blur: 4,
                                    opacity: 0.35
                                }
                            },

                            dataLabels: {
                                show: true,
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: '#888',
                                    fontSize: '17px'
                                },
                                value: {
                                    formatter: function () {
                             
                                        return 'D'//parseInt(val) * 2;
                                    },
                                    color: '#111',
                                    fontSize: '36px',
                                    show: true,
                                }
                            }
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            type: 'horizontal',
                            shadeIntensity: 0.5,
                            gradientToColors: ['#ABE5A1'],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100]
                        }
                    },
                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Nutriscore'],
                },


                output: ""
            }
        },
        async mounted() {
            this.output = this.facts?.cent?.energy?.joules?.value;

            //  if (this.value != undefined) {

            // }

            this.mapFacts();
            if (this.lfacts != undefined)
                this.calculateScore(this.lfacts);
            this.$nextTick(function () {
                window.scrollTo(0, 0)

            });


        },
        methods: {
     
            emptyResult() {
                this.result = { "negatives": {}, "positives": {}, "badScore": 0, "applyProtein": true, "proteinAppliedReason": 3, "totalScore": null, "letterScore": { "value": null, "fractal": null, "points": "" }, "mode": "cheese", "name": "Cheese" }
            },
            mapFacts() {
                this.lfacts.kJ = this.facts?.cent?.energy?.joules?.value;

                this.lfacts.sugar = this.facts?.cent?.glucides?.sugar?.value;
                this.lfacts.totalFats = this.facts?.cent?.fat?.total?.value;
                this.lfacts.satFats = this.facts?.cent?.fat?.satured?.value;


                this.lfacts.fiber = this.facts?.cent?.fiber?.total?.value ? this.facts?.cent?.fiber?.total?.value : "0";

                this.lfacts.oil = "0";
                this.lfacts.goodStuff = this.facts?.cent?.fiber?.percent?.value ? this.facts?.cent?.fiber?.percent?.value : "0";

                this.lfacts.salt = this.facts?.cent?.sodium?.salt?.value;
                this.lfacts.sodium = this.facts?.cent?.sodium?.total?.value;

                this.lfacts.protein = this.facts?.cent?.proteins?.total?.value;

            },
            trans,
            calculateScore(nutritionalInfo) {
          
                if (Object.keys(nutritionalInfo).length == 0) {

             

                    this.emptyResult(); return
                }

                if (nutritionalInfo.kJ == "") {

              
                    this.emptyResult(); return
                }
                if (nutritionalInfo.sugar == "") {
                
                    this.emptyResult(); return
                }
                if (nutritionalInfo.satFats == "") {

               
                    this.emptyResult(); return
                }
                if (nutritionalInfo.totalFats == "") {

                
                    this.emptyResult(); return
                }
                if (nutritionalInfo.fiber == "") {

                 
                    this.emptyResult(); return
                }
                if (nutritionalInfo.salt == "") {

              
                    this.emptyResult(); return
                }
                if (nutritionalInfo.protein == "") {
                
                    this.emptyResult(); return
                }

          



                this.result = null
                this.colors = {}
                let normalizedNutriInfo = {}
                for (const [prop, val] of Object.entries(nutritionalInfo)) {
                    normalizedNutriInfo[prop] = this.normalizeFloat(val)
                }


 


                this.result = this.currentTable.calculateScore(normalizedNutriInfo)
          
                this.result.mode = this.mode
                this.result.name = this.name || GetDisplayNames(this.mode)
                this.resultTable = this.currentTable

                return this.result
            },
            resetModal() {
                this.showModalInfoFor = null;
                this.modalParams = {}
            },

            newCalculation() {
                this.nutritionalInfo = {
                    kJ: 0,
                    sugar: 0,
                    satFats: 0,
                    sodium: 0,
                    protein: 0,
                    fiber: 0,
                    oil: 0,
                    goodStuff: 0,
                    totalFats: 100,
                    salt: 0
                };

                this.name = this.getPlaceholderText(this.mode)

            },
            initModal(name, modalParams) {
                this.showModalInfoFor = name;
                this.modalParams = modalParams
            },
            buildQuery(nutriInfo, mode, name) {
                let params = new URLSearchParams({
                    ...nutriInfo,
                    mode,
                    name
                })
                return params.toString()
            },

            normalizeFloat(val) {
                if (val == undefined) return val;
                if (CurrentLocale === 'de') {
                    return parseFloat((val).toString().replace(',', '.'))
                } else {
                    return parseFloat((val).toString())
                }
            },
            inputDisplayNames(prop) {
                return GetDisplayNames(prop, true)
            },
            displayNames(prop) {
                return GetDisplayNames(prop, WasPropUsedInCalculation(prop, this.result))
            },
            getUnit(nutriProp) {
                return getUnit(nutriProp)
            },
            getInputInfoText(prop) {
                return GetInputInfoTexts(prop)
            },
            getPlaceholderText(prop) {
                return GetPlaceholderText(prop)
            },
            appendPropColor(color, prop) {
                this.colors[prop] = color
            },
            getColorForProp(prop) {
                return this.colors[prop] ?? null
            },
            hasInfoModal(prop) {
                return !!(AvailableModalInfos[prop])
            },
            wasUsedInCalculation(prop) {
                return (prop !== 'protein' || this.result.applyProtein)
            },
            getImageForMode(mode) {
                const PATH = './img/';
                switch (mode) {
                    case 'general':
                        return `${PATH}sandwich.svg`
                    case 'fats':
                        return `${PATH}butter.svg`
                    case 'cheese':
                        return `${PATH}cheese-wedge.svg`
                    case 'drinks':
                        return `${PATH}cup.svg`
                    default:
                        return `${PATH}sandwich.svg`
                }
            },
            isValid(prop) {
                let value = this.nutritionalInfo[prop];
                if (value === '') {
                    return false
                }
                value = this.normalizeFloat(value)
                const defaultvalidator = (v) => (v >= 0 && v <= 100)
                const kjValidator = (v) => (v >= 0 && v <= 10000)
                const validators = {
                    kJ: kjValidator
                };

                return (validators[prop] || defaultvalidator)(value)
            },

            allFieldsValid() {
                let isValid = true;
                for (let [name,] of this.currentTable.negativeInputs()) {
                    isValid &= this.isValid(name)
                }
                for (let [name,] of this.currentTable.positiveInputs()) {
                    isValid &= this.isValid(name)
                }
                return isValid


            },
            getData() {


            }
        },
        watch: {
            facts: function (val) {
              
                this.mapFacts();
                this.output = val;

                this.calculateScore(this.lfacts);
            },
            records: function () {
             
                this.mapFacts();

                this.calculateScore(this.lfacts);

            }
        }
    }*/
</script>


<style>

</style>
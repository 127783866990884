<template>
  <div class="  mb-2 w-full min-h-96 relative">
    <label
      class=" flex flex-row font-bold text-left ml-4 px-2.5 text-center block mb-2 text-lg   text-gray-900 dark:text-white"
    >
      <div class="  w-full">{{ labels['LABEL_INGREDIENT_TITLE'][lg] }} ***** {{ mode }}</div>
 
    </label>
 
    <div>
      <div class="w-full flex  ">
        <div class="w-12/12 w-full    ">
        
          <draggable
            ghost-class="ghost"
            class="mt-4 w-full   min-h-96 border-1 border-solid items-start flex flex-col bg-white mb-3 border-gray-300 p-6"
            class2="list-group mt-2 w-full h-12  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
            :list="lines"
            item-key="id"
            @start="drag=true"
            @end="drag=false"
          >
            <template #header2>
              <div
                class="hidden  mb-2 w-full py-2 flex justify-center"
                role="group"
              >
                <button
                  type="button"
                  class="  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  @click="onOpenList"
                >
                  <i class="fa-solid fa-plus  mr-2" />

                  {{ labels['BUTTON_ADD'][lg] }}  
                </button>
              </div>
            </template>



            <template #item="{element}">
              <div
                class="cursor-pointer list-group mt-2 w-full  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
              >
                <div class="flex flex-col items-center w-full  pl-6">
                  <div class=" h-6 flex flex-row items-center w-full   ">
                    <div class="w-full flex items-center">
                      <label
                        class="  text-left block  text-lg font-bold text-gray-900 dark:text-white"
                      >
                        {{ element.type.name[lg] }} - {{ element.quantity.value }}
                        {{ element.quantity.unit }}

                      </label>
                    </div>

                    <div class="w-auto flex  w-full items-center justify-end  ">
                      <div class="w-auto flex flex-row items-center justify-center  ">
                        <div
                          href="#"
                          class="hidden  w-4 cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg px-6 flex items-center  justify-center        focus:outline-none  "
                          @click="onClickSetup(element)"
                        >
                          <i class="fa-solid fa-gear" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.bold), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.bold)} "
                          class="hidden rounded-lg w-4 cursor-pointer   font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                          @click="onClickBold(element)"
                        >
                          <i class="fa-solid fa-bold" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.italic), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.italic)} "
                          class="hidden rounded-lg w-4 cursor-pointer   font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                          @click="onClickItalic(element)"
                        >
                          <i class="fa-solid fa-italic" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.underline), 'text-gray-100 hover:text-gray-200 bg-blue-800   ': (element.options.underline)} "
                          class="hidden rounded-lg w-4 cursor-pointer     font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1     focus:outline-none  "
                          @click="onClickUnderline(element)"
                        >
                          <i class="fa-solid fa-underline" />
                        </div>
                      </div>
                      <div
                        class="hidden w-auto flex  w-full   items-center justify-end   w-16 px-3"
                      >
                        <div
                          href="#"
                          class=" flex justify-end   cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg          focus:outline-none  "
                          @click="onClickMinus(element)"
                        >
                          <i class="fa-solid fa-xmark  " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row items-center w-full   h-4 pr-4">
                    <hr
                      class="w-full h-1 mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"
                    >
                  </div>
                  <div class="flex flex-row w-full items-center justify-start pb-2">
                    <label
                      v-if="mode=='percent'"
                      class="mr-4 sw-auto text-sm font-medium text-gray-900   dark:text-white"
                    >
                      Lot(s)</label>



                    <Multiselect
                      v-if="mode=='percent'"
                      v-model="output"
                      class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="true"
                      mode="tags"
                      :on-create="handleTagCreate"
                      :options="loadAutocomplete"
                    />

                    <div
                      v-if="mode=='stock'"
                      class="flex justify-between mb-1  pr-4 w-full flex-col"
                    >
                      <div class="flex justify-between mb-1  ">
                        <span
                          class="text-xs font-bold font-medium text-blue-700 dark:text-white"
                        >Nécéssite
                          {{ ratio(element) }} </span>
                        <span class="text-xs font-medium text-blue-700 dark:text-white">Stock
                          {{ element.stock }} Kg</span>
                      </div>
                      <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                          class="bg-blue-600 h-2.5 rounded-full"
                          :style="`${calcRatioStyle(ratio(element),element.stock)}`"
                        />
                      </div>
                    </div>

                    <div
                      v-if="mode=='recipe'"
                      class="flex justify-between mb-1  pr-4 w-full flex-col"
                    >
                      <div class="flex justify-between mb-1  ">
                        <span
                          class="text-xs font-bold font-medium text-blue-700 dark:text-white"
                        >Nécéssite
                          {{ ratio(element) }} </span>
                        <span class="text-xs font-medium text-blue-700 dark:text-white">Stock
                          {{ element.stock }} Kg</span>
                      </div>
                      <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                          class="bg-blue-600 h-2.5 rounded-full"
                          :style="`${calcRatioStyle(ratio(element),element.stock)}`"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="hidden flex flex-row items-center w-full flex-col mb-2  ">
                    <label
                      for="default-range"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Default
                      range</label>
                    <input
                      id="default-range"
                      type="range"
                      value="50"
                      class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    >
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
  <SelectIngredientModal
    @close="onSelectIngredientClose"
    @select="onSelectIngredientSelect"
  />
</template>

<script>
    import SelectIngredientModal from '@/components/modals/SelectIngredientModal2.vue'
    import draggable from 'vuedraggable'
    import Multiselect from '@vueform/multiselect'
    import labels from "@/assets/lg/content-composer.json"
    import { Dropdown } from 'flowbite';
    import { Modal } from 'flowbite';
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";

    export default {
        name: 'ContentIngredientComposer',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: Object,
            parent: Object,
            volume: Object,
            config: Object
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            }
        },
        watch: {

            volume: async function () {
                

            },
            parent: async function () {
                let product = await service.getDataId("raw-products", this.parent, this.user?.tokenn, { fields: "id,recipe" })
         

                let stocks = await service.getData("inbound-stock/summary", this.user?.token, {})

           
                for (let i = 0; i < product?.recipe?.length; i++) {
                    let item = product.items.find(function (a) {
 

                        return a._id == product.recipe[i].type_id
                    })


                    let stock = stocks.records.find(function (a) {

                  

                        return a._id == product.recipe[i].type_id
                    });
                    if (stock != undefined)
                        product.recipe[i].stock = stock.count
                    else
                        product.recipe[i].stock = -1;
             
                    if (item != undefined)
                        product.recipe[i].type = item
                }

         
                this.lines = product.recipe
            },

            lines: function () {
            
            },
            value: function () {
                //   this.output = val;
        
            }
        },
        mounted() {
            if (this.value != undefined)
                this.lines = this.value;
            /*
                        const options = {
                            placement: 'bottom',
                            triggerType: 'click',
                            offsetSkidding: 0,
                            offsetDistance: 10,
                            delay: 300,
                            onHide: () => {
                                
                            },
                            onShow: () => {
                                 
                            },
                            onToggle: () => {
                            
            
            
            
                            }
            
                        };
                        {
                            const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-0');
                            const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-0');
                            this.dropdowns[0] = new Dropdown($targetEl, $triggerEl, options);
                           
            
                        }
                        {
                            const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-1');
                            const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-1');
                        
            
                            this.dropdowns[1] = new Dropdown($targetEl, $triggerEl, options);
                        }
            */
            const modalEl = document.getElementById('select-ingredient-modal');
            this.addIngredientModal = new Modal(modalEl, {
                placement: 'center'
            });

       

        },

        methods: {
      
            calcRatioStyle: function (needed, stock) {
                if (needed == "") {
                    return "width:0%;"
                }

                if (stock == "-1") {
                    return "width:0%;"
                }

                let ratio = (parseFloat(needed) * 100) / parseFloat(stock);
                if ((ratio > 99) && (ratio < 100)) {
                    return "width: " + ratio.toFixed(0) + "%;background-color:orange"
                }


                if (ratio < 100) {
                    return "width: " + ratio.toFixed(0) + "%;background-color:green"
                }
                return "width:100%;background-color:red"
            },


            checkPourcent: function (event, index) {

                event.stopPropagation();

                if (this.lines[index].value > 100) {
                    this.lines[index].error = true
                }
                else
                    this.lines[index].error = false

                if (this.lines[index].display == undefined)
                    this.lines[index].display = 1
                this.BuildIngredientText()
            },
            ratio: function (el) {
           
                if (this.volume == undefined) return "";
                if (this.volume.value == undefined) return "";
                if (this.volume.value == "") return "";
                return ((parseFloat(el.quantity.value) * parseFloat(this.volume?.value)) / 100).toFixed(2) + this.volume?.unit
            },
            getIndex: function (id) {


                let index = this.lines.findIndex(function (el) { return el.id == id })

            
                return index;
            },
            selectLanguage: function (lg) {
                this.selectedLang = lg;
                this.BuildIngredientText()
            },
            BuildIngredientText: function () {
                let text = "";
                for (let i = 0; i < this.lines.length; i++) {
                    if (text != "") text = text + ", ";
                    let tagStart = "";
                    let tagEnd = "";

                    if (this.lines[i].options) {
                        if (this.lines[i].options.bold) {
                            tagStart = tagStart + "<b>";
                            tagEnd = "</b>" + tagEnd
                        }
                        if (this.lines[i].options.italic) {
                            tagStart = tagStart + "<i>";
                            tagEnd = "</i>" + tagEnd
                        }
                        if (this.lines[i].options.underline) {
                            tagStart = tagStart + "<u>";
                            tagEnd = "</u>" + tagEnd
                        }
                    }
                    let value = ""
                    if (this.lines[i].value) {
                        if (this.lines[i].options.display == 1)
                            value = " " + this.lines[i].value + " %"
                        if (this.lines[i].options.display == 2)
                            value = " : " + this.lines[i].value + " %"
                        if (this.lines[i].options.display == 3)
                            value = " (" + this.lines[i].value + " %)"
                    }
                    text = text + tagStart + this.lines[i].displayName[this.selectedLang.toUpperCase()] + value + tagEnd;

                }

                return text;
            },


            getData() {


                return this.lines;

                //var x = document.getElementById("date-" + this.id).value;
                // return this.formatIso(x)
            },
            onDropdownOpen: function (index) {


                //  if (this.dropdowns[index] == undefined) 
                {

                    const options = {
                        placement: 'bottom',
                        triggerType: 'click',
                        offsetSkidding: 0,
                        offsetDistance: 10,
                        delay: 300,
                        onHide: () => {
                            
                        },
                        onShow: () => {
                            
                        },
                        onToggle: () => {
                           

                        }

                    };
                    {
                        const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-' + index);
                        

                        const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-' + index);
                       
                        this.dropdowns[index] = new Dropdown($targetEl, $triggerEl, options);

                    }
                }
                this.dropdowns[index].toggle()
                

            },
            onSelectIngredientSelect: function (item) {

                let newItem = {};


                newItem.type_id = item._id;
                newItem.type = item;
                newItem.quantity = {}
                newItem.quantity.unit = '%';
                newItem.quantity.value = '100'
                newItem.options = {};

                newItem.batches = []

                this.lines.push(newItem)

        

                this.addIngredientModal.hide();
            },
            onSelectIngredientClose: function () {
                this.addIngredientModal.hide();
            },
            onOpenList: function () {
   
                this.addIngredientModal.show();
                //  this.windowRef = window.open("https://editor-label.vercel.app/edit/" + this.selectedLabel.id, this.selectedLabel.id, "");
                //  if (!this.windowRef) return;
            },
            selectValue: function (tag, unit) {
                //this.output = a
          

                let index = this.getIndex(tag)
                if (this.lines[index] == undefined) this.lines[index] = { unit: '' }
                this.lines[index].unit = unit;

                this.dropdowns[index].toggle()
            },
            onClickDisplay: function (item, display) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}

                item.options.display = display

                this.lines[index] = item;
                this.BuildIngredientText()


            },

            onClickBold: function (item) {
                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.bold = !item.options.bold
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickItalic: function (item) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.italic = !item.options.italic
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickUnderline: function (item) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.underline = !item.options.underline
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickSetup: function (/*item*/) {

              
            },
            onClickMinus: function (item) {
                function removeItemOnce(arr, index) {

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    return arr;
                }
                for (let i = 0; i < this.lines.length; i++) {

                    if (this.lines[i].id == item.id) {
                       
                        //   this.lines = this.output;
                        this.lines = removeItemOnce(this.lines, i);

                        return
                    }
                }
            }
        },
        components: {
            SelectIngredientModal, draggable, Multiselect
        },
        data() {
            return {
                output: [],
                labels: labels,
                lg: 'FR',
                drag: false,
                addIngredientModal: null,
                el_dropdown: null,
                dropdowns: [],
                lines: [],
                mode: 'stock',
                enabled: true,
                selectedLang: 'fr',
                dragging: false
            }
        }
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .buttons {
        margin-top: 35px;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }
</style>
<template>
  <div class="mt-2 mb-2 w-full flex flex-row items-center relative" :class="{'border-b border-gray-400' : config.underlined == true}">
   
 
    <label v-if="label != null" :for="`${id}`"
      class="w-full text-left  mt-2  mb-2 text-sm font-bold text-gray-900 dark:text-white">
      {{ label }} 
    </label>

    <div class="px-3   w-full flex items-center">
      <label for="default" class="text-left block  pr-3  text-sm   text-gray-900 dark:text-white"
        :class="{'font-bold': output!=true, ' font-medium': output==true, }">
        Non
      </label>
      <label class="w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
        <input :id="`${id}`" v-model="output" type="checkbox" value="" class="sr-only peer" :disabled="config.disabled">
        <div
        :class="{'w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white   after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600':  (config.disabled != true),
            'relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600' : (config.disabled== true) }"></div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
      </label>

      <label for="default" class="text-left block  pr-3  text-sm   text-gray-900 dark:text-white"
        :class="{'font-bold': output==true, ' font-medium': output!=true, }">
        Oui
      </label>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ContentSwitch',
    props: {
      id: String,
      label: String,
      placeholder: String,
      required: Boolean,
      config: Object,
      value: Boolean
    },
    data() {
      return {
        output: ""
      }
    },
    watch: {
      output: function () {
        this.$emit("updateFieldValue", { _id: this.config.id, value: this.output, id: this.config.data })
      },
      value: function (val) {
        this.output = val;
      }
    },
    mounted() {
      this.output = this.value;

    },
    methods: {

      getData() {

        return this.output
      }
    }
  }
</script>
<template>
  <div
    class=" mx-auto fixed top-0 w-full h-full  bg-no-repeat bg-full "
    :style="`background-image: url('${registerBg2}');background-repeat: no-repeat;background-attachment: fixed;background-size: cover;`"
  >
    <div class="relative shadow-xl flex content-center items-center justify-center h-full  ">
      <div
        class="w-full max-w-sm lg:max-w-xl  px-4"
        :class="{
          'opacity-50': loginInProgress,
          block: !loginInProgress,
        }"
      >
        <div
          class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
               shadow-lg
              rounded-lg
              bg-slate-200
              border-0
            "
        >
          <div class="flex-auto justify-end px-4 lg:px-10 py-10 pt-0">
            <div class="w-full bg-transparent text-center flex justify-center">
              <a
                href="https://www.b4food.io"
                class="flex  "
              >
                <img
                  :src="logo"
                  class="h-24  "
                  alt="B4food.io"
                >

              </a>
            </div>
            <div class="text-slate-600 text-center mb-3 font-bold text-xl lg:text-2xl">
              <br> {{ $t("LOGIN_TITLE") }}
            </div>

            <div class="text-slate-600 text-center mb-3 lg:mb-6 font-normal text-sm lg:text-lg">
              {{ $t("LOGIN_DESCRIPTION") }}
            </div>
            <div class="h-6 py-2 mb-6">
              <div
                v-if="showError=='ERROR_LOGIN'"
                class=" bg-orange-100 border-l-4 border-l-orange-500  text-red-500 font-normal"
              >
                {{ $t("LOGIN_ERROR_LOGIN") }}
              </div>

              <div
                v-if="showError=='ERROR_INTERNAL'"
                class=" bg-orange-100 border-l-4 border-l-orange-500 text-red-500 font-normal"
              >
                {{ $t("CHANGE_PASSWORD_ERROR_INTERNAL") }}
              </div>
            </div>

            <form @submit.prevent="submit">
              <div class="relative w-full mb-3">
                <label
                  class="
                      block
                      text-left
                      uppercase
                      text-slate-600 text-xs
                      font-bold
                      mb-2
                    "
                  htmlFor="grid-password"
                >

                  {{ $t("LOGIN_LABEL_EMAIL") }}



                </label>
                <input
                  ref="login"
                  v-model="form.email"
                  type="email"
                  class="  
                      border-0
                      px-3
                      py-3
                      text-left
                      placeholder-slat-300
                      text-slate-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  placeholder="Adresse e-mail"
                >
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="
                      block
                      uppercase
                      text-left
                      text-slate-600 text-xs
                      font-bold
                      mb-2 flex items-center max-w-lg mx-aut
                    "
                  htmlFor="grid-password"
                >


                  {{ $t("LOGIN_LABEL_PASSWORD") }}

                </label>
                <input
                  ref="password"
                  v-model="form.password"
                  type="password"
                  :disabled="loginInProgress"
                  class="
                  
                      border-0
                      px-3
                      py-3
                      placeholder-slat-300
                      text-slate-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  placeholder="Mot de passe"
                >






                <div class="relative w-full hidden">
                  <input
                    ref="password"
                    v-model="form.password"
                    type="password"
                    :disabled="loginInProgress"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Mot de passe"
                    required
                  >
                  <button
                    type="button"
                    class="absolute inset-y-0 end-0 flex items-center pe-3"
                  >
                    <svg
                      onClick="onViewPassword"
                      class="w-4 h-4 text-gray-300 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    ><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                      <path
                        d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <label class=" w-full text-left inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    v-model="form.rememberme"
                    type="checkbox"
                    class="
                        form-checkbox
                        border-0
                        rounded
                        text-slate-700
                        ml-1
                        w-5
                        h-5
                        ease-linear
                        transition-all
                        duration-150
                      "
                  >
                  <span class=" text-left ml-2 text-sm font-semibold text-slate-600">


                    {{ $t("LOGIN_LABEL_REMEMBER") }}


                  </span>
                </label>
                <div class=" text-left flex flex-wrap mt-6 relative">
                  <div class="w-1/2">
                    <div
                      class="cursor-pointer"
                      @click="ResetPassword"
                    >
                      <small>
                        {{ $t("LOGIN_LABEL_FORGOT_PASSWORD") }}


                      </small>
                    </div>
                  </div>

                  <div class="w-1/2 text-right hidden">
                    <router-link
                      to="/auth/register"
                      class=""
                    >
                      <small>Create new account</small>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="text-center mt-6">
                <button
                  class="
                      bg-gray-800
                      text-white
                      active:bg-gray-600
                      text-sm
                      font-bold
                      uppercase
                      px-6
                      py-3
                      rounded
                      shadow
                      hover:shadow-lg
                      outline-none
                      focus:outline-none
                      mr-1
                      mb-1
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  :disabled="loginInProgress"
                  type="submit"
                >
                  {{ $t("LOGIN_BUTTON") }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative" />
      </div>

      <div class="absolute top-0 right-0 flex flex-row bg-black px-2 py-2">
        <div
          class="px-1 text-white   cursor-pointer"
          :class="{ 'font-bold' : ( $i18next?.language === 'en' ), 'font-normal' : ( $i18next?.language !== 'en' ),}"
          @click="onChangeLanguage('en')"
        >
          en
        </div>
        <div class="px-1 text-white font-normal">
          |
        </div>
        <div
          class="px-1 text-white  cursor-pointer"
          :class="{ 'font-bold' : ( $i18next?.language === 'fr' ), 'font-normal' : ( $i18next?.language !== 'fr' ),}"
          @click="onChangeLanguage('fr')"
        >
          fr
        </div>
        <div class="px-1 text-white font-normal">
          |
        </div>
        <div
          class="px-1 text-white  cursor-pointer"
          :class="{ 'font-bold' : ( $i18next?.language === 'sp' ), 'font-normal' : ( $i18next?.language !== 'sp' ),}"
          @click="onChangeLanguage('sp')"
        >
          sp
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import registerBg2 from "@/assets/img/bg_login_1.jpg";

  import service from "@/services/FullService.vue"

  import labels from "@/assets/lg/login.json";

  import logo from "@/assets/logo.png"

  import github from "@/assets/img/github.svg";
  import google from "@/assets/img/google.svg";
  import { mapActions, mapGetters } from "vuex";

  export default {

    name: "LoginView",



    data() {
      return {
        registerBg2,
        logo: logo,
        labels: labels,
        lg: "FR",
        passwordType: "password",
        form: {
          email: "",
          password: "",
        },
        autofocus: false,
        loginInProgress: false,
        showError: null,
        github,
        google,
      };
    },
    computed: {
      ...mapGetters({ Remember: "StateRememberMe" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    mounted() {
      if (this.Remember.flag == true) {
        this.form.email = this.Remember.login;
        this.form.rememberme = this.Remember.flag;
        this.autofocus = true;
        /* eslint-disable */
        setTimeout(x => {

          this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
        }, 800);
      }
      else {
        /* eslint-disable */
        setTimeout(x => {

          this.$nextTick(() => this.setFocusLogin()); // this works great, just watch out with going to fast !!!
        }, 800);
      }
    },
   
    methods: {
      ...mapActions(["RememberMe", "LogIn"]),
      onChangeLanguage: function (lang) {
        this.$i18next.changeLanguage(lang);
      },
      ResetPassword: function () {
        this.$router.push("/auth/reset-password")
      },
      setFocus: function () {
        // Note, you need to add a ref="search" attribute to your input.
        this.$refs?.password?.focus();
      },
      setFocusLogin: function () {
        // Note, you need to add a ref="search" attribute to your input.
        this.$refs?.login?.focus();
      },
      onViewPassword() {
        if (this.passwordType == "password") {
          this.passwordType = "text"
        }
        else {
          this.passwordType = "password"
        }
        return true;
      },
      async submit() {
        let user = {
          email: this.form.email,
          password: this.form.password,
        };
        // const User = new FormData();
        // User.append("email", this.form.email);
        // User.append("password", this.form.password);
        try {
          let remember = {
            flag: this.form.rememberme,
            login: this.form.email,
          };
          await this.RememberMe(remember);

          this.loginInProgress = true;
          let response = await service.LogIn(user);



          if (response.code == 0) {
            this.showError = null;
            await this.LogIn(response)
     
         this.$router.push("/admin/dashboard/main")
            return
          } this.loginInProgress = false;

          if (response.code == 5) {
            this.showError = "ERROR_LOGIN"
          }
          else {
            this.showError = "ERROR_INTERNAL"
          }

 
        } catch (error) {

          console.log(error)
          this.loginInProgress = false;

          this.showError = true;
        }
      },
    },
  };
</script>
<style scoped>
  .error {
    color: white;
    background-color: red;
    text-align: center;
  }
</style>
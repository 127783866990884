<template>
  <div id="send-mail-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full   max-w-xl  ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  flex flex-col  items-stretch   ">
        <!-- Modal header -->
        <div
          class="  px-4 flex items-center justify-between pb-1 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700">
          <h3 class="text-left left font-semibold text-gray-900 text-lg dark:text-white">
            <h5 class="font-semibold text-gray-900 text-lg dark:text-white"  >
              Envoi de mail
            </h5>
          </h3>
          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class=" flex items-stretch bg-red-100 rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4">
          <div class="w-full  h-full">
            <div class="w-fullmx-2 text-left justify-left relative mt-2">
              <div  
                class="flex flex-col w-full pr-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white  h-64 overflow-y-auto">
             
             
             Souhaitez-vous renvoyer le document à 
             
              </div>
            </div>
            <form
              class="flex items-center mt-3  mx-auto flex flex-col text-left left justify-start items-start  w-full ">
              <p class="w-full justify-start px-4  text-left left text-base font-bold text-gray-500 dark:text-gray-400 mb-2"
                >
                Adresse(s) : 
              </p>
              <label for="simple-search" class="sr-only">Search</label>
              <div class="relative w-full px-4">
                <input @keyup="onChange" v-model="query" type="text" id="simple-search"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-2 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Numéro du lot" required />
              </div>
            </form>
          </div>
        </div>
        <div class="flex items-center justify-end px-4  ">
          <button type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click="onClose()">
            Annuler
          </button>
          <button type="button" :disabled="(!checkName)"
            class="inline-flex items-center text-white   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            :class="{'bg-gray-400 hover:bg-gray-400': (!checkName),'bg-gray-600 hover:bg-gray-700': (checkName) }"
            @click="onValidate">
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import labels from "@/assets/lg/attribut.json"


  import { mapGetters } from "vuex";

  export default {
    name: 'SendMailModal',
    props: {
      input: Object
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      checkName() {
        return (this.input.name == this.query)
      }
    },
    data() {
      return ({
        lg: "FR",
        labels: labels,
        price: "",
        mode: "",
        id: null,
        selected: null,

        query: ""
      })
    },
    watch: {
      'input': async function () {
        this.id = this.input?.id
        this.query = ""



      }
    },
    async mounted() {
      this.id = this.input?.id

    },

    methods: {


      onValidate() {
        let item =
        {
          action: this.input.action,
          source: { id: this.id }
        }
        this.$emit("confirm", item)
        // this.name = ""
      },
      onClose() {
        this.$emit("close")

      }
    },
    components: {

    },
  }
</script>
<script setup>

// import {   defineProps } from 'vue';

    const prop2s = defineProps({
        label: {
            type: String,
            required: true,
        },
    })
</script>

<template>
  <tspan
    dy="10"
    x="0"
  >
    {{ prop2s.label }} **********
  </tspan>
  **********
</template>
<template>
  <div
    class="  relative  cursor-grab flex  list-group mt-2 w-full flex items-center       flex flex-row justify-start items-center "
  >
    <div
      class="flex    w-full  pl-2  pr-2 "
        
      :class="{'flex-col items-start' : (properties?.layout != 'row'), 'flex-row items-center' : (properties?.layout == 'row') }"
    >
      <div class="w-full flex items-center flex-col">
        <div
          v-if="properties?.option?.title"
          class=" w-full flex font-bold  py-0.5  items-center justify-start  text-sm  text-left text-gray-700  "
        >
          {{ properties?.title }}
        </div>
        <div
          v-if="properties?.option?.description"
          class="mt-2  w-full   text-xs text-left text-gray-500  font-normal"
        >
          {{ properties?.description }}
        </div>
      </div>


      <div class="w-full mt-2  font-bold  text-xs  text-gray-800 ">
        <div
          type="text"
          :style="getStyle"

                   
          class="  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    
          :class="getClass"
        />
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            getClass : function()

            {
              if (this.properties.width == undefined) return "w-full"
              if (this.properties.width == "") return "w-full"
              return this.properties.width
            },
            getStyle: function () {

                let lines = 1

                if (this.properties?.lines != undefined) lines = parseInt(this.properties?.lines)


                return "height: " + (lines * 30) + "px"
            }
        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties?.id })
            },
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
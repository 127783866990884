<template>
  <div class="  relative    flex    w-full flex items-center         flex flex-row justify-start items-center ">
    <div class="flex flex-col items-start w-full  pl-2  pr-2 ">
      <div
        v-if="properties?.option?.title"
        class=" w-full flex  py-0.5  items-center font-bold justify-start w-full text-sm  text-left text-gray-700  "
      >
        {{ properties?.title }}
      </div>
      <div
        v-if="properties?.option?.description"
        class="mt-2  w-full   text-xs text-left text-gray-500  font-normal"
      >
        {{ properties?.description }}
      </div>

      <div class="mt-2 rounded border-gray-300 w-24 h-24 border-2 flex flex-col items-center justify-center">
        <i class="fa-solid fa-lg fa-camera" />
        <span class="mt-4 text-gray-500 font-medium text-xs">Charger une image</span>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'EditorComponentButton',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {

        },
        methods:
        {
            addItem: function () {
                this.$emit("add", { type: 'avatar', id: this.properties?.id })
            },
            deleteMe: function () {

                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
<template>
  <div
    class=" relative  cursor-grab bg-gray-700 border  list-group mt-2 w-full flex items-start bg-gray-700 border   shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
    :style="getStyle"
    :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>

    <div class="flex flex-col items-start w-full  pl-2 bg-gray-700 ">
      <div
        v-if="element.isTitleDisplayed"
        class="w-full   text-xs text-left text-white"
      >
        {{ element.title }}
      </div>

      <div
        v-if="element.isDescriptionDisplayed"
        class="mt-2  w-full h-6 text-2xs text-left text-gray-100 font-normal"
      >
        {{ element.description }}
      </div>
      <div
        :class="{'top-6' : (! element.isDescriptionDisplayed), 'top-12' : (element.isDescriptionDisplayed)}"
        class="absolute bottom-0 left-0 right-0   font-bold text-lg  text-white  bg-blue-900 overflow-y-auto"
        style="height:calc(100% -60px)"
      >
        <div v-html="element.body" />
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'HtmlTabletFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            getStyle: function () {

         
                if (this.element == undefined) return ""
                if (this.element?.height == "full") return "height:250px"
                return "height:" + (this.element?.height?.replace("px", "") / 2).toString() + "px"
            }
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
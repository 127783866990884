<template>
  <div class="relative mt-24   overflow-y-auto bg-gray-50 lg:ml-12 dark:bg-gray-900">
    <nav
      class="border-b-1 border-b-gray-300 flex p-4"
      aria-label="Breadcrumb"
    >
      <ol class="w-full inline-flex items-center   ">
        <li>
          <div class="flex items-center">
            <h3 class="text-3xl font-bold ml-2">
              Agenda des employés
            </h3>
          </div>
        </li>
      </ol>
      <ol class="w-full text-right justify-end inline-flex items-center  ">
        <li class="cursor-pointer inline-flex items-center">
          <div
            class=" inline-flex items-center text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            @click="onClickBreadCrumb('/')"
          >
            Date :
          </div>
        </li>
        <li class="cursor-pointer inline-flex items-center">
          <div
            class="px-4 inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            @click="onClickBreadCrumb('/')"
          >
            <input
              :id="`date-${id}`"
              v-model="searchRange"
              datepicker
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Date de la journée"
            >
          </div>
        </li>
        <li>
          <div class="flex items-center px-4">
            <button
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickAdd"
            >
              <i class="fa-solid fa-plus  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }}
            </button>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <button
              type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickExport"
            >
              <i class="fa-solid fa-download mr-2 " />
              {{ common_labels['BUTTON_EXPORT'][lg] }}
            </button>
          </div>
        </li>
      </ol>
    </nav>
    <main>
      <div class="flex flex-col mt-2">
        <div class="overflow-x-auto">
          <pro-calendar
            :events="evts"
            :loading="false"
            :config="Configs"
            view="month"
            date="2024-04-08T00:00:00.000Z"
            @calendar-closed="closeCalendar"
            @fetch-events="void 0"
          >
            <template #closeButton>
              <!-- replace close button with this slot -->
            </template>
          </pro-calendar>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
  import labels from "@/assets/lg/common.json"


  // import service from "@/services/FullService.vue"
  /* const heatmapData = [
       {
           lat: 43.6744,
           lng: 7.1949
       },
       {
           lat: 48.8232,
           lng: 2.278
       }];
*/
  import { mapGetters } from "vuex";
  // import "vue-pro-calendar/style";
  export default {

    name : "AgendaView",
    components: {

    },
    props:
    {
      title: String,
      subTitle: String
    },
    data() {

      return {
        id: 100,
        lg: "FR",
        api: null,
        common_labels: labels,
        evts: [{
          date: "2024-04-19T14:00:00.000Z",
          comment: "",
          id: "cl32rbkjk1700101o53e3e3uhn",
          keywords: "Projet BAMBA",
          name: "MONTCHO Kévin",
        },
        {
          date: "2024-04-19T16:00:00.000Z",
          comment: "",
          id: "cl32rbkjk1700101o5se3e3uhn",
          keywords: "Projet BAMBA",
          name: "MONTCHO Kévin",
        }, {
          date: "2024-04-19T16:00:00.000Z",
          comment: "",
          id: "cl32rbkjk1700101o53s3e3uhn",
          keywords: "Projet BAMBA",
          name: "MONTCHO Kévin",
        }],
        Configs: {
          viewEvent: undefined,
          reportEvent: {
            icon: true,
            text: "xxxxxxxxxxxxxxx",
          },
          searchPlaceholder: "sssss",
          eventName: "ss",
          closeText: "",
          todayButton: true,
          firstDayOfWeek: 0,
          nativeDatepicker: false,
        },
        response: [],
        labels: [],
      }
    },


    computed:
    {
      ...mapGetters({ user: "StateUser" }),
    },
    watch: {
      'user.tenant_id': async function () {
        let response = await this.getPoints()
        this.heatmapData = response

      }
    },
    mounted: async function () {


      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login"
        return;
      }


      this.$nextTick(function () {

      });
    },

    methods:
    {
      closeCalendar: function () {
        alert("close")
      }
    },



  };
</script>
<template>
  <div class="w-full text-left text-semibold">
    <div>
      <label
        for="small-input"
        class="block mb-2 text-sm  font-semibold text-gray-900 dark:text-white"
      >X
        Axis</label>


 
      <draggable
        ghost-class="ghost"
        group="people"
        :component-data="getComponentData()"
        class="mb-2 min-h-8 h-fit overflow-y-auto flex items-start justify-center   cursor-pointer  w-full border rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500     "
        :class="{ 'bg-green-100  text-green-700  border-green-400   ' : isEmpty, 'bg-white border border-gray-200   hover:bg-gray-100  ' : !isEmpty}"
        :list="items"
        item-key="id"
        @change="log"
        @start="dragging = true"
        @move="dragging = true"
        @dragover="dragging = true"
        @dragenter2="dragging = false"
        @dragleave2="dragging = false"
        @end="dragging = false"
      >
        <template #footer>
          <div>
            <div
              v-if2=" ((items.length == 0) && (dragging == false)) || ((items.length > 0) && (dragging != false))"
              class="p-2"
              :class="{'hidden': !isEmpty}"
            >
              + Category
            </div>
          </div>
        </template>
        <template #item="{element }">
          <div>
            <div
              v-if="dragging != true"
              class="  w-full"
            >
              <button
                v-if="element.type != 'string'"
                type="button"
                class="w-full flex flex-row items-center justify-start         rounded-lg text-xs    "
              >
                <div class="w-4 text-gray-500 ml-2">
                  <i
                    v-if="element.type=='string'"
                    class="fa-solid fa-font"
                  />
                  <i
                    v-if="element.type=='numeric'"
                    class="fa-solid fa-hashtag"
                  />
                  <i
                    v-if="element.type=='object'"
                    class="fa-solid fa-caret-right"
                  />
                  <i
                    v-if="element.type=='geo'"
                    class="fa-solid fa-earth-americas"
                  />
                </div>
                <div class="ml-2 text-gray-900 font-semibold text-left text-xs w-full" />
              </button>
              <PropertiesXAxisString
                v-if="element.type == 'string'"
                class2="bg-red-300"
                @delete="onXAxisDelete"
              />
              <PropertiesXAxisTimestamp class="hidden" />
            </div>
          </div>
        </template>
      </draggable>




      <PropertiesXAxisTimestamp class="hidden" />

      <PropertiesXAxisNumber class="hidden" />
    </div>
    <div class="hidden">
      <label
        for="small-input"
        class="block mb-2 text-sm  font-semibold text-gray-900 dark:text-white mt-2"
      >Y
        Axis</label>
      <PropertiesYAxisString />
      <PropertiesYAxisNumber />
      <PropertiesYAxisTimestamp />
      <input
        id="small-input"
        type="text"
        class="hidden block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
    </div>
  </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import PropertiesYAxisString from "@/pages/ChartEditorView/PropertiesYAxisString.vue"

    import PropertiesYAxisNumber from "@/pages/ChartEditorView/PropertiesYAxisNumber.vue"



    import PropertiesXAxisString from "@/pages/ChartEditorView/PropertiesXAxisString.vue"
    import PropertiesXAxisNumber from "@/pages/ChartEditorView/PropertiesXAxisNumber.vue"

    import PropertiesXAxisTimestamp from "@/pages/ChartEditorView/PropertiesXAxisTimestamp.vue"

    import PropertiesYAxisTimestamp from "@/pages/ChartEditorView/PropertiesYAxisTimestamp.vue"
    export default {
        name: 'StackedColumn',
        components: { draggable, PropertiesXAxisString, PropertiesYAxisString, PropertiesXAxisTimestamp, PropertiesYAxisTimestamp, PropertiesYAxisNumber, PropertiesXAxisNumber },
        props: {

        },
        data() {
            return {
                activeNames: null,
                title: "+ Category",
                items: [],
                dragging: false
            }
        },
        computed: {
            draggingInfo() {
                return this.dragging ? "under drag" : "not";
            },
            isEmpty() {
                return (this.items.length < 1) && (this.dragging == false)
            }

        },
        methods:
        {
            onXAxisDelete() {
                this.items = []
            },

            log: function (evt) {
                window.console.log(evt);
                if (evt?.added) {

                    let a = []
                    a.push(evt.added.element)
                    this.items = a
                    this.dragging = false;

                    this.$emit("addAxisX", this.items)
                }

            },
            handleChange() {
                console.log('changed');
            },
            inputChanged(value) {
                console.log('inputChanged', value);
            },

            getComponentData() {
                return {
                    onChange: this.handleChange,
                    onInput: this.inputChanged,
                    wrap: true,
                    value: this.activeNames
                };
            }
        },
    }
</script>
<style scoped>
    .drop-zone {
        background-color: #eee;
        margin-bottom: 10px;
        padding: 10px;
    }

    .drag-el {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 5px;
    }
</style>
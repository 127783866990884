<template>
  <div
    id="edit-diagram-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-6xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-[calc(100%-0.1rem)]">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Diagramme : {{ input?.title }} 
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="onClose"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentTitleColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="px-4 md:px-5 flex flex-col">
          <form @submit.prevent="closeDialog">
            <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
              <ul
                id="default-styled-tab"
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#default-styled-tab-content"
                data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500"
                data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
                role="tablist"
              >
                <li
                  class="me-2"
                  role="presentation"
                >
                  <button
                    id="profile-styled-tab"
                    class="inline-block p-4 border-b-2 rounded-t-lg"
                    data-tabs-target="#styled-profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Profile
                  </button>
                </li>
                <li
                  class="me-2"
                  role="presentation"
                >
                  <button
                    id="dashboard-filters-tab"
                    class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    data-tabs-target="#dashboard-filters"
                    type="button"
                    role="tab"
                    aria-controls="filters"
                    aria-selected="false"
                  >
                    Filtres
                  </button>
                </li>
                <li
                  class="me-2"
                  role="presentation"
                >
                  <button
                    id="dashboard-series-tab"
                    class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    data-tabs-target="#dashboard-series"
                    type="button"
                    role="tab"
                    aria-controls="series"
                    aria-selected="false"
                  >
                    Séries
                  </button>
                </li>
                <li role="presentation">
                  <button
                    id="dashboard-theme-tab"
                    class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    data-tabs-target="#dashboard-theme"
                    type="button"
                    role="tab"
                    aria-controls="theme"
                    aria-selected="false"
                  >
                    Théme
                  </button>
                </li>
              </ul>
            </div>
            <div id="default-styled-tab-content ">
              <div
                id="styled-profile"
                class=" hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <OverviewEditDiagramModal :input="input" />
              </div>
              <div
                id="dashboard-filters"
                class=" hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="dashboard-filters-tab"
              >
                <FiltersEditDiagramModal :input="input" />
              </div>
              <div
                id="dashboard-series"
                class=" hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="dashboard-series-tab"
              >
                <SeriesEditDiagramModal :input="input" />
              </div>
              <div
                id="dashboard-theme"
                class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="dashboard-theme-tab"
              >
                <ThemeEditDiagramModal :input="input" />
              </div>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div
          class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 justify-end"
        >
          <button
            type="button"
            class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border-0 border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            @click="onClose"
          >
            Annuler
          </button>
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="onValidate"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { Dropdown } from 'flowbite';


    import OverviewEditDiagramModal from "@/components/modals/EditDiagramModal/OverviewEditDiagramModal.vue"
   
    import ThemeEditDiagramModal from "@/components/modals/EditDiagramModal/ThemeEditDiagramModal.vue"
    import SeriesEditDiagramModal from "@/components/modals/EditDiagramModal/SeriesEditDiagramModal.vue"
  
    import FiltersEditDiagramModal from "@/components/modals/EditDiagramModal/FiltersEditDiagramModal.vue"
    ///import service from "@/services/FullService.vue"
    //// import labels from "@/assets/lg/product-types.json"
    //import { mapGetters } from "vuex";
    export default {
        name: 'EditDiagramModal',
        components: {
            FiltersEditDiagramModal, ThemeEditDiagramModal,SeriesEditDiagramModal,OverviewEditDiagramModal
        },
        props: {
            input: Object
        },
        data() {
            return ({
                lg: "FR",



                dropdownTitleBg: null,
                dropdownChartBg: null,
                dropdownBg: null,
                currentTitleBgColor: "",
                currentChartBgColor: "",
                currentTitleColor: "",
                currentBgColor: "",
                layout: "",
                output: {},
                colors: ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'],
                variants: [100, 200, 300, 400, 500, 600, 700, 800, 900],
            })
        },
        //    computed: {
        //        ...mapGetters({ user: "StateUser" }),
        //        isLoggedIn: function () {
        //            return this.$store.getters.isAuthenticated;
        //        },
        //    },

        watch:
        {
            'input': function () {
                if (this.input != undefined)
                    this.output = this.input

                this.currentTitleColor = this.findTextColor(this.input?.class_title)
                this.currentTitleBgColor = this.findBgColor(this.input?.class_title)

                this.currentChartBgColor = this.findBgColor(this.input?.class_chart)

                this.currentBgColor = this.findBgColor(this.input?.class_container)
                this.layout = this.findLayout(this.input?.class)

            }
        },
        mounted() {

            if (this.input != undefined) {
                this.output = this.input
                this.currentTitleColor = this.findTextColor(this.input?.class_title)
                this.currentTitleBgColor = this.findBgColor(this.input?.class_title)

                this.currentChartBgColor = this.findBgColor(this.input?.class_chart)

                this.currentBgColor = this.findBgColor(this.input?.class_container)
                this.layout = this.findLayout(this.input?.class)
            }

        },
        methods: {
            onValidate() {

                this.output.class = this.buildClass();

                this.output.class_title = this.buildTitleClass();
                this.output.class_chart = this.buildChartClass();
                this.output.class_container = this.buildContainerClass();
                this.$emit("validate", this.output)
            },
            onClose() {
                this.$emit("close")
            },
            selectColor: function (color, variant) {
                if (color == "black") {
                    this.currentTitleColor = color;
                }
                else if (color == "white") {
                    this.currentTitleColor = color;
                }
                else
                    this.currentTitleColor = color + "-" + variant;
                this.dropdown.hide()
            },
            selectBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentBgColor = color;
                }
                else if (color == "white") {
                    this.currentBgColor = color;
                }
                else this.currentBgColor = color + "-" + variant;
                this.dropdownBg.hide()
            },
            selectChartBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentChartBgColor = color;
                }
                else if (color == "white") {
                    this.currentChartBgColor = color;
                }
                else this.currentChartBgColor = color + "-" + variant;
                this.dropdownChartBg.hide()
            },
            selectTitleBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentTitleBgColor = color;
                }
                else if (color == "white") {
                    this.currentTitleBgColor = color;
                }
                else this.currentTitleBgColor = color + "-" + variant;
                this.dropdownTitleBg.hide()
            },
            onInputClick: function () {

                const $targetEl = document.getElementById("dropdown-input-color");
                const $triggerEl = document.getElementById("color-picker");

                if ($triggerEl != undefined) {
                    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdown.show()
            },
            onInputBgClick: function () {

                const $targetEl = document.getElementById("dropdown-input-bg-color");
                const $triggerEl = document.getElementById("bg-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownBg.show()
            },
            onInputChartBgClick: function () {

                const $targetEl = document.getElementById("dropdown-input-bg-chart-color");
                const $triggerEl = document.getElementById("bg-chart-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownChartBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownChartBg.show()
            },
            onInputTitleBgClick: function () {

                const $targetEl = document.getElementById("dropdown-input-bg-title-color");
                const $triggerEl = document.getElementById("bg-title-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownTitleBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownTitleBg.show()
            },
            findTextColor: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("bg-") != -1) {
                    if (item.indexOf("text-gray") != -1) {
                        return this.findColorVariant(item, "text-gray", "gray");
                    }
                    if (item.indexOf("text-red") != -1) {
                        return this.findColorVariant(item, "text-red", "red");
                    }
                    if (item.indexOf("text-yellow") != -1) {
                        return this.findColorVariant(item, "text-yellow", "yellow");
                    }

                    if (item.indexOf("text-green") != -1) {
                        return this.findColorVariant(item, "text-green", "green");
                    }
                    if (item.indexOf("text-blue") != -1) {
                        return this.findColorVariant(item, "text-blue", "blue");
                    }
                    if (item.indexOf("text-indigo") != -1) {
                        return this.findColorVariant(item, "text-indigo", "indigo");
                    }
                    if (item.indexOf("text-purple") != -1) {
                        return this.findColorVariant(item, "text-purple", "purple");
                    }
                    if (item.indexOf("text-pink") != -1) {
                        return this.findColorVariant(item, "text-pink", "pink");
                    }
                    if (item.indexOf("text-white") != -1) {
                        return "white";
                    }
                    if (item.indexOf("text-black") != -1) {
                        return "black";
                    }
                    return "gray-900"
                }

                return "gray-900"
            },
            findColorVariant(item, tag, color) {
                if (item == undefined) return "";
                if (item.indexOf(tag + "-100") != -1) {
                    return color + "-100"
                }
                if (item.indexOf(tag + "-200") != -1) {
                    return color + "-200"
                }
                if (item.indexOf(tag + "-300") != -1) {
                    return color + "-300"
                }
                if (item.indexOf(tag + "-400") != -1) {
                    return color + "-400"
                }
                if (item.indexOf(tag + "-500") != -1) {
                    return color + "-500"
                }
                if (item.indexOf(tag + "-600") != -1) {
                    return color + "-600"
                }
                if (item.indexOf(tag + "-700") != -1) {
                    return color + "-700"
                }
                if (item.indexOf(tag + "-800") != -1) {
                    return color + "-800"
                }
                if (item.indexOf(tag + "-900") != -1) {
                    return color + "-900"
                }
                if (item.indexOf(tag) != -1) {
                    return color
                }
                return ""
            },
            findBgColor: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("bg-") != -1) {
                    if (item.indexOf("bg-gray") != -1) {
                        return this.findColorVariant(item, "bg-gray", "gray");
                    }
                    if (item.indexOf("bg-red") != -1) {
                        return this.findColorVariant(item, "bg-red", "red");
                    }
                    if (item.indexOf("bg-yellow") != -1) {
                        return this.findColorVariant(item, "bg-yellow", "yellow");
                    }

                    if (item.indexOf("bg-green") != -1) {
                        return this.findColorVariant(item, "bg-green", "green");
                    }
                    if (item.indexOf("bg-blue") != -1) {
                        return this.findColorVariant(item, "bg-blue", "blue");
                    }
                    if (item.indexOf("bg-indigo") != -1) {
                        return this.findColorVariant(item, "bg-indigo", "indigo");
                    }
                    if (item.indexOf("bg-purple") != -1) {
                        return this.findColorVariant(item, "bg-purple", "purple");
                    }
                    if (item.indexOf("bg-pink") != -1) {
                        return this.findColorVariant(item, "bg-pink", "pink");
                    }
                    if (item.indexOf("bg-white") != -1) {
                        return "white";
                    }
                    if (item.indexOf("bg-black") != -1) {
                        return "black";
                    }
                    return "white"
                }

                return "white"
            },
            findLayout: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("w-1/12") != -1) return "w-1/12";
                if (item.indexOf("w-2/12") != -1) return "w-2/12";
                if (item.indexOf("w-3/12") != -1) return "w-3/12";
                if (item.indexOf("w-4/12") != -1) return "w-4/12";
                if (item.indexOf("w-5/12") != -1) return "w-5/12";
                if (item.indexOf("w-6/12") != -1) return "w-6/12";
                if (item.indexOf("w-7/12") != -1) return "w-7/12";
                if (item.indexOf("w-8/12") != -1) return "w-8/12";
                if (item.indexOf("w-9/12") != -1) return "w-9/12";
                if (item.indexOf("w-10/12") != -1) return "w-10/12";
                if (item.indexOf("w-11/12") != -1) return "w-11/12";
                if (item.indexOf("w-12/12") != -1) return "w-full";
                if (item.indexOf("w-full") != -1) return "w-full";
                return "w-full";
            },
            buildClass() {
                return this.layout;
            },
            buildContainerClass() {
                return  " bg-" + this.currentBgColor;
            },
            buildChartClass() {
                return " bg-" + this.currentChartBgColor;
            },
            buildTitleClass() {
                return " bg-" + this.currentTitleBgColor + " text-" + this.currentTitleColor;
            },
        },
    }
</script>
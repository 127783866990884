<template>
  <aside
    id="sidebar-properties-editor"
    class="top-0 bottom-0 z-20 fixed   right-0 overflow-y-hidden  flex transition-transform  overflow-y-hidden  pt-12"
    aria-label="Sidebar"
  >
    <div
      id="secondary-sidenav"
      class="   relative  w-96       border-l border-gray-200 "
    >
      <div class="  h-20 flex items-center justify-center px-2 bg-gray-700  ">
        <span class="font-bold text-xl text-white">
          Propriétés
          <div class="hidden">{{ type }} // {{ selectedSection?.type }}  </div> 
        </span>
      </div>

      <div class="  h-20 border-b border-gray-200 dark:border-gray-700">
        <ul
          id="default-styled-tab"
          class="flex flex-wrap -mb-px text-sm font-medium text-center"
          role="tablist"
        >
          <li
            class="me-2"
            role="presentation"
          >
            <button
              id="editor-properties-general"
              :class="{'cursor-not-allowed ' : selectedSection == undefined,
                    
                       'text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500' : tab == 0,
                       'dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300' : tab != 0
                     
              }"
              class="inline-block p-4 border-b-2 rounded-t-lg"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
              @click="onSelectTab(0)"
            >
              Propriétés
            </button>
          </li>
          <li
            class="me-2"
            role="presentation"
          >
            <button
              id="dashboard-styled-tab"
              :class="{'cursor-not-allowed ' : selectedSection == undefined,
                    
                       'text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500' : tab == 1,
                       'dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300' : tab != 1
                        
                    
              }"
              class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              type="button"
              role="tab"
              aria-selected="false"
              @click="onSelectTab(1)"
            >
              Contôles
            </button>
          </li>
          <li
            class="me-2"
            role="presentation"
          >
            <button
              id="settings-styled-tab"
              :class="{'cursor-not-allowed ' : selectedSection == undefined,
                    
                       'text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500' : tab == 2,
                       'dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300' : tab != 2
                        
              }"
              class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              type="button"
              role="tab"
              aria-selected="false"
              @click="onSelectTab(2)"
            >
              Evénements
            </button>
          </li>
          <li
            role="presentation"
            class="hidden"
          >
            <button
              id="contacts-styled-tab"
              :class="{'cursor-not-allowed ' : selectedSection == undefined}"
              class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              data-tabs-target="#styled-contacts"
              type="button"
              role="tab"
              aria-controls="contacts"
              aria-selected="false"
            >
              Théme
            </button>
          </li>
        </ul>
      </div>



      <div class="pb-48 h-full      ">
        <div class="  h-full min-h-full  overflow-y-scroll  flex flex-col  ">
          <div class="hidden">
            <a
              href="#"
              class="flex font-bold text-sm  items-center p-2 text-base   text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <span class="ml-6">Header</span>
            </a>
          </div>
 
          <component
            :is="mapComponent(type, action)"
            id="section.id"
            class="w-full"
            :properties="selectedSection"
            :record="record"
            @update-field="onUpdateAttribute"
          />
        </div>
      </div>
      <div
        class="absolute bg-white overflow-x-hidden left-0 right-0 bottom-0 text-red-600  border-t border-gray-300 h-8 pb-1.5 font-normal text-sm flex justify-center items-center"
      >
        <div
          v-if="type != 'none'"
          class="py-1 px-6 flex cursor-pointer items-center"
          @click="onDeleteSection"
        >
          <i class="  fa-solid     fa-trash    mr-2 " />
          <span>Supprimer l'élement</span>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
    import { mapGetters } from "vuex";

    import propertiesTitle from '@/layout/Editor/Properties/propertiesTitle.vue'
    import propertiesEdit from '@/layout/Editor/Properties/propertiesEdit.vue'
    import propertiesDate from '@/layout/Editor/Properties/propertiesDate.vue'
    import propertiesAvatar from '@/layout/Editor/Properties/propertiesAvatar.vue'
    import propertiesSeparator from '@/layout/Editor/Properties/propertiesSeparator.vue'
    import propertiesRadio from '@/layout/Editor/Properties/propertiesRadio.vue'
    import propertiesScanCode from '@/layout/Editor/Properties/propertiesScanCode.vue'
    import propertiesScanDoc from '@/layout/Editor/Properties/propertiesScanDoc.vue'
    import propertiesNumber from '@/layout/Editor/Properties/propertiesNumber.vue'
    import propertiesNone from '@/layout/Editor/Properties/propertiesNone.vue'
    import propertiesSignature from '@/layout/Editor/Properties/propertiesSignature.vue'
    import propertiesHTML from '@/layout/Editor/Properties/propertiesHtml.vue'
    import propertiesImage from '@/layout/Editor/Properties/propertiesImage.vue'
    import propertiesValidate from '@/layout/Editor/Properties/propertiesTitle.vue'
    import propertiesControls from '@/layout/Editor/Properties/propertiesControls.vue'
    import propertiesTiggers from '@/layout/Editor/Properties/propertiesTriggers.vue'
    import propertiesButton from '@/layout/Editor/Properties/propertiesButton.vue'
    import propertiesTakePhoto from '@/layout/Editor/Properties/propertiesTakePhoto.vue'
    
    import propertiesGrid from '@/layout/Editor/Properties/propertiesGrid.vue'
    import propertiesPrint from '@/layout/Editor/Properties/propertiesPrint.vue'
 
    import propertiesRow from '@/layout/Editor/Properties/propertiesRow.vue'
    import propertiesList from '@/layout/Editor/Properties/propertiesList.vue'

    export default {
        name: 'SideNavEditor',
        props: {
            selectedSection: Object,
            sections : Array,
            zone: String,
            area: String,
            record: Object
        },
        methods: {

            onUpdateAttribute: function (event) {
                console.log("SideRightNavEditor - onUpdateAttribute")
                console.log(event)
                console.log(typeof event)
                if (event.id != undefined) {
                    console.log(JSON.stringify(event))
                    this.$emit('updateSection', event)

                }
            },
            onDeleteSection: function () {
                this.$emit("deleteSection", this.selectedSection)
            },

            onSelectTab: function (index) {
                console.log("onSelectTab")
                this.tab = index

                if (index == 0) {
                    this.type = this.selectedSection?.type
                }

                if (index == 1) {
                    this.type = "controls"
                }
                if (index == 2) {
                    this.type = "triggers"
                }
            },
            mapComponent: function (type, action) {
                if (type == 'title') return propertiesTitle;
                if (type == 'radio') return propertiesRadio;
                if (type == 'number') return propertiesNumber;

                if (type == 'avatar') return propertiesAvatar;
                if (type == 'date') return propertiesDate;
                if (type == 'scan-code') return propertiesScanCode


                if (type == 'scan-qrcode') return propertiesScanCode
                if (type == 'scan-barcode') return propertiesScanCode


                if (type == 'scan-doc') return propertiesScanDoc
                if (type == 'button') {

                    if (action == "take-photo") {
                        return propertiesTakePhoto
                    }
                    return propertiesButton

                }
                if (type == 'validate') return propertiesButton
                if (type == 'html') return propertiesHTML;

                if (type == 'image') return propertiesImage;

                if (type == 'hand-signature') return propertiesSignature;

                if (type == 'separator') return propertiesSeparator;
                if (type == 'validate') return propertiesValidate;
                if (type == 'edit') return propertiesEdit;

                if (type == 'controls') return propertiesControls;
                if (type == 'triggers') return propertiesTiggers;

                if (type == 'none') return propertiesNone;
                if (type == 'list') return  propertiesList
                if (type == 'row') return  propertiesRow


                if (type == 'print') return propertiesPrint;
                if (type == 'grid') return propertiesGrid;




                return propertiesNone;

            },

        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch: {
            'selectedSection': function () {

                console.log("Watch selectedSection - RIGHT Side")
                console.log(this.properties)
                if (this.selectedSection?.type == undefined) this.type = "none"
                else
                    this.type = this.selectedSection?.type

                this.action = this.selectedSection?.action
                this.tab = 0

            },
            'record': function () {

                console.log("Watch record - RIGHT Side")


            }

        },
        mounted() { },
        components: {
            propertiesTitle,
            propertiesEdit,
            propertiesDate,
            propertiesAvatar,
            propertiesSeparator,
            propertiesRadio,
            propertiesValidate,
            propertiesControls,
            propertiesTiggers,
            propertiesSignature,
            propertiesHTML,
            propertiesImage,
            propertiesScanCode,
            propertiesScanDoc,
            propertiesNumber,
            propertiesNone,
            propertiesButton,
            propertiesTakePhoto,
            propertiesList,
            propertiesRow,
            propertiesGrid,
            propertiesPrint
        },
        data() {
            return {
                lg: "FR",
                tab: 0,
                type: "none",
                format: "",
                sideNav: [],
                subitems: [],
                currentitem: {},
                loading2: false,
                mode: 'closed',
                config: {},
            }
        }
    }

</script>
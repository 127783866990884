<template>
  <div id="move-wild-game-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full   max-w-2xl  ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  flex flex-col  items-stretch   ">
        <!-- Modal header -->
        <div
          class="  px-4 flex items-center justify-between pb-1 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700">
          <h3 class="text-left left font-semibold text-gray-900 text-lg dark:text-white">

            <h5 class="font-semibold text-gray-900 text-lg dark:text-white">
              <span class="">Gibier</span> <span class="italic text-gray-700">{{input.name}} - {{input.number}}</span>
            </h5>
            <h5 class="  font-semibold text-gray-900 text-lg dark:text-white">
              <span class=" ">Lot actuel :</span> <span class="italic text-gray-700">{{input.source}}</span>
            </h5>
          </h3>

          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>




        <div class=" flex items-stretch bg-white rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4">


          <div class="w-full  h-full">
            <p class="text-base font-bold text-gray-500 dark:text-gray-400 mb-4">
              Veuillez choisir le nouveau lot du Gibier
            </p>
            <form class="flex items-center max-w-sm mx-auto">
              <label for="simple-search" class="sr-only">Search</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2" />
                  </svg>
                </div>
                <input @keyup="onChange" v-model="query" type="text" id="simple-search"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Rechercher par numéro, nom ou reference du chasseur" required />
              </div>

            </form>

            <div class="w-full bg-orange-200  mx-2 text-left justify-left relative mt-3">
              <div
                class="flex flex-col w-full pr-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white  h-96 overflow-y-auto">
                <button v-for="item in filtered_items" :key="item.id" type="button" @click="onSelect(item)"
                  class="flex flex-row justify-left items-center w-full px-4 py-2 font-medium text-left rtl:text-right border-b border-gray-200 cursor-pointer hover:bg-blue-500 hover:text-white focus:outline-none  focus:font-bold focus:ring-2 focus:ring-blue-700 focus:text-white focus:bg-blue-700  dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                  <div class="left text-left w-36 justify-left truncate">
                    {{item.received_at}}
                  </div>
                  <div class="left text-left  w-36  justify-left truncate">
                    {{item.name}}
                  </div>
                  <div class="right truncate w-24">
                    {{item.hunter_number}}
                  </div>
                  <div class="right truncate">
                    {{item.hunter_name}}
                  </div>

                </button>
              </div>
            </div>
          </div>
        </div>


        <div class="flex items-center justify-end px-4  ">
          <button type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click="onClose()">
            Annuler
          </button>
          <button type="button"
            class="inline-flex items-center text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
          :disabled="selected==undefined"
          :class="{' bg-gray-600 hover:bg-gray-700':selected != undefined,' bg-gray-400 hover:bg-gray-400': (selected == undefined)}"


            @click="onValidate">
            Déplacer
          </button>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
  import labels from "@/assets/lg/attribut.json"
  import service from "@/services/FullService.vue"



  import { mapGetters } from "vuex";


  export default {
    name: 'WildGameMoveModal',
    props: {
      input: Object
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    data() {
      return ({
        lg: "FR",
        labels: labels,
        price: "",
        mode: "",
        id: null,
        items: [],
        selected: null,
        filtered_items: [],
        query: ""
      })
    },
    watch: {
      'input': async function () {
        this.id = this.input?.id
        this.source = this.input?.source

        this.loadBatches()

      }
    },
    async mounted() {
      this.id = this.input?.id
      this.source = this.input?.source
    },

    methods: {

      onChange() {
        //filtered_items

        let q = this.query
        this.filtered_items = this.items.filter(function (a) {

          return ((a.hunter_number.indexOf(q) != -1) || (a.hunter_name.indexOf(q) != -1)|| (a.name.indexOf(q) != -1))

        })
      },

      displayDate(d) {
        if (d == undefined) return ""
        let s = d.split("T")

        let ss = s[0].split("-")

        return ss[2] + "/" + ss[1] + "/" + ss[0]
      },
      async loadBatches() {
        let id = "661bf932f79d5093e35014f2"
        let records = await service.getData("data-sources/" + id + '/records', this.user?.token, Object.assign({ limit: 50, offset: 0, entity_id: this.input.entity_id }))

        let items = []
        console.log(JSON.stringify(records))
        for (let i = 0; i < records.records.length; i++) {
          if (this.input.source != records.records[i].delivery_number)
            items.push({ id: records.records[i]._id, name: records.records[i].delivery_number, received_at: this.displayDate(records.records[i].received_at), hunter_name: records.records[i]?.company_name, hunter_number: records?.records[i]?.company_reference  })
        }
        this.items = items
        this.filtered_items = items
        this.query = ""
      },
      onSelect(id) {
        this.selected = id
      },

      onValidate() {

        let item =
        {
          id: this.input.id,
     
          source: { id: this.input.record_id},
          target: this.selected

        }
        
        this.$emit("confirm", item)
        // this.name = ""
      },
      onClose() {
        this.$emit("close")

      }
    },
    components: {

    },
  }
</script>
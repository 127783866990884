<template>
  <div class="list-group mt-3 mb-3 w-full flex items-start flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full  pl-2  pr-2 ">
      <div
        v-if="properties?.option?.title"
        class="w-full  text-gray-700  text-sm text-left  font-bold"
      >
        {{ properties?.title }}
      </div>

      <div
        v-if="properties?.option?.description"
        class="mt-2  w-full h-6 text-xs text-left text-gray-100 font-normal text-gray-500 "
      >
        {{ properties?.description }}
      </div>
      <div
        class="  font-bold text-lg  text-white  bg-blue-900 overflow-y-auto w-full"
        :style="getStyle"
      >
        <div
          class="hidden w-full flex"
          v-html="properties?.body"
        />
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'HtmlTabletFormComponent',
        props: {
            properties: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            getStyle: function () {


                if (this.properties == undefined) return ""
                if (this.properties?.height == "full") return "height:250px";

                return "height:" +  this.properties?.height 
            }
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.properties)
            }
        },
    }
</script>
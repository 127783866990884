<template>
  <div class="mt-2 mb-2 w-full relative">
    <div
      v-if="value=='OK'"
      class="w-full inline-flex justify-start  items-center"
    >
      <div
        class="w-full  flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
        role="alert"
      >
        <svg
          class="flex-shrink-0 inline w-4 h-4 mr-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span class="sr-only">Info</span>
        <div>
          <span
            class="font-medium"
            v-html="messages.OK.FR"
          />  
        </div>
      </div>
    </div>
    <div
      v-if="value=='KO'"
      class="w-full flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <svg
        class="flex-shrink-0 inline w-4 h-4 mr-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span
          class="font-medium"
          v-html="messages.NOK.FR"
        />  
      </div>
    </div>
    <div
      v-if="value=='WARNING'"
      class="w-full flex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
      role="alert"
    >
      <svg
        class="flex-shrink-0 inline w-4 h-4 mr-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only">Information</span>
      <div>
        <span
          class="font-medium"
          v-html="messages.WARNING.FR"
        />  
      </div>
    </div>
    <div
      v-if="comment != undefined && comment!=''"
      class="w-full flex items-center w-full   p-4 space-x-4 text-gray-500 bg-gray-50 divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
      role="alert"
    >
      <i class="fa-solid fa-triangle-exclamation" />
      <div class="pl-4 text-sm font-normal">
        {{ comment }}
      </div>
    </div>
  </div>
</template>
<script>
    import common_labels from "@/assets/lg/common.json"
    export default {
        name: 'ContentInput',
        props: {
            id: String,
            label: String,
            messages: Object,
            value: String,
            comment: String,
            config:Object

        },
        data() {
            return {
                output: "",
                lg: "FR",
                common_labels: common_labels
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        },
        mounted() {
            this.output = this.value;
        },
        methods: {
        
            getData() {
                return this.output
            }
        }
    }
</script>
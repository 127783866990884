<template>
  <div class="  h-30 mt-2 w-96 px-2">
    <div class="w-full    ">
      <div
        class="cursor-pointer h-10 w-full bg-transparent border-gray-100  border border-dotted  text-gray-100  hover:text-gray-700  hover:bg-gray-100 w-full flex justify-center items-center "
        @click="addItem"
      >
        <i class="fa-solid fa-add text-gray-100 cursor-pointer" /> <span class="ml-2">Ajouter</span>
      </div>
    </div>

    <div
      class="  relative h-20 mb-6 w-full flex flex-col items-start bg-gray-700 cursor-grab border dark:bg-gray-700 dark:border-gray-700   flex flex-row justify-center  "
      :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
      @click="selectItem"
      @mouseover.stop="showheader=true;"
      @mouseleave.stop="showheader=false;"
    >
      <div
        class="flex  px-3 items-center w-full h-6 text-xs text-left text-white  "
            
        :class="{'justify-end': (element.alignment=='end'), 'justify-start': (element.alignment=='start'), 'justify-center': (element.alignment=='center') }"
      >
        <button
          class=" flex items-center text-white inline-flex w-36 justify-center bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <i :class="`fa-solid fa-${element.prefix} mr-2`" />
          <span> {{ element.title }}</span>
          <i :class="`fa-solid fa-${element.suffix} ml-2`" />
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ValidateTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return { showheader: false }
        },
        computed:
        {

        },
        methods:
        {

            addItem : function () {
               
                this.$emit("addItem", -1)
            },

            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
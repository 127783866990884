<template>
  <div class="bg-gray-100 w-full p-6">
    <div class="mb-6 flex flex-col items-start justify-start">
      <div class="mb-6 flex flex-col items-start justify-start w-full">
        <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">

          Titre du Tableau


        </label>

        <div
          v-if="currentItem.label != undefined"
          class="flex flex-col items-center   justify-start"
        >
          <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
            <div
              class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
            >
              <div class="text-sm  text-gray-50">
                FR
              </div>
            </div>
            <input
              v-model="currentItem.label.FR"
              class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
            >
          </div>
          <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
            <div
              class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
            >
              <div class="text-sm  text-gray-50">
                EN
              </div>
            </div>
            <input
              v-model="currentItem.label.EN"
              class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
            >
          </div>
          <div class="flex justify-start flex-row w-full mt-2 ml-6 items-center">
            <input
              id="checked-checkbox"
              v-model="currentItem.label.displayTitle"
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="checked-checkbox"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Afficher le
              titre</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'DeveloperItemTable',
        props: {
            item: Object
        },
        data() {
            return ({
                currentItem: {},
                collections: [],
                schemas: [],
                filter: "",
                lg: "FR"
            })
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        watch: {
            item: function (val) {
                let item = val;
                if (item.label == undefined) item.label = {}
                if (item.label.FR == undefined) item.label.FR = ""
                if (item.label.EN == undefined) item.label.EN = ""
                if (item.label.displayTitle == undefined) item.label.displayTitle = true
             
             
                this.currentItem = item;
                if (this.c != undefined)
                    this.filter = JSON.stringify(this.currentItem.filter, null, "\t")
            }
        },



        async mounted() {

            let item = this.item;

            if (item.label == undefined) item.label = {}
            if (item.label.FR == undefined) item.label.FR = ""
            if (item.label.EN == undefined) item.label.EN = ""
            if (item.label.displayTitle == undefined) item.label.displayTitle = true



            this.currentItem = item;
            if (this.currentItem.filter != undefined)
                this.filter = JSON.stringify(this.currentItem.filter, null, "\t")


            let responseCollection = await service.getData("select-values", this.user?.token, { category: "value-collection" })
            if (responseCollection != undefined)
                this.collections = responseCollection.records;


            let responseTableDefinition = await service.getData("ui-lists", this.user?.token, { embedded: "YES" })
            if (responseTableDefinition != undefined)
                this.schemas = responseTableDefinition.records;

        }

    }
</script>
<template>
  <div class="mt-2 mb-1 w-full relative ">
    <div class="flex">
      <div class="relative w-full flex ">
        <button
          type="button"
          class="ml-6  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          @click="onClick"
        >
          <i
            class="fa-solid  mr-2"
            :class="icon_button"
          />

          {{ label }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
    import common_labels from "@/assets/lg/common.json"


    export default {
        name: 'ContentButton',
        components: {},
        props: {
            id: String,
            label: String,
            index: String,
            required: Boolean,
            config: {},
        },
        emits: ["updateField", "valueChanged", "manageField"],
        data() {
            return {
                output: "",
                lg: "FR",
                labels: common_labels,
                common_labels: common_labels,
            }
        },

        computed:
        {
            'icon_button': function () { return  this.config?.icon ? this.config?.icon : 'fa-plus ' }
        },
        watch: {
            value: async function (val) {
                if (val != undefined) {
                    this.output = this.value;
                }
            }
        },
        async mounted() {
            this.output = this.value
        },

        methods: {
            onClick : function()
            {
                
                let event = {
                    type: this.config?.action,
                    email: ''
                }
                this.$emit("action", event)
            }
           
        }
    }
</script>
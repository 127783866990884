<template>
  <div class=" mt-2 mb-2 w-full relative">
    <label
      class="flex flex-row font-bold text-left ml-4 px-2.5 text-left block mb-2 text-lg   text-gray-900 dark:text-white"
    >
      <div class="hidden w-full">{{ labels['LABEL_INGREDIENT_TITLE'][lg] }}</div>
            
    </label>

        
    <div>
      <div class="w-full flex  ">
        <div class="w-6/12    ">
          <label class=" w-full  font-bold  ">
            {{ labels['LABEL_INGREDIENT_TITLE'][lg] }}
          </label>

          <draggable
            ghost-class="ghost"
            class="mt-4 w-full h-96 min-h-96 border-1 border-solid items-start flex flex-col bg-white mb-3 border-gray-300 p-6"
            class2="list-group mt-2 w-full h-12  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
            :list="lines"
            item-key="id"
            @start="drag=true"
            @end="drag=false"
          >
            <template #header>
              <div
                class=" mb-2 w-full py-2 flex justify-center"
                role="group"
              >
                <button
                  type="button"
                  class="  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  @click="onOpenList"
                >
                  <i class="fa-solid fa-plus  mr-2" />
            
                  {{ labels['BUTTON_ADD'][lg] }}
                </button>
              </div>
            </template>
                         
            <template #item="{element}">
              <div
                class="cursor-pointer list-group mt-2 w-full  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
              >
                <div class="flex flex-col items-center w-full  pl-6">
                  <div class=" h-12 flex flex-row items-center w-full   ">
                    <div class="w-96 flex items-center">
                      <label
                        class="  text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
                      >
                        {{ element.name[lg] }}
                      </label>
                    </div>

                    <div class="w-auto flex  w-full items-center justify-end  ">
                      <div class="w-auto flex flex-row items-center justify-center  ">
                        <div
                          href="#"
                          class="w-4 cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg px-6 flex items-center  justify-center        focus:outline-none  "
                          @click="onClickSetup(element)"
                        >
                          <i class="fa-solid fa-gear" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.bold), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.bold)} "
                          class="rounded-lg w-4 cursor-pointer   font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                          @click="onClickBold(element)"
                        >
                          <i class="fa-solid fa-bold" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.italic), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.italic)} "
                          class="rounded-lg w-4 cursor-pointer   font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                          @click="onClickItalic(element)"
                        >
                          <i class="fa-solid fa-italic" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.underline), 'text-gray-100 hover:text-gray-200 bg-blue-800   ': (element.options.underline)} "
                          class="rounded-lg w-4 cursor-pointer     font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1     focus:outline-none  "
                          @click="onClickUnderline(element)"
                        >
                          <i class="fa-solid fa-underline" />
                        </div>
                      </div>
                      <div class="w-auto flex   items-center justify-end   w-16 px-3">
                        <div
                          href="#"
                          class=" flex justify-end   cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg          focus:outline-none  "
                          @click="onClickMinus(element)"
                        >
                          <i class="fa-solid fa-xmark  " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row items-center w-full   h-6 pr-4">
                    <hr
                      class="w-full h-1 mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"
                    >
                  </div>

                  <div class="flex flex-row w-full items-center justify-start pb-2">
                    <label class="mr-4 sw-auto text-sm font-medium text-gray-900   dark:text-white">
                      Pourcentage : </label>

                    <div class="relative  ">
                      <input
                        v-model="lines[getIndex(element.id)].value"
                        type="number"
                        max="999"
                        min="1"
                        :class="{'bg-red-200': element.error}"
                        class=" w-24 block p-1.5 w-full z-20 text-sm text-gray-900 bg-white  border   border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                        placeholder=""
                        required
                        @input="checkPourcent($event,getIndex(element.id))"
                      >
                      <div
                        type="button"
                        class="text-gray-500 absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        %
                      </div>
                    </div>

                    <label
                      v-if="element.value != '' && element.value !=undefined"
                      class="ml-6 mr-4 sw-auto text-sm font-medium text-gray-900   dark:text-white"
                    >
                      Affichage : </label>

                    <div
                      v-if="element.value != '' && element.value !=undefined"
                      class="w-auto flex flex-row items-center justify-center  "
                    >
                      <div
                        href="#"
                        :class="{'text-gray-800 hover:text-gray-900': (!element.options.display == 1), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.display == 1)} "
                        class="rounded-lg   cursor-pointer   font-medium   text-sm px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                        @click="onClickDisplay(element,1)"
                      >
                        {{ lines[getIndex(element.id)].value }} %
                      </div>
                      <div
                        href="#"
                        :class="{'text-gray-800 hover:text-gray-900': (!element.options.display == 2), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.display == 2)} "
                        class="rounded-lg  cursor-pointer    font-medium   text-sm px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                        @click="onClickDisplay(element,2)"
                      >
                        : {{ lines[getIndex(element.id)].value }} %
                      </div>
                      <div
                        href="#"
                        :class="{'text-gray-800 hover:text-gray-900': (!element.options.display == 3), 'text-gray-100 hover:text-gray-200 bg-blue-800   ': (element.options.display == 3)} "
                        class="rounded-lg   cursor-pointer     font-medium   text-sm px-6 flex items-center  justify-center   py-2 mx-1     focus:outline-none  "
                        @click="onClickDisplay(element,3)"
                      >
                        ({{ lines[getIndex(element.id)].value }} %)
                      </div>
                    </div>
                  </div>

                  <div class="hidden flex flex-row items-center w-full flex-col mb-2  ">
                    <label
                      for="default-range"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Default
                      range</label>
                    <input
                      id="default-range"
                      type="range"
                      value="50"
                      class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    >
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>

        <div class="w-6/12 pb-6  max-h-96">
          <label class=" w-full pb-3 font-bold">
            {{ labels['LABEL_INGREDIENT_PREVIEW'][lg] }}
          </label>
          <div class="px-6 mt-4  w-full  ">
            <div class="p-6 bg-white w-full  h-full text-left  ">
              <div
                class="inline-flex rounded-md shadow-sm"
                role="group"
              >
                <button
                  type="button"
                  class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                  @click="selectLanguage('fr')"
                >
                  Fr
                </button>
                <button
                  type="button"
                  class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b  border-r border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                  @click="selectLanguage('en')"
                >
                  En
                </button>
                <button
                  type="button"
                  class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-r  border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                  @click="selectLanguage('de')"
                >
                  De
                </button>
                <button
                  type="button"
                  class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                  @click="selectLanguage('sp')"
                >
                  Es
                </button>

                <button
                  type="button"
                  class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                  @click="selectLanguage('it')"
                >
                  It
                </button>
              </div>
              <div
                class="mt-1 rounded-lg bg-gray-100 p-6 bg-white w-full  h-full text-left border border-solid border-2 border-gray-200"
                v-html="BuildIngredientText()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SelectIngredientModal
    @close="onSelectIngredientClose"
    @select="onSelectIngredientSelect"
  />
</template>

<script>
    import SelectIngredientModal from '@/components/modals/SelectIngredientModal.vue'
    import draggable from 'vuedraggable'

    import labels from "@/assets/lg/content-composer.json"
    import { Dropdown } from 'flowbite';
    import { Modal } from 'flowbite';



    export default {
        name: 'ContentComposer',
        components: {
            SelectIngredientModal, draggable
        },
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: Object,
            config: Object
        },
        emits: [ "manageField"],
        data() {
            return {
                output: [],
                labels: labels,
                lg: 'FR',
                drag: false,
                addIngredientModal: null,
                el_dropdown: null,
                dropdowns: [],
                lines: [],
                enabled: true,
                selectedLang: 'fr',
                dragging: false
            }
        },
        computed: {
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            }
        },
        watch: {
            lines: function () {
        
            },
            value: function () {
                //   this.output = val;
              
            }
        },
        mounted() {
            if (this.value != undefined)

                this.lines = this.value;
            /*
                        const options = {
                            placement: 'bottom',
                            triggerType: 'click',
                            offsetSkidding: 0,
                            offsetDistance: 10,
                            delay: 300,
                            onHide: () => {
                            
                            },
                            onShow: () => {
                              
                            },
                            onToggle: () => {
                             
            
            
            
                            }
            
                        };
                        {
                            const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-0');
                            const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-0');
                            this.dropdowns[0] = new Dropdown($targetEl, $triggerEl, options);
                        
            
                        }
                        {
                            const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-1');
                            const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-1');
                       
            
                            this.dropdowns[1] = new Dropdown($targetEl, $triggerEl, options);
                        }
            */
            const modalEl = document.getElementById('select-ingredient-modal-old');
            if (modalEl != undefined)
            this.addIngredientModal = new Modal(modalEl, {
                placement: 'center'
            });

        },
        methods: {
 
            checkPourcent: function (event, index) {

                event.stopPropagation();

                if (this.lines[index].value > 100) {
                    this.lines[index].error = true
                }
                else
                    this.lines[index].error = false

                if (this.lines[index].display == undefined)
                    this.lines[index].display = 1
                this.BuildIngredientText()
            },
            getIndex: function (id) {


                let index = this.lines.findIndex(function (el) { return el.id == id })
 
                return index;
            },
            selectLanguage: function (lg) {
                this.selectedLang = lg;
                this.BuildIngredientText()
            },
            BuildIngredientText: function () {
                let text = "";
                for (let i = 0; i < this.lines.length; i++) {
                    if (text != "") text = text + ", ";
                    let tagStart = "";
                    let tagEnd = "";

                    if (this.lines[i].options) {
                        if (this.lines[i].options.bold) {
                            tagStart = tagStart + "<b>";
                            tagEnd = "</b>" + tagEnd
                        }
                        if (this.lines[i].options.italic) {
                            tagStart = tagStart + "<i>";
                            tagEnd = "</i>" + tagEnd
                        }
                        if (this.lines[i].options.underline) {
                            tagStart = tagStart + "<u>";
                            tagEnd = "</u>" + tagEnd
                        }
                    }
                    let value = ""
                    if (this.lines[i].value) {
                        if (this.lines[i].options.display == 1)
                            value = " " + this.lines[i].value + " %"
                        if (this.lines[i].options.display == 2)
                            value = " : " + this.lines[i].value + " %"
                        if (this.lines[i].options.display == 3)
                            value = " (" + this.lines[i].value + " %)"
                    }
                    text = text + tagStart +    value + tagEnd;
                  //TO BE REVIEWED  text = text + tagStart + this.lines[i].displayName[this.selectedLang] + value + tagEnd;

                }

                return text;
            },


            getData() {

        
                return this.lines;

                //var x = document.getElementById("date-" + this.id).value;
                // return this.formatIso(x)
            },
            onDropdownOpen: function (index) {


                //  if (this.dropdowns[index] == undefined) 
                {

                    const options = {
                        placement: 'bottom',
                        triggerType: 'click',
                        offsetSkidding: 0,
                        offsetDistance: 10,
                        delay: 300,
                        onHide: () => {
                          
                        },
                        onShow: () => {
                            
                        },
                        onToggle: () => {
                          

                        }

                    };
                    {
                        const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-' + index);
                        

                        const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-' + index);
                      

                        this.dropdowns[index] = new Dropdown($targetEl, $triggerEl, options);

                    }
                }
                this.dropdowns[index].toggle()
              

            },
            onSelectIngredientSelect: function (item) {

                let newItem = item;
                newItem.unit = 'g';
                newItem.value = ''
                newItem.options = {};

                newItem.options.bold = false;
                newItem.options.italic = false;
                newItem.options.underline = false;

                newItem.options.display = 1;

                this.lines.push(newItem)

                this.addIngredientModal.hide();
            },
            onSelectIngredientClose: function () {
                this.addIngredientModal.hide();
            },
            onOpenList: function () {
                this.addIngredientModal.show();
                //  this.windowRef = window.open("https://editor-label.vercel.app/edit/" + this.selectedLabel.id, this.selectedLabel.id, "");
                //  if (!this.windowRef) return;
            },
            selectValue: function (tag, unit) {
                //this.output = a
           

                let index = this.getIndex(tag)
                if (this.lines[index] == undefined) this.lines[index] = { unit: '' }
                this.lines[index].unit = unit;

                this.dropdowns[index].toggle()
            },
            onClickDisplay: function (item, display) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}

                item.options.display = display

                this.lines[index] = item;
                this.BuildIngredientText()


            },

            onClickBold: function (item) {
                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.bold = !item.options.bold
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickItalic: function (item) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.italic = !item.options.italic
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickUnderline: function (item) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.underline = !item.options.underline
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickSetup: function (/*tem*/) {
 
            },
            onClickMinus: function (item) {
                function removeItemOnce(arr, index) {

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    return arr;
                }
                for (let i = 0; i < this.lines.length; i++) {

                    if (this.lines[i].id == item.id) {
                     
                        //   this.lines = this.output;
                        this.lines = removeItemOnce(this.lines, i);

                        return
                    }
                }
            }
        }
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    .buttons {
        margin-top: 35px;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }
</style>
<template>
  <div class="  mb-2 w-full h-14     grid w-full  grid-cols-4    gap-4 items-center  mt-3 ">
    <div class="px-3  w-full flex items-center  ">
      <label
        for="default"
        class="text-left block  text-sm font-bold font-bold text-gray-900 dark:text-white"
      >
        {{ label }}
      </label>
    </div>
    <div class="px-3   w-full flex items-center  ">
      <label
        for="default"
        class="text-left block  pr-3  text-sm   font-medium text-gray-900 dark:text-white"
        :class="{'font-bold': output?.active!=true, ' font-medium': output?.active==true, }"
      >
        Non
      </label>

      <label class="w-auto relative inline-flex items-center     cursor-pointer">
        <input
          :id="`${id}`"
          v-model="output.active"
          type="checkbox"
          value=""
          class="sr-only peer"
          @change="OnChangeSwitch"
        >
        <div
          class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
        />
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
      </label>

      <label
        for="default"
        class="text-left block    text-sm   font-medium text-gray-900 dark:text-white"
        :class="{'font-bold': output.active==true, ' font-medium': output.active!=true, }"
      >
        Oui
      </label>
    </div>
    <div class=" px-3    flex flex-row  w-full items-center">
      <label
        for="default"
        class="hidden text-left block mb-2 text-lg   font-normal text-gray-900 dark:text-white"
      >
        {{ label }}

        <i class="ml-2 fa-solid fa-trash  mr-2" />

      </label>
      <label
        v-if="output.active==true"
        class="pr-3 text-left italic  block text-sm   font-normal text-gray-900 dark:text-white"
      >
        Pas de fichier




      </label>

      <div
        v-if="output.active==true"
        class="p-2"
      >
        <button
          type="button"
          class="w-24 flex items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          @click="onFileSelect"
        >
          <i class="fa-solid fa-upload mr-2" />
          Parcourir
        </button>
      </div>
    </div>


    <div
      v-if="output.active==true"
      class="px-3  w-full"
    >
      <div
        v-if="(displayExpiration == true) || (displayExpiration == undefined)"
        class="relative max-w-xs  w-full  "
      >
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          :id="`date-${id}`"
          v-model="output.expiredAt"
          datepicker
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Date expiration   du document"
          @change="onChangeSwith"
          @paste="onChangeSwith"
        >
      </div>
      <div
        v-if="displayExpiration == false"
        class="relative max-w-sm mt-2 mb-2 w-full "
      />
    </div>

    <div class="hidden">
      <input
        :id="`dropzone-file-${id}`"
        :ref="`fileDocument-${id}`"
        type="file"
        class="hidden"
        @change="onFileChange"
      >
    </div>


    <div
      v-if="output[id]!=undefined"
      class="hidden flex flex-row -mt-6 ml-6 pl-6 w-full bg-gray-200 mr-6"
    >
      <div
        class="flex-auto mr-4   flex flex-wrap items-center justify-start  text-left"
        @click="onClickDelete"
      >
        <i class="fa-solid fa-trash    mr-2" /> delete
      </div>

      <div class=" flex-auto flex flex-wrap items-center justify-start ">
        <i class="fa-solid fa-trash  mr-2" /> Supprimer le fond
      </div>
    </div>
  </div>
</template>
<script>
    import axios from "axios";

    import Datepicker from 'flowbite-datepicker/Datepicker';




    export default {
        name: 'ContentFileUploadExtended',
        components: {

        },
        props: {
            id: String,
            label: String,
            source: String,
            value: String,
            displayExpiration: Boolean,
            config: Object
        },
        data() {
            return {
                output: {
                    expiredAt: "",
                    active: false
                },
                empty: {},
                isLoading: false
            }
        },
        watch: {
            'output.expiredAt': function (/*val*/) {

            },
            source: function (val) {
                this.empty[this.id] = this.source;
                this.output[this.id] = val;
            }
        },
        mounted() {


            const datepickerEl = document.getElementById('date-' + this.id);

            if (datepickerEl != undefined) {
                new Datepicker(datepickerEl, {
                    format: "dd/mm/yyyy",
                    autohide: true,
                    title: "Date expiration du document"
                });

            }

            this.output[this.id] = this.source;
            this.empty[this.id] = this.source;
        },

        methods: {
            openCalendar: function () {

            },
            onChangeSwith: function () {
                let value = this.output;



                //                if (this.output.active == true) {

                //              }
                this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })

            },
            onFileSelect() {
              
                const fileInput = document.getElementById('dropzone-file-' + this.id);
                fileInput.click();
            },

            getData() {



                return this.output;
            },
            onClickDelete() {
                this.empty[this.id] = this.source;
                this.output[this.id] = null;
            },
            onFileChange() {

          
                this.isLoading = true;

                this.filelist = [...this.$refs['fileDocument-' + this.id].files];

                if (this.filelist.length > 0) {
                    // this.SetLoadingMyLots(true)
                    // this.$emit("showList", false);

                    const formData = new FormData();
                    formData.append("image", this.filelist[0]); // appending file

                 //  axios
                 //       .post("/api/files/upload", formData, {
                 //           headers: { "content-type": "multipart/form-data" },
                 //       })


                        
                    axios
                        .post("https://files.b4food.io/b4food/files", formData, {
                            headers: {
                                "x-access-token": this.user?.token,
                                "content-type": "multipart/form-data"
                            },
                        })


                        .then((res) => {

                            this.output[this.id] = res.data.url


                            this.filelist = [];

                            // this.$refs.form.reset();


                            //this.SetLoadingMyLots(false)
                            this.isLoading = false;
                            // this.displayModal(false)
                            //  this.showModal2 = !this.showModal2;
                        })
                        .catch((err) => {

                            console.log("ERROR =========================", err);
                        });
                }
            },

        }
    }
</script>
<template>
  <div class="  mb-2 w-full min-h-96 relative">
    <label
      class="hidden flex flex-row font-bold text-left ml-4 px-2.5 text-center block mb-2 text-lg   text-gray-900 dark:text-white"
    >
      <div class="  w-full">{{ labels['LABEL_RECIPE_TITLE'][lg] }}</div>

    </label>


    <div class="w-full items-center justify-center flex mb-3">
      <label class="font-semibold  mr-3">
        Volume produit final
      </label>
      <div class="w-48 text-left">
        <ContentInput
          :id="`content-recipe-text`"
          :ref="`content-recipe-text`"
          :config="item"
          :label="item.label && item.label[lg]"
          :is-button="item.isButton"
          :value="reference"
          @value-changed="onValueTotalChanged"
        />
      </div>
    </div>
    <div>
      <div class="w-full flex  ">
        <div class="w-12/12 w-full border   ">
          <draggable
            ghost-class="ghost"
            class="mt-4 w-full   min-h-96 border-1 border-solid items-start flex flex-col bg-white mb-3 border-gray-300 p-6"
            class2="list-group mt-2 w-full h-12  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
            :list="lines"
            item-key="id"
            @start="drag=true"
            @end="drag=false"
          >
            <template #header>
              <div
                class="   mb-2 w-full py-2 flex justify-center"
                role="group"
              >
                <button
                  type="button"
                  class="  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  @click="onOpenList"
                >
                  <i class="fa-solid fa-plus  mr-2" />

                  {{ labels['BUTTON_ADD'][lg] }} {{ parent }}
                </button>
              </div>
            </template>
            <template #item="{element, index}">
              <div
                class="cursor-pointer list-group mt-2 w-full  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
                :class="{'border-blue-600 border-3': (selectedItem == index), 'border-gray-2s00': (selectedItem != index)}"
                @click="onSelectItem(index)"
              >
                <div class="flex flex-col items-center w-full  pl-2">
                  <div class=" h-12 flex flex-row items-center w-full pr-6 py-3 mb-3 mt-2 ">
                    <div class="w-full flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 pr-2 text-sm"
                        viewBox="0 0 320 512"
                      ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z"
                        />
                      </svg>
                      <label
                        class="w-full pr-3 text-left block  text-sm font-bold text-gray-900 dark:text-white"
                      >
                        {{ element?.type?.name ? element?.type?.name[lg] : element?.type?.name }}
                      </label>

                      <div class="w-48 ">
                        <ContentInput
                          :id="`content-recipe-text-${element.type_id}-${index}`"
                          :ref="`content-recipe-text-${element.type_id}-${index}`"
                          :config="item"
                          :index="index"
                          :label="item.label && item.label[lg]"
                          :is-button="item.isButton"
                          :placeholder="item.placeholder && item.placeholder[lg]"
                          :value="element.quantity"
                          @value-changed="onValueChanged"
                        />
                      </div>
                    </div>

                    <div class="w-auto flex  w-full items-center justify-end  ">
                      <div class="w-auto flex flex-row items-center justify-center  ">
                        <div
                          href="#"
                          class="hidden  w-4 cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg px-6 flex items-center  justify-center        focus:outline-none  "
                          @click="onClickSetup(element)"
                        >
                          <i class="fa-solid fa-gear" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.bold), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.bold)} "
                          class="hidden rounded-lg w-4 cursor-pointer   font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                          @click="onClickBold(element)"
                        >
                          <i class="fa-solid fa-bold" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.italic), 'text-gray-100 hover:text-gray-200 bg-blue-800    ': (element.options.italic)} "
                          class="hidden rounded-lg w-4 cursor-pointer   font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1       focus:outline-none  "
                          @click="onClickItalic(element)"
                        >
                          <i class="fa-solid fa-italic" />
                        </div>
                        <div
                          href="#"
                          :class="{'text-gray-800 hover:text-gray-900': (!element.options.underline), 'text-gray-100 hover:text-gray-200 bg-blue-800   ': (element.options.underline)} "
                          class="hidden rounded-lg w-4 cursor-pointer     font-bold   text-lg px-6 flex items-center  justify-center   py-2 mx-1     focus:outline-none  "
                          @click="onClickUnderline(element)"
                        >
                          <i class="fa-solid fa-underline" />
                        </div>
                      </div>
                      <div class="  w-auto flex  w-full   items-center justify-end   w-16 px-3">
                        <div
                          href="#"
                          class=" flex justify-end   cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg          focus:outline-none  "
                          @click="onClickMinus(element)"
                        >
                          <i class="fa-solid fa-xmark  " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
        <div
          v-if="supplier != false"
          class="w-12/12 w-full  border  "
        >
          <draggable
            ghost-class="ghost"
            class="mt-4 w-full   min-h-96 border-1 border-solid items-start flex flex-col bg-white mb-3 border-gray-300 p-6"
            class2="list-group mt-2 w-full h-12  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
            :list="products"
            item-key="id"
            @start="drag=true"
            @end="drag=false"
          >
            <template #header>
              <div
                class="   mb-2 w-full py-2 flex justify-center font-bold"
                role="group"
              >
                Produits des fournisseurs
              </div>
            </template>
            <template #item="{element }">
              <div
                class="cursor-pointer list-group mt-2 w-full  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
              >
                <div class="flex flex-col items-center w-full  pl-2">
                  <div class=" h-12 flex flex-row items-center w-full pr-6 py-3 mb-3 mt-2 ">
                    <div class="w-full flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 pr-2 text-sm"
                        viewBox="0 0 320 512"
                      ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z"
                        />
                      </svg>

                      <div class="w-full flex items-center   text-left">
                        <img
                          class="  h-10 w-10 mr-3"
                          :src="`${host_media}${element.image_front_product.url}?icon=true`"
                        >
                        <label
                          class=" pr-3   text-left text-sm font-bold text-gray-900 dark:text-white "
                        >
                          {{ element.name }}

                        </label>
                      </div>


                      <div class="w-full flex items-center mr-6  text-left">
                        <img
                          class="  h-12 w-12 mr-3"
                          :src="`${host_media}${element.supplier.image_id}?icon=true`"
                        >
                        <label
                          class=" pr-3   text-left text-sm font-bold text-gray-900 dark:text-white "
                        >
                          {{ element?.supplier?.name }}

                        </label>
                      </div>
                      <div class="w-48 ">
                        <label class="relative inline-flex items-center cursor-pointer">
                          <input
                            v-model="filtered[element._id]"
                            type="checkbox"
                            value=""
                            class="sr-only peer"
                            @change="onSwitchChange(element._id)"
                          >
                          <div
                            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                          />
                          <span
                            class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >

                            {{ filtered[element?._id] ? 'inclus' : 'exclus' }}




                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </draggable>

          <div v-if="products?.length == 0">
            Selectionner un élement
          </div>
        </div>
      </div>
    </div>
  </div>
  <SelectIngredientModal
    @close="onSelectIngredientClose"
    @select="onSelectIngredientSelect"
  />
</template>

<script>

    import ContentInput from "@/components/content/ContentInput.vue"
    import SelectIngredientModal from '@/components/modals/SelectIngredientModal3.vue'
    import draggable from 'vuedraggable'
    //import Multiselect from '@vueform/multiselect'
    import labels from "@/assets/lg/content-composer.json"
    import { Dropdown } from 'flowbite';
    import { Modal } from 'flowbite';
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";

    export default {
        name: 'ContentRecipeComposer',
        props: {
            id: String,
            label: String,

            placeholder: String,
            required: Boolean,
            supplier: Boolean,
            value: Object,
            parent: Object,
            volume: Object,
            config: Object
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            }
        },
        watch: {

            volume: async function () {


            },
            parent: async function () {
                let product = await service.getDataId("raw-products", this.parent, this.user?.token, { fields: "id,recipe" })


                let stocks = await service.getData("inbound-stock/summary", this.user?.token, {})



                for (let i = 0; i < product?.recipe?.length; i++) {
                    let item = product.items.find(function (a) {



                        return a._id == product.recipe[i].type_id
                    })


                    let stock = stocks.records.find(function (a) {



                        return a._id == product.recipe[i].type_id
                    });
                    if (stock != undefined)
                        product.recipe[i].stock = stock.count
                    else
                        product.recipe[i].stock = -1;

                    if (item != undefined)
                        product.recipe[i].type = item
                }


                this.lines = product.recipe
            },

            lines: function () {

            },
            value: function () {
                //   this.output = val;

            }
        },
        mounted() {
            if (this.value != undefined) {
                this.lines = this.value?.items;
                this.reference = this.value?.reference;
            }

            /*
                        const options = {
                            placement: 'bottom',
                            triggerType: 'click',
                            offsetSkidding: 0,
                            offsetDistance: 10,
                            delay: 300,
                            onHide: () => {
                             
                            },
                            onShow: () => {
                               
                            },
                            onToggle: () => {
                             
            
            
            
                            }
            
                        };
                        {
                            const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-0');
                            const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-0');
                            this.dropdowns[0] = new Dropdown($targetEl, $triggerEl, options);
                          
            
                        }
                        {
                            const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-1');
                            const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-1');
                        
            
                            this.dropdowns[1] = new Dropdown($targetEl, $triggerEl, options);
                        }
            */
            const modalEl = document.getElementById('select-ingredient-modal-3');
            this.addIngredientModal = new Modal(modalEl, {
                placement: 'center'
            });

        },

        methods: {

            onValueChanged: function (val) {

                this.lines[val.index].quantity = val.value;

            },

            onValueTotalChanged: function (val) {
                this.reference = val.value;

            },


            onSwitchChange: async function (id) {
                if (this.lines[this.selectedItem].products == undefined) this.lines[this.selectedItem].products = {}
                this.lines[this.selectedItem].products[id] = this.filtered[id]
            },
            updateSupplierProducts: async function (id) {
             //   console.log("updateSupplierProducts", id)




             
                let products = await service.getData("product-suppliers", this.user?.token, { product_type_id: id })

                if (this.lines[this.selectedItem].products == undefined) this.lines[this.selectedItem].products = {}

                for (let i = 0; i < products?.records.length; i++) {


                    if (this.lines[this.selectedItem].products[products.records[i]._id] == undefined) {
                        this.lines[this.selectedItem].products[products.records[i]._id] = true
                    }


                    this.filtered[products.records[i]._id] = this.lines[this.selectedItem].products[products.records[i]._id].toString()
                }
                this.products = products.records;

            },

            onSelectItem: async function (index) {
                this.selectedItem = index
                await this.updateSupplierProducts(this.lines[index].type_id)



            },
            calcRatioStyle: function (needed, stock) {
                if (needed == "") {
                    return "width:0%;"
                }

                if (stock == "-1") {
                    return "width:0%;"
                }

                let ratio = (parseFloat(needed) * 100) / parseFloat(stock);
                if ((ratio > 99) && (ratio < 100)) {
                    return "width: " + ratio.toFixed(0) + "%;background-color:orange"
                }


                if (ratio < 100) {
                    return "width: " + ratio.toFixed(0) + "%;background-color:green"
                }
                return "width:100%;background-color:red"
            },


            checkPourcent: function (event, index) {

                event.stopPropagation();

                if (this.lines[index].value > 100) {
                    this.lines[index].error = true
                }
                else
                    this.lines[index].error = false

                if (this.lines[index].display == undefined)
                    this.lines[index].display = 1
                this.BuildIngredientText()
            },
            ratio: function (el) {

                if (this.volume == undefined) return "";
                if (this.volume.value == undefined) return "";
                if (this.volume.value == "") return "";
                return ((parseFloat(el.quantity.value) * parseFloat(this.volume?.value)) / 100).toFixed(2) + this.volume?.unit
            },
            getIndex: function (id) {


                let index = this.lines.findIndex(function (el) { return el.id == id })


                return index;
            },
            selectLanguage: function (lg) {
                this.selectedLang = lg;
                this.BuildIngredientText()
            },
            BuildIngredientText: function () {
                let text = "";
                for (let i = 0; i < this.lines.length; i++) {
                    if (text != "") text = text + ", ";
                    let tagStart = "";
                    let tagEnd = "";

                    if (this.lines[i].options) {
                        if (this.lines[i].options.bold) {
                            tagStart = tagStart + "<b>";
                            tagEnd = "</b>" + tagEnd
                        }
                        if (this.lines[i].options.italic) {
                            tagStart = tagStart + "<i>";
                            tagEnd = "</i>" + tagEnd
                        }
                        if (this.lines[i].options.underline) {
                            tagStart = tagStart + "<u>";
                            tagEnd = "</u>" + tagEnd
                        }
                    }
                    let value = ""
                    if (this.lines[i].value) {
                        if (this.lines[i].options.display == 1)
                            value = " " + this.lines[i].value + " %"
                        if (this.lines[i].options.display == 2)
                            value = " : " + this.lines[i].value + " %"
                        if (this.lines[i].options.display == 3)
                            value = " (" + this.lines[i].value + " %)"
                    }
                    text = text + tagStart + this.lines[i].displayName[this.selectedLang.toUpperCase()] + value + tagEnd;

                }

                return text;
            },


            getData() {


                return { reference: this.reference, items: this.lines }


            },
            onDropdownOpen: function (index) {


                //  if (this.dropdowns[index] == undefined) 
                {

                    const options = {
                        placement: 'bottom',
                        triggerType: 'click',
                        offsetSkidding: 0,
                        offsetDistance: 10,
                        delay: 300,
                        onHide: () => {

                        },
                        onShow: () => {

                        },
                        onToggle: () => {


                        }

                    };
                    {
                        const $targetEl = document.getElementById('dropdown-unit-' + this.id + '-' + index);


                        const $triggerEl = document.getElementById('dropdown-button-2-' + this.id + '-' + index);

                        this.dropdowns[index] = new Dropdown($targetEl, $triggerEl, options);

                    }
                }
                this.dropdowns[index].toggle()


            },
            onSelectIngredientSelect: function (item) {

                let newItem = {};

                newItem.type_id = item._id;
                newItem.type = item;
                newItem.quantity = {}
                newItem.quantity.unit = '%';
                newItem.quantity.value = '100'
                newItem.options = {};

                newItem.batches = []
                if (this.lines == undefined) this.lines = []
                this.lines.push(newItem)



                this.addIngredientModal.hide();
            },
            onSelectIngredientClose: function () {
                this.addIngredientModal.hide();
            },
            onOpenList: function () {
              
               


                this.addIngredientModal.show();
                //  this.windowRef = window.open("https://editor-label.vercel.app/edit/" + this.selectedLabel.id, this.selectedLabel.id, "");
                //  if (!this.windowRef) return;
            },
            selectValue: function (tag, unit) {
                //this.output = a


                let index = this.getIndex(tag)
                if (this.lines[index] == undefined) this.lines[index] = { unit: '' }
                this.lines[index].unit = unit;

                this.dropdowns[index].toggle()
            },
            onClickDisplay: function (item, display) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}

                item.options.display = display

                this.lines[index] = item;
                this.BuildIngredientText()


            },

            onClickBold: function (item) {
                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.bold = !item.options.bold
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickItalic: function (item) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.italic = !item.options.italic
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickUnderline: function (item) {

                let index = this.getIndex(item.id)
                if (item.options == undefined) item.options = {}
                item.options.underline = !item.options.underline
                this.lines[index] = item;
                this.BuildIngredientText()
            },
            onClickSetup: function (/*item*/) {

            },
            onClickMinus: function (item) {
                function removeItemOnce(arr, index) {

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    return arr;
                }
                for (let i = 0; i < this.lines.length; i++) {

                    if (this.lines[i].id == item.id) {

                        //   this.lines = this.output;
                        this.lines = removeItemOnce(this.lines, i);

                        return
                    }
                }
            }
        },
        components: {
            SelectIngredientModal, draggable, /*Multiselect, */ContentInput
        },
        data() {
            return {
                output: [],
                reference: {
                    value: "10",
                    unit: "kg"
                },
                filtered: {},
                labels: labels,
                lg: 'FR',
                drag: false,
                products: [],
                item: {
                    id: "11",
                    "name":
                    {
                        'FR': 'Quantité',
                        'EN': 'Quantitté'
                    },
                    "options":
                    {

                    },
                    "data": "val",
                    "format": "mass-volume",
                    "prefix": false
                },
                quantity: { value: "100", unit: 'kg' },
                addIngredientModal: null,
                el_dropdown: null,
                dropdowns: [],
                lines: [],
                mode: 'stock',
                enabled: true,
                selectedLang: 'fr',
                selectedItem: null,
                dragging: false
            }
        }
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    .buttons {
        margin-top: 35px;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }
</style>
<template>
  <div class="mt-2 mb-2 w-full relative">
    <label
      for="default" 
        
      class="text-left block mb-1 text-sm font-semibold text-gray-900 dark:text-white"
    >{{ label }}</label>
    
    <select
      id="default"
      v-model="output"
      class2="      bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      @change="onChange"
    >
      <option
        v-for="choice in choices"
        :key="choice._id"
        :value="choice?._id"
      >
        {{ formatOption(choice) }}  
      </option>
    </select>
  </div>
</template>
<script>
    import engine from "@/lib/engine.js"
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    export default {
        name: 'ContentSelectCollection',
        props: {
            id: String,
            label: String,

            required: Boolean,
            value: String,
            record: Object,

            customAction: Object,
            datasource: String,
            mode: String,
            filters: Object,
            config: Object
        },
        async mounted() {

            this.output = this.value;

            this.loadData()


            this.output = this.value;
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {

            async loadData() {




                //   console.log(item.class)



                let datasource = this.datasource;
                if (datasource == undefined) { return []; }
                let filters = {};

                if (this.filters != undefined) {
                    if (typeof this.filters == 'object') filters = this.filters
                }

                filters.limit = 100;
                filters.offset = 0;
                let ff = this.config?.filter
                if (ff != undefined) {
                    if (this?.config?.load_condition != null) {

                        let e = new engine(this.record, this?.config?.load_condition)
                        let c = e.check()

                        if (c.length != 0) {

                            this.choices = []  //TODO : Mettre la valeur de la régle
                            return;
                        }
                    }

                    let k = Object.keys(ff)

                    for (let i = 0; i < k.length; i++) {
                        if (k[i] == "team_id") {
                            ff[k[i]] = this.record?.team_id
                        }
                    }
                }

                filters = {
                    ...filters, ...this.setFilter(ff)
                }

                if ((datasource != undefined) && (datasource != "")) {
                    let choices = await service.getData("data-sources/" + datasource + "/records", this.user?.token, filters)
                    if (choices?.records == undefined) this.choices = []
                    else this.choices = choices?.records
                }
                else {
                    this.choices = []
                }

            },


            setFilter(filter) {

                let newFilter = {}
                if (filter == undefined) return filter;

                if (typeof filter != "string") return filter
                let rules = filter.split("&")

                for (let i = 0; i < rules.length; i++) {
                    let rule = rules[i];

                    let tags = rule.split("=")

                    if (tags.length == 2) {

                        newFilter[tags[0]] = tags[1]

                    }
                }

                return newFilter
            },
            onChange() {
                this.$emit("valueChanged", { id: this.config.data, value: this.output, mode: 0 })
            },
            getData() {
                return this.output;
            },
            formatOption(choice) {
                // if ( (this.config.format != undefined) && (this.config.format != "")) {
                //    return choice.name + " / FT-" + choice.reference
                // }
                let name = choice.name
                if (typeof choice.name == "object")
                    name = choice.name[this.lg]
                return name
            }
        },
        data() {
            return {
                output: "",
                att: "_id",
                choices: [],
                lg: "FR",
                choices_appelations: [],
                appelations: [
                    "AOC Sancerre",
                    "AOC Pouilly-Fumé",
                    "AOC Pouilly-sur-Loire",
                    "AOC Menetou-Salon",
                    "AOC Coteaux du Giennois",
                    "AOC Vins de l'Orléanais",
                    "AOC Cheverny",
                    "AOC Cour-Cheverny",
                    "AOC Quincy",
                    "AOC Reuilly",
                    "AOC Valençay",
                    "AOC Touraine",
                    "AOC Touraine-Mesland",
                    "AOC Touraine-Amboise",
                    "AOC Touraine-Azay-Le-Rideau",
                    "AOC Coteaux du Vendômois",
                    "AOC Jasnières",
                    "AOC Vouvray",
                    "AOC Montlouis",
                    "AOC Chinon",
                    "AOC Coteaux du Loir",
                    "AOC Bourgueil",
                    "AOC Saint-Nicolas-de-Bourgueil",
                    "Haut Poitou AOVDQS",
                    "AOC Saumur et Saumur-Champigny",
                    "AOC Coteaux de l'Aubance",
                    "Vins du Thouarsais AOVDQS",
                    "AOC Bonnezeaux",
                    "AOC Quarts de Chaume",
                    "AOC Coteaux du Layon",
                    "AOC Coteaux du Layon-Chaume",
                    "AOC Anjou",
                    "AOC Savennières",
                    "AOC Coteaux d'Ancenis",
                    "AOC Muscadet",
                    "Gros Plant AOVDQS",
                    "Châteaumeillant AOVDQS",
                    "Fiefs Vendeens AOVDQS",
                    "Côte d'Auvergne AOVDQS",
                    "Saint-Pourçain AOVDQS",
                    "Côte Roannaise AOC",
                    "Coteaux du Lyonnais AOVDQS",
                    "AOC Coteaux de Pierrevert",
                    "AOC Médoc",
                    "AOC Saint-Estèphe",
                    "AOC Pauillac",
                    "AOC Saint Julien",
                    "AOC Listrac-Médoc",
                    "AOC Moulis",
                    "AOC Margaux",
                    "AOC Pessac Leognan",
                    "AOC Sauternes",
                    "AOC Sainte-Croix-du-Mont",
                    "AOC Barsac",
                    "AOC Cérons",
                    "AOC Loupiac",
                    "AOC Cadillac",
                    "AOC Pomerol",
                    "AOC Lalande-de-Pomerol",
                    "AOC Fronsac",
                    "AOC Canon-Fronsac",
                    "AOC Saint-Émilion",
                    "AOC Montagne St Emilion",
                    "AOC Puisseguin St Emilion",
                    "AOC Lussac St Emilion",
                    "AOC Côtes de Bordeaux Castillon",
                    "AOC Entre-Deux-Mers",
                    "AOC Côtes de Bourg",
                    "AOC Chablis",
                    "AOC Saint-Bris",
                    "AOC Irancy",
                    "AOC Marsannay",
                    "AOC Fixin",
                    "AOC Gevrey-Chambertin",
                    "AOC Morey St Denis",
                    "AOC Chambolle-Musigny",
                    "AOC Vougeot",
                    "AOC Vosne-Romanée",
                    "AOC Nuits-Saint-Georges",
                    "AOC Ladoix",
                    "AOC Aloxe-Corton",
                    "AOC Pernand-Vergelesses",
                    "AOC Savigny-les-Beaune",
                    "AOC Chorey-les-Beaune",
                    "AOC Beaune",
                    "AOC Pommard",
                    "AOC Volnay",
                    "AOC Monthelie",
                    "AOC Auxey-Duresses",
                    "AOC Saint-Romain",
                    "AOC Meursault",
                    "AOC Blagny",
                    "AOC Puligny-Montrachet",
                    "AOC Chassagne-Montrachet",
                    "AOC Saint-Aubin",
                    "AOC Maranges",
                    "AOC Rully",
                    "AOC Mercurey",
                    "AOC Givry",
                    "AOC Montagny",
                    "AOC Bouzeron",
                    "AOC Mâcon-Villages",
                    "AOC Viré-Clessé",
                    "AOC Pouilly-Fuissé",
                    "AOC St Véran",
                    "AOC Chénas",
                    "AOC Côte de Brouilly",
                    "AOC Saint-Amour",
                    "AOC Chiroubles",
                    "AOC Régnié",
                    "AOC Juliénas",
                    "AOC Moulin à Vent",
                    "AOC Fleurie",
                    "AOC Morgon",
                    "AOC Brouilly",
                    "Sillery",
                    "Puisieulx",
                    "Verzy",
                    "Mailly-Champagne",
                    "Verzenay",
                    "Beaumont-sur-Vesle",
                    "Chouilly",
                    "Cramant",
                    "Avize",
                    "Le Mesnil-sur-Oger",
                    "Oger",
                    "Ambonnay",
                    "Louvois",
                    "Oiry",
                    "Bouzy",
                    "Tours-sur-Marne",
                    "Ay",
                    "AOC Rosé des Riceys",
                    "Alsace",
                    "AOC Arbois",
                    "AOC L'Étoile",
                    "AOC Château-Chalon",
                    "AOC Crépy",
                    "AOC Seyssel",
                    "AOC Cote Rotie",
                    "AOC Cornas",
                    "AOC Condrieu",
                    "AOC Chateau Grillet",
                    "AOC St Joseph",
                    "AOC Saint-Péray",
                    "AOC Crozes-Hermitage",
                    "AOC Châteauneuf-du-Pape",
                    "AOC Gigondas",
                    "AOC Tavel",
                    "AOC Lirac",
                    "AOC Vacqueyras",
                    "AOC Châtillon-en-Diois",
                    "AOC Clairette de Die",
                    "AOC Bandol",
                    "AOC Cassis",
                    "AOC Bellet",
                    "AOC Palette",
                    "AOC Baux-de-Provence",
                    "AOC Vin de Corse-Figari",
                    "AOC Vin de Corse-Sartène",
                    "AOC Vin de Corse-Porto-Vecchio",
                    "AOC Vin de Corse-Calvi",
                    "AOC Vin de Corse-Coteaux du Cap Corse",
                    "AOC Patrimonio",
                    "AOC Ajaccio",
                    "AOC Muscat du Cap Corse",
                    "AOC Vin de Savoie- Apremont",
                    "AOC Vin de Savoie-Ripaille",
                    "AOC Vin de Savoie-Marignan",
                    "AOC Vin de Savoie-Chautagne",
                    "AOC Vin de Savoie-Chignin",
                    "AOC Vin de Savoie Saint-Jean-de-La-Porte",
                    "AOC Roussette de Savoie-Marestel",
                    "AOC Roussette de Savoie-Frangy",
                    "AOC Roussette de Savoie-Monterminod",
                    "AOC Roussette de Savoie-Monthoux",
                    "AOC Vin de Savoie-Ayze",
                    "AOC Vin de Savoie-Cruet",
                    "AOC Vin de Savoie-Montmélian",
                    "AOC Vin de Savoie-Arbin",
                    "AOC Vin de Savoie-Charpignat",
                    "AOC Vin de Savoie-Saint-Jeoire-Prieuré",
                    "AOC Vin de Savoie Sainte-Marie-d'Alloix",
                    "AOC Vin de Savoie-Abymes",
                    "AOC Côtes du Jura",
                    "Bordeaux",
                    "AOC Coteaux du Languedoc-La Clape",
                    "AOC Coteaux du Languedoc-Cabrières",
                    "AOC Coteaux du Languedoc-Quatourze",
                    "AOC Coteaux du Languedoc-Montpeyroux",
                    "AOC Coteaux du Languedoc Saint-Saturnin",
                    "AOC Coteaux du Languedoc-Vérargues",
                    "AOC Coteaux du Languedoc Saint-Christol",
                    "AOC Coteaux du Languedoc Saint-Georges-d'Orques",
                    "AOC Coteaux du Languedoc Saint-Drézéry",
                    "AOC Coteaux du Languedoc Mejanelle",
                    "AOC Coteaux du Languedoc Picpoul-de-Pinet",
                    "AOC Coteaux du Languedoc Pic St Loup",
                    "AOC Saint-Chinian",
                    "AOC Fitou",
                    "AOC Faugères",
                    "AOC Collioure",
                    "AOC Corbières",
                    "AOC Minervois",
                    "AOC Côtes du Roussillon",
                    "AOC Clairette de Bellegarde",
                    "AOC Clairette du Languedoc",
                    "AOC Limoux",
                    "AOC Malepère",
                    "AOC Cabardès",
                    "AOC Banyuls",
                    "AOC Maury",
                    "AOC Rivesaltes",
                    "AOC Muscat de Lunel",
                    "AOC Muscat de Frontignan",
                    "AOC Muscat de Saint-Jean-de-Minervois",
                    "AOC Muscat de Mireval",
                    "AOC Muscat de Beaumes-de-Venise",
                    "AOC Grand Roussillon",
                    "AOC Rasteau",
                    "AOC Saint-Sardos",
                    "AOC Bergerac",
                    "AOC Montravel",
                    "AOC Rosette",
                    "AOC Saussignac",
                    "AOC Monbazillac",
                    "AOC Pécharmant",
                    "AOC Madiran",
                    "AOC Pacherenc du Vic-Bihl",
                    "AOC Bearn et Bearn Bellocq",
                    "AOC Jurançon",
                    "AOC Cahors",
                    "AOC Côtes du Frontonnais",
                    "AOC Buzet",
                    "AOC Côtes de Duras",
                    "AOC Irouléguy",
                    "AOC Gaillac",
                    "AOC Côtes du Marmandais",
                    "AOC Marcillac",
                    "Tursan AOVDQS",
                    "Vin d'Estaing AOVDQS",
                    "Lavilledieu AOVDQS",
                    "Côtes de Millau AOVDQS",
                    "Vin d'Entraygues et du Fel AOVDQS",
                    "AOC Santenay"
                ]
            }
        },
        watch: {
            customAction: function () {


                if (this.customAction.attribut == this.config.data) {
           
                    this.loadData()
                }
            },

            config: function () {
                if (this.config?.attribut == undefined) this.att = "_id"
                else this.att = this.config?.attribut
            },
            value: function (val) {

                this.output = val;
                if (this.config.data == "team_id")
                    this.$emit("valueChanged", { id: this.config.data, value: this.output, mode: 1 })
            },
            datasource: async function () {

                this.loadData()

            }
        }
    }
</script>
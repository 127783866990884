<template>
  <div class="w-full mt-12 text-2xl text-gray-400 dark:text-gray-500 flex flex-col justify-start items-start  ">
    <h1> SECTION </h1>
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
      <ul
        id="myTab"
        class="flex flex-wrap -mb-px text-sm font-medium text-center"
        data-tabs-toggle="#myFormTabContent"
        role="tablist"
      >
        <li
          class="mr-2"
          role="section-properties"
        >
          <button
            id="section-properties-tab"
            class="inline-block p-4 border-b-2 rounded-t-lg"
            data-tabs-target="#section-properties-content"
            type="button"
            role="tab"
            aria-controls="section-properties-content"
            aria-selected="false"
          >
            Propriétés
          </button>
        </li>
        <li
          class="mr-2"
          role="section-style"
        >
          <button
            id="section-style-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#section-style-content"
            type="button"
            role="tab"
            aria-controls="section-style-content"
            aria-selected="false"
          >
            Style Graphique
          </button>
        </li>
        <li
          class="mr-2"
          role="section-misc"
        >
          <button
            id="section-misc-tab"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            data-tabs-target="#section-misc-content"
            type="button"
            role="tab"
            aria-controls="section-misc-content"
            aria-selected="false"
          >
            Divers
          </button>
        </li>
      </ul>
    </div>
    <div id="myFormTabContent">
      <div
        id="section-properties-content"
        class="  p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="section-properties-tab"
      >
        <div
          v-if="currentItem"
          class="hidden mb-6 w-full"
        >
          <label
            for="type"
            class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white"
          >
            Name <span class="font-medium">{{ tag }}</span></label>
          <input
            v-model="currentItem.shortName"
            type="text"
            class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Nom court"
            required
          >
        </div>
        <div class="mb-6 flex flex-col items-start justify-start w-full">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Libellé </label>

          <div
            v-if="currentItem.title != undefined"
            class="flex flex-col items-center   justify-start"
          >
            <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
              <div
                class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
              >
                <div class="text-sm  text-gray-50">
                  FR
                </div>
              </div>
              <input
                v-model="currentItem.title.FR"
                class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              >
            </div>
            <div class="relative flex flex-row justify-start items-center mb-1 w-96 rounded-lg">
              <div
                class="absolute rounded-lg bg-gray-800 inset-y-0 left-0 flex items-center pl-3 pr-3  pointer-events-none"
              >
                <div class="text-sm  text-gray-50">
                  EN
                </div>
              </div>
              <input
                v-model="currentItem.title.EN"
                class="block w-full p-2 pl-12 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                required
              >
            </div>
            <div class="flex justify-start flex-row w-full mt-2 ml-6 items-center">
              <input
                id="checked-checkbox"
                v-model="currentItem.title.display"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              >
              <label
                for="checked-checkbox"
                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Afficher le titre</label>
            </div>
          </div>
        </div>
        <div class="hidden mb-6 flex flex-col items-start justify-start">
          <label class="text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
            Type {{ currentItem.type }}</label>
          <select
            id="default"
            v-model="currentItem.type"
            class="w-full bg-white border border-gray-300 text-gray-900 mb-6 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option
              v-for="type in types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.value[lg] ? type.value[lg] : type.value }}
            </option>
          </select>
        </div>
      </div>

      <div
        id="section-style-content"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="section-style-tab"
      >
        <DeveloperStyle
          :item="currentItem"
          @update="onUpdateStyle"
        />
      </div>
      <div
        id="section-misc-content"
        class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
        role="tabpanel"
        aria-labelledby="section-misc-tab"
      >
        <DeveloperMisc :item="currentItem" />
      </div>
    </div>








    <span class="bg-red-300 ">** {{ currentItem }} **</span>

    <div class="w-full flex flex-row items-center mb-3">
      <div class="w-full flex justify-start">
        <button
          type="submit"
          class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          Supprimer
        </button>
      </div>
      <div
        class="w-full flex justify-end"
        @click="onClickAddColumn"
      > 
        <button  
          class="text-right right justify-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Ajouter un colonne
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    import { Tabs } from "flowbite";
    import DeveloperStyle from "@/components/developer/DeveloperStyle.vue";
    import DeveloperMisc from "@/components/developer/DeveloperMisc.vue";


    import  slugify from 'slugify'
    export default {
        name: 'DeveloperSection',
        components: {
            DeveloperStyle, DeveloperMisc
        },
        props: {
            item: Object
        },

        data() {
            return ({
                lg: "FR",
                currentItem: {},
                tabElements: [],
                options: {
                    defaultTabId: 'section-home',
                    activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
                    inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
                    onShow: () => {
                     
                    }
                },
                types2: [
                    {
                        id: "display",
                        value: {
                            "EN": "Display Label",
                            "FR": "Affichage Libellé"
                        }
                    },
                    {
                        id: "text",
                        value: {
                            "EN": "Text Field",
                            "FR": "Champ de saisie"
                        }
                    },
                    {
                        id: "select",
                        value: {
                            "EN": "Simple Select",
                            "FR": "Selection Simple"
                        }
                    },
                    {
                        id: "tag",
                        value: {
                            "EN": "Tags Fields",
                            "FR": "Champ Tags"
                        }
                    },
                    {
                        id: "note",
                        value: {
                            "EN": "Note Fields",
                            "FR": "Champ de saisie Avancé"
                        }
                    },
                    {
                        id: "image-upload",
                        value: {
                            "EN": "Upload Image",
                            "FR": "Chargement Image"
                        }
                    },

                    {
                        id: "blank",
                        value: {
                            "EN": "Blank",
                            "FR": "Espace Vide"
                        }
                    }
                ],

            })
        },
        computed: {

            tag: function () {
                if (this.currentItem == undefined) return ""
                if (this.currentItem.shortName == undefined) return ""
                return slugify(this.currentItem.shortName, { lower: true })
            }
        },
        watch: {
            item: function (val) {
                let item = val;
                if (item.title == undefined)
                    item.title = {}
                if (item.title.FR == undefined)
                    item.title.FR = ""
                if (item.title.EN == undefined)
                    item.title.EN = ""
                    if (item.title.display == undefined)
                    item.title.display = true
                    
                this.currentItem = item;

            }
        },
        mounted() {

            let item = this.item;
            if (item.title == undefined)
                item.title = {}
            if (item.title.FR == undefined)
                item.title.FR = ""
            if (item.title.EN == undefined)
                item.title.EN = ""

                if (item.title.display == undefined)
                    item.title.display = true
            this.currentItem = item;
            const tabElements = [
                {
                    id: 'section-home',
                    triggerEl: document.querySelector('#section-properties-tab'),
                    targetEl: document.querySelector('#section-properties-content')
                },
                {
                    id: 'section-dashboard',
                    triggerEl: document.querySelector('#section-style-tab'),
                    targetEl: document.querySelector('#section-style-content')
                },
                {
                    id: 'section-settings',
                    triggerEl: document.querySelector('#section-misc-tab'),
                    targetEl: document.querySelector('#section-misc-content')
                }
            ]
    
   
            this.$nextTick(() => {
                const tabs = new Tabs(tabElements, this.options);
        
                tabs.show('section-home');
            });
        },
        methods: {
            onUpdateStyle: function (cls) {
        
                this.currentItem.class = cls.class;
            
                this.$emit("update", this.currentItem)
            },
            onClickAddColumn: function () {
                this.$emit("addColumn", this.currentItem);
            }
        }

    }
</script>
<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div
        v-if="events?.onChange?.length == 0"
        class="  mb-6 w-full text-left"
      >
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Pas de
          triggers pour ce type d'élement</label>

        <div class="hidden  block text-black">
          {{ element }}
        </div>
      </div>

      <div
        v-for="(event, index2) in events.onChange"
        :key="event.id"
        class="w-full "
      >
        <div
          v-if="event.action == 'refresh'"
          class="bg-white  mb-6 w-full text-left   border border-gray-200"
        >
          <div class=" w-full flex flex-row items-center justify-between border-b border-b-gray-200 pl-4 ">
            <label
              for="template-input"
              class="block mb-2 text-lg font-medium text-gray-900 dark:text-white flex items-center"
            >Rafraichissement
              d'un
              composant</label>
            <div class="  px-4 text-gray-900 flex items-center">
              <div
                class="h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-200 rounded-full"
              >
                <i class="fa-solid fa-chevron-up cursor-pointer" />
              </div>


              <div
                class="  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-200 rounded-full"
              >
                <i class="   fa-solid fa-chevron-down  cursor-pointer" />
              </div>

              <div
                class="ml-4  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-200 rounded-full"
              >
                <i class="fa-regular fa-trash-can  cursor-pointer " />
              </div>
            </div>
          </div>



          <div
            v-for="(value, index) in event?.properties?.dependencies"
            :key="value"
            class="mt-2 px-4 w-full bg-white flex flex-row items-center  border-b-0 mb-1  border-gray-200"
          >
            <select
              :id="`att-widget-${element?.id}`"
              :value="value"
              class="mr-3 block py-2.5 px-3 w-full text-sm text-gray-500 bg-gray-100 border-0   border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              @change="changeSelect($event,index2,index)"
            >
              <option
                class="px-3"
                value=""
              />

              <option
                v-for="widget in form?.lines"
                :key="widget.id"
                class="px-3"
                :value="widget.id"
              >
                <b>{{ widget.title }}</b>
              </option>
            </select>

            <div
              class="font-medium  dark:text-red-500   mr-3 cursor-pointer"
              :class="{'text-red-600 cursor-pointer' : ( event?.properties?.dependencies.length > 1), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length < 2) }"
              @click="removeLine(index2 ,index)"
            >
              <i class="fa-solid fa-circle-minus fa-lg" />
            </div>
            <div
              class="font-medium dark:text-green-500  "
              :class="{'text-green-600 cursor-pointer' : ( event?.properties?.dependencies.length < 5), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length > 4) }"
              @click="addLine(index2 ,index)"
            >
              <i class="fa-solid fa-circle-plus fa-lg" />
            </div>
          </div>
        </div>


        <div
          v-if="event.action == 'api'"
          class="bg-white  mb-3 w-full text-left  border border-gray-200"
        >
          <div class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200 ">
            <label
              for="template-input"
              class="block mb-2 text-lg font-medium text-gray-900 dark:text-white flex items-center"
            >Appel
              API</label>
            <div class="  px-4 text-gray-900 flex items-center">
              <div
                class="h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-200 rounded-full"
              >
                <i class="fa-solid fa-chevron-up cursor-pointer" />
              </div>


              <div
                class="  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-200 rounded-full"
              >
                <i class="   fa-solid fa-chevron-down  cursor-pointer" />
              </div>

              <div
                class="ml-4  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-200 rounded-full"
              >
                <i class="fa-regular fa-trash-can  cursor-pointer " />
              </div>
            </div>
          </div>


          <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
            <div class="  mb-6 w-full text-left flex flex-row items-center">
              <label
                for="default-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-24"
              >URL</label>
              <input
                id="default-input"
                v-model="event.properties.href"
                type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @input="onInputURL"
              >
            </div>

            <div class="  mb-6 w-full text-left flex flex-row items-center">
              <label
                for="default-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-24"
              >Attribut</label>
              <input
                id="default-input"
                v-model="event.properties.attribute"
                type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @input="onInputAttribute"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TriggersTableFormComponentDetails',
        props: {
            form: Object,
            element: Object,
            index: Number,
        },
        data() {

            return {
                values: [
                    {
                        id: ''
                    }
                ],
                events: {
                    'onChange': [],
                    'onInit': []
                },

  /* 
                events: {
                 'onChange': [
                        {
                            "action": "api",
                            "properties": {
                                "href": "/collections-full/product-suppliers?_id={{#ids}}{{.}},{{/ids}}",
                                "attribute": "products"
                            }
                        },
                        {
                            "action": "api",
                            "properties": {
                                "href": "/collections-full/companies?_id={{#products}}{{supplier_id}},{{/products}}",
                                "attribute": "suppliers"
                            }
                        },
                        {
                            "action": "refresh",
                            "properties": {
                                "dependencies": [
                                    "fb0a6e7a-804a-45b6-9db5-14baf57326fa",
                                    "483b0130-8c31-4b99-8946-79710939e01f"
                                ],
                                "fields": [
                                    "avatars"
                                ]
                            }
                        }
                    ]
                },
*/

                title: "",
                description: "",
                format: "",
                click: "",
                isTitleDisplayed: false,
                isDescriptionDisplayed: false
            }
        },
        computed: {
            displayMessage: function () {

                if (this.element?.type != 'avatar') return true

                return false;
            },
            displaySelect: function () {

                if (this.element?.type == 'avatar') return true



                return false;
            }
        },
        watch:
        {
            element: function () {
                this.click = this.element?._triggers?.click;

                this.events = this.element?._events;
                if (this.events == undefined)
                this.events = {
                        'onChange': [],
                        'onInit': []
                    }

                if (this.events.onChange == undefined) this.events.onChange = []
                if (this.events.onInit == undefined) this.events.onInit = []
            }
        },
        mounted() {
            this.click = this.element?._triggers?.click;

            this.events = this.element?._events;

            if (this.events == undefined)
            this.events = {
                    'onChange': [],
                    'onInit': []
                }

            if (this.events.onChange == undefined) this.events.onChange = []
            if (this.events.onInit == undefined) this.events.onInit = []
        },
        methods:
        {
            changeSelect(event, index2, index) {
                this.events.onChange[index2].properties.dependencies[index] = event?.target?.value
                this.$emit("update", this.events)
            },
            onInputClick: function () {
                this.$emit("update", { att: 'click', value: this.click })
            },
            addLine: function (index2, index) {

                this.events.onChange[index2]?.properties.dependencies.splice(index + 1, 0,
                    "");

                this.$emit("update", this.events)

                /*
                                if (this.values.length > 10) return;
                                this.values.splice(index + 1, 0, {
                                    id: "",
                                    title: "",
                                    value: "",
                                    default: ""
                                });
                                */
            },
            removeLine: function (index2, index) {


                if (this.events.onChange[index2]?.properties.dependencies.length < 2) return;
                this.events.onChange[index2]?.properties.dependencies.splice(index, 1);

                this.$emit("update", { type: 'onChange', events: this.events })

            }

        }
    }
</script>
<template>
  <div class="list-group mt-2  w-full flex items-start flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full   pt-6   px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-description-input"
          class="block mb-2 text-sm  font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          id="default-description-input"
          v-model="description"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm  font-medium text-gray-900 dark:text-white"
        >Template</label>
        <div class="w-full flex flex-row">
          <div class="  flex items-center mb-4 w-full">
            <label
              for="default-radio-1"
              class="hidden w ms-2 mr-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Format</label>
            <select
              id="template"
              v-model="template"
              class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="onChangeTemplate"
            >
              <option value="title-tpl-1">
                Petit Titre
              </option>
              <option value="title-tpl-2">
                Gros Titre
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm  font-medium text-gray-900 dark:text-white"
        >Options</label>

        <div class="flex flex-col">
          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isTitleDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsTitleDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage du Titre</label>
          </div>

          <div class="flex items-center me-4">
            <input
              id="inline-2-checkbox"
              v-model="isDescriptionDisplayed"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onChangeIsDescriptionDisplayed"
            >
            <label
              for="inline-2-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Affichage de la
              Description</label>
          </div>
        </div>
      </div>
      <div
        class="hidden flex items-center me-4 mb-6 border w-full border-t-gray-600 border-t-solid justify-center"
      >
        <button
          class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="addItem"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'TitleTableFormComponentDetails',
        props: {
            properties: Object,
            index: Number,
        },
        data() {
            return ({
                title: "",
                description: "",
                template: "title-tpl-1",
                isTitleDisplayed: false,
                isDescriptionDisplayed: false
            }
            )
        },
        watch:
        {
            properties: function () {
                this.title = this.properties.title;
                this.description = this.properties?.description;

                if (this.properties?.template != undefined)
                    this.template = this.properties?.template

                this.isTitleDisplayed = this.properties?.option?.title;
                this.isDescriptionDisplayed = this.properties?.option?.description;
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.description = this.properties?.description;

            if (this.properties?.template != undefined)
                this.template = this.properties?.template

            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;

        },
        methods:
        {
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.title', value: this.isTitleDisplayed })
            },

            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'option.description', value: this.isTitleDisplayed })
            },
            onChangeTemplate: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'template', value: this.template })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },
            onInputDescription: function () {

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            }
        }

    }
</script>
<template>
  <div
    class="  cursor-pointer  mt-2  w-full flex  flex-col items-start       dark:bg-gray-800 dark:border-gray-700     px-6 justify-center  "
  >
    <div v-if="properties != undefined">
      <div class="flex flex-col items-start w-full   pt-6  ">
        <div class="hidden   block text-black">
          <h1>*{{ properties?.attribute }}*</h1>
        </div>


        <label
          for="default-input"
          class="block mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >Evénement
          OnInit</label>
        <div
          v-if="events?.onInit?.length == 0"
          class="  mb-6 w-full text-left"
        >
          <div class="w-full">
            <label
              for="default-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Pas
              de
              triggers pour ce type d'élement</label>
          </div>
        </div>
        <draggable
          id="dd-005"
          class="dragArea list-group w-full"
          :list="events.onInit"
          @change="onMoveOnInit"
        >
          <div
            v-for="(event, index2) in events.onInit"
            :key="event.id"
            class="w-full "
          >
            <div
              v-if="event.action == 'refresh'"
              class="bg-white  mb-6 w-full text-left   border border-gray-200"
            >
              <div
                class=" w-full flex flex-row items-center justify-between border-b border-b-gray-200 pl-4 bg-gray-600 "
              >
                <label
                  for="template-input"
                  class="block  text-sm font-semibold text-white flex items-center"
                >Rafraichissement
                  Composant</label>
                <div class="  px-4  text-white flex items-center">
                  <div
                    class="ml-4  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-800 rounded-full"
                    @click="deleteOnInitEvent(event, index2)"
                  >
                    <i class="fa-regular fa-trash-can fa-xs  cursor-pointer" />
                  </div>
                </div>
              </div>
              <div
                v-for="(value, index) in event?.properties?.dependencies"
                :key="value"
                class="mt-2 px-4 w-full bg-white flex flex-row items-center  border-b-0 mb-1  border-gray-200"
              >
                <select
                  :id="`att-widget-init-${properties?.id}`"
                  :value="value"
                  class="mr-3 block py-2.5 px-3 w-full text-sm text-gray-500 bg-gray-100 border-0   border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  @change="changeSelectInit($event,index2,index)"
                >
                  <option
                    class="px-3"
                    value=""
                  />
                  <option
                    v-for="widget in record?.properties.pages[0].components"
                    :key="widget.id"
                    class="px-3"
                    :value="widget.id"
                  >
                    <b>{{ widget.title }}</b>
                  </option>
                </select>

                <div
                  class="font-medium  dark:text-red-500   mr-3 cursor-pointer"
                  :class="{'text-red-600 cursor-pointer' : ( event?.properties?.dependencies.length > 1), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length < 2) }"
                  @click="removeLineInit(index2 ,index)"
                >
                  <i
                    class="fa-solid fa-circle-minus fa-lg"
                  />
                </div>
                <div
                  class="font-medium dark:text-green-500  "
                  :class="{'text-green-600 cursor-pointer' : ( event?.properties?.dependencies.length < 10), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length > 9) }"
                  @click="addLineInit(index2 ,index)"
                >
                  <i class="fa-solid fa-circle-plus fa-lg" />
                </div>
              </div>
            </div>


            <div
              v-if="event.action == 'api'"
              class="bg-white  mb-3 w-full text-left  border border-gray-200"
            >
              <div
                class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200  bg-gray-600"
              >
                <label
                  for="template-input"
                  class="block   text-sm font-semibold  text-white flex items-center"
                >Appel
                  API</label>
                <div
                  class="  pl-4 pr-2  text-gray-900 flex items-center"
                  @click="deleteOnInitEvent(event, index2)"
                >
                  <div
                    class="ml-4  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-800 rounded-full"
                  >
                    <i class="fa-regular fa-trash-can text-white  fa-xs cursor-pointer " />
                  </div>
                </div>
              </div>

              <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
                <div class="  mb-6 w-full text-left flex flex-row items-center">
                  <label
                    for="default-input"
                    class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                  >URL</label>
                  <input
                    id="default-input"
                    v-model="event.properties.href"
                    type="text"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    @input="onInputURL"
                  >
                </div>

                <div class="  mb-6 w-full text-left flex flex-row items-center">
                  <label
                    for="default-input"
                    class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                  >Attribut</label>
                  <input
                    id="default-input"
                    v-model="event.properties.attribute"
                    type="text"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    @input="onInputAttribute"
                  >
                </div>
              </div>
            </div>
          </div>
        </draggable>


        <div class="flex flex-col space-y-1">
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnInitEventAPI"
          >
            Ajouter
            Appel API
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnInitEventRefresh"
          >
            Ajouter
            Rafraichir Composant
          </button>
        </div>
      </div>
      <div class="mt-6 h-1 border-b border-gray-300 w-full" />

      <div class="flex flex-col items-start w-full   pt-6  ">
        <label
          for="default-input"
          class="block mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >Evénements onChange</label>

        <div
          v-if="events?.onChange?.length == 0"
          class="  mb-6 w-full text-left"
        >
          <div class="w-full">
            <label
              for="default-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Pas
              de
              triggers pour ce type d'élement</label>

            <div class="hidden  block text-black">
              {{ properties }}
            </div>
          </div>
        </div>

        <div
          v-for="(event, index2) in events.onChange"
          :key="event.id"
          class="w-full "
        >
          <div
            v-if="event.action == 'refresh'"
            class="bg-white  mb-6 w-full text-left   border border-gray-400 pb-2"
          >
            <div
              class=" w-full flex flex-row items-center justify-between border-b border-b-gray-200 pl-4 bg-gray-600 "
            >
              <label
                for="template-input"
                class="block text-sm font-semibold text-white  flex items-center"
              >Rafraichissement
                Composant</label>
              <div class=" pl-4 pr-2  text-gray-900 flex items-center">
                <div
                  class="ml-4  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-800 rounded-full  "
                  @click="deleteOnChangeEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer text-white" />
                </div>
              </div>
            </div>



            <div
              v-for="(value, index) in event?.properties?.dependencies"
              :key="value"
              class="mt-2 px-4 w-full bg-white flex flex-row items-center  border-b-0 mb-1  border-gray-200"
            >
              <select
                :id="`att-widget-${properties?.id}`"
                :value="value"
                class="mr-3 block py-1.5 px-3 w-full text-sm text-gray-500 bg-gray-100 border-0   border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                @change="changeSelect($event,index2,index)"
              >
                <option
                  class="px-3"
                  value=""
                />
                <option
                  v-for="widget in record?.properties.pages[0].components"
                  :key="widget.id"
                  class="px-3"
                  :value="widget.id"
                >
                  <b>{{ widget.title }}</b>
                </option>
              </select>

              <div
                class="font-medium  dark:text-red-500  text-sm  mr-3 cursor-pointer"
                :class="{'text-red-600 cursor-pointer' : ( event?.properties?.dependencies.length > 1), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length < 2) }"
                @click="removeLine(index2 ,index)"
              >
                <i class="fa-solid fa-circle-minus fa-lg" />
              </div>
              <div
                class="font-medium dark:text-green-500  "
                :class="{'text-green-600 cursor-pointer' : ( event?.properties?.dependencies.length < 10), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length > 9) }"
                @click="addLine(index2 ,index)"
              >
                <i class="fa-solid fa-circle-plus fa-lg" />
              </div>
            </div>
          </div>


          <div
            v-if="event.action == 'api'"
            class="bg-white  mb-3 w-full text-left  border border-gray-200     "
          >
            <div
              class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200 bg-gray-600  w-full"
            >
              <label
                for="template-input"
                class="block  text-sm font-semibold  text-white flex items-center"
              >Appel API</label>
              <div class="  pl-4 pr-2 text-gray-900 flex items-center  justify-end">
                <div
                  class="ml-4 h-8 w-8  flex items-center justify-center cursor-pointer  hover:bg-gray-800 rounded-full"
                  @click="deleteOnChangeEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer   text-white" />
                </div>
              </div>
            </div>


            <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >URL</label>
                <input
                  id="default-input"
                  v-model="event.properties.href"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputURL"
                >
              </div>
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >Attribut</label>
                <input
                  id="default-input"
                  v-model="event.properties.attribute"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputAttribute"
                >
              </div>
            </div>
          </div>



          <div
            v-if="event.action == 'set'"
            class="bg-white  mb-3 w-full text-left  border border-gray-200     "
          >
            <div
              class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200 bg-gray-600  w-full"
            >
              <label
                for="template-input"
                class="block  text-sm font-semibold  text-white flex items-center"
              >Set Value !!!</label>
              <div class="  pl-4 pr-2 text-gray-900 flex items-center  justify-end">
                <div
                  class="ml-4 h-8 w-8  flex items-center justify-center cursor-pointer  hover:bg-gray-800 rounded-full"
                  @click="deleteOnClickEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer   text-white" />
                </div>
              </div>
            </div>


            <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >Attribut</label>
                <input
                  id="default-input"
                  v-model="event.properties.a"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputURL"
                >
              </div>
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >Value</label>
                <input
                  id="default-input"
                  v-model="event.properties.b"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputAttribute"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnChangeEventAPI"
          >
            Ajouter
            Appel API
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnChangeEventRefresh"
          >
            Ajouter
            Raffraîssemnt Composant --
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnChangeEventRefresh"
          >
            Ajouter
            Assigner Valeur
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnChangeEventRefresh"
          >
            Ajouter
            Afficher Popup
          </button>
        </div>
      </div>






      <div class="flex flex-col items-start w-full   pt-6  ">
        <label
          for="default-input"
          class="block mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >Evénement
          onClick</label>

        <div
          v-if="events?.onClick?.length == 0"
          class="  mb-6 w-full text-left"
        >
          <div class="w-full">
            <label
              for="default-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Pas
              de
              triggers pour ce type d'élement</label>

            <div class="hidden  block text-black">
              {{ properties }}
            </div>
          </div>
        </div>

        <div
          v-for="(event, index2) in events.onClick"
          :key="event.id"
          class="w-full "
        >
          <div
            v-if="event.action == 'refresh'"
            class="bg-white  mb-6 w-full text-left   border border-gray-400 pb-2"
          >
            <div
              class=" w-full flex flex-row items-center justify-between border-b border-b-gray-200 pl-4 bg-gray-600 "
            >
              <label
                for="template-input"
                class="block text-sm font-semibold text-white  flex items-center"
              >Rafraichissement
                Composant</label>
              <div class=" pl-4 pr-2  text-gray-900 flex items-center">
                <div
                  class="ml-4  h-8 w-8 flex items-center justify-center cursor-pointer hover:bg-gray-800 rounded-full  "
                  @click="deleteOnClickEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer text-white" />
                </div>
              </div>
            </div>



            <div
              v-for="(value, index) in event?.properties?.dependencies"
              :key="value"
              class="mt-2 px-4 w-full bg-white flex flex-row items-center  border-b-0 mb-1  border-gray-200"
            >
              <select
                :id="`att-widget-${properties?.id}`"
                :value="value"
                class="mr-3 block py-1.5 px-3 w-full text-sm text-gray-500 bg-gray-100 border-0   border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                @change="changeSelect($event,index2,index)"
              >
                <option
                  class="px-3"
                  value=""
                />
                <option
                  v-for="widget in record?.properties.pages[0].components"
                  :key="widget.id"
                  class="px-3"
                  :value="widget.id"
                >
                  <b>{{ widget.title }}</b>
                </option>
              </select>

              <div
                class="font-medium  dark:text-red-500  text-sm  mr-3 cursor-pointer"
                :class="{'text-red-600 cursor-pointer' : ( event?.properties?.dependencies.length > 1), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length < 2) }"
                @click="removeLine(index2 ,index)"
              >
                <i class="fa-solid fa-circle-minus fa-lg" />
              </div>
              <div
                class="font-medium dark:text-green-500  "
                :class="{'text-green-600 cursor-pointer' : ( event?.properties?.dependencies.length < 10), 'text-gray-300 cursor-pointer' : ( event?.properties?.dependencies.length > 9) }"
                @click="addLine(index2 ,index)"
              >
                <i class="fa-solid fa-circle-plus fa-lg" />
              </div>
            </div>
          </div>


          <div
            v-if="event.action == 'api'"
            class="bg-white  mb-3 w-full text-left  border border-gray-200     "
          >
            <div
              class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200 bg-gray-600  w-full"
            >
              <label
                for="template-input"
                class="block  text-sm font-semibold  text-white flex items-center"
              >Appel API</label>
              <div class="  pl-4 pr-2 text-gray-900 flex items-center  justify-end">
                <div
                  class="ml-4 h-8 w-8  flex items-center justify-center cursor-pointer  hover:bg-gray-800 rounded-full"
                  @click="deleteOnClickEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer   text-white" />
                </div>
              </div>
            </div>


            <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >URL</label>
                <input
                  id="default-input"
                  v-model="event.properties.href"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputURL"
                >
              </div>
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >Attribut</label>
                <input
                  id="default-input"
                  v-model="event.properties.attribute"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputAttribute"
                >
              </div>
            </div>
          </div>




          <div
            v-if="event.action == 'set'"
            class="bg-white  mb-3 w-full text-left  border border-gray-200     "
          >
            <div
              class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200 bg-gray-600  w-full"
            >
              <label
                for="template-input"
                class="block  text-sm font-semibold  text-white flex items-center"
              >Set Value</label>
              <div class="  pl-4 pr-2 text-gray-900 flex items-center  justify-end">
                <div
                  class="ml-4 h-8 w-8  flex items-center justify-center cursor-pointer  hover:bg-gray-800 rounded-full"
                  @click="deleteOnClickEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer   text-white" />
                </div>
              </div>
            </div>


            <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >URL</label>
                <input
                  id="default-input"
                  v-model="event.properties.a"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputURL"
                >
              </div>
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >Attribut</label>
                <input
                  id="default-input"
                  v-model="event.properties.b"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputAttribute"
                >
              </div>
            </div>
          </div>

          <div
            v-if="event.action == 'popup'"
            class="bg-white  mb-3 w-full text-left  border border-gray-200     "
          >
            <div
              class=" w-full flex flex-row items-center justify-between  pl-4   border-b border-gray-200 bg-gray-600  w-full"
            >
              <label
                for="template-input"
                class="block  text-sm font-semibold  text-white flex items-center"
              >Open PopUp</label>
              <div class="  pl-4 pr-2 text-gray-900 flex items-center  justify-end">
                <div
                  class="ml-4 h-8 w-8  flex items-center justify-center cursor-pointer  hover:bg-gray-800 rounded-full"
                  @click="deleteOnClickEvent(event,index2)"
                >
                  <i class="fa-regular fa-trash-can fa-xs cursor-pointer   text-white" />
                </div>
              </div>
            </div>


            <div class="mt-2 w-full bg-white flex flex-col items-center px-4">
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >View</label>
                <input
                  id="default-input"
                  v-model="event.properties.view"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputView"
                >
              </div>
              <div class="  mb-6 w-full text-left flex flex-row items-center">
                <label
                  for="default-input"
                  class="block   text-xs font-medium text-gray-900 dark:text-white w-24"
                >Max</label>
                <input
                  id="default-input"
                  v-model="event.properties.max"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @input="onInputMax"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnClickEventAPI"
          >
            Ajouter
            Appel API
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addClickEventRefresh"
          >
            Ajouter
            Raffraîssemnt Composant --
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addClickEventSet"
          >
            Ajouter
            Assigner Valeur
          </button>
          <button
            type="button"
            class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="addOnClickEventPopup"
          >
            Ajouter
            Afficher Popup
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

    import { VueDraggableNext } from 'vue-draggable-next'

    export default {
        name: 'TriggersTableFormComponentDetails',
        components: { draggable: VueDraggableNext, },
        props: {
            form: Object,
            properties: Object,
            record: Object,
            index: Number,
        },
        data() {

            return {
                values: [
                    {
                        id: ''
                    }
                ],
                events: {
                    'onChange': [],
                    'onClick': [],
                    'onInit': []
                },

                title: "",
                description: "",
                format: "",
                click: "",
                isTitleDisplayed: false,
                isDescriptionDisplayed: false
            }
        },
        computed: {
            displayMessage: function () {

                if (this.properties?.type != 'avatar') return true

                return false;
            },
            displaySelect: function () {

                if (this.properties?.type == 'avatar') return true



                return false;
            }
        },
        watch:
        {
            properties: function () {

                this.events = JSON.parse(JSON.stringify(this.properties?._events));
                if (this.events == undefined)
                    this.events = {
                        'onChange': [],
                        'onInit': []
                    }
                if (this.events.onClick == undefined) this.events.onClick = []
                if (this.events.onChange == undefined) this.events.onChange = []
                if (this.events.onInit == undefined) this.events.onInit = []
            }
        },
        mounted() {


            let events = undefined;

            if (this.properties?._events != undefined)
                events = JSON.parse(JSON.stringify(this.properties?._events));



            if (events == undefined)
                events = {
                    'onChange': [],
                    'onClick': [],
                    'onInit': []
                }


            if (events.onClick == undefined)  events.onClick = []

            if (events.onChange == undefined) events.onChange = []
            if (events.onInit == undefined) events.onInit = []

            this.events = events

        },
        methods:
        {
            onMoveOnInit(event) {
                console.log("onMoveOnInit")
                console.log(event)
            },
            addOnInitEventAPI() {
                console.log("addOnInitEventAPI")

                let eventAPI = {
                    "action": "api",
                    "properties": {
                        "href": "",
                        "attribute": "attribut"
                    }
                }

                this.events.onInit.push(eventAPI);
                // this.$emit("updateField", this.events)
            },
            addOnInitEventRefresh() {
                console.log("addOnInitEventRefresh")

                let event = {
                    "action": "refresh",
                    "properties": {
                        "dependencies": [""],
                        "fields": []
                    }
                }

                event.properties.fields.push(this.properties?.attribute)

                this.events.onInit.push(event);
                //  this.$emit("updateField", this.events)
            },
            deleteOnInitEvent(item, index) {
                console.log("deleteOnInitEvent", JSON.stringify(item))
                console.log("index  = ", index)
                const arrayOfLetters = this.events.onInit

                const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

                const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                this.events.onInit = copyWithoutThirdElement
                console.log("New Array")
                console.log(JSON.stringify(this.events.onInit))
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

            },
            addOnChangeEventAPI() {
                console.log("addOnChangeEventAPI")
                let eventAPI = {
                    "action": "api",
                    "properties": {
                        "href": "",
                        "attribute": "attribut"
                    }
                }
                this.events.onChange.push(eventAPI);
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

                //  this.$emit("updateField", this.events)
            },

            addOnChangeEventRefresh() {
                console.log("addOnChangeEventRefresh")
                let event = {
                    "action": "refresh",
                    "properties": {
                        "dependencies": [""],
                        "fields": []
                    }
                }
                event.properties.fields.push(this.properties?.attribute)

                this.events.onChange.push(event);
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })


            },
            deleteOnChangeEvent(item, index) {

                console.log("deleteOnchangeItem", JSON.stringify(item))
                const arrayOfLetters = this.events.onChange

                const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

                const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                this.events.onChange = copyWithoutThirdElement
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

            },



            changeSelect(event, index2, index) {
                console.log("RIGHT changeSelect")
                this.events.onChange[index2].properties.dependencies[index] = event?.target?.value
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

                //   this.$emit("updateField", this.events)
            },
            changeSelectInit(event, index2, index) {
                console.log("RIGHT changeSelect")
                this.events.onInit[index2].properties.dependencies[index] = event?.target?.value
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

                //   this.$emit("updateField", this.events)
            },
            onInputClick: function () {
                //  console.log("RIGHT onInputClick")
                //     this.$emit("updateField", { id: this.properties?.id, tab: "triggers", att: 'click', value: this.click })
            },
            addLine: function (index2, index) {
                console.log("RIGHT addLine")
                this.events.onChange[index2]?.properties.dependencies.splice(index + 1, 0,
                    "");

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

            },
            addLineInit: function (index2, index) {
                console.log("RIGHT addLine")
                this.events.onInit[index2]?.properties.dependencies.splice(index + 1, 0,
                    "");

                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

            },




            removeLine: function (index2, index) {
                console.log("RIGHT removeLine")

                if (this.events.onChange[index2]?.properties.dependencies.length < 2) return;
                //this.events.onChange[index2]?.properties.dependencies.splice(index, 1);

                const arrayOfLetters = this.events.onChange[index2]?.properties.dependencies
                console.log(arrayOfLetters)
                const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

                const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                console.log(copyWithoutThirdElement)
                this.events.onChange[index2].properties.dependencies = copyWithoutThirdElement
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })
            },

            removeLineInit: function (index2, index) {



                if (this.events.onInit[index2]?.properties.dependencies.length < 2) return;



                const arrayOfLetters = this.events.onInit[index2]?.properties.dependencies
                console.log(arrayOfLetters)
                const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

                const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                console.log(copyWithoutThirdElement)
                this.events.onInit[index2].properties.dependencies = copyWithoutThirdElement




                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })


            },
            addOnClickEventAPI() {
                console.log("addOnClickEventAPI")
                let eventAPI = {
                    "action": "api",
                    "properties": {
                        "href": "",
                        "attribute": "attribut"
                    }
                }
                this.events.onClick.push(eventAPI);
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

                //  this.$emit("updateField", this.events)
            },

            addOnClickEventRefresh() {
                console.log("addOnClickEventRefresh")
                let event = {
                    "action": "refresh",
                    "properties": {
                        "dependencies": [""],
                        "fields": []
                    }
                }
                event.properties.fields.push(this.properties?.attribute)

                this.events.onClick.push(event);
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })


            },


            addOnClickEventPopup() {
                console.log("addOnClickEventPopup")
                let eventAPI = {
                    "action": "popup",
                    "properties": {
                        "view": "",
                        "max": "1"
                    }
                }
                this.events.onClick.push(eventAPI);
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

                //  this.$emit("updateField", this.events)
            },

            deleteOnClickEvent(item, index) {

                console.log("deleteOnClickItem", JSON.stringify(item))
                const arrayOfLetters = this.events.onClick

                const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

                const halfAfterTheUnwantedElement = arrayOfLetters.slice(index + 1);
                const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);
                this.events.onClick = copyWithoutThirdElement
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: '_events', value: this.events })

            }


        }
    }
</script>
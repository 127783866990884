<template>
  <div class="    w-full text-left flex flex-row items-center h-96">
    <div class="w-full flex flex-col min-h-96 max-h-96  overflow-y-auto   pb-6">
      <div class="w-full">
        <label
          for="labelFR"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre
          en Français</label>
        <input
          id="labelEN"
          v-model="output.title"
          type="text"
          name="labelFR"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder=""
        >
      </div>



      <div class="flex gap-4 mb-4 sm:grid-cols-2 w-full">
        <div class=" ">
          <label
            for="labelEN"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Titre
            en Anglais</label>
          <input
            id="labelEN"
            type="text"
            name="labelEN"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder=""
          >
        </div>
        <div class=" ">
          <label
            for="labelEN"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Titre
            en Anglais</label>
          <input
            id="labelEN"
            type="text"
            name="labelEN"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder=""
          >
        </div>
      </div>
      <div class="w-full ">
        <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
          Thème
        </h3>

        <div class="w-full grid gap-4 mb-2 sm:grid-cols-2   ">
          <div class="w-full flex flex-col">
            <label
              for="color-picker"
              class="block mb-1 font-normal"
            >Couleur du
              texte </label>
            <div class="flex flex-row relative items-center  ">
              <div
                id="color-picker"
                class=" w-48 border border-gray-200 p-1 rounded-lg bg-white flex   items-center justify-center"
                @click="onInputClick"
              >
                <div
                  class="w-full h-8 rounded-sm"
                  :class="`bg-${currentTitleColor}`"
                />
              </div>


              <div
                id="dropdown-input-color"
                class="z-50 hidden bg-gray-50 divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 flex "
              >
                <div
                  class="flex bg-gray-50"
                  aria-labelledby="dropdown-input-color"
                >
                  <div class="">
                    <div
                      class="bg-white cursor-pointer w-6 h-6 rounded-lg mx-1 my-1 border border-gray-400"
                      @click="selectColor('white','')"
                    />
                    <div
                      class="bg-black cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                      @click="selectColor('black','')"
                    />
                  </div>

                  <template
                    v-for="color in colors"
                    :key="color"
                  >
                    <div class="">
                      <template
                        v-for="variant in variants"
                        :key="variant"
                      >
                        <div
                          class="cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                          :class="`bg-${color}-${variant}`"
                          @click="selectColor(color,variant)"
                        />
                      </template>
                    </div>
                  </template>
                </div>
                <div />
              </div>
            </div>
          </div>







          <div class="w-full flex flex-col relative">
            <label
              for="bg-color-picker"
              class="block mb-1 font-normal"
            >Couleur du
              fond du titre {{ currentTitleBgColor }}</label>
            <div class="flex flex-row relative items-center  ">
              <div
                id="bg-title-color-picker"
                class=" w-48 border border-gray-200 p-1 rounded-lg bg-white flex   items-center justify-center"
                @click="onInputTitleBgClick"
              >
                <div
                  class="w-full h-8 rounded-sm"
                  :class="`bg-${currentTitleBgColor}`"
                />
              </div>


              <div
                id="dropdown-input-bg-title-color"
                class="z-50 hidden bg-white divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 flex "
              >
                <div
                  class="flex bg-gray-50"
                  aria-labelledby="dropdown-input-bg-title-color"
                >
                  <div class="">
                    <div
                      class="bg-white cursor-pointer w-6 h-6 rounded-lg mx-1 my-1 border border-gray-400"
                      @click="selectTitleBgColor('white','')"
                    />
                    <div
                      class="bg-black cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                      @click="selectTitleBgColor('black','')"
                    />
                  </div>

                  <template
                    v-for="color in colors"
                    :key="color"
                  >
                    <div class="">
                      <template
                        v-for="variant in variants"
                        :key="variant"
                      >
                        <div
                          class="cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                          :class="`bg-${color}-${variant}`"
                          @click="selectTitleBgColor(color,variant)"
                        />
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
















          <div class="w-full flex flex-col relative">
            <label
              for="bg-color-picker"
              class="block mb-1 font-normal"
            >Couleur du
              fond {{ currentBgColor }}</label>
            <div class="flex flex-row relative items-center  ">
              <div
                id="bg-color-picker"
                class=" w-48 border border-gray-200 p-1 rounded-lg bg-white flex   items-center justify-center"
                @click="onInputBgClick"
              >
                <div
                  class="w-full h-8 rounded-sm"
                  :class="`bg-${currentBgColor}`"
                />
              </div>


              <div
                id="dropdown-input-bg-color"
                class="z-50 hidden bg-white divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 flex "
              >
                <div
                  class="flex bg-gray-50"
                  aria-labelledby="dropdown-input-bg-color"
                >
                  <div class="">
                    <div
                      class="bg-white cursor-pointer w-6 h-6 rounded-lg mx-1 my-1 border border-gray-400"
                      @click="selectBgColor('white','')"
                    />
                    <div
                      class="bg-black cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                      @click="selectBgColor('black','')"
                    />
                  </div>

                  <template
                    v-for="color in colors"
                    :key="color"
                  >
                    <div class="">
                      <template
                        v-for="variant in variants"
                        :key="variant"
                      >
                        <div
                          class="cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                          :class="`bg-${color}-${variant}`"
                          @click="selectBgColor(color,variant)"
                        />
                      </template>
                    </div>
                  </template>
                </div>
                <div />
              </div>
            </div>
          </div>





          <div class="w-full flex flex-col relative">
            <label
              for="bg-color-picker"
              class="block mb-1 font-normal"
            >Couleur du
              fond du Graphique {{ currentChartBgColor }}</label>
            <div class="flex flex-row relative items-center  ">
              <div
                id="bg-chart-color-picker"
                class=" w-48 border border-gray-200 p-1 rounded-lg bg-white flex   items-center justify-center"
                @click="onInputChartBgClick"
              >
                <div
                  class="w-full h-8 rounded-sm"
                  :class="`bg-${currentChartBgColor}`"
                />
              </div>


              <div
                id="dropdown-input-bg-chart-color"
                class="z-50 hidden bg-white divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 flex "
              >
                <div
                  class="flex bg-gray-50"
                  aria-labelledby="dropdown-input-bg-chart-color"
                >
                  <div class="">
                    <div
                      class="bg-white cursor-pointer w-6 h-6 rounded-lg mx-1 my-1 border border-gray-400"
                      @click="selectChartBgColor('white','')"
                    />
                    <div
                      class="bg-black cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                      @click="selectChartBgColor('black','')"
                    />
                  </div>

                  <template
                    v-for="color in colors"
                    :key="color"
                  >
                    <div class="">
                      <template
                        v-for="variant in variants"
                        :key="variant"
                      >
                        <div
                          class="cursor-pointer w-6 h-6 rounded-lg mx-1 my-1"
                          :class="`bg-${color}-${variant}`"
                          @click="selectChartBgColor(color,variant)"
                        />
                      </template>
                    </div>
                  </template>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="w-full">
        <div class="w-full">
          <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">
            Largeur
          </h3>
          <ul
            class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-1"
                  v-model="layout"
                  type="radio"
                  value="1/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-1"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >1/12</label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-2"
                  v-model="layout"
                  type="radio"
                  value="w-2/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-2"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >2/12</label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-3"
                  v-model="layout"
                  type="radio"
                  value="w-3/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-3"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >3/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-4"
                  v-model="layout"
                  type="radio"
                  value="w-4/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-4"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >4/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-5"
                  v-model="layout"
                  type="radio"
                  value="w-5/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-5"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >5/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-6"
                  v-model="layout"
                  type="radio"
                  value="w-6/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-6"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >6/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-7"
                  v-model="layout"
                  type="radio"
                  value="w-7/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-7"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >7/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-8"
                  v-model="layout"
                  type="radio"
                  value="w-8/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-8"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >8/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-9"
                  v-model="layout"
                  type="radio"
                  value="w-9/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-9"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >9/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-10"
                  v-model="layout"
                  type="radio"
                  value="w-10/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-10"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >10/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-11"
                  v-model="layout"
                  type="radio"
                  value="w-11/12"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-11"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >11/12</label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input
                  id="layout-radio-12"
                  v-model="layout"
                  type="radio"
                  value="w-full"
                  name="layout-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                >
                <label
                  for="layout-radio-12"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >12/12</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import { Dropdown } from 'flowbite';


    export default {
        name: 'ThemeEditDiagramModal',
        components: {

        },
        props: {


        },
        data() {
            return ({
                lg: "FR",



                dropdownTitleBg: null,
                dropdownChartBg: null,
                dropdownBg: null,
                currentTitleBgColor: "",
                currentChartBgColor: "",
                currentTitleColor: "",
                currentBgColor: "",
                layout: "",
                output: {},
                colors: ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'],
                variants: [100, 200, 300, 400, 500, 600, 700, 800, 900],

            })
        },
        watch: {


        },

        mounted() {

        },
        methods: {
            selectColor: function (color, variant) {
                if (color == "black") {
                    this.currentTitleColor = color;
                }
                else if (color == "white") {
                    this.currentTitleColor = color;
                }
                else
                    this.currentTitleColor = color + "-" + variant;
                this.dropdown.hide()
            },
            selectBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentBgColor = color;
                }
                else if (color == "white") {
                    this.currentBgColor = color;
                }
                else this.currentBgColor = color + "-" + variant;
                this.dropdownBg.hide()
            },
            selectChartBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentChartBgColor = color;
                }
                else if (color == "white") {
                    this.currentChartBgColor = color;
                }
                else this.currentChartBgColor = color + "-" + variant;
                this.dropdownChartBg.hide()
            },
            selectTitleBgColor: function (color, variant) {
                if (color == "black") {
                    this.currentTitleBgColor = color;
                }
                else if (color == "white") {
                    this.currentTitleBgColor = color;
                }
                else this.currentTitleBgColor = color + "-" + variant;
                this.dropdownTitleBg.hide()
            },
            onInputClick: function () {

                const $targetEl = document.getElementById("dropdown-input-color");
                const $triggerEl = document.getElementById("color-picker");

                if ($triggerEl != undefined) {
                    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdown.show()
            },
            onInputBgClick: function () {

                const $targetEl = document.getElementById("dropdown-input-bg-color");
                const $triggerEl = document.getElementById("bg-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownBg.show()
            },
            onInputChartBgClick: function () {

                const $targetEl = document.getElementById("dropdown-input-bg-chart-color");
                const $triggerEl = document.getElementById("bg-chart-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownChartBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownChartBg.show()
            },
            onInputTitleBgClick: function () {

                const $targetEl = document.getElementById("dropdown-input-bg-title-color");
                const $triggerEl = document.getElementById("bg-title-color-picker");

                if ($triggerEl != undefined) {
                    this.dropdownTitleBg = new Dropdown($targetEl, $triggerEl, this.options);

                }
                this.dropdownTitleBg.show()
            },
            findTextColor: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("bg-") != -1) {
                    if (item.indexOf("text-gray") != -1) {
                        return this.findColorVariant(item, "text-gray", "gray");
                    }
                    if (item.indexOf("text-red") != -1) {
                        return this.findColorVariant(item, "text-red", "red");
                    }
                    if (item.indexOf("text-yellow") != -1) {
                        return this.findColorVariant(item, "text-yellow", "yellow");
                    }

                    if (item.indexOf("text-green") != -1) {
                        return this.findColorVariant(item, "text-green", "green");
                    }
                    if (item.indexOf("text-blue") != -1) {
                        return this.findColorVariant(item, "text-blue", "blue");
                    }
                    if (item.indexOf("text-indigo") != -1) {
                        return this.findColorVariant(item, "text-indigo", "indigo");
                    }
                    if (item.indexOf("text-purple") != -1) {
                        return this.findColorVariant(item, "text-purple", "purple");
                    }
                    if (item.indexOf("text-pink") != -1) {
                        return this.findColorVariant(item, "text-pink", "pink");
                    }
                    if (item.indexOf("text-white") != -1) {
                        return "white";
                    }
                    if (item.indexOf("text-black") != -1) {
                        return "black";
                    }
                    return "gray-900"
                }

                return "gray-900"
            },
            findColorVariant(item, tag, color) {
                if (item == undefined) return "";
                if (item.indexOf(tag + "-100") != -1) {
                    return color + "-100"
                }
                if (item.indexOf(tag + "-200") != -1) {
                    return color + "-200"
                }
                if (item.indexOf(tag + "-300") != -1) {
                    return color + "-300"
                }
                if (item.indexOf(tag + "-400") != -1) {
                    return color + "-400"
                }
                if (item.indexOf(tag + "-500") != -1) {
                    return color + "-500"
                }
                if (item.indexOf(tag + "-600") != -1) {
                    return color + "-600"
                }
                if (item.indexOf(tag + "-700") != -1) {
                    return color + "-700"
                }
                if (item.indexOf(tag + "-800") != -1) {
                    return color + "-800"
                }
                if (item.indexOf(tag + "-900") != -1) {
                    return color + "-900"
                }
                if (item.indexOf(tag) != -1) {
                    return color
                }
                return ""
            },
            findBgColor: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("bg-") != -1) {
                    if (item.indexOf("bg-gray") != -1) {
                        return this.findColorVariant(item, "bg-gray", "gray");
                    }
                    if (item.indexOf("bg-red") != -1) {
                        return this.findColorVariant(item, "bg-red", "red");
                    }
                    if (item.indexOf("bg-yellow") != -1) {
                        return this.findColorVariant(item, "bg-yellow", "yellow");
                    }

                    if (item.indexOf("bg-green") != -1) {
                        return this.findColorVariant(item, "bg-green", "green");
                    }
                    if (item.indexOf("bg-blue") != -1) {
                        return this.findColorVariant(item, "bg-blue", "blue");
                    }
                    if (item.indexOf("bg-indigo") != -1) {
                        return this.findColorVariant(item, "bg-indigo", "indigo");
                    }
                    if (item.indexOf("bg-purple") != -1) {
                        return this.findColorVariant(item, "bg-purple", "purple");
                    }
                    if (item.indexOf("bg-pink") != -1) {
                        return this.findColorVariant(item, "bg-pink", "pink");
                    }
                    if (item.indexOf("bg-white") != -1) {
                        return "white";
                    }
                    if (item.indexOf("bg-black") != -1) {
                        return "black";
                    }
                    return "white"
                }

                return "white"
            },
            findLayout: function (item) {
                if (item == undefined) return "";
                if (item.indexOf("w-1/12") != -1) return "w-1/12";
                if (item.indexOf("w-2/12") != -1) return "w-2/12";
                if (item.indexOf("w-3/12") != -1) return "w-3/12";
                if (item.indexOf("w-4/12") != -1) return "w-4/12";
                if (item.indexOf("w-5/12") != -1) return "w-5/12";
                if (item.indexOf("w-6/12") != -1) return "w-6/12";
                if (item.indexOf("w-7/12") != -1) return "w-7/12";
                if (item.indexOf("w-8/12") != -1) return "w-8/12";
                if (item.indexOf("w-9/12") != -1) return "w-9/12";
                if (item.indexOf("w-10/12") != -1) return "w-10/12";
                if (item.indexOf("w-11/12") != -1) return "w-11/12";
                if (item.indexOf("w-12/12") != -1) return "w-full";
                if (item.indexOf("w-full") != -1) return "w-full";
                return "w-full";
            },
            buildClass() {
                return this.layout;
            },
            buildContainerClass() {
                return " bg-" + this.currentBgColor;
            },
            buildChartClass() {
                return " bg-" + this.currentChartBgColor;
            },
            buildTitleClass() {
                return " bg-" + this.currentTitleBgColor + " text-" + this.currentTitleColor;
            },

        }
    }
</script>
<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6 bg-gray-100 px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Icones</label>
        <div class="w-full flex flex-row">
          <div class="ml-3 flex items-center mb-4 w-64">
            <label
              for="default-radio-1"
              class="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Avant</label>

            <select
              id="iconeprefix"
              v-model="prefix"
              class="w-16 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="onChangeIconPrefix"
            >
              <option value="none">
                Pas d'icone <i class="fa-solid fa-chevron-right" />
              </option>
              <option value="chevron-right">
                Chevron Droit
              </option>
              <option value="chevron-left">
                Chevron Gauche
              </option>
            </select>
          </div>


          <div class="  ml-3 flex items-center mb-4 w-64">
            <label
              for="default-radio-2"
              class="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Aprés</label>

            <select
              id="iconesuffix"
              v-model="suffix"
              class="w-16 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="onChangeIconSuffix"
            >
              <option value="none">
                Pas d'icone
              </option>
              <option value="chevron-right">
                Chevron Droit
              </option>
              <option value="chevron-left">
                Chevron Gauche <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 20"
                >
                  <path
                    d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z"
                  />
                </svg>
              </option>
            </select>
          </div>
        </div>
      </div>




      <div class="  mb-6 w-full text-left">
        <label
          for="default-format-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Format</label>
        <div class="w-full flex flex-row">
          <div class="ml-3 flex items-center mb-4 w-48">
            <input
              id="format-radio-1"
              v-model="format"
              type="radio"
              value="RELATIVE"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputFormat"
            >
            <label
              for="format-radio-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Position Relative</label>
          </div>


          <div class="  ml-3 flex items-center mb-4 w-48">
            <input
              id="format-radio-2"
              v-model="format"
              type="radio"
              value="FIXED-BOTTOM"
              name="format-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputFormat"
            >
            <label
              for="format-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Postion Fixe en bas</label>
          </div>
        </div>
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-aligmment-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Alignement ({{ format }})</label>
        <div class="w-full flex flex-row">
          <div class="ml-3 flex items-center mb-4 w-36">
            <input
              id="aligmment-radio-1"
              v-model="alignment"
              type="radio"
              value="start"
              name="aligmment-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputAlignment"
            >
            <label
              for="aligmment-radio-1"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Gauche</label>
          </div>


          <div class="  ml-3 flex items-center mb-4 w-36">
            <input
              id="aligmment-radio-2"
              v-model="alignment"
              checked
              type="radio"
              value="center"
              name="aligmment-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputAlignment"
            >
            <label
              for="aligmment-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Centre</label>
          </div>

          <div class="  ml-3 flex items-center mb-4 w-36">
            <input
              id="aligmment-radio-3"
              v-model="alignment"
              checked
              type="radio"
              value="end"
              name="aligmment-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @change="onInputAlignment"
            >
            <label
              for="aligmment-radio-2"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Droite</label>
          </div>
        </div>
      </div>


      <div class=" hidden  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex">
          <div class="  flex items-center">
            <input
              id="inline-disabled-checkbox"
              disabled
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="inline-disabled-checkbox"
              class="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500"
            >option</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ValidateTableFormComponentDetails',
        props: {
            element: Object
        },
        data() {

            return {
                title: "",
                format: "relative",
                position: "fixed",
                alignment: "left",
                suffix: "none",
                prefix: "none"

            }
        },
        watch:
        {
            element: function () {
                this.title = this.element.title;
                this.alignment = this.element.alignment;
                this.position = this.element.position
                this.prefix = this.element.prefix
                this.format = this.element.format

            }
        },
        mounted() {
            this.title = this.element?.title
            this.format = this.element.format
            this.position = this.element.position
            this.prefix = this.element.prefix
            this.suffix = this.element.suffix
            this.alignment = this.element.alignment

        },
        methods:
        {
            onInputPosition: function () {
                this.$emit("updateField", { att: 'position', value: this.position })
            },


            onInputAlignment: function () {
                this.$emit("updateField", { att: 'alignment', value: this.alignment })
            },

            onInputFormat: function () {
                this.$emit("updateField", { att: 'format', value: this.format })
            },
            onInputTitle: function () {
                this.$emit("updateField", { att: 'title', value: this.title })
            },


            onChangeIconPrefix: function () {
                this.$emit("updateField", { att: 'prefix', value: this.prefix })
            },


            onChangeIconSuffix: function () {
                this.$emit("updateField", { att: 'suffix', value: this.suffix })
            }


        }
    }
</script>
<template>
  <div
    id="edit-tablet-form-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-6xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Formulaires **
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="closeDialog"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <div
            class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
          >
            <ul class="flex flex-wrap -mb-px">
              <li
                class="me-2"
                @click="selectTab(0)"
              >
                <a
                  href="#"
                  class="inline-block p-4  border-b-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  aria-current="page"
                  :class=" {'text-blue-600 border-blue-600 ':tab==0, 'border-transparent ': tab!=0}"
                >Formulaires</a>
              </li>
              <li
                class="me-2"
                @click="selectTab(1)"
              >
                <a
                  href="#"
                  class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  :class=" {'text-blue-600 border-blue-600 ':tab==1, 'border-transparent ': tab!=1}"
                >Tableau
                  1</a>
              </li>
              <li
                class="me-2"
                @click="selectTab(2)"
              >
                <a
                  href="#"
                  class="inline-block p-4 border-b-2   rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  :class=" {'text-blue-600 border-blue-600 ':tab==2, 'border-transparent ': tab!=2}"
                >Tableau
                  2</a>
              </li>
            </ul>
          </div>
          <div class="  mb-6 w-full text-left flex flex-row items-center mt-3">
            <label
              for="default-input"
              class="block mb-2 text-lg font-bold text-gray-900 dark:text-white mr-3"
            >Titre du Formulaire
              : </label>
            <input
              id="default-input"
              v-model="title[tab]"
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @input="onInputTitle"
            >
          </div>







          <div class="flex flex-row mt-3">
            <div class="w-6/12 flex flex-col  ">
              <div
                class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[590px]    w-[410px]  "
              >
                <div
                  class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg  "
                />
                <div
                  class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg  "
                />
                <div
                  class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg  "
                />
                <div
                  class=" h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg  "
                />
                <div class="mt-4 overflow-hidden h-[540px]   bg-gray-700 dark:bg-gray-800 bg-gray-700">
                  <headerComponent :title="title[tab]" />

                  <div
                    class=" dark:hidden    w-96 bg-gray-700 overflow-y-auto overflow-x-hidden"
                    style="height:510px;padding-right: 0px;box-sizing: content-box; width:100%"
                  >
                    <draggable
                      ghost-class="ghost"
                      draggable=".item:not(.exclude-this-item)"
                      class="mt-1 w-full   min-h-96 border-1 border-solid items-start flex flex-col  bg-gray-700  mb-12 border-gray-300   py-2 h-96"
                      :list="lines[tab]"
                      item-key="name"
                      @start="onDragStart($event)"
                      @end="onDrop($event)"
                    >
                      <template #header2>
                        <div
                          class="   mb-2 w-full py-2 flex justify-center"
                          role="group"
                        >
                          <button
                            type="button"
                            class="  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            @click="onOpenList"
                          >
                            <i class="fa-solid fa-plus  mr-2" />
                            {{ labels['BUTTON_ADD'][lg] }} {{ parent }}
                          </button>
                        </div>
                      </template>

                      <template #item="{element, index}">
                        <div
                          class="w-full bg-gray-700  "
                          :class="{'exclude-this-item':(element?.type=='validate')}"
                        >
                          <dateComponent
                            v-if="element?.type=='date'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />
                          <temperatureComponent
                            v-if="element?.type=='number'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <editComponent
                            v-if="element?.type=='edit'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <htmlComponent
                            v-if="element?.type=='html'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <titleComponent
                            v-if="element?.type=='title'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <separatorComponent
                            v-if="element?.type=='separator'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <radioComponent
                            v-if="element?.type=='radio'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <tableComponent
                            v-if="element?.type=='table'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <signatureComponent
                            v-if="element?.type=='hand-signature'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <buttonComponent
                            v-if="element?.type=='button'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />


                          <photoComponent
                            v-if="element?.type=='take-photo'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />


                          <documentComponent
                            v-if="element?.type=='scan-doc'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <codeComponent
                            v-if="element?.type=='scan-code'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />

                          <avatarComponent
                            v-if="element?.type=='avatar'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @delete-item="deleteItem"
                          />
                          <validateComponent
                            v-if="element?.type=='validate'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @add-item="addItem"
                          />

                          <ghostComponent
                            v-if="element?.type=='ghost'"
                            :element="element"
                            :index="index"
                            :is-selected="selected==element?.id"
                            @select-item="selectItem"
                            @add-item="addItem"
                            @delete-item="deleteItem"
                          />
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>



            <div class="w-6/12 flex flex-col   w-full px-6 ">
              <div
                class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
              >
                <ul class="flex flex-wrap -mb-px">
                  <li
                    class="me-2"
                    @click="selectSubTab(0)"
                  >
                    <a
                      href="#"
                      class="inline-block p-4  border-b-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      aria-current="page"
                      :class=" {'text-blue-600 border-blue-600 ':subtab==0, 'border-transparent ': subtab!=0}"
                    >Formulaires</a>
                  </li>
                  <li
                    class="me-2"
                    @click="selectSubTab(1)"
                  >
                    <a
                      href="#"
                      class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                      :class=" {'text-blue-600 border-blue-600 ':subtab==1, 'border-transparent ': subtab!=1}"
                    >Controles
                    </a>
                  </li>
                  <li
                    class="me-2"
                    @click="selectSubTab(2)"
                  >
                    <a
                      href="#"
                      class="inline-block p-4 border-b-2   rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                      :class=" {'text-blue-600 border-blue-600 ':subtab==2, 'border-transparent ': subtab!=2}"
                    >Triggers
                    </a>
                  </li>
                </ul>
              </div>




              <div
                v-if="subtab ==0"
                class="flex flex-col   w-full overflow-y-auto h-full"
              >
                <dateComponentDetails
                  v-if="getElement?.type=='date'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />
                <temperatureComponentDetails
                  v-if="getElement?.type=='number'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />

                <avatarComponentDetails
                  v-if="getElement?.type=='avatar'"
                  :element="getElement"
                  :form="profile"
                  @update="updateItem"
                  @add="addItemAfter"
                />

                <editComponentDetails
                  v-if="getElement?.type=='edit'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />
                <titleComponentDetails
                  v-if="getElement?.type=='title'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />

                <signatureComponentDetails
                  v-if="getElement?.type=='hand-signature'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />


                <htmlComponentDetails
                  v-if="getElement?.type=='html'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />


                <buttonComponentDetails
                  v-if="getElement?.type=='button'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />


                <photoComponentDetails
                  v-if="getElement?.type=='take-photo'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />


                <documentComponentDetails
                  v-if="getElement?.type=='scan-doc'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />



                <codeComponentDetails
                  v-if="getElement?.type=='scan-code'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />


                <radioComponentDetails
                  v-if="getElement?.type=='radio'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />


                <tableComponentDetails
                  v-if="getElement?.type=='table'"
                  :element="getElement"
                  @update="updateItem"
                  @add="addItemAfter"
                />

                <validateComponentDetails
                  v-if="getElement?.type=='validate'"
                  :element="getElement"
                  @update="updateItem"
                />

                <ghostComponentDetails
                  v-if="getElement?.type=='ghost'"
                  :element="getElement"
                  :index="selectItem.id"
                  @select-type="selectType"
                />


                <separatorComponentDetails
                  v-if="getElement?.type=='separator'"
                  :element="getElement"
                  :index="selectItem.id"
                  @update="updateItem"
                />
              </div>



              <div
                v-if="subtab ==1"
                class="flex flex-col   w-full overflow-y-auto h-full"
              >
                <controlsComponentDetails
                  :element="getElement"
                  :defintion="controls"
                  @update="updateControls"
                />
              </div>


              <div
                v-if="subtab ==2"
                class="flex flex-col   w-full overflow-y-auto h-full"
              >
                <triggersComponentDetails
                  :element="getElement"
                  :form="profile"
                  @update="updateTiggers"
                />
              </div>
            </div>
          </div>

          <button
            class="mt-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="closeDialog"
          >
            Annuler
          </button>
          <button
            class="mt-3 ml-3 text-white inline-flex w-36 justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="saveForm"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import draggable from 'vuedraggable'
    import service from "@/services/FullService.vue"

    import headerComponent from "@/components/modals/components/HeaderTabletFormComponent.vue"
    import validateComponent from "@/components/modals/components/ValidateTabletFormComponent.vue"
    import validateComponentDetails from "@/components/modals/components/ValidateTabletFormComponentDetails.vue"

    import dateComponent from "@/components/modals/components/DateTabletFormComponent.vue"
    import dateComponentDetails from "@/components/modals/components/DateTabletFormComponentDetails.vue"

    import signatureComponent from "@/components/modals/components/HandSignatureTabletFormComponent.vue"
    import signatureComponentDetails from "@/components/modals/components/HandSignatureTabletFormComponentDetails.vue"

    import htmlComponent from "@/components/modals/components/HtmlTabletFormComponent.vue"
    import htmlComponentDetails from "@/components/modals/components/HtmlTabletFormComponentDetails.vue"

    import editComponent from "@/components/modals/components/EditTabletFormComponent.vue"
    import editComponentDetails from "@/components/modals/components/EditTabletFormComponentDetails.vue"

    import ghostComponent from "@/components/modals/components/GhostTabletFormComponent.vue"

    import ghostComponentDetails from "@/components/modals/components/GhostTabletFormComponentDetails.vue"

    import radioComponent from "@/components/modals/components/RadioTabletFormComponent.vue"
    import radioComponentDetails from "@/components/modals/components/RadioTabletFormComponentDetails.vue"

    import tableComponent from "@/components/modals/components/TableTabletFormComponent.vue"
    import tableComponentDetails from "@/components/modals/components/TableTabletFormComponentDetails.vue"

    import titleComponent from "@/components/modals/components/TitleTabletFormComponent.vue"
    import titleComponentDetails from "@/components/modals/components/TitleTabletFormComponentDetails.vue"

    import temperatureComponent from "@/components/modals/components/TemperatureTabletFormComponent.vue"
    import temperatureComponentDetails from "@/components/modals/components/TempératureTabletFormComponentDetails.vue"

    import buttonComponent from "@/components/modals/components/ButtonTabletFormComponent.vue"
    import buttonComponentDetails from "@/components/modals/components/ButtonTabletFormComponentDetails.vue"

    import photoComponent from "@/components/modals/components/PhotoTabletFormComponent.vue"
    import photoComponentDetails from "@/components/modals/components/PhotoTabletFormComponentDetails.vue"

    import codeComponent from "@/components/modals/components/CodeTabletFormComponent.vue"
    import codeComponentDetails from "@/components/modals/components/CodeTabletFormComponentDetails.vue"

    import documentComponent from "@/components/modals/components/DocumentTabletFormComponent.vue"
    import documentComponentDetails from "@/components/modals/components/DocumentTabletFormComponentDetails.vue"

    import avatarComponent from "@/components/modals/components/AvatarTabletFormComponent.vue"
    import avatarComponentDetails from "@/components/modals/components/AvatarTabletFormComponentDetails.vue"


    import separatorComponent from "@/components/modals/components/SeparatorTabletFormComponent.vue"
    import separatorComponentDetails from "@/components/modals/components/SeparatorTabletFormComponentDetails.vue"


    import triggersComponentDetails from "@/components/modals/components/TriggersTabletFormComponentDetails.vue"

    import controlsComponentDetails from "@/components/modals/components/ControlsTabletFormComponentDetails.vue"

    import labels from "@/assets/lg/product-types.json"

    import { v4 }  from 'uuid' ;

    import { mapGetters } from "vuex";
    export default {
        name: 'EditTabletFormModal',
        props: {
            profile: Object
        },
        data() {
            return ({
                lg: "FR",
                labels: labels,
                selected: "0",
                title: [""],
                controls: {},
                tab: 0,
                subtab: 0,
                lines: [],
                drag: false,
                types: []
            })
        },
        watch: {
            profile: function () {

                if (this.profile == undefined) return;

                this.lines[0] = this.profile.lines;
                this.title[0] = this.profile.title;
                this.title[1] = "***************************";
                this.lines[1] = [{
                    id: "98",
                    title: "Valider",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }]

                this.lines[2] = [{
                    id: "98",
                    title: "Valider!",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }]

            },
            lines: function () {
                this.$emit("update", { lines: this.lines, title: this.title })
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            getElement: function () {
                if (this.selected == "0") return null
                let selected = this.selected;

                if (this.lines == undefined) {

                    return;
                }
                let item = this.lines[this.tab]?.find(function (a) { return a.id == selected })

                return item
            }
        },
        async mounted() {

            let collection = "data-sources/668cef0f9535b23c096b6408/records"
      //      let collection = "product-templates"

            let types = await service.getData(collection, this.user?.token, {})

            if (types != null) this.types = types.records;

            if (this.profile != undefined) {
                this.lines[0] = this.profile.lines;
                this.lines[1] = [{
                    id: "98",
                    title: "Valider",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }];
                this.lines[2] = [{
                    id: "98",
                    title: "Valider",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }];
                this.title[0] = this.profile.title;
                this.title[1] = "***************************";
            }
            else {
                this.lines[0] = [{
                    id: "98",
                    title: "Valider",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }];
                this.lines[1] = [{
                    id: "98",
                    title: "Valider",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }];
                this.lines[2] = [{
                    id: "98",
                    title: "Valider",
                    type: "validate",
                    format: "relative",
                    suffix: "none",
                    prefix: "none"
                }];
                this.title[0] = "";
                this.title[1] = "***************************";
            }
        },

        methods: {
            selectTab: function (index) {
                this.tab = index
                this.subtab = 0
            },
            selectSubTab: function (index) {
                this.subtab = index
            },

            onDragStart: function (event) {

                this.drag = true
                event.stopPropagation()
            },
            onDrop: function (event) {


                this.drag = false
                event.stopPropagation()
            },
            controlForm() {
                return true;
            },
            closeDialog() {

                this.$emit("close", null)
            },

            saveForm() {
                if (!this.controlForm()) {

                    return;
                }

                this.$emit("save", { lines: this.lines, title: this.title })
            },


            addItemAfter(item) {


                let index = this.lines[this.tab].findIndex(function (a) { return a.id == item.id })

                if (index == -1) return;


                let newLine = {
                    id: v4(),
                    title: "xxxxxxxxxx",
                    type: "ghost",
                    format: "text",
                    isRequired: true,
                    isDisplayScan: false,
                    isTitleDisplayed: true
                }



                this.lines[this.tab].splice(index + 1, 0, newLine);
                this.selected = newLine.id
            },

            selectType(item) {
                let model = {}

                let index = this.lines[this.tab].findIndex(function (a) { return a.id == item.id })

                if (index == -1) return;
                let id = item.id;

                switch (item.type) {
                    case 'date': model = { id: id , title: "Sélection Date", type: "date", format: "date-time", isTitleDisplayed: true }; break;
                    case 'avatar': model = { id: id, title: "Titre de l'élement", type: "avatar", data: "", source: { id: "", name: "" }, filter: "", isTitleDisplayed: true }; break;
                    case 'edit': model = { id: id, title: "Titre pour saisie", type: "edit", format: "text", isRequired: true, isDisplayScan: false, isTitleDisplayed: true }; break;
                    case 'title': model = { id: id, title: "Example de Titre", type: "title", format: "default", isTitleDisplayed: true }; break;
                    case 'radio': model = { id: id, title: "Titre de la liste de choix", type: "radio", data: "", values: [{ title: "Choix 1", value: "", default: "on" }, { title: "Choix 2", value: "", default: "off" }, { title: "Choix 3", value: "", default: "off" }], isTitleDisplayed: true }; break;
                    case 'number': model = { id: id, title: "Titre liste de nombres", type: "number", format: "temperature", data: "", displayStepValue: "1", isTitleDisplayed: true, minValue: "", maxValue: "", minDisplayValue: "0", maxDisplayValue: "15" }; break;
                    case 'hand-signature': model = { id: id, title: "Signature", type: "hand-signature", format: "number", data: "", displayStepValue: "1", isTitleDisplayed: true, minValue: "", maxValue: "", minDisplayValue: "0", maxDisplayValue: "15" }; break;
                    case 'button': model = { id: id, title: "Titre bouton", type: "button", label: "button", action: "take-photo", data: "", displayStepValue: "1", isTitleDisplayed: true, minValue: "", maxValue: "", minDisplayValue: "0", maxDisplayValue: "15" }; break;
                    case 'html': model = { id: id, title: "Titre HTML", type: "html", body: "<b>hello</b>", href: null, data: "",   isTitleDisplayed: true    }; break;
                    case 'table': model = { id: id, title: "Titre du tableau", type: "table", columns: [], values: [], data: "", isTitleDisplayed: true, }; break;

                    case 'take-photo': model = { id: id, title: "Titre Prendre une photo", type: "take-photo", action: "", data: "", displayStepValue: "1", isTitleDisplayed: true, minValue: "", maxValue: "", minDisplayValue: "0", maxDisplayValue: "15" }; break;
                    case 'scan-doc': model = { id: id, title: "Titre Scanner un document", type: "scan-doc", action: "", data: "", displayStepValue: "1", isTitleDisplayed: true, minValue: "", maxValue: "", minDisplayValue: "0", maxDisplayValue: "15" }; break;
                    case 'scan-code': model = { id: id, title: "Titre Scanner un code", type: "scan-code", action: "", data: "", displayStepValue: "1", isTitleDisplayed: true, minValue: "", maxValue: "", minDisplayValue: "0", maxDisplayValue: "15" }; break;
                    case 'separator': model = { id: id, title: "Separateur", type: "separator", columns: [], values: [], height: "30px", line_height: "12px", }; break;

                }

                this.lines[this.tab][index] = model;
            },
            addItem(/*index*/) {

                let newLine = {
                    id: v4(),
                    title: "xxxxxxxxxx",
                    type: "ghost",
                    format: "text",
                    isRequired: true,
                    isDisplayScan: false,
                    isTitleDisplayed: true
                }



                this.lines[this.tab].splice(this.lines[this.tab].length - 1, 0, newLine);
                this.selected = newLine.id

                this.$emit("update", { lines: this.lines[0], title: this.title[0] })

            },

            deleteItem(index) {

                if (index == undefined) return;
                this.lines[this.tab].splice(index, 1)
            },
            updateItem(event) {

                let selected = this.selected;
                let item = this.lines[this.tab]?.find(function (a) { return a.id == selected })
                if (item == undefined) return;
                item[event.att] = event.value
            },


            updateControls(event) {

                let selected = this.selected;
                let item = this.lines[this.tab]?.find(function (a) { return a.id == selected })
                if (item == undefined) return;

                if (item._controls == undefined) item._controls = {}

                item._controls[event.att] = event.value
            
            },

            updateTiggers(event) {

                let selected = this.selected;
                let item = this.lines[this.tab]?.find(function (a) { return a.id == selected })
                if (item == undefined) return;

                if (item._events == undefined) item._events = { onChange:[], onInit:[]}

                item._events['onChange'] = event.onChange
           
            },
            selectItem(item) {
                this.selected = item.id
            },
            onProductSelect(item) {
                this.$emit("select", item)
            },
            onClose() {
                this.$emit("close")
            }
        },
        components: {
            draggable, triggersComponentDetails, controlsComponentDetails, separatorComponent, separatorComponentDetails, headerComponent, validateComponent, dateComponent, tableComponent, tableComponentDetails, temperatureComponent, avatarComponent, dateComponentDetails, temperatureComponentDetails, avatarComponentDetails, editComponent, editComponentDetails, titleComponent, titleComponentDetails, radioComponent, radioComponentDetails, validateComponentDetails, ghostComponent, ghostComponentDetails, signatureComponent, signatureComponentDetails, buttonComponent, buttonComponentDetails, photoComponent, photoComponentDetails, documentComponent, documentComponentDetails, codeComponent, codeComponentDetails, htmlComponent, htmlComponentDetails
        },
    }
</script>
<template>
  <div
    style="padding-left:255px;min-height: 400px; "
    class="h-full relative mt-24   bg-white  dark:bg-gray-900 overflow-y-auto"
  >
    <VNetworkGraph
      :nodes="nodes"
      :edges="edges"
      :configs="configs"
      :layouts="layouts"
      :event-handlers="eventHandlers"
    />


    <!-- Tooltip -->
  </div>
  <div
    class="absolute  w-10 h-10 text-white"
    style="top:100px;right:10px;"
  >
    <button
      type="button"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
      @click="onClose()"
    >
      <svg
        aria-hidden="true"
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
  <div
    class="absolute flex flex-col items-center justify-start text-left"
    style="top:100px;left:300px"
  >
    <div class="  text-3xl font-bold   text-gray-800 text-left justify-start">
      {{ record && record.product && record.product.name }}
    </div>
    <div class="  text-2xl font-medium   text-gray-600 text-left justify-start">
      Lot: {{ record && record.batch && record.batch.toUpperCase() }}
    </div>
  </div>


  <div
    class="absolute text-3xl font-bold   text-gray-800"
    style="top:200px;right:30px"
  >
    <div
      v-if="item && item.name"
      class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700"
    >
      <form
        class="space-y-6"
        action="#"
      >
        <h5 class="text-xl font-medium text-gray-900 dark:text-white">
          {{ item && item.name }}
        </h5>
        <div class="text-left">
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Numéro de lot :</label>
          <label class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ item &&
            item.batch }} <a href="#"><i class="fa-solid fa-up-right-from-square ml-2" /></a></label>
        </div>
        <div
          v-if="item && item.createdAt"
          class="text-left"
        >
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date Fabrication
            :</label>
          <label class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ item &&
            item.createdAt }}</label>
        </div>
        <div
          v-if="item && item.receivedAt"
          class="text-left"
        >
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date Réception :</label>
          <label class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ item &&
            item.receivedAt }}</label>
        </div>
        <div class="text-left">
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date DLUO</label>
          <label class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ item &&
            item.dluo }}</label>
        </div>
        <div class="text-left">
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Fabricant</label>
          <label class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ item &&
            item.manufacturer }}</label>
        </div>
        <button
          type="submit"
          class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Plus de détails
        </button>
        <div class="hidden text-sm font-medium text-gray-500 dark:text-gray-300">
          Not registered? <a
            href="#"
            class="text-blue-700 hover:underline dark:text-blue-500"
          >Voir les
            détails</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  // 
  //import VNetworkGraph from "v-network-graph"
  import service from "@/services/FullService.vue"


  import * as vNG from "v-network-graph"
  const configs = vNG.defineConfigs({
    view: {
      grid: {
        visible: true,
        interval: 25,
        thickIncrements: 5,
        line: {
          color: "#e0e0e0",
          width: 1,
          dasharray: 1,
        },
        thick: {
          color: "#cccccc",
          width: 1,
          dasharray: 0,
        },
      },
      path: { curveInNode: true, },

      layoutHandler: new vNG.GridLayout({ grid: 10 }),
    },
    node: {
      selectable: true,
      normal: {
        type: "rect",
        width: 32,
        height: 32,
        borderRadius: 8,
        color: "rgb(226, 232, 240)"
      },
      hover: {
        color: "#ff5500",
        width: 36,
        height: 36,
        borderRadius: 8,
      },
      label: {
        fontSize: 16,
        color: "#000000",
        direction: "north",
      },
    },
    edge: {
      type: "curve",
      normal: {
        width: 2,
        color: "#ff6f00",
        dasharray: "4 6",
        linecap: "round",
      },
      hover: {
        color: "#ff5500",
      },
    },

  })

  import { mapGetters } from "vuex";

  export default {
    name: "NetworkView",

    components: {

      // VNetworkGraph
    },
    props:
    {


    },
    data() {

      return {
        record: {},
        configs: configs,
        nextNodeIndex: 300,
        title: "",
        eventHandlers: {
          // wildcard: capture all events node:click node:dblclick 
          "node:dblclick": (type, event) => {
            //  const timestamp = new Date()

            if (event instanceof Object && "event" in event) {
              Object.assign(event, { event: "(...)" })
            }

            //  this.addNode()
          },
          "node:select": (type, event) => {
            //  const timestamp = new Date()

            if (event instanceof Object && "event" in event) {
              Object.assign(event, { event: "(...)" })
            }

            if (type != undefined)
              this.item = this.records[type[0]];

            //this.addNode()
          },
          "*m": (type, event) => {
            //     const timestamp = new Date()

            if (event instanceof Object && "event" in event) {
              Object.assign(event, { event: "(...)" })
            }

            this.addNode()
          },
        },
        nodes: {

          node1: { name: "Lait de Vache Bio" },
          node2: { name: "Ferment XXXXXXXX" },
          node4: { name: "Lait en poudre" },
          node5: { name: "Sel Raffiné Fin séché " },
          node6: { name: "Presure" },
          node7: { name: "Chlorure de calcium" },

          node3: { name: "" },


          node8: { name: "Papier" },
          node9: { name: "Boite Carton" },
          node10: { name: "Etiquette Face" },
          node11: { name: "Etiquette Dos" },

        },

        edges: {
          edge1: { source: "node1", target: "node3" },
          edge2: { source: "node2", target: "node3" },
          edge4: { source: "node4", target: "node3" },
          edge5: { source: "node5", target: "node3" },
          edge6: { source: "node6", target: "node3" },
          edge7: { source: "node7", target: "node3" },

          edge308: { source: "node8", target: "node3" },
          edge300: { source: "node9", target: "node3" },
          edge310: { source: "node10", target: "node3" },
          edge311: { source: "node11", target: "node3" },

        },
        layouts: {
          nodes: {
            node1: { x: 0, y: 0 },
            node2: { x: 0, y: 80 },

            node4: { x: 0, y: 160 },
            node5: { x: 0, y: 240 },
            node6: { x: 0, y: 300 },
            node7: { x: 0, y: 380 },

            node8: { x: 480, y: 80 },
            node9: { x: 480, y: 160 },
            node10: { x: 480, y: 300 },
            node11: { x: 480, y: 240 },

            node100: { x: 160, y: 200 },
            node200: { x: 400, y: 200 },
            node3: { x: 280, y: 200 },
          },
        },
        item: {
          name: ""
        },
        records: {
          node1: {
            name: "Lait de Vache Bio",
            batch: "23002VPB",
            createdAt: "2023-01-12",
            dluo: "2023-01-04",
            receivedAt: "2023-01-12",
            manufacturer: "Ferme de la Tremblaye"
          },
          node3: {
            name: "Camembert Fermier Bio",
            batch: "23002VPB-CAM",
            createdAt: "2023-01-11",
            dluo: "-",
            receivedAt: null,
            manufacturer: "Ferme de la Tremblaye"
          },
          node7: {
            name: "Chlorure de calcium pour fromagerie",
            batch: "AB-1232323-L",
            receivedAt: "2023-01-11",
            dluo: "-",
            createdAt: null,
            manufacturer: "Laboratoire Abia"
          },
          node4: {
            name: "Lait en poudre LEF 100",
            batch: "2022/22/03/1230/12",
            receivedAt: "2022-03-11",
            dluo: "2027-12-01",
            createdAt: null,
            manufacturer: "Laboratoire Abia"
          },
          node5: {
            name: "Sel Raffiné Fin séché ",
            batch: "34123BC",
            receivedAt: "2022-08-02",
            dluo: "-",
            createdAt: null,
            manufacturer: "Salinor"
          },
          node2: {
            name: "Ferment XXXXXXXX",
            batch: "K8903-90",
            receivedAt: "2022-08-02",
            dluo: "-",
            createdAt: null,
            manufacturer: "xxxxxxx"
          }





        }

      }
    },

    computed:
    {
      ...mapGetters({ user: "StateUser" }),


    },

    watch: {


    },




    mounted: async function () {


      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login"
        return;
      }

      this.record = await service.getItem(this.$route.params.id, this.user?.token)

      this.records.node3.name = this.record.product.name
      this.nodes.node3.name = this.record.product.name
      this.records.node3.batch = this.record.batch.toUpperCase()
    },
    methods:
    {
      // ...mapActions(["GetSensorDatas"]),
      addNode: function () {
        const nodeId = `node${this.nextNodeIndex}`
        const name = `N${this.nextNodeIndex}`
        this.nodes[nodeId] = { name }

        this.layouts.nodes[nodeId] = { x: 380, y: 400 }
        delete this.nodes["node10"]

        this.edges[nodeId] = { source: "node100", target: nodeId }

        this.nextNodeIndex++
      },
      onClose: function () {
        let url = "/admin/batches/" + this.$route.params.id;
        window.location = url;
      }
    },
  };
</script>

<style>
  .graph {
    width: 800px;
    height: 600px;
    border: 1px solid #000;
  }
</style>
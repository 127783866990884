
<template>
    <div class="w-full   bg-gray-50 overflow-y-auto"
    
    
            :class = "{ 'w-48' :(items?.length < 2), 'w-64' :(items?.length ==2), 'w-96' :(items?.length > 2), }"
    
    >
        <div
          class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
          <h3 class="font-bold text-gray-900 dark:text-white">
            Fiche d'Information
          </h3>
        </div>
        <div class="px-3 py-2 flex flex-col w-full border-b border-b-gray-300"  v-for="cell in items" :key="cell.id">
          <div class=" text-sm font-normal  h-full flex flex-row items-center px-3 ">
            <i class="fa-solid fa-hashtag pr-2 w-6" /><span>Numéro de lot
              :</span>&MediumSpace;<span class="font-semibold">{{ cell?.batch_number }}</span>
          </div>

          <div class=" text-sm font-normal  h-full flex flex-row items-center px-3 ">
            <i class="fa-solid fa-box-open pr-2  " /><span>Ouvert le
              :</span>&MediumSpace;<span class="font-semibold">{{ formatDateTime(cell?.opened_at) }}</span>
          </div>

          <div
            
            class="text-sm font-normal  flex flex-row items-center px-3  h-full">
            <i class="fa-solid fa-user   pr-2 " /><span>Ouvert par
                :</span>&MediumSpace;<span class="font-semibold">{{ cell?.opened_by?.name }}</span>
          </div>

          <div class=" text-sm   h-full flex flex-row items-center px-3 font-normal ">
            <i class="fa-solid fa-user pr-2  " /><span>Quantité en stock
              :</span>&MediumSpace;<span class="font-semibold">{{ cell?.quantity }}</span>
          </div>
        </div>
      </div>
     
</template>

<script>

export default {
    name: 'FileColDisplayBatch',

    components: {

    },
    methods : 
     {
      formatDateTime : function(date)
      {
        if (date == undefined) return "";
        if (date == "") return "";
        if (date == "null") return "";
        if (date == "--") return "";

        var myDate = new Date(date);

        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let formatteddate = myDate.toLocaleString('fr-FR', options);
 
        //  return date
        return formatteddate 
      }
     },
    props: {
      items: Array
    },
    data() {
    }
}

</script>
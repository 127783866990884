<template>
  <div class="relative w-full h-64 flex justify-center items-center p-2">
    <apexchart
      class="h-auto  w-96 flex items-center  justify-center"
      type="donut"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
  import VueApexCharts from "vue3-apexcharts";
  export default {
    name: 'SemiDonutChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      parent: String,
      title: String,
      records: Object,
      config: Object
    },
    data: function () {
      return {
        chartOptions: {
          chart: {
            type: 'donut',
            height: 600
          },
          legend: {
            position: 'right',
            show: false,
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              offsetY: 10
            }
          },
          grid: {
            padding: {
              bottom: -80
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                height: 400
              },
              legend: {
                position: 'right',
                show: false,
              }
            }
          }]


        },
        series: [44, 55, 41, 17, 15],
      };
    },
    watch: {

      'records': async function (val) {
          console.log("SemiDonuts WATCH records")
        //   console.log(val)
        let r = this.transformData(val);
        if (r == undefined) r = { data: [], name: "" }
        this.localrecords = r
        this.series.data = r.series[0].data;

      
        this.chartOptions =
        {
          labels: r.categories
        }
        //     this.series[0].name = r.series[0].name;
      }
    },

    async mounted() {
 
      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
 

    },
    methods: {
      transformData(data) {

        //       console.log("DONUT - transformData transformData transformData transformData transformData", data.records)
        //       console.log(JSON.stringify(data))
        let series = []
        let labels = {}


        {
          for (let i = 0; i < data.records.length; i++) {
            if (data.records[i]) {
              series.push(data.records[i]['value'])
              labels[data.records[i]['label']] = data.records[i]['value']
            }

          }
        }
 
        return { categories: Object.keys(labels), series: [{ name: "test", data: series }] }
      }
    },

  }
</script>
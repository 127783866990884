<template>
  <div class="h-full relative mt-14  bg-gray-200 overflow-y-none   dark:bg-gray-900 h-full">
    <PDFNavBar
      :name="name"
      mode="generate"
      :reference="reference"
      :version="version"
      :loading="!showPDF"
      :status="status"
      :is-available-f-r="isAvailableFR"
      :is-available-e-n="isAvailableEN"
      @download="onDownload"
      @publish="onPublish"
      @lg="onChangeLg"
      @view="OnView"
      @back="OnBack"
    />
    <div class="w-full h-full">
      <div
        v-if="!showPDF"
        class="flex items-center justify-center px-16 py-6 bg-gray-500 h-screen w-full"
      >
        <svg
          aria-hidden="true"
          class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>

        <span class="text-2xl text-white">Génération en cours, Merci de patienter...</span>
      </div>

      <div
        v-if="showPDF"
        class="px-16 py-6 bg-gray-200 border border-color-gray-700 w-full "
        :class="{ 'px-6' : (viewBy==1), 'px-4': (viewBy==2)}"
      >
        <div
          :class=" {'hidden' : (viewBy==1), 'show' : (viewBy==2)}"
          class="grid grid-cols-2 items-center justify-between w-full "
        >
          <div class="px-3  w-full">
            <vue-pdf-embed
              ref="pdfRef1"
              class="w-full  "
              :source="sourceCurrent"
              :page="page"
            />
          </div>
          <div class="px-3 w-full">
            <vue-pdf-embed
              ref="pdfRef2"
              class="w-full "
              :source="sourceCurrent"
              :page="page2"
            />
          </div>
        </div>

        <div
          :class=" {'opacity-0' : (viewBy==2), 'opacity-1' : (viewBy==1)}"
          class="grid grid-cols-1 items-center justify-between w-full "
        >
          <div class="px-1  w-full">
            <vue-pdf-embed
              ref="pdfRef3"
              class="w-full"
              :source="sourceCurrent"
            />
          </div>
        </div>
      </div>
    </div>
    <SelectUsers
      :records="users"
      :rights="rights"
      @validate="onUsersValidate"
      @close="onUsersClose"
    />
  </div>
</template>

<script>

  import service from "@/services/FullService.vue"
  import PDFNavBar from "@/components/PDFNav.vue"
  import { mapGetters } from "vuex";
  import VuePdfEmbed from 'vue-pdf-embed'

  import { Modal } from 'flowbite';
  import SelectUsers from '@/components/modals/SelectUsers.vue'


  export default {

    name: "DocumentView",

    components: {

      SelectUsers, PDFNavBar,
      VuePdfEmbed,
    },

    props:
    {
      title: String,
      subTitle: String
    },
    data() {

      return {
        viewBy: 1,
        showPDF: false,
        currentlg: "fr",
        page: 1,
        page2: 2,
        users: [],
        pdf_w: "650",
        reference: "",
        name: "",
        version: "",
        selectUsersModal: null,
        rights: [],
        sourceCurrent: "",
        langageCurrent: "fr",
        status: "",
        newTS: null,
        recordFR: {},
        recordEN: {},
        recordCurrent: {},
        response: [],
        labels: [],
      }
    },

    computed:
    {
      ...mapGetters({ user: "StateUser" }),
      mapRef: function (a) {
        if (a == undefined) return null;
        return a;
      }


    },

    watch: {
      'user.tenant_id': async function () {
        let response = await this.getPoints()
        this.heatmapData = response
      }
    },

    mounted: async function () {


      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login"
        return;
      }


      let collection = "products";

      collection = "data-sources/66184d2c2cdd7ea01681abd6/records"

      let product = await service.getDataId(collection, this.$route.params.id, this.user?.token)
      this.name = product.name;
      this.reference = "FT-" + product.reference;



      const modalEl = document.getElementById('select-users-modal');
      this.selectUsersModal = new Modal(modalEl, {
        placement: 'center'
      });
 
      this.isAvailableFR = (product.datasheet_template_fr_id != null);
      this.isAvailableEN = (product.datasheet_template_en_id != null);
 
      if (this.isAvailableFR)
        this.recordFR = await service.generatePDF(this.$route.params.id, this.user?.token, { lg: "fr" })
      else
        this.recordFR = null;


      if (this.isAvailableEN)
        this.recordEN = await service.generatePDF(this.$route.params.id, this.user?.token, { lg: 'en' })
      else
        this.recordEN = null;


      if ((this.recordFR == null) && (this.recordEN == null)) {
        alert("Error Generation PDF - Pas de template")
      }
      else {

        if (this.recordFR == undefined) {

          this.newTS = this.recordEN.technicalSheet;
          this.version = this.recordEN.version;
          this.status = this.recordEN.status;

          this.sourceCurrent = this.recordEN.data
          this.recordCurrent = this.recordEN
          this.rights = this.recordEN.access;
          this.langageCurrent = "en"

        }
        else {
          this.newTS = this.recordFR.technicalSheet;
          this.version = this.recordFR.version;
          this.status = this.recordFR.status;
          this.rights = this.recordFR.access;
          this.sourceCurrent = this.recordFR.data
          this.recordCurrent = this.recordFR

          this.langageCurrent = "fr"
        }


      }

      this.showPDF = true;

      this.$nextTick(function () {
        window.scrollTo(0, 0)
      });
    },

    methods:
    {


      async onUsersValidate(output) {



        let body =
        {
          status: "PUBLISHED",
          access: output
        }

        let response = await service.updateRecord("technical-sheets/publish", this.newTS, this.user?.token, body)

        if (response?.id != null) {
          if (this.langageCurrent == "fr")
            this.recordFR.status = "PUBLISHED"

          if (this.langageCurrent == "en")
            this.recordEN.status = "PUBLISHED"

          this.status = "PUBLISHED"
        }




        this.selectUsersModal.hide()
      },
      onUsersClose() {
        this.selectUsersModal.hide()
      },

      onChangeLg: async function (lg) {


        if ((lg == 1) && (this.langageCurrent != "fr")) {
          this.langageCurrent = "fr"
          this.sourceCurrent = this.recordFR.data;

          this.recordCurrent = this.recordFR

          this.newTS = this.recordFR.technicalSheet;
          this.version = this.recordFR.version;
          this.status = this.recordFR.status;
          this.rights = this.recordFR.access;

        }

        if ((lg == 2) && (this.langageCurrent != "en")) {
          this.langageCurrent = "en"
          this.sourceCurrent = this.recordEN.data;

          this.recordCurrent = this.recordEN

          this.langageCurrent = "en"
          this.newTS = this.recordEN.technicalSheet;
          this.version = this.recordEN.version;
          this.status = this.recordEN.status;
          this.rights = this.recordEN.access;
        }




        this.$refs.pdfRef3.render()
        this.$nextTick(() => {

          //this.$refs.pdfRef1.render()
          //   this.$refs.pdfRef3.render()

        });



      },

      OnView: function (v) {
        this.viewBy = v


        this.$refs.pdfRef3.render()
        this.$refs.pdfRef1.render()
        this.$refs.pdfRef2.render()


        /*
        this.$nextTick(() => {
            if (v == 2) {
             
                this.$refs.pdfRef1.render()
                this.$refs.pdfRef2.render()
            }

            if (v == 1) {
           
                //this.$refs.pdfRef1.render()
                this.$refs.pdfRef3.render()
            }
        });
*/

      },
      onPublish: async function () {
        this.users = [];

        let users = await service.getData("portal-users", this.user?.token, Object.assign({}, { limit: 5, offset: 0 }, {}))


        this.users = users;

        this.selectUsersModal.show();

        //    this.$router.go(-1)
      },

      OnBack: function () {
        this.$router.go(-1)
      },
      onDownload: function () {

        if (this.langageCurrent == "fr")

          this.downloadPDF(this.recordFR.data, "FT-" + this.recordFR.reference + "-" + this.langageCurrent.toUpperCase() + ".pdf")


        if (this.langageCurrent == "en")

          this.downloadPDF(this.recordEN.data, "FT-" + this.recordEN.reference + "-" + this.langageCurrent.toUpperCase() + ".pdf")



      },
      downloadPDF: function (base64, filename) {

        // Create an anchor element
        var link = document.createElement("a");
        link.href = base64;
        link.download = filename;
        document.body.appendChild(link);
        // Click the link
        link.click();
        // Remove the link
        document.body.removeChild(link);
      }

    },



  };
</script>


<style>
  .vue-pdf-embed>div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  }
</style>
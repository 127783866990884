<template>
  <div
    class="  relative h-20 cursor-grab list-group mt-2  pt-1 w-full flex items-start bg-gray-500 border   shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
    :class="{'border-gray-100 hover:border-solid hover:border-gray-100' : isSelected , 'border-gray-700 hover:border-dashed hover:border-gray-100' : !isSelected }"
    @click="selectItem"
    @mouseover.stop="showheader=true;"
    @mouseleave.stop="showheader=false;"
  >
    <div
      v-show="showheader"
      class="h-6 w-6 bg-transparent absolute top-1 right-2 z-20 w-full flex justify-end  "
    >
      <i
        class="fa-solid fa-trash text-gray-100 cursor-pointer"
        @click="deleteMe"
      />
    </div>
    <div class="flex flex-col items-center w-full  pt-3 pl-2 bg-gray-500 ">
      <div class="w-full h-6   font-bold text-lg  text-white flex justify-center  mr-2 pb-2">
        <div class=" pb-6 mb-6  px-6  italic  text-center flex justify-center font-normal border border-dashed">
          Sélectionner le type de Composant
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'GhostTableFormComponent',
        props: {
            element: Object,
            isSelected: Boolean,
            index: Number,
        },
        data() {
            return {
                showheader: false,
            }
        },
        computed:
        {
            displayDateTime: function () {
                if (this.element.format == "date") return "12/1/2023";
                if (this.element.format == "date-time") return "12/1/2023 2:44";
                if (this.element.format == "time") return "2:44";
                return ""
            }
        },
        methods:
        {
            deleteMe: function () {
                this.$emit("deleteItem", this.index)
            },
            selectItem: function () {
                this.$emit("selectItem", this.element)
            }
        },
    }
</script>
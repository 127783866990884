<template>
  <div
    id="generate-sheet-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
  >
    <div class="relative p-4 relative w-full max-w-7xl max-h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <!-- Modal header -->
        <div
          class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            {{ labels['TITLE_SELECT_PRODUCT_TYPE'][lg] }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="w-full h-full flex flex-col ">
          <div class="w-full h-full flex justify-center ">
            <ol class="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
              <li class="flex w-full items-center text-blue-600 dark:text-blue-500 space-x-2.5">
                <span
                  class="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500"
                >
                  1
                </span>
                <span>
                  <h3 class="font-medium leading-tight">Parémètres</h3>
                  <p class="text-sm">- - - - -</p>
                </span>
              </li>
              <li class="flex w-full items-center text-gray-500 dark:text-gray-400 space-x-2.5">
                <span
                  class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                >
                  2
                </span>
                <span>
                  <h3 class="font-medium leading-tight">Prévisualisation</h3>
                  <p class="text-sm">- - - - </p>
                </span>
              </li>
              <li class="flex w-full items-center text-gray-500 dark:text-gray-400 space-x-2.5">
                <span
                  class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400"
                >
                  3
                </span>
                <span>
                  <h3 class="font-medium leading-tight">Publication</h3>
                  <p class="text-sm">- - - - - - -</p>
                </span>
              </li>
            </ol>
          </div>
          <div class="mt-4 w-full h-full flex justify-center h-96 ">
            <div v-if="step==0">
              <h1>Step #1</h1>
            </div>


            <div v-if="step==1">
              <h1>Step #2</h1>
            </div>

            <div v-if="step==2">
              <h1>Step #3</h1>
            </div>
          </div>
        </div>

        <div class="flex items-center flex flex-row w-full">
          <div class="flex items-center w-full justify-start">
            <button
              type="button"
              class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click=" onClose()"
            >
              Annuler
            </button>
          </div>
          <div class="flex items-center justify-end">
            <button
              v-if="step == 0 || step == 1 || step == 2"
              type="button"
              class="w-36 mr-3 flex justify-center inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
              :class="{ 'pointer-events-none text-gray-500 bg-gray-300': (step == 0)}"
              @click="onPrevious"
            >
              <i class="mr-2 fa-solid fa-arrow-left" />
              <span>Précédent</span>
            </button>
            <button
              v-if="step == 0 || step == 1"
              type="button"
              class="w-36  flex justify-center inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
              @click="onNext"
            >
              <span class="mr-2">Suivant</span>
              <i class="fa-solid fa-arrow-right" />
            </button>
            <button
              v-if="step == 2"
              type="button"
              class="w-36 flex justify-center inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
              @click="onValidate"
            >
              <i class="mr-2 fa-solid fa-circle-check" />
              <span>Valider</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    const MAX_ITEMS = 10;
    import labels from "@/assets/lg/generate-sheet.json"

    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue"
    export default {
        name: 'GenerqteSheetModal',
        data() {
            return ({
                lg: "FR",
                step: 0,
                labels: labels,
                current: null,
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                records: [],
                folders: [],
                transparent: false,
                item: {
                    id: "1",
                    label: {
                        'EN': "",
                        'FR': ''
                    }
                },
            })
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        methods: {

            onSelectImage(image) {
                this.$emit("selectImage", image);
            },
            async reloadPage(id, offset, limit, filter) {

                if (this.user == undefined) {
                    this.$router.push("/login");
                    return;
                }

                this.offset = offset;
                this.limit = limit;

                filter.type = "FOLDER";

                if (id != null)
                    filter.parent_id = id;
                else
                    delete filter.parent_id
                this.folders = await service.getData("images", this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))
                filter.type = "IMAGES";
                this.records = await service.getData("images", this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))

             
            },
            onRefreshTable: function (item) {
             
                this.reloadPage("images", item.offset, item.limit, item.filter);
            },
            onRefreshPage: function (item) {
              
                this.reloadPage(this.current, item.offset, item.limit, item.filter);
            },

            onChangeFolder: function (item) {
                
 
                this.current = item.id
                this.reloadPage(this.current, 0, MAX_ITEMS, {});
            },
            onValidate() {

            },

            onNext() {
               
                if (this.step == 1) this.step = 2;
                if (this.step == 0) this.step = 1;
             
            },


            onPrevious() {
                if (this.step == 1) this.step = 0;
                if (this.step == 2) this.step = 1;
               
            },

            onClose() {
                this.$emit("close")
            }
        },
        components: {

        },
    }
</script>
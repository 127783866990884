<template>
  <div
    class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center  "
  >
    <div class="flex flex-col items-start w-full   pt-6  px-6">
      <div class="  mb-6 w-full text-left">
        <div class="flex">
          <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
            Rien de Selectionner
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ControlsTableFormComponentDetails',
        props: {
            properties: Object,
            index: Number,
            definition: Object
        },
        data() {

            return {
               
            }
        },
        computed: {
         
        },
        watch:
        {
            properties: function () {
                
            }
        },

        mounted() {
         

        },
        methods:
        {
            onChangeIsRequired: function () {
               
            }
        }
    }
</script>
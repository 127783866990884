<!-- vue/script-setup-uses-vars -->
<template>
  <GoogleMap
    id="map"
    ref="mapRef"
    class="h-screen"
    :api-key="map.key"
    :libraries="map.libraries"
    :language="map.language"
    :region="map.region"
    :center="map.center"
    :zoom="map.zoom"
    :min-zoom="map.minZoom"
    :max-zoom="map.maxZoom"
    :street-view-control="map.streetViewControl"
    @zoom_changed="zoomChanged"
    @center_changed="centerChanged"
  >
    <Marker
      v-for="r in data3"
      :key="r.id"
      :options="{ position: r.location, color: '#F0F', icon: 'https://static.wixstatic.com/media/a796c2_55a6932b62c848258b04bc70a5bd3ca1~mv2.png/v1/fill/w_195,h_137,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a796c2_55a6932b62c848258b04bc70a5bd3ca1~mv2.png',label: 'L', title: 'LADY LIBERTY' }"
    >
      <InfoWindow>
        <div id="content">
          <div id="siteNotice" />
          <h1
            id="firstHeading"
            class="bg-red-100"
          >
            Uluru
          </h1>
          <div id="bodyContent">
            <p>
              <b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large
              sandstone rock formation in the southern part of the
              Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi)
              south west of the nearest large town, Alice Springs; 450&#160;km
              (280&#160;mi) by road. Kata Tjuta and Uluru are the two major
              features of the Uluru - Kata Tjuta National Park. Uluru is
              sacred to the Pitjantjatjara and Yankunytjatjara, the
              Aboriginal people of the area. It has many springs, waterholes,
              rock caves and ancient paintings. Uluru is listed as a World
              Heritage Site.
            </p>
            <p>
              Attribution: Uluru, <a
                href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194"
              >
                https://en.wikipedia.org/w/index.php?title=Uluru</a>
              (last visited June 22, 2009).
            </p>
          </div>
        </div>
      </InfoWindow>
    </Marker>


    <CustomMarker
      v-for="r in data3"
      :key="r.id"
      class="hidden"
      :options="{ position: r.location, anchorPoint: 'BOTTOM_CENTER' }"
    >
      <div
        class="hidden rounded-full bg-red-500 flex    font-bold items-center border-2 border-red-500 shadow-sm tooltip"
      >
        <img
          class="bg-white rounded-full"
          src="https://static.wixstatic.com/media/a796c2_55a6932b62c848258b04bc70a5bd3ca1~mv2.png/v1/fill/w_195,h_137,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a796c2_55a6932b62c848258b04bc70a5bd3ca1~mv2.png"
          width="40"
          height="40"
        >

        <div
          style="font-size: 0.625rem  "
          class="px-3 text-white"
        >
          Faible
        </div>
      </div>
      <div
        class=" bg-center bg-cover bg-no-repeat w-16 h-28  flex items-center justify-center  "
        style="background-image: url(https://cdn.pixabay.com/photo/2014/04/03/10/03/google-309741__180.png)"
      >
        <div class="text-sm font-bold text-gray-800 px-3 text-white pr-6">
          Faible
        </div>
        <InfoWindow>
          <div id="content">
            <div id="siteNotice" />
            <h1
              id="firstHeading"
              class="firstHeading"
            >
              Uluru
            </h1>
          </div>
        </InfoWindow>
      </div>
    </CustomMarker>
  </GoogleMap>
</template>
<!--  <HeatmapLayer :options="{ data: heatmapData, radius:50 }" />-->

<script setup>
	//defineProps,
	import { ref, watch } from 'vue';

	/* eslint-disable */
	const props = defineProps({
		heatmapData: String,
	});
	const data3 = [

		{ weight: 5, location: { lat: 48.845832, lng: 2.324384 } },
		{ weight: 5, location: { lat: -5.782745, lng: 21.444586 } },
		{ weight: 5, location: { lat: 27.782842, lng: -22.443688 } },
		{ weight: 5, location: { lat: 47.782919, lng: -52.442815 } },
		{ weight: 5, location: { lat: 7.782992, lng: 2.442112 } }


	]
	// eslint-disable-next-line
	import { GoogleMap, CustomMarker,  Marker, InfoWindow } from 'vue3-google-map';

	// eslint-disable-next-line
	const map = {
		key: 'AIzaSyCyvxVv-VQiwvkQWAx09B3Z0N7yL2HGaXI', // Place a Google API Key here
		libraries: ['visualization'],
		language: 'fr-FR',
		region: 'FR',
		maxZoom: null,
		center: {
			lat: 47.8232,
			lng: 2.278
		},
		radius: 30,
		zoom: 6,
		minZoom: 2,

		streetViewControl: false
	};
	// eslint-disable-next-line


	let gmap = null;

	const mapRef = ref(null);



	watch(() => mapRef.value?.ready, ready => {
		if (ready) {
			gmap = mapRef.value.map;

		}
	});
	// eslint-disable-next-line
	function zoomChanged() {

	}

	// eslint-disable-next-line
	function centerChanged() {
		const center = gmap.getCenter();

	}
</script>
<template>
  <div class="bg-white w-full p-6">
    <h1>For Future Use</h1>
  </div>
</template>

<script>

    export default {
        name: 'DeveloperMisc',
        props: {
            item: Object
        },
        data() {
            return ({
                currentItem: {}
            })
        },
        watch: {
            item: function (val) {
                let item = val;

                this.currentItem = item;

            }
        },



        mounted() {

            let item = this.item;

            this.currentItem = item;


        }

    }
</script>
<script setup>
    /* eslint-disable */

    import { Handle, Position } from '@vue-flow/core'
    import { computed } from 'vue'


    const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
    })


    // const onConnect = (params) => {}





    const sourceHandleStyleA = computed(() => ({

        backgroundColor: props.data.color,
        color: props.data.color,
        height: '16px',
        width: '16px',
        top: '-8px'
    }))
    const sourceHandleStyleB = computed(() => ({

        height: '16px',
        width: '16px',
        bottom: '-8px'
    }))



</script>

<style>
    .basicflow .vue-flow__node-custom {
    border: 1px solid #777;
    padding: 10px;
    border-radius: 0px;
    background: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    max-width: 250px
    }

    .vue-flow__node-custom .vue-flow__handle.connectable::after {
    color: green; font-size: 10pt; } .basicflow button { padding: 5px; width: 25px;
        height: 25px; border-radius: 25px; -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .3); box-shadow: 0 5px
        10px #0000004d; cursor: pointer } .basicflow button:hover { opacity: .9; transform: scale(105%); transition:
        .25s all ease } .animated-bg-gradient { background: linear-gradient(122deg, #6f3381, #81c7d4, #fedfe1, #fffffb);
        background-size: 800% 800%; -webkit-animation: gradient 4s ease infinite; -moz-animation: gradient 4s ease
        infinite; animation: gradient 4s ease infinite } @-webkit-keyframes gradient { 0% { background-position: 0% 22%
        } 50% { background-position: 100% 79% } to { background-position: 0% 22% } } @-moz-keyframes gradient { 0% {
        background-position: 0% 22% } 50% { background-position: 100% 79% } to { background-position: 0% 22% } }
        @keyframes gradient { 0% { background-position: 0% 22% } 50% { background-position: 100% 79% } to {
        background-position: 0% 22% } } 
    </style>

        <template>
            <div class="font-bold" style="width:150px;text-align: center;">
                <div class="text-sm  font-bold">
                    <div class="flex flex-row items-center justify-center">
                        <div class="w-3/12"><i class="fa fa-cube" style="font-size:22px"></i></div>
                        <div class="w-9/12 px-2 align-middle">
                            <span>{{props.data.label}}</span>
                        </div>
                    </div>
                </div>
                <span
                    v-if="data.data != undefined &&  data.data.parameters!= undefined && data.data.parameters.length >0"
                    class="inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded text-xs">{{data.data
                    && data.data.parameters && data.data.parameters.length}} <span
                        v-if="data.data != undefined &&  data.data.parameters!= undefined && data.data.parameters.length==1">parameter</span><span
                        v-if="data.data.parameters!= undefined && data.data.parameters.length > 1">parameters</span></span>

                <span
                    v-if="data.data != undefined &&  data.data.parameters!= undefined && data.data.parameters.length  == 0"
                    class="inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline bg-slate-600 text-white rounded text-xs">aucun
                    paramètre</span>


                <Handle id="b" type="source" :position="Position.Bottom" :style="sourceHandleStyleB" />
            </div>
        </template>
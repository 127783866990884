<template>
  <div class="  h-8 cursor-pointer list-group mt-2  w-full flex items-start         flex flex-row justify-center  ">
    <div class="flex flex-col items-start w-full   pt-6  px-6">
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Titre</label>
        <input
          id="default-input"
          v-model="title"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputTitle"
        >
      </div>


        

      <div class="  mb-4 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Description</label>
        <input
          :id="`att-input-description-${properties?.id}`"
          v-model="description"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onInputDescription"
        >
      </div>



      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Attribute</label>
        <input
          :id="`att-input-${properties?.id}`"
          v-model="attribute"
          type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @input="onChangeAttribute"
        >
      </div>
      <div class="  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >Nombre Maximum de photo</label>

        <div class="w-full flex flex-col  relative mb-2">
          <div class="w-full flex flex-row w-full px-3 ">
            <div class="start-0 absolute  flex   -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900"
              >1</label>
            </div>
            <div class="start-1/12 absolute flex justify-center -bottom-6">
              <label
                for="steps-range"
                class="block text-2xs font-medium text-gray-900"
              >2</label>
            </div>
            <div class="start-2/12 absolute flex justify-center  -bottom-6">
              <label
                for="steps-range"
                class="block  text-2xs font-medium text-gray-900 "
              >3</label>
            </div>
            <div class="start-3/12 absolute flex justify-center  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >4</label>
            </div>
            <div class="start-4/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >5</label>
            </div>
            <div class="start-5/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >6</label>
            </div>
            <div class="start-6/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >7</label>
            </div>
            <div class="start-7/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >8</label>
            </div>
            <div class="start-8/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >9</label>
            </div>
            <div class="start-9/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >10</label>
            </div>
            <div class="start-10/12 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >11</label>
            </div>
            <div class="end-0 absolute  flex justify-end  -bottom-6">
              <label
                for="steps-range"
                class="block   text-2xs font-medium text-gray-900  "
              >12</label>
            </div>
          </div>
          <input
            id="photo-max-range"
            v-model="max"
            type="range"
            min="1"
            max="12"
            step="1"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            @change="onChangeMax"
          >
        </div>
      </div>



      <div class=" hidden  mb-6 w-full text-left">
        <label
          for="default-input"
          class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Affichage</label>

        <div class="flex">
          <div class="  flex items-center">
            <input
              id="inline-disabled-checkbox"
              disabled
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            >
            <label
              for="inline-disabled-checkbox"
              class="ms-2 text-sm font-medium text-gray-400 dark:text-gray-500"
            >option</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ValidateTableFormComponentDetails',
        props: {
            properties: Object
        },
        data() {

            return {
                title: "",
                description: "",
                attribute: "",
                isTitleDisplayed: false,
                isDescriptionDisplayed: false,
                max : 1
            }
        },
        watch:
        {
            properties: function () {
                this.title = this.properties?.title
            this.description = this.properties?.description
            this.max = this.properties?.max
            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;
            this.attribute = this.properties?.attribute;
            }
        },
        mounted() {
            this.title = this.properties?.title
            this.description = this.properties?.description
            this.max = this.properties?.max
            this.isTitleDisplayed = this.properties?.option?.title;
            this.isDescriptionDisplayed = this.properties?.option?.description;
            this.attribute = this.properties?.attribute;

        },
        methods:
        {
            onChangeAttribute: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'attribute', value: this.attribute })
            },
            onChangeMax: function ( ) {
               
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'max', value: parseInt(this.max) })
            },
            onChangeIsTitleDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'option.title;', value: this.isTitleDisplayed })
            },
            onChangeIsDescriptionDisplayed: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties",att: 'option.description;', value: this.isDescriptionDisplayed })
            },
            onInputTitle: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'title', value: this.title })
            },
            onInputDescription: function () {
                this.$emit("updateField", { id: this.properties?.id, tab: "properties", att: 'description', value: this.description })
            },
    
        }
    }
</script>
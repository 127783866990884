// Editor.vue
<template>
  <button @click="onBtn">
    Finish edit
  </button>
</template>
<script lang="js">
export default {
    props: ['rowIndex', 'model', 'save', 'close'],
    methods: {
        onBtn(e ) {
            const event = new CustomEvent('cell', {
                bubbles: true,
                detail: { row: this.model },
            })
            this.$el.dispatchEvent(event)
            e.stopPropagation()
            if (typeof this.close === 'function') {

                console.log("function")
                this.close()
           //     (this.close as () => void)()
            }
        },
    },
}
</script>